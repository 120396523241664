/* eslint-disable functional/prefer-readonly-type */
import type * as QP from "shared-lib/query-product";

type SearchReset = {
  market: string | undefined;
  language: string | undefined;
  source: QP.DataSourceResponse | undefined;
};

export const globalSearchReset: SearchReset = {
  market: undefined,
  language: undefined,
  source: undefined,
};
