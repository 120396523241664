import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import * as PC from "shared-lib/product-codes";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { CustomUnitsLookup } from "shared-lib/uom";
import type { Input } from "./types";
import type {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  InputParam,
  ResultQuery} from "../types";
import {
  createInputMapperSuccess
} from "../types";
import * as Attributes from "../shared/attributes";
import type { SpeedControl } from "../result-items-types";
import * as AirDensity from "../shared/air-density";

const defaultCfm: readonly [number, number] = [0.0058857778, 588577.190089];
const defaultInWg: readonly [number, number] = [0.00040146307866177, 4014.626771986913];

function getAirFlowLimits(variant: PropertyValueSet.PropertyValueSet, params: string): readonly [number, number] {
  if (params !== "search") {
    return defaultCfm;
    //"supplyAirFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour";
  } else if (PropertyValueSet.hasProperty("application_fresh_air", variant)) {
    return [0.0058857778, 270.1];
  } else if (PropertyValueSet.hasProperty("application_commercial", variant)) {
    return [270.1, 588577.190089];
  } else {
    return defaultCfm;
  }
}
function getPressureLimits(variant: PropertyValueSet.PropertyValueSet, params: string): readonly [number, number] {
  if (params !== "search") {
    return defaultInWg;
    //"supplyAirFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour";
  } else if (PropertyValueSet.hasProperty("application_fresh_air", variant)) {
    return [0.00040146307866177, 0.41];
  } else if (PropertyValueSet.hasProperty("application_commercial", variant)) {
    return [0.41, 4014.626771986913];
  } else {
    return defaultInWg;
  }
}

export function getCalcParams(
  params: string,
  _attributes: Attributes.Attributes,
  variant: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  const [airFlowMin, airFlowMax] = getAirFlowLimits(variant, params);
  const [pressureMin, pressureMax] = getPressureLimits(variant, params);

  const commercialOrFreshAir =
    params === "search" &&
    (PropertyValueSet.hasProperty("application_commercial", variant) ||
      PropertyValueSet.hasProperty("application_fresh_air", variant));

  const performance: Array<InputParam> = [
    {
      type: "Amount",
      group: "performanceFilters",
      name: "toleranceMinus",
      quantity: "Dimensionless",
      fieldName: "tolerance",
      visibilityFilter: commercialOrFreshAir ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup) : undefined,
    },
    {
      type: "Amount",
      group: "performanceFilters",
      name: "tolerancePlus",
      quantity: "Dimensionless",
      fieldName: "tolerance",
      visibilityFilter: commercialOrFreshAir ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup) : undefined,
    },
  ];

  const basics: Array<InputParam> = [
    {
      type: "Amount",
      group: "calculationParams",
      name: "supplyAirFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `supplyAirFlow>=${airFlowMin}:CubicFeetPerMinute&supplyAirFlow<${airFlowMax}:CubicFeetPerMinute`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      visibilityFilter: commercialOrFreshAir ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup) : undefined,
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "supplyPressure",
      quantity: "Pressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `supplyPressure>=${pressureMin}:InchOfWaterColumn&supplyPressure<${pressureMax}:InchOfWaterColumn`,
        CustomUnitsLookup
      ),
      fieldName: "airPressure",
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: commercialOrFreshAir
        ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup)
        : PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: commercialOrFreshAir
        ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup)
        : PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: commercialOrFreshAir
        ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup)
        : PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "extractAirFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `extractAirFlow>=${airFlowMin}:CubicFeetPerMinute&extractAirFlow<${airFlowMax}:CubicFeetPerMinute`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityTemperature",
      visibilityFilter: commercialOrFreshAir
        ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup)
        : PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod!=0", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityTemperature!=null", CustomUnitsLookup),
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(20, Units.Celsius),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "extractPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `extractPressure>=${pressureMin}:InchOfWaterColumn&extractPressure<${pressureMax}:InchOfWaterColumn`,
        CustomUnitsLookup
      ),
      quantity: "Pressure",
      fieldName: "airPressure",
    },
  ];

  if (params === "search") {
    return [...basics, ...performance];
  }
  return basics;
}

export function getQuery(productId: string, variantId: string | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
    ct_AirData2: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirData2"),
    ct_AirLimitsData: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirLimitsData"),
    ct_SoundData: QP.tableFromMtOrByProductId(productId, variantId, "ct_SoundData"),
  });
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_AirData2: QP.AirDataTable;
  readonly ct_AirLimitsData: QP.AirLimitsDataTable;
  readonly ct_SoundData: QP.SoundDataTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const supplyAirFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("supplyAirFlow", calcParams);
  const supplyPressure = PropertyValueSet.getAmount<Quantity.Pressure>("supplyPressure", calcParams);

  const extractAirFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("extractAirFlow", calcParams);
  const extractPressure = PropertyValueSet.getAmount<Quantity.Pressure>("extractPressure", calcParams);

  const toleranceMinus = PropertyValueSet.getAmount<Quantity.Dimensionless>("toleranceMinus", calcParams);
  const tolerancePlus = PropertyValueSet.getAmount<Quantity.Dimensionless>("tolerancePlus", calcParams);

  const airDensity = AirDensity.getAirDensityKgPerCubicMeter(calcParams);

  const speedControlAttr = attributes.byAttribute["PROP-speed-regulation-type-MULTI"] || [];
  const speedControl = (speedControlAttr[0] || "Transformer") as SpeedControl;

  const airMeasurement = Attributes.getMeasurementNo("measurement-number-air", attributes);
  const soundMeasurement = Attributes.getMeasurementNo("measurement-number-sound", attributes);
  const airData = (airMeasurement && queryResultMap.ct_AirData2[airMeasurement]) || [];
  const airLimitsData = (airMeasurement && queryResultMap.ct_AirLimitsData[airMeasurement]) || [];
  const soundData = (soundMeasurement && queryResultMap.ct_SoundData[soundMeasurement]) || [];

  const productCodes = PC.getProductCodes(queryResultMap, properties);

  return createInputMapperSuccess({
    supplyAirFlow: supplyAirFlow,
    supplyPressure: supplyPressure,

    extractAirFlow: extractAirFlow,
    extractPressure: extractPressure,
    airDensity: airDensity,

    toleranceMinus: toleranceMinus,
    tolerancePlus: tolerancePlus,

    productCodes: productCodes,
    speedControl: speedControl,
    airData: airData,
    airLimitsData: airLimitsData,
    soundData: soundData,
  });
}
