/* eslint-disable no-lonely-if */
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { isDefined } from "shared-lib/utils";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type * as QP from "shared-lib/query-product";
import type * as C from "shared-lib/calculation";
import * as Attributes from "./attributes";

export interface AirFlowStep {
  readonly step: number;
  readonly name: string;
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
}

export function hasFixedAirflowPercentage(attributes: Attributes.Attributes): boolean {
  return getFixedAirflowPercentSteps(attributes).length > 0;
}

export function getAirFlowSteps(attributes: Attributes.Attributes): ReadonlyArray<AirFlowStep> {
  return hasFixedAirflowPercentage(attributes) ? getFixedAirflowPercentSteps(attributes) : _getAirFlowSteps(attributes);
}

function _getAirFlowSteps(attributes: Attributes.Attributes): ReadonlyArray<AirFlowStep> {
  const airFlows = [];

  for (let step = 1; ; step++) {
    const attributeName = `air-flow-speed-${step}-BASE-ALL`;
    const airflow = Attributes.getFloat(attributeName, attributes);
    if (airflow !== undefined) {
      airFlows.push(airflow);
    } else {
      break;
    }
  }

  if (airFlows.length === 0 && attributes.byAttribute["air-flow-speeds-MULTI-BASE-ALL"]) {
    attributes.byAttribute["air-flow-speeds-MULTI-BASE-ALL"]
      .map((v) => Number.parseFloat(v))
      .filter((v) => Number.isFinite(v))
      .forEach((v) => airFlows.push(v));
  }

  const sortedAirFlows = airFlows.slice(0).sort((a, b) => b - a);
  const steps = sortedAirFlows.map((af, i) => {
    const step = sortedAirFlows.length - i;
    return {
      step: step,
      name: step.toString(),
      airFlow: Amount.create(af, Units.CubicMeterPerHour),
    };
  });
  return steps;
}

function getFixedAirflowPercentSteps(attributes: Attributes.Attributes): ReadonlyArray<AirFlowStep> {
  const percentages = [100, 80, 60, 40, 20];

  return percentages
    .map((percent) => {
      const attributeName = `air-flow-in-percentage-${percent}`;
      const airflow = Attributes.getFloat(attributeName, attributes);

      if (!airflow) {
        return undefined;
      }
      return {
        step: percent,
        name: percent.toString(),
        airFlow: Amount.create(airflow, Units.CubicMeterPerHour),
      };
    })
    .filter(isDefined);
}

export function canCool(attributes: Attributes.Attributes): boolean {
  return (
    Attributes.getString("DLL-input-roenest-cooling-enabled", attributes)?.toLowerCase() === "true" ||
    Attributes.getString("DLL-input-friterm-cooling-enabled", attributes)?.toLowerCase() === "true"
  );
}

export interface ProductTables {
  readonly ct_Attributes2: QP.AttributesTable;
}

export function updateAirCurtainCalcParams(
  fullConfigPrev: C.ItemConfig | undefined,
  fullConfigCurr: C.ItemConfig | undefined,
  productTables: ProductTables,
  setInitialAirFlowStep: boolean
): PropertyValueSet.PropertyValueSet | undefined {
  if (!fullConfigPrev || !fullConfigCurr) {
    return undefined;
  }

  const prevCalcParams = fullConfigPrev?.calcParams;
  const currCalcParams = fullConfigCurr?.calcParams;
  const currAttributes = Attributes.createMap(fullConfigCurr.properties, productTables.ct_Attributes2);

  let updatedCalcParams = undefined;

  const updateAirStepParams = handleAirFlowStep(prevCalcParams, currCalcParams, currAttributes, setInitialAirFlowStep);
  if (updateAirStepParams) {
    updatedCalcParams = PropertyValueSet.merge(updateAirStepParams, updatedCalcParams ?? PropertyValueSet.Empty);
  }

  const updatedOpModeParam = disableCoolingModeIfNotSupported(currCalcParams, currAttributes);
  if (updatedOpModeParam) {
    updatedCalcParams = PropertyValueSet.merge(updatedOpModeParam, updatedCalcParams ?? PropertyValueSet.Empty);
  }

  const newCalcParams = updatedCalcParams ? PropertyValueSet.merge(updatedCalcParams, currCalcParams) : undefined;

  return newCalcParams;
}

function disableCoolingModeIfNotSupported(
  calcParams: PropertyValueSet.PropertyValueSet,
  attributes: Attributes.Attributes
): PropertyValueSet.PropertyValueSet | undefined {
  if (!canCool(attributes) && PropertyValueSet.getInteger("operatingMode", calcParams) === 1) {
    return PropertyValueSet.setInteger("operatingMode", 0, PropertyValueSet.Empty);
  } else {
    return undefined;
  }
}

function handleAirFlowStep(
  prevCalcParams: PropertyValueSet.PropertyValueSet,
  currCalcParams: PropertyValueSet.PropertyValueSet,
  attributes: Attributes.Attributes,
  setInitialAirFlowStep: boolean
): PropertyValueSet.PropertyValueSet | undefined {
  const airflowProperty = hasFixedAirflowPercentage(attributes) ? "airFlowPercent" : "airFlowStep";

  const prevStep = PropertyValueSet.getInteger(airflowProperty, prevCalcParams);
  const currStep = PropertyValueSet.getInteger(airflowProperty, currCalcParams);

  const currAirFlow = PropertyValueSet.getAmount<Units.VolumeFlow>("customAirFlow", currCalcParams);
  const previousAirFlow = PropertyValueSet.getAmount<Units.VolumeFlow>("customAirFlow", prevCalcParams);
  const steps = getAirFlowSteps(attributes);

  const stepAirflow = new Map(steps.map((step) => [step.step, step.airFlow]));

  //Handle initial default value
  if (
    (currAirFlow === undefined && previousAirFlow !== undefined && prevStep === currStep && !setInitialAirFlowStep) ||
    currStep === undefined
  ) {
    return undefined;
  }

  let newAirFlow = undefined;
  let newStep = undefined;
  if (!setInitialAirFlowStep) {
    // step changed, set currAirflow
    if (currStep !== undefined && prevStep !== currStep && stepAirflow.has(currStep)) {
      newAirFlow = stepAirflow.get(currStep);
    }

    // airflow changed, set step
    else if (currAirFlow && previousAirFlow && !Amount.equals(currAirFlow, previousAirFlow)) {
      const airFlowStep = steps.find((step) => Amount.equals(step.airFlow, currAirFlow));

      newStep = airFlowStep?.step ?? 0;
    }
  } else {
    if (prevStep === undefined || previousAirFlow === undefined) {
      if (steps.length > 0) {
        const biggestStep = steps[steps.length - 1];

        newStep = biggestStep.step;

        newAirFlow = biggestStep.airFlow;
      }
      // update airflowstep if it belongs to the new product in stepairflow-map
      return undefined;
    } else if (prevStep === 0) {
      newAirFlow = previousAirFlow;

      newStep = steps.find((s) => Amount.equals(previousAirFlow, s.airFlow))?.step ?? 0;
    } else {
      if (stepAirflow.has(prevStep)) {
        newStep = prevStep;

        newAirFlow = stepAirflow.get(prevStep);
      } else if (steps.length > 0) {
        const biggestStep = steps[steps.length - 1];

        newStep = biggestStep.step;

        newAirFlow = biggestStep.airFlow;
      }
    }
  }

  let pvs = undefined;
  if (newAirFlow && (!currAirFlow || !Amount.equals(newAirFlow, currAirFlow))) {
    pvs = PropertyValueSet.setAmount("customAirFlow", newAirFlow, pvs ?? PropertyValueSet.Empty);
  }

  if (newStep !== undefined && (currStep === undefined || newStep !== currStep)) {
    pvs = PropertyValueSet.setInteger(airflowProperty, newStep, pvs ?? PropertyValueSet.Empty);
  }
  return pvs;
}
