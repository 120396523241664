import * as R from "ramda";
import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as QI from "shared-lib/query-identity";
import * as C from "shared-lib/calculation";
import { diaqConnect } from "client-lib/redux-integration";
import * as Texts from "shared-lib/language-texts";
import * as ProductUtils from "shared-lib/product-utils";
import type { StateProps, Response, OwnProps } from "./container-component";
import { FricoSpecificationTextEpimContainerComponent, createConfig } from "./container-component";
import type { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.fricoSpecificationTextEpim,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (s: RootState) => s.ui.product.config,
  (state, showAdvanced, userSettings, itemconfig) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
    itemConfig: itemconfig,
  })
);

function mapPropsToQuery(props: OwnProps & StateProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_SearchProducts: QP.tableByProductId(QP.fricoProductId, "ct_SearchProducts"),
    translateTables: translateTablesQuery(props, response),
    fricoTables: QP.tablesByProductId(QP.fricoProductId, [
      "property",
      "ct_PropertyFieldNames",
      "ct_PropertySelectorTypes",
      "ct_SavedResultColumns",
    ]),
    tablesForProducts: tablesForProductsQuery(getMainProducts(response)),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    marketTables: ProductUtils.createMarketTablesQuery(props.ecomUrl, props.market),
    productTables: QD.createMapQuery({
      ct_Accessories: props.state.productId
        ? QP.tableByProductId(props.state.productId, "ct_Accessories")
        : QI.createIdentityQuery(null),
      ct_Attributes2: props.state.productId
        ? QP.tableByProductId(props.state.productId, "ct_Attributes2")
        : QI.createIdentityQuery(null),
    }),
    calculationResponse: QD.createMapQuery(
      props.state.addedItems.reduce((acc, item) => {
        return {
          ...acc,
          [item.productId]: C.calculationQuery(
            {
              productId: item.productId,
              config: createConfig(item),
              variantId: item.variantNo,
            },
            response?.calculationResponse[item.productId]
          ),
        };
      }, {})
    ),
  });
}

function translateTablesQuery(
  props: OwnProps & StateProps,
  response: Response | undefined
): QP.TablesByProductIdsQuery | undefined {
  if (!response || !response.tablesForProducts || !response.marketTables) {
    return undefined;
  }

  const accessoryIds: Array<string> = [];
  for (const product of props.state.addedItems) {
    const accTable = response.tablesForProducts[product.productId];
    const accProductIds = accTable.ct_Accessories.map((acc) => acc.product);
    accessoryIds.push(...accProductIds);
  }

  const ids = [
    ...R.uniq(accessoryIds),
    ...props.state.addedItems.map((id) => id.productId),
    QP.fricoProductId,
    ...response.ct_SearchProducts?.map((p) => p.product),
  ];

  return Texts.getTablesQuery(ids);
}

function tablesForProductsQuery(
  products: ReadonlyArray<string> | undefined
): QD.DiaqMapQuery<Response["tablesForProducts"]> | undefined {
  if (products === undefined) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    map[product] = QP.tablesByProductId(product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_ItemNumberStatus",
      "ct_AccessoryRelations",
      "ct_ResultItems",
      "ct_Attributes2",
      "ct_Accessories",
    ]);
  }
  return QD.createMapQuery(map);
}

function getMainProducts(response: Response | undefined): ReadonlyArray<string> | undefined {
  if (!response || !response.marketTables) {
    return undefined;
  }
  return ProductUtils.filterProductsByMarket(
    response.marketTables,
    response.ct_SearchProducts.map((p) => p.product)
  );
}

export const FricoSpecificationTextEpimContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(FricoSpecificationTextEpimContainerComponent);
