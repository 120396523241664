import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import type * as Actions from "./actions";
import type { State } from "./types";

const numResults = 10;

const initialState: State = {
  searchProductId: "",
  selections: {},
  calcParams: PropertyValueSet.Empty,
  accessories: [],
  groupBy: "Product",
  groupsOpen: [],
  sortType: undefined,
  sortPath: "sort_no",
  columnGroup: "",
  descending: false,
  numResults: numResults,
  crmSavedResults: [],
  groupSortParams: undefined,
  useEcomQueryParam: true,
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "persist/REHYDRATE":
      if (!state.useEcomQueryParam) {
        return { ...state, useEcomQueryParam: true };
      }
      return state;

    case "search-products/RESET":
      return initialState;

    case "search-products/SET_SELECTIONS":
      return {
        ...state,
        searchProductId: action.payload.searchProductId,
        crmSavedResults: [],
        selections: action.payload.selections,
        useEcomQueryParam: false,
      };

    case "search-products/SET_CALC_PARAMS":
      return {
        ...state,
        searchProductId: action.payload.searchProductId,
        crmSavedResults: [],
        calcParams: action.payload.calcParams,
      };
    case "search-products/SET_ACCESSORIES":
      return {
        ...state,
        crmSavedResults: [],
        searchProductId: action.payload.searchProductId,
        accessories: action.payload.accessories,
      };

    case "search-products/SET_GROUP_BY":
      return {
        ...state,
        groupBy: action.payload,
        groupsOpen: [],
      };
    case "search-products/SET_GROUP_OPEN": {
      const groupsOpen = action.payload.open
        ? R.append(action.payload.group, state.groupsOpen)
        : R.filter((g) => g !== action.payload.group, state.groupsOpen);
      return {
        ...state,
        groupsOpen: groupsOpen,
      };
    }
    case "search-products/SET_SORTING":
      return {
        ...state,
        sortType: action.payload.sortType,
        sortPath: action.payload.sortPath,
        descending: action.payload.descending,
        groupSortParams: undefined,
      };
    case "search-products/SET_GROUP_SORTING":
      return {
        ...state,
        sortType: undefined,
        sortPath: "",
        descending: false,
        groupSortParams: action.payload,
      };
    case "search-products/SET_COLUMN_GROUP":
      return {
        ...state,
        columnGroup: action.payload,
      };
    case "search-products/SET_NUM_RESULTS":
      return {
        ...state,
        numResults: action.payload,
      };
    case "search-products/DISABLE_ECOM_CATEGORY":
      return {
        ...state,
        useEcomQueryParam: false,
      };
    case "search-products/SAVE_CRM_RESULT": {
      const found = state.crmSavedResults.find((r) => r === action.payload);
      return {
        ...state,
        crmSavedResults:
          found === undefined
            ? [...state.crmSavedResults, action.payload]
            : state.crmSavedResults.filter((r) => r !== action.payload),
      };
    }
    default:
      return state;
  }
}
