import * as React from "react";
import type { DispatchProp } from "client-lib/redux-integration";
import type * as Texts from "shared-lib/language-texts";
import type * as UserSettingsClient from "client-lib/user-settings";
import type * as UserSettingsShared from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import type {
  Selections,
  SelectionsChanged,
  Images} from "containers/properties-selector-multi";
import {
  PropertiesSelectorMultiContainer
} from "containers/properties-selector-multi";

type Props = OwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export interface OwnProps {
  readonly translate: Texts.TranslateFunction;
  readonly market: string;
  readonly language: string;
  readonly productId: string;
  readonly hideProperties: ReadonlyArray<string>;
  readonly selections: Selections;
  readonly selectionsChanged: SelectionsChanged;
  readonly hideInvalidValues?: boolean;
  readonly comboBoxImageCSSFilter?: string;
  readonly hideFirstGroupTitle?: boolean;
}

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  readonly showAdvanced: boolean;
}

export interface Response {
  readonly productTables: ProductTables;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly images: Images;
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly ct_PropertyFieldNames: QP.PropertyFieldNamesTable;
  readonly ct_PropertySelectorTypes: QP.PropertySelectorTypesTable;
  readonly ct_ClosedGroups: QP.ClosedGroups;
  readonly image: QP.ProductImageTable;
}

export function SearchPropertiesSelectorContainerComponent({
  productId,
  productTables,
  hideProperties,
  selections,
  selectionsChanged,
  translate,
  market,
  language,
  hideInvalidValues,
  comboBoxImageCSSFilter,
  hideFirstGroupTitle,
}: Props): React.ReactElement<Props> {
  const productProperties = productTables.property;
  const properties = boxFanPeruRemoveDensityByPressure(productProperties, market, productId);
  return (
    <div>
      <PropertiesSelectorMultiContainer
        translate={translate}
        market={market}
        language={language}
        productId={productId}
        hideProperties={hideProperties}
        selections={selections}
        selectionsChanged={selectionsChanged}
        hideInvalidValues={hideInvalidValues}
        propertySelectorTypes={productTables.ct_PropertySelectorTypes}
        properties={properties}
        propertyFieldNames={productTables.ct_PropertyFieldNames}
        comboBoxImageCSSFilter={comboBoxImageCSSFilter}
        hideFirstGroupTitle={hideFirstGroupTitle}
      />
    </div>
  );
}
// Hack to get Boxfans for peru and chile to hide method "by pressure"
// Boxfans will be reworked because we have mixed three different UI's into one search product that causes it to become complex
function boxFanPeruRemoveDensityByPressure(
  properties: ReadonlyArray<QP.ProductProperty>,
  market: string,
  productId: string
): ReadonlyArray<QP.ProductProperty> {
  if (productId === QP.boxFanProductId && (market === "265" || market === "285")) {
    const densityMethod = properties.find((p) => p.name === "airDensityCalculationMethod");
    if (!densityMethod) {
      return properties;
    }
    const removedPressure = densityMethod.value.filter((t) => t.value.value !== 1);
    return [
      ...properties.filter((p) => p.name !== "airDensityCalculationMethod"),
      { ...densityMethod, value: removedPressure },
    ].sort((a, b) => a.sort_no - b.sort_no);
  }

  return properties;
}

// function getImage(
//   url: string | undefined,
//   images: Images,
//   productTables: ProductTables,
//   p: QP.ProductProperty,
//   pv: QP.ProductPropertyValue,
//   variant: PropertyValueSet.PropertyValueSet
// ): string | undefined {
//   if (!url) {
//     return undefined;
//   }

//   const alternativeImage = getAlternativeImage(images, productTables, p, pv, variant);
//   if (alternativeImage !== undefined) {
//     return alternativeImage;
//   }

//   const imageData = images[url];
//   if (!imageData) {
//     return undefined;
//   }
//   return imageData;
// }

// function getAlternativeImage(
//   images: Images,
//   productTables: ProductTables,
//   p: QP.ProductProperty,
//   pv: QP.ProductPropertyValue,
//   variant: PropertyValueSet.PropertyValueSet
// ): string | undefined {
//   if (pv.value.type !== "integer") {
//     return undefined;
//   }

//   if (pv.value.type === "integer") {
//     const alternativeImages = productTables.image.filter(r => (r.image === images[r.image]) !== undefined);
//     const correctedVariant = PropertyValueSet.setInteger(p.name, pv.value.value, variant);

//     const matchingImage = alternativeImages.find(
//       i => PropertyFilter.isValid(correctedVariant, i.property_filter) && i.type === p.name
//     );

//     if (matchingImage !== undefined) {
//       return images[matchingImage.image];
//     }
//   }
//   return undefined;
// }
