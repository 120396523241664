import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Types from "../types";
import * as Messages from "../messages";
import type { Curve, Fan, FanNominalData } from "../result-items-types";
import * as FanAir from "../shared/fan-air";
import * as FanSound from "../shared/fan-sound";
import * as FanMaxEfficiency from "../shared/fan-max-efficiency";
import * as Attributes from "../shared/attributes";
import * as Utils from "../shared/utils";
import type { Input } from "./types";
//import * as FEI from "../fan-efficiency-index";

const source = "FantechFanCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<Fan>> {
  const {
    airFlow,
    externalPressure,
    speedControl,
    accessories,
    airData,
    airLimitsData,
    soundData,
    attributes,
    calcParams,
    productCodes,
    airDensity,
  } = input;

  if (!speedControl) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const messages: Array<Messages.Message> = [];

  const maxEfficiency =
    !airFlow && !externalPressure ? FanMaxEfficiency.findMaxEfficiencyWorkingPoint(airData) : undefined;
  const desiredAirFlow = maxEfficiency?.airFlow ?? airFlow;
  const desiredPressure = maxEfficiency?.pressure ?? externalPressure;

  const accessoryPressureCurves = accessories.map((a) => a.pressureCurve);
  const pressureCurves = accessoryPressureCurves.filter((a) => a) as Array<Curve>;

  const fan = FanAir.calculate(
    speedControl,
    airData,
    airLimitsData,
    desiredAirFlow,
    desiredPressure,
    pressureCurves,
    airDensity,
    undefined,
    false,
    maxEfficiency !== undefined
  );
  if (!fan.workingPoint) {
    messages.push(Messages.Error_OutsideValidRange(source));
  }

  if (fan.airFlow !== undefined && fan.desiredAirFlow !== undefined && fan.desiredPointIsOutsideValidArea) {
    messages.push(Messages.Warning_PointAdjustedToClosestValid(source));
  }

  const inletSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Inlet",
    undefined
  );
  const inletSilencer = accessories.find((a) => a.type === "Silencer");
  const inletWithSilencerSound =
    inletSilencer && FanSound.applyAttenuation(inletSound.octaveBands, inletSilencer.soundAttenuation);
  const outletSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Outlet",
    undefined
  );
  const outletSilencer = accessories.find((a) => a.type === "Silencer");
  const outletWithSilencerSound =
    outletSilencer && FanSound.applyAttenuation(outletSound.octaveBands, outletSilencer.soundAttenuation);
  const surroundingSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Surrounding",
    undefined
  );

  const nomVoltage = Attributes.getInt("input-voltage-NOM", attributes);
  const nomPower = Attributes.getFloat("input-power-BASE-ALL", attributes);
  const nomCurrent = Attributes.getFloat("input-current", attributes);
  const nomSpeed = Attributes.getFloat("impeller-speed-NOM", attributes);
  const maxAirFlow = Attributes.getFloat("air-flow-RATE-MAX-BASE-ALL", attributes);
  const nomSoundPressure = Attributes.getFloat("sound-press-3m-20m2-sabin", attributes);
  const insulationClass = Attributes.getString("insulation-class-motor", attributes);
  const enclosureClass = Attributes.getString("enclosure-class-motor", attributes);

  const nominalData: FanNominalData = {
    voltage: Utils.maybeAmount(nomVoltage, customUnits.Volt, 0),
    power: Utils.maybeAmount(nomPower, customUnits.Watt, 1),
    current: Utils.maybeAmount(nomCurrent, customUnits.Ampere, 3),
    speed: Utils.maybeAmount(nomSpeed, customUnits.RevolutionsPerMinute, 0),
    maxAirFlow: Utils.maybeAmount(maxAirFlow, customUnits.CubicMeterPerHour, 0),
    soundPressure: Utils.maybeAmount(nomSoundPressure, customUnits.Decibel, 1),
    insulationClass: insulationClass,
    enclosureClass: enclosureClass,
    weight: undefined,
  };

  /// FEI -disabled for now
  //  use http://localhost:8888/?item=40022 for testing
  const fanEfficiencyIndex = undefined;
  // if (airDensity !== undefined && fan !== undefined && fan.workingPoint !== undefined && fan.power !== undefined) {
  //   console.log(fan.power);

  //   //We calculate static pressure, if we need total we have to calculate it
  //  // const outletDuctSize = Attributes.getFloatOrDefault("size-duct-outlet-circular-BASE-IMP", attributes, -1);
  //   // if (outletDuctSize !== -1) {
  //     // const area = Math.PI * (outletDuctSize / 2) ** 2;
  //     // const totalPressure =
  //     //   fan.workingPoint.point.y +
  //     //   ((fan.workingPoint.point.x * 0.001) / (1097.8 * area)) ** 2 * ///////////// THIS IS WRONG! ONLY FOR SI UNITS (1097.8) See function calculateTotalPressure
  //     //     Amount.valueAs(customUnits.KilogramPerCubicMeter, airDensity); 

  //     const fei = FEI.fanEfficiencyIndexWireToAir(
  //       "static",
  //       Amount.valueAs(customUnits.KilogramPerCubicMeter, airDensity),
  //       fan.workingPoint.point.x * 0.001,
  //       fan.workingPoint.point.y,
  //       Amount.valueAs(customUnits.KiloWatt, fan.power)
  //     );
  //     if (fei !== undefined) {
  //       fanEfficiencyIndex = Amount.create(fei, customUnits.One, 10);
  //     }
  //   //}
  // }
  // END FEI

  return Types.createCalculatorSuccess(
    [
      {
        value: fan.distanceWorkingPointToDesiredPoint !== undefined ? fan.distanceWorkingPointToDesiredPoint : Infinity,
        descending: false,
      },
      { value: 1 - ((fan.efficiency && Amount.valueAs(customUnits.One, fan.efficiency)) || 1), descending: false },
      { value: productCodes.code, descending: false },
    ],
    {
      air: fan,
      fanEfficiencyIndex: fanEfficiencyIndex,
      inletSound: inletSound.octaveBands3rd,
      inletWithSilencerSound: inletWithSilencerSound,
      outletSound: outletSound.octaveBands3rd,
      outletWithSilencerSound: outletWithSilencerSound,
      surroundingSound: surroundingSound.octaveBands3rd,
      nominal: nominalData,
      accessories: [],
    },
    messages,
    calcParams
  );
}
