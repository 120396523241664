import * as React from "react";
import * as ExternalClient from "client-lib/external";
import { Alert, Button, LinkButton } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import type { Dispatch } from "client-lib/redux-integration";
import * as EI from "shared-lib/external-item";
import type * as UserSettingsShared from "shared-lib/user-settings";
import type * as C from "shared-lib/calculation";
import { resolveQuery } from "client-lib/redux-query-resolver";
import { clientConfig } from "config";

export interface ExternalAddButtonProps {
  readonly externalState: ExternalClient.State;
  readonly translate: Texts.TranslateFunction;
  readonly dispatch: Dispatch<ExternalClient.Action>;
  readonly externalConfig: EI.ExternalConfig | undefined;
  readonly market: string;
  readonly variant: string | undefined;
  readonly m3ItemNo: string;
  readonly userSettings: UserSettingsShared.State;
  readonly fullConfig: C.ItemConfig;
  readonly buttonId: string;
  readonly compact?: boolean;
  readonly secondary?: boolean;
}

export function ExternalAddButton({
  externalState,
  translate,
  dispatch,
  externalConfig,
  market,
  variant,
  m3ItemNo,
  userSettings,
  fullConfig,
  buttonId,
  compact,
  secondary,
}: ExternalAddButtonProps): React.ReactElement<ExternalAddButtonProps> {
  const [showDetails, setShowDetails] = React.useState(false);

  if (!externalConfig) {
    return <></>;
  }

  const activeButton = externalState.buttonId === buttonId;

  if (externalState.adding) {
    return (
      <Button
        compact={compact}
        buttonType={secondary ? "secondary" : "primary"}
        iconLeft={activeButton ? "spinner-third" : "plus"}
        spinnerIcon={activeButton}
        label={
          activeButton
            ? translate(externalConfig.type === "crm" ? Texts.adding_to_crm() : Texts.adding_to_specifier())
            : translate(externalConfig.type === "crm" ? Texts.add_to_crm() : Texts.add_to_specifier())
        }
        clicked={() => null}
        disabled={true}
      />
    );
  }

  return (
    <div>
      <Button
        compact={compact}
        buttonType={secondary ? "secondary" : "primary"}
        iconLeft="plus"
        label={translate(externalConfig.type === "crm" ? Texts.add_to_crm() : Texts.add_to_specifier())}
        clicked={async () => {
          const items = await EI.createExternalItem(
            {
              market: market,
              language: externalConfig.type === "crm" ? externalConfig.crmQuoteLanguage : "en",
              m3ItemNo: m3ItemNo,
              variant: variant,
              stateConfig: fullConfig,
              propsConfig: undefined,
              userSettings: userSettings,
              imageServiceUrl: clientConfig.imageServiceUrl,
              externalConfig,
            },
            resolveQuery
          );

          dispatch(ExternalClient.addItems(externalConfig, items, buttonId));
        }}
      />
      {externalState.status && externalState.status.type === "error" && activeButton ? (
        <Alert
          className="mt-8"
          type="error"
          close={() => {
            setShowDetails(false);
            dispatch(ExternalClient.dismissError());
          }}
        >
          {translate(externalConfig.type === "crm" ? Texts.crm_error() : Texts.specifier_error())}
          {externalState.status.code !== undefined || externalState.status.message !== undefined ? (
            <div className="max-w-sm">
              {showDetails ? (
                <>
                  <p>
                    <b>{translate(Texts.error_details())}</b>
                  </p>
                  <p>
                    {translate(Texts.error_code())}:{" "}
                    {externalState.status.code !== undefined ? externalState.status.code : "n/a"}
                  </p>
                  <p>
                    {translate(Texts.error_message())}:{" "}
                    {externalState.status.message !== undefined ? externalState.status.message : "n/a"}
                  </p>
                </>
              ) : (
                <LinkButton onClick={() => setShowDetails(true)}>{translate(Texts.show_details())}</LinkButton>
              )}
            </div>
          ) : undefined}
        </Alert>
      ) : undefined}
      {externalState.status && externalState.status.type === "success" && activeButton ? (
        <Alert className="mt-8" type="success" close={() => dispatch(ExternalClient.dismissError())}>
          {translate(externalConfig.type === "crm" ? Texts.crm_added() : Texts.specifier_added())}
        </Alert>
      ) : undefined}
    </div>
  );
}
