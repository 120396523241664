import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { acceptableAmcaSearchProducts } from "shared-lib/version";
import type { RootState } from "types";
import { AmcaVersionComponent } from "./container-component";
import type { OwnProps, Response } from "./types";

const mapStateToProps = (_: RootState, __: OwnProps): unknown => ({});

function mapPropsToQuery(_: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery({
    tables: QD.createMapQuery({
      products: QP.productsByKey(),
      searchProductTables:
        response &&
        QP.tablesByProductIds(
          acceptableAmcaSearchProducts.map((p) => response.tables.products[p].id),
          ["ct_SearchProducts"]
        ),
    }),
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AmcaVersion = diaqConnect(mapPropsToQuery, mapStateToProps)(AmcaVersionComponent as any);
