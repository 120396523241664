import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as Types from "../types";
import type * as Messages from "../messages";
import type { Other } from "../result-items-types";
import * as PressureDrop from "../shared/pressure-drop";
import type { Input } from "./types";
import { validateMaxAirFlowAndPressure } from "../shared/validate-max-airflow-and-pressure";

const source = "OtherCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<Other>> {
  const { airFlow, maxAirFlow, maxPressure } = input;

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);

  const messages: Array<Messages.Message> = [];
  const points = [
    Interpolation.vec2Create(
      Amount.valueAs(customUnits.LiterPerSecond, maxAirFlow),
      Amount.valueAs(customUnits.Pascal, maxPressure)
    ),
  ];

  const minFlow = 0;
  const maxFlow = Math.max(...points.map((p) => p.x)) * 3;

  const pressureDropCurve = PressureDrop.createPowerPressureCurve(minFlow, maxFlow, points);
  const pressureDropPa = Interpolation.splineGetPoint(airFlowLps, pressureDropCurve.spline) || 0;

  messages.push(...validateMaxAirFlowAndPressure(source, maxAirFlow, maxPressure, airFlow, pressureDropPa));

  return Types.createCalculatorSuccess(
    [],
    {
      airFlow: airFlow,
      pressureDrop: Amount.create(pressureDropPa, customUnits.Pascal, 1),
      pressureDropCurve: pressureDropCurve,
    },
    messages
  );
}
