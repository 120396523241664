import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as Types from "../types";
import * as Messages from "../messages";
import type {
  FanAirResult,
  ExchangerResult,
  ElectricDuctHeater,
  SystemairHeatRecoveryUnit,
} from "../result-items-types";
import * as Exchanger from "../shared/exchanger";
import * as FanAir from "../shared/fan-air";
import * as FanSound from "../shared/fan-sound";
import * as Utils from "../shared/utils";
import * as ElectricHeater from "../shared/electric-heater";
import type { Input } from "./types";
import * as Attributes from "../shared/attributes";

const source = "SystemairHeatRecoveryUnitCalculator";

function calcTotalSfp(
  supplyFan: FanAirResult,
  extractFan: FanAirResult
): Amount.Amount<Quantity.SpecificFanPower> | undefined {
  if (!supplyFan.airFlow || !extractFan.airFlow || !supplyFan.power || !extractFan.power) {
    return undefined;
  }
  const supplyPower = Amount.valueAs(Units.KiloWatt, supplyFan.power);
  const extractPower = Amount.valueAs(Units.KiloWatt, extractFan.power);
  const supplyFlow = Amount.valueAs(Units.CubicMeterPerSecond, supplyFan.airFlow);
  const extractFlow = Amount.valueAs(Units.CubicMeterPerSecond, extractFan.airFlow);
  const totalSfpKwPerCmps = (supplyPower + extractPower) / Math.max(supplyFlow, extractFlow);
  return Utils.maybeAmount(totalSfpKwPerCmps, Units.KiloWattPerCubicMeterPerSecond, 2);
}

export async function calculate(input: Input): Promise<Types.CalculatorResult<SystemairHeatRecoveryUnit>> {
  const {
    supplyAirFlow,
    supplyPressure,

    extractAirFlow,
    extractPressure,

    supplyOutletTemperature,
    attributes,
    speedControl,
    tempEfficiencyCorrections,
    airData,
    airLimitsData,
    soundData,
    productCodes,
    variantName,
    airDensity,
  } = input;

  const messages: Array<Messages.Message> = [];

  const supplyFan = FanAir.calculate(
    speedControl,
    airData.filter((p) => p.part === "Supply"),
    airLimitsData.filter((p) => p.part === "Supply"),
    supplyAirFlow,
    supplyPressure,
    [],
    airDensity,
    undefined,
    true,
    false
  );
  const extractFan = FanAir.calculate(
    speedControl,
    airData.filter((p) => p.part === "Extract"),
    airLimitsData.filter((p) => p.part === "Extract"),
    extractAirFlow,
    extractPressure,
    [],
    airDensity,
    undefined,
    true,
    false
  );

  if (supplyFan.desiredPointIsOutsideValidArea || extractFan.desiredPointIsOutsideValidArea) {
    messages.push(Messages.Error_OutsideValidRange(source));
  }

  if (!supplyFan.voltageLow || !extractFan.voltageLow) {
    messages.push(Messages.Warning_LowLoadOutsideValidRange(source));
  }

  if (!supplyFan.voltageHigh || !extractFan.voltageHigh) {
    messages.push(Messages.Warning_HighLoadOutsideValidRange(source));
  }

  const maxAirFlowDifferencePercent = 50; // CI.getFloat("MaxAirFlowDifferencePercent", calculationInputs, 50);
  const flowDifference = Utils.amountPercentDifference(supplyAirFlow, extractAirFlow);
  if (flowDifference > maxAirFlowDifferencePercent) {
    messages.push(Messages.Error_SupplyExhaustAirFlowDifferenceTooHigh(source));
  }

  const maxPressureDifferencePercent = 50; // CI.getFloat("MaxPressureDifferencePercent", calculationInputs, 50);
  const pressureDifference = Utils.amountPercentDifference(supplyPressure, extractPressure);
  if (pressureDifference > maxPressureDifferencePercent) {
    messages.push(Messages.Error_SupplyExhaustPressureDifferenceTooHigh(source));
  }

  const supplyFanIsOutside =
    supplyFan.airFlow !== undefined &&
    supplyFan.desiredAirFlow !== undefined &&
    supplyFan.desiredPointIsOutsideValidArea;
  const extractFanIsOutside =
    extractFan.airFlow !== undefined &&
    extractFan.desiredAirFlow !== undefined &&
    extractFan.desiredPointIsOutsideValidArea;
  if (supplyFanIsOutside || extractFanIsOutside) {
    messages.push(Messages.Warning_PointAdjustedToClosestValid(source));
  }

  const hasErrorMessage = messages.some((m) => Messages.isErrorMessage(m.code));

  const passiveHouse = variantName.includes("Passive House");

  const isSummer = input.season === "Summer" || input.season === "Summer+Winter";
  const isWinter = input.season === "Winter" || input.season === "Summer+Winter";

  const exchangerResultSummer =
    isSummer &&
    !supplyFan.desiredPointIsOutsideValidArea &&
    !extractFan.desiredPointIsOutsideValidArea &&
    !hasErrorMessage
      ? await Exchanger.calculate(
          supplyFan,
          input.freshTemperatureSummer,
          input.freshHumiditySummer,
          extractFan,
          input.extractTemperatureSummer,
          input.extractHumiditySummer,
          attributes,
          tempEfficiencyCorrections,
          passiveHouse
        )
      : undefined;

  const exchangerResultWinter =
    isWinter &&
    !supplyFan.desiredPointIsOutsideValidArea &&
    !extractFan.desiredPointIsOutsideValidArea &&
    !hasErrorMessage
      ? await Exchanger.calculate(
          supplyFan,
          input.freshTemperatureWinter,
          input.freshHumidityWinter,
          extractFan,
          input.extractTemperatureWinter,
          input.extractHumidityWinter,
          attributes,
          tempEfficiencyCorrections,
          passiveHouse
        )
      : undefined;

  if (isSummer && exchangerResultSummer) {
    messages.push(...exchangerResultSummer.messages);
  }
  if (isWinter && exchangerResultWinter) {
    messages.push(...exchangerResultWinter.messages);
  }

  const electricHeaterSummer = isSummer
    ? calcElectricHeater(
        supplyFan.airFlow,
        input.freshTemperatureSummer,
        input.freshHumiditySummer,
        exchangerResultSummer,
        supplyOutletTemperature,
        attributes
      )
    : undefined;

  const electricHeaterWinter = isWinter
    ? calcElectricHeater(
        supplyFan.airFlow,
        input.freshTemperatureWinter,
        input.freshHumidityWinter,
        exchangerResultWinter,
        supplyOutletTemperature,
        attributes
      )
    : undefined;

  const supplyOutletTemperatureSummer = electricHeaterSummer
    ? electricHeaterSummer.outletAirTemperature
    : exchangerResultSummer
    ? exchangerResultSummer.supplyOutletTemperature
    : isSummer
    ? input.freshTemperatureSummer
    : undefined;

  const supplyOutletTemperatureWinter = electricHeaterWinter
    ? electricHeaterWinter.outletAirTemperature
    : exchangerResultWinter
    ? exchangerResultWinter.supplyOutletTemperature
    : isWinter
    ? input.freshTemperatureWinter
    : undefined;

  const supplySound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Supply",
    undefined
  );
  const outdoorSound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Outdoor",
    undefined
  );
  const extractSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Extract",
    undefined
  );
  const exhaustSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Exhaust",
    undefined
  );
  const surrSupplySound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Surrounding, supply",
    undefined
  );
  const surrExhaustSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Surrounding, exhaust",
    undefined
  );
  const surroundingSound = FanSound.sumResults(surrSupplySound, surrExhaustSound);

  const nominalFlow = Attributes.getFloat("air-flow-NOM-BASE-ALL", input.attributes);
  return Types.createCalculatorSuccess(
    [
      {
        value:
          nominalFlow && supplyAirFlow
            ? Math.abs(Amount.valueAs(Units.CubicMeterPerHour, supplyAirFlow) - nominalFlow)
            : Infinity,
        descending: false,
      },
      {
        value:
          (supplyFan.distanceWorkingPointToMaxPoint ?? Infinity) +
          (extractFan.distanceWorkingPointToMaxPoint ?? Infinity),
        descending: false,
      },
      {
        value: 100 - (supplyFan.efficiency ? Amount.valueAs(Units.Percent, supplyFan.efficiency) : 0),
        descending: false,
      },
      { value: productCodes.code, descending: false },
      { value: productCodes.variant || "", descending: false },
    ],
    {
      supplyFan,
      extractFan,
      exchangerSummer: exchangerResultSummer,
      exchangerWinter: exchangerResultWinter,
      exchanger: isSummer ? exchangerResultSummer : exchangerResultWinter, // For search result columns
      electricHeaterSummer,
      electricHeaterWinter,

      totalSfp: calcTotalSfp(supplyFan, extractFan),
      supplyOutletTemperatureSummer,
      supplyOutletTemperatureWinter,
      airDensity,

      supplySound: supplySound.octaveBands3rd,
      outdoorSound: outdoorSound.octaveBands3rd,
      exhaustSound: exhaustSound.octaveBands3rd,
      extractSound: extractSound.octaveBands3rd,
      surroundingSound: surroundingSound.octaveBands3rd,
    },
    messages
  );
}

function calcElectricHeater(
  supplyAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  freshTemperature: Amount.Amount<Quantity.Temperature>,
  freshHumidity: Amount.Amount<Quantity.RelativeHumidity>,
  exchangerResult: ExchangerResult | undefined,
  supplyOutletTemperature: Amount.Amount<Quantity.Temperature> | undefined,
  attributes: Attributes.Attributes
): ElectricDuctHeater | undefined {
  const tempToHeater = exchangerResult ? exchangerResult.supplyOutletTemperature : freshTemperature;
  const humToHeater = exchangerResult ? exchangerResult.supplyOutletHumidity ?? freshHumidity : freshHumidity;
  if (!supplyAirFlow) {
    return undefined;
  }
  const input: ElectricHeater.ElectricHeaterInput = {
    airFlow: supplyAirFlow,
    inletAirTemperature: tempToHeater,
    inletAirHumidity: humToHeater,
    requestedOutletAirTemperature: supplyOutletTemperature ?? tempToHeater,
    requestedPower: Amount.create(0, Units.Watt),
    attributes: attributes,
    heaterLimits: undefined,
  };

  if (supplyOutletTemperature) {
    return ElectricHeater.calculate("OutletAirTemperature", input);
  } else {
    return undefined;
  }
}
