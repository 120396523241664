import * as React from "react";
import type * as PU from "shared-lib/product-utils";
import type { Dispatch } from "client-lib/redux-integration";
import type { ResultIdToM3ItemNo } from "containers/frico-specification-text-epim/reducer";
import { Checkbox } from "client-lib/elements";
import type * as Search from "client-lib/search";
import * as SpecificationText from "../../frico-specification-text-epim/actions";
import type { Item } from "../../frico-specification-text-epim/types";

export function FricoCheckbox({
  resultId,
  addedSearchResultIds,
  dispatch,
  selections,
  calculationState,
  checkedChanged,
}: {
  readonly dispatch: Dispatch<SpecificationText.Action>;
  readonly selections: PU.Selections;
  readonly calculationState: Search.State;
  readonly resultId: string;
  readonly addedSearchResultIds: ResultIdToM3ItemNo;
  readonly checkedChanged?: (checked: boolean) => void;
}): JSX.Element | null {
  return (
    <Checkbox
      checked={!!addedSearchResultIds[resultId]}
      checkedChanged={(checked) => {
        if (checked) {
          const item = createSpecificationItem(resultId, calculationState);
          if (item) {
            dispatch(SpecificationText.addPersistedVariant(resultId, item, selections));
          }
        } else {
          dispatch(SpecificationText.removePersistedVariant(resultId));
        }
        if (checkedChanged) {
          checkedChanged(checked);
        }
      }}
    />
  );
}

function createSpecificationItem(resultId: string, calculationState: Search.State): Item | undefined {
  if (calculationState.type === "Calculating") {
    return undefined;
  }
  const savedResult = calculationState.results.find((r) => r.id === resultId);
  if (savedResult === undefined) {
    return undefined;
  }
  const additionalData =
    (savedResult.result.additionalData &&
      savedResult.result.additionalData.type === "aircurtain" &&
      savedResult.result.additionalData.data) ||
    undefined;
  const multi = additionalData && additionalData.twoSides === 1 ? 2 : 1;
  const quantity = (additionalData?.quantity || 1) * multi;
  const { result } = savedResult;
  return {
    sortNo: -1,
    itemName: result.itemName,
    m3ItemNo: result.m3,
    variantNo: result.variant || "",
    quantity: quantity,
    productId: result.productId,
    properties: result.properties,
    calcParams: result.calcParams,
  };
}
