import type * as C from "shared-lib/calculation";
import type { ComparedProduct } from "./types";

export type Action = AddProduct | RemoveProduct;

export interface AddProduct {
  readonly type: "compare/ADD_PRODUCT";
  readonly payload: ComparedProduct;
}

export function addProduct(productId: string, itemConfig: C.ItemConfig): AddProduct {
  return {
    type: "compare/ADD_PRODUCT",
    payload: { productId, itemConfig },
  };
}

export interface RemoveProduct {
  readonly type: "compare/REMOVE_PRODUCT";
}

export function removeProduct(): RemoveProduct {
  return {
    type: "compare/REMOVE_PRODUCT",
  };
}
