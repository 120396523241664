/* eslint-disable no-case-declarations */
import type * as Actions from "./actions";
import type { State } from "./types";

const initialState: State = {
  imageTooltip: undefined,
  uiFilterNoResults: false,
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "persist/REHYDRATE":
      return state;
    case "search-products/RESET":
      return initialState;
    case "search-products/SET_IMAGE_TOOLTIP":
      return {
        ...state,
        imageTooltip: action.payload,
      };
    case "search-products/SET_UI_FILTER_NO_RESULTS": {
      return {
        ...state,
        uiFilterNoResults: action.payload,
      };
    }
    default:
      return state;
  }
}
