import fetch from "cross-fetch";
import * as log from "loglevel";

const maxAttempts = 2;

export async function retryFetch(
  url: string | Request,
  init?: RequestInit | undefined,
  options?: {
    readonly returnResponseOnError?: boolean;
    readonly breakOnHTTPCodes?: ReadonlyArray<number>;
  }
): Promise<Response> {
  const errorResponses: Array<string> = [];
  let response = undefined;
  for (let i = 0; i < maxAttempts; ++i) {
    try {
      response = await fetch(url, init);
      if (response.ok) {
        return response;
      } else {
        const responseText = await response.text();
        errorResponses.push(`Attempt: ${i}, NotOk: ${responseText.slice(0, 100)}`);
        if (options?.breakOnHTTPCodes?.includes(response.status)) {
          break;
        }
      }
    } catch (e) {
      errorResponses.push(`Attempt: ${i}, Exception: ${e.message}`);
      log.error("Failed fetch: ", e);
    }
  }
  if (options?.returnResponseOnError && response) {
    return response;
  } else {
    throw new Error(
      `Failed to fetch ${JSON.stringify(url)} after ${maxAttempts} attempts (Error: ${errorResponses.join("\n")})`
    );
  }
}
