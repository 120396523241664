/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Matcher, Calculator, CalculatorKey, InputMapperKey, InputMapperModule, OutputMapperModule } from "./types";
import * as ElectricDuctHeaterMatcher from "./electric-duct-heater/matcher";
import * as ElectricDuctHeaterSelector from "./electric-duct-heater/selector";
import * as ElectricDuctHeaterCalculator from "./electric-duct-heater/calculator";
import * as ElectricDuctHeaterInputMapper from "./electric-duct-heater/input-mapper";
import * as ElectricDuctHeaterOutputMapper from "./electric-duct-heater/output-mapper";
import * as FritermWaterCoilMatcher from "./friterm-water-coil/matcher";
import * as FritermWaterCoilSelector from "./friterm-water-coil/selector";
import * as FritermWaterCoilCalculator from "./friterm-water-coil/calculator";
import * as FritermWaterCoilInputMapper from "./friterm-water-coil/input-mapper";
import * as FritermWaterCoilOutputMapper from "./friterm-water-coil/output-mapper";
import * as FritermDxCoilMatcher from "./friterm-dx-coil/matcher";
import * as FritermDxCoilSelector from "./friterm-dx-coil/selector";
import * as FritermDxCoilCalculator from "./friterm-dx-coil/calculator";
import * as FritermDxCoilInputMapper from "./friterm-dx-coil/input-mapper";
import * as FritermDxCoilOutputMapper from "./friterm-dx-coil/output-mapper";
import * as RoenEstWaterCoilMatcher from "./roenest-water-coil/matcher";
import * as RoenEstWaterCoilSelector from "./roenest-water-coil/selector";
import * as RoenEstWaterCoilCalculator from "./roenest-water-coil/calculator";
import * as RoenEstWaterCoilInputMapper from "./roenest-water-coil/input-mapper";
import * as RoenEstWaterCoilOutputMapper from "./roenest-water-coil/output-mapper";
import * as DbmWaterCoilMatcher from "./dbm-water-coil/matcher";
import * as DbmWaterCoilSelector from "./dbm-water-coil/selector";
import * as DbmWaterCoilCalculator from "./dbm-water-coil/calculator";
import * as DbmWaterCoilInputMapper from "./dbm-water-coil/input-mapper";
import * as DbmWaterCoilOutputMapper from "./dbm-water-coil/output-mapper";
import * as DbmDxCoilMatcher from "./dbm-dx-coil/matcher";
import * as DbmDxCoilSelector from "./dbm-dx-coil/selector";
import * as DbmDxCoilCalculator from "./dbm-dx-coil/calculator";
import * as DbmDxCoilInputMapper from "./dbm-dx-coil/input-mapper";
import * as DbmDxCoilOutputMapper from "./dbm-dx-coil/output-mapper";
import * as SystemairHeatRecoveryUnitMatcher from "./systemair-heat-recovery-unit/matcher";
import * as SystemairHeatRecoveryUnitSelector from "./systemair-heat-recovery-unit/selector";
import * as SystemairHeatRecoveryUnitCalculator from "./systemair-heat-recovery-unit/calculator";
import * as SystemairHeatRecoveryUnitInputMapper from "./systemair-heat-recovery-unit/input-mapper";
import * as SystemairHeatRecoveryUnitOutputMapper from "./systemair-heat-recovery-unit/output-mapper";
import * as FantechHeatRecoveryUnitMatcher from "./fantech-heat-recovery-unit/matcher";
import * as FantechHeatRecoveryUnitSelector from "./fantech-heat-recovery-unit/selector";
import * as FantechHeatRecoveryUnitCalculator from "./fantech-heat-recovery-unit/calculator";
import * as FantechHeatRecoveryUnitInputMapper from "./fantech-heat-recovery-unit/input-mapper";
import * as FantechHeatRecoveryUnitOutputMapper from "./fantech-heat-recovery-unit/output-mapper";
import * as SystemairFanMatcher from "./systemair-fan/matcher";
import * as SystemairFanSelector from "./systemair-fan/selector";
import * as SystemairFanCalculator from "./systemair-fan/calculator";
import * as SystemairFanInputMapper from "./systemair-fan/input-mapper";
import * as SystemairFanOutputMapper from "./systemair-fan/output-mapper";
import * as BoxFanMatcher from "./box-fan/matcher";
import * as BoxFanSelector from "./box-fan/selector";
import * as BoxFanCalculator from "./box-fan/calculator";
import * as BoxFanInputMapper from "./box-fan/input-mapper";
import * as BoxFanOutputMapper from "./box-fan/output-mapper";
import * as CentrifugalFanMatcher from "./centrifugal-fan/matcher";
import * as CentrifugalFanSelector from "./centrifugal-fan/selector";
import * as CentrifugalFanCalculator from "./centrifugal-fan/calculator";
import * as CentrifugalFanInputMapper from "./centrifugal-fan/input-mapper";
import * as CentrifugalFanOutputMapper from "./centrifugal-fan/output-mapper";
import * as BoxFanNicotraMatcher from "./box-fan-nicotra/matcher";
import * as BoxFanNicotraSelector from "./box-fan-nicotra/selector";
import * as BoxFanNicotraCalculator from "./box-fan-nicotra/calculator";
import * as BoxFanNicotraInputMapper from "./box-fan-nicotra/input-mapper";
import * as BoxFanNicotraOutputMapper from "./box-fan-nicotra/output-mapper";
import * as BoxFanYilidaMatcher from "./box-fan-yilida/matcher";
import * as BoxFanYilidaSelector from "./box-fan-yilida/selector";
import * as BoxFanYilidaCalculator from "./box-fan-yilida/calculator";
import * as BoxFanYilidaInputMapper from "./box-fan-yilida/input-mapper";
import * as BoxFanYilidaOutputMapper from "./box-fan-yilida/output-mapper";
import * as FantechFanMatcher from "./fantech-fan/matcher";
import * as FantechFanSelector from "./fantech-fan/selector";
import * as FantechFanCalculator from "./fantech-fan/calculator";
import * as FantechFanInputMapper from "./fantech-fan/input-mapper";
import * as FantechFanOutputMapper from "./fantech-fan/output-mapper";
import * as SilencerMatcher from "./silencer/matcher";
import * as SilencerSelector from "./silencer/selector";
import * as SilencerCalculator from "./silencer/calculator";
import * as SilencerInputMapper from "./silencer/input-mapper";
import * as SilencerOutputMapper from "./silencer/output-mapper";
import * as FilterMatcher from "./filter/matcher";
import * as FilterSelector from "./filter/selector";
import * as FilterCalculator from "./filter/calculator";
import * as FilterInputMapper from "./filter/input-mapper";
import * as FilterOutputMapper from "./filter/output-mapper";
import * as OtherMatcher from "./other/matcher";
import * as OtherSelector from "./other/selector";
import * as OtherCalculator from "./other/calculator";
import * as OtherInputMapper from "./other/input-mapper";
import * as OtherOutputMapper from "./other/output-mapper";
import * as AirCurtainMatcher from "./air-curtain/matcher";
import * as AirCurtainSelector from "./air-curtain/selector";
import * as AirCurtainCalculator from "./air-curtain/calculator";
import * as AirCurtainInputMapper from "./air-curtain/input-mapper";
import * as AirCurtainOutputMapper from "./air-curtain/output-mapper";

const matcherRegistry: { readonly [key: string]: Matcher } = {
  ElectricDuctHeater: ElectricDuctHeaterMatcher.match,
  FritermWaterCoil: FritermWaterCoilMatcher.match,
  FritermDxCoil: FritermDxCoilMatcher.match,
  RoenEstWaterCoil: RoenEstWaterCoilMatcher.match,
  DbmWaterCoil: DbmWaterCoilMatcher.match,
  DbmDxCoil: DbmDxCoilMatcher.match,
  SystemairHeatRecoveryUnit: SystemairHeatRecoveryUnitMatcher.match,
  FantechHeatRecoveryUnit: FantechHeatRecoveryUnitMatcher.match,
  SystemairFan: SystemairFanMatcher.match,
  FantechFan: FantechFanMatcher.match,
  Silencer: SilencerMatcher.match,
  Filter: FilterMatcher.match,
  Other: OtherMatcher.match,
  AirCurtain: AirCurtainMatcher.match,
  BoxFan: BoxFanMatcher.match,
  CentrifugalFan: CentrifugalFanMatcher.match,
  BoxFanNicotra: BoxFanNicotraMatcher.match,
  BoxFanYilida: BoxFanYilidaMatcher.match,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectorRegistry: { readonly [key: string]: Calculator<any, any> } = {
  ElectricDuctHeater: ElectricDuctHeaterSelector.select,
  FritermWaterCoil: FritermWaterCoilSelector.select,
  FritermDxCoil: FritermDxCoilSelector.select,
  RoenEstWaterCoil: RoenEstWaterCoilSelector.select,
  DbmWaterCoil: DbmWaterCoilSelector.select,
  DbmDxCoil: DbmDxCoilSelector.select,
  SystemairHeatRecoveryUnit: SystemairHeatRecoveryUnitSelector.select,
  FantechHeatRecoveryUnit: FantechHeatRecoveryUnitSelector.select,
  SystemairFan: SystemairFanSelector.select,
  FantechFan: FantechFanSelector.select,
  Silencer: SilencerSelector.select,
  Filter: FilterSelector.select,
  Other: OtherSelector.select,
  AirCurtain: AirCurtainSelector.select,
  BoxFan: BoxFanSelector.select,
  CentrifugalFan: CentrifugalFanSelector.select,
  BoxFanNicotra: BoxFanNicotraSelector.select,
  BoxFanYilida: BoxFanYilidaSelector.select,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculatorRegistry: { readonly [key: string]: Calculator<any, any> } = {
  ElectricDuctHeater: ElectricDuctHeaterCalculator.calculate,
  FritermWaterCoil: FritermWaterCoilCalculator.calculate,
  FritermDxCoil: FritermDxCoilCalculator.calculate,
  RoenEstWaterCoil: RoenEstWaterCoilCalculator.calculate,
  DbmWaterCoil: DbmWaterCoilCalculator.calculate,
  DbmDxCoil: DbmDxCoilCalculator.calculate,
  SystemairHeatRecoveryUnit: SystemairHeatRecoveryUnitCalculator.calculate,
  FantechHeatRecoveryUnit: FantechHeatRecoveryUnitCalculator.calculate,
  SystemairFan: SystemairFanCalculator.calculate,
  FantechFan: FantechFanCalculator.calculate,
  Silencer: SilencerCalculator.calculate,
  Filter: FilterCalculator.calculate,
  Other: OtherCalculator.calculate,
  AirCurtain: AirCurtainCalculator.calculate,
  BoxFan: BoxFanCalculator.calculate,
  CentrifugalFan: CentrifugalFanCalculator.calculate,
  BoxFanNicotra: BoxFanNicotraCalculator.calculate,
  BoxFanYilida: BoxFanYilidaCalculator.calculate,
};

const inputMapperModuleRegistry: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [key: string]: InputMapperModule<any, any>;
} = {
  ElectricDuctHeater: ElectricDuctHeaterInputMapper,
  FritermWaterCoil: FritermWaterCoilInputMapper,
  FritermDxCoil: FritermDxCoilInputMapper,
  RoenEstWaterCoil: RoenEstWaterCoilInputMapper,
  DbmWaterCoil: DbmWaterCoilInputMapper,
  DbmDxCoil: DbmDxCoilInputMapper,
  SystemairHeatRecoveryUnit: SystemairHeatRecoveryUnitInputMapper,
  FantechHeatRecoveryUnit: FantechHeatRecoveryUnitInputMapper,
  SystemairFan: SystemairFanInputMapper,
  FantechFan: FantechFanInputMapper,
  Silencer: SilencerInputMapper,
  Filter: FilterInputMapper,
  Other: OtherInputMapper,
  AirCurtain: AirCurtainInputMapper,
  BoxFan: BoxFanInputMapper,
  CentrifugalFan: CentrifugalFanInputMapper,
  BoxFanNicotra: BoxFanNicotraInputMapper,
  BoxFanYilida: BoxFanYilidaInputMapper,
};

const outputMapperModuleRegistry: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [key: string]: OutputMapperModule<any>;
} = {
  // New ones that includes the type of result item
  ElectricDuctHeater: ElectricDuctHeaterOutputMapper,
  FritermWaterCoil: FritermWaterCoilOutputMapper,
  FritermDxCoil: FritermDxCoilOutputMapper,
  RoenEstWaterCoil: RoenEstWaterCoilOutputMapper,
  DbmWaterCoil: DbmWaterCoilOutputMapper,
  DbmDxCoil: DbmDxCoilOutputMapper,
  SystemairHeatRecoveryUnit: SystemairHeatRecoveryUnitOutputMapper,
  FantechHeatRecoveryUnit: FantechHeatRecoveryUnitOutputMapper,
  SystemairFan: SystemairFanOutputMapper,
  FantechFan: FantechFanOutputMapper,
  Silencer: SilencerOutputMapper,
  Filter: FilterOutputMapper,
  Other: OtherOutputMapper,
  AirCurtain: AirCurtainOutputMapper,
  BoxFan: BoxFanOutputMapper,
  CentrifugalFan: CentrifugalFanOutputMapper,
  BoxFanNicotra: BoxFanNicotraOutputMapper,
  BoxFanYilida: BoxFanYilidaOutputMapper,
};

export function lookupMatcher(key: CalculatorKey): Matcher {
  const matcher = matcherRegistry[key];
  if (!matcher) {
    throw new Error(`Matcher with key '${key}' was not found in the registry.`);
  }
  return matcher;
}

export function lookupSelector<TInput, TOutput>(key: CalculatorKey): Calculator<TInput, TOutput> {
  const selector = selectorRegistry[key];
  if (!selector) {
    throw new Error(`Selector with key '${key}' was not found in the registry.`);
  }
  return selector;
}

export function lookupCalculator<TInput, TOutput>(key: CalculatorKey): Calculator<TInput, TOutput> {
  const calculator = calculatorRegistry[key];
  if (!calculator) {
    throw new Error(`Calculator with key '${key}' was not found in the registry.`);
  }
  return calculator;
}

export function lookupInputMapperModule<TQueryProps>(key: InputMapperKey): InputMapperModule<any, TQueryProps> {
  const inputMapperModule = inputMapperModuleRegistry[key];
  if (!inputMapperModule) {
    throw new Error(`InputMapperModule with key '${key}' was not found in the registry.`);
  }
  return inputMapperModule as InputMapperModule<any, TQueryProps>;
}

export function lookupOutputMapperModule(key: InputMapperKey): OutputMapperModule<any> {
  const outputMapperModule = outputMapperModuleRegistry[key];
  if (!outputMapperModule) {
    throw new Error(`OutputMapperModule with key '${key}' was not found in the registry.`);
  }
  return outputMapperModule;
}
