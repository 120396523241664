import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import type { RootState } from "../../../types";
import type { SingleVisualizerOwnProps as VisualizerOwnProps } from "../types";
import type {
  StateProps,
  Response,
  ProductTables} from "./container-component";
import {
  BoxFanRelatedProductsVisualizerContainerComponent
} from "./container-component";

function mapPropsToQuery(props: VisualizerOwnProps, __: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    productTables: productTablesQuery(props.productId),
  });
}

function productTablesQuery(productId: string): QD.DiaqMapQuery<ProductTables> {
  return QD.createMapQuery<ProductTables>({
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
    code: QP.tableByProductId(productId, "code"),
    ct_Attributes2: QP.tableByProductId(productId, "ct_Attributes2"),
    property: QP.tableByProductId(productId, "property"),
    ct_Fan: QP.tableByProductId(productId, "ct_Fan"),
    ct_StandardFan: QP.tableByProductId(productId, "ct_StandardFan"),
  });
}

const mapStateToProps: Selector<RootState, VisualizerOwnProps, StateProps> = createSelector(
  UserSettings.stateSelector,
  (userSettings) => ({
    userSettings: userSettings,
  })
);

export const BoxFanRelatedProductsVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(BoxFanRelatedProductsVisualizerContainerComponent);
