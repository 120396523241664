import { PropertyValueSet } from "@promaster-sdk/property";
import type { AnyQuantity } from "shared-lib/uom";
import type { Amount } from "uom";
import type { GetUnitFunction, GetDecimalsFunction } from "shared-lib/user-settings";
import type { MessageCode } from "../system-calculator";

const GANYMED_META_PRODUCT_ID = "8DD62170-5DA0-11E7-9E17-6B8225A95BDB";

export type PLangText = PText | PPropertyValueText | PPropertyNameText | PPropertyDescriptionText | PMessageCodeText;

export type TranslateFunction = (
  text: PLangText,
  defaultText?: string,
  getUnit?: GetUnitFunction,
  getDecimals?: GetDecimalsFunction
) => string;

export interface PText {
  readonly type: "text";
  readonly productId: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly key: string;
  readonly params: Params;
}

export interface Params {
  readonly [key: string]: string;
}

export function createText(
  key: string,
  params: Params = {},
  productId: string = GANYMED_META_PRODUCT_ID,
  variant: PropertyValueSet.PropertyValueSet = PropertyValueSet.Empty
): PText {
  return {
    type: "text",
    productId: productId,
    variant: variant,
    key: key,
    params: params,
  };
}

export interface PPropertyValueText {
  readonly type: "property_value";
  readonly productId: string;
  readonly property: string;
  readonly value: number;
}

export function createPropertyValueText(productId: string, property: string, value: number): PPropertyValueText {
  return {
    type: "property_value",
    productId: productId,
    property: property,
    value: value,
  };
}

export interface PPropertyNameText {
  readonly type: "property_name";
  readonly productId: string;
  readonly property: string;
}

export function createPropertyNameText(productId: string, property: string): PPropertyNameText {
  return {
    type: "property_name",
    productId: productId,
    property: property,
  };
}

export interface PPropertyDescriptionText {
  readonly type: "property_description";
  readonly productId: string;
  readonly property: string;
}

export function createPropertyDescriptionText(productId: string, property: string): PPropertyDescriptionText {
  return {
    type: "property_description",
    productId: productId,
    property: property,
  };
}

export interface PMessageCodeText {
  readonly type: "message_code";
  readonly productId: string;
  readonly code: MessageCode;
  readonly source: string;
  readonly params: MessageParams;
}

export function createMessageCodeText(code: number, source: string, params: MessageParams): PMessageCodeText {
  return {
    type: "message_code",
    productId: GANYMED_META_PRODUCT_ID,
    code: code,
    source: source,
    params: params,
  };
}

export interface MessageParams {
  readonly [key: string]: MessageParam;
}

export type MessageParam = AmountParam | TextParam | FieldParam;

export interface AmountParam {
  readonly type: "amount";
  readonly fieldName: string;
  readonly value: Amount.Amount<AnyQuantity>;
}

export function createAmountParam(fieldName: string, value: Amount.Amount<AnyQuantity>): AmountParam {
  return {
    type: "amount",
    fieldName,
    value,
  };
}

export interface TextParam {
  readonly type: "text";
  readonly value: string;
}

export function createTextParam(value: string): TextParam {
  return {
    type: "text",
    value,
  };
}

export interface FieldParam {
  readonly type: "field";
  readonly fieldName: string;
}

export function createFieldParam(fieldName: string): FieldParam {
  return {
    type: "field",
    fieldName,
  };
}
