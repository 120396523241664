import { Amount } from "uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import { customUnits } from "shared-lib/uom";
import type * as Messages from "../messages";
import type { Output, Input, AmbientInput } from "./types";
import { controlAsString, versionAsString } from "./attributes";

export function calculateAmbient(input: Input, _ambientInput: AmbientInput): Output {
  const { airFlow, control, version, manueverVoltage } = input;
  const messages: Array<Messages.Message> = [];
  // No calculation done, has no heater. In the future we might add air pressure drop calculation
  return {
    type: "ok",
    airCurtain: {
      airFlow: airFlow,
      control: controlAsString(control),
      version: versionAsString(version),
      manueverVoltage: manueverVoltage !== undefined ? manueverVoltage.voltage : Amount.create(230, customUnits.Volt),
      airPressureDrop: undefined,
      waterPressureDrop: undefined,
      outletAirTemperature: undefined,
      outletAirHumidity: undefined,
      inletAirHumidity: undefined,
      waterFlow: undefined,
      inletWaterTemperature: undefined,
      inletAirTemperature: undefined,
      outletWaterTemperature: undefined,
      power: undefined,
      coilCode: undefined,
      fluid: undefined,
      glycol: undefined,
      condensedWater: undefined,
      fluidSpecificHeat: undefined,
      shf: undefined,
    },
    messages,
    outCalcParams: PropertyValueSet.Empty,
  };
}
