import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import type * as Messages from "../messages";
import * as ElectricHeater from "../shared/curtain-electric-heater";
import type { Output, Input, ElectricInput } from "./types";
import { versionAsString, controlAsString } from "./attributes";

export function calculateElectric(input: Input, electricInput: ElectricInput): Output {
  const { airFlow, control, version, manueverVoltage } = input;
  const { inletAirTemperature, inletAirHumidity, maxPower, voltage } = electricInput;

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const voltageV = Amount.valueAs(customUnits.Volt, voltage.voltage);
  const phasesO = Amount.valueAs(customUnits.One, voltage.phases);
  const messages: Array<Messages.Message> = [];

  const maxPowerW = Amount.valueAs(customUnits.Watt, maxPower!);
  const electricHeaterInput: ElectricHeater.ElectricHeaterPowerInput = {
    airFlowLps: airFlowLps,
    inletAirTemperatureC: inletAirTemperatureC,
    inletAirHumidityP: inletAirHumidityP,
    maxPowerW: maxPowerW,
    powerW: maxPowerW,
    voltageV: voltageV,
    phasesO: phasesO,
  };

  const heatingResult = ElectricHeater.calculateForPower(electricHeaterInput);

  return {
    type: "ok",
    airCurtain: {
      airFlow: airFlow,
      airPressureDrop: undefined,
      waterPressureDrop: undefined,
      outletAirTemperature: Amount.create(heatingResult.outletAirTemperature, customUnits.Celsius, 1),
      inletAirHumidity: inletAirHumidity,
      outletAirHumidity: Amount.create(heatingResult.outletAirHumidity, customUnits.PercentHumidity, 1),
      waterFlow: undefined,
      inletWaterTemperature: undefined,
      inletAirTemperature: inletAirTemperature,
      outletWaterTemperature: undefined,
      power: maxPower,
      control: controlAsString(control),
      version: versionAsString(version),
      manueverVoltage: manueverVoltage !== undefined ? manueverVoltage.voltage : Amount.create(230, customUnits.Volt),
      coilCode: undefined,
      fluid: undefined,
      glycol: undefined,
      condensedWater: undefined,
      fluidSpecificHeat: undefined,
      shf: undefined,
    },
    messages,
    outCalcParams: PropertyValueSet.Empty,
  };
}
