export type Brand = "fantech" | "frico" | "systemair";
export const searchProductToBrand: { readonly [searchProductId: string]: Brand } = {
  "2B126100-5DA0-11E7-DE92-199EB319B78A": "systemair",
  "64F4BB90-5DA0-11E7-9A6A-4941B404A253": "systemair",
  "21574700-3260-11E9-C35E-2BDDE95BCFDC": "systemair",
  "A6113390-ABC0-11E7-D264-CD90F30F46EB": "fantech",
  "17C809C0-ABC0-11E7-9A24-77C5C447D5CF": "fantech",
  "AF3E4180-CEA0-11E9-F858-2BAE52FFA150": "frico",
  "1E0F5660-CEA0-11E9-ADAD-D7E33F9762E9": "systemair",
  "D9B892C0-91F0-11EA-AD66-1B9769D3529B": "fantech",
  "F673EB80-91F0-11EA-9F17-A79DAAE01A00": "fantech",
  "8DE682C0-84B0-11E7-9CEF-6DD5C1DBC161": "systemair",
};
