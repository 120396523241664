import type { ExternalConfig, ExternalResult } from "shared-lib/external-item";
import type { ExternalStatus } from "./types";

export type Action = AddItems | ItemsAdded | DismissError;

export interface AddItems {
  readonly type: "external/ADD_ITEMS";
  readonly payload: {
    readonly params: ExternalConfig;
    readonly buttonId: string;
    readonly items: ExternalResult;
  };
}

export function addItems(params: ExternalConfig, items: ExternalResult, buttonId: string): AddItems {
  return {
    type: "external/ADD_ITEMS",
    payload: { params, items, buttonId },
  };
}

export interface ItemsAdded {
  readonly type: "external/ITEMS_ADDED";
  readonly payload: ExternalStatus;
}

export function itemsAdded(externalStatus: ExternalStatus): ItemsAdded {
  return {
    type: "external/ITEMS_ADDED",
    payload: externalStatus,
  };
}

export interface DismissError {
  readonly type: "external/DISMISS_ERROR";
  readonly payload: boolean;
}

export function dismissError(): DismissError {
  return {
    type: "external/DISMISS_ERROR",
    payload: true,
  };
}
