import * as React from "react";
import type * as SC from "shared-lib/system-calculator";
import type { GetUnitFunction, GetDecimalsFunction } from "shared-lib/user-settings";
import * as CFD from "shared-lib/system-calculator/shared/centrifugal-fan-diagram";
import * as AbstractChart from "shared-lib/abstract-chart";
import * as AbstractImage from "abstract-image";
import type * as Texts from "shared-lib/language-texts";
import * as Style from "shared-lib/style";
import type { FormatNumberFunction } from "shared-lib/utils";

export interface Props {
  readonly results: SC.ResultItemOutputMap;
  readonly getUnit: GetUnitFunction;
  readonly getDecimals: GetDecimalsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly translate: Texts.TranslateFunction;
}

const diagramSize = AbstractImage.createSize(200, 150);

export function CentrifugalFanDiagram(props: Props): React.ReactElement {
  const { results, getUnit, translate, getDecimals } = props;

  const maybeResultItem = results.CentrifugalFan;
  const resultItem =
    maybeResultItem &&
    maybeResultItem.type === "OutputMapperSuccess" &&
    (maybeResultItem.result as SC.CentrifugalFanResultItem);
  const centrifugalFanResult = resultItem && resultItem.type === "CentrifugalFan" && resultItem.value;
  if (!centrifugalFanResult) {
    return <div />;
  }
  const { air, motor } = centrifugalFanResult;

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);
  const powerUnit = getUnit("power", "Power");

  const { pressure, power } = CFD.generateCharts({
    fan: air,
    motor,
    flowUnit,
    pressureUnit,
    powerUnit,
    translate,
    showLineLabels: false,
    style: Style.diagramCentrifugalFan,
    powerCurves: centrifugalFanResult.powerCurves,
    classCurves: centrifugalFanResult.classCurves,
  });

  const pressureWithDecimals = {
    ...pressure,
    linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
    linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
  };

  const powerWithDecimals = {
    ...power,
    linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
    linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
  };

  const abstractImagePressure = AbstractChart.renderChart(pressureWithDecimals, diagramSize, {
    allowTextOverlap: true,
    formatNumber: props.formatNumber.format,
  });
  const renderedPressure = AbstractImage.createReactSvg(abstractImagePressure);

  const abstractImagePower = AbstractChart.renderChart(powerWithDecimals, diagramSize, {
    allowTextOverlap: true,
    formatNumber: props.formatNumber.format,
  });
  const renderedPower = AbstractImage.createReactSvg(abstractImagePower);

  return (
    <div>
      {renderedPressure} {renderedPower}
    </div>
  );
}
