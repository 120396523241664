import * as C from "shared-lib/calculation";
import { PropertyValueSet } from "@promaster-sdk/property";
import type * as Actions from "./actions";
import type { State } from "./types";

const initialState: State = {
  config: C.emptyItemConfig,
  calculationError: false,
  changingAccessory: undefined,
  setInitialAirFlowStep: true,
  hideErrors: true,
  showComparison: false,
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "persist/REHYDRATE":
      return state;

    case "product/RESET":
      return initialState;

    case "product/SET_CONFIG":
      return {
        calculationError: false,
        config: action.payload,
        changingAccessory: undefined,
        setInitialAirFlowStep: true,
        hideErrors:
          PropertyValueSet.isEmpty(action.payload.calcParams) && PropertyValueSet.isEmpty(action.payload.properties),
        showComparison: false,
      };
    case "product/SET_PROPERTIES":
      return {
        ...state,
        calculationError: false,
        config: {
          ...state.config,
          properties: action.payload,
        },
        setInitialAirFlowStep: false,
        hideErrors: false,
      };
    case "product/SET_CALC_PARAMS":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        setInitialAirFlowStep: false,
        config: {
          ...state.config,
          calcParams: action.payload,
        },
      };
    case "product/SET_SECOND_CALC_PARAMS":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        setInitialAirFlowStep: false,
        config: {
          ...state.config,
          secondCalcParams: action.payload,
        },
      };
    case "product/ADD_ACCESSORY":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        config: {
          ...state.config,
          accessories: [...state.config.accessories, action.payload],
        },
      };
    case "product/REMOVE_ACCESSORY":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        config: {
          ...state.config,
          accessories: removeAccessory(state.config.accessories, [action.payload]),
        },
      };
    case "product/CHANGE_ACCESSORY":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        changingAccessory: action.payload,
      };
    case "product/UPDATE_ACCESSORY":
      return {
        ...state,
        hideErrors: false,
        calculationError: false,
        changingAccessory: undefined,
        config: {
          ...state.config,
          accessories: removeAccessory(
            state.config.accessories,
            state.config.accessories.filter((a) => a.parentId === action.payload.id).map((a) => a.id)
          ).map((a) => (a.id !== action.payload.id ? a : action.payload)),
        },
      };
    case "product/UPDATE_ACCESSORY_CALC_PARAMS":
      return {
        ...state,
        config: {
          ...state.config,
          accessories: state.config.accessories.map((a) =>
            a.id === action.payload.accessoryId ? { ...a, calcParams: action.payload.calcParams } : a
          ),
        },
      };
    case "product/CALCULATION_ERROR":
      return {
        ...state,
        hideErrors: false,
        calculationError: action.payload,
      };
    case "product/SET_SHOW_COMPARISON":
      return {
        ...state,
        showComparison: action.payload,
      };
    default:
      return state;
  }
}

function removeAccessory(
  accessories: ReadonlyArray<C.Accessory>,
  accessoryIds: ReadonlyArray<string>
): ReadonlyArray<C.Accessory> {
  if (accessoryIds.length > 0) {
    let updatedAccessories: ReadonlyArray<C.Accessory> = accessories.filter(
      (a) => !accessoryIds.some((id) => id === a.id)
    );
    updatedAccessories = removeAccessory(
      updatedAccessories,
      updatedAccessories.filter((a) => accessoryIds.some((id) => id === a.parentId)).map((a) => a.id)
    );
    return updatedAccessories;
  } else {
    return accessories;
  }
}
