/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { AnyQuantity } from "shared-lib/uom";
import type * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import { PropertyValueSet } from "@promaster-sdk/property";
import type * as UserSettings from "shared-lib/user-settings";
import type {
  Property as PropertyType,
  TranslatePropertyName,
  TranslatePropertyValue,
} from "client-lib/properties-selector";
import * as UserSettingsClient from "client-lib/user-settings";
import {
  AmountPropertySelector,
  CheckboxPropertySelector,
  ComboboxPropertySelector,
  TextboxPropertySelector,
  PropertyLabel,
  Property,
  CheckboxPropertySelectorLabel,
} from "client-lib/properties-selector-elements";
import type { Dispatch } from "client-lib/redux-integration";
import type { FormatNumberFunction } from "shared-lib/utils";
import type { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import { RadioGroupPropertySelector } from "./radio-group-property-selector";
import { MultiPropertySelector } from "./multi-property-selector";
import { MultiHorizontalPropertySelector } from "./multi-horizontal-property-selector";
import * as Shared from "./shared";

export interface PropertySelectorProps {
  readonly productId: string;
  readonly property: PropertyType;
  readonly index: number;
  readonly selections: Shared.Selections;
  readonly selectionsChanged: Shared.SelectionsChanged;
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly hiddenProperties: ReadonlyArray<string>;
  readonly translate: Texts.TranslateFunction;
  readonly translatePropertyName?: TranslatePropertyName;
  readonly translatePropertyValue?: TranslatePropertyValue;
  readonly getUnit: UserSettings.GetUnitFunction;
  readonly getUnits: UserSettings.GetUnitsFunction;
  readonly getDecimals: UserSettings.GetDecimalsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly selectorType: QP.PropertySelectorType | undefined;
  readonly fieldName: string | undefined;
  readonly dispatch: Dispatch<UserSettingsClient.Action>;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly showAdvanced: boolean;
  readonly images: Shared.Images;
  readonly hideInvalidValues?: boolean;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly comboBoxImageCSSFilter?: string;
}

export function PropertySelector(props: PropertySelectorProps): JSX.Element | null {
  const {
    productId,
    property,
    index,
    variants,
    selections,
    selectionsChanged,
    translate,
    getUnit,
    getUnits,
    getDecimals,
    formatNumber,
    selectorType,
    fieldName,
    dispatch,
    filterPrettyPrint,
    showAdvanced,
    images,
    hideInvalidValues,
    comboBoxImageCSSFilter,
    translatePropertyName,
    hideEmptyUnitSelectors,
    hiddenProperties,
  } = props;

  if (hiddenProperties.find((hp) => hp === property.name)) {
    // When we want to hide the property from Promaster, we remove it completely
    return null;
  }

  const finalSelectorType: QP.SelectorType = selectorType
    ? selectorType.type
    : property.quantity === "Discrete"
    ? "ComboBox"
    : property.quantity === "Text"
    ? "TextBox"
    : "AmountField";

  const fieldNameOrPropertyName = fieldName ?? property.name;
  const unit = getUnit(fieldNameOrPropertyName, property.quantity as AnyQuantity);
  const decimalCount = getDecimals(fieldNameOrPropertyName, unit);
  const label = translatePropertyName
    ? translatePropertyName(property.name)
    : translate(Texts.property_name(productId, property.name));
  const isRequired = isPropertyRequired(finalSelectorType, property);
  return (
    <Property selectorType={finalSelectorType}>
      {finalSelectorType === "AmountField" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <AmountPropertySelector
            value={Shared.getAmount(property.name, selections)}
            unit={unit}
            validationFilter={property.validation_filter}
            getUnits={getUnits}
            decimalCount={decimalCount}
            fieldName={fieldNameOrPropertyName}
            translate={translate}
            validateFilter={(validationFilter) => Shared.isFilterValid(variants, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
            index={index}
            hideEmptyUnitSelector={hideEmptyUnitSelectors}
            onValueChange={(newAmount) => selectionsChanged(Shared.setAmount(property.name, newAmount, selections))}
            unitChanged={(u) => dispatch(UserSettingsClient.setFieldUnit(fieldNameOrPropertyName, u))}
            unitCleared={() => dispatch(UserSettingsClient.clearFieldUnit(fieldNameOrPropertyName))}
            formatNumber={formatNumber}
          />
        </>
      ) : finalSelectorType === "Checkbox" ? (
        <>
          <CheckboxPropertySelectorLabel label={label} property={property} />
          <CheckboxPropertySelector
            productId={productId}
            property={property}
            value={Shared.getIntegers(property.name, selections)?.some((v) => v === 1) ? 1 : undefined}
            onChange={(newValue) => selectionsChanged(Shared.setIntegers(property.name, [newValue], selections))}
            images={images}
            translate={translate}
            validateFilter={(validationFilter) => Shared.isFilterValid(variants, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
          />
        </>
      ) : finalSelectorType === "ComboBox" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <ComboboxPropertySelector
            productId={productId}
            property={property}
            value={(Shared.getIntegers(property.name, selections) || [])[0]}
            onChange={(newValue) => selectionsChanged(Shared.setIntegers(property.name, [newValue], selections))}
            translate={translate}
            validateFilter={(validationFilter) => Shared.isFilterValid(variants, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
            showAdvanced={showAdvanced}
            images={images}
            hideInvalidValues={hideInvalidValues}
            imageCSSFilter={comboBoxImageCSSFilter}
          />
        </>
      ) : finalSelectorType === "RadioGroup" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <RadioGroupPropertySelector
            productId={productId}
            property={property}
            variants={variants}
            selections={selections}
            selectionsChanged={selectionsChanged}
            translate={translate}
            filterPrettyPrint={filterPrettyPrint}
            showAdvanced={showAdvanced}
            images={images}
          />
        </>
      ) : finalSelectorType === "TextBox" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <TextboxPropertySelector
            value={Shared.getText(property.name, selections) || ""}
            onChange={(newValue) => selectionsChanged(Shared.setText(property.name, newValue, selections))}
          />
        </>
      ) : finalSelectorType === "Multi" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <MultiPropertySelector
            productId={productId}
            property={property}
            variants={variants}
            selections={selections}
            selectionsChanged={selectionsChanged}
            translate={translate}
            filterPrettyPrint={filterPrettyPrint}
            showAdvanced={showAdvanced}
            images={images}
          />
        </>
      ) : finalSelectorType === "MultiHorizontal" ? (
        <MultiHorizontalPropertySelector
          productId={productId}
          property={property}
          variants={variants}
          selections={selections}
          selectionsChanged={selectionsChanged}
          translate={translate}
          filterPrettyPrint={filterPrettyPrint}
          showAdvanced={showAdvanced}
          images={images}
        />
      ) : (
        <span />
      )}
    </Property>
  );
}

function isPropertyRequired(selectorType: QP.SelectorType, property: PropertyType): boolean {
  if (selectorType === "AmountField") {
    return !Shared.isFilterValid([PropertyValueSet.Empty], property.validation_filter);
  } else {
    return false;
  }
}
