/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable functional/no-this-expression */
import * as React from "react";
import type { Unit } from "uom";
import { Amount } from "uom";
import * as debounce from "lodash.debounce";
import type { FormatNumberFunction, SeparatorSymbol } from "shared-lib/utils";
import { localizedToString } from "shared-lib/utils";
import type { AnyQuantity } from "shared-lib/uom";
import { withTw } from "./with-tw";

const ErrorWrapper = withTw("div", "w-full", (p: { readonly isValid: boolean }) =>
  !p.isValid ? "was-invalidated" : ""
);

export interface AmountInputBoxProps {
  readonly key?: string;
  readonly value: Amount.Amount<AnyQuantity> | undefined;
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly decimalCount: number;
  readonly notNumericMessage: string;
  readonly isRequiredMessage: string;
  readonly errorMessage: string;
  readonly isValid?: boolean;
  readonly readOnly: boolean;
  readonly formatNumber: FormatNumberFunction;
  readonly onValueChange: (newAmount: Amount.Amount<AnyQuantity> | undefined) => void;
  readonly debounceTime: number;
  readonly index?: number;
}

export interface State {
  readonly textValue: string;
  readonly hasFocus: boolean;
}

// eslint-disable-next-line functional/no-class
export class AmountInputBox extends React.Component<AmountInputBoxProps, State> {
  constructor(props: AmountInputBoxProps) {
    super(props);
    this.state = {
      textValue: "",
      hasFocus: false,
    };
  }

  debouncedOnValueChange = debounce(() => {
    this.onValueChange(true);
  }, this.props.debounceTime);

  UNSAFE_componentWillMount(): void {
    const formattedValue = getString(
      this.props.value,
      this.props.decimalCount,
      this.props.unit,
      this.props.formatNumber
    );
    this.setState({ textValue: formattedValue });
  }

  UNSAFE_componentWillReceiveProps(nextProps: AmountInputBoxProps): void {
    const formattedValue = getString(nextProps.value, nextProps.decimalCount, nextProps.unit, this.props.formatNumber);
    if (!this.state.hasFocus) {
      this.setState({ textValue: formattedValue });
    }
  }

  render(): React.ReactElement<AmountInputBoxProps> {
    const { readOnly } = this.props;
    const errorMessage = this.getInputErrorMessage() ?? this.props.errorMessage;

    return (
      <ErrorWrapper isValid={!!this.props.isValid && !errorMessage}>
        <input
          key="input"
          className="form-input pt-4 pb-4 pl-8 pr-8 h-36 w-full print:border-white"
          value={this.state.textValue}
          onChange={(e) => {
            this.onChange(e);
          }}
          title={errorMessage}
          disabled={readOnly}
          onFocus={() => this.setState({ hasFocus: true })}
          onBlur={() => {
            this.onValueChange(false); // Update value to prevent UNSAFE_componentWillReceiveProps from overwriting with the old value
            this.debouncedOnValueChange.cancel();
            this.setState({ hasFocus: false });
          }}
          tabIndex={0}
        />
      </ErrorWrapper>
    );
  }

  onChange(e: React.FormEvent<HTMLInputElement>): void {
    const textValue = e.currentTarget.value;
    this.setState({ textValue: textValue });
    this.debouncedOnValueChange();
  }

  getInputErrorMessage(): string | undefined {
    const { isRequiredMessage, unit, notNumericMessage, formatNumber } = this.props;
    const { textValue } = this.state;
    const unformatedValue = localizedToString(textValue, formatNumber.getFormat);
    const amount = getAmount(unformatedValue, unit, formatNumber);
    // Check if blank and if required or not
    if (unformatedValue.trim() === "" && isRequiredMessage) {
      // The user has not entred anything, but a value was required
      return isRequiredMessage;
    }
    if (unformatedValue.trim() !== "" && !amount && isRequiredMessage) {
      // The user has entered something, but it could not be converted to an amount (=was not numeric)
      return notNumericMessage;
    }
    return undefined;
  }

  onValueChange(isDebounce: boolean): void {
    const errorMessage = this.getInputErrorMessage();
    const valueChanged =
      getString(this.props.value, this.props.decimalCount, this.props.unit, this.props.formatNumber) !==
      this.state.textValue;
    if (!errorMessage && valueChanged) {
      const { formatNumber } = this.props;
      const unformatedValue = localizedToString(this.state.textValue, formatNumber.getFormat);
      if (!isDebounce) {
        const textValue = formatNumber.format(unformatedValue);
        this.setState({ textValue: textValue });
      }
      const amount = getAmount(unformatedValue, this.props.unit, formatNumber);
      this.props.onValueChange(amount);
    }
  }
}

function getString<T extends AnyQuantity>(
  amount: Amount.Amount<T> | undefined,
  decimalCount: number,
  unit: Unit.Unit<T>,
  formatNumber: FormatNumberFunction
): string {
  if (!amount) {
    return "";
  }

  const valueToUse = Amount.valueAs(unit, amount);
  return formatNumber.format(valueToUse.toFixed(decimalCount));
}

function getAmount<T extends AnyQuantity>(
  text: string,
  unit: Unit.Unit<T>,
  formatNumber: FormatNumberFunction
): Amount.Amount<T> | undefined {
  if (!text || text.length === 0) {
    return undefined;
  }

  const decimalSeparator = formatNumber.getFormat.decimalSeparator;
  const parsedFloatValue = parseFloat(text.replace(decimalSeparator, "."));
  if (!Number.isFinite(parsedFloatValue)) {
    return undefined;
  }
  const decimals = getDecimalCountFromString(text, decimalSeparator);
  return Amount.create(parsedFloatValue, unit, decimals);
}

function getDecimalCountFromString(stringValue: string, decimalSeparator: SeparatorSymbol): number {
  const decimalIndex = stringValue.indexOf(decimalSeparator);
  if (decimalIndex >= 0) {
    return stringValue.length - decimalIndex - 1;
  }
  return 0;
}
