import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Types from "../types";
import type { ElectricDuctHeater } from "../result-items-types";
import * as ElectricHeater from "../shared/electric-heater";
import * as Messages from "../messages";
import type { Input } from "./types";
import type { SortValue } from "../types";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "ElectricDuctHeater";

export async function calculate(input: Input): Promise<Types.CalculatorResult<ElectricDuctHeater>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    calculationMethod,
    outletAirTemperature,
    power,
    codes,
    attributes,
    heaterLimits,
    calcParams,
  } = input;

  let heatingResult: ElectricDuctHeater | undefined = undefined;
  const electricHeaterInput: ElectricHeater.ElectricHeaterInput = {
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,
    requestedOutletAirTemperature: outletAirTemperature,
    requestedPower: power,
    attributes: attributes,
    heaterLimits: heaterLimits,
  };

  let newCalcParams = calcParams;

  const sortValues: Array<SortValue> = [];
  if (calculationMethod === "OutletAirTemperature" && outletAirTemperature) {
    heatingResult = ElectricHeater.calculate("OutletAirTemperature", electricHeaterInput);
    if (heatingResult) {
      const reqAirTempOut = Amount.valueAs(customUnits.Celsius, outletAirTemperature);
      const airTempOut = Amount.valueAs(customUnits.Celsius, heatingResult.outletAirTemperature);
      sortValues.push({ value: Math.abs(airTempOut - reqAirTempOut), descending: false });
    }
  } else if (calculationMethod === "Power" && power) {
    heatingResult = ElectricHeater.calculate("Power", electricHeaterInput);
    if (heatingResult) {
      const airTempOut = Amount.valueAs(customUnits.Celsius, heatingResult.outletAirTemperature);
      sortValues.push({ value: Math.abs(airTempOut - 25), descending: false });
    }
  } else if (calculationMethod === "MaxPower") {
    heatingResult = ElectricHeater.calculate("MaxPower", electricHeaterInput);
    if (heatingResult) {
      const airTempOut = Amount.valueAs(customUnits.Celsius, heatingResult.outletAirTemperature);
      if (PropertyValueSet.getInteger("is_accessory_search", calcParams) === 1) {
        newCalcParams = PropertyValueSet.setAmount(
          "outletAirTemperature",
          heatingResult.outletAirTemperature,
          newCalcParams
        );
      }
      sortValues.push({ value: Math.abs(airTempOut - 25), descending: false });
    }
  }
  sortValues.push({ value: codes.code, descending: false });
  if (!heatingResult) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const messages = [];

  messages.push(...heatingResult.messages);

  messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, heatingResult.pressureDrop));

  return Types.createCalculatorSuccess(sortValues, heatingResult, messages, newCalcParams);
}
