import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as I from "shared-lib/interpolation";
import * as CI from "../shared/calculation-inputs";
import type { Curve } from "../result-items-types";

export type BathroomOption = "toilet" | "shower" | "regular_tub" | "steam_shower" | "jacuzzi_tub_or_whirpool";

export const allBathroomOptions: ReadonlyArray<BathroomOption> = [
  "toilet",
  "shower",
  "regular_tub",
  "steam_shower",
  "jacuzzi_tub_or_whirpool",
];

export interface BathroomInput {
  readonly length: Amount.Amount<Quantity.Length>;
  readonly width: Amount.Amount<Quantity.Length>;
  readonly height: Amount.Amount<Quantity.Length>;
  readonly selectedOptions: ReadonlyArray<BathroomOption>;
}

export interface BathroomSearchInput {
  readonly bathrooms: ReadonlyArray<BathroomInput>;
}

export function isBathroomFanSearch(variant: PropertyValueSet.PropertyValueSet): boolean {
  return PropertyValueSet.getInteger("application", variant) === 10;
}

function getNumBathrooms(pvs: PropertyValueSet.PropertyValueSet): number {
  const value = PropertyValueSet.getInteger("num_bathrooms", pvs);
  if (!value) {
    return 1;
  }
  return Math.min(Math.max(value, 1), 2);
}

export function getBathroomInput(calcParams: PropertyValueSet.PropertyValueSet): BathroomSearchInput | undefined {
  const bathrooms: Array<BathroomInput> = [];
  for (let i = 1; i <= getNumBathrooms(calcParams); i++) {
    const length = PropertyValueSet.getAmount<Quantity.Length>(bathroomParam(i, "length"), calcParams);
    const width = PropertyValueSet.getAmount<Quantity.Length>(bathroomParam(i, "width"), calcParams);
    const height = PropertyValueSet.getAmount<Quantity.Length>(bathroomParam(i, "height"), calcParams);
    const selectedOptions = allBathroomOptions.filter(
      (o) => PropertyValueSet.getInteger(bathroomParam(i, `${o}_option`), calcParams) === 1
    );
    if (!length || !width || !height) {
      continue;
    }
    bathrooms.push({
      length,
      width,
      height,
      selectedOptions,
    });
  }
  if (bathrooms.length === 0) {
    return undefined;
  }
  return {
    bathrooms: bathrooms,
  };
}

export function calculateBathroomFanAirFlow(input: BathroomSearchInput): Amount.Amount<Quantity.VolumeFlow> {
  let airFlowCfm = 0;
  for (const b of input.bathrooms) {
    const length = Amount.valueAs(Units.Foot, b.length);
    const width = Amount.valueAs(Units.Foot, b.width);
    const height = Amount.valueAs(Units.Foot, b.height);
    const volume = length * width * height;
    const additionalAirflow =
      b.selectedOptions.includes("jacuzzi_tub_or_whirpool") || b.selectedOptions.includes("steam_shower") ? 100 : 0;
    const roomAirFlow = volume * (8 / 60) + additionalAirflow;
    airFlowCfm += roomAirFlow;
  }
  const airFlow = Amount.create(airFlowCfm, Units.CubicFeetPerMinute);
  return airFlow;
}

export function updateCalcParams(
  calcParams: PropertyValueSet.PropertyValueSet,
  airFlow: Amount.Amount<Quantity.VolumeFlow>,
  pressure: Amount.Amount<Quantity.Pressure>
): PropertyValueSet.PropertyValueSet {
  let updatedCalcParams = calcParams;

  // Rest so max efficiency point is used
  updatedCalcParams = PropertyValueSet.setAmount("airFlow", airFlow, updatedCalcParams);
  updatedCalcParams = PropertyValueSet.setAmount("externalPressure", pressure, updatedCalcParams);

  // Fill in default calc params for input's that not visible in bathroom search mode
  updatedCalcParams = PropertyValueSet.setInteger("airDensityCalculationMethod", 0, updatedCalcParams);
  updatedCalcParams = PropertyValueSet.setAmount(
    "airDensity",
    Amount.create(1.204, Units.KilogramPerCubicMeter),
    updatedCalcParams
  );

  // Bathroom fans should always have fan control None
  updatedCalcParams = PropertyValueSet.setInteger("speedControl", 1, updatedCalcParams);

  return updatedCalcParams;
}

function bathroomParam(index: number, name: string): string {
  return `bathroom${index}_${name}`;
}

export interface BathroomFanWorkingPoint {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressure: Amount.Amount<Quantity.Pressure>;
}

export function findBathroomFanWorkingPoint(
  input: BathroomSearchInput,
  airData: ReadonlyArray<QP.AirData>
): BathroomFanWorkingPoint | undefined {
  const pressureCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    airData.filter((p) => p.param === "Ps_v"),
    []
  );

  if (pressureCurves.length === 0) {
    return undefined;
  }

  const maxPressureCurve = pressureCurves[pressureCurves.length - 1];

  const airFlow = calculateBathroomFanAirFlow(input);
  const airFlowLps = Amount.valueAs(Units.LiterPerSecond, airFlow);

  if (airFlowLps < maxPressureCurve.spline.xMin || airFlowLps < maxPressureCurve.workMin) {
    return undefined;
  }

  // Air flow higher than the working range of the fan, show a warning
  // by returning a working point outside the range
  // Use the same logic as the fan calculation to find the lowest valid pressure
  if (airFlowLps > maxPressureCurve.spline.xMax) {
    const lowestPressuresPa = pressureCurves
      .map((c) => findLowestPressure(airFlowLps, c))
      .filter((p): p is number => p !== undefined);
    const lowestPressurePa = Math.max(...lowestPressuresPa);
    if (lowestPressurePa === undefined) {
      return undefined;
    }
    const lowestPressure = Amount.create(lowestPressurePa, Units.Pascal);
    return {
      airFlow: airFlow,
      pressure: lowestPressure,
    };
  }

  const pressurePa = I.splineGetPoint(airFlowLps, maxPressureCurve.spline);
  if (pressurePa === undefined) {
    return undefined;
  }
  const pressure = Amount.create(pressurePa, Units.Pascal);

  return {
    airFlow: airFlow,
    pressure: pressure,
  };
}

function findLowestPressure(airflowLps: number, curve: Curve): number | undefined {
  const highX = Math.min(curve.spline.xMax, curve.workMax);
  const lowY = I.splineGetPoint(highX, curve.spline);
  if (lowY === undefined) {
    return undefined;
  }
  const clampedLowY = Math.max(lowY, 0.1);
  const k = clampedLowY / highX ** 2;
  const lowestPressure = k * airflowLps ** 2;
  return lowestPressure;
}
