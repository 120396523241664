import type * as Utils from "shared-lib/utils";
import type { PreFilterFunction, PostFilterFunction } from "../types";
import * as AirCurtains from "./air-curtains";
import * as BoxFans from "./box-fans";
import * as CentrifugalFans from "./centrifugal-fans";
import * as FantechHru from "./fantech-hru";
import * as FantechBathroomFans from "./fantech-bathroom-fans";
import * as AmcaCertified from "./amca-certified";

export const preFilterFunctions: Utils.Map<PreFilterFunction> = {
  AirCurtainsEpim: AirCurtains.preFilter,
  FantechBathroomFans: FantechBathroomFans.preFilter,
  AmcaCertified: AmcaCertified.preFilter,
};

export const postFilterFunctions: Utils.Map<PostFilterFunction> = {
  AirCurtainsEpim: AirCurtains.postFilter,
  BoxFans: BoxFans.postFilter,
  CentrifugalFans: CentrifugalFans.postFilter,
  FantechHru: FantechHru.postFilter,
};
