import { Amount } from "uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as log from "loglevel";
import * as Types from "../types";
import * as Messages from "../messages";
import type { WaterCoil } from "../result-items-types";
import * as Dbm from "../dll/dbm";
import * as PressureDrop from "../shared/pressure-drop";
import type { Input, ParamsToUpdate } from "./types";
import * as Area from "../shared/area";
import * as Attributes from "../shared/attributes";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "DbmWaterCoilCalculator";

const probeFaceVelocities = [1, 3];

export async function calculate(input: Input): Promise<Types.CalculatorResult<WaterCoil>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    inletWaterTemperature,
    calculationMethod,
    outletWaterTemperature,
    waterFlow,
    outletAirTemperature,
    codes,
    waterCoilLimits,
    attributes,
  } = input;

  const airFlowCmph = Amount.valueAs(customUnits.CubicMeterPerHour, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const inletWaterTemperatureC = Amount.valueAs(customUnits.Celsius, inletWaterTemperature);
  const outletWaterTemperatureC = outletWaterTemperature
    ? Amount.valueAs(customUnits.Celsius, outletWaterTemperature)
    : null;
  const waterFlowLps = waterFlow ? Amount.valueAs(customUnits.LiterPerSecond, waterFlow) : null;
  const outletAirTemperatureC = outletAirTemperature ? Amount.valueAs(customUnits.Celsius, outletAirTemperature) : null;

  const target =
    calculationMethod === 0 ? "FluidTempOut" : calculationMethod === 2 ? "AirOutTemperature" : "FluidFlow_dm3s";

  try {
    const dbmInput: Dbm.DbmInput = {
      target: target,
      inputsData: {
        CoilType: Attributes.getIntOrThrow("DLL-input-dbm-coil-type", attributes),
        AirInTemperature: inletAirTemperatureC,
        AirInHumidity: inletAirHumidityP,
        AirInAbsHumidity: 0,
        AirInWetBulb: 0,
        AirInFlowStandard: 0,
        AirInFlowNormal: 0,
        AirInFlowActual: airFlowCmph,
        HeaderMaterial: Attributes.getIntOrThrow("DLL-input-dbm-header-material", attributes),
        AirWeight_kgh: 0,
        AirWeight_kgs: 0,
        NoRows: Attributes.getIntOrThrow("DLL-input-dbm-rows", attributes),
        NoTubes: Attributes.getIntOrThrow("DLL-input-dbm-tubes", attributes),
        FinPitch: Attributes.getFloatOrThrow("DLL-input-dbm-fin-pitch", attributes),
        NoCircuits: Attributes.getIntOrThrow("DLL-input-dbm-circuits", attributes),
        CoilWidth: Attributes.getFloatOrThrow("DLL-input-dbm-width", attributes),
        CoilHeight: Attributes.getFloatOrThrow("DLL-input-dbm-height", attributes),
        Capacity_kCalh: 0,
        CapacitykW_kW: 0,
        AirOutTemperature: outletAirTemperatureC ?? 0,
        AirInVelocityStandard: 0,
        AirInVelocityNormal: 0,
        AirInVelocityActual: 0,
        FluidFlow_dm3s: waterFlowLps ?? 0,
        FluidFlow2_dm3h: 0,
        FluidTempIn: inletWaterTemperatureC,
        FluidTempOut: outletWaterTemperatureC ?? 0,
        FluidWeight: 0,
        FluidWeightkgH: 0,
        MaxAllowedFluidSidePressureDrop: 0,
        WorkingPressure_bar: 1.013,
        WorkingPressure_atm: 0,
        WorkingPressure_kpa: 0,
        WorkingPressure_kgm2: 0,
        WorkingPressure_mmHg: 0,
        WorkingPressureMMH20: 0,
        TubeSideFoulingFactor: 0,
        GlycolType: 0,
        GlycolPercentageByVolume: 0,
        GlycolPercentageByWieght: 0,
        SafetyFactorOnSurface: 0,
        SafetyFactorOnCapacity: 0,
        FluidType: Attributes.getIntOrThrow("DLL-input-dbm-fluid-type", attributes),
        FluidDensity: 0,
        FluidVicosity: 0,
        FluidSpecificHeat: 0,
        FluidConductivity: 0,
        FrameCode: Attributes.getIntOrThrow("DLL-input-dbm-frame-code", attributes),
        PriceMultiplier: 1,
        FoulingFactorFinsSide: 0,
        CondensingPressure: 0,
        CondensingTemperature: 0,
        EvaporatingPressure: 0,
        EvaporatingTemperature: 0,
        SubCooling: 0,
        SuperHeating: 0,
        TypeOfCalculation: 0,
        FinsMaterial: Attributes.getIntOrThrow("DLL-input-dbm-fins-material", attributes),
        FinsThickness: Attributes.getFloatOrThrow("DLL-input-dbm-fins-thickness", attributes),
        TubeThickness: Attributes.getFloatOrThrow("DLL-input-dbm-tube-thickness", attributes),
        Flanges: 0,
        TubeMaterial: Attributes.getIntOrThrow("DLL-input-dbm-tube-material", attributes),
        CustomerField1: 0,
        CustomerField2: 0,
        CustomerField3: 0,
        ConnectionSide: 0,
        OveralldimensionWidth: 0,
        ARIVersion: 0,
        TypeOfFins: 0,
        AutomaticCoilSelection: 0,
        NumberOfGasCircuits: 0,
        OverallDimensionHeight: 0,
        SteamCoilExecutionType: 0,
        ElectroTinnedAfterManufacturing: 0,
        CalculationMode: 0,
        InletManifoldDiameter: Attributes.getIntOrThrow("DLL-input-dbm-inlet-manifold-diameter", attributes),
        OutletManifoldDiameter: Attributes.getIntOrThrow("DLL-input-dbm-outled-manifold-diameter", attributes),
        BasinType: 0,
        DropEliminator: 0,
        PackingType: 0,
        MinheightofBottomFrameMetalSheet: 0,
        MinheightofTopFrameMetalSheet: 0,
        TypeOfFlow: 0,
      },
    };
    const result = await Dbm.calculate(dbmInput);
    if (!result) {
      return Types.createCalculatorError([Messages.Exception(source, "DBM request failed")]);
    } else if (result.errorcode > 0) {
      log.warn(`DBM errorcode: ${result.errorcode}`);
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }

    const refOutTemp = inletWaterTemperatureC < inletAirTemperatureC ? 18 : 25;
    const targetOutTemp = (calculationMethod === 2 ? outletAirTemperatureC : refOutTemp) ?? 20;

    const messages: Array<Messages.Message> = [];

    if (result.gasvelocity > Amount.valueAs(customUnits.MeterPerSecond, waterCoilLimits.max_air_velocity)) {
      messages.push(Messages.Error_AirVelocityTooHigh(source, waterCoilLimits.max_air_velocity));
    }

    if (result.airsidepressuredropdrymode > Amount.valueAs(customUnits.Pascal, waterCoilLimits.max_air_pressure_drop)) {
      messages.push(Messages.Error_AirPressureDropTooHigh(source, waterCoilLimits.max_air_pressure_drop));
    }

    if (
      result.fluidsidepressuredrop > Amount.valueAs(customUnits.KiloPascal, waterCoilLimits.max_water_pressure_drop)
    ) {
      messages.push(Messages.Error_WaterPressureDropTooHigh(source, waterCoilLimits.max_water_pressure_drop));
    }

    const areaM2 = Area.getArea(attributes);
    if (areaM2 === undefined) {
      return Types.createCalculatorError([Messages.Exception(source, "Could not get face area data")]);
    }
    const altInputs = probeFaceVelocities.map((vMPS) => ({
      ...dbmInput,
      inputsData: { ...dbmInput.inputsData, AirInFlowActual: areaM2 * vMPS * 3600 },
    }));
    const altCalls = altInputs.map((altInput) => Dbm.calculate(altInput));
    const altResults = await Promise.all(altCalls);
    const pressureDropCurve = PressureDrop.createPowerPressureCurve(
      0,
      5000,
      altResults
        .map((r, i) =>
          Interpolation.vec2Create(altInputs[i].inputsData.AirInFlowActual / 3.6, r?.airsidepressuredropdrymode ?? NaN)
        )
        .filter((p) => Number.isFinite(p.y))
    );

    // The DLL will return the closest outlet air temp it's able to find
    const resultOutletAirTemperatureC =
      target === "AirOutTemperature" && outletAirTemperatureC ? outletAirTemperatureC : result.airoutlettemperature;
    if (target === "AirOutTemperature" && Math.abs(resultOutletAirTemperatureC - result.airoutlettemperature) > 0.1) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }

    messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, result.airsidepressuredropdrymode));

    return Types.createCalculatorSuccess(
      [
        { value: Math.abs(targetOutTemp - result.airoutlettemperature), descending: false },
        { value: codes.code, descending: false },
      ],
      {
        airFlow: airFlow,
        recomendedKv: undefined,
        airPressureDrop: Amount.create(result.airsidepressuredropdrymode, customUnits.Pascal, 1),
        outletAirTemperature: Amount.create(resultOutletAirTemperatureC, customUnits.Celsius, 1),
        outletAirHumidity: Amount.create(result.airoutletrelativehumidity, customUnits.PercentHumidity, 1),
        waterFlow: Amount.create(result.fluidvolumeDmH, customUnits.LiterPerHour, 4),
        inletWaterTemperature: Amount.create(inletWaterTemperatureC, customUnits.Celsius, 1),
        outletWaterTemperature: Amount.create(result.fluidoutlettemperature, customUnits.Celsius, 1),
        waterVelocity: Amount.create(result.fluidvelocity, customUnits.MeterPerSecond, 2),
        airVelocity: Amount.create(result.gasvelocity, customUnits.MeterPerSecond, 2),
        waterPressureDrop: Amount.create(result.fluidsidepressuredrop, customUnits.KiloPascal, 2),
        power: Amount.create(result.capacityKw, customUnits.KiloWatt, 2),
        inletAirTemperature: inletAirTemperature,
        inletAirHumidity: inletAirHumidity,
        connectionSizeIn: result.inletConnectionSize,
        connectionSizeOut: result.outletConnectionSize,
        coilCode: result.supplierCode,
        pressureDropCurve: pressureDropCurve,
      },
      messages,
      getOutCalcParams(input.paramsToUpdate, target, result, input)
    );
  } catch (e) {
    return Types.createCalculatorError([Messages.Exception(source, e.toString())]);
  }
}

function getOutCalcParams(
  paramsToUpdate: ParamsToUpdate,
  target: Dbm.DbmInput["target"],
  result: Dbm.DbmResult,
  input: Input
): PropertyValueSet.PropertyValueSet {
  const {
    waterFlow,
    waterFlowParam,
    outletWaterTemperature,
    outletWaterTemperatureParam,
    outletAirTemperature,
    outletAirTemperatureParam,

    inletAirHumidity,
    inletAirHumidityParam,
    inletAirTemperature,
    inletAirTemperatureParam,
    inletWaterTemperature,
    inletWaterTemperatureParam,
    calculationMethod,
    calculationMethodParam,
  } = paramsToUpdate;
  let pvs = PropertyValueSet.Empty;

  if (waterFlow && (target === "FluidTempOut" || target === "AirOutTemperature")) {
    pvs = PropertyValueSet.setAmount(
      waterFlowParam,
      Amount.create(result.fluidvolumeDmH, customUnits.LiterPerHour, 1),
      pvs
    );
  }

  if (outletAirTemperature && (target === "AirOutTemperature" || target === "FluidFlow_dm3s")) {
    pvs = PropertyValueSet.setAmount(
      outletAirTemperatureParam,
      Amount.create(result.airoutlettemperature, customUnits.Celsius, 1),
      pvs
    );
  }

  if (outletWaterTemperature && (target === "FluidFlow_dm3s" || target === "FluidTempOut")) {
    pvs = PropertyValueSet.setAmount(
      outletWaterTemperatureParam,
      Amount.create(result.fluidoutlettemperature, customUnits.Celsius, 1),
      pvs
    );
  }

  if (inletAirHumidity && inletAirHumidityParam) {
    pvs = PropertyValueSet.setAmount(inletAirHumidityParam, input.inletAirHumidity, pvs);
  }

  if (inletAirTemperature && inletAirTemperatureParam) {
    pvs = PropertyValueSet.setAmount(inletAirTemperatureParam, input.inletAirTemperature, pvs);
  }

  if (inletWaterTemperature && inletWaterTemperatureParam) {
    pvs = PropertyValueSet.setAmount(inletWaterTemperatureParam, input.inletWaterTemperature, pvs);
  }

  if (calculationMethod && calculationMethodParam) {
    pvs = PropertyValueSet.setInteger(calculationMethodParam, input.calculationMethod, pvs);
  }

  return pvs;
}
