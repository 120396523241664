export type Action = OpenDescription | CloseDescription;

export interface OpenDescription {
  readonly type: "frico-product-table/Open";
  readonly payload: string;
}

export interface CloseDescription {
  readonly type: "frico-product-table/Close";
  readonly payload: string;
}

export function openDescription(id: string): OpenDescription {
  return {
    type: "frico-product-table/Open",
    payload: id
  };
}

export function closeDescription(id: string): CloseDescription {
  return {
    type: "frico-product-table/Close",
    payload: id
  };
}
