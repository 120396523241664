import type { Selector } from "reselect";
import { createSelector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as QI from "shared-lib/query-identity";
import * as UserSettings from "client-lib/user-settings";
import { SearchResultTableContainerComponent } from "./container-component";
import type { StateProps, OwnProps, Response, Props } from "./types";
import type { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.searchResults,
  (s: RootState) => s.search,
  UserSettings.stateSelector,
  UserSettings.showAdvancedSelector,
  UserSettings.showDiagramsSelector,
  (s: RootState) => s.external,
  (state, calculationState, userSettings, showAdvanced, showDiagrams, externalState) => ({
    state: state,
    calculationState: calculationState,
    userSettings: userSettings,
    showAdvanced: showAdvanced,
    showDiagrams: showDiagrams,
    externalState: externalState,
  })
);

function mapPropsToQuery(ownProps: Props): QD.DiaqMapQuery<Response> | QD.DiaqMapQuery<Response> {
  // Dont load Queries if we are not going to show the table, saves ~ 100 requests
  if (
    ownProps.calculationState.type === "Calculating" ||
    ownProps.searchProductId !== ownProps.calculationState.searchProductId ||
    ownProps.calculationState.results.length === 0
  ) {
    return QD.createMapQuery<Response>({
      ct_MarketUnits: QI.createIdentityQuery([]),
      ct_MarketPriceList: QI.createIdentityQuery([]),
      ct_MarketHiddenFields: QI.createIdentityQuery([]),
      ct_DivisionCurrency: QI.createIdentityQuery([]),
      ct_LanguageMapping: QI.createIdentityQuery([]),
      ct_SearchAttributes: QI.createIdentityQuery([]),
    });
  }

  return QD.createMapQuery<Response>({
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    ct_MarketPriceList: QP.tableByProductId(QP.metaProductId, "ct_MarketPriceList"),
    ct_MarketHiddenFields: QP.tableByProductId(QP.metaProductId, "ct_MarketHiddenFields"),
    ct_DivisionCurrency: QP.tableByProductId(QP.metaProductId, "ct_DivisionCurrency"),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    ct_SearchAttributes: QP.tableByProductId(ownProps.searchProductId, "ct_SearchAttributes"),
  });
}

export const SearchResultTableContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(SearchResultTableContainerComponent);
