import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import type * as SC from "shared-lib/system-calculator";
import { Heading3, ResultRowContainer, Checkbox } from "client-lib/elements";
import * as UserSettingsShared from "shared-lib/user-settings";
import type { BoxFanResultItem } from "shared-lib/system-calculator/result-items-types";
import { formatNumberFunction, isAllDefined } from "shared-lib/utils";
import * as BoxFanDiagram from "shared-lib/system-calculator/shared/boxfan-diagram";
import type * as QP from "shared-lib/query-product";
import type { DispatchProp } from "client-lib/redux-integration";
import * as UserSettingsClient from "client-lib/user-settings";
import type { VisualizerOwnProps, VisualizerProductProps } from "../types";
import { ViewerDiagramContainer } from "../../viewer-diagram";

export type Props = VisualizerOwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  readonly showRpm: boolean;
  readonly showEfficiency: boolean;
  readonly showMotorPower: boolean;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

const diagramWidth = 580;
const diagramHeight = 320;

export function BoxFanDiagramsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const renderedDiagrams = products.map((p, idx) => renderBoxFanDiagramsVisualizer(idx, props, p, idx !== 0));
  if (renderedDiagrams.length === 1) {
    const performance = renderedDiagrams[0] ? renderedDiagrams[0][0] : undefined;
    const power = renderedDiagrams[0] ? renderedDiagrams[0][1] : undefined;
    if (!performance || !power) {
      return <div />;
    }
    return (
      <div className="flex flex-wrap sm-max:flex-col">
        <div className="flex flex-col sm-max:w-full sm-max:mb-40 w-1/2">
          <Heading3 className="ml-8">{translate(Texts.performanceCurve())}</Heading3>
          {performance}
        </div>
        <div className="flex flex-col sm-max:w-full w-1/2">
          <Heading3 className="ml-8">{translate(Texts.powerCurve())}</Heading3>
          {power}
        </div>
      </div>
    );
  } else {
    const performance = renderedDiagrams.map((diagrams, i) => (diagrams ? diagrams[0] : <div key={i} />));
    const power = renderedDiagrams.map((diagrams, i) => (diagrams ? diagrams[1] : <div key={i} />));
    return (
      <div>
        <ResultRowContainer heading={translate(Texts.performanceCurve())}>{performance}</ResultRowContainer>
        <ResultRowContainer heading={translate(Texts.powerCurve())}>{power}</ResultRowContainer>
      </div>
    );
  }
}

function renderBoxFanDiagramsVisualizer(
  key: number,
  {
    translate,
    market,
    language,
    userSettings,
    ct_MarketUnits,
    ct_LanguageMapping,
    showDownload,
    showRpm,
    showEfficiency,
    showMotorPower,
    dispatch,
  }: Props,
  { resultItemMap, calcParams, calcParamsChanged }: VisualizerProductProps,
  disableHoverPoint: boolean
): readonly [React.ReactElement<Props>, React.ReactElement<Props>] | undefined {
  const maybeFan: BoxFanResultItem = R.head(
    R.values(R.filter((ri: SC.ResultItem) => ri.type === "BoxFan", resultItemMap))
  ) as BoxFanResultItem;
  const fan = maybeFan?.value.air;

  if (!fan) {
    return undefined;
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const powerUnit = getUnit("power", "Power");

  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);
  const powerUnitDecimals = getDecimals("airPressure", powerUnit);

  const { pressure, power } = BoxFanDiagram.generateCharts({
    fan,
    flowUnit,
    pressureUnit,
    powerUnit,
    translate,
    showRpmCurves: showRpm,
    showEfficiencyCurves: showEfficiency,
    showMotorPowerCurves: showMotorPower,
    showLineLabels: true,
    showActualRpmCurve: true,
    showActualRpmCurveLabel: true,
  });

  return [
    <div key={`pressure_${key}`}>
      <ViewerDiagramContainer
        linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
        linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
        id="fanExternalPressure"
        chart={pressure}
        maxWidth={diagramWidth}
        formatNumber={formatNumber}
        maxHeight={diagramHeight}
        showDownload={showDownload}
        onClick={
          !disableHoverPoint
            ? (x, y) => {
                const withFlow = PropertyValueSet.setAmount(
                  "airFlow",
                  Amount.create(x, flowUnit, flowUnitDecimals),
                  calcParams
                );
                const withPressure = PropertyValueSet.setAmount(
                  "externalPressure",
                  Amount.create(y, pressureUnit, pressureUnitDecimals),
                  withFlow
                );
                calcParamsChanged(withPressure);
              }
            : undefined
        }
        allowTextOverlap={true}
      />
      <div className="flex flex-row space-x-24 pl-24">
        <Checkbox
          label={
            <div className="inline-flex items-center space-x-4">
              <svg width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg">
                <rect fill="rgb(110,185,30)" x="0" y="0" width="1rem" height="1rem" />
              </svg>
              <span>{translate(Texts.showRpmCurves())}</span>
            </div>
          }
          checked={showRpm}
          checkedChanged={(checked) => dispatch(UserSettingsClient.setShowBoxFanRpm(checked))}
        />

        <Checkbox
          label={
            <div className="inline-flex items-center space-x-4">
              <svg width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg">
                <rect fill="rgb(255,170,0)" x="0" y="0" width="1rem" height="1rem" />
              </svg>
              <span>{translate(Texts.showEfficiencyCurves())}</span>
            </div>
          }
          checked={showEfficiency}
          checkedChanged={(checked) => dispatch(UserSettingsClient.setShowBoxFanEfficiency(checked))}
        />

        <Checkbox
          label={
            <div className="inline-flex items-center space-x-4">
              <svg width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg">
                <rect fill="rgb(166,0,119)" x="0" y="0" width="1rem" height="1rem" />
              </svg>
              <span>{translate(Texts.showMotorPowerCurves())}</span>
            </div>
          }
          checked={showMotorPower}
          checkedChanged={(checked) => dispatch(UserSettingsClient.setShowBoxFanMotorPower(checked))}
        />
      </div>
    </div>,
    <ViewerDiagramContainer
      key={`power_${key}`}
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={powerUnitDecimals}
      id="fanExternalPower"
      chart={power}
      maxWidth={diagramWidth}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      allowTextOverlap={true}
      formatNumber={formatNumber}
      // onClick={(x, y) => {
      //   const withFlow = PropertyValueSet.setAmount(
      //     "airFlow",
      //     Amount.create(x, flowUnit, flowUnitDecimals),
      //     calcParams
      //   );
      //   const withPressure = PropertyValueSet.setAmount(
      //     "externalPressure",
      //     Amount.create(y, pressureUnit, pressureUnitDecimals),
      //     withFlow
      //   );
      //   calcParamsChanged(withPressure);
      // }}
    />,
  ];
}
