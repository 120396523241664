import * as React from "react";
import type { Amount } from "uom";
import { UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import type * as UserSettingsShared from "shared-lib/user-settings";
import type * as QP from "shared-lib/query-product";
import * as PU from "shared-lib/product-utils";
import * as Texts from "shared-lib/language-texts";
import { LinkButton, withTw } from "client-lib/elements";
import type { ComponentResult } from "shared-lib/search";
import type { TranslateFunction } from "shared-lib/language-texts";
import * as SC from "shared-lib/system-calculator";
import type { PropertyValueSet } from "@promaster-sdk/property";
import type * as Ecom from "shared-lib/ecom-api";
import * as CUtils from "client-lib/utils";
import type { AnyQuantity } from "shared-lib/uom";
import { renderFricoSelectionCriteria } from "client-lib/frico";
import * as ImageService from "client-lib/image-service";
import type { FormatNumberFunction } from "shared-lib/utils";

const HelpContainer = withTw("div", "frico-spec-print-default frico-spec-print-help-container");
const TableThText = withTw("div", "frico-spec-print-default frico-spec-print-table-th-text");
const MaxWidth = withTw("div", "frico-spec-print-default frico-spec-print-max-width");
const DescriptionHeader = withTw("p", "frico-spec-print-default frico-spec-print-description-header");
const DescriptionLeft = withTw("div", "frico-spec-print-default frico-spec-print-description-left");
const FlexRow = withTw("div", "frico-spec-print-default frico-spec-print-flex-row");
const PrintThead = withTw("thead", "frico-spec-print-default frico-spec-print-print-thead");
const PrintTd = withTw("td", "frico-spec-print-default frico-spec-print-print-td");
const PrintTdHeader = withTw("td", "frico-spec-print-default frico-spec-print-print-td-header");
const PrintTdCalcResult = withTw("td", "frico-spec-print-default frico-spec-print-print-td-calc-result");
const BoldP = withTw("p", "frico-spec-print-default frico-spec-print-bold-p");
const PrintTable = withTw("table", "frico-spec-print-default frico-spec-print-print-table");
const Description = withTw("div", "frico-spec-print-default frico-spec-print-description");
const Foot = withTw("div", "frico-spec-print-default frico-spec-print-foot");
const Td = withTw("td", "frico-spec-print-default");
const Tr = withTw("tr", "frico-spec-print-default");
const PrintHeading2 = withTw("h2", "frico-spec-print-default frico-spec-print-heading2");
const ProductImage = withTw("img", "w-full mt-16");
type Props = StateProps & OwnProps & Response;

export interface Item {
  readonly sortNo: number;
  readonly itemName: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
  readonly quantity: number;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
}

interface AccessoryItem {
  readonly name: string;
  readonly m3ItemNo: string;
  readonly quantity: number;
  readonly category: number;
  readonly variantNo: string;
}
export interface Accessory {
  readonly productId: string;
  readonly m3ItemNo: string;
  readonly quantity: number;
}

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
}

export interface OwnProps {
  readonly projectName: string;
  readonly projectDescription: string;
  readonly translate: TranslateFunction;
  readonly closeCallBack: () => void;
  readonly products: ReadonlyArray<Item>;
  readonly searchSelections: PU.Selections | undefined;
  readonly addedAccessories: ReadonlyArray<Accessory>;
  readonly savedCalculations: ReadonlyArray<ComponentResult>;
  readonly getUnit: UserSettingsShared.GetUnitFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly getDecimals: UserSettingsShared.GetDecimalsFunction;
  readonly specificationDataUrl: string;
  readonly market: string;
  readonly language: string;
  readonly ecomUrl: string;
}

export interface Response {
  readonly marketTables: PU.MarketTablesResponse;
  readonly tablesForProducts: TablesForProducts;
  readonly tablesForAccessories: TablesForProducts;
  readonly productDescription: ProductDescriptionAndTechParam;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly fricoTables: {
    readonly property: QP.PropertyTable;
    readonly ct_PropertyFieldNames: QP.PropertyFieldNamesTable;
    readonly ct_PropertySelectorTypes: QP.PropertySelectorTypesTable;
    readonly ct_SavedResultColumns: QP.SavedResultColumnsTable;
  };
}

interface TablesForProducts {
  readonly [id: string]: AllProductTables;
}

interface AllProductTables {
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export interface ProductDescriptionAndTechParam {
  readonly [variantNo: string]: Ecom.ProductDescriptionAndTechnicalParameters;
}

export function PrintViewContainerComponentEpim(props: Props): React.ReactElement<Props> {
  const {
    translate,
    closeCallBack,
    projectDescription,
    projectName,
    products,
    addedAccessories,
    savedCalculations,
    tablesForProducts,
    tablesForAccessories,
    fricoTables,
    marketTables,
    searchSelections,
    market,
    language,
    ct_MarketUnits,
    ct_LanguageMapping,
    userSettings,
  } = props;

  React.useEffect(() => {
    const appContainer = document.getElementById("app-container");
    if (appContainer) {
      appContainer.scrollTop = 0;
    }
    const oldTitle = document.title;
    document.title = translate(Texts.specification_texts_title());
    return () => {
      document.title = oldTitle;
    };
  }, []);

  const includedAccessories =
    (tablesForProducts &&
      marketTables &&
      PU.getFricoIncludedAccessoriesEpim({
        products: products,
        response: {
          tablesForProducts: tablesForProducts,
          marketTables: marketTables,
          tablesForAccessories: tablesForAccessories,
        },
        addedAccessories: addedAccessories,
      })) ||
    [];

  const quantities =
    tablesForProducts &&
    marketTables &&
    PU.getFricoAccessoryQuantitiesEpimImpl(
      products,
      {
        tablesForProducts: tablesForProducts,
        tablesForAccessories: tablesForAccessories,
        marketTables: marketTables,
      },
      includedAccessories,
      addedAccessories
    );

  const accessories = includedAccessories
    .filter((a) => (quantities[a.itemNo] || 0) !== 0)
    .map((a) => ({
      name: a.name,
      m3ItemNo: a.itemNo,
      quantity: quantities[a.itemNo] || 0,
      category: a.category,
      variantNo: a.variantId,
    }));

  const selectionCriteria =
    searchSelections &&
    renderFricoSelectionCriteria({
      property: fricoTables.property,
      ct_MarketUnits: ct_MarketUnits,
      ct_PropertyFieldNames: fricoTables.ct_PropertyFieldNames,
      market: market,
      language: language,
      userSettings: userSettings,
      translate: translate,
      searchSelections: searchSelections,
      ct_LanguageMapping,
    });

  return (
    <FlexRow>
      <MaxWidth>
        <div className="hidden print:block mb-24">
          <img src="/images/frico_logo.svg" className="h-64" />
        </div>
        <FlexRow style={{ justifyContent: "flex-end" }} className="print:hidden">
          <LinkButton onClick={() => closeCallBack()}>{translate(Texts.back_to_specification_text())}</LinkButton>{" "}
          <span style={{ margin: "0 6px" }}>|</span>
          <LinkButton onClick={() => window.print()}>{translate(Texts.print())}</LinkButton>
        </FlexRow>
        <FlexRow>
          <PrintHeading2>
            {translate(Texts.specification_text())}: {products.map((r) => r.itemName).join(" | ")}
          </PrintHeading2>
        </FlexRow>
        {projectName !== "" || projectDescription !== "" ? (
          <FlexRow>
            <HelpContainer>
              <p>{translate(Texts.project_description())}</p>
            </HelpContainer>
            <MaxWidth>
              {projectName !== "" ? (
                <FlexRow style={{ marginTop: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>{translate(Texts.project_name())}:</span>
                  <span style={{ marginLeft: "10px" }}>{projectName}</span>
                </FlexRow>
              ) : null}
              {projectDescription !== "" ? (
                <FlexRow style={{ marginTop: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>{translate(Texts.project_comments())}:</span>
                  <span style={{ marginLeft: "10px" }}>{projectDescription}</span>
                </FlexRow>
              ) : null}
            </MaxWidth>
          </FlexRow>
        ) : null}

        {selectionCriteria && (
          <FlexRow style={{ marginTop: "20px" }}>
            <HelpContainer>
              <p>{translate(Texts.frico_search_criteria())}</p>
            </HelpContainer>
            <MaxWidth>
              {selectionCriteria.groups.map((group) => {
                return (
                  <PrintTable key={group.key}>
                    <PrintThead>
                      <tr key={group.key}>
                        <td>{group.label}</td>
                        <td>{translate(Texts.value())}</td>
                      </tr>
                    </PrintThead>
                    <tbody>
                      {group.properties.map((property) => {
                        return (
                          <tr key={property.key}>
                            <PrintTd>{property.label}</PrintTd>
                            <PrintTd>{property.value}</PrintTd>
                          </tr>
                        );
                      })}
                    </tbody>
                  </PrintTable>
                );
              })}
            </MaxWidth>
          </FlexRow>
        )}

        {products.length > 0 ? (
          <FlexRow style={{ marginTop: "20px" }}>
            <HelpContainer>
              <p>{translate(Texts.product_text())}</p>
            </HelpContainer>
            <MaxWidth>
              <PrintTable>
                <PrintThead>
                  <Tr key={"head_product_print"}>
                    <Td>{translate(Texts.product_name_text())}</Td>
                    <Td>{translate(Texts.product_no())}</Td>
                    <Td>{translate(Texts.qty())}</Td>
                  </Tr>
                </PrintThead>
                <tbody>
                  {products.map((r) => {
                    return (
                      <Tr key={r.m3ItemNo}>
                        <PrintTd>{r.itemName}</PrintTd>
                        <PrintTd>{r.m3ItemNo}</PrintTd>
                        <PrintTd>{r.quantity}</PrintTd>
                      </Tr>
                    );
                  })}
                </tbody>
              </PrintTable>
            </MaxWidth>
          </FlexRow>
        ) : null}

        {accessories.length > 0 ? (
          <FlexRow style={{ marginTop: "20px" }}>
            <HelpContainer>
              <p>{translate(Texts.accessories())}</p>
            </HelpContainer>
            <MaxWidth>
              <PrintTable>
                <PrintThead>
                  <Tr key={"head_accessory_print"}>
                    <Td>{translate(Texts.product_name_text())}</Td>
                    <Td>{translate(Texts.product_no())}</Td>
                    <Td>{translate(Texts.qty())}</Td>
                  </Tr>
                </PrintThead>
                <tbody>
                  {accessories.map((r) => {
                    const accessory = r;
                    return (
                      <Tr key={r.m3ItemNo}>
                        <PrintTd>
                          {accessory !== undefined
                            ? translate(Texts.frico_accessory(accessory.m3ItemNo), accessory.name)
                            : ""}
                        </PrintTd>
                        <PrintTd>{r.m3ItemNo}</PrintTd>
                        <PrintTd>{r.quantity}</PrintTd>
                      </Tr>
                    );
                  })}
                </tbody>
              </PrintTable>
            </MaxWidth>
          </FlexRow>
        ) : null}
        {savedCalculations.length > 0 ? (
          <FlexRow style={{ marginTop: "20px" }}>
            <HelpContainer>
              <p>{translate(Texts.heating_calculation())}</p>
            </HelpContainer>
            <MaxWidth>{renderTablePrint(props, translate)}</MaxWidth>
          </FlexRow>
        ) : null}

        {productDescriptionAndParameters(props)}
        {accessoriesDescriptionAndParameters(props, accessories)}
        {pageFooter(props)}
      </MaxWidth>
    </FlexRow>
  );
}
function pageFooter(props: Props): React.ReactElement {
  const { translate } = props;
  return (
    <FlexRow style={{ marginTop: "20px" }}>
      <HelpContainer />
      <MaxWidth>
        <Foot>
          <p>
            <b>{translate(Texts.frico_note())} </b>
            {translate(Texts.frico_print_note())}
          </p>
        </Foot>
      </MaxWidth>
    </FlexRow>
  );
}

function renderTablePrint(props: Props, translate: TranslateFunction): React.ReactElement {
  const { savedCalculations, fricoTables } = props;
  const { ct_SavedResultColumns } = fricoTables;
  return (
    <PrintTable>
      {renderTableHeadPrint(props, translate)}
      <tbody>{savedCalculations.map((r) => renderTableRowPrint(r, props, ct_SavedResultColumns, translate))}</tbody>
    </PrintTable>
  );
}

function renderTableRowPrint(
  result: ComponentResult,
  props: OwnProps,
  searchColumns: ReadonlyArray<QP.SavedResultColumn>,
  translate: TranslateFunction
): React.ReactElement | undefined {
  const componentResult = result;
  return (
    <Tr key={componentResult.id}>
      <PrintTdCalcResult>
        <div>{result.productCode}</div>
      </PrintTdCalcResult>
      <PrintTdCalcResult>{componentResult.m3}</PrintTdCalcResult>
      {searchColumns.map((c) => {
        const type = Object.keys(componentResult.results)[0];
        const path = type + "." + c.result_path;
        const value = SC.getResultItemValue(type, path, componentResult.results);

        const valueString =
          typeof value === "string"
            ? translate(Texts.translatedResultTableValue(value))
            : CUtils.renderAmount(value as Amount.Amount<AnyQuantity>, c.field_name || c.language_key, props);
        return <PrintTdCalcResult key={c.result_path}>{valueString}</PrintTdCalcResult>;
      })}
    </Tr>
  );
}

function renderTableHeadPrint(props: Props, translate: TranslateFunction): React.ReactElement {
  const { ct_SavedResultColumns } = props.fricoTables;
  return (
    <PrintThead>
      <Tr key={"print_head"}>
        <Td style={{ verticalAlign: "text-top" }} key="product_code">
          {translate(Texts.name()) + " "}
        </Td>

        <Td style={{ verticalAlign: "text-top" }} key="m3">
          {translate(Texts.articleNo()) + " "}
        </Td>

        {ct_SavedResultColumns.map((c) => renderTableHeadColumnPrint(c, props, translate))}
      </Tr>
    </PrintThead>
  );
}

function renderTableHeadColumnPrint(
  c: QP.SavedResultColumn,
  props: OwnProps,
  translate: TranslateFunction
): React.ReactElement {
  const label = translate(Texts.createText(c.language_key));
  return (
    <Td style={{ verticalAlign: "text-top" }} key={c.result_path}>
      <TableThText title={label}>{label}</TableThText>[{renderTableHeadColumnUnitSelectorPrint(c, props)}]
    </Td>
  );
}

function renderTableHeadColumnUnitSelectorPrint(c: QP.SavedResultColumn, props: OwnProps): string {
  const { getUnit, translate } = props;

  const { savedCalculations } = props;
  if (savedCalculations.length === 0) {
    return "";
  }

  const withWater = savedCalculations.find(
    (r) => SC.getResultItemValue("AirCurtain", "AirCurtain.waterPressureDrop", r.results) !== undefined
  );
  const firstResult = withWater || savedCalculations[0];

  const type = Object.keys(firstResult.results)[0];
  const path = type + "." + c.result_path;

  const value = SC.getResultItemValue(type, path, firstResult.results);
  if (!value || typeof value === "string") {
    return "";
  }
  const amount = value as Amount.Amount<AnyQuantity>;
  const unit = getUnit(c.field_name || c.language_key, amount.unit.quantity);

  return translate(Texts.unitLabel(unit), UnitFormat.getUnitFormat(unit, UnitsFormat)?.label);
}

function accessoriesDescriptionAndParameters(
  props: Props,
  accessories: ReadonlyArray<AccessoryItem>
): React.ReactElement {
  const { productDescription } = props;
  return <div>{accessories.map((i) => renderAccessoryDescription(i, productDescription, props.translate))}</div>;
}

function renderAccessoryDescription(
  item: AccessoryItem,
  descAndParams: ProductDescriptionAndTechParam,
  translate: TranslateFunction
): React.ReactElement {
  const productDescription = descAndParams?.[item.variantNo]?.description || undefined;
  return (
    <FlexRow key={item.name + item.variantNo} style={{ marginTop: "20px" }}>
      <HelpContainer>
        <DescriptionLeft>
          <p>{translate(Texts.description_for_accessory())}</p>
          <BoldP>{item.name}</BoldP>
        </DescriptionLeft>
      </HelpContainer>
      <MaxWidth key={"main"}>
        <DescriptionHeader key={"Description"}>{translate(Texts.description())}</DescriptionHeader>
        {productDescription ? (
          <Description dangerouslySetInnerHTML={{ __html: productDescription.product_specification_text }} />
        ) : null}
        {renderTechnicalParamsAccessory(item, descAndParams, translate)}
      </MaxWidth>
    </FlexRow>
  );
}

function renderTechnicalParamsAccessory(
  item: AccessoryItem,
  descAndParams: ProductDescriptionAndTechParam,
  translate: TranslateFunction
): React.ReactElement {
  const technicalData = descAndParams?.[item.variantNo]?.technicalParameters || undefined;
  if (technicalData === undefined) {
    return <span />;
  }
  return (
    <MaxWidth key={"description"}>
      <PrintTable key={item.m3ItemNo}>
        <PrintThead>
          <Tr key={"top"}>
            <Td>
              <BoldP>{translate(Texts.techincal_data())}</BoldP>
            </Td>
            <Td />
            <Td />
          </Tr>
        </PrintThead>
        <tbody>
          {technicalData.table.map((r, i) => {
            if (r.tr.length === 1) {
              return <PrintHeader key={i} header={r.tr[0].th} />;
            } else {
              return <PrintRow key={i} cells={r.tr} />;
            }
          })}
        </tbody>
      </PrintTable>
    </MaxWidth>
  );
}

function productDescriptionAndParameters(props: Props): React.ReactElement {
  const { products, productDescription } = props;
  return <div>{products.map((i) => renderProductDescription(i, productDescription, props.translate))}</div>;
}

function renderProductDescription(
  item: Item,
  descAndParams: ProductDescriptionAndTechParam,
  translate: TranslateFunction
): React.ReactElement {
  const productDescription = descAndParams?.[item.variantNo]?.description || undefined;

  return (
    <FlexRow key={item.itemName + item.variantNo} style={{ marginTop: "20px" }}>
      <HelpContainer>
        <DescriptionLeft>
          <p>{translate(Texts.description_for())}</p>
          <BoldP>{item.itemName}</BoldP>
          <ProductImage src={ImageService.itemNumberToServiceUrl(item.m3ItemNo)} />
        </DescriptionLeft>
      </HelpContainer>
      <MaxWidth key={"main"}>
        <DescriptionHeader key={"Description"}>{translate(Texts.description())}</DescriptionHeader>
        {productDescription ? (
          <Description dangerouslySetInnerHTML={{ __html: productDescription.product_specification_text }} />
        ) : null}
        {renderTechnicalParams(item, descAndParams, translate)}
      </MaxWidth>
    </FlexRow>
  );
}

function renderTechnicalParams(
  item: Item,
  descAndParams: ProductDescriptionAndTechParam,
  translate: TranslateFunction
): React.ReactElement {
  const technicalData = descAndParams?.[item.variantNo]?.technicalParameters || undefined;
  if (technicalData === undefined) {
    return <span />;
  }
  return (
    <MaxWidth key={"description"}>
      <PrintTable key={item.m3ItemNo}>
        <PrintThead>
          <Tr key={"top"}>
            <Td>
              <BoldP>{translate(Texts.techincal_data())}</BoldP>
            </Td>
            <Td />
            <Td />
          </Tr>
        </PrintThead>
        <tbody>
          {technicalData.table.map((r, i) => {
            if (r.tr.length === 1) {
              return <PrintHeader key={i} header={r.tr[0].th} />;
            } else {
              return <PrintRow key={i} cells={r.tr} />;
            }
          })}
        </tbody>
      </PrintTable>
    </MaxWidth>
  );
}
function PrintHeader({ header }: { readonly header: string | undefined }): React.ReactElement | null {
  if (!header) {
    return null;
  }
  return (
    <Tr>
      <PrintTdHeader colSpan={3}>{header}</PrintTdHeader>
    </Tr>
  );
}

function PrintRow({ cells }: { readonly cells: ReadonlyArray<Ecom.TableCell> }): React.ReactElement {
  return (
    <Tr>
      {cells.map((c, i) => (
        <PrintTd key={i}>{c.td}</PrintTd>
      ))}
    </Tr>
  );
}
