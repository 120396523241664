import type * as UserSettingsShared from "shared-lib/user-settings";
import type * as C from "shared-lib/calculation";
import type * as QP from "shared-lib/query-product";
import type * as Texts from "shared-lib/language-texts";

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly m3ItemNo: string;
  readonly variant: string | undefined;
  readonly propsConfig: C.ItemConfig | undefined;
  readonly stateConfig: C.ItemConfig;
  readonly userSettings: UserSettingsShared.State;
  readonly imageServiceUrl: string
  readonly externalConfig: ExternalConfig | undefined
}

export interface Response {
  readonly productId: string;
  readonly productTables: ProductTables;
  readonly metaTables: MetaTables;
  readonly translateTables: Texts.TranslationTables;
  readonly calculationResponse: C.Response;
  readonly accessoryTables: AccessoryTables;
}

export interface AccessoryTables {
  readonly [productId: string]: ProductTables;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_ResultViews: QP.ResultViewsTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
  readonly ct_MarketHiddenFields: QP.MarketHiddenFieldsTable;
  readonly ct_SpecifierResultMappingTable: QP.SpecifierResultMappingTable
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly code: QP.CodeTable;
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_CalcParamDefault: QP.CalcParamDefaultTable;
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly ct_Attributes2: QP.AttributesTable;
  readonly ct_AmcaStatements: QP.AmcaStatementsTable;
}

export interface ExternalItem {
  readonly itemNumber: string;
  readonly variant: string;
  readonly productName?: string;
  readonly productDescription?: string;
  readonly items: ReadonlyArray<Item>;
}

export type Item = Alert | Table | Diagram | Base64Diagram;

export interface Alert {
  readonly type: "Error" | "Warning";
  readonly text: string;
}

export interface Table {
  readonly type: "Table";
  readonly columns: ReadonlyArray<string>;
  readonly rows: ReadonlyArray<ReadonlyArray<string>>;
}

export interface Diagram {
  readonly type: "Diagram";
  readonly label: string;
  readonly svg: string;
}

export interface Base64Diagram {
  readonly type: "Base64Diagram";
  readonly label: string;
  readonly format: string;
  readonly data: string;
}

export function createAlertType(type: "error" | "warning", text: string): Alert {
  return {
    type: type === "error" ? "Error" : "Warning",
    text: text,
  };
}

export function isAlert(item: Item): item is Alert {
  return item.type === "Error" || item.type === "Warning";
}

export type ExternalResult = {
  readonly externalItems: ReadonlyArray<ExternalItem>;
  readonly specifierPayload: SpecifierPayload | undefined
};

export type SpecifierPayload = {
  readonly payload: {
    readonly Projects__Tbl: ReadonlyArray<SpecifierProject>;
  };
};

type SpecifierProject = {
  readonly ProjectNo: string;
  readonly Products__Tbl: SpecifierProduct;
};

type SpecifierProduct = {
  readonly ApplicationInterfaceNo: number;
  readonly ProductDetailsNo: null;
  readonly ProductCode: string;
  readonly ProductDescription: string;
  readonly RangeDescription: string;
  readonly ProductImageJpgURL: string;
  readonly ProductType: "External";
  readonly Qty: number;
  readonly Properties__Tbl: ReadonlyArray<SpecifierProperty>;
  readonly Sounds__Tbl?: ReadonlyArray<SpecifierSound>
  readonly Ancillaries__Tbl: ReadonlyArray<SpecifierAncillary
  >
};

type SpecifierAncillary= {
  readonly ProductCode: string,
  readonly PartNumber: string,
  readonly ProductDescription: string,
  readonly Qty: number,
}

export type SpecifierSound = {
  readonly SoundDescription: string
  readonly dBA63: number,
  readonly dBA125: number,
  readonly dBA250: number,
  readonly dBA500: number,
  readonly dBA1k: number,
  readonly dBA2k: number,
  readonly dBA4k: number,
  readonly dBA8k: number,
  readonly dBATotal: number
}

type SpecifierProperty = SpecifierPropertyString | SpecifierPropertyTable;
type SpecifierPropertyString = {
  readonly PropertyName: string;
  readonly PropertyType: "string";
  readonly PropertyValue: string;
};
type SpecifierPropertyTable = {
  readonly PropertyName: string;
  readonly PropertyType: "Table";
  readonly PropertyValue: ReadonlyArray<SpecifierRow>;
};


type SpecifierRow = { 
  readonly [key in Column3Digits]: string;
};

// Enforces the columns to have the form of 'Column001', 'Column021' etc
type Column3Digits = `Column${`${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`}`;



export interface CrmConfig {
  readonly type: "crm";
  readonly crmUrl: string;
  readonly crmQuoteId: string;
  readonly crmQuoteLanguage: string;
}

export interface SpecifierConfig {
  readonly type: "specifier";
  readonly url: string;
  readonly token: string;
  readonly projectId: string;
  readonly projectDescription: string;
  readonly applicationInterfaceNo: string;
  readonly language: string;
}

export type ExternalConfig = CrmConfig | SpecifierConfig;