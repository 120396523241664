import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as ProductCodes from "shared-lib/product-codes";
import type * as Attributes from "../shared/attributes";
import * as Messages from "../messages";
import type { Input } from "./types";
import type { ComponentInput, InputMapperSuccess, InputMapperError, InputParam, ResultQuery } from "../types";
import { createInputMapperError, createInputMapperSuccess } from "../types";

const msgSource = "DbmDxCoilInputMapper";

// The DLL seems to get in an infinite loop if condensing temp is above this value
const maxCondTemp = 70;
const defaultCondTemp = 45;

export function getCalcParams(
  params: string,
  _attributes: Attributes.Attributes,
  _variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  const isAccessory = PropertyValueSet.getInteger("is_accessory", calcParams) === 1;
  const {
    calculationParamsGroup,
    inletAirHumidity,
    inletAirTemperature,
    //outletAirTemperature,
    evaporatingTemperature,
    //condensingTemperature,
  } = getParamNames(params, isAccessory);

  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~10000:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: inletAirHumidity,
      quantity: "RelativeHumidity",
      defaultValue: Amount.create(params === "heating" ? 90 : 60, Units.PercentHumidity),
      fieldName: "airHumidity",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `${inletAirHumidity}=0:PercentHumidity~100:PercentHumidity`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: inletAirTemperature,
      quantity: "Temperature",
      defaultValue: Amount.create(32, Units.Celsius),
      fieldName: "airTemperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `${inletAirTemperature}=-60:Celsius~50:Celsius`,
        CustomUnitsLookup
      ),
    },
    // {
    //   type: "Amount",
    //   group: calculationParamsGroup,
    //   name: condensingTemperature,
    //   quantity: "Temperature",
    //   fieldName: "condensingTemperature",
    //   defaultValue: Amount.create(defaultCondTemp, Units.Celsius),
    //   validationFilter: PropertyFilter.fromStringOrEmpty(
    //     `${condensingTemperature}>${evaporatingTemperature}&${condensingTemperature}<${maxCondTemp}:Celsius`,
    //     CustomUnitsLookup
    //   ),
    // },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: evaporatingTemperature,
      quantity: "Temperature",
      fieldName: "evaporatingTemperature",
      defaultValue: Amount.create(6, Units.Celsius),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        //`${evaporatingTemperature}>=-20:Celsius&${evaporatingTemperature}<${inletAirTemperature}`,
        `${evaporatingTemperature}=-20:Celsius~${maxCondTemp}:Celsius`,
        CustomUnitsLookup
      ),
    },
    // {
    //   type: "Discrete",
    //   group: calculationMethodGroup,
    //   name: calcMethodWaterCoil,
    //   values: [
    //     {
    //       value: 0,
    //       name: "outletWaterTemperature",
    //     },
    //     {
    //       value: 1,
    //       name: "waterFlow",
    //     },
    //     // Disabled, not supported by the DBM dll
    //     //{
    //     //  value: 2,
    //     //  name: "outletAirTemperature",
    //     //},
    //   ],
    // },
    //   {
    //   type: "Amount",
    //   group: calculationMethodGroup,
    //   name: outletAirTemperature,
    //   quantity: "Temperature",
    //   fieldName: "airTemperature",
    //   visibilityFilter: PropertyFilter.fromStringOrEmpty(`${calcMethodWaterCoil}=2`, CustomUnitsLookup),
    //   validationFilter:
    //     params === "heating"
    //       ? PropertyFilter.fromStringOrEmpty(
    //           `${outletAirTemperature}=-14:Celsius~50:Celsius&${outletAirTemperature}<${inletWaterTemperature}`,
    //           CustomUnitsLookup
    //         )
    //       : PropertyFilter.fromStringOrEmpty(
    //           `${outletAirTemperature}=-14:Celsius~50:Celsius&${outletAirTemperature}>${inletWaterTemperature}`,
    //           CustomUnitsLookup
    //         ),
    // },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_WaterCoilLimits: QP.tableByProductId(productId, "ct_WaterCoilLimits"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
    ct_DllDbmCoilsFluidType: QP.tableByProductId(QP.metaProductId, "ct_DllDbmCoilsFluidType"),
  });
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_WaterCoilLimits: QP.WaterCoilLimitsTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_DllDbmCoilsFluidType: QP.DllDbmCoilsFluidTypeTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  response: Response,
  _resultQuery: unknown,
  calculatorParams: string
): InputMapperSuccess<Input> | InputMapperError {
  const isAccessory = PropertyValueSet.getInteger("is_accessory", calcParams) === 1;
  const isAccessorySearch = PropertyValueSet.getInteger("is_accessory_search", calcParams) === 1;
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  if (!airFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airFlow")]);
  }

  const waterCoilLimits = response.ct_WaterCoilLimits.find((r) =>
    PropertyFilter.isValid(properties, r.property_filter)
  );

  const codes = ProductCodes.getProductCodes(response, properties);

  if (!waterCoilLimits) {
    console.warn({
      waterCoilLimits,
    });
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  // DX is always cooling
  if (isAccessorySearch) {
    const inletAirTemperature = Amount.create(32, Units.Celsius);
    const inletAirHumidity = Amount.create(calculatorParams === "heating" ? 90 : 60, Units.PercentHumidity);
    const condensingTemperature = Amount.create(defaultCondTemp, Units.Celsius);
    const calculationMethod = 0;
    const evaporatingTemperature = Amount.create(6, Units.Celsius);
    const outletAirTemperature = Amount.create(20, Units.Celsius);

    const propertiesToSet = {
      inletAirTemperature,
      inletAirHumidity,
      condensingTemperature,
      evaporatingTemperature,
      outletAirTemperature,
    };

    const newCalcParams = Object.entries(propertiesToSet).reduce(
      (calcP, pToSet) => PropertyValueSet.setAmount(pToSet[0], pToSet[1], calcP),
      calcParams
    );

    return createInputMapperSuccess({
      airFlow,
      inletAirTemperature,
      inletAirHumidity,
      condensingTemperature,
      calculationMethod,
      evaporatingTemperature,
      outletAirTemperature,
      attributes: attributes,
      codes,
      waterCoilLimits,
      dllDbmCoilsFluidTypes: response.ct_DllDbmCoilsFluidType,
      calcParams: newCalcParams,
    });
  }

  const p = getParamNames(calculatorParams, isAccessory);
  const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.inletAirTemperature, calcParams);
  const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>(p.inletAirHumidity, calcParams);
  const outletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.outletAirTemperature, calcParams);
  const evaporatingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.evaporatingTemperature, calcParams);
  //const condensingTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.condensingTemperature, calcParams);
  const condensingTemperature = Amount.create(defaultCondTemp, Units.Celsius);
  const calculationMethod = PropertyValueSet.getInteger(p.calcMethoDxCoil, calcParams) ?? 0;

  if (!inletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.inletAirTemperature)]);
  }
  if (!inletAirHumidity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.inletAirHumidity)]);
  }
  // if (!condensingTemperature) {
  //   return createInputMapperError([Messages.Error_MissingInput(msgSource, p.condensingTemperature)]);
  // }
  if (!evaporatingTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.evaporatingTemperature)]);
  }

  return createInputMapperSuccess({
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,
    condensingTemperature: condensingTemperature,
    calculationMethod: calculationMethod,
    outletAirTemperature: outletAirTemperature,
    evaporatingTemperature: evaporatingTemperature,
    attributes: attributes,
    codes: codes,
    waterCoilLimits: waterCoilLimits,
    dllDbmCoilsFluidTypes: response.ct_DllDbmCoilsFluidType,
    calcParams,
  });
}

function getParamNames(
  params: string,
  isAccessory: boolean
): {
  readonly calculationParamsGroup: string;
  readonly calcMethoDxCoil: string;
  readonly inletAirHumidity: string;
  readonly inletAirTemperature: string;
  readonly outletAirTemperature: string;
  readonly evaporatingTemperature: string;
  readonly condensingTemperature: string;
} {
  const suffix = !isAccessory ? "" : params === "heating" ? "H" : "C";
  return {
    calculationParamsGroup: `calculationParams${suffix}`,
    calcMethoDxCoil: `calcMethodDxCoil${suffix}`,
    inletAirHumidity: `inletAirHumidity${suffix}`,
    inletAirTemperature: `inletAirTemperature${suffix}`,
    outletAirTemperature: `outletAirTemperature${suffix}`,
    evaporatingTemperature: `evaporatingTemperature${suffix}`,
    condensingTemperature: `condensingTemperature${suffix}`,
  };
}
