import { withTw } from "client-lib/elements";
import { clientConfig } from "config";

export const FricoWrapper = withTw(
  "div",
  "h-full flex flex-col",
  clientConfig.addOuterPadding ? "pr-400 print:pr-0" : ""
);
export const SidebarBottomPadding = withTw("div", "print:hidden flex-1 min-w-224 max-w-224 bg-neutral-50");
export const HelpContainer = withTw(
  "div",
  "flex flex-col space-y-200 print:hidden pl-400 pr-300 pb-24 pt-24 text-sm min-w-224 max-w-224 mr-400 bg-neutral-50"
);
export const StepRow = withTw("div", "flex flex-row");
export const StepContent = withTw("div", "flex flex-col space-y-24 w-full pb-32");
export const Step = withTw("div", "w-full");
export const StepNo = withTw("div", "text-neutral-600 text-style-label-tiny-bold");
export const StepHeading = withTw("div", "text-style-label-small-bold");
export const StepDesc = withTw("div", "text-label-small");
