/* eslint-disable no-restricted-globals */
import type { Effect } from "redux-saga/effects";
import { take, put, call } from "redux-saga/effects";
import * as uuid from "uuid";
import { delay } from "redux-saga";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Statistics from "shared-lib/statistics";
import * as Search from "shared-lib/search";
import { retryFetch } from "shared-lib/fetch";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { globalSearchReset } from "global-search-reset";
import type { RehydrateAction } from "redux-persist";
import { clientConfig } from "config";
import type { StartCalculation } from "./actions";
import { calculationFinished, rehydrate, reset } from "./actions";
import { resolveQuery } from "../redux-query-resolver";
import { shouldSearchBeReset } from "./reducer";
import type { State } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* saga(): Generator<Effect, void, StartCalculation & ReadonlyArray<Search.Result>> {
  while (true) {
    const action: StartCalculation = yield take("search/START_CALCULATION");

    yield delay(0);

    const searchId = uuid();
    // console.time("Calculation");
    const start = performance.now();

    const allResults: ReadonlyArray<Search.Result> = yield clientConfig.calculateOnServer
      ? call(calculateProductVariantsOnServer, action.payload.ownProps)
      : call(calculateProductVariantsOnClient, action.payload.ownProps);

    yield put(
      calculationFinished(
        action.payload.source,
        allResults,
        action.payload.ownProps.searchProductId,
        action.payload.ownProps.properties,
        searchId,
        action.payload.ownProps.language,
        action.payload.ownProps.market
      )
    );
    const executionTime = performance.now() - start;

    Statistics.registerSearch(action.payload.ownProps, allResults, executionTime, searchId);

    console.log(`Calculation: ${executionTime}ms`);
  }
}

type Response = {
  readonly source: QP.DataSourceResponse;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
};
function dataSourceQuery(): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    source: QP.dataSource(),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"), // Need to get a table for data source to update
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* sagaHandleRehydrate(): Generator<Effect, void, RehydrateAction & Response> {
  while (true) {
    const action: RehydrateAction = yield take("persist/REHYDRATE");
    yield delay(0);
    if (action.key !== "root") {
      continue;
    }

    const response: Response = yield call(async () => {
      const resolved = await resolveQuery(dataSourceQuery, {});
      return resolved;
    });
    globalSearchReset.source = response.source;

    if (shouldSearchBeReset(action)) {
      yield put(reset());
    } else if (action.payload && Object.prototype.hasOwnProperty.call(action.payload, "search")) {
      yield put(rehydrate((action.payload as { search: State }).search));
    }
  }
}

async function calculateProductVariantsOnClient(ownProps: Search.OwnProps): Promise<ReadonlyArray<Search.Result>> {
  const response = await resolveQuery(Search.searchQuery, {
    ecomUrl: ownProps.ecomUrl,
    searchProductId: ownProps.searchProductId,
    properties: [],
    calcParams: PropertyValueSet.Empty,
    attributes: [],
    accessories: [],
    market: ownProps.market,
    language: ownProps.language,
    externalApiType: ownProps.externalApiType,
    overallSortParams: {
      sortPath: "",
      sortType: undefined,
      descending: false,
    },
    groupSortParams: undefined,
  });
  return Search.calculateProductVariants(ownProps, response);
}

async function calculateProductVariantsOnServer(ownProps: Search.OwnProps): Promise<ReadonlyArray<Search.Result>> {
  const response = await retryFetch(`${clientConfig.calculationServer}/calculate/product/multiple`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ownProps),
  });
  // console.time("Decode");
  // eslint-disable-next-line no-return-await
  return response.json();
  // const json = await response.json();
  // return Search.decode(json);
}
