import * as R from "ramda";
import * as AbstractImage from "abstract-image";

const minPixelsPerMainTick = 40;
const minPixelsPerSubTick = 15;

export type Axis = LinearAxis | LogarithmicAxis | LinearFixedStepAxis;

export interface Tick {
  readonly main: boolean;
  readonly value: number;
}

export interface LinearAxis {
  readonly type: "linear";
  readonly min: number;
  readonly max: number;
  readonly label: string;
}

export function createLinearAxis(min: number, max: number, label: string): LinearAxis {
  return {
    type: "linear",
    min: min,
    max: max,
    label: label,
  };
}

export interface LinearFixedStepAxis {
  readonly type: "linearFixed";
  readonly min: number;
  readonly max: number;
  readonly label: string;
  readonly step: number;
}

export function createLinearFixedStepAxis(min: number, max: number, label: string, step: number): LinearFixedStepAxis {
  return {
    type: "linearFixed",
    min: min,
    max: max,
    label: label,
    step: step,
  };
}

export interface LogarithmicAxis {
  readonly type: "logarithmic";
  readonly min: number;
  readonly max: number;
  readonly label: string;
}

export function createLogarithmicAxis(min: number, max: number, label: string): LogarithmicAxis {
  return {
    type: "logarithmic",
    min: Math.max(min, 0.00001),
    max: max,
    label: label,
  };
}

const linearMultiples = [1, 2, 5];

export function getTicks(pixels: number, axis: Axis): ReadonlyArray<Tick> {
  switch (axis.type) {
    case "linear":
      return getLinearTicks(pixels, axis.min, axis.max);
    case "logarithmic":
      return getLogarithmicTicks(pixels, axis.min, axis.max);
    case "linearFixed":
      return getLinearFixedTicks(axis.min, axis.max, axis.step);
    default:
      throw new Error("Unknown axis type");
  }
}

function getLinearTicks(pixels: number, min: number, max: number): ReadonlyArray<Tick> {
  const dataPower = Math.ceil(Math.log10(Math.abs(max)));
  const matchingPowers = R.range(dataPower - 2, dataPower);
  let best: ReadonlyArray<Tick> = [];
  for (const power of matchingPowers) {
    for (const subMultiple of linearMultiples) {
      const ticks = createLinearTicks(power, subMultiple, pixels, min, max);
      if (ticks === undefined) {
        continue;
      }
      if (ticks.length > best.length) {
        best = ticks;
      }
    }
  }
  return best || [];
}

function getLinearFixedTicks(min: number, max: number, step: number): ReadonlyArray<Tick> {
  const ticks = [];
  for (let x = min; x <= max + 0.001; x += step) {
    ticks.push(x);
  }
  return ticks.map((v) => ({ value: v, main: true }));
}

function makeFinalTicks(main: ReadonlyArray<number>, sub: ReadonlyArray<number>): ReadonlyArray<Tick> {
  let i = 0;
  const ticks: Array<Tick> = [];
  for (const tick of sub) {
    if (i < main.length && main[i] === tick) {
      ticks.push({
        value: tick,
        main: true,
      });
      ++i;
    } else {
      ticks.push({
        value: tick,
        main: false,
      });
    }
  }
  return ticks;
}

function createLinearTicks(
  power: number,
  multiple: number,
  pixels: number,
  min: number,
  max: number
): ReadonlyArray<Tick> | undefined {
  // eslint-disable-next-line no-restricted-properties
  const base = Math.pow(10, power);
  const step = base * multiple;
  const pixelsPerSubTick = (pixels * step) / (max - min);
  if (pixelsPerSubTick < minPixelsPerSubTick) {
    return undefined;
  }

  const cMin = Math.ceil(min / step);
  const cMax = Math.floor(max / step);
  const lines = cMax - cMin + 1;
  if (lines < 3) {
    return undefined;
  }

  const ticks = R.range(cMin, cMax + 1).filter((l) => l * step >= min && l * step <= max);

  let mainEvery = Math.ceil(minPixelsPerMainTick / pixelsPerSubTick);
  if (mainEvery > 5) {
    mainEvery = 10;
  } else if (mainEvery > 2 && ticks.length > 5) {
    mainEvery = 5;
  }
  const ticksWithMains = ticks.map((l) => ({ value: l * step, main: l % mainEvery === 0 }));

  if (ticksWithMains.length < 5) {
    return undefined;
  }

  return ticksWithMains;
}

const logarithmicAlternatives = [
  { main: [0, 5], sub: [0, 1, 2, 3, 4, 5] },
  { main: [0, 1, 2, 5], sub: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 3, 4, 5] },
  { main: [0, 1, 2, 3, 5], sub: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 2.5, 3, 4, 5] },
  {
    main: [0, 1, 2, 3, 5, 8],
    sub: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9],
  },
  {
    main: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    sub: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8.5, 9, 9.5],
  },
];

function getLogarithmicTicks(pixels: number, min: number, max: number): ReadonlyArray<Tick> {
  const desiredTicks = pixels / (minPixelsPerMainTick * 2);
  const minPow = Math.floor(Math.log10(min)) - 1;
  const maxPow = Math.ceil(Math.log10(max)) + 1;
  const powers = R.range(0, maxPow - minPow + 1).map((p) => minPow + p);
  const alternatives = logarithmicAlternatives.map((stepAlt) => {
    const altTextLines = powers.reduce((lines: Array<number>, power: number) => {
      // eslint-disable-next-line no-restricted-properties
      const base = Math.pow(10, power);
      const powerLines = stepAlt.main.map((i) => i * base);
      return lines.concat(powerLines);
    }, []);
    const altLines = powers.reduce((lines: Array<number>, power: number) => {
      // eslint-disable-next-line no-restricted-properties
      const base = Math.pow(10, power);
      const powerLines = stepAlt.sub.map((i) => i * base);
      return lines.concat(powerLines);
    }, []);
    return {
      main: altTextLines.filter((l) => l >= min && l <= max),
      sub: altLines.filter((l) => l >= min && l <= max),
    };
  });
  const bestLines = alternatives.reduce((prev, alt) =>
    Math.abs(alt.main.length - desiredTicks) < Math.abs(prev.main.length - desiredTicks) ? alt : prev
  );
  return makeFinalTicks(bestLines.main, bestLines.sub);
}

export function transformPoint(
  point: AbstractImage.Point,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  xAxis: Axis | undefined,
  yAxis: Axis | undefined
): AbstractImage.Point {
  const x = transformValue(point.x, xMin, xMax, xAxis);
  const y = transformValue(point.y, yMin, yMax, yAxis);
  return AbstractImage.createPoint(x, y);
}

export function transformValue(value: number, min: number, max: number, axis: Axis | undefined): number {
  if (!axis) {
    return value;
  }
  const range = max - min;
  switch (axis.type) {
    case "linear":
    case "linearFixed":
      return min + range * linearTransform(value, axis.min, axis.max);
    case "logarithmic":
      return min + range * logarithmicTransform(value, axis.min, axis.max);
    default:
      return 0;
  }
}

export function inverseTransformValue(value: number, min: number, max: number, axis: Axis | undefined): number {
  if (!axis) {
    return value;
  }
  const range = max - min;
  switch (axis.type) {
    case "linear":
    case "linearFixed":
      return inverseLinearTransform((value - min) / range, axis.min, axis.max);
    case "logarithmic":
      return inverseLogarithmicTransform((value - min) / range, axis.min, axis.max);
    default:
      return 0;
  }
}

function linearTransform(value: number, min: number, max: number): number {
  return (value - min) / (max - min);
}

function logarithmicTransform(value: number, min: number, max: number): number {
  if (value > 0) {
    return (Math.log10(value) - Math.log10(min)) / (Math.log10(max) - Math.log10(min));
  } else if (value < 0) {
    return 0.0;
  } else {
    return 0.0;
  }
}

function inverseLinearTransform(value: number, min: number, max: number): number {
  return min + value * (max - min);
}

function inverseLogarithmicTransform(value: number, min: number, max: number): number {
  // eslint-disable-next-line no-restricted-properties
  return Math.pow(10, value * (Math.log10(max) - Math.log10(min)) + Math.log10(min));
}
