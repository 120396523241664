/* eslint-disable functional/no-this-expression */
import * as React from "react";
import type { Unit } from "uom";
import { Serialize, UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import type { AnyQuantity } from "shared-lib/uom";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as Texts from "shared-lib/language-texts";
import * as Combobox from "./combobox";

export interface UnitSelectorProps {
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly units: ReadonlyArray<Unit.Unit<AnyQuantity>>;
  readonly unitChanged: (u: Unit.Unit<AnyQuantity>) => void;
  readonly unitCleared: () => void;
  readonly useFixedDropdownPos?: boolean;
  readonly allowUnitNameOverFlow?: boolean;
  readonly noBackgroundColor?: boolean;
  readonly translate: Texts.TranslateFunction;
  readonly small?: boolean;
  readonly noBorder?: boolean;
  readonly className?: string;
  readonly extraText?: string;
  readonly disableTabFoucus?: boolean;
}

// eslint-disable-next-line functional/no-class
export class UnitSelector extends React.Component<UnitSelectorProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: UnitSelectorProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: UnitSelectorProps): boolean {
    const { unit, units, translate, useFixedDropdownPos, noBackgroundColor } = this.props;
    if (
      unit !== nextProps.unit ||
      units !== nextProps.units ||
      translate !== nextProps.translate ||
      useFixedDropdownPos !== nextProps.useFixedDropdownPos ||
      noBackgroundColor !== nextProps.noBackgroundColor
    ) {
      return true;
    }
    return false;
  }

  render(): React.ReactElement<UnitSelectorProps> {
    const {
      unit,
      units,
      unitChanged,
      unitCleared,
      translate,
      useFixedDropdownPos,
      noBackgroundColor,
      allowUnitNameOverFlow,
      small,
      noBorder,
      className,
      extraText,
      disableTabFoucus,
    } = this.props;
    const includeReset = units.length > 1 || units[0] !== unit;
    const options = units.map((u) => ({
      value: Serialize.unitToString(u),
      label: translate(Texts.unitLabel(u), UnitFormat.getUnitFormat(u, UnitsFormat)?.label) + (extraText ?? ""),
    }));
    const resetOption = {
      value: "__reset",
      label: translate(Texts.reset(), "Reset"),
    };

    const unitOptions = includeReset ? [resetOption, ...options] : options;

    if (unitOptions.length === 1 && unitOptions[0].value === "One") {
      return <span></span>;
    }

    return (
      <Combobox.Combobox
        disableTabFoucus={disableTabFoucus}
        noBorder={noBorder}
        small={small}
        className={`w-full ${className ?? ""}`}
        value={Serialize.unitToString(unit)}
        options={unitOptions}
        useFixedPos={useFixedDropdownPos}
        noBackgroundColor={noBackgroundColor}
        allowOverFlow={allowUnitNameOverFlow}
        onChange={(newValue) => {
          if (newValue === "__reset") {
            unitCleared();
          } else {
            unitChanged(Serialize.stringToUnit(newValue, CustomUnitsLookup) as Unit.Unit<AnyQuantity>);
          }
        }}
      />
    );
  }
}
