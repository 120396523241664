import type * as Actions from "./actions";

export interface State {
  readonly searchTerm: string;
}

const initialState = {
  searchTerm: "",
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "frico-product-browser-epim/SET_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };
    case "frico-product-browser-epim/CLEAR_SELECTION":
      return initialState;
    default:
      return state;
  }
}
