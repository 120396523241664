import { retryFetch } from "shared-lib/fetch";
import * as Search from "../search";

//const statUrl = "http://localhost:3000/stat";
const statUrl = "https://stdsel-stat.systemair.com/stat";
const alwaysRegisterEvent = false;

function shouldRegisterEvent(): boolean {
  return (
    alwaysRegisterEvent ||
    (typeof window !== "undefined" &&
      window.location.hostname.indexOf("divid") === -1 &&
      window.location.hostname.indexOf("localhost") === -1 &&
      window.location.hostname.indexOf("stage") === -1)
  );
}

export async function registerSearch(
  props: Search.OwnProps,
  allResults: ReadonlyArray<Search.Result>,
  executionTimeMs: number,
  searchId: string
): Promise<void> {
  if (!shouldRegisterEvent()) {
    return;
  }
  const sortedResults = Search.getMatchingAndSortedResults(
    allResults,
    {
      sortPath: props.overallSortParams.sortPath,
      descending: props.overallSortParams.descending,
      sortType: props.overallSortParams.sortType,
    },
    props.groupSortParams
  );

  retryFetch(`${statUrl}/search`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      searchId: searchId,
      searchProductId: props.searchProductId,
      searchData: props,
      results: sortedResults.slice(0, 20).map((r) => r.result),
      time: executionTimeMs,
      crm: props.externalApiType === "crm",
      totalResults: allResults.length,
    }),
  });
}

export async function registerProductClick(
  searchId: string,
  resultId: string,
  itemNumber: string,
  variantId: string | undefined
): Promise<void> {
  if (!shouldRegisterEvent()) {
    return;
  }
  retryFetch(`${statUrl}/click`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      searchId: searchId,
      resultId: resultId,
      itemNumber: itemNumber,
      variantId: variantId,
    }),
  });
}
