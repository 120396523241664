/* eslint-disable no-restricted-properties */
const mh2o = 18.01534; // molar mass of water, g mol-1
const mDry = 28.9644; // molar mass of dry air, g mol-1
const specificDryAirGasConstant = 287.058; // J/(kg*K)
// const universalGasConstantMol =	8.31447;         // J/(mol*K)
const seaLevelAtmosphericPressure = 101300; // Pa
const temperatureLapseRate = 0.0065; // K/m
const seaLevelStandardTemperature = 288.15; // K
const pressureEquationPower = 5.2557812929; // g * M / R * L, where:
// L temperature lapse rate 0.0065 K/m
// g Earth-surface gravitational acceleration 9.80665 m/s2
// M molar mass of dry air 0.0289644 kg/mol
// R universal gas constant 8.31447 J/(mol•K)

const specificWaterVaporGasConstant = 461.495; // J/(kg*K)
const kelvinDelta = 273.15;
// const standardAirTemperature = 20;
export const standardAirPressure = 101325;
// const defaultSeaLevel = 0;
// const standardAirDensity = 1.204;

/// <summary>
/// Mass mixing ratio of dry air from temp, pressure and relative humidity; returns in kg/kg
/// </summary>
/// <param name="dryBulbTemperature">in degrees C</param>
/// <param name="pressure">in Pa</param>
/// <param name="relativeHumidity">in percents (0 - 1.0)</param>
/// <returns></returns>
export function relativeToMassMixingRatio(
  dryBulbTemperature: number,
  pressure: number,
  relativeHumidity: number
): number {
  if (dryBulbTemperature < -60) {
    throw new Error("Value out of range: dryBulbTemperature");
  }
  if (relativeHumidity > 1.0 || relativeHumidity < 0.0) {
    throw new Error("Value out of range: relativeHumidity");
  }
  const psat = h2opsat(dryBulbTemperature);
  const ph2o = psat * relativeHumidity;
  const vmr = ph2o / pressure;
  const spc = (vmr * mh2o) / (vmr * mh2o + (1.0 - vmr) * mDry);
  const mmv = spc / (1 - spc);
  return mmv;
}

/// <summary>
/// Relative humidity calculator from temperature, pressure and mass mixing ratio; returns in (0-1) range
/// </summary>
/// <param name="dryBulbTemperature">in degrees C</param>
/// <param name="pressure">in hPa</param>
/// <param name="massMixingRatio">in kg/kg</param>
/// <returns></returns>
export function massMixingRatioToRelativeHumidity(
  dryBulbTemperature: number,
  pressure: number,
  massMixingRatio: number
): number {
  if (massMixingRatio < 0 || dryBulbTemperature < -60.0) {
    throw new Error("Value out of range.");
  }
  const spc = massMixingRatio / (1.0 + massMixingRatio);
  const vmr = mDry / (mDry - mh2o * (1.0 - 1.0 / spc));
  const psat = h2opsat(dryBulbTemperature);
  const ph2o = vmr * pressure;
  const rh = ph2o / psat;
  return rh;
}

/// <summary>
/// Moist air density calculator; returns in kg/m3
/// </summary>
/// <param name="dryBulbTemperature" >in degrees C</param>
/// <param name="pressure">in Pa</param>
/// <param name="relativeHumidity">in percents (0 - 1.0)</param>
/// <returns></returns>
export function moistAirDensity(dryBulbTemperature: number, pressure: number, relativeHumidity: number): number {
  if (relativeHumidity > 1.0 || relativeHumidity < 0.0) {
    throw new Error("Value out of range: relativeHumidity");
  }
  const h20p = h2opsat(dryBulbTemperature) * relativeHumidity;
  return (
    (pressure - h20p) / (specificDryAirGasConstant * (dryBulbTemperature + kelvinDelta)) +
    h20p / (specificWaterVaporGasConstant * (dryBulbTemperature + kelvinDelta))
  );
}

/// <summary>
/// Dry air density calculator; returns in kg/m3
/// </summary>
/// <param name="dryBulbTemperature" >in degrees C</param>
/// <param name="pressure">in Pa</param>
/// <returns></returns>
export function dryAirDensity(dryBulbTemperature: number, pressure: number): number {
  return pressure / (specificDryAirGasConstant * (dryBulbTemperature + kelvinDelta));
}

/// <summary>
/// Water vapor density calculator; returns in kg/m3
/// </summary>
/// <param name="dryBulbTemperature" >in degrees C</param>
/// <param name="pressure">in Pa</param>
/// <param name="relativeHumidity">in percents (0 - 1.0)</param>
/// <returns></returns>
export function waterVaporDensity(dryBulbTemperature: number, relativeHumidity: number): number {
  if (relativeHumidity > 1.0 || relativeHumidity < 0.0) {
    throw new Error("Value out of range: relativeHumidity");
  }
  return (
    (h2opsat(dryBulbTemperature) * relativeHumidity) /
    (specificWaterVaporGasConstant * (dryBulbTemperature + kelvinDelta))
  );
}

/// <summary>
/// H2O saturation pressure from Lowe & Ficke, 1974; returns in Pa
/// </summary>
/// <param name="temp">in degrees C</param>
/// <returns></returns>
function h2opsat(temp: number): number {
  if (temp < -60.0) {
    throw new Error("Value out of range: temp");
  }
  const pwat =
    6.107799961 +
    temp *
      (4.436518521e-1 +
        temp *
          (1.428945805e-2 +
            temp * (2.650648471e-4 + temp * (3.031240396e-6 + temp * (2.034080948e-8 + temp * 6.136820929e-11)))));
  const pice =
    6.109177956 +
    temp *
      (5.03469897e-1 +
        temp *
          (1.886013408e-2 +
            temp * (4.176223716e-4 + temp * (5.82472028e-6 + temp * (4.838803174e-8 + temp * 1.838826904e-10)))));
  return Math.min(pwat, pice) * 100.0;
}

/// <summary>
/// Calculates Saturation Vapor Pressure
/// </summary>
/// <param name="Ctemp">Temperature in Celsius</param>
/// <returns></returns>
function eSubs(ctemp: number): number {
  const es = 6.112 * Math.exp((17.67 * ctemp) / (ctemp + 243.5));
  return es;
}
/// <summary>
/// Calculates Vapor Pressure
/// </summary>
/// <param name="Es">Saturation Vapor Pressure </param>
/// <param name="rh">Relative humidity</param>
/// <returns></returns>
function invertedRH(es: number, rh: number): number {
  const e = es * (rh / 100);
  return e;
}

/// <summary>
/// Calculates dew point
/// </summary>
/// <param name="E">Vapor Pressure</param>
/// <returns></returns>
function dewPoint(e: number): number {
  const dp = (243.5 * Math.log(e / 6.112)) / (17.67 - Math.log(e / 6.112));
  return dp;
}

/// <param name="Ctemp">Temperature in Celsius</param>
/// <param name="MBpressure">Station pressure in hPa</param>
/// <param name="E2">Vapor Pressure</param>
/// <returns></returns>
function calculateWetBulbTemperatureInternal(cTemp: number, mbPressure: number, e2: number): number {
  let twGuess = 0;
  let incr = 10;
  let previousSign = 1;
  let eDifference = 1;
  while (Math.abs(eDifference) > 0.005) {
    const ewGuess = 6.112 * Math.exp((17.67 * twGuess) / (twGuess + 243.5));
    const eGuess = ewGuess - mbPressure * (cTemp - twGuess) * 0.00066 * (1 + 0.00115 * twGuess);
    eDifference = e2 - eGuess;
    let curSign = 1;
    if (eDifference === 0) {
      break;
    } else {
      if (eDifference < 0) {
        curSign = -1;
      } else {
        curSign = 1;
      }

      if (curSign !== previousSign) {
        previousSign = curSign;
        // eslint-disable-next-line operator-assignment
        incr = incr / 10;
      }
    }

    // eslint-disable-next-line operator-assignment
    twGuess = twGuess + incr * previousSign;
  }
  const wetbulb = twGuess;
  return wetbulb;
}

/// <param name="dryBulbTemperature">in degrees C</param>
/// <param name="relativeHumidity">in percents (0 - 100)</param>
/// <param name="stationPressure">in Pa</param>
export function calculateWetBulbTemperature(
  dryBulbTemperature: number,
  relativeHumidity: number,
  stationPressure: number = standardAirPressure
): number {
  // source of this formula https://www.easycalculation.com/weather/dewpoint-wetbulb-calculator.php check javascript
  const mbPressure = stationPressure * 0.01;
  const es = eSubs(dryBulbTemperature);
  const e2 = invertedRH(es, relativeHumidity);
  const wetbulb = calculateWetBulbTemperatureInternal(dryBulbTemperature, mbPressure, e2);
  return wetbulb;
}

/// <param name="dryBulbTemperature">in degrees C</param>
/// <param name="relativeHumidity">in percents (0 - 100)</param>
/// <param name="stationPressure">in Pa</param>
export function calculateRelativeHumidity(
  dryBulbTemperature: number,
  wetBulbTemperature: number,
  stationPressure: number = standardAirPressure
): number {
  // source of this formula http://www.ringbell.co.uk/info/humid.htm check javascript
  const atmos = stationPressure * 0.01;
  // calculate the Saturation Vapour Pressure (millibars) at dry bulb temp
  const es = 6.112 * Math.pow(Math.E, (17.67 * dryBulbTemperature) / (243.5 + dryBulbTemperature));
  // calculate the Saturation Vapour Pressure at Wet Temp in hPa/milibars
  const ew = 6.112 * Math.pow(Math.E, (17.67 * wetBulbTemperature) / (243.5 + wetBulbTemperature));
  // use wet bulb SVP to determine actual vapour pressure
  const e = ew - 0.00066 * (1 + 0.00115 * wetBulbTemperature) * (dryBulbTemperature - wetBulbTemperature) * atmos;
  // Percentage Humidity
  const percentHumid = 100 * (e / es);
  if (percentHumid < 0) {
    return 0;
  }
  if (percentHumid > 100) {
    return 100;
  }
  return percentHumid;
}

/// <param name="dryBulbTemperature">in degrees C</param>
/// <param name="relativeHumidity">in percents (0 - 100)</param>
export function calculateDewPointTemperature(dryBulbTemperature: number, relativeHumidity: number): number {
  // source of this formula https://www.easycalculation.com/weather/dewpoint-wetbulb-calculator.php check javascript
  const es = eSubs(dryBulbTemperature);
  const e = invertedRH(es, relativeHumidity);
  const dp = dewPoint(e);
  return dp;
}

export function calculateSeaLevel(pressure: number): number {
  return (
    (1 - Math.pow(pressure / seaLevelAtmosphericPressure, 1 / pressureEquationPower)) *
    (seaLevelStandardTemperature / temperatureLapseRate)
  );
}

export function calculateDensity(pressure: number, temperature: number): number {
  return pressure / (specificDryAirGasConstant * (temperature + kelvinDelta));
}

export function calculatePressureFromSeaLevel(seaLevel: number): number {
  const powerBase = 1.0 - (temperatureLapseRate * seaLevel) / seaLevelStandardTemperature;
  return seaLevelAtmosphericPressure * Math.pow(powerBase, pressureEquationPower);
}

export function calculateTemperatureFromDensity(density: number, pressure: number): number {
  return pressure / (density * specificDryAirGasConstant) - kelvinDelta;
}

export function calculatePressureFromDensity(density: number, temperature: number): number {
  return density * specificDryAirGasConstant * (temperature + kelvinDelta);
}
