import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as log from "loglevel";
import * as Messages from "../messages";
import * as RoenEst from "../dll/roenest";
import * as Friterm from "../dll/friterm";
import * as Eurocoil from "../dll/eurocoil";
import * as CI from "../shared/calculation-inputs";
import type { Output, Input, WaterInput } from "./types";
import { controlAsString, versionAsString } from "./attributes";

export async function calculateWater(source: string, calculationInput: Input, waterInput: WaterInput): Promise<Output> {
  const { airFlow, control, version, manueverVoltage } = calculationInput;
  const {
    inletAirTemperature,
    inletAirHumidity,
    inletWaterTemperature,
    calculationMethod,
    outletWaterTemperature,
    waterFlow,
    outletAirTemperature,
    dllInput,
    fluidType,
    glycol,
    operatingMode,
  } = waterInput;

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const glycolPercentage = fluidType === 0 ? 0 : glycol ? Amount.valueAs(customUnits.Percent, glycol) : 0;
  const fluidTypeKey = `fluidType_${fluidType}`;
  const inletWaterTemperatureC = Amount.valueAs(customUnits.Celsius, inletWaterTemperature);
  const outletWaterTemperatureC = Amount.valueAs(customUnits.Celsius, outletWaterTemperature);
  const waterFlowLps = Amount.valueAs(customUnits.LiterPerSecond, waterFlow);
  const outletAirTemperatureC = Amount.valueAs(customUnits.Celsius, outletAirTemperature);
  const coilType = operatingMode === "heating" ? "Heater" : "Cooler";

  const messages: Array<Messages.Message> = [];

  if (dllInput.type === "friterm") {
    const calculationInputs = dllInput.calculationInputs;
    const fritermInput: Omit<Friterm.FritermInput, "dllPassword"> = {
      airFlow: airFlowLps, // l/s
      airTemperatureIn: inletAirTemperatureC, // C
      waterTempIn: inletWaterTemperatureC, // C
      waterTempOut: calculationMethod === 0 ? outletWaterTemperatureC : null, // C
      airHumidity: inletAirHumidityP, // %
      method: calculationMethod, // 0 = Outlet water tempererature supplied, 1 = water flow supplied, 2 output air temperature supplied
      geometry: CI.getString("Geometry", calculationInputs),
      waterFlow: calculationMethod === 1 ? waterFlowLps : null, // l/s
      airTemperatureOut: calculationMethod === 2 ? outletAirTemperatureC : null, // if method = 2 // C
      numberOfTubes: CI.getInt("NumberOfTubes", calculationInputs),
      tubeMaterial: CI.getString("TubeMaterial", calculationInputs),
      finsMaterial: CI.getString("FinsMaterial", calculationInputs),
      tubeThickness: CI.getFloat("TubeThickness", calculationInputs),
      finThickness: CI.getFloat("FinThickness", calculationInputs),
      finSpacing: CI.getFloat("FinSpacing", calculationInputs),
      length: CI.getFloat("Length", calculationInputs),
      rows: CI.getInt("Rows", calculationInputs),
      circuits: CI.getInt("Circuits", calculationInputs),
      coilType: getCoilType(coilType),
      // connectionMaterial: CI.getString("ConnectionMaterial", calculationInputs),
      // connectionIn: CI.getFloat("ConnectionIn", calculationInputs, 0),
      // connectionOut: CI.getFloat("ConnectionOut", calculationInputs, 0),
      correctionFactorWaterPressure: CI.getFloat("CorrectionFactorWaterPressure", calculationInputs, 1),
      correctionFactorPower: CI.getFloat("CorrectionFactorPower", calculationInputs, 1),
      manifoldMaterial: CI.getString("ConnectionMaterial", calculationInputs),
      manifoldInletDiameter: CI.getFloat("ConnectionIn", calculationInputs, 0),
      manifoldOutletDiameter: CI.getFloat("ConnectionOut", calculationInputs, 0),
      fluidType,
      mixtureRatio: glycolPercentage,
      // glycolPercentage,
      ErrorThrowException: 422,
    };
    const result = await Friterm.calculate(fritermInput);
    if (result.message !== undefined) {
      return { type: "error", messages: [Messages.Exception(source, result.message)] };
    }

    if (result.waterPressureDrop > 40 || result.waterPressureDrop < 1) {
      messages.push(Messages.Warning_CoilPressure(source));
    }

    return {
      type: "ok",
      airCurtain: {
        airFlow: airFlow,
        inletAirHumidity: inletAirHumidity,
        outletAirHumidity: Amount.create(result.airHumidityOut, customUnits.PercentHumidity, 1),
        airPressureDrop: Amount.create(result.airPressureDrop, customUnits.Pascal, 1),
        outletAirTemperature: Amount.create(result.airTemperatureOut, customUnits.Celsius, 1),
        waterFlow: Amount.create(result.waterFlow, customUnits.LiterPerSecond, 1),
        inletWaterTemperature: Amount.create(result.waterTemperatureIn, customUnits.Celsius, 1),
        outletWaterTemperature: Amount.create(result.waterTemperatureOut, customUnits.Celsius, 1),
        waterPressureDrop: Amount.create(result.waterPressureDrop, customUnits.KiloPascal, 1),
        glycol: fluidType === 0 ? undefined : Amount.create(glycolPercentage, customUnits.Percent, 1),
        fluid: fluidTypeKey,
        power: Amount.create(result.power, customUnits.KiloWatt, 1),
        shf: undefined,
        condensedWater: undefined,
        fluidSpecificHeat: undefined,
        inletAirTemperature: inletAirTemperature,
        control: controlAsString(control),
        version: versionAsString(version),
        coilCode: undefined,
        manueverVoltage: manueverVoltage !== undefined ? manueverVoltage.voltage : Amount.create(230, customUnits.Volt),
      },
      messages,
      outCalcParams: PropertyValueSet.Empty,
    };
  } else if (dllInput.type === "eurocoil") {
    const airFlowCmph = Amount.valueAs(customUnits.CubicMeterPerHour, airFlow);
    const waterFlowLph = Amount.valueAs(customUnits.LiterPerHour, waterFlow);
    const flagTuwQwVw = calculationMethod === 0 || calculationMethod === 1 ? calculationMethod : 0;
    const tuwQwVw =
      calculationMethod === 0
        ? outletWaterTemperatureC
        : calculationMethod === 1
        ? waterFlowLph
        : outletAirTemperatureC;
    try {
      const input: Eurocoil.EurocoilHeatInput = {
        Flg_DIM: true,
        Geometry: dllInput.geometry,
        Tube: dllInput.tubeDiameter,
        A: dllInput.finHeight,
        L: dllInput.finLength,
        NR: dllInput.rows,
        FinSpa: dllInput.finSpacing,
        NC: dllInput.circuits,
        TS: dllInput.unusedTubes,
        Fins: dllInput.finType,
        FinThickness: 0, // Should always be 0 according to the dll documentation
        TSA1: inletAirTemperatureC,
        FlagQaVa: 0, // By air flow
        QaVa: airFlowCmph,
        TW1: inletWaterTemperatureC,
        FlagTuwQwVw: flagTuwQwVw,
        TuwQwVw: tuwQwVw,
        Glic: glycolPercentage,
        Altitude: 0,
        OutAirTemp: calculationMethod === 2 ? outletAirTemperatureC : undefined,
      };
      const result =
        calculationMethod !== 2 ? await Eurocoil.calculateHeat(input) : await Eurocoil.calculateHeatByOutAirTemp(input);
      if (!result) {
        return { type: "error", messages: [Messages.Exception(source, "Eurocoil request failed")] };
      } else if (result.returnValue >= 0) {
        log.warn(`Eurocoil errorcode: ${result.returnValue}`);
        return { type: "error", messages: [Messages.Error_OutsideValidRange(source)] };
      }
      return {
        type: "ok",
        airCurtain: {
          airFlow: Amount.create(result.Qa, customUnits.CubicMeterPerHour, 1),
          inletAirHumidity: inletAirHumidity,
          outletAirHumidity: undefined, // Not calculated by the DLL
          airPressureDrop: Amount.create(result.DPA, customUnits.Pascal, 1),
          outletAirTemperature: Amount.create(result.TUA, customUnits.Celsius, 1),
          waterFlow: Amount.create(result.Qw, customUnits.LiterPerHour, 4),
          inletWaterTemperature: inletWaterTemperature,
          outletWaterTemperature: Amount.create(result.TUW, customUnits.Celsius, 1),
          waterPressureDrop: Amount.create(result.DPW, customUnits.KiloPascal, 2),
          glycol: fluidType === 0 ? undefined : Amount.create(glycolPercentage, customUnits.Percent, 1),
          fluid: fluidTypeKey,
          shf: undefined,
          condensedWater: undefined,
          fluidSpecificHeat: undefined,
          power: Amount.create(result.QTOT, customUnits.KiloWatt, 2),
          inletAirTemperature: inletAirTemperature,
          control: controlAsString(control),
          version: versionAsString(version),
          coilCode: dllInput.code,
          manueverVoltage:
            manueverVoltage !== undefined ? manueverVoltage.voltage : Amount.create(230, customUnits.Volt),
        },
        messages,
        outCalcParams: PropertyValueSet.Empty,
      };
    } catch (e) {
      return { type: "error", messages: [Messages.Exception(source, e.toString())] };
    }
  } else {
    const calculationInputs = dllInput.calculationInputs;
    // TODO BETTER
    try {
      CI.getFloat("Length", calculationInputs);
      CI.getFloat("Height", calculationInputs);
      CI.getInt("Rows", calculationInputs);
      CI.getFloat("FinSpacing", calculationInputs);
      CI.getInt("Circuits", calculationInputs);
      CI.getString("Geometry", calculationInputs);
      CI.getString("RowsMaterial", calculationInputs);
      CI.getString("FinsMaterial", calculationInputs);
      CI.getString("HeaderConf", calculationInputs);
      CI.getString("Header", calculationInputs);
    } catch (ex) {
      console.warn(ex);
      return { type: "error", messages: [Messages.Error_CalculationInputMissing(source)] };
    }
    // Heater = 1, Cooler = 2, Evaporator = 3, Condenser = 4
    const coilCalculationType = coilType === "Heater" ? 1 : 2;

    const roenEstInput: RoenEst.RoenEstInput = {
      AirFlow: airFlowLps, // l/s
      AirTemperatureIn: inletAirTemperatureC, // C
      WaterTempIn: inletWaterTemperatureC, // C
      WaterTempOut: calculationMethod === 0 ? outletWaterTemperatureC : null, // C
      AirHumidity: inletAirHumidityP, // %
      Method: calculationMethod, // 0 = Outlet water tempererature supplied, 1 = water flow supplied, 2 output air temperature supplied
      WaterFlow: calculationMethod === 1 ? waterFlowLps : null, // l/s
      AirTemperatureOut: calculationMethod === 2 ? outletAirTemperatureC : null, // if method = 2 // C
      Length: CI.getFloat("Length", calculationInputs),
      Height: CI.getFloat("Height", calculationInputs),
      Rows: CI.getInt("Rows", calculationInputs),
      FinSpacing: CI.getFloat("FinSpacing", calculationInputs),
      Circuits: CI.getInt("Circuits", calculationInputs),
      Geometry: CI.getString("Geometry", calculationInputs),
      RowsMaterial: CI.getString("RowsMaterial", calculationInputs),
      FinsMaterial: CI.getString("FinsMaterial", calculationInputs),
      FreeTubes:
        calculationInputs.find((r) => r.name === "FreeTubes") !== undefined
          ? CI.getInt("FreeTubes", calculationInputs)
          : null,
      HeaderConf: CI.getString("HeaderConf", calculationInputs),
      Header: CI.getString("Header", calculationInputs),
      // FinOption: getInt("FinOption", calculationInputs),
      FinOption: 0,
      CoilType: coilCalculationType,
      Glycol: glycolPercentage,
      FluidType: fluidType === undefined ? 1 : fluidType + 1, // Fluid type for DLL: 1 = water, 2 = etylen, 3 = propylen
    };

    const result = await RoenEst.calculate(roenEstInput);
    if (result.Message !== undefined) {
      return { type: "error", messages: [Messages.Exception(source, result.Message)] };
    }

    if (result.WaterPressureDrop > 40 || result.WaterPressureDrop < 1) {
      messages.push(Messages.Warning_CoilPressure(source));
    }
    const maxAirVelocity = 3; // m/s
    if (coilType === "Cooler" && result.AirVelocity > maxAirVelocity) {
      messages.push(
        Messages.Warning_AirVelocityTooHigh(source, Amount.create(maxAirVelocity, customUnits.MeterPerSecond))
      );
    }

    const outCalcParams = getOutCalcParams(calculationMethod, result);

    return {
      type: "ok",
      airCurtain: {
        airFlow: airFlow,
        airPressureDrop: Amount.create(result.AirPressureDrop, customUnits.Pascal, 1),
        waterPressureDrop: Amount.create(result.WaterPressureDrop, customUnits.KiloPascal, 1),
        outletAirTemperature: Amount.create(result.AirTemperatureOut, customUnits.Celsius, 1),
        inletAirHumidity: inletAirHumidity,
        outletAirHumidity: Amount.create(result.AirHumidityOut, customUnits.PercentHumidity, 1),
        waterFlow: Amount.create(result.WaterFlow, customUnits.LiterPerSecond, 3),
        inletWaterTemperature: Amount.create(result.WaterTemperatureIn, customUnits.Celsius, 1),
        inletAirTemperature: inletAirTemperature,
        outletWaterTemperature: Amount.create(result.WaterTemperatureOut, customUnits.Celsius, 1),
        glycol: fluidType === 0 ? undefined : Amount.create(glycolPercentage, customUnits.Percent, 1),
        fluid: fluidTypeKey,
        shf: result.Shf ? Amount.create(result.Shf, customUnits.One) : undefined,
        condensedWater: result.CondensedWater
          ? Amount.create(result.CondensedWater, customUnits.KilogramPerSecond)
          : undefined,
        fluidSpecificHeat: result.FluidSpecificHeat
          ? Amount.create(result.FluidSpecificHeat / 1000, customUnits.KilojoulePerKilogramKelvin)
          : undefined,
        power: Amount.create(result.Power, customUnits.KiloWatt, 1),
        control: controlAsString(control),
        version: versionAsString(version),
        coilCode: result.CoilCode,
        manueverVoltage: manueverVoltage !== undefined ? manueverVoltage.voltage : Amount.create(230, customUnits.Volt),
      },
      messages,
      outCalcParams,
    };
  }
}

function getOutCalcParams(calculationMethod: number, result: RoenEst.RoenEstResult): PropertyValueSet.PropertyValueSet {
  if (calculationMethod === 0) {
    // Outlet water temp specified by user
    return PropertyValueSet.setAmount(
      "waterFlow",
      Amount.create(result.WaterFlow, customUnits.LiterPerSecond, 3),
      PropertyValueSet.setAmount(
        "outletAirTemperature",
        Amount.create(result.AirTemperatureOut, customUnits.Celsius, 1),
        PropertyValueSet.Empty
      )
    );
  } else if (calculationMethod === 1) {
    // Water flow specified by user
    return PropertyValueSet.setAmount(
      "outletWaterTemperature",
      Amount.create(result.WaterTemperatureOut, customUnits.Celsius, 1),
      PropertyValueSet.setAmount(
        "outletAirTemperature",
        Amount.create(result.AirTemperatureOut, customUnits.Celsius, 1),
        PropertyValueSet.Empty
      )
    );
  } else {
    // Outlet air temp specified by user
    return PropertyValueSet.setAmount(
      "outletWaterTemperature",
      Amount.create(result.WaterTemperatureOut, customUnits.Celsius, 1),
      PropertyValueSet.setAmount(
        "waterFlow",
        Amount.create(result.WaterFlow, customUnits.LiterPerSecond, 3),
        PropertyValueSet.Empty
      )
    );
  }
}

function getCoilType(coilType: string): number {
  switch (coilType) {
    case "Heater":
      return 1;
    case "Cooler":
      return 2;
    case "Evaporator":
      return 2; // For now calculate DXRE as cooler
    case "Condenser":
      return 4;
    default:
      throw new Error("Unknown coiltype: " + coilType);
  }
}
