/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import * as Api from "../../promaster-api";
import * as Mappings from "./mappings";
import type { Products, Product } from "./types";
import type { TableName, ItemNoToProductId, VariantNoToProductId, RowMap, ProductIdToRetired } from "../table-types";
import { exhaustiveCheck } from "../../exhaustive-check";

export function mapRawProducts(
  rawProducts: ReadonlyArray<Api.RawProduct>
): { readonly products: Products; readonly productsByKey: Products } {
  const mappedProducts = rawProducts.map((rawProduct) => mapProduct(rawProduct));
  const idProductPairs = mappedProducts.map((p) => [p.id, p] as [string, Product]);
  const products = R.fromPairs(idProductPairs);

  const keyProductPairs = mappedProducts.map((p) => [p.key, p] as [string, Product]);
  const productsByKey = R.fromPairs(keyProductPairs);

  return { products, productsByKey };
}

export function mapItemNoToProduct(rawProductToM3: ReadonlyArray<Api.RawProduct>): ItemNoToProductId {
  const m3ToProductId: { [m3ItemNo: string]: string } = {};
  for (const product of rawProductToM3) {
    if (product.data.ct_ItemNo) {
      for (const m3ItemNo of product.data.ct_ItemNo) {
        m3ToProductId[m3ItemNo.item_no as "string" | "number"] = product.id;
      }
    }
    // if (product.data.ct_M3ItemNo) { // beacuse m3Itemno is removed
    //   for (const m3ItemNo of product.data.ct_M3ItemNo) {
    //     m3ToProductId[m3ItemNo.m3_item_no] = product.id;
    //   }
    // }
  }
  return m3ToProductId;
}

export function mapProductIdToRetired(rawProductToItemNo: ReadonlyArray<Api.RawProduct>): ProductIdToRetired {
  const productIdToRetired: { [productId: string]: boolean } = {};
  for (const product of rawProductToItemNo) {
    productIdToRetired[product.id] = product.retired;
  }
  return productIdToRetired;
}

export function mapVariantNoToProduct(rawProductToItemNo: ReadonlyArray<Api.RawProduct>): VariantNoToProductId {
  const variantNoToProductId: { [variantNo: string]: string } = {};
  for (const product of rawProductToItemNo) {
    if (product.data.ct_VariantNo) {
      for (const variantNo of product.data.ct_VariantNo) {
        variantNoToProductId[variantNo.variant_no as "string" | "number"] = product.id;
      }
    }
  }
  return variantNoToProductId;
}

function mapProduct(rawProduct: Api.RawProduct): Product {
  return {
    id: rawProduct.id,
    key: rawProduct.key,
    name: rawProduct.name,
    retired: rawProduct.retired,
    transactionId: rawProduct.transaction_id,
  };
}

export function mapTables(
  rawData: Api.RawProductData,
  tableNames: ReadonlyArray<TableName>
): { readonly [tableName: string]: object } {
  const response: { [tableName: string]: any } = {};
  for (const tableName of tableNames) {
    const mapped = mapTable(rawData, tableName);
    response[tableName] = mapped ?? [];
  }
  return response;
}

function mapTable(rawData: Api.RawProductData, tableName: TableName): any {
  switch (tableName) {
    // Standard Modules
    case "property":
      return Api.mapProperty(rawData);
    case "code":
      return Api.mapCode(rawData);
    case "ct_ItemNo":
      return Api.mapTable(rawData, "ct_ItemNo", Mappings.itemNo) ?? [];
    case "ct_DiaqTemplates":
      return Api.mapTable(rawData, "ct_DiaqTemplates", Mappings.productDiaqTemplates);
    case "ct_CalculationInputs":
      return Api.mapTable(rawData, "ct_CalculationInputs", Mappings.calculationInputs);
    case "ct_AirData2":
      return createMap(Api.mapTable(rawData, "ct_AirData2", Mappings.airData), (r) => r.meas);
    case "ct_SoundData":
      return createMap(Api.mapTable(rawData, "ct_SoundData", Mappings.soundData), (r) => r.measurement);
    case "ct_ItemNumberStatus":
      return Api.mapTable(rawData, "ct_ItemNumberStatus", Mappings.itemNumberStatus);
    case "text":
      return Api.mapText(rawData);
    case "language":
      return Api.mapLanguage(rawData);
    case "image":
      return Api.mapImage(rawData);
    // Custom tables
    case "ct_SearchMetaProducts":
      return Api.mapTable(rawData, "ct_SearchMetaProducts", Mappings.productSearchMetaProduct);
    case "ct_SearchProducts":
      return Api.mapTable(rawData, "ct_SearchProducts", Mappings.productSearchProduct);
    case "ct_SearchColumns":
      return Api.mapTable(rawData, "ct_SearchColumns", Mappings.productSearchColumn);
    case "ct_ResultItems":
      return Api.mapTable(rawData, "ct_ResultItems", Mappings.resultItems);
    case "ct_ResultViews":
      return Api.mapTable(rawData, "ct_ResultViews", Mappings.resultViews);
    case "ct_ResultVisualizerParamsTable":
      return Api.mapTable(rawData, "ct_ResultVisualizerParamsTable", Mappings.resultVisualizerParamsTable);
    case "ct_CalcParamDefault":
      return Api.mapTable(rawData, "ct_CalcParamDefault", Mappings.calcParamDefault);
    case "ct_Voltage":
      return Api.mapTable(rawData, "ct_Voltage", Mappings.voltage);
    case "ct_SearchViews":
      return Api.mapTable(rawData, "ct_SearchViews", Mappings.searchView);
    case "ct_ScoreGuide":
      return Api.mapTable(rawData, "ct_ScoreGuide", Mappings.scoreGuideData);
    case "ct_ScoreType":
      return Api.mapTable(rawData, "ct_ScoreType", Mappings.scoreTypeData);
    case "ct_AirFlowStep":
      return Api.mapTable(rawData, "ct_AirFlowStep", Mappings.airFlowStepData);
    case "ct_Accessories":
      return Api.mapTable(rawData, "ct_Accessories", Mappings.accessories);
    case "ct_Length":
      return Api.mapTable(rawData, "ct_Length", Mappings.length);
    case "ct_VerticalLimit":
      return Api.mapTable(rawData, "ct_VerticalLimit", Mappings.verticalLimit);
    case "ct_PropertySelectorTypes":
      return Api.mapTable(rawData, "ct_PropertySelectorTypes", Mappings.propertySelectorType);
    case "ct_CategoryLevels":
      return Api.mapTable(rawData, "ct_CategoryLevels", Mappings.categoryLevel);
    case "ct_Attributes2":
      return Api.mapTable(rawData, "ct_Attributes2", Mappings.attribute);
    case "ct_SearchAttributes":
      return Api.mapTable(rawData, "ct_SearchAttributes", Mappings.searchAttribute);
    case "ct_BrowserProducts":
      return Api.mapTable(rawData, "ct_BrowserProducts", Mappings.browserProduct);
    case "ct_SearchSortings":
      return Api.mapTable(rawData, "ct_SearchSortings", Mappings.searchSorting);
    case "ct_MarketUnits":
      return Api.mapTable(rawData, "ct_MarketUnits", Mappings.marketUnit);
    case "ct_MarketHiddenFields":
      return Api.mapTable(rawData, "ct_MarketHiddenFields", Mappings.marketHiddenField);
    case "ct_SavedResultColumns":
      return Api.mapTable(rawData, "ct_SavedResultColumns", Mappings.savedResultColumn);
    case "ct_TempEfficiencyCorrection":
      return Api.mapTable(rawData, "ct_TempEfficiencyCorrection", Mappings.tempEfficiencyCorrection);
    case "ct_AirLimitsData":
      return createMap(Api.mapTable(rawData, "ct_AirLimitsData", Mappings.airLimitsData), (r) => r.measurement);
    case "ct_ManueverVoltage":
      return Api.mapTable(rawData, "ct_ManueverVoltage", Mappings.manueverVoltage);
    case "ct_ClosedGroups":
      return Api.mapTable(rawData, "ct_ClosedGroups", Mappings.closedGroup);
    case "ct_FricoAccessories":
      return Api.mapTable(rawData, "ct_FricoAccessories", Mappings.fricoAccessory);
    case "ct_AccessoryRelations":
      return Api.mapTable(rawData, "ct_AccessoryRelations", Mappings.accessoryRelation);
    case "ct_CurtainFilterDimension":
      return Api.mapTable(rawData, "ct_CurtainFilterDimension", Mappings.curtainFilterDimension);
    case "ct_WaterCoilLimits":
      return Api.mapTable(rawData, "ct_WaterCoilLimits", Mappings.waterCoilLimits);
    case "ct_MarketSettings":
      return Api.mapTable(rawData, "ct_MarketSettings", Mappings.marketSetting);
    case "ct_SearchAccessories":
      return Api.mapTable(rawData, "ct_SearchAccessories", Mappings.searchAccessory);
    case "ct_SearchExcludeProperties":
      return Api.mapTable(rawData, "ct_SearchExcludeProperties", Mappings.searchExcludeProperty);
    case "ct_DivisionCurrency":
      return Api.mapTable(rawData, "ct_DivisionCurrency", Mappings.divisionCurrency);
    case "ct_PropertyFieldNames":
      return Api.mapTable(rawData, "ct_PropertyFieldNames", Mappings.propertyFieldName);
    case "ct_MaxPower":
      return Api.mapTable(rawData, "ct_MaxPower", Mappings.maxPower);
    case "ct_VariantNo":
      return Api.mapTable(rawData, "ct_VariantNo", Mappings.variantNo);
    case "ct_MarketPriceList":
      return Api.mapTable(rawData, "ct_MarketPriceList", Mappings.marketPriceList);
    case "ct_Curve":
      return Api.mapTable(rawData, "ct_Curve", Mappings.boxFanCurve);
    case "ct_CurvePoint":
      return Api.mapTable(rawData, "ct_CurvePoint", Mappings.boxFanCurvePoint);
    case "ct_Fan":
      return Api.mapTable(rawData, "ct_Fan", Mappings.boxFanFan);
    case "ct_Motor":
      return Api.mapTable(rawData, "ct_Motor", Mappings.boxFanMotor);
    case "ct_StandardFan":
      return Api.mapTable(rawData, "ct_StandardFan", Mappings.boxFanStandardFan);
    case "ct_FanCondition":
      return Api.mapTable(rawData, "ct_FanCondition", Mappings.boxFanFanCondition);
    case "ct_LanguageMapping":
      return Api.mapTable(rawData, "ct_LanguageMapping", Mappings.languageMapping);
    case "ct_AccessoryPressureDrop":
      return Api.mapTable(rawData, "ct_AccessoryPressureDrop", Mappings.accessoryPressureDrop);
    case "ct_FilterFunctions":
      return Api.mapTable(rawData, "ct_FilterFunctions", Mappings.filterFunctions);
    case "ct_CentrifugalMotor":
      return Api.mapTable(rawData, "ct_CentrifugalMotor", Mappings.centrifugalMotor);
    case "ct_DllLimit":
      return Api.mapTable(rawData, "ct_DllLimit", Mappings.dllLimit);
    case "ct_CentrifugalFan":
      return Api.mapTable(rawData, "ct_CentrifugalFan", Mappings.centrifugalFan);
    case "ct_AttributeTemplateMapping":
      return Api.mapTable(rawData, "ct_AttributeTemplateMapping", Mappings.attributeTemplateMapping);
    case "ct_EcomCategories":
      return Api.mapTable(rawData, "ct_EcomCategories", Mappings.ecomCategory);
    case "ct_EcomCategoryToProperty":
      return Api.mapTable(rawData, "ct_EcomCategoryToProperty", Mappings.ecomCategoryToProperty);
    case "ct_HiddenEcomCategories":
      return Api.mapTable(rawData, "ct_HiddenEcomCategories", Mappings.hiddenEcomCategories);
    case "ct_DllDbmCoilsFluidType":
      return Api.mapTable(rawData, "ct_DllDbmCoilsFluidType", Mappings.dllDbmCoilsFluidType);
    case "ct_AmcaStatements":
      return Api.mapTable(rawData, "ct_AmcaStatements", Mappings.amcaStatements);
    case "ct_BathroomFanSelection":
      return Api.mapTable(rawData, "ct_BathroomFanSelection", Mappings.bathroomFanSelection);
    case "ct_SpecifierResultMappingTable":
      return Api.mapTable(rawData, "ct_SpecifierResultMappingTable", Mappings.specifierResultMapping);
    default:
      return exhaustiveCheck(tableName);
  }
}

function createMap<T>(rows: ReadonlyArray<T> | undefined, rowToKey: (row: T) => string): RowMap<T> | undefined {
  if (rows === undefined) {
    return undefined;
  }
  return R.groupBy(rowToKey, rows);
}
