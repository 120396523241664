import * as React from "react";
import * as R from "ramda";
import type { AnyQuantity } from "shared-lib/uom";
import * as Texts from "shared-lib/language-texts";
import { ResultViewTable, ResultViewTableTd, ResultViewTableTh, UnitSelector, Heading3 } from "client-lib/elements";
import { Amount } from "uom";
import type * as QP from "shared-lib/query-product";
import * as Shared from "shared-lib/result-views";
import * as UserSettings from "shared-lib/user-settings";
import { isAllDefined } from "shared-lib/utils/filter-undefined";
import * as UserSettingsClient from "client-lib/user-settings";
import type { DispatchProp } from "client-lib/redux-integration";
import type { VisualizerOwnProps } from "../types";
import { renderResultTableRowIcon } from "../shared/shared";

export type Props = VisualizerOwnProps & Response & StateProps & DispatchProp<UserSettingsClient.Action>;
const resultTdFill = { width: "2px", height: "2px" };
export interface Response {
  readonly metaTables: {
    readonly ct_MarketUnits: QP.MarketUnitsTable;
    readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
    readonly ct_MarketHiddenFields: QP.MarketHiddenFieldsTable;
  };
}

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function MultiColumnTableVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { market, visualizerParams, translate, metaTables, userSettings, products } = props;
  const { ct_MarketUnits } = metaTables;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const getUnit = UserSettings.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettings.getUnits({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettings.getDecimals({ market, ct_MarketUnits });
  const params = visualizerParams.split(";");
  const columns = params[2].split(",");
  const results = Shared.getMultiColumnRows(
    market,
    metaTables,
    visualizerParams,
    products.map((p) => p.resultItemMap)
  );
  if (results.length === 0) {
    return <span />;
  }
  const singleProduct = props.products.length === 1;

  const renderedHeaderValues = R.range(0, products.length).map((idx) => (
    <React.Fragment key={idx}>
      {columns.map((c) => (
        <ResultViewTableTh key={`col_${c}`} textAlign="center">
          {translate(Texts.createText(c))}
        </ResultViewTableTh>
      ))}
    </React.Fragment>
  ));
  const renderedHeaderValuesWithPadding = singleProduct
    ? renderedHeaderValues
    : renderedHeaderValues.map((v, idx) => (
        <React.Fragment key={idx}>
          {idx !== 0 ? <th /> : null}
          {v}
          <th />
        </React.Fragment>
      ));

  return (
    <ResultViewTable equalWidths={true} noTopMargin={params[0].length === 0}>
      {!singleProduct ? (
        <colgroup key={"multi"}>
          <col key={"label"} className="w-1/6"></col>
          {props.products.map((_p, idx) => (
            <React.Fragment key={idx}>
              <col key={`left_empty`} />
              {R.range(0, columns.length).map((i) => (
                <col key={`value_${i}`}></col>
              ))}
              <col key={`right_empty`} />
            </React.Fragment>
          ))}
          <col key={"selector"} className="w-1/6"></col>
          <col key={"empty"} className="w-26"></col>
        </colgroup>
      ) : (
        <colgroup key={"single"}>
          <col key={"single_label"}></col>
          {R.range(0, columns.length).map((i) => (
            <col key={`single_value_${i}`} className="w-1/5"></col>
          ))}
          <col key={"single_selector"} className="w-144"></col>
          <col key={"single_empty"} className="w-1/5"></col>
        </colgroup>
      )}
      <thead>
        <tr>
          <ResultViewTableTh colSpan={singleProduct ? 1 : 2}>
            {params[0].length > 0 && (
              <Heading3 style={{ marginLeft: "5px" }}>{translate(Texts.createText(params[0]))}</Heading3>
            )}
          </ResultViewTableTh>
          {renderedHeaderValuesWithPadding}
          <th colSpan={2} />
        </tr>
      </thead>
      <tbody>
        {results.map((r) =>
          renderResultTableRow(r.item, columns.length, r.allValues, getUnit, getUnits, getDecimals, props)
        )}
      </tbody>
    </ResultViewTable>
  );
}

function renderResultTableRow(
  item: QP.ResultVisualizerParamsTable,
  numColumns: number,
  allValues: ReadonlyArray<ReadonlyArray<Shared.TableResultValue>>,
  getUnit: UserSettings.GetUnitFunction,
  getUnits: UserSettings.GetUnitsFunction,
  getDecimals: UserSettings.GetDecimalsFunction,
  props: Props
): React.ReactElement {
  const translate = props.translate;
  const singleProduct = props.products.length === 1;
  const renderedValues = allValues.map((v, idx) =>
    renderResultValue(`result_value_${idx}`, item, numColumns, v, getUnit, getDecimals, props)
  );
  const renderedValuesWithPadding = singleProduct
    ? renderedValues
    : renderedValues.map((v, idx) => (
        <React.Fragment key={idx}>
          {idx !== 0 ? <td /> : null}
          {v}
          <td />
        </React.Fragment>
      ));

  return (
    <tr key={item.label}>
      <ResultViewTableTd key={"label"} colSpan={singleProduct ? 1 : 2}>
        <div className="inline-flex">
          <span className="min-w-26">{renderResultTableRowIcon(item.icon)}</span>
          <span>{translate(Texts.createText(item.label))}</span>
        </div>
      </ResultViewTableTd>
      {renderedValuesWithPadding}
      {renderUnitSelector(item, allValues, getUnit, getUnits, props)}
      <ResultViewTableTd key="empty" />
    </tr>
  );
}

function renderResultValue(
  key: string,
  item: QP.ResultVisualizerParamsTable,
  numColumns: number,
  values: ReadonlyArray<Shared.TableResultValue>,
  getUnit: UserSettings.GetUnitFunction,
  getDecimals: UserSettings.GetDecimalsFunction,
  props: Props
): React.ReactElement {
  const { translate } = props;
  const textAlign = "center";
  const paths = item.result_value_path.split(",");
  const colSpan = paths.length === 1 ? numColumns : 1;
  return (
    <React.Fragment key={key}>
      {values.map((value, idx) => {
        const tdKey = paths[idx];
        if (value === undefined) {
          return (
            <ResultViewTableTd key={tdKey} colSpan={colSpan} textAlign={textAlign}>
              -
            </ResultViewTableTd>
          );
        } else if (typeof value === "string") {
          return (
            <ResultViewTableTd key={tdKey} colSpan={colSpan} textAlign={textAlign}>
              {translate(Texts.createText(value), value)}
            </ResultViewTableTd>
          );
        } else if (value instanceof Array) {
          return (
            <ResultViewTableTd key={tdKey} colSpan={colSpan} textAlign={textAlign}>
              <p>{value.map((v) => translate(Texts.createText(v), v)).join(", ")}</p>
            </ResultViewTableTd>
          );
        } else {
          const amount = value as Amount.Amount<AnyQuantity>;
          const unit = getUnit(item.field_name, amount.unit.quantity);
          const decimals = getDecimals(item.field_name, unit);
          return (
            <ResultViewTableTd key={tdKey} colSpan={colSpan} textAlign={textAlign}>
              {Amount.valueAs(unit, amount).toFixed(decimals).toString()}
            </ResultViewTableTd>
          );
        }
      })}
    </React.Fragment>
  );
}

function renderUnitSelector(
  item: QP.ResultVisualizerParamsTable,
  allValues: ReadonlyArray<ReadonlyArray<Shared.TableResultValue>>,
  getUnit: UserSettings.GetUnitFunction,
  getUnits: UserSettings.GetUnitsFunction,
  props: Props
): React.ReactElement {
  const { translate, dispatch } = props;
  const values = R.unnest(allValues);
  const amount = values.find((value) => typeof value === "object" && !(value instanceof Array)) as
    | Amount.Amount<AnyQuantity>
    | undefined;
  const quantity = amount?.unit.quantity;
  if (quantity) {
    const unit = getUnit(item.field_name, quantity);
    const units = getUnits(item.field_name, quantity);
    return (
      <ResultViewTableTd>
        <UnitSelector
          className="max-w-144 float-right"
          noBackgroundColor={true}
          noBorder={true}
          unit={unit}
          units={units}
          unitChanged={(u) => dispatch(UserSettingsClient.setFieldUnit(item.field_name, u))}
          unitCleared={() => dispatch(UserSettingsClient.clearFieldUnit(item.field_name))}
          translate={translate}
        />
      </ResultViewTableTd>
    );
  } else {
    return (
      <ResultViewTableTd>
        <div style={resultTdFill} />
      </ResultViewTableTd>
    );
  }
}
