import type { UrlAndMarket, UrlMarketlanguageItem } from "./types";

export function identifierToKey(identifier: UrlAndMarket): string {
  return identifier.url + ":" + identifier.market;
}

export function productDescriptionsidentifierToKey(identifier: UrlMarketlanguageItem): string {
  return (
    identifier.url +
    ":" +
    identifier.market +
    ":" +
    identifier.language +
    ":" +
    identifier.itemNo +
    ":" +
    identifier.variantId
  );
}
