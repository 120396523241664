import * as FEIData from "./data";

// Calculates the Fan Efficiency Index according to AMCA 208 using SI units
//#region Defs
const q0 = 0.118;
const p0 = 100;
const rhoStd = 1.2;
const eta0Total = 0.66;
const eta0Static = 0.6;
const conversionConstant = 1000;

type FanCategory =
  | "Centrifugal Housed"
  | "Centrifugal Inline"
  | "Centrifugal Unhoused"
  | "Centrifugal PRV Exhaust"
  | "Centrifugal PRV Supply"
  | "Axial Inline"
  | "Axial Panel"
  | "Axial PRV"
  | "Laboratory Exhaust"
  | "Jet Fan"
  | "Circulating";
type PressureBasis = "static" | "total";
type DriveType = "V-Belt" | "Direct" | "Coupling" | "Synch Belt";
type MotorEnclosure =
  | "ODP HP Motor"
  | "TEFC HP Motor"
  | "ODP kW Motor"
  | "TEFC kW Motor"
  | "IE1 50Hz"
  | "IE2 50Hz"
  | "IE3 50Hz"
  | "IE4 50Hz"
  | "IE1 60Hz"
  | "IE2 60Hz"
  | "IE3 60Hz"
  | "IE4 60Hz"
  | "GB Grade 1"
  | "GB Grade 2"
  | "GB Grade 3"
  | "AO Motor"
  | "TEAO Motor";

type NumberOfPoles = 2 | 4 | 6 | 8;
type VFD = "VFD HP" | "VFD kW" | "DOL HP" | "DOL kW";
type MotorSize =
  | 1
  | 1.5
  | 2
  | 3
  | 5
  | 7.5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 40
  | 50
  | 60
  | 75
  | 100
  | 125
  | 150
  | 200
  | 250
  | 300
  | 350
  | 400
  | 450
  | 500;
type VFDCapacity =
  | 1
  | 1.5
  | 2
  | 3
  | 5
  | 7.5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 40
  | 50
  | 60
  | 75
  | 100
  | 125
  | 150
  | 200
  | 250
  | 300
  | 350
  | 400
  | 450
  | 500;
//#endregion

export function fanEfficiencyIndexFanWithoutMotor(
  fanCategory: FanCategory,
  pressureBasis: PressureBasis,
  airDensitykgm3: number,
  airflowM3sec: number,
  airPressurePa: number,
  driveType: DriveType,
  fanShaftPowerKw: number,
  fanFrequencyHz: number // Should be 50/60
): number | undefined {
  if (fanFrequencyHz !== 50 && fanFrequencyHz !== 60) {
    throw new Error("FanFrequency must be 50hz or 60hz");
  }

  const fanShaftPowerKwRoundedToOneDecimal = Math.round(fanShaftPowerKw * 10) / 10;
  const hiRef = calculateHiRefNoMotor(fanCategory, pressureBasis, airflowM3sec, airPressurePa, airDensitykgm3);
  const nTransRef = calculateNTransRef(hiRef);
  const nMtrRef = calculateMtrRef(hiRef, nTransRef);
  const fepRef = calculateFepRef(hiRef, nTransRef, nMtrRef);
  const nTransAct = calculateNTransAct(driveType, fanShaftPowerKwRoundedToOneDecimal);
  const htDef = calculateHtdef(fanShaftPowerKwRoundedToOneDecimal, nTransAct);
  const nMtrDef = calculateMtrDef(htDef, fanFrequencyHz);
  const fepAct = calculateFepAct(fanShaftPowerKwRoundedToOneDecimal, nTransAct, nMtrDef);
  return fepRef / fepAct;
}

function calculateHtdef(fanShaftPowerKw: number, nTransAct: number): number {
  return fanShaftPowerKw / nTransAct;
}

function calculateHiRefNoMotor(
  fanCategory: FanCategory,
  pressureBasis: PressureBasis,
  airflowM3sec: number,
  airPressurePa: number,
  airDensitykgm3: number
): number {
  if (fanCategory === "Circulating") {
    return airflowM3sec * (airPressurePa + airDensitykgm3 / 0.075);
  } else if (pressureBasis === "total") {
    return ((airflowM3sec + q0) * (airPressurePa + 100 * (airDensitykgm3 / 1.2))) / (1000 * 0.66);
  } else {
    return ((airflowM3sec + q0) * (airPressurePa + (100 * airDensitykgm3) / 1.204)) / (1000 * 0.6);
  }
}

export function fanEfficiencyIndexFanWithRegulatedMotor(
  driveType: DriveType,
  motorSize: MotorSize,
  motorEnclosure: MotorEnclosure,
  numberOfPoles: NumberOfPoles,
  includeVFD: VFD,
  VFDCapacity: VFDCapacity | undefined,
  pressureBasis: PressureBasis,
  airDensitykgm3: number,
  airflowM3sec: number,
  airPressurePa: number,
  fanShaftPowerKw: number
): number | undefined {
  // Todo verify drive type, motorenclosure,VFD at runtime

  const hiRef = calculateHiRef(pressureBasis, airDensitykgm3, airflowM3sec, airPressurePa);
  const nTransRef = calculateNTransRef(hiRef);
  const nMtrRef = calculateMtrRef(hiRef, nTransRef);
  const fepRef = calculateFepRef(hiRef, nTransRef, nMtrRef);
  const nTransAct = calculateNTransAct(driveType, fanShaftPowerKw);
  if (isOverload(fanShaftPowerKw, nTransAct, motorSize)) {
    return undefined;
  }

  // Todo nMtrACt can be 0, any case where we devide by it (YES!)
  const nMtrAct = calculateMtrAct(
    fanShaftPowerKw,
    nTransAct,
    motorSize,
    includeVFD,
    VFDCapacity,
    motorEnclosure,
    numberOfPoles
  );

  const fepAct = calculateFepAct(fanShaftPowerKw, nTransAct, nMtrAct);

  return fepRef / fepAct;
}

function isOverload(fanShaftPowerKw: number, nTransAct: number, motorSize: MotorSize): boolean {
  if (fanShaftPowerKw / nTransAct > 1.5 * motorSize) {
    // Overload
    return true;
  }
  return false;
}

function calculateFepAct(fanShaftPowerKw: number, nTransAct: number, nMtrAct: number): number {
  return fanShaftPowerKw * (1 / nTransAct) * (1 / nMtrAct);
}

function calculateMtrAct(
  fanShaftPowerKw: number,
  nTransAct: number,
  motorSize: MotorSize,
  includeVFD: VFD,
  VFDCapacity: VFDCapacity | undefined,
  motorEnclosue: MotorEnclosure,
  numberOfPoles: NumberOfPoles
): number {
  if ((includeVFD === "VFD HP" || includeVFD === "VFD kW") && VFDCapacity !== undefined) {
    return etaMtrAct(fanShaftPowerKw / nTransAct, motorSize, VFDCapacity, motorEnclosue, numberOfPoles, includeVFD);
  } else {
    return etaMtrAct(fanShaftPowerKw / nTransAct, motorSize, motorSize, motorEnclosue, numberOfPoles, includeVFD);
  }
}

function calculateNTransAct(driveType: DriveType, fanShaftPowerKw: number): number {
  switch (driveType) {
    case "Coupling":
      return 0.98;
    case "Direct":
      return 1;
    case "Synch Belt":
      if (fanShaftPowerKw <= 1) {
        return 0.94;
      } else if (fanShaftPowerKw <= 5) {
        return 0.01 * fanShaftPowerKw + 0.93;
      } else {
        return 0.98;
      }
    case "V-Belt":
      return 0.96 * (fanShaftPowerKw / (fanShaftPowerKw + 1.64)) ** 0.05;
    default:
      return 0;
  }
}

export function fanEfficiencyIndexWireToAir(
  pressureBasis: PressureBasis,
  airDensitykgm3: number,
  airflowM3sec: number,
  airPressurePa: number,
  inputPowerKw: number
): number | undefined {
  const hiRef = calculateHiRef(pressureBasis, airDensitykgm3, airflowM3sec, airPressurePa);
  const nTransRef = calculateNTransRef(hiRef);
  const nMtrRef = calculateMtrRef(hiRef, nTransRef);
  const fepRef = calculateFepRef(hiRef, nTransRef, nMtrRef);

  return fepRef / inputPowerKw;
}

function calculateHiRef(
  pressureBasis: PressureBasis,
  airDensitykgm3: number,
  airflowM3sec: number,
  airPressurePa: number
): number {
  const eta0 = pressureBasis === "total" ? eta0Total : eta0Static;
  return ((airflowM3sec + q0) * (airPressurePa + p0 * (airDensitykgm3 / rhoStd))) / (conversionConstant * eta0);
}

function calculateNTransRef(hiRef: number): number {
  //0,96*($R14/($R14+1,64))^0,05
  return 0.96 * (hiRef / (hiRef + 1.64)) ** 0.05;
}

const f9 = -0.0038;
const f10 = 0.0258;
const f11 = -0.0726;
const f12 = 0.1256;
const f13 = 0.8503;
function calculateMtrRef(hiRef: number, nTransRef: number): number {
  // AMCA 208 Tables'!F$9*(LOG(T14/U14))^4+'AMCA 208 Tables'!F$10*(LOG(T14/U14))^3+'AMCA 208 Tables'!F$11*(LOG(T14/U14))^2+'AMCA 208 Tables'!F$12*LOG(T14/U14)+'AMCA 208 Tables'!F$13)
  const refQuota = hiRef / nTransRef;
  return (
    f9 * Math.log10(refQuota) ** 4 +
    f10 * Math.log10(refQuota) ** 3 +
    f11 * Math.log10(refQuota) ** 2 +
    f12 * Math.log10(refQuota) +
    f13
  );
}

const j9 = -0.003812;
const j10 = 0.025834;
const j11 = -0.072577;
const j12 = 0.125559;
const j13 = 0.850274;

const k9 = 0;
const k10 = 0;
const k11 = 0;
const k12 = 0;
const k13 = 0.962;

const l9 = 0;
const l10 = 0.076356;
const l11 = 0.048236;
const l12 = 0.210903;
const l13 = 0.860998;

const m9 = 0;
const m10 = 0.000773;
const m11 = -0.018951;
const m12 = 0.092984;
const m13 = 0.837025;

function calculateMtrDef(htDef: number, frequency: number): number {
  const logOfHtDef = Math.log10(htDef);
  if (frequency === 60) {
    // 60HZ
    //(IF(Z18<185;)
    if (htDef < 185) {
      //'AMCA 208 Tables'!J$9*(LOG(Z18))^4+'AMCA 208 Tables'!J$10*(LOG(Z18))^3+'AMCA 208 Tables'!J$11*(LOG(Z18))^2+'AMCA 208 Tables'!J$12*LOG(Z18)+'AMCA 208 Tables'!J$13;

      return j9 * logOfHtDef ** 4 + j10 * logOfHtDef ** 3 + j11 * logOfHtDef ** 2 + j12 * logOfHtDef + j13;
    } else {
      //'AMCA 208 Tables'!K$9*(LOG(Z18))^4+'AMCA 208 Tables'!K$10*(LOG(Z18))^3+'AMCA 208 Tables'!K$11*(LOG(Z18))^2+'AMCA 208 Tables'!K$12*LOG(Z18)+'AMCA 208 Tables'!K$13)
      return k9 * logOfHtDef ** 4 + k10 * logOfHtDef ** 3 + k11 * logOfHtDef ** 2 + k12 * logOfHtDef + k13;
    }
  } // 50HZ
  // (IF(Z18<0,75;';'))
  else if (htDef < 0.75) {
    // AMCA 208 Tables'!L$9*(LOG(Z18))^4+'AMCA 208 Tables'!L$10*(LOG(Z18))^3+'AMCA 208 Tables'!L$11*(LOG(Z18))^2+'AMCA 208 Tables'!L$12*LOG(Z18)+'AMCA 208 Tables'!L$13
    return l9 * logOfHtDef ** 4 + l10 * logOfHtDef ** 3 + l11 * logOfHtDef ** 2 + l12 * logOfHtDef + l13;
  } else {
    return m9 * logOfHtDef ** 4 + m10 * logOfHtDef ** 3 + m11 * logOfHtDef ** 2 + m12 * logOfHtDef + m13;
  }
}

function calculateFepRef(hiRef: number, nTransRef: number, nMtrRef: number): number {
  return hiRef * (1 / nTransRef) * (1 / nMtrRef);
}

// Below VB function from Excel file
function etaMtrAct(
  motorOutputPower: number,
  motorNamePlate: MotorSize,
  vfdNamePlate: VFDCapacity,
  enclosure: MotorEnclosure,
  pole: NumberOfPoles,
  Vfd_Selection: VFD
): number {
  let motorRange: ReadonlyArray<FEIData.NominalMotorEfficiencyRow> = [];
  if (enclosure === "ODP HP Motor") {
    motorRange = FEIData.ODP_HP_Motor; //Sheets("AMCA 207 Tables").Range("A4:E28")
  } else if (enclosure === "TEFC HP Motor") {
    motorRange = FEIData.TEFC_HP_Motor; //Sheets("AMCA 207 Tables").Range("A33:E57")
  } else if (enclosure === "ODP kW Motor") {
    motorRange = FEIData.ODP_KW_MOTOR; //Sheets("AMCA 207 Tables").Range("A62:E86")
  } else if (enclosure === "TEFC kW Motor") {
    motorRange = FEIData.TEFC_KW_Motor; //Sheets("AMCA 207 Tables").Range("A91:E115")
  } else if (enclosure === "IE1 50Hz") {
    motorRange = FEIData.IE1_50Hz; // Sheets("AMCA 207 Tables").Range("A121:E150")
  } else if (enclosure === "IE2 50Hz") {
    motorRange = FEIData.IE2_50Hz; // Sheets("AMCA 207 Tables").Range("A155:E184")
  } else if (enclosure === "IE3 50Hz") {
    motorRange = FEIData.IE3_50Hz; //Sheets("AMCA 207 Tables").Range("A189:E218")
  } else if (enclosure === "IE4 50Hz") {
    motorRange = FEIData.IE4_50Hz; //Sheets("AMCA 207 Tables").Range("A766:E801")
  } else if (enclosure === "IE1 60Hz") {
    motorRange = FEIData.IE1_60Hz; // Sheets("AMCA 207 Tables").Range("A223:E248")
  } else if (enclosure === "IE2 60Hz") {
    motorRange = FEIData.IE2_60Hz; //Sheets("AMCA 207 Tables").Range("A254:E279")
  } else if (enclosure === "IE3 60Hz") {
    motorRange = FEIData.IE3_60Hz; //Sheets("AMCA 207 Tables").Range("A284:E309")
  } else if (enclosure === "IE4 60Hz") {
    motorRange = FEIData.IE4_60Hz; //Sheets("AMCA 207 Tables").Range("A806:E838")
  } else if (enclosure === "GB Grade 1") {
    motorRange = FEIData.GB_Grade_1; //Sheets("AMCA 207 Tables").Range("A314:D339")
  } else if (enclosure === "GB Grade 2") {
    motorRange = FEIData.GB_Grade_2; //Sheets("AMCA 207 Tables").Range("A344:D369")
  } else if (enclosure === "GB Grade 3") {
    motorRange = FEIData.GB_Grade_3; //Sheets("AMCA 207 Tables").Range("A374:D399")
  } else if (enclosure === "AO Motor") {
    motorRange = FEIData.AO_Motor; //Sheets("AMCA 207 Tables").Range("A622:E641")
  } else if (enclosure === "TEAO Motor") {
    motorRange = FEIData.TEAO_Motor; //Sheets("AMCA 207 Tables").Range("A646:E665")
  } else {
    return 0;
  }

  //MotorFLEff = Application.WorksheetFunction.VLookup(MotorNamePlate, MotorRange, MotorPole, False);
  const motorFleff = getMotorFLEff(motorNamePlate, motorRange, pole);
  if (motorFleff === undefined) {
    return 0;
  }

  // Motor Load Ratio
  const Lm = motorOutputPower / motorNamePlate;

  // Combined Motor and Vfd Efficiency
  if (Vfd_Selection === "VFD HP") {
    const VfdRange = FEIData.VFD_Driven_HP; //Sheets("AMCA 207 Tables").Range("A404:M428")
    const a_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "A"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, a_Pole, False)
    const b_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "B"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, b_Pole, False)
    const c_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "C"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, c_Pole, False)

    if (a_Vfd === undefined || b_Vfd === undefined || c_Vfd === undefined) {
      return 0;
    }

    //Motor Eff
    const MotorwVfdEff = motorFleff * ((a_Vfd * Lm) / (b_Vfd + Lm) + c_Vfd * Lm ** 2);

    const Lc = motorOutputPower / (vfdNamePlate * MotorwVfdEff);

    const VfdPerfRange = FEIData.VFD_Performance_Coefficienct_HP; //Sheets("AMCA 207 Tables").Range("A466:D490")
    const d_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "D"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 2, False)
    const e_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "E"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 3, False)
    const f_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "F"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 4, False)

    if (d_Vfd === undefined || e_Vfd === undefined || f_Vfd === undefined) {
      return 0;
    }
    //Vfd Eff
    const VfdEff = (d_Vfd * Lc) / (e_Vfd + Lc) + f_Vfd * Lc;

    //Combined Motor and Vfd Eff
    return MotorwVfdEff * VfdEff;
  } else if (Vfd_Selection === "VFD kW") {
    const VfdRange = FEIData.VFD_Driven_Kw; //Sheets("AMCA 207 Tables").Range("A433:M462")
    const a_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "A"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, a_Pole, False)
    const b_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "B"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, b_Pole, False)
    const c_Vfd = getVfdEfficiency(motorNamePlate, VfdRange, pole, "C"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, c_Pole, False)

    if (a_Vfd === undefined || b_Vfd === undefined || c_Vfd === undefined) {
      return 0;
    }

    //Motor Eff
    const MotorwVfdEff = motorFleff * ((a_Vfd * Lm) / (b_Vfd + Lm) + c_Vfd * Lm ** 2);

    const Lc = motorOutputPower / (vfdNamePlate * MotorwVfdEff);

    const VfdPerfRange = FEIData.VFD_Performance_Coefficienct_KW; // Sheets("AMCA 207 Tables").Range("A494:D523")
    const d_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "D"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 2, False)
    const e_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "E"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 3, False)
    const f_Vfd = getVfdPerformance(motorNamePlate, VfdPerfRange, "F"); //Application.WorksheetFunction.VLookup(MotorNamePlate, VfdRange, 4, False)

    if (d_Vfd === undefined || e_Vfd === undefined || f_Vfd === undefined) {
      return 0;
    }
    //Vfd Eff
    const VfdEff = (d_Vfd * Lc) / (e_Vfd + Lc) + f_Vfd * Lc;

    // Combined Motor and Vfd Eff
    return MotorwVfdEff * VfdEff;
  }

  // 'Direct on Line Motor Efficiency
  else if (Vfd_Selection === "DOL HP") {
    const DOLRange = FEIData.DOL_HP; //Sheets("AMCA 207 Tables").Range("A528:M552")
    const a_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "A"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, a_Pole, False)
    const b_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "B"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, b_Pole, False)
    const c_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "C"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, c_Pole, False)
    if (a_DOL === undefined || b_DOL === undefined || c_DOL === undefined) {
      return 0;
    }

    return motorFleff * ((a_DOL * Lm) / (b_DOL + Lm) + c_DOL * Lm ** 2);
  } else {
    const DOLRange = FEIData.DOL_KW; // Sheets("AMCA 207 Tables").Range("A557:M584")
    const a_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "A"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, a_Pole, False)
    const b_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "B"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, b_Pole, False)
    const c_DOL = getVfdEfficiency(motorNamePlate, DOLRange, pole, "C"); //Application.WorksheetFunction.VLookup(MotorNamePlate, DOLRange, c_Pole, False)
    if (a_DOL === undefined || b_DOL === undefined || c_DOL === undefined) {
      return 0;
    }
    return motorFleff * ((a_DOL * Lm) / (b_DOL + Lm) + c_DOL * Lm ** 2);
  }
}

function getMotorFLEff(
  motorNamePlate: MotorSize,
  motorRange: ReadonlyArray<FEIData.NominalMotorEfficiencyRow>,
  pole: NumberOfPoles
): number | undefined {
  const matchingRow = motorRange.find((mr) => mr.motorPower === motorNamePlate);
  if (!matchingRow) {
    return undefined;
  }

  switch (pole) {
    case 2:
      return matchingRow.pole2;
    case 4:
      return matchingRow.pole4;
    case 6:
      return matchingRow.pole6;
    case 8:
      return matchingRow.pole8;
    default:
      return undefined;
  }
}

function getVfdPerformance(
  motorNamePlate: MotorSize,
  vfdRange: ReadonlyArray<FEIData.VFDPerformanceCoefficienctRow>,
  coeficient: "D" | "E" | "F"
): number | undefined {
  const matchingRow = vfdRange.find((mr) => mr.power === motorNamePlate);
  if (!matchingRow) {
    return undefined;
  }

  switch (coeficient) {
    case "D":
      return matchingRow.d;
    case "E":
      return matchingRow.e;
    case "F":
      return matchingRow.f;
    default:
      return undefined;
  }
}

function getVfdEfficiency(
  motorNamePlate: MotorSize,
  vfdRange: ReadonlyArray<FEIData.InductionMoterEfficiencyRow>,
  pole: NumberOfPoles,
  coeficient: "A" | "B" | "C"
): number | undefined {
  const matchingRow = vfdRange.find((mr) => mr.power === motorNamePlate);
  if (!matchingRow) {
    return undefined;
  }

  switch (pole) {
    case 2:
      switch (coeficient) {
        case "A":
          return matchingRow.pole2a;
        case "B":
          return matchingRow.pole2b;
        case "C":
          return matchingRow.pole2c;
        default:
          return undefined;
      }

    case 4:
      switch (coeficient) {
        case "A":
          return matchingRow.pole4a;
        case "B":
          return matchingRow.pole4b;
        case "C":
          return matchingRow.pole4c;
        default:
          return undefined;
      }
    case 6:
      switch (coeficient) {
        case "A":
          return matchingRow.pole6a;
        case "B":
          return matchingRow.pole6b;
        case "C":
          return matchingRow.pole6c;
        default:
          return undefined;
      }
    case 8:
      switch (coeficient) {
        case "A":
          return matchingRow.pole8a;
        case "B":
          return matchingRow.pole8b;
        case "C":
          return matchingRow.pole8c;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
}
