import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { AirCurtain} from "../result-items-types";
import { createAirCurtainResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<AirCurtain>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createAirCurtainResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
