import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { BoxFan} from "../result-items-types";
import { createBoxFanResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<BoxFan>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createBoxFanResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
