import * as R from "ramda";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { diaqConnect } from "client-lib/redux-integration";
import { createSelector } from "reselect";
import type { Response } from "./container-component";
import { AmcaStatementsVisualizerContainerComponent } from "./container-component";
import type { VisualizerOwnProps, VisualizerProductProps } from "../types";
import type { RootState } from "../../../types";

const mapStateToProps = createSelector(
  (p: RootState) => p.settings.user,
  (userSettings) => ({
    userSettings: userSettings,
  })
);

function mapPropsToQuery(props: VisualizerOwnProps): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    metaTables: QP.tablesByProductId(QP.metaProductId, ["ct_MarketUnits"]),
    allProductTables: QD.createMapQuery(
      R.fromPairs(
        props.products
          .filter((p): p is VisualizerProductProps => !!p)
          .map((p) => [p.configKey, QP.tablesByProductId(p.productId, ["ct_AmcaStatements"])])
      )
    ),
  });
}

export const AmcaStatementsVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(AmcaStatementsVisualizerContainerComponent);
