/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import isPropValid from "@emotion/is-prop-valid";

type PropsRef = { readonly elementref?: React.RefObject<unknown> };

export function withTw<
  ElementTag extends keyof JSX.IntrinsicElements,
  ElementProps = JSX.IntrinsicElements[ElementTag],
  Props = unknown,
  FullProps = Props & ElementProps & PropsRef
>(
  elementTag: ElementTag,
  ...classNames: ReadonlyArray<string | ((p: Props) => string)>
): (props: FullProps) => JSX.Element {
  if (classNames.every((c) => typeof c === "string")) {
    const className = classNames.join(" ");
    return (props) =>
      React.createElement(elementTag, {
        ...getValidProps(props),
        ref: (props as PropsRef).elementref,
        className: [className, (props as React.HTMLAttributes<unknown>).className ?? ""].join(" "),
      });
  } else {
    return (props) => {
      const className = classNames
        .reduce((sofar, c) => {
          if (typeof c === "string") {
            sofar.push(c);
          } else {
            sofar.push(c((props as unknown) as Props));
          }
          return sofar;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, new Array<any>())
        .join(" ");
      return React.createElement(elementTag, {
        ...getValidProps(props),
        ref: (props as PropsRef).elementref,
        className: [className, (props as React.HTMLAttributes<unknown>).className ?? ""].join(" "),
      });
    };
  }
}

export function twClass<Props>(
  strings: ReadonlyArray<string>,
  ...args: ReadonlyArray<((p: Props) => string | undefined) | string | undefined>
): (props: Props) => string {
  return (props: Props): string => {
    const classNames = [strings[0]];
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (arg !== undefined) {
        if (typeof arg === "string") {
          classNames.push(arg);
        } else {
          classNames.push(arg(props) ?? "");
        }
      }
      classNames.push(strings[i + 1]);
    }
    return classNames.join(" ");
  };
}

// Props that aren't React props shouldn't be forwarded to createElement. This
// function removes all props that aren't valid React props.
function getValidProps(props: any): any {
  const validProps: any = {};
  for (const key of Object.keys(props)) {
    if (isPropValid(key)) {
      validProps[key] = props[key];
    }
  }
  return validProps;
}
