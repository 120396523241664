import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { ElectricDuctHeater} from "../result-items-types";
import { createElectricDuctHeaterResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<ElectricDuctHeater>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createElectricDuctHeaterResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
