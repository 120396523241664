import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import * as PU from "shared-lib/product-utils";
import * as Texts from "shared-lib/language-texts";
import * as C from "shared-lib/calculation";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { formatNumberFunction } from "shared-lib/utils";
import * as UserSettingsShared from "shared-lib/user-settings";
import { PropertyValueSet } from "@promaster-sdk/property";
import type { OwnProps, Response, ExternalItem, ExternalResult } from "./types";
import { createAlertType, isAlert } from "./types";
import { createCrmItems } from "./crm";
import { createSpecifierPayload } from "./specifier";

function externalItemsQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery({
    productId: QP.productIdByM3ItemNo(ownProps.m3ItemNo),
    productTables:
      response &&
      QP.tablesByProductId(response.productId, [
        "property",
        "ct_ItemNo",
        "ct_VariantNo",
        "code",
        "ct_DiaqTemplates",
        "ct_CalcParamDefault",
        "ct_Accessories",
        "ct_Attributes2",
        "ct_AmcaStatements",
      ]),
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultItems",
      "ct_ResultViews",
      "ct_MarketUnits",
      "ct_LanguageMapping",
      "ct_ResultVisualizerParamsTable",
      "ct_AttributeTemplateMapping",
      "ct_MarketHiddenFields",
      "ct_SpecifierResultMappingTable",
    ]),
    accessoryTables: accessoryTablesQuery(response),
    translateTables: response && Texts.getTablesQuery([response.productId]),
    calculationResponse: getCalculationQuery(ownProps, response),
  });
}

function getCalculationQuery(
  ownProps: OwnProps,
  response: Response | undefined
): QD.DiaqMapQuery<Response["calculationResponse"]> | undefined {
  if (!response) {
    return undefined;
  }
  const { market, m3ItemNo, variant, propsConfig, stateConfig } = ownProps;
  const { productId, productTables, metaTables, accessoryTables, calculationResponse } = response;

  if (
    !productId ||
    !productTables ||
    !metaTables ||
    !accessoryTables ||
    productTables.ct_Accessories.some((a) => !accessoryTables[a.product])
  ) {
    return undefined;
  }

  const fullConfig = PU.getFullConfig({
    market: market,
    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,
    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });

  if (!fullConfig) {
    throw new Error(`Could not find item number: ${m3ItemNo}, variant: ${variant}, market: ${market}`);
  }

  return C.calculationQuery({ productId: productId, config: fullConfig, variantId: variant }, calculationResponse);
}

function accessoryTablesQuery(
  response: Response | undefined
): QD.DiaqMapQuery<Response["accessoryTables"]> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_DiaqTemplates",
      "ct_CalcParamDefault",
      "ct_Accessories",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

async function getExternalItems(props: OwnProps, response: Response): Promise<ExternalResult> {
  const { language, m3ItemNo, variant, propsConfig, stateConfig, market, userSettings } = props;
  const { productId, productTables, metaTables, accessoryTables } = response;
  const { ct_MarketUnits, ct_LanguageMapping } = metaTables;

  if (!response.translateTables || !productTables || !accessoryTables || !response.calculationResponse) {
    throw new Error("Failed to load all data for print items");
  }
  const translate = Texts.translateFunctionSelector(response.translateTables, language);
  const fullConfig = PU.getFullConfig({
    market: market,

    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,

    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });

  if (!fullConfig) {
    throw new Error("Item number not found");
  }

  const attributes = Attributes.createMap(fullConfig.properties, productTables.ct_Attributes2);

  const calcParamsValid = C.ValidateCalcParams.validate(
    metaTables,
    productTables,
    accessoryTables,
    fullConfig,
    attributes
  );
  if (!calcParamsValid) {
    const mainExternalItem: ExternalItem = {
      itemNumber: m3ItemNo,
      variant: variant ?? "",
      items: [createAlertType("error", translate(Texts.invalid_parameters()))],
    };
    return { externalItems: [mainExternalItem], specifierPayload: undefined };
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getExtraText = UserSettingsShared.getExtraText({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });
  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const hideErrors =
    (propsConfig === undefined &&
      PropertyValueSet.isEmpty(stateConfig.calcParams) &&
      PropertyValueSet.isEmpty(stateConfig.properties)) ||
    !propsConfig ||
    PropertyValueSet.isEmpty(propsConfig.calcParams);

  const externalItems = await createCrmItems({
    getUnit,
    getExtraText,
    getDecimals,
    formatNumber,
    hideErrors,
    response,
    translate,
    fullConfig,
    attributes,
    productId,
    ownProps: props,
  });

  const specifierPayload = await createSpecifierPayload({
    getUnit,
    getExtraText,
    getDecimals,
    formatNumber,
    hideErrors,
    response,
    translate,
    fullConfig,
    attributes,
    productId,
    ownProps: props,
  });

  return { externalItems: externalItems, specifierPayload };
}

export async function createExternalItem(input: OwnProps, resolveQuery: QD.ResolveQueryFn): Promise<ExternalResult> {
  const response = await resolveQuery(externalItemsQuery, input);
  const externalResult = await getExternalItems(input, response);
  return externalResult;
}

export async function createExternalItemOld(input: OwnProps, resolveQuery: QD.ResolveQueryFn): Promise<ExternalItem> {
  const externalItems = await createExternalItem(input, resolveQuery);
  const alerts = [];
  const items = [];
  const addedAlerts = new Set();
  for (const ei of externalItems.externalItems) {
    for (const i of ei.items) {
      if (isAlert(i)) {
        const alertKey = `${i.type}_${i.text}`;
        if (!addedAlerts.has(alertKey)) {
          alerts.push(i);
          addedAlerts.add(alertKey);
        }
      } else {
        items.push(i);
      }
    }
  }
  const mainItem = externalItems.externalItems.find((ei) => ei.itemNumber === input.m3ItemNo);
  if (!mainItem) {
    // The main item should always be included among the returned items
    throw new Error("Main item not found");
  }
  return {
    itemNumber: mainItem.itemNumber,
    variant: mainItem.variant,
    items: [...alerts, ...items],
  };
}

export async function createExternalConfig(
  input: OwnProps,
  resolveQuery: QD.ResolveQueryFn
): Promise<string | undefined> {
  const response = await resolveQuery(externalItemsQuery, input);

  const { m3ItemNo, variant, propsConfig, stateConfig, market } = input;
  const { productTables, metaTables, accessoryTables, productId, calculationResponse } = response;

  const fullConfig = PU.getFullConfig({
    market: market,

    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,

    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });

  const results = await C.calculateSystem(
    { productId, config: fullConfig || C.emptyItemConfig, variantId: variant },
    calculationResponse
  );
  if (!results) {
    return undefined;
  }

  // If there are messages we dont allow a config to be exported, we still want the selected units:
  // There is a case where no input is entered, in this case we show the nominal result. The user might change a unit and print it
  // const componentMessages = SC.getMessages(results[productId]);
  // if (componentMessages.length > 0) {
  //   return C.serializeConfig({
  //     ...emptyItemConfig,
  //     meta: {
  //       fieldUnits: UserSettingsShared.getFieldUnitSettings({
  //         market,
  //         ct_MarketUnits: metaTables.ct_MarketUnits,
  //         userSettings: input.userSettings,
  //       }),
  //     },
  //   });
  // }

  const configWithMeta = {
    ...(fullConfig === undefined ? emptyItemConfig : fullConfig),
    meta: {
      ...input.userSettings,
    },
  };

  return (configWithMeta && C.serializeConfig(configWithMeta)) || undefined;
}

const emptyItemConfig = {
  properties: PropertyValueSet.Empty,
  calcParams: PropertyValueSet.Empty,
  secondCalcParams: undefined,
  accessories: [],
};
