import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export function initSentry(dsn: string | undefined, release: string, environment: string): void {
  if (dsn) {
    Sentry.init({
      dsn: dsn,
      environment: environment,
      integrations: [new Integrations.BrowserTracing()],
      sampleRate: 0.2,
      tracesSampleRate: 0.02,
      release: release,
    });
  }
}

export function setUser(email: string): void {
  Sentry.setUser({ email: email });
}
