import { debug } from "loglevel";
import fetch from "cross-fetch";

export async function waitFor(url: string, attempts: number = 20, intervalMs: number = 2000): Promise<void> {
  const sleep = (ms: number): Promise<void> => new Promise((r) => setTimeout(r, ms));

  let count = 1;

  while (count < attempts) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        if (response.status === 200) {
          return;
        }
      } else {
        count++;
      }
    } catch (e) {
      count++;
      debug(`Still down, trying ${count} of ${attempts}`);
    }

    await sleep(intervalMs);
  }

  throw new Error(`Server is down: ${count} attempts tried`);
}
