import * as Attributes from "./attributes";
import type { DiscreteInputParamValue } from "../types";

export function getSpeedControls(attributes: Attributes.Attributes): ReadonlyArray<DiscreteInputParamValue> {
  const controlTypes: Array<DiscreteInputParamValue> = [];
  const none = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "None");
  const transformer = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Transformer");
  const stepless = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Stepless");
  const frequency = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Frequency converter");
  if (transformer) {
    controlTypes.push({
      value: 2,
      name: "stepped",
    });
  }
  if (stepless) {
    controlTypes.push({
      value: 3,
      name: "stepless",
    });
  }
  if (frequency) {
    controlTypes.push({
      value: 4,
      name: "stepless",
    });
  }
  if (none || controlTypes.length === 0) {
    controlTypes.push({
      value: 1,
      name: "none",
    });
  }
  return controlTypes;
}

export function getDefaultMode(attributes: Attributes.Attributes): "Heating" | "Cooling" {
  const cooling = Attributes.matchesAttribute("selection-default-calculation-mode", attributes, "Cooling");
  const heating = Attributes.matchesAttribute("selection-default-calculation-mode", attributes, "Heating");
  return cooling ? "Cooling" : heating ? "Heating" : "Heating";
}
