/* eslint-disable no-return-await */
import type * as Types from "../types";
import type { Input } from "./types";
import { calculate } from "./calculator";
import type * as ResultTypes from "../result-items-types";

export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.BoxFanYilida>> {
  const calcParams = input.calcParams;
  const resultInput = { ...input, calcParams };
  const result = await calculate(resultInput);
  return result;
}
