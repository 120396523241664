import type * as QP from "shared-lib/query-product";
import { PropertyFilter } from "@promaster-sdk/property";

const allowedAttributes = new Set(["ecom-product-id", "ecom-category-id-MULTI"]);

export function getAttributesFromQueryParams(windowLocationSearch: string | undefined): QP.AttributesTable {
  const parameters = getAllQueryParameters(windowLocationSearch);

  const result: Array<QP.Attribute> = [];
  for (const key of Object.keys(parameters)) {
    if (!allowedAttributes.has(key)) {
      continue;
    }
    const values = parameters[key];
    for (const value of values) {
      result.push({
        property_filter: PropertyFilter.Empty,
        collection: "ECOM-HIERARCHY",
        attribute: key,
        value: value,
      });
    }
  }
  return result;
}

function getAllQueryParameters(
  windowLocationSearch: string | undefined
): { readonly [key: string]: ReadonlyArray<string> } {
  return windowLocationSearch
    ? windowLocationSearch
        .substr(1)
        .split(`&`)
        .reduce<{ [key: string]: Array<string> }>((sofar, curr) => {
          const [k, v] = curr.split(`=`);
          const value = v && decodeURIComponent(v);
          (sofar[k] = sofar[k] || []).push(value);
          return sofar;
        }, {})
    : {};
}
