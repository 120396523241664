/* eslint-disable functional/no-this-expression */
import * as React from "react";
import * as AbstractImage from "abstract-image";
import * as AC from "shared-lib/abstract-chart";
import { LinkButton, withTw } from "client-lib/elements";
import { saveAs } from "file-saver";
import * as Style from "shared-lib/style";
import { withSize } from "react-sizeme";
import { chartPadding } from "shared-lib/abstract-chart";
import type { FormatNumberFunction } from "shared-lib/utils";

interface DiagramDivProps {
  readonly onClick?: MouseCallback;
  readonly hoveredPoint: AbstractImage.Point | undefined;
}

const Diagram = withTw("div", (p: DiagramDivProps) => (p.onClick && p.hoveredPoint ? "cursor-crosshair" : ""));

export type Props = OwnProps & StateProps & SizeProps;

export type MouseCallback = (x: number, y: number) => void;
export type MouseHoverCallback = (point: AbstractImage.Point | undefined) => void;

export interface OwnProps {
  readonly id: string;
  readonly chart?: AC.Chart;
  readonly maxWidth: number;
  readonly maxHeight: number;
  readonly onClick?: MouseCallback;
  readonly onHover?: MouseHoverCallback;
  readonly showDownload?: boolean;
  readonly linesAndTextXAxisNoOfDecimals: number;
  readonly linesAndTextYAxisNoOfDecimals: number;
  readonly allowTextOverlap?: boolean;
  readonly formatNumber: FormatNumberFunction;
}

interface SizeProps {
  readonly size: {
    readonly width: number | null;
  };
}

export interface StateProps {
  readonly showAdvanced: boolean;
}

export interface LocalState {
  readonly hoveredPoint: AbstractImage.Point | undefined;
}

// eslint-disable-next-line functional/no-class
export class ViewerDiagramContainerComponentImpl extends React.Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredPoint: undefined,
    };
  }

  render(): React.ReactElement<Props> {
    const {
      chart,
      onClick,
      onHover,
      showAdvanced,
      showDownload,
      linesAndTextXAxisNoOfDecimals,
      linesAndTextYAxisNoOfDecimals,
      allowTextOverlap,
      formatNumber,
    } = this.props;
    if (!chart) {
      return <span />;
    }

    const hoveredPoint = this.state.hoveredPoint;
    const chartWithCorrectDecimals = {
      ...chart,
      linesAndTextXAxisNoOfDecimals,
      linesAndTextYAxisNoOfDecimals,
    };
    const chartWithHover = hoveredPoint
      ? {
          ...chartWithCorrectDecimals,
          components: [
            ...chart.components,
            AC.createChartPoint({
              color: Style.diagramGanymed.desiredPointColor,
              position: hoveredPoint,
            }),
          ],
        }
      : chartWithCorrectDecimals;
    // const size = AbstractImage.createSize(maxWidth, maxHeight);
    const size = getSize(this.props);
    const abstractImage = AC.renderChart(chartWithHover, size, { allowTextOverlap, formatNumber: formatNumber.format });
    const rendered = AbstractImage.createReactSvg(abstractImage);
    return (
      <div>
        <Diagram
          style={{ width: size.width }}
          hoveredPoint={hoveredPoint}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={onClick && ((e: any) => _onClick(e, this.props))}
          onMouseMove={(e) => {
            const eventPoint = getEventPoint(e, this.props);
            if (onClick) {
              this.setState({ hoveredPoint: eventPoint });
            }
            if (onHover) {
              onHover(eventPoint);
            }
          }}
          onMouseLeave={() => {
            if (onClick) {
              this.setState({ hoveredPoint: undefined });
            }
            if (onHover) {
              onHover(undefined);
            }
          }}
        >
          {rendered}
        </Diagram>
        {showAdvanced || showDownload ? (
          <LinkButton
            onClick={() => {
              const svg = AbstractImage.createSVG(abstractImage);
              const blob = new Blob([svg], {
                type: "image/svg+xml;charset=utf-8",
              });
              saveAs(blob, "Diagram.svg");
            }}
          >
            <i className="fa fa-download" />
          </LinkButton>
        ) : undefined}
      </div>
    );
  }
}
function getSize(props: Props & SizeProps): AbstractImage.Size {
  const { maxWidth, maxHeight } = props;
  const aspect = maxWidth / maxHeight;
  const desiredWidth = Math.min(maxWidth, props.size.width ?? maxWidth);
  const height = Math.min(maxHeight, desiredWidth / aspect);
  const width = Math.min(maxWidth, height * aspect);
  return AbstractImage.createSize(width, height);
}

function getEventPoint(e: React.MouseEvent<Element>, props: Props): AbstractImage.Point | undefined {
  if (!props.chart) {
    return undefined;
  }
  // const size = AbstractImage.createSize(props.maxWidth, props.maxHeight);
  const size = getSize(props);
  const target = e.currentTarget;
  const rect = target.getBoundingClientRect();
  const offsetX = e.clientX - rect.left;
  const offsetY = e.clientY - rect.top;
  const mousePoint = AbstractImage.createPoint(offsetX, offsetY);
  const diagramPoint = AC.inverseTransformPoint(mousePoint, size, props.chart, "bottom", "left");

  // Prevents interactions outside of the diagram area, the numbers are taken from chart.tsx padding variables
  if (
    mousePoint &&
    (offsetX > size.width - chartPadding.right ||
      offsetX < chartPadding.left ||
      offsetY < chartPadding.top ||
      offsetY > size.height - chartPadding.bottom)
  ) {
    return undefined;
  }
  return diagramPoint;
}

function _onClick(e: React.MouseEvent<Element>, props: Props): void {
  const point = getEventPoint(e, props);
  if (!point || !props.onClick) {
    return;
  }
  props.onClick(point.x, point.y);
}

export const ViewerDiagramContainerComponent = withSize({ monitorWidth: true })(
  ViewerDiagramContainerComponentImpl
) as (p: Props) => React.ReactElement<Props>;
