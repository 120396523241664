import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import * as QEcom from "shared-lib/query-ecom";
import * as ProductUtils from "shared-lib/product-utils";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import type { RootState } from "../../types";
import type { StateProps, Response, OwnProps } from "./container-component";
import { FricoAccessoriesTableContainerComponent } from "./container-component";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.fricoAccessoriesTableEpim,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (state, showAdvanced, userSettings) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_SearchProducts: QP.tableByProductId(QP.fricoProductId, "ct_SearchProducts"),
    itemNoToProductId: QP.itemNoToProductId(),
    tablesForProducts: tablesForProductsQuery(getMainProducts(response)),
    marketTables: ProductUtils.createMarketTablesQuery(ownProps.ecomUrl, ownProps.market),
    productDescriptions: productDescriptions(ownProps, response),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    accessoryTables: accessoryTablesQuery(ownProps, response),
  });
}

function accessoryTablesQuery(
  ownProps: OwnProps,
  response: Response | undefined
): QD.DiaqMapQuery<Response["accessoryTables"]> | undefined {
  if (!response || !isTablesForProductsQueryLoaded(response) || !response.marketTables) {
    return undefined;
  }

  const accessoryIds: Array<string> = [];
  for (const product of ownProps.addedProducts) {
    const accTable = response.tablesForProducts[product.productId];
    const accProductIds = accTable.ct_Accessories.map((acc) => acc.product);
    accessoryIds.push(...accProductIds);
  }

  const uniqueAccessories = R.uniq(accessoryIds);

  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of uniqueAccessories) {
    map[accessory] = QP.tablesByProductId(accessory, [
      "ct_Accessories",
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

function isAccessoryTablesQueryLoaded(ownProps: OwnProps, response: Response | undefined): boolean {
  if (!response || !response.tablesForProducts || !response.accessoryTables) {
    return false;
  }
  for (const product of ownProps.addedProducts) {
    const accTable = response.tablesForProducts[product.productId];
    const accProductIds = accTable.ct_Accessories.map((acc) => acc.product);
    if (accProductIds.some((id) => !response.accessoryTables[id])) {
      return false;
    }
  }
  return true;
}

function productDescriptions(
  ownProps: OwnProps,
  response: Response | undefined
): QD.DiaqMapQuery<Response["productDescriptions"]> | undefined {
  const accessories =
    response?.tablesForProducts && response?.marketTables && isAccessoryTablesQueryLoaded(ownProps, response)
      ? ProductUtils.getFricoIncludedAccessoriesEpim({
          products: ownProps.addedProducts,
          response: {
            tablesForProducts: response.tablesForProducts,
            tablesForAccessories: response.accessoryTables,
            marketTables: response.marketTables,
          },
          addedAccessories: ownProps.addedAccessories,
        })
      : undefined;
  if (accessories === undefined || !response || response.ct_LanguageMapping === undefined) {
    return undefined;
  }
  const language = Texts.getLanguage(ownProps.language, response.ct_LanguageMapping);
  const map: { [id: string]: QEcom.ProductDescriptionQuery } = {};
  for (const accessory of accessories) {
    map[accessory.variantId] = QEcom.productDescription(
      ownProps.specificationDataUrl,
      ownProps.market,
      language,
      accessory.itemNo,
      accessory.variantId
    );
  }

  return QD.createMapQuery(map);
}

function tablesForProductsQuery(
  products: ReadonlyArray<string> | undefined
): QD.DiaqMapQuery<Response["tablesForProducts"]> | undefined {
  if (products === undefined) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    map[product] = QP.tablesByProductId(product, [
      "ct_Accessories",
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

function isTablesForProductsQueryLoaded(response: Response | undefined): boolean {
  if (!response?.tablesForProducts) {
    return false;
  }
  const productIds = getMainProducts(response);
  if (!productIds) {
    return false;
  }
  return productIds.every((id) => !!response.tablesForProducts[id]);
}

function getMainProducts(response: Response | undefined): ReadonlyArray<string> | undefined {
  if (!response || !response.marketTables) {
    return undefined;
  }
  return ProductUtils.filterProductsByMarket(
    response.marketTables,
    response.ct_SearchProducts.map((p) => p.product)
  );
}

export const FricoAccessoriesTableContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(FricoAccessoriesTableContainerComponent);
