import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import type * as SC from "shared-lib/system-calculator";
import type { Unit } from "uom";
import type { Quantity } from "uom-units";
import type { PropertyValueSet } from "@promaster-sdk/property";
import { formatNumberFunction, isAllDefined } from "shared-lib/utils";
import * as UserSettingsShared from "shared-lib/user-settings";
import type { FanResultItem } from "shared-lib/system-calculator/result-items-types";
import * as FanDiagramMultiple from "shared-lib/system-calculator/shared/fan-diagram-multiple";
import type * as QP from "shared-lib/query-product";
import type * as PC from "shared-lib/product-codes";
import * as Style from "shared-lib/style";
import type * as AI from "abstract-image";
import { ResultRowContainer, withTw } from "client-lib/elements";
import { ViewerDiagramContainer } from "../../viewer-diagram";
import type { VisualizerOwnProps, VisualizerProductProps } from "../types";

interface LegendProps {
  readonly legendColor: AI.Color;
  readonly highlighted: boolean;
}
const Legend = withTw("div", "", (_p: LegendProps) => "");

interface ProductNameProps {
  readonly highlighted: boolean;
}
const ProductName = withTw("div", "", (_p: ProductNameProps) => "");

export type Props = VisualizerOwnProps & StateProps & Response;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

const diagramWidth = 800 * 1.5;
const diagramHeight = 400 * 1.5;

export function FanDiagramsPocVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  const [highlightedProduct, setHighlightedProduct] = React.useState<number | undefined>(undefined);

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  return (
    <div>
      <ResultRowContainer heading={`${translate(Texts.performanceCurve())} (PoC)`}>
        {renderDiagram(props, products, highlightedProduct, setHighlightedProduct)}
      </ResultRowContainer>
      <div>
        {products.map((p, i) =>
          renderVariantName(
            translate,
            p.variant,
            p.productId,
            p.codes,
            Style.diagramGanymed.actualAreaColorMulti[i % Style.diagramGanymed.actualAreaColorMulti.length],
            highlightedProduct === i,
            (highlighted) => (highlighted ? setHighlightedProduct(i) : setHighlightedProduct(undefined))
          )
        )}
      </div>
    </div>
  );
}

function renderVariantName(
  translate: Texts.TranslateFunction,
  variant: PropertyValueSet.PropertyValueSet,
  productId: string,
  codes: PC.ProductCodes,
  legendColor: AI.Color,
  highlighted: boolean,
  setHighlighted: (highlighted: boolean) => void
): React.ReactElement {
  const itemName = translate(Texts.item_name(productId, variant), codes.code);
  const itemNo = codes.itemNo;
  const variantName = translate(Texts.item_variant(productId, variant), codes.variantId);
  return (
    <ProductName
      key={`${itemName} - ${variantName} (${itemNo})`}
      highlighted={highlighted}
      onMouseEnter={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
    >
      <Legend legendColor={legendColor} highlighted={highlighted} />
      {`${itemName} - ${variantName} (${itemNo})`}
    </ProductName>
  );
}

function renderDiagram(
  { translate, market, language, userSettings, ct_MarketUnits, ct_LanguageMapping, showDownload }: Props,
  visualizerProductProps: ReadonlyArray<VisualizerProductProps>,
  highlightedProduct: number | undefined,
  setHighlightedProduct: (i: number | undefined) => void
): React.ReactElement {
  const maybeFans = visualizerProductProps.map((p) =>
    R.head(R.values(R.filter((ri: SC.ResultItem) => ri.type === "Fan", p.resultItemMap)))
  ) as ReadonlyArray<FanResultItem>;
  const fans = maybeFans.map((maybeFan) => maybeFan?.value.air).filter((fan) => !!fan);

  if (fans.length === 0) {
    return <div />;
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");

  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);

  const fanProps = fans.map((fan, i) => ({
    fan: fan,
    highlighted: i === highlightedProduct,
  }));

  const { pressure } = FanDiagramMultiple.generateChartsMultiple({
    fanProps,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramGanymed,
  });

  return (
    <ViewerDiagramContainer
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
      id="fanExternalPressure"
      chart={pressure}
      maxWidth={diagramWidth}
      formatNumber={formatNumber}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      onHover={(p) => onHover(flowUnit, pressureUnit, fanProps, p, setHighlightedProduct)}
    />
  );
}

function onHover(
  flowUnit: Unit.Unit<Quantity.VolumeFlow>,
  pressureUnit: Unit.Unit<Quantity.Pressure>,
  fanProps: ReadonlyArray<FanDiagramMultiple.FanProps>,
  point: AI.Point | undefined,
  setHighlightedProduct: (i: number | undefined) => void
): void {
  if (point === undefined) {
    setHighlightedProduct(undefined);
  } else {
    const intersectedProps = FanDiagramMultiple.fanCurvesPointIntersection(flowUnit, pressureUnit, fanProps, point);
    const propsIndex = R.indexOf(intersectedProps, fanProps);
    setHighlightedProduct(propsIndex !== -1 ? propsIndex : undefined);
  }
}
