import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import * as QEcom from "shared-lib/query-ecom";
import * as Texts from "shared-lib/language-texts";
import type { StateProps, Response, OwnProps } from "./container-component";
import { FricoProductTableContainerComponent } from "./container-component";
import type { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.fricoProductTable,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (state, showAdvanced, userSettings) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    tablesForProducts: tablesForProductsQuery(getMainProducts(ownProps)),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    productDescriptions: productDescriptionsQuery(ownProps, response),
  });
}

function productDescriptionsQuery(
  ownProps: OwnProps,
  response: Response | undefined
): QD.DiaqMapQuery<Response["productDescriptions"]> | undefined {
  if (ownProps.products === undefined || !response || response.ct_LanguageMapping === undefined) {
    return undefined;
  }
  const language = Texts.getLanguage(ownProps.language, response.ct_LanguageMapping);
  const map: { [id: string]: QEcom.ProductDescriptionQuery } = {};
  for (const product of ownProps.products) {
    map[product.variantNo] = QEcom.productDescription(
      ownProps.specificationDataUrl,
      ownProps.market,
      language,
      product.m3ItemNo,
      product.variantNo
    );
  }
  return QD.createMapQuery(map);
}

function tablesForProductsQuery(
  products: ReadonlyArray<string> | undefined
): QD.DiaqMapQuery<Response["tablesForProducts"]> | undefined {
  if (products === undefined) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    map[product] = QP.tablesByProductId(product, ["ct_AccessoryRelations"]);
  }
  return QD.createMapQuery(map);
}

function getMainProducts(ownProps: OwnProps): ReadonlyArray<string> | undefined {
  return ownProps.products.map((t) => t.productId);
}

export const FricoProductTableContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(FricoProductTableContainerComponent);
