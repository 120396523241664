import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Types from "../types";
import * as Messages from "../messages";
import type { HeatRecoveryUnit } from "../result-items-types";
import * as FanAir from "../shared/fan-air";
import type { Input } from "./types";
import { calculate } from "./calculator";

const source = "FantechHeatRecoveryUnit";

export async function select(input: Input): Promise<Types.CalculatorResult<HeatRecoveryUnit>> {
  const {
    supplyAirFlow,
    supplyPressure,
    toleranceMinus,
    tolerancePlus,
    speedControl,
    airData,
    airLimitsData,
    airDensity,
  } = input;
  if (!supplyAirFlow || !supplyPressure) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const supplyData = airData.filter((p) => p.part === "Supply");
  const supplyLimits = airLimitsData.filter((p) => p.part === "Supply");
  const supplyFan = FanAir.calculate(
    speedControl,
    supplyData,
    supplyLimits,
    supplyAirFlow,
    supplyPressure,
    [],
    airDensity,
    undefined,
    false,
    false
  );
  // const supplyPressureData = airSupplyData.filter((p) => p.part === "Supply" && p.param === "Ps_v");
  // const supplyPressureLines = R.values(R.groupBy((r) => `${r.step} ${r.volt_ctrl}`, supplyPressureData));
  // supplyPressureLines.sort((a, b) => a[0].value - b[0].value);
  // const lines = supplyPressureLines[Math.floor(supplyPressureLines.length / 2)];
  // const supplyLimits = airSupplyLimitsData.filter((p) => p.part === "Supply");
  // const supplyFan = FanAir.calculate(
  //   speedControl,
  //   lines,
  //   supplyLimits,
  //   supplyAirFlow,
  //   supplyPressure,
  //   [],
  //   undefined,
  //   undefined,
  //   false
  // );
  if (!supplyFan.workingPoint) {
    console.log({ supplyLimits });
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }

  const supplyAirFlowLps = Amount.valueAs(customUnits.LiterPerSecond, supplyAirFlow);
  const workingFlowLps = supplyFan.workingPoint.point.x;
  const diffSupply = (100.0 * (supplyAirFlowLps - workingFlowLps)) / workingFlowLps;

  if (toleranceMinus && Amount.valueAs(customUnits.Percent, toleranceMinus) > diffSupply) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }
  if (tolerancePlus && diffSupply > Amount.valueAs(customUnits.Percent, tolerancePlus)) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }

  return calculate(input);
}
