/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as Redux from "redux";
import * as Actions from "./actions";
import { postToExternal } from "./post-to-external";

type AnyStoreState = any;

export function createMiddleware(): any {
  return (store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    const responseToReturn = next(action);
    if (action.type === "external/ADD_ITEMS") {
      const params = action.payload.params;
      const items = action.payload.items;
      postToExternal(params, items).then((success) => store.dispatch(Actions.itemsAdded(success)));
    }
    return responseToReturn;
  };
}
