import * as R from "ramda";
import * as React from "react";
import type { DispatchProp } from "client-lib/redux-integration";
import type * as Texts from "shared-lib/language-texts";
import { formatNumberFunction } from "shared-lib/utils";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import type { Property, TranslatePropertyName, TranslatePropertyValue } from "client-lib/properties-selector";
import type * as QP from "shared-lib/query-product";
import { PropertyGroup } from "./property-group";
import * as Shared from "./shared";

type Props = OwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export interface OwnProps {
  readonly translate: Texts.TranslateFunction;
  readonly market: string;
  readonly language: string;
  readonly productId: string;
  readonly hideProperties: ReadonlyArray<string>;
  readonly selections: Shared.Selections;
  readonly selectionsChanged: Shared.SelectionsChanged;
  readonly hideInvalidValues?: boolean;
  readonly comboBoxImageCSSFilter?: string;
  readonly translatePropertyName?: TranslatePropertyName;
  readonly translatePropertyValue?: TranslatePropertyValue;
  readonly properties: ReadonlyArray<Property>;
  readonly propertyFieldNames: ReadonlyArray<QP.PropertyFieldName>;
  readonly propertySelectorTypes: ReadonlyArray<QP.PropertySelectorType>;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly hideFirstGroupTitle?: boolean;
}

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  readonly showAdvanced: boolean;
}

export interface Response {
  readonly productTables: ProductTables;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly images: Shared.Images;
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly ct_PropertyFieldNames: QP.PropertyFieldNamesTable;
  readonly ct_PropertySelectorTypes: QP.PropertySelectorTypesTable;
  readonly ct_ClosedGroups: QP.ClosedGroups;
  readonly image: QP.ProductImageTable;
}

export function PropertiesSelectorMultiContainerComponent({
  productId,
  hideProperties,
  selections,
  selectionsChanged,
  dispatch,
  showAdvanced,
  translate,
  market,
  language,
  userSettings,
  ct_MarketUnits,
  ct_LanguageMapping,
  images,
  hideInvalidValues,
  comboBoxImageCSSFilter,
  translatePropertyName,
  translatePropertyValue,
  properties: allProperties,
  propertyFieldNames,
  propertySelectorTypes,
  hideEmptyUnitSelectors,
  productTables,
  hideFirstGroupTitle,
}: Props): React.ReactElement<Props> {
  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettingsShared.getUnits({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const closedGroups = UserSettingsShared.getClosedGroups(productTables.ct_ClosedGroups, userSettings);
  const groups = R.groupBy((p) => p.group || "main", allProperties);
  const variants = Shared.getVariants(selections);
  return (
    <div>
      {R.keys(groups).map((group) => (
        <PropertyGroup
          comboBoxImageCSSFilter={comboBoxImageCSSFilter}
          key={group}
          productId={productId}
          group={group}
          hiddenProperties={hideProperties}
          properties={groups[group]}
          allProperties={allProperties}
          selections={selections}
          selectionsChanged={selectionsChanged}
          variants={variants}
          translate={translate}
          translatePropertyName={translatePropertyName}
          translatePropertyValue={translatePropertyValue}
          selectorTypes={propertySelectorTypes}
          fieldNames={propertyFieldNames}
          getUnit={getUnit}
          getUnits={getUnits}
          getDecimals={getDecimals}
          dispatch={dispatch}
          formatNumber={formatNumber}
          showAdvanced={showAdvanced}
          images={images}
          hideInvalidValues={hideInvalidValues}
          hideEmptyUnitSelectors={hideEmptyUnitSelectors}
          closed={closedGroups[group]}
          onToggleClosed={() => dispatch(UserSettingsClient.setGroupClosed(group, !closedGroups[group]))}
          hideFirstGroupTitle={hideFirstGroupTitle}
        />
      ))}
    </div>
  );
}
