import type * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
//import * as PU from "shared-lib/product-utils";
import * as R from "ramda";
import * as SC from "shared-lib/system-calculator";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import type {
  AirCurtainCapacity,
  Result,
  AllProductTables,
  MetaTables,
  AdditionalComponentData,
  SearchMetaTables,
} from "../types";

const bufferInmillimeter = 100;

interface VariantWithAttributes {
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly attributes: Attributes.Attributes;
}

export function preFilter(
  searchVariant: PropertyValueSet.PropertyValueSet,
  _productId: string,
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  metaTables: MetaTables,
  _searchMetaTable: SearchMetaTables,
  productTables: AllProductTables
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const guideScore = calculateAirCurtainGuideScore(searchVariant, metaTables.ct_ScoreGuide);
  const mountingScoreType = getSelectedScoreType(searchVariant, metaTables.ct_ScoreTypeEpim);

  const variantsWithAttributes = variants.map((v) => ({
    variant: v,
    attributes: Attributes.createMap(v, productTables.ct_Attributes2),
  }));

  // Filter out by product score
  const filteredByScore = filterAirCurtainVariantsByScore(variantsWithAttributes, guideScore, mountingScoreType);
  // Filter out more by size
  const filteredBySize = filterAirCurtianVariantsOnSize(filteredByScore, searchVariant);

  return filteredBySize.map((va) => va.variant);
}

export function postFilter(
  searchVariant: PropertyValueSet.PropertyValueSet,
  results: ReadonlyArray<Result>,
  calcParams: PropertyValueSet.PropertyValueSet,
  metaTables: MetaTables,
  _searchMetaTable: SearchMetaTables,
  productTables: AllProductTables
): ReadonlyArray<Result> {
  const guideScore = calculateAirCurtainGuideScore(searchVariant, metaTables.ct_ScoreGuide);
  const mountingScoreType = getSelectedScoreType(searchVariant, metaTables.ct_ScoreTypeEpim);
  const adjustedResults: Array<Result> = [];
  for (const result of results) {
    const additionalData = createAirCurtainAdditionalData(
      result.result.properties,
      productTables,
      guideScore,
      mountingScoreType
    );
    adjustedResults.push({
      ...result,
      result: {
        ...result.result,
        additionalData,
      },
    });
  }
  const withGoodResult = setGoodHeatingResult(adjustedResults, calcParams);
  return withGoodResult;
}

function getSelectedScoreType(
  searchVariant: PropertyValueSet.PropertyValueSet,
  ct_ScoreTypeEpim: QP.ScoreTypeTable
): QP.ScoreMountingType {
  const selectedScoreType = ct_ScoreTypeEpim.find((t) => PropertyFilter.isValid(searchVariant, t.property_filter));
  if (selectedScoreType === undefined) {
    console.log(`Warning: ${PropertyValueSet.toString(searchVariant)}: No score mounting type found`);
    return "horizontal";
  } else {
    return selectedScoreType.mounting;
  }
}

function calculateAirCurtainGuideScore(
  properties: PropertyValueSet.PropertyValueSet,
  metaScoreGuideTable: QP.ScoreGuideTable
): number {
  const mounting = PropertyValueSet.getInteger("mounting", properties);

  if (mounting !== undefined && mounting === 3) {
    return calculateRevolvingGuideScore(properties, metaScoreGuideTable);
  }
  return calculateRegularGuideScore(properties, metaScoreGuideTable);
}

function calculateRevolvingGuideScore(
  properties: PropertyValueSet.PropertyValueSet,
  metaScoreGuideTable: QP.ScoreGuideTable
): number {
  const length = PropertyValueSet.getAmount<Quantity.Length>("installationWidth", properties);
  const height = PropertyValueSet.getAmount<Quantity.Length>("installationHeight", properties);

  if (!length || !height) {
    return 0;
  }

  const lengthM = Amount.valueAs(Units.Meter, length);
  const heightM = Amount.valueAs(Units.Meter, height);
  const areaM2 = lengthM * heightM;

  const totalDecrease = metaScoreGuideTable
    .filter((r) => PropertyFilter.isValid(properties, r.property_filter))
    .reduce((p: number, c: QP.ScoreGuideData) => p * c.value, 1);

  const kWM2 = totalDecrease * 5;
  const requiredPower = areaM2 * kWM2;
  return requiredPower;
}

function calculateRegularGuideScore(
  properties: PropertyValueSet.PropertyValueSet,
  metaScoreGuideTable: QP.ScoreGuideTable
): number {
  const scoreGuideScore = metaScoreGuideTable
    .filter((r) => PropertyFilter.isValid(properties, r.property_filter))
    .reduce((p: number, c: QP.ScoreGuideData) => p + c.value, 0);
  return scoreGuideScore;
}

function getVariantScore(
  selectedMountingType: QP.ScoreMountingType,
  variantAttributes: Attributes.Attributes
): { readonly low: number; readonly high: number; readonly min: number; readonly max: number } | undefined {
  switch (selectedMountingType) {
    case "horizontal": {
      const low = Attributes.getFloat("CL-frico-selection-horizontal-mount-low-score", variantAttributes);
      const high = Attributes.getFloat("CL-frico-selection-horizontal-mount-high-score", variantAttributes);
      const min = Attributes.getFloat("CL-frico-selection-horizontal-mount-min-score", variantAttributes);
      const max = Attributes.getFloat("CL-frico-selection-horizontal-mount-max-score", variantAttributes);

      if (low !== undefined && high !== undefined && min !== undefined && max !== undefined) {
        return { low, high: high, min: min, max: max };
      }
      return undefined;
    }

    case "vertical": {
      const low = Attributes.getFloat("CL-frico-selection-vertical-mount-low-score", variantAttributes);
      const high = Attributes.getFloat("CL-frico-selection-vertical-mount-high-score", variantAttributes);
      const min = Attributes.getFloat("CL-frico-selection-vertical-mount-min-score", variantAttributes);
      const max = Attributes.getFloat("CL-frico-selection-vertical-mount-max-score", variantAttributes);

      if (low !== undefined && high !== undefined && min !== undefined && max !== undefined) {
        return { low, high: high, min: min, max: max };
      }
      return undefined;
    }
    default:
      throw new Error("Mountingtype with unknown score");
  }
}

function filterAirCurtainVariantsByScore(
  variantsWithAttributes: ReadonlyArray<VariantWithAttributes>,
  guideScore: number,
  selectedMountingType: QP.ScoreMountingType
): ReadonlyArray<VariantWithAttributes> {
  const filteredVariants: Array<VariantWithAttributes> = [];
  for (const variantWithAttributes of variantsWithAttributes) {
    // const { variant } = variantWithAttributes;

    // SFS AND RDS ARE RETIRED AND WONT SHOW UP ANYMORE SO WE OMIT THIS
    // // SFS and RDS have scores depending on variant, other products have product wide scores
    // const productDefinedScores = productScoreTable.find((r) => PropertyFilter.isValid(variant, r.property_filter));

    const variantScoreRow = getVariantScore(selectedMountingType, variantWithAttributes.attributes);

    if (variantScoreRow === undefined || guideScore < variantScoreRow.min || guideScore > variantScoreRow.max) {
      // console.log(
      //   `model=${PropertyValueSet.getInteger("model", variant)}:`,
      //   variantScoreRow?.min,
      //   variantScoreRow?.max,
      //   guideScore
      // );
      continue;
    }

    filteredVariants.push(variantWithAttributes);
  }
  return filteredVariants;
}

interface LengthResult {
  readonly length: number;
  readonly units: number;
  readonly productId: string;
}

function filterAirCurtianVariantsOnSize(
  variantsWithAttributes: ReadonlyArray<VariantWithAttributes>,
  selectionProps: PropertyValueSet.PropertyValueSet
): ReadonlyArray<VariantWithAttributes> {
  if (variantsWithAttributes.length === 0) {
    return variantsWithAttributes;
  }

  const installationWidth = PropertyValueSet.getAmount<Quantity.Length>("installationWidth", selectionProps);
  const installationHeight = PropertyValueSet.getAmount<Quantity.Length>("installationHeight", selectionProps);
  const mountingMethod = PropertyValueSet.getInteger("mountingMethod", selectionProps);
  const mounting = PropertyValueSet.getInteger("mounting", selectionProps);

  // Revolving door
  if (mounting === 3) {
    return variantsWithAttributes;
  }

  if (
    installationWidth === undefined ||
    installationHeight === undefined ||
    mountingMethod === undefined ||
    mounting === undefined
  ) {
    return variantsWithAttributes;
  }

  // We need to group by product name because the PIM structure groups different products into on M3 product
  // like PA3200 and PA2500, this casues issues with the length calculations and proposals.
  const allLengthsGrouped: { [key: string]: Array<number> } = {};
  for (const va of variantsWithAttributes) {
    const productId = Attributes.getStringOrDefault("ecom-product-id", va.attributes, "");
    const length = getLength(va.attributes);
    if (length === undefined) {
      continue;
    }
    if (allLengthsGrouped[productId] === undefined) {
      allLengthsGrouped[productId] = [];
    }
    if (allLengthsGrouped[productId].some((l) => l === length)) {
      continue;
    }
    allLengthsGrouped[productId].push(length);
  }

  if (R.keys(allLengthsGrouped).length === 0) {
    return variantsWithAttributes;
  }

  // Special case UF 600
  if (variantsWithAttributes.some((va) => getUnderBlowingDims(va) !== undefined)) {
    //if (mounting === 2) {
    const width = Amount.valueAs(Units.Millimeter, installationWidth);
    const height = Amount.valueAs(Units.Millimeter, installationHeight);

    const allowedVariants: Array<VariantWithAttributes> = [];

    for (const va of variantsWithAttributes) {
      const allowedDimensions = getUnderBlowingDims(va);
      if (allowedDimensions === undefined) {
        continue;
      }

      const variantMaxWidth = allowedDimensions.max_width;
      const variantMinWidth = allowedDimensions.min_width;
      const variantMaxheight = allowedDimensions.max_height;
      const variantMinHeight = allowedDimensions.min_height;
      if (
        width >= variantMinWidth &&
        width <= variantMaxWidth &&
        height >= variantMinHeight &&
        height <= variantMaxheight
      ) {
        allowedVariants.push(va);
      }
    }

    return allowedVariants;
  }

  const installationWidthMillimeter = Amount.valueAs(Units.Millimeter, installationWidth);
  const results: Array<VariantWithAttributes> = [];
  const handled: Set<string> = new Set<string>();

  const length =
    mountingMethod === 0
      ? Amount.valueAs(Units.Millimeter, installationWidth)
      : Amount.valueAs(Units.Millimeter, installationHeight);

  for (const key of R.keys(allLengthsGrouped)) {
    if (allLengthsGrouped[key].length === 1) {
      handled.add(key);
      const numberOfCurtains = Math.ceil(length / (allLengthsGrouped[key][0] + bufferInmillimeter));
      const withNumberOfUnits = variantsWithAttributes
        .filter((v) => Attributes.getString("ecom-product-id", v.attributes))
        .map((va) => {
          return { ...va, variant: PropertyValueSet.setInteger("quantity", numberOfCurtains, va.variant) };
        });
      results.push(...withNumberOfUnits);
    }
  }

  const unhandledKeys = R.keys(allLengthsGrouped).filter((key) => !handled.has(key));

  const possibleLengths: Array<LengthResult> = [];

  for (const key of unhandledKeys) {
    const allLengths = allLengthsGrouped[key].sort((n1, n2) => n1 - n2);
    possibleLengths.push(...getPossibleLengths(allLengths, length, key));
  }

  if (possibleLengths.length === 0) {
    return [];
  }

  const matchingVariants = variantsWithAttributes.filter((va) =>
    possibleLengths.some(
      (l) => l.length === getLength(va.attributes) && Attributes.getString("ecom-product-id", va.attributes)
    )
  );

  const complete: Array<VariantWithAttributes> = [];
  for (const variantWithAttributes of matchingVariants) {
    const { variant, attributes } = variantWithAttributes;
    const mainLength = getLength(attributes);
    const mainLengthResult = possibleLengths.find(
      (l) =>
        l.length === mainLength &&
        l.productId === Attributes.getString("ecom-product-id", variantWithAttributes.attributes)
    );
    const additionalUnit = possibleLengths.find(
      (l) =>
        l.length !== mainLength &&
        l.productId === Attributes.getString("ecom-product-id", variantWithAttributes.attributes)
    );

    const variantVerticalLimits = getVerticalLimitsInMillimeter(variantWithAttributes);

    // If vertical mounting and the the width of the opening is larger than the products max_width, we skip it
    if (
      (mountingMethod === 1 &&
        variantVerticalLimits !== undefined &&
        variantVerticalLimits.max_width < installationWidthMillimeter) ||
      mainLengthResult === undefined
    ) {
      continue;
    }

    // If vertical mounting and the width of the opening is larger then the double_width of the variant, we install it on both sides of the opening
    const twoSides =
      mountingMethod === 1 &&
      mounting !== 3 &&
      variantVerticalLimits !== undefined &&
      installationWidthMillimeter > variantVerticalLimits.double_width;

    const additionalData: { [key: string]: PropertyValue.PropertyValue } = {};
    additionalData.twoSides = PropertyValue.fromInteger(twoSides ? 1 : 0);
    additionalData.quantity = PropertyValue.fromInteger(mainLengthResult.units);

    if (additionalUnit !== undefined) {
      additionalData.additionalQuantity = PropertyValue.fromInteger(additionalUnit.units);
      additionalData.additionalLengthmm = PropertyValue.fromInteger(additionalUnit.length);
    }
    const withAdditionalData = {
      attributes,
      variant: PropertyValueSet.merge(additionalData, variant),
    };
    complete.push(withAdditionalData);
  }
  return complete;
}

function getVerticalLimitsInMillimeter(
  variantWithAttributes: VariantWithAttributes
): { readonly max_width: number; readonly double_width: number } | undefined {
  const double_width = Attributes.getFloat(
    "size-width-installation-double-mount-MIN-BASE-ALL",
    variantWithAttributes.attributes
  );
  const max_width = Attributes.getFloat(
    "size-width-installation-vertical-mount-MAX-BASE-ALL",
    variantWithAttributes.attributes
  );
  if (max_width !== undefined && double_width !== undefined) {
    return { max_width, double_width };
  }
  return undefined;
}

function getPossibleLengths(
  allLengths: ReadonlyArray<number>,
  length: number,
  productId: string
): ReadonlyArray<LengthResult> {
  const maxLength = allLengths[allLengths.length - 1];
  const max = Math.ceil(length / maxLength);
  let numberOfUnits = Math.max(1, Math.floor(length / maxLength));
  let possibleLengths: ReadonlyArray<LengthResult> = [];
  do {
    possibleLengths = getLengthsForNunits(length, allLengths, numberOfUnits++, productId);
  } while (numberOfUnits <= max && possibleLengths.length === 0);

  return possibleLengths;
}

function getLengthsForNunits(
  widthOrHeight: number,
  lengths: ReadonlyArray<number>,
  numberOfUnits: number,
  productId: string
): ReadonlyArray<LengthResult> {
  let i: number = 0;
  for (i = 0; i < lengths.length; i++) {
    if (widthOrHeight <= lengths[i] * numberOfUnits + bufferInmillimeter) {
      return [{ length: lengths[i], units: numberOfUnits, productId: productId }];
    }

    if (i + 1 < lengths.length) {
      for (let k = 1; k < numberOfUnits; k++) {
        if (widthOrHeight <= k * lengths[i + 1] + (numberOfUnits - k) * lengths[i] + bufferInmillimeter) {
          return [
            { length: lengths[i + 1], units: k, productId: productId },
            { length: lengths[i], units: numberOfUnits - k, productId: productId },
          ];
        }
      }
    }
  }
  return [];
}

function calculateAirCurtainCapacity(
  variantattributes: Attributes.Attributes,
  guideScore: number,
  selectedMountingType: QP.ScoreMountingType
): AirCurtainCapacity {
  const variantScoreRow = getVariantScore(selectedMountingType, variantattributes);
  if (variantScoreRow === undefined) {
    return "unknown";
  }

  if (
    variantScoreRow.min <= guideScore &&
    guideScore <= variantScoreRow.high &&
    variantScoreRow.high !== variantScoreRow.min
  ) {
    return "high";
  } else if (variantScoreRow.high <= guideScore && guideScore <= variantScoreRow.low) {
    return "ok";
  } else if (variantScoreRow.low <= guideScore && guideScore <= variantScoreRow.max) {
    return "low";
  }
  return "unknown";
}

interface CoilCodePressure {
  readonly code: string;
  readonly waterPressure: number;
  readonly outletAirTemperature: number;
}
// Returns the coil that has good heating result
function getGoodHeatingResult(results: ReadonlyArray<Result>, calculationMethod: number): string {
  const calcResults = results.map((r) => r.result);
  const outputs = calcResults.map((r) => r.results);

  const coilCodeWithPressure = outputs.reduce((acc, r) => {
    const airCurtainResult = r.AirCurtain;
    if (airCurtainResult === undefined) {
      return acc;
    }
    if (
      airCurtainResult.type === "OutputMapperSuccess" &&
      airCurtainResult.result !== undefined &&
      airCurtainResult.result.type === "AirCurtain"
    ) {
      if (
        airCurtainResult.result.value.waterPressureDrop !== undefined &&
        airCurtainResult.result.value.coilCode !== undefined &&
        airCurtainResult.result.value.outletAirTemperature !== undefined
      ) {
        acc.push({
          code: airCurtainResult.result.value.coilCode,
          waterPressure: Amount.valueAs(Units.KiloPascal, airCurtainResult.result.value.waterPressureDrop),
          outletAirTemperature: Amount.valueAs(Units.Celsius, airCurtainResult.result.value.outletAirTemperature),
        });
      }
    }
    return acc;
  }, Array<CoilCodePressure>());

  if (calculationMethod === 2) {
    // Outlet air temp selected
    coilCodeWithPressure.sort((a, b) => Math.abs(a.waterPressure - 15) - Math.abs(b.waterPressure - 15));
    const goodResult = coilCodeWithPressure.find((r) => r.waterPressure <= 30);
    if (goodResult !== undefined) {
      return goodResult.code;
    }
  } else {
    coilCodeWithPressure.sort((a, b) => Math.abs(a.outletAirTemperature - 37) - Math.abs(b.outletAirTemperature - 37));
    const goodResult = coilCodeWithPressure.find((r) => r.outletAirTemperature >= 32 && r.waterPressure < 20);
    if (goodResult === undefined) {
      const tryGoodResult = coilCodeWithPressure.find((r) => r.waterPressure < 30 && r.outletAirTemperature >= 32);
      return tryGoodResult === undefined ? "" : tryGoodResult.code;
    }
    return goodResult.code;
  }
  return "";
}

function createAirCurtainAdditionalData(
  variant: PropertyValueSet.PropertyValueSet,
  productTables: AllProductTables,
  airCurtainGuideScore: number,
  selectedMountingType: QP.ScoreMountingType
): AdditionalComponentData {
  const variantAttributes = Attributes.createMap(variant, productTables.ct_Attributes2);
  return {
    type: "aircurtain",
    data: {
      capacity: calculateAirCurtainCapacity(variantAttributes, airCurtainGuideScore, selectedMountingType),
      goodHeatingResult: false,
      quantity: PropertyValueSet.getInteger("quantity", variant) ?? 0,
      twoSides: PropertyValueSet.getInteger("twoSides", variant) ?? 0,
      additionalUnitLengthmm: PropertyValueSet.getInteger("additionalLengthmm", variant),
      additionalUnitQuantity: PropertyValueSet.getInteger("additionalQuantity", variant),
    },
  };
}

function setGoodHeatingResult(
  results: ReadonlyArray<Result>,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<Result> {
  const calculationMethod = PropertyValueSet.getInteger("calcMethodWaterCoil", calcParams);

  if (calculationMethod === undefined || results === undefined) {
    return results;
  }

  const goodHeatingResult = getGoodHeatingResult(results, calculationMethod);
  if (goodHeatingResult === "") {
    return results;
  }

  const resultWithGoodHeating = results.map((r) => {
    const coilCode = SC.getResultItemValue("AirCurtain", "AirCurtain.coilCode", r.result.results);
    if (coilCode !== undefined && coilCode === goodHeatingResult) {
      return {
        ...r,
        result: {
          ...r.result,
          additionalData: {
            ...r.result.additionalData,
            type: r.result.additionalData!.type,
            data: { ...r.result.additionalData!.data, goodHeatingResult: true },
          },
        },
      };
    }
    return r;
  });

  return resultWithGoodHeating;
}

interface UnderBlowing {
  readonly min_height: number;
  readonly max_height: number;
  readonly min_width: number;
  readonly max_width: number;
}

function getUnderBlowingDims(va: VariantWithAttributes): UnderBlowing | undefined {
  const max_height = Attributes.getFloat("size-height-installation-MAX-BASE-ALL", va.attributes);
  const min_height = Attributes.getFloat("size-height-installation-MIN-BASE-ALL", va.attributes);
  const max_width = Attributes.getFloat("size-width-installation-MAX-BASE-ALL", va.attributes);
  const min_width = Attributes.getFloat("size-width-installation-MIN-BASE-ALL", va.attributes);

  if (max_height !== undefined && min_height !== undefined && max_width !== undefined && min_width !== undefined) {
    return { max_height, min_height, min_width, max_width };
  }
  return undefined;
}

export function getLength(attributes: Attributes.Attributes): number | undefined {
  const variantLength = Attributes.getInt("size-length-BASE-ALL", attributes);
  return variantLength;
}
