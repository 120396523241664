import * as R from "ramda";
import type * as QP from "shared-lib/query-product";
import { PropertyValueSet, PropertyValue, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import type { AnyQuantity } from "shared-lib/uom";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as SC from "shared-lib/system-calculator";
import type * as C from "shared-lib/calculation";
import * as PC from "shared-lib/product-codes";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import * as Selections from "./selections";

export interface FullConfigProps {
  readonly market: string;

  readonly m3ItemNo: string;
  readonly variant: string | undefined;
  readonly propsConfig: C.ItemConfig | undefined;
  readonly stateConfig: C.ItemConfig;

  readonly metaTables: MetaTables;
  readonly productTables: ProductTables;
  readonly accessoryTables: AccessoryTables;
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_CalcParamDefault: QP.CalcParamDefaultTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_Attributes2: QP.AttributesTable;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
}

export interface AccessoryTables {
  readonly [productId: string]: {
    readonly property: QP.PropertyTable;
    readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
    readonly ct_CalcParamDefault: QP.CalcParamDefaultTable;
    readonly ct_Attributes2: QP.AttributesTable;
    readonly ct_ItemNo: QP.ItemNoTable;
  };
}

export function getFullConfig(props: FullConfigProps, useMeasureToolData: boolean = false): C.ItemConfig | undefined {
  const { market, m3ItemNo, variant, propsConfig, stateConfig, metaTables, productTables, accessoryTables } = props;
  const m3Variant = useMeasureToolData
    ? PropertyValueSet.fromString(`model=999999;variant=999999`, CustomUnitsLookup) /* Fake variant for MT */
    : PC.getVariantFromM3ItemNo(productTables, m3ItemNo, variant);
  if (!m3Variant) {
    return undefined;
  }

  const navigateVariant = PropertyValueSet.setValues(propsConfig?.properties ?? PropertyValueSet.Empty, m3Variant);

  const mainPvs = getFullVariantAndCalcParams(
    market,
    metaTables,
    productTables,
    PropertyValueSet.setValues(stateConfig.properties, navigateVariant),
    PropertyValueSet.setValues(stateConfig.calcParams, propsConfig ? propsConfig.calcParams : PropertyValueSet.Empty),
    stateConfig.secondCalcParams &&
      PropertyValueSet.setValues(
        stateConfig.secondCalcParams,
        propsConfig?.secondCalcParams ? propsConfig.secondCalcParams : PropertyValueSet.Empty
      )
  );

  const fullAccessories = stateConfig.accessories.map((acc) => {
    const accPropsConfig = propsConfig?.accessories.find((a) => a.id === acc.id);
    const accTables = accessoryTables[acc.productId];
    const accCalcParams = PropertyValueSet.setInteger(
      "is_accessory",
      1,
      PropertyValueSet.setValues(acc.calcParams, accPropsConfig ? accPropsConfig.calcParams : PropertyValueSet.Empty)
    );
    const accPvs = getFullVariantAndCalcParams(
      market,
      metaTables,
      accTables,
      PropertyValueSet.setValues(acc.properties, accPropsConfig?.properties || PropertyValueSet.Empty),
      accCalcParams,
      undefined
    );
    return {
      ...acc,
      properties: accPvs.variant,
      calcParams: accPvs.calcParams,
    };
  });

  const fullConfig = {
    properties: mainPvs.variant,
    calcParams: mainPvs.calcParams,
    secondCalcParams: mainPvs.secondCalcParams,
    accessories: fullAccessories,
  };

  return fullConfig;
}

function getFullVariantAndCalcParams(
  market: string,
  metaTables: {
    readonly ct_ResultItems: QP.ResultItemsTable;
    readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
  },
  productTables: {
    readonly property: QP.PropertyTable;
    readonly ct_Attributes2: QP.AttributesTable;
    readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
    readonly ct_CalcParamDefault: QP.CalcParamDefaultTable;
  },
  variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet,
  secondCalcParams: PropertyValueSet.PropertyValueSet | undefined
): {
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly secondCalcParams: PropertyValueSet.PropertyValueSet | undefined;
} {
  const fullProperties = getFullVariant(productTables.property, productTables.ct_CalcParamDefault, market, variant);
  const attributes = Attributes.createMap(fullProperties, productTables.ct_Attributes2);
  const fullCalcParams = getFullCalcParams(
    productTables.ct_DiaqTemplates,
    metaTables.ct_ResultItems,
    productTables.ct_CalcParamDefault,
    calcParams,
    fullProperties,
    market,
    attributes,
    metaTables.ct_AttributeTemplateMapping
  );
  const fullSecondCalcParams =
    secondCalcParams &&
    getFullCalcParams(
      productTables.ct_DiaqTemplates,
      metaTables.ct_ResultItems,
      productTables.ct_CalcParamDefault,
      secondCalcParams,
      fullProperties,
      market,
      attributes,
      metaTables.ct_AttributeTemplateMapping
    );
  return { variant: fullProperties, calcParams: fullCalcParams, secondCalcParams: fullSecondCalcParams };
}

export function getFullSelections(
  propertyTable: QP.PropertyTable,
  defaultValueTable: QP.CalcParamDefaultTable,
  market: string,
  selections: Selections.Selections,
  ecomVariant: string | undefined
): Selections.Selections {
  let fullSelections = Selections.setIntegers("market", [parseInt(market, 10)], selections);
  for (const p of propertyTable) {
    const existingValue = selections[p.name];
    if (existingValue || fullSelections[p.name]) {
      continue;
    }
    const marketDefValue =
      defaultValueTable.find((v) => v.market === market && v.calc_param === p.name) ||
      defaultValueTable.find((v) => v.market === "" && v.calc_param === p.name);
    const variants = Selections.getVariants(fullSelections);
    const propDefValues = p.def_value.filter((_) => true);
    if (p.quantity === "Discrete") {
      const validValues = p.value
        .filter((v) => Selections.isFilterValid(variants, v.property_filter))
        .map((v) => PropertyValue.getInteger(v.value) ?? 0);
      if (marketDefValue) {
        fullSelections = Selections.setIntegers(p.name, [marketDefValue.value], fullSelections);
      } else if (propDefValues.length > 0) {
        const intValues = propDefValues.map((v) => PropertyValue.getInteger(v.value) ?? 0);
        fullSelections = Selections.setIntegers(p.name, intValues, fullSelections);
      } else {
        fullSelections = Selections.setIntegers(p.name, [validValues[0]], fullSelections);
      }
    } else if (R.keys(selections).length === 0) {
      if (marketDefValue) {
        fullSelections = Selections.setAmount(
          p.name,
          Amount.create(marketDefValue.value, marketDefValue.unit),
          fullSelections
        );
      } else if (propDefValues.length > 0) {
        const propDefValue = propDefValues[0];
        if (propDefValue.value.type === "amount") {
          fullSelections = Selections.setAmount(
            p.name,
            propDefValue.value.value as Amount.Amount<AnyQuantity>,
            fullSelections
          );
        } else if (propDefValue.value.type === "integer") {
          fullSelections = Selections.setIntegers(p.name, [propDefValue.value.value], fullSelections);
        } else {
          fullSelections = Selections.setText(p.name, propDefValue.value.value, fullSelections);
        }
      }
    }
  }

  const ecomPropertiesToSet = ecomVariant !== undefined ? ecomVariant.split(";") : [];

  // Overwrite values to default
  for (const property of ecomPropertiesToSet) {
    const [propertyName, propertyValue] = property.split("=");
    const values = propertyValue.split(",");

    fullSelections = Selections.setIntegers(
      propertyName,
      values.map((v) => parseInt(v, 10)),
      fullSelections
    );
  }

  return fullSelections;
}

export function getFullVariant(
  propertyTable: QP.PropertyTable,
  defaultValueTable: QP.CalcParamDefaultTable,
  market: string,
  variant: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const finalVariant = fillWithDefaultValues(propertyTable, defaultValueTable, market, variant);
  return finalVariant;
}

function fillWithDefaultValues(
  propertyTable: QP.PropertyTable,
  defaultValueTable: QP.CalcParamDefaultTable,
  market: string,
  variant: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let fullVariant = PropertyValueSet.setInteger("market", parseInt(market, 10), variant);
  for (const p of propertyTable) {
    // line below fixes error @typescript-eslint/no-loop-func : Function declared in a loop contains unsafe references to variable(s)
    const currentVariant = fullVariant;
    if (PropertyValueSet.hasProperty(p.name, fullVariant)) {
      continue;
    }
    const marketDefValue =
      defaultValueTable.find((v) => v.market === market && v.calc_param === p.name) ||
      defaultValueTable.find((v) => v.market === "" && v.calc_param === p.name);
    const propDefValue = p.def_value.find((_) => true);
    let value: PropertyValue.PropertyValue | undefined = undefined;
    if (p.quantity === "Discrete") {
      const validValues = p.value
        .filter((v) => PropertyFilter.isValid(currentVariant, v.property_filter))
        .map((v) => PropertyValue.getInteger(v.value));
      const validValue = validValues.find((v) => {
        if (!marketDefValue && !propDefValue) {
          return true;
        } else if (marketDefValue && marketDefValue.value === v) {
          return true;
        } else if (propDefValue && PropertyValue.getInteger(propDefValue.value) === v) {
          return true;
        }
        return false;
      });
      if (validValue === undefined) {
        continue;
      }
      value = PropertyValue.fromInteger(validValue);
    } else if (PropertyValueSet.isEmpty(variant)) {
      if (marketDefValue) {
        value = PropertyValue.fromAmount(Amount.create(marketDefValue.value, marketDefValue.unit));
      } else if (propDefValue) {
        value = propDefValue.value;
      }
    }
    if (value) {
      fullVariant = PropertyValueSet.set(p.name, value, fullVariant);
    }
  }
  return fullVariant;
}

export function getFullCalcParams(
  templatesTable: QP.DiaqTemplatesTable,
  resultItemsTable: QP.ResultItemsTable,
  defaultValueTable: QP.CalcParamDefaultTable,
  calcParams: PropertyValueSet.PropertyValueSet,
  variant: PropertyValueSet.PropertyValueSet,
  market: string,
  attributes: Attributes.Attributes,
  attributeTemplateMapping: QP.AttributeTemplateMappingTable
): PropertyValueSet.PropertyValueSet {
  const defaultValues = getDefaultCalcParams(
    templatesTable,
    resultItemsTable,
    defaultValueTable,
    variant,
    market,
    calcParams,
    attributes,
    attributeTemplateMapping
  );
  const finalVariant = PropertyValueSet.setValues(calcParams, defaultValues);
  return finalVariant;
}

function getDefaultCalcParams(
  templatesTable: QP.DiaqTemplatesTable,
  resultItemsTable: QP.ResultItemsTable,
  defaultValueTable: QP.CalcParamDefaultTable,
  variant: PropertyValueSet.PropertyValueSet,
  market: string,
  currentCalcParams: PropertyValueSet.PropertyValueSet,
  attributes: Attributes.Attributes,
  attributeTemplateMapping: QP.AttributeTemplateMappingTable
): PropertyValueSet.PropertyValueSet {
  const template = DiaqTemplates.getTemplatesFromAttributes(
    variant,
    templatesTable,
    attributeTemplateMapping,
    attributes
  ).find((t) => t.type === "ResultItems");
  if (!template) {
    return PropertyValueSet.Empty;
  }
  const resultItems = resultItemsTable.filter((i) => i.template === template.template);
  const calcParams = SC.getCalcParams(resultItems, attributes, variant, currentCalcParams);
  const marketDefaultValues = defaultValueTable.filter((r) => PropertyFilter.isValid(variant, r.property_filter));
  let defaultValues = PropertyValueSet.Empty;
  // Default calc params will only be filled in if the current calc params is empty.
  // But for accessories is_accessory is always set, so this is a workaround to get
  // default calc params for accessories.
  const isCurrentEmpty = PropertyValueSet.isEmpty(PropertyValueSet.removeProperty("is_accessory", currentCalcParams));
  for (const calcParam of calcParams) {
    const defaultValue =
      marketDefaultValues.find((v) => v.market === market && v.calc_param === calcParam.name) ||
      marketDefaultValues.find((v) => v.market === "" && v.calc_param === calcParam.name);
    if (calcParam.type === "Amount") {
      if (defaultValue && (PropertyValueSet.hasProperty(calcParam.name, currentCalcParams) || isCurrentEmpty)) {
        const value = Amount.create(defaultValue.value, defaultValue.unit);
        defaultValues = PropertyValueSet.setAmount(calcParam.name, value, defaultValues);
      } else if (
        calcParam.defaultValue &&
        (PropertyValueSet.hasProperty(calcParam.name, currentCalcParams) || isCurrentEmpty)
      ) {
        defaultValues = PropertyValueSet.setAmount(calcParam.name, calcParam.defaultValue, defaultValues);
      }
    } else if (calcParam.type === "Discrete" && calcParam.defaultValue !== undefined) {
      defaultValues = PropertyValueSet.setInteger(calcParam.name, calcParam.defaultValue, defaultValues);
    } else if (defaultValue) {
      defaultValues = PropertyValueSet.setInteger(calcParam.name, defaultValue.value, defaultValues);
    } else if (calcParam.values[0]) {
      defaultValues = PropertyValueSet.setInteger(calcParam.name, calcParam.values[0].value, defaultValues);
    }
  }
  return defaultValues;
}

export function generateVariantsForProperties(
  generateProperties: ReadonlyArray<string>,
  filterProperties: PropertyValueSet.PropertyValueSet,
  propertyTable: QP.PropertyTable
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const propertiesMap = R.fromPairs(
    propertyTable.map((p) => [p.name, p] as R.KeyValuePair<string, QP.ProductProperty>)
  );
  const referencesMap = R.fromPairs(
    propertyTable.map(
      (p) =>
        [
          p.name,
          R.uniq(R.unnest(p.value.map((v) => PropertyFilter.getReferencedProperties(v.property_filter)))),
        ] as R.KeyValuePair<string, Array<string>>
    )
  );

  const missingProperties = generateProperties.filter((p) => !propertiesMap[p]);
  if (missingProperties.length > 0) {
    console.warn("Generate properties that are not in properties: ", missingProperties);
  }

  // generateproperties = propertyNames found in tables on product
  // properitesMap = product properties on product
  // filterProperties, found on searchproduct

  // 1. Tables might contain more properties than the product has, this is the case for Frico, clean out properties not available in product
  // const productProperties = generateProperties.filter((p) => propertiesMap[p]);
  let properties = generateProperties.filter((p) => propertiesMap[p]);
  let newProperties = generateProperties.filter((p) => propertiesMap[p]);
  do {
    properties = newProperties;
    newProperties = [];
    for (const property of properties) {
      const references = referencesMap[property];
      if (references === undefined) {
        console.warn(property + " has no references", { propertyTable });
        return [];
      }
      newProperties.push(property, ...references);
    }
    newProperties = R.uniq(newProperties);
  } while (newProperties.length > properties.length);

  let variants: Array<PropertyValueSet.PropertyValueSet> = [PropertyValueSet.Empty];
  while (properties.length > 0) {
    const currentProperties = properties;
    const property = properties.find(
      (p) => !referencesMap[p].find((pr) => !!currentProperties.find((p2) => p2 === pr))
    );
    if (!property) {
      console.warn("Circular dependencies in properties", properties);
      return [];
    }
    properties = properties.filter((p) => p !== property);
    const propertyDef = propertiesMap[property];
    const newVariants = [];
    const filterValue = PropertyValueSet.getValue(property, filterProperties);
    const values = propertyDef.value.filter(
      (v) => filterValue === undefined || PropertyValue.equals(v.value, filterValue)
    );
    for (const value of values) {
      const intValue = PropertyValue.getInteger(value.value);
      if (intValue === undefined) {
        continue;
      }
      for (const variant of variants) {
        if (PropertyFilter.isValid(variant, value.property_filter)) {
          newVariants.push(PropertyValueSet.setInteger(property, intValue, variant));
        }
      }
    }
    variants = newVariants;
  }
  // 2. Adds properties removed in 1 so that they can be used in calculators
  // const noneProductProperties = PropertyValueSet.removeProperties(productProperties, filterProperties);
  const completeVariants = variants.map((v) => PropertyValueSet.merge(filterProperties, v));

  return completeVariants;
}

export function variantIsValid(propertyTable: QP.PropertyTable, variant: PropertyValueSet.PropertyValueSet): boolean {
  return propertyTable
    .filter((p) => PropertyFilter.isValid(variant, p.visibility_filter))
    .every((property) => {
      const propertyValue = PropertyValueSet.get(property.name, variant);
      if (!PropertyFilter.isValid(variant, property.validation_filter)) {
        return false;
      }
      if (property.quantity === "Discrete") {
        if (!propertyValue || propertyValue.type !== "integer") {
          return false;
        }
        const value = propertyValue.value;
        const foundValue = property.value.find((pv) => PropertyValue.getInteger(pv.value) === value);
        return foundValue !== undefined && PropertyFilter.isValid(variant, foundValue.property_filter);
      }
      return true;
    });
}
