/* eslint-disable no-restricted-properties */
import * as Attributes from "./attributes";

export function getArea(attributes: Attributes.Attributes): number | undefined {
  const diameter = Attributes.getInt("size-duct-inlet-circular-BASE-MET", attributes);
  if (diameter) {
    const diameterM = 0.001 * diameter;
    return Math.PI * Math.pow(diameterM * 0.5, 2);
  }
  const width = Attributes.getInt("size-duct-inlet-rectangular-width-BASE-MET", attributes);
  const height = Attributes.getInt("size-duct-inlet-rectangular-height-BASE-MET", attributes);

  if (width && height) {
    const widthM = 0.001 * width;
    const heightM = 0.001 * height;
    return widthM * heightM;
  }
  // Informed by Jonas Enbom 2022-10-19, this does not exist anymore
  // const side = Attributes.getInt("size-duct-inlet-square-BASE-MET", attributes);
  // if (side) {
  //   const sideM = 0.001 * side;
  //   return sideM * sideM;
  // }
  console.log("Could not find size attributes");
  return undefined;
}
