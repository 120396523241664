import type { RehydrateAction } from "redux-persist";
import type { ImageTooltip } from "./types";

export type Action = SetImageTooltip | StartCalculation | Reset | RehydrateAction | SetUiFilterNoResults;

export interface Reset {
  readonly type: "search-products/RESET";
}

export function reset(): Reset {
  return {
    type: "search-products/RESET",
  };
}

export interface SetImageTooltip {
  readonly type: "search-products/SET_IMAGE_TOOLTIP";
  readonly payload: ImageTooltip | undefined;
}

export function setImageTooltip(toolTip: ImageTooltip | undefined): SetImageTooltip {
  return {
    type: "search-products/SET_IMAGE_TOOLTIP",
    payload: toolTip,
  };
}

export interface StartCalculation {
  readonly type: "search/START_CALCULATION";
}

export interface SetUiFilterNoResults {
  readonly type: "search-products/SET_UI_FILTER_NO_RESULTS";
  readonly payload: boolean;
}

export function setUiFilterNoResults(uiFilterNoResults: boolean): SetUiFilterNoResults {
  return {
    type: "search-products/SET_UI_FILTER_NO_RESULTS",
    payload: uiFilterNoResults,
  };
}
