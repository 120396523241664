import * as React from "react";
import type * as SC from "shared-lib/system-calculator";

export interface Props {
  readonly results: SC.ResultItemOutputMap;
}

interface Base64DiagramType {
  readonly diagramBase64: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBase64Diagram(arg: any): arg is Base64DiagramType {
  return arg.diagramBase64 !== undefined;
}

export function Base64Diagram(props: Props): React.ReactElement {
  const { results } = props;

  const maybeResultItem = results[Object.keys(results)[0]];
  const resultItem =
    (maybeResultItem && maybeResultItem.type === "OutputMapperSuccess" && maybeResultItem.result) || undefined;
  const resultValue = resultItem?.value;
  if (!resultValue || !isBase64Diagram(resultValue)) {
    return <div />;
  }

  return (
    <div>
      <img
        src={`data:image/png;base64,${resultValue.diagramBase64}`}
        style={{ maxWidth: "229px", maxHeight: "164px" }}
      />
    </div>
  );
}
