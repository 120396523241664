import type * as QP from "shared-lib/query-product";
import type { AnyQuantity } from "shared-lib/uom";
import { customUnits, CustomUnitsLookup } from "shared-lib/uom";
import * as R from "ramda";
import { Unit, Serialize, UnitMap } from "uom";
import type { Selector } from "reselect";
import { createSelector } from "reselect";
import type {
  State,
  Value,
  GroupValues,
  GetUnitFunction,
  GetUnitsFunction,
  GetDecimalsFunction,
  SoundFilter,
  FieldUnitSettings,
  ClosedGroupsSettings,
  GetExtraTextFunction,
  OctaveBandsType,
} from "./types";

export function getShowAdvanced(s: State): boolean {
  return getBool("general", "showAdvanced", false, s);
}

export function getShowDiagrams(s: State): boolean {
  return getBool("general", "showDiagrams", true, s);
}

export function getSoundFilter(s: State): SoundFilter {
  return getString("general", "soundFilter", "A", s) as SoundFilter;
}

export function getOctaveBandsType(s: State): OctaveBandsType {
  return getString("general", "octaveBandsType", "Octave", s) as OctaveBandsType;
}

export function getSoundAbsorptionArea(s: State): string {
  return getString("general", "soundAbsorptionArea", "20", s);
}

export function getSoundDistance(s: State): string {
  return getString("general", "soundDistance", "3", s);
}

export function getClosedGroups(closedGroups: QP.ClosedGroups, s: State): ClosedGroupsSettings {
  const userClosed = getGroup("closedGroups", s);
  const closed = closedGroups.reduce(
    (sofar, g) => ({ ...sofar, [g.group]: userClosed[g.group] !== false }),
    {} as { readonly [group: string]: boolean }
  );
  return closed;
}

export function getShowBoxFanRpm(s: State): boolean {
  return getBool("boxFan", "showBoxFanRpm", true, s);
}

export function getShowBoxFanEfficiency(s: State): boolean {
  return getBool("boxFan", "showBoxFanEfficiency", true, s);
}

export function getShowBoxFanMotorPower(s: State): boolean {
  return getBool("boxFan", "showBoxFanMotorPower", true, s);
}

export function getShowStandardFanAlternatives(s: State): boolean {
  return getBool("boxFan", "showStandardFanAlternatives", true, s);
}

export interface GetUnitProps {
  readonly market: string;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly userSettings: State;
}

export const getUnit: Selector<GetUnitProps, never, GetUnitFunction> = createSelector(
  (p) => p.market,
  (p) => p.ct_MarketUnits,
  (p) => p.userSettings,
  (market, marketUnits, state): (<T extends AnyQuantity>(fieldName: string, quantity: T) => Unit.Unit<T>) => {
    return <T extends AnyQuantity>(fieldName: string, quantity: T): Unit.Unit<T> =>
      _getUnit(marketUnits, market, fieldName, quantity, state);
  }
);

export interface GetUnitsProps {
  readonly market: string;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
}

export const getUnits: Selector<GetUnitsProps, never, GetUnitsFunction> = createSelector(
  (p) => p.market,
  (p) => p.ct_MarketUnits,
  (market, marketUnits): (<T extends AnyQuantity>(fieldName: string, quantity: T) => ReadonlyArray<Unit.Unit<T>>) => {
    return <T extends AnyQuantity>(fieldName: string, quantity: T) =>
      _getUnits(marketUnits, market, fieldName, quantity);
  }
);

export interface FieldUnitSettingsProps {
  readonly userSettings: State;
}

export const getFieldUnitSettings: Selector<FieldUnitSettingsProps, never, FieldUnitSettings> = createSelector(
  (p) => p.userSettings,
  (state): FieldUnitSettings => {
    const pairs = R.toPairs(state.fieldUnits || {}).map(([fieldName, unitName]): [string, string] => [
      fieldName,
      unitName.toString(),
    ]);
    const res = R.fromPairs<string>(pairs);
    return res;
  }
);

export interface GetDecimalsProps {
  readonly market: string;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
}

export const getDecimals: Selector<GetDecimalsProps, unknown, GetDecimalsFunction> = createSelector(
  (p) => p.market,
  (p) => p.ct_MarketUnits,
  (market, marketUnits) => {
    return (fieldName: string, unit: Unit.Unit<AnyQuantity>) => _getDecimals(marketUnits, market, fieldName, unit);
  }
);

export interface GetExtraTextProps {
  readonly market: string;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
}

export const getExtraText: Selector<GetExtraTextProps, unknown, GetExtraTextFunction> = createSelector(
  (p) => p.market,
  (p) => p.ct_MarketUnits,
  (market, marketUnits) => {
    return (fieldName: string, unit: Unit.Unit<AnyQuantity>) => _getExtraText(marketUnits, market, fieldName, unit);
  }
);

/// Gets the unit to use for a property by the following best-effort logic:
// 1. Use user setting to get the unit for this property's name.
// 2. Use the property's default value's unit, or it's corresponding unit in the other system.
// 3. Use the property's quantity to get the default unit for that quantity.
function _getUnit<T extends AnyQuantity>(
  marketUnits: QP.MarketUnitsTable,
  market: string,
  fieldName: string,
  quantity: T,
  state: State
): Unit.Unit<T> {
  // Get from user settings
  const userUnit = getStringOrUndefined("fieldUnits", fieldName, state);
  const unit = userUnit ? (Serialize.stringToUnit(userUnit, CustomUnitsLookup) as Unit.Unit<T>) : undefined;
  if (userUnit && unit) {
    return unit;
  }

  // Get for market
  const marketUnit = marketUnits.find((r) => r.market === market && r.field_name === fieldName && r.type === "Default");
  if (marketUnit) {
    return marketUnit.unit as Unit.Unit<T>;
  }

  // Get default
  const defaultUnit = marketUnits.find((r) => r.market === "" && r.field_name === fieldName && r.type === "Default");
  if (defaultUnit) {
    return defaultUnit.unit as Unit.Unit<T>;
  }

  // Get first available unit for quantity
  return UnitMap.getUnitsForQuantity(quantity, customUnits)[0] as Unit.Unit<T>;
}

function _getUnits<T extends AnyQuantity>(
  marketUnits: QP.MarketUnitsTable,
  market: string,
  fieldName: string,
  quantity: T
): ReadonlyArray<Unit.Unit<T>> {
  const marketFieldUnits = marketUnits
    .filter((r) => r.market === market && r.field_name === fieldName)
    .map((r) => r.unit);
  if (marketFieldUnits.length > 1) {
    return marketFieldUnits as Array<Unit.Unit<T>>;
  }
  const defaultFieldUnits = marketUnits.filter((r) => r.market === "" && r.field_name === fieldName).map((r) => r.unit);
  if (defaultFieldUnits.length > 0) {
    return defaultFieldUnits as Array<Unit.Unit<T>>;
  }
  if (quantity === "Dimensionless") {
    return [customUnits.Percent] as Array<Unit.Unit<T>>;
  }
  return UnitMap.getUnitsForQuantity(quantity, customUnits) as Array<Unit.Unit<T>>;
}

function _getExtraText(
  marketUnits: QP.MarketUnitsTable,
  market: string,
  fieldName: string,
  unit: Unit.Unit<AnyQuantity>
): string {
  // Get for market
  const marketUnit = marketUnits.find(
    (r) => r.market === market && r.field_name === fieldName && Unit.equals(r.unit, unit)
  );
  if (marketUnit) {
    return marketUnit.extra_text;
  }

  // Get default
  const defaultUnit = marketUnits.find(
    (r) => r.market === "" && r.field_name === fieldName && Unit.equals(r.unit, unit)
  );
  if (defaultUnit) {
    return defaultUnit.extra_text;
  }
  return "";
}

function _getDecimals(
  marketUnits: QP.MarketUnitsTable,
  market: string,
  fieldName: string,
  unit: Unit.Unit<AnyQuantity>
): number {
  // Get for market
  const marketUnit = marketUnits.find(
    (r) => r.market === market && r.field_name === fieldName && Unit.equals(r.unit, unit)
  );
  if (marketUnit) {
    return marketUnit.decimals;
  }

  // Get default
  const defaultUnit = marketUnits.find(
    (r) => r.market === "" && r.field_name === fieldName && Unit.equals(r.unit, unit)
  );
  if (defaultUnit) {
    return defaultUnit.decimals;
  }

  return 2;
}

function getString(group: string, key: string, defaultValue: string, state: State): string {
  const v = getValue(group, key, state);
  if (typeof v !== "string") {
    return defaultValue;
  }
  return v;
}

function getStringOrUndefined(group: string, key: string, state: State): string | undefined {
  const v = getValue(group, key, state);
  if (typeof v !== "string") {
    return undefined;
  }
  return v;
}

function getGroup(group: string, state: State): GroupValues {
  const g = state[group] || {};
  return g;
}

// function getNumber(group: string, key: string, defaultValue: number, state: State): number {
//   const v = getValue(group, key, state);
//   if (typeof v !== "number") {
//     return defaultValue;
//   }
//   return v;
// }

// function getNumberOrUndefined(group: string, key: string, state: State): number | undefined {
//   const v = getValue(group, key, state);
//   if (typeof v !== "number") {
//     return undefined;
//   }
//   return v;
// }

// function getAmount<T extends AnyQuantity>(
//   group: string,
//   key: string,
//   defaultValue: Amount.Amount<T>,
//   state: State
// ): Amount.Amount<T> {
//   const v = getString(group, key, "", state);
//   const pv = PropertyValue.fromString(v);
//   if (!pv || pv.type !== "amount") {
//     return defaultValue;
//   }
//   return pv.value;
// }

// function getAmountOrUndefined<T extends AnyQuantity>(
//   group: string,
//   key: string,
//   state: State
// ): Amount.Amount<T> | undefined {
//   const v = getString(group, key, "", state);
//   const pv = PropertyValue.fromString(v);
//   if (!pv || pv.type !== "amount") {
//     return undefined;
//   }
//   return pv.value;
// }

function getBool(group: string, key: string, defaultValue: boolean, state: State): boolean {
  const v = getValue(group, key, state);
  if (typeof v !== "boolean") {
    return defaultValue;
  }
  return v;
}

// function getPropertyValueSet(group: string, key: string, state: State): PropertyValueSet.PropertyValueSet {
//   const v = getValue(group, key, state);
//   if (typeof v !== "string") {
//     return PropertyValueSet.Empty;
//   }
//   return PropertyValueSet.fromString(v) || PropertyValueSet.Empty;
// }

function getValue(group: string, key: string, state: State): Value | undefined {
  const g = state[group];
  if (g === undefined) {
    return undefined;
  }
  const v = state[group][key];
  if (v === undefined) {
    return undefined;
  }
  return v;
}
