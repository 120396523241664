import * as R from "ramda";
import * as React from "react";
import { PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import type * as UserSettingsShared from "shared-lib/user-settings";
import type { TranslateFunction } from "shared-lib/language-texts";
import type { FormatNumberFunction } from "shared-lib/utils";
import type { Images } from "client-lib/properties-selector-elements";
import type * as QP from "shared-lib/query-product";
import { PropertyGroup } from "./property-group";
import type {
  PropertyFormats,
  OnUnitChangedFn,
  OnUnitClearedFn,
  PropertyCalculateFunctions,
  Property,
  OnPropertiesChanged,
  TranslatePropertyName,
  TranslatePropertyValue,
} from "./types";

export interface PropertiesSelectorProps {
  readonly productId: string;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly productProperties: ReadonlyArray<Property>;
  readonly onChanged: OnPropertiesChanged;
  readonly onUnitChanged: OnUnitChangedFn;
  readonly onUnitCleared: OnUnitClearedFn;
  readonly translatePropertyName?: TranslatePropertyName;
  readonly translatePropertyValue?: TranslatePropertyValue;
  readonly translate: TranslateFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly propertySelectorTypes: QP.PropertySelectorTypesTable;
  readonly hideInvalidValues: boolean;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly getUnits: UserSettingsShared.GetUnitsFunction;
  readonly propertyFormats: PropertyFormats;
  readonly propertyCalculateFunctions?: PropertyCalculateFunctions;
  readonly showAdvanced: boolean;
  readonly hideProperties: ReadonlyArray<string>;
  readonly images: Images;
  readonly closedGroups: UserSettingsShared.ClosedGroupsSettings;
  readonly onToggleGroupClosed: (group: string) => void;
  readonly extraGroupTitle?: string;
  readonly hideTitle?: boolean;
}

export function PropertiesSelector({
  productId,
  propertyValueSet,
  productProperties,
  onChanged,
  onUnitChanged,
  onUnitCleared,
  translatePropertyName,
  translatePropertyValue,
  translate,
  formatNumber,
  propertySelectorTypes,
  hideInvalidValues,
  hideEmptyUnitSelectors,
  getUnits,
  propertyFormats,
  propertyCalculateFunctions,
  showAdvanced,
  hideProperties,
  images,
  closedGroups,
  onToggleGroupClosed,
  extraGroupTitle,
  hideTitle,
}: PropertiesSelectorProps): React.ReactElement<PropertiesSelectorProps> {
  const groups = R.groupBy((p) => p.group || "main", productProperties);
  return (
    <div>
      {R.keys(groups).map((group) => (
        <PropertyGroup
          key={group}
          productId={productId}
          group={group}
          extraTitle={extraGroupTitle}
          properties={groups[group]}
          allProperties={productProperties.filter((p) => !hideProperties.some((hp) => hp === p.name))}
          propertyValueSet={propertyValueSet}
          onChanged={(properties, propertyNames) =>
            onChangeWithCalculate(onChanged, propertyCalculateFunctions, properties, propertyNames)
          }
          onUnitChanged={onUnitChanged}
          onUnitCleared={onUnitCleared}
          translate={translate}
          translatePropertyName={translatePropertyName}
          translatePropertyValue={translatePropertyValue}
          selectorTypes={propertySelectorTypes}
          getUnits={getUnits}
          formatNumber={formatNumber}
          propertyFormats={propertyFormats}
          showAdvanced={showAdvanced}
          hideInvalidValues={hideInvalidValues}
          hideEmptyUnitSelectors={hideEmptyUnitSelectors}
          images={images}
          closed={closedGroups[group]}
          onToggleClosed={() => onToggleGroupClosed(group)}
          hideTitle={hideTitle}
        />
      ))}
    </div>
  );
}

function onChangeWithCalculate(
  onChange: OnPropertiesChanged,
  propertyCalculateFunctions: PropertyCalculateFunctions | undefined,
  properties: PropertyValueSet.PropertyValueSet,
  propertyNames: ReadonlyArray<string>
): void {
  if (propertyCalculateFunctions === undefined) {
    onChange(properties, propertyNames);
  } else {
    const calculatedProperties = propertyNames.reduce((sofar, name) => {
      const calculateFunction = propertyCalculateFunctions[name];
      if (calculateFunction) {
        return calculateFunction(sofar);
      } else {
        return sofar;
      }
    }, properties);

    const calculatedNames = PropertyValueSet.getPropertyNames(calculatedProperties).filter((calcualtedName) => {
      const previousValue = PropertyValueSet.getValue(calcualtedName, properties);
      const calculatedValue = PropertyValueSet.getValue(calcualtedName, calculatedProperties);
      if (previousValue === undefined && calculatedValue !== undefined) {
        return true;
      } else if (calculatedValue === undefined) {
        return false;
      } else if (!PropertyValue.equals(previousValue, calculatedValue)) {
        return true;
      } else {
        return false;
      }
    });
    onChange(calculatedProperties, [...propertyNames, ...calculatedNames]);
  }
}
