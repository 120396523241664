/* eslint-disable no-restricted-globals */
export function calculateDampingSabine(sabine: number): number {
  if (!isFinite(sabine)) {
    return 0;
  }
  return 10 * Math.log10(1 / (4 * Math.PI * 9) + 4 / sabine);
}

export function calculateDampingForDistance(soundDistance: number): number {
  if (!isFinite(soundDistance)) {
    return 0;
  }
  return Math.abs(10 * Math.log10(1 / (4 * Math.PI * soundDistance * soundDistance)));
  // return 10 * Math.log10(1 / (4 * Math.PI * 9) + 4 / soundDistance);
}
