import {
  relativeToMassMixingRatio,
  moistAirDensity,
  standardAirPressure,
  massMixingRatioToRelativeHumidity
} from "./functions";

export interface ElectricHeaterOutput {
  readonly current: number;
  readonly powerPercent: number;
  readonly outletAirTemperature: number;
  readonly outletAirHumidity: number;
}

export interface ElectricHeaterOutletAirTemperatureInput {
  readonly airFlowLps: number;
  readonly inletAirTemperatureC: number;
  readonly inletAirHumidityP: number;
  readonly outletAirTemperatureC: number;
  readonly maxPowerW: number;
  readonly voltageV: number;
  readonly phasesO: number;
}

export function calculateForOutletAirTemperature(input: ElectricHeaterOutletAirTemperatureInput): ElectricHeaterOutput {
  const {
    airFlowLps,
    inletAirTemperatureC,
    inletAirHumidityP,
    outletAirTemperatureC,
    maxPowerW,
    voltageV,
    phasesO
  } = input;

  const absH = relativeToMassMixingRatio(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0);
  const airMass =
    moistAirDensity(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0) * (airFlowLps / 1000.0);

  let dT = maxPowerW / (airMass * 1005);
  let powerPercent = 100;
  const requestedDT = outletAirTemperatureC - inletAirTemperatureC;
  if (dT > requestedDT) {
    powerPercent = 100 * requestedDT / dT;
    dT = requestedDT;
  } else if (dT < 0) {
    dT = 0;
    powerPercent = 0;
  }
  const actualOutletAirTemperatureC = inletAirTemperatureC + dT;
  const outletAirHumidityP =
    massMixingRatioToRelativeHumidity(actualOutletAirTemperatureC, standardAirPressure, absH) * 100.0;

  const current = calculateCurrent(maxPowerW, voltageV, phasesO);

  return {
    current: current,
    powerPercent: powerPercent,
    outletAirTemperature: actualOutletAirTemperatureC,
    outletAirHumidity: outletAirHumidityP
  };
}

export interface ElectricHeaterPowerInput {
  readonly airFlowLps: number;
  readonly inletAirTemperatureC: number;
  readonly inletAirHumidityP: number;
  readonly powerW: number;
  readonly maxPowerW: number;
  readonly voltageV: number;
  readonly phasesO: number;
}

export function calculateForPower(input: ElectricHeaterPowerInput): ElectricHeaterOutput {
  const { airFlowLps, inletAirTemperatureC, inletAirHumidityP, powerW, maxPowerW, voltageV, phasesO } = input;

  const absH = relativeToMassMixingRatio(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0);
  const airMass =
    moistAirDensity(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0) * (airFlowLps / 1000.0);
  const requestedPowerW = Math.max(0, Math.min(powerW, maxPowerW));
  const dT = requestedPowerW / (airMass * 1005);
  const powerPercent = 100 * requestedPowerW / maxPowerW;
  const actualOutletAirTemperatureC = inletAirTemperatureC + dT;
  const outletAirHumidityP =
    massMixingRatioToRelativeHumidity(actualOutletAirTemperatureC, standardAirPressure, absH) * 100.0;

  const current = calculateCurrent(maxPowerW, voltageV, phasesO);
  return {
    current: current,
    powerPercent: powerPercent,
    outletAirTemperature: actualOutletAirTemperatureC,
    outletAirHumidity: outletAirHumidityP
  };
}

function calculateCurrent(maxPowerW: number, voltageV: number, phasesO: number): number {
  if (phasesO === 1 || phasesO === 2) {
    return maxPowerW / voltageV;
  } else if (phasesO === 3) {
    return maxPowerW / (voltageV * Math.sqrt(3));
  }
  throw new Error("Unknown number of phases " + phasesO.toString());
}
