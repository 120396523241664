import { PropertyFilter } from "@promaster-sdk/property";
import type { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Units } from "uom-units";
import type { Result, AllProductTables, MetaTables, SearchMetaTables } from "../types";
import * as BathroomFan from "../../system-calculator/fantech-fan/bathroom-fan";

export function preFilter(
  searchVariant: PropertyValueSet.PropertyValueSet,
  _productId: string,
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  _metaTables: MetaTables,
  searchMetaTable: SearchMetaTables,
  productTables: AllProductTables
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const bathroomFanSelections = searchMetaTable.ct_BathroomFanSelection.filter((r) =>
    PropertyFilter.isValid(searchVariant, r.property_filter)
  );
  const input = BathroomFan.getBathroomInput(searchVariant);
  if (!input) {
    return [];
  }
  const airFlow = BathroomFan.calculateBathroomFanAirFlow(input);
  const airFlowCfm = Amount.valueAs(Units.CubicFeetPerMinute, airFlow);
  const filteredVariants = [];
  for (const variant of variants) {
    const m3ItemNo = productTables.ct_ItemNo.find((r) => PropertyFilter.isValid(variant, r.property_filter))?.item_no;
    if (!m3ItemNo) {
      continue;
    }
    if (
      bathroomFanSelections.some(
        (r) => airFlowCfm >= r.min_airflow && airFlowCfm <= r.max_airflow && r.item_no === m3ItemNo
      )
    ) {
      filteredVariants.push(variant);
    }
  }
  return filteredVariants;
}

export function postFilter(
  _searchVariant: PropertyValueSet.PropertyValueSet,
  results: ReadonlyArray<Result>,
  _calcParams: PropertyValueSet.PropertyValueSet,
  _metaTables: MetaTables,
  _searchMetaTable: SearchMetaTables,
  _productTables: AllProductTables
): ReadonlyArray<Result> {
  return results;
}
