import type * as Actions from "./actions";

export interface State {
  readonly openDescriptions: ReadonlyArray<string>;
}

const initialState = {
  openDescriptions: []
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "frico-product-table/Open":
      return { ...state, openDescriptions: [...state.openDescriptions, action.payload] };
    case "frico-product-table/Close":
      return { ...state, openDescriptions: state.openDescriptions.filter(o => o !== action.payload) };
    default:
      return state;
  }
}
