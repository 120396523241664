import * as React from "react";
import type { Dispatch } from "client-lib/redux-integration";
import { Button, HToolbar } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import type { TranslateFunction } from "shared-lib/language-texts";
import type * as Search from "client-lib/search";
import { clientConfig } from "config";
import type { NavigateToFricoSpecificationText } from "types";
import * as SpecificationText from "../../frico-specification-text-epim/actions";

export interface Props {
  readonly translate: Texts.TranslateFunction;
  readonly fricoSpecificationTextUrl?: string;
  readonly navigateToSpecificationText: NavigateToFricoSpecificationText;
  readonly dispatch: Dispatch<SpecificationText.Action>;
  readonly calculationState: Search.State;
  readonly itemsAreAdded: boolean;
}

export function FricoFooter(props: Props): JSX.Element | null {
  const { translate } = props;
  return (
    <>
      {createDisclaimer(translate)}
      {addToSpecificationTextButton(props, translate)}
    </>
  );
}

function createDisclaimer(translate: TranslateFunction): React.ReactElement {
  return (
    <div className={`text-xs pt-16 pb-16 ${clientConfig.addOuterPadding ? "px-400 print:px-0" : ""}`}>
      <div>{translate(Texts.frico_disclaimer_row_1())}</div>
      <div>{translate(Texts.frico_disclaimer_row_2())}</div>
    </div>
  );
}

function addToSpecificationTextButton(props: Props, translate: TranslateFunction): React.ReactElement | undefined {
  const { calculationState, dispatch, fricoSpecificationTextUrl } = props;
  const disabled = !props.itemsAreAdded || calculationState.type === "Calculating";
  const button = (
    <Button
      small={true}
      disabled={disabled}
      buttonType="secondary"
      clicked={() => {
        if (!fricoSpecificationTextUrl) {
          dispatch(SpecificationText.clearAddedSearchResultIds(true));
          props.navigateToSpecificationText();
        } else {
          dispatch(SpecificationText.clearAddedSearchResultIds(false));
        }
      }}
      label={translate(Texts.add_to_specification_text())}
    />
  );
  return (
    <HToolbar
      className={`print:hidden pt-16 pb-16 sticky -bottom-16 ${
        clientConfig.addOuterPadding ? "px-400 print:px-0" : ""
      }`}
    >
      {fricoSpecificationTextUrl && !disabled ? (
        <a target="_blank" href={fricoSpecificationTextUrl}>
          {button}
        </a>
      ) : (
        button
      )}
    </HToolbar>
  );
}
