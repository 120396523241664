/* eslint-disable no-restricted-properties */
import type * as Vec2 from "./vector2";

export interface PowerCurve {
  readonly a: number;
  readonly b: number;
}

export function powerCurveCreate(a: number, b: number): PowerCurve {
  return {
    a: a,
    b: b,
  };
}

export function powerCurveCreateFromPoints(points: ReadonlyArray<Vec2.Vec2>): PowerCurve {
  if (points.length === 0) {
    return powerCurveCreate(0, 2);
  } else if (points.length === 1) {
    const a = points[0].y / Math.pow(points[0].x, 2);
    return powerCurveCreate(a, 2);
  } else {
    const m = sum(points.map((p) => Math.pow(p.y, 2)));
    const avgX = sum(points.map((p) => Math.pow(p.y, 2) * Math.log(p.x))) / m;
    const t = sum(points.map((p) => Math.pow(p.y, 2) * Math.pow(Math.log(p.x) - avgX, 2)));
    const b = sum(points.map((p) => Math.pow(p.y, 2) * Math.log(p.y) * (Math.log(p.x) - avgX))) / t;
    const a = Math.exp(sum(points.map((p) => Math.pow(p.y, 2) * Math.log(p.y))) / m - b * avgX);
    return powerCurveCreate(a, b);
  }
}

export function powerCurveGetY(x: number, powerCurve: PowerCurve): number {
  return powerCurve.a * Math.pow(x, powerCurve.b);
}

function sum(values: ReadonlyArray<number>): number {
  return values.reduce((a, b) => a + b, 0);
}
