import { retryFetch } from "shared-lib/fetch";

export interface BoxFanBlackBoxInput {
  readonly Airflow: number; // m3/h
  readonly Pressure: number; // PA
  readonly AirDensity: number; // kg/m3
  readonly LPDistance: number; // m
  readonly Series: string; //  Possible values "L","R","SR", Seems other like T2L, T2SR,T2R,T3R are not supported by systemair or BoxFanSelection app
  readonly FanSize: string; // For Spain market this should be null, Calling DLL for Systemair, the value is retrieved from DB, Example of value "9/9"
}

export interface BoxFanSoundResult {
  readonly Hz63: number;
  readonly Hz125: number;
  readonly Hz250: number;
  readonly Hz500: number;
  readonly Hz1000: number;
  readonly Hz2000: number;
  readonly Hz4000: number;
  readonly Hz8000: number;
}

export interface BoxFanBlackBoxResult {
  readonly Airflow: number; // m3/h
  readonly AirPressure: number; // PA
  readonly Power: number; // W
  readonly Efficiency: number; // %
  readonly Speed: number; // RPM
  readonly AirVelocity: number; // m/s
  readonly TotalPressure: number; // PA
  readonly Size: string;
  readonly SoundInlet: BoxFanSoundResult;
  readonly SoundOutlet: BoxFanSoundResult;
  readonly SoundSurrounding: BoxFanSoundResult;
  readonly SoundPressureLevel: number; // LpA
  readonly EfficiencyGradeNReal: number;
  readonly EfficiencyGradeNNormal: number;
  readonly NObjective: number;
  readonly NReal: number;
  readonly MeasurementCategory: string;
  readonly EfficiencyCategory: string;
  readonly SpecificRatio: number;
  readonly DiagramBase64Png: string; // png image of the Diagram encoded as Base64, !Note Will always be null for now as the DLL method is not implemented
}

// Example of Working input for Systemair Except for Spain market
// {
//   "AirFlow":1000,
//   "Pressure":100,
//   "AirDensity":1.2,
//   "LPDistance":1,
//   "FanSize":"9/9",
//   "Series":"L",
// }

// Example of Working input for Systemair Spain market only
// {
// "AirFlow":1000,
// "Pressure":300,
// "AirDensity":1.2,
// "LPDistance":1,
// "FanSize":null,
// "Series":"L",
//   }

// NOTE If FanSize is null you will recive 0 or more results, if fansize is set you will recieve 0 or 1 result
export async function calculate(input: BoxFanBlackBoxInput): Promise<ReadonlyArray<BoxFanBlackBoxResult>> {
  const result: ReadonlyArray<BoxFanBlackBoxResult> = await retryFetch(
    "https://design-dllcal.systemair.com/api/boxfanblackbox/calculate",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }
  ).then((r) => r.json());
  return result;
}
