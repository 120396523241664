export function itemNumberToServiceUrl(
  imageServiceUrl: string | undefined, // Specified in config
  itemNumber: string,
  scale?: number,
  maxWidth?: number,
  maxHeight?: number,
  format?: "jpeg" | "png",
  background?: string
): string {
  if (!imageServiceUrl) {
    return "";
  }

  const params = [];
  params.push(`itemNumber=${itemNumber}`);
  if (scale) {
    params.push(`scale=${scale}`);
  }
  if (maxWidth) {
    params.push(`maxWidth=${maxWidth}`);
  }
  if (maxHeight) {
    params.push(`maxHeight=${maxHeight}`);
  }
  if (format) {
    params.push(`format=${format}`);
  }
  if (background) {
    params.push(`background=${background.startsWith("#") ? background.slice(1) : background}`);
  }
  const paramsPart = params.join("&");
  return encodeURI(`${imageServiceUrl}?${paramsPart}`);
}
