import type { IdentityQuery, UrlResponse } from "./query-types";
import { exhaustiveCheck } from "../exhaustive-check/index";

export interface IdentityCacheState {}

const initialCache: IdentityCacheState = {};

export function selectQuery(
  _: IdentityCacheState | undefined = initialCache,
  query: IdentityQuery
): UrlResponse | undefined {
  switch (query.type) {
    case "Identity/Identity":
      return query.value;
    case "Identity/Noop":
      throw new Error("Not implemented yet.");
    default:
      return exhaustiveCheck(query, true);
  }
}
