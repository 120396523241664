import * as React from "react";

export interface RadioButtonProps {
  readonly label?: string | React.ReactNode;
  readonly checked: boolean;
  readonly checkedChanged?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;
}

export function RadioButton(props: RadioButtonProps): React.ReactElement<RadioButtonProps> {
  return (
    <div className={`form-radio ${props.className ?? ""}`}>
      <label className="form-control">
        <input
          name={props.name}
          checked={props.checked}
          type="radio"
          disabled={props.disabled}
          onChange={() => {
            if (!props.disabled && props.checkedChanged) {
              props.checkedChanged(!props.checked);
            }
          }}
        />
        {props.label && (
          <div className="ml-8 flex items-center">
            {typeof props.label === "string" ? <span>{props.label}</span> : props.label}
          </div>
        )}
      </label>
    </div>
  );
}
