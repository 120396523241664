import type { TechnicalParametersTable, SpecificationText } from "shared-lib/ecom-api";

export type EcomQuery = VariantNoToPriceQuery | ProductDescriptionQuery | Noop;
export type EcomResponse = VariantNoToPriceResponse | ProductDescriptionResponse | NoopResponse;

export interface VariantNoToPriceQuery {
  readonly source: "ecom";
  readonly type: "VariantNoToPrice";
  readonly url: string;
  readonly market: string;
  readonly time: number;
}
export type VariantNoToPriceResponse = { readonly [variantNo: string]: number };
export function variantNoToPrice(url: string, market: string): VariantNoToPriceQuery {
  return {
    source: "ecom",
    type: "VariantNoToPrice",
    url: url,
    market: market,
    time: Date.now(),
  };
}

export interface ProductDescriptionQuery {
  readonly source: "ecom";
  readonly type: "ProductDescription";
  readonly url: string;
  readonly market: string;
  readonly language: string;
  readonly itemNo: string;
  readonly variantId: string;
}
export interface ProductDescriptionResponse {
  readonly description: SpecificationText | undefined;
  readonly technicalParameters: TechnicalParametersTable | undefined;
}
export function productDescription(
  url: string,
  market: string,
  language: string,
  itemNo: string,
  variantId: string
): ProductDescriptionQuery {
  return {
    source: "ecom",
    type: "ProductDescription",
    url: url,
    market: market,
    language: language,
    itemNo: itemNo,
    variantId: variantId,
  };
}

// Noop
export interface Noop {
  readonly source: "ecom";
  readonly type: "Noop";
}
export type NoopResponse = object;
export function noop(): Noop {
  return {
    source: "ecom",
    type: "Noop",
  };
}
