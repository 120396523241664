/* eslint-disable no-restricted-properties */
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type * as QP from "shared-lib/query-product";
import type { PropertyValueSet } from "@promaster-sdk/property";
import { customUnits } from "shared-lib/uom";
import * as R from "ramda";
// Uncomment below to use AMCA calculation
import * as Interpolation from "../fan-calculation-amca/interpolation";
import * as FanAir from "../fan-calculation-amca/fan-air";
import * as FanSound from "../fan-calculation-amca/fan-sound";
// import * as FanAir from "../shared/fan-air";
// import * as FanSound from "../shared/fan-sound";
// import * as Interpolation from "../../interpolation";
import * as Types from "../types";
import type { CentrifugalFan, MotorResult, FanAirResult, Curve } from "../result-items-types";
import * as Sound from "../shared/sound";
import type { Input } from "./types";
import * as Messages from "../messages";
import * as FEI from "../fan-efficiency-index";

const source = "CentrifugalFanCalculator";
export async function calculate(input: Input): Promise<Types.CalculatorResult<CentrifugalFan>> {
  const {
    airFlow,
    externalPressure,
    airDensity,
    calcParams,
    airData,
    airLimitsData,
    centrifugalFan,
    centrifugalMotors,
    soundData,
    voltage,
    frequency,
    configurableFan,
    motorPowerAttribute,
    impellerDiameterMM,
    numberOfFanBlades,
    m3WeightKg
  } = input;

  const speedControl = "Stepless";
  const fanResult = FanAir.calculate(
    speedControl,
    airData,
    airLimitsData,
    airFlow,
    externalPressure,
    [],
    airDensity,
    undefined,
    false,
    false
  ); // If standard fan and we don't have the motor power we don't know what motor to use

  if (!configurableFan && motorPowerAttribute === undefined) {
    return Types.createCalculatorError([Messages.Error_StandardFanWithoutMotorAttribute(source)]);
  }

  const messages = [];

  if (
    fanResult.airFlow !== undefined &&
    fanResult.desiredAirFlow !== undefined &&
    fanResult.desiredPointIsOutsideValidArea
  ) {
    messages.push(Messages.Warning_PointAdjustedToClosestValid(source));
  }

  const totalPressure = calculateTotalPressure(
    fanResult,
    Amount.valueAs(Units.KilogramPerCubicMeter, airDensity),
    centrifugalFan.outlet_area
  );

  /// FEI -disabled for now
  //  use http://localhost:8888/?item=40022 for testing
  let fanEfficiencyIndex = undefined;
  if (
    airDensity !== undefined &&
    fanResult !== undefined &&
    fanResult.workingPoint !== undefined &&
    fanResult.shaftPower !== undefined &&
    totalPressure !== undefined && frequency !== undefined
  ) {
    //console.log(fan.power);

    //We calculate static pressure, if we need total we have to calculate it
    // const outletDuctSize = Attributes.getFloatOrDefault("size-duct-outlet-circular-BASE-IMP", attributes, -1);
    // if (outletDuctSize !== -1) {
    // const area = Math.PI * (outletDuctSize / 2) ** 2;
    // const totalPressure =
    //   fan.workingPoint.point.y +
    //   ((fan.workingPoint.point.x * 0.001) / (1097.8 * area)) ** 2 * ///////////// THIS IS WRONG! ONLY FOR SI UNITS (1097.8) see function calculateTotalPressure
    //     Amount.valueAs(customUnits.KilogramPerCubicMeter, airDensity);

    const fei = FEI.fanEfficiencyIndexFanWithoutMotor(
      "Centrifugal Inline",
      "total",
      Amount.valueAs(customUnits.KilogramPerCubicMeter, airDensity),
      fanResult.workingPoint.point.x * 0.001,
      totalPressure,
      "Direct",
      Amount.valueAs(customUnits.KiloWatt, fanResult.shaftPower),
      frequency
    );
    if (fei !== undefined) {
      fanEfficiencyIndex = Amount.create(fei, customUnits.One, 10);
    }
    //}
  }
  // END FEI

  const fan = addRpmLabelsToCurves(fanResult, airData);

  const motorPowerToFind = configurableFan ? fan.power : Amount.create(motorPowerAttribute!, Units.Watt);

  const motor =
    motorPowerToFind &&
    findMotor(centrifugalMotors, centrifugalFan, Amount.valueAs(Units.KiloWatt, motorPowerToFind), !configurableFan);
  const powerCurves = generatePowerCurves(
    airData,
    [0.8, 1, 1.5, 2, 3, 4, 5, 6, 7, 10, 15, 18.5, 29, 30, 40, 50],
    centrifugalFan
  );
  const classCurves = generateClassCurves(airData, centrifugalFan);

  // const eff = generateEfficiency(airData, [53, 62, 73, 79, 75, 63, 46], centrifugalFan);
  // console.log(eff);

  const partialResult = checkErrors(fan, motor, calcParams, powerCurves, classCurves);
  if (partialResult) {
    return partialResult;
  }

  const inletSound = FanSound.calcSound(
    speedControl,
    airFlow,
    externalPressure,
    airDensity,
    fan,
    soundData,
    "Inlet",
    undefined,
    impellerDiameterMM,
    numberOfFanBlades
  );
  const outletSound = FanSound.calcSound(
    speedControl,
    airFlow,
    externalPressure,
    airDensity,
    fan,
    soundData,
    "Outlet",
    undefined,
    impellerDiameterMM,
    numberOfFanBlades
  );
  const surroundingSound = FanSound.calcSound(
    speedControl,
    airFlow,
    externalPressure,
    airDensity,
    fan,
    soundData,
    "Surrounding",
    undefined,
    impellerDiameterMM,
    numberOfFanBlades
  );
  
  const faceVelocity = calculateFaceVelocity(fan, centrifugalFan);
  const totalEfficiency = calculateTotalEfficiency(fan, centrifugalFan);

  const octaveBandA = FanSound.aWeightOctaveBands(inletSound.octaveBands);
  const octaveBand = FanSound.applyOneAttenuation(octaveBandA, Sound.calculateDampingForDistance(3));
  const soundLpaAt3m = FanSound.calcTotFromOctaveBands(octaveBand);

  return Types.createCalculatorSuccess(
    [
      {
        value:
          (motor && fan.power && Amount.valueAs(Units.Watt, motor.power) - Amount.valueAs(Units.Watt, fan.power)) ||
          Infinity,
        descending: false,
      },
    ],
    {
      air: fan,
      fanEfficiencyIndex: fanEfficiencyIndex,
      totalWeight: configurableFan ? Amount.create(motor!.weight + centrifugalFan.base_weight, Units.Kilogram) : m3WeightKg !== undefined ? Amount.create(m3WeightKg, Units.Kilogram) : undefined,
      airVelocity: faceVelocity,
      motor: motor,
      inletSound: inletSound.octaveBands3rd,
      outletSound: outletSound.octaveBands3rd,
      surroundingSound: surroundingSound.octaveBands3rd,
      voltage: (voltage && Amount.create(voltage, Units.Volt)) || undefined,
      frequency: (frequency && Amount.create(frequency, Units.Hertz)) || undefined,
      totalPressure: (totalPressure && Amount.create(totalPressure, Units.Pascal)) || undefined,
      powerCurves: powerCurves,
      classCurves: classCurves,
      totalEfficiency: totalEfficiency,
      soundPressureLevelAt3m: (soundLpaAt3m && Amount.create(soundLpaAt3m, Units.Decibel)) || undefined,
    },
    messages,
    calcParams
  );
}

function calculateTotalEfficiency(
  fanAir: FanAirResult,
  fan: QP.CentrifugalFan
): Amount.Amount<Quantity.Dimensionless> | undefined {
  const outletArea = fan.outlet_area;

  if (!fanAir.airDensity || !fanAir.airFlow || !fanAir.externalPressure || !fanAir.power) {
    return undefined;
  }

  const density = Amount.valueAs(Units.KilogramPerCubicMeter, fanAir.airDensity);
  const staticPressure = Amount.valueAs(Units.Pascal, fanAir.externalPressure);
  const airflow = Amount.valueAs(Units.CubicMeterPerSecond, fanAir.airFlow);
  const power = Amount.valueAs(Units.KiloWatt, fanAir.power);

  // eslint-disable-next-line no-restricted-properties
  const velocityPressure = (density * Math.pow(airflow / outletArea, 2)) / 2;
  const totalPressure = velocityPressure + staticPressure;
  const totalEfficiency = (airflow * totalPressure) / (10 * power);

  return Amount.create(totalEfficiency, Units.Percent);
}

function calculateFaceVelocity(
  fanAir: FanAirResult,
  fan: QP.CentrifugalFan
): Amount.Amount<Quantity.Velocity> | undefined {
  const airFlowCmps = (fanAir.workingPoint && fanAir.workingPoint.point.x / 1000) || 0;
  const faceVelocityMps = fan ? fan.outlet_area && airFlowCmps / fan.outlet_area : undefined;
  return (faceVelocityMps && Amount.create(faceVelocityMps, Units.MeterPerSecond)) || undefined;
}

function checkErrors(
  fan: FanAirResult,
  motor: MotorResult | undefined,
  calcParams: PropertyValueSet.PropertyValueSet,
  powerCurves: ReadonlyArray<Curve>,
  classCurves: ReadonlyArray<Curve> | undefined
): Types.CalculatorResult<CentrifugalFan> | undefined {
  let message: Messages.Message | undefined = undefined;

  if (!message && (!fan.power || !fan.workingPoint)) {
    message = Messages.Error_OutsideValidRange(source);
  }

  if (!message && !motor) {
    message = Messages.Error_NotEnoughPower(source);
  }

  const motorPower = motor && Amount.valueAs(Units.Watt, motor.power);
  const fanPower = fan.power && Amount.valueAs(Units.Watt, fan.power);
  if (!message && motorPower !== undefined && fanPower !== undefined && motorPower < fanPower) {
    message = Messages.Error_NotEnoughPower(source);
  }

  if (message) {
    return Types.createCalculatorSuccess(
      [
        {
          value:
            fan.distanceWorkingPointToDesiredPoint !== undefined ? fan.distanceWorkingPointToDesiredPoint : Infinity,
          descending: false,
        },
        { value: 1 - ((fan.efficiency && Amount.valueAs(Units.One, fan.efficiency)) || 1), descending: false },
      ],
      {
        air: fan,
        fanEfficiencyIndex: undefined,
        totalWeight: undefined,
        airVelocity: undefined,
        motor: motor,
        inletSound: undefined,
        outletSound: undefined,
        surroundingSound: undefined,
        voltage: undefined,
        frequency: undefined,
        totalPressure: undefined,
        powerCurves: powerCurves,
        classCurves: classCurves,
        totalEfficiency: undefined,
        soundPressureLevelAt3m: undefined,
      },
      [message],
      calcParams
    );
  }

  return undefined;
}

function addRpmLabelsToCurves(fan: FanAirResult, airData: ReadonlyArray<QP.AirData>): FanAirResult {
  const groupedPoints = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "r_v")
  );

  const curveLabels = Object.keys(groupedPoints).reduce<{ [key: string]: number }>((sofar, key) => {
    sofar[key] = groupedPoints[key][0].value;
    return sofar;
  }, {});

  const fanResult: FanAirResult = {
    ...fan,
    adjustedPressureCurves: fan.adjustedPressureCurves.map((c) => ({
      ...c,
      label: curveLabels[c.id]?.toString() || undefined,
    })),
  };
  return fanResult;
}

function generateClassCurves(
  airData: ReadonlyArray<QP.AirData>,
  centrifugalFan: QP.CentrifugalFan
): ReadonlyArray<Curve> {
  const rpmCurves = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "r_v")
  );
  const pressureCurves = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "Ps_v")
  );
  const curvesToGenerate = [
    { name: "class2", rpm: centrifugalFan.class_2_rpm },
    { name: "class3", rpm: centrifugalFan.class_3_rpm },
  ];
  if (curvesToGenerate.some((c) => !Number.isFinite(c.rpm))) {
    return [];
  }

  const curves = new Array<Curve>();
  for (const curveToGenerate of curvesToGenerate) {
    const stepKeys = R.keys(rpmCurves);
    const closestStep = stepKeys.reduce((sofar, key) => {
      const sofarRpm = rpmCurves[sofar][0].value;
      const rpm = rpmCurves[key][0].value;
      if (Math.abs(curveToGenerate.rpm - rpm) < Math.abs(curveToGenerate.rpm - sofarRpm)) {
        return key;
      } else {
        return sofar;
      }
    }, stepKeys[0]);

    const rpmCurve = rpmCurves[closestStep] || [];
    const pressureCurve = pressureCurves[closestStep] || [];

    const curvePoints = rpmCurve.reduce<Array<Interpolation.Vec2>>((sofar, rpmPoint) => {
      const airflowLs = rpmPoint.flow;
      const rpm = rpmPoint.value;
      const pressurePa = pressureCurve.find((p) => p.flow === airflowLs)?.value;
      if (pressurePa === undefined) {
        return sofar;
      }

      //https://www.axair-fans.co.uk/news/applications/understanding-basic-fan-laws/
      const newRpm = curveToGenerate.rpm;
      const newAirflowLs = (newRpm / rpm) * airflowLs;
      const newPressurePa = Math.pow(newRpm / rpm, 2) * pressurePa;

      sofar.push({
        x: newAirflowLs,
        y: newPressurePa,
      });
      return sofar;
    }, []);

    curves.push({
      id: curveToGenerate.name,
      controlVoltage: 0,
      supplyVoltage: 0,
      controlFrequency: 0,
      unitX: Units.LiterPerSecond,
      label: curveToGenerate.rpm.toString(),
      unitY: Units.Pascal,
      workMin: Math.min(...curvePoints.map((p) => p.x)),
      workMax: Math.max(...curvePoints.map((p) => p.x)),
      spline: Interpolation.splineCreateFromPoints(curvePoints),
    });
  }

  return curves;
}

function generatePowerCurves(
  airData: ReadonlyArray<QP.AirData>,
  kwSteps: ReadonlyArray<number>,
  centrifugalFan: QP.CentrifugalFan
): ReadonlyArray<Curve> {
  const powerCurves = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "P_v")
  );
  const pressureCurves = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "Ps_v")
  );
  const rpmCurves = R.groupBy(
    (r) => `${r.step} ${r.volt_ctrl}`,
    airData.filter((d) => d.param === "r_v")
  );
  const keys = Object.keys(powerCurves);
  if (keys.length === 0) {
    return [];
  }
  const key = keys[keys.length - 1]; // Take bottom curve
  const onePowerCurve = powerCurves[key];

  const matchingRpm = rpmCurves[key];
  const matchingPressure = pressureCurves[key];

  const dataPoints: ReadonlyArray<DataPoint> = onePowerCurve.reduce<Array<DataPoint>>((sofar, c) => {
    const fanspeed = matchingRpm.find((r) => r.flow === c.flow) || undefined;
    const press = matchingPressure.find((r) => r.flow === c.flow) || undefined;
    if (fanspeed === undefined || press === undefined) {
      return sofar;
    }
    const density = 1.2;
    const outletArea = centrifugalFan.outlet_area;
    // eslint-disable-next-line no-restricted-properties
    const velocityPressure = (density * Math.pow(c.flow / 1000 / outletArea, 2)) / 2;
    const totalPressure = velocityPressure + press.value;
    const totalEfficiency = ((c.flow / 1000) * totalPressure) / (10 * (c.value / 1000));
    sofar.push({
      fanSpeedRpm: fanspeed.value,
      staticPressurePa: press.value,
      airflowls: c.flow,
      powerW: c.value,
      totalEfficiency: totalEfficiency,
    });
    return sofar;
  }, []);

  const minPressureCurve = Interpolation.splineCreateFromPoints(
    pressureCurves[keys[keys.length - 1]].map((t) => ({ x: t.flow, y: t.value }))
  );
  const maxPressureCurve = Interpolation.splineCreateFromPoints(
    pressureCurves[keys[0]].map((t) => ({ x: t.flow, y: t.value }))
  );

  return kwSteps.reduce<Array<Curve>>((curves, kw) => {
    const points = dataPoints.map((dp) => calculateAirFlowAndPressureForPowerInKW(dp, kw));

    const powerSpline = Interpolation.splineCreateFromPoints(
      points.map((p) => ({
        x: p.x,
        y: p.y,
      }))
    );

    const intersectionWithMinCurve = Interpolation.findSplineSplineInterSection(powerSpline, minPressureCurve);
    const interSectionWithMaxCurve = Interpolation.findSplineSplineInterSection(powerSpline, maxPressureCurve);

    const pointsWithoutMin = intersectionWithMinCurve
      ? [...points.filter((p) => p.x <= intersectionWithMinCurve.x), intersectionWithMinCurve]
      : points;
    const cleanedPoints = interSectionWithMaxCurve
      ? [...pointsWithoutMin.filter((p) => p.x >= interSectionWithMaxCurve.x), interSectionWithMaxCurve]
      : pointsWithoutMin;

    if (
      cleanedPoints.every(
        (c) => pressureCurves[keys[keys.length - 1]].find((p) => p.flow < c.x && p.value < c.y) === undefined
      )
    ) {
      return curves;
    }

    if (cleanedPoints.every((c) => pressureCurves[keys[0]].find((p) => p.flow > c.x && p.value > c.y) === undefined)) {
      return curves;
    }

    const workMin = Math.min(...cleanedPoints.map((p) => p.x));
    const workMax = Math.max(...cleanedPoints.map((p) => p.x));

    curves.push({
      id: kw.toString(),
      controlVoltage: 0,
      supplyVoltage: 0,
      controlFrequency:0,
      unitX: Units.LiterPerSecond,
      label: kw.toString(),
      unitY: Units.Pascal,
      workMin: workMin,
      workMax: workMax,
      spline: Interpolation.splineCreateFromPoints(
        cleanedPoints.map((p) => ({
          x: p.x,
          y: p.y,
        }))
      ),
    });
    return curves;
  }, []);
}

function findMotor(
  motors: QP.CentrifugalMotorTable,
  fan: QP.CentrifugalFan,
  requiredPowerKw: number,
  standardFan: boolean
): MotorResult | undefined {
  const minPowerRequired = ((fan.transmission_loss_percentage + 100) / 100) * requiredPowerKw;
  const motor = standardFan
    ? motors.find((m) => m.power === requiredPowerKw)
    : motors
        .filter((m) => m.power >= fan.min_motor_power && m.power <= fan.max_motor_power)
        .sort((a, b) => a.power - b.power)
        .find((m) => m.power > minPowerRequired);

  if (!motor) {
    return undefined;
  }

  return {
    power: Amount.create(motor.power, Units.KiloWatt),
    current: Amount.create(motor.current, Units.Ampere),
    startingCurrent: Amount.create(motor.starting_current, Units.Ampere),

    weight: motor.base_weight,
    efficiency: Amount.create(motor.efficiency, Units.Percent),
    performanceFactorCosPhi: Amount.create(motor.performance_factor_cos_phi, Units.One),
    efficiencyClass: motor.efficiency_class,
    pole: Amount.create(motor.pole, Units.One),
  };
}

function calculateTotalPressure(fan: FanAirResult, density: number, outletAreaM2: number): number | undefined {
  const { airFlow, externalPressure } = fan;
  if (!airFlow || !externalPressure) {
    return undefined;
  }

  const airflowCmps = Amount.valueAs(Units.CubicMeterPerSecond, airFlow);
  const pressurePa = Amount.valueAs(Units.Pascal, externalPressure);

 return  pressurePa+Math.pow((airflowCmps/(outletAreaM2)), 2)* (density / 2)

  
}

interface DataPoint {
  readonly fanSpeedRpm: number;
  readonly staticPressurePa: number;
  readonly airflowls: number;
  readonly powerW: number;
  readonly totalEfficiency: number;
}

//https://www.axair-fans.co.uk/news/applications/understanding-basic-fan-laws/
function calculateAirFlowAndPressureForPowerInKW(dataPoint: DataPoint, targetPowerKw: number): Interpolation.Vec2 {
  const dataPointAirflowCmps = dataPoint.airflowls / 1000;

  const newPointRPM = dataPoint.fanSpeedRpm * Math.pow(targetPowerKw / (dataPoint.powerW / 1000), 1 / 3);
  const newPointStaticPressurePa = dataPoint.staticPressurePa * Math.pow(newPointRPM / dataPoint.fanSpeedRpm, 2);
  const newPointAirflowLs = dataPointAirflowCmps * (newPointRPM / dataPoint.fanSpeedRpm) * 1000;

  return {
    x: newPointAirflowLs,
    y: newPointStaticPressurePa,
  };
}
