import type { ComponentResult } from "shared-lib/search";
import type { PropertyValueSet } from "@promaster-sdk/property";
import type * as PU from "shared-lib/product-utils";
import type { Item, SelectedVariant } from "./types";

export type Action =
  | SetSelectedPath
  | SetSelectedVariant
  | AddVariant
  | RemoveVariant
  | UpdateVariant
  | UpdateAllCalcParams
  | SaveCalculation
  | ClearSavedCalculations
  | RemoveCalculation
  | CalculationExists
  | UpdateProjectName
  | UpdateProjectComments
  | UpdateAccessoryQuantity
  | Reset
  | DisplayPrint
  | AddPersistedVariant
  | RemovePersistedVariant
  | SearchNotFound
  | ClearAddedSearchResultIds;

export interface SetSelectedPath {
  readonly type: "frico-specification-text-epim/SET_SELECTED_PATH";
  readonly payload: {
    readonly lvl1: string | undefined;
    readonly lvl2: string | undefined;
    readonly productId: string | undefined;
    readonly ecomProductId: string | undefined;
    readonly selectedVariant: SelectedVariant | undefined;
  };
}

export interface SearchNotFound {
  readonly type: "frico-specification-text-epim/SEARCH_NOT_FOUND";
}
export function searchNotFound(): SearchNotFound {
  return {
    type: "frico-specification-text-epim/SEARCH_NOT_FOUND",
  };
}

export function setSelectedPath(
  lvl1: string | undefined,
  lvl2: string | undefined,
  productId: string | undefined,
  selectedVariant: SelectedVariant | undefined,
  ecomProductId: string | undefined
): SetSelectedPath {
  return {
    type: "frico-specification-text-epim/SET_SELECTED_PATH",
    payload: {
      lvl1: lvl1,
      lvl2: lvl2,
      productId: productId,
      ecomProductId: ecomProductId,
      selectedVariant: selectedVariant,
    },
  };
}

export interface SetSelectedVariant {
  readonly type: "frico-specification-text-epim/SET_SELECTED_VARIANT";
  readonly payload: string;
}

export function setSelectedVariant(m3ItemNo: string): SetSelectedVariant {
  return {
    type: "frico-specification-text-epim/SET_SELECTED_VARIANT",
    payload: m3ItemNo,
  };
}
export interface AddVariant {
  readonly type: "frico-specification-text-epim/ADD_VARIANT";
  readonly payload: Item;
}

export function addVariant(
  itemName: string,
  m3ItemNo: string,
  variantNo: string,
  quantity: number,
  sortNo: number,
  productId: string,
  properties: PropertyValueSet.PropertyValueSet
): AddVariant {
  return {
    type: "frico-specification-text-epim/ADD_VARIANT",
    payload: {
      itemName: itemName,
      m3ItemNo: m3ItemNo,
      variantNo: variantNo,
      quantity: quantity,
      sortNo: sortNo,
      productId: productId,
      properties: properties,
    },
  };
}

export interface AddPersistedVariant {
  readonly type: "frico-specification-text-epim/ADD_PERSISTED_VARIANT";
  readonly payload: {
    readonly searchResultId: string;
    readonly item: Item;
    readonly searchSelections: PU.Selections;
  };
}

export function addPersistedVariant(
  searchResultId: string,
  item: Item,
  searchSelections: PU.Selections
): AddPersistedVariant {
  return {
    type: "frico-specification-text-epim/ADD_PERSISTED_VARIANT",
    payload: {
      searchResultId,
      item,
      searchSelections,
    },
  };
}

export interface RemovePersistedVariant {
  readonly type: "frico-specification-text-epim/REMOVE_PERSISTED_VARIANT";
  readonly payload: string;
}

export function removePersistedVariant(searchResultId: string): RemovePersistedVariant {
  return {
    type: "frico-specification-text-epim/REMOVE_PERSISTED_VARIANT",
    payload: searchResultId,
  };
}

export interface RemoveVariant {
  readonly type: "frico-specification-text-epim/REMOVE_VARIANT";
  readonly payload: {
    readonly m3ItemNo: string;
    readonly accessoriesToRemove: ReadonlyArray<string>;
  };
}

export function removeVariant(m3ItemNo: string, accessoriesToRemove: ReadonlyArray<string>): RemoveVariant {
  return {
    type: "frico-specification-text-epim/REMOVE_VARIANT",
    payload: {
      m3ItemNo: m3ItemNo,
      accessoriesToRemove: accessoriesToRemove,
    },
  };
}

export interface UpdateVariant {
  readonly type: "frico-specification-text-epim/UPDATE_VARIANT";
  readonly payload: Item;
}

export function updateVariant(
  itemName: string,
  m3ItemNo: string,
  variantNo: string,
  quantity: number,
  sortNo: number,
  productId: string,
  properties: PropertyValueSet.PropertyValueSet,
  calcParams?: PropertyValueSet.PropertyValueSet
): UpdateVariant {
  return {
    type: "frico-specification-text-epim/UPDATE_VARIANT",
    payload: {
      itemName: itemName,
      m3ItemNo: m3ItemNo,
      variantNo: variantNo,
      quantity: quantity,
      sortNo: sortNo,
      productId: productId,
      properties: properties,
      calcParams: calcParams,
    },
  };
}

export interface UpdateAllCalcParams {
  readonly type: "frico-specification-text-epim/UPDATE_ALL_CALCPARAMS";
  readonly payload: PropertyValueSet.PropertyValueSet;
}

export function updateAllCalcParams(calcParams: PropertyValueSet.PropertyValueSet): UpdateAllCalcParams {
  return {
    type: "frico-specification-text-epim/UPDATE_ALL_CALCPARAMS",
    payload: calcParams,
  };
}

export interface ClearSavedCalculations {
  readonly type: "frico-specification-text-epim/CLEAR_SAVED_CALCULATIONS";
}

export function clearSavedCalculations(): ClearSavedCalculations {
  return {
    type: "frico-specification-text-epim/CLEAR_SAVED_CALCULATIONS",
  };
}

export interface SaveCalculation {
  readonly type: "frico-specification-text-epim/SAVE_CALCULATION";
  readonly payload: ComponentResult;
}

export function saveCalculation(result: ComponentResult): SaveCalculation {
  return {
    type: "frico-specification-text-epim/SAVE_CALCULATION",
    payload: result,
  };
}

export interface RemoveCalculation {
  readonly type: "frico-specification-text-epim/REMOVE_CALCULATION";
  readonly payload: string;
}

export function removeCalculation(id: string): RemoveCalculation {
  return {
    type: "frico-specification-text-epim/REMOVE_CALCULATION",
    payload: id,
  };
}

export interface CalculationExists {
  readonly type: "frico-specification-text-epim/CALCULATION_EXISTS";
}

export function calculationExists(): CalculationExists {
  return {
    type: "frico-specification-text-epim/CALCULATION_EXISTS",
  };
}

export interface UpdateProjectName {
  readonly type: "frico-specification-text-epim/UPDATE_PROJECT_NAME";
  readonly payload: string;
}

export function updateProjectName(value: string): UpdateProjectName {
  return {
    type: "frico-specification-text-epim/UPDATE_PROJECT_NAME",
    payload: value,
  };
}

export interface UpdateProjectComments {
  readonly type: "frico-specification-text-epim/UPDATE_PROJECT_COMMENTS";
  readonly payload: string;
}

export function updateProjectComments(value: string): UpdateProjectComments {
  return {
    type: "frico-specification-text-epim/UPDATE_PROJECT_COMMENTS",
    payload: value,
  };
}

export interface DisplayPrint {
  readonly type: "frico-specification-text-epim/DISPLAY_PRINT";
  readonly payload: boolean;
}

export function displayPrint(payload: boolean): DisplayPrint {
  return {
    type: "frico-specification-text-epim/DISPLAY_PRINT",
    payload,
  };
}

export interface Reset {
  readonly type: "frico-specification-text-epim/RESET";
}

export function reset(): Reset {
  return {
    type: "frico-specification-text-epim/RESET",
  };
}

export interface UpdateAccessoryQuantity {
  readonly type: "frico-specification-text-epim/UPDATE_ACCESSORY_QUANTITY";
  readonly payload: {
    readonly productId: string;
    readonly itemNumber: string;
    readonly quantity: number;
    readonly category: number | undefined;
  };
}

export function updateAccessoryQuantity(
  productId: string,
  itemNumber: string,
  quantity: number,
  category: number | undefined
): UpdateAccessoryQuantity {
  return {
    type: "frico-specification-text-epim/UPDATE_ACCESSORY_QUANTITY",
    payload: {
      productId: productId,
      itemNumber: itemNumber,
      quantity: quantity,
      category: category,
    },
  };
}

export interface ClearAddedSearchResultIds {
  readonly type: "frico-specification-text-epim/CLEAR_ADDED_SEARCH_RESULT_IDS";
  readonly payload: {
    readonly setPersistedDataToCurrent: boolean;
  };
}

export function clearAddedSearchResultIds(setPersistedDataToCurrent: boolean): ClearAddedSearchResultIds {
  return {
    type: "frico-specification-text-epim/CLEAR_ADDED_SEARCH_RESULT_IDS",
    payload: { setPersistedDataToCurrent },
  };
}
