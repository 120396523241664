import type { Unit } from "uom";
import type { SoundFilter, GroupValues, OctaveBandsType } from "shared-lib/user-settings";
import type { AnyQuantity } from "shared-lib/uom";

export type Action =
  | Reset
  | SetShowAdvanced
  | SetShowDiagrams
  | SetSoundFilter
  | SetOctaveBandsType
  | SetSoundAbsorptionArea
  | SetGroupClosed
  | SetFieldUnit
  | ClearFieldUnit
  | SetFieldUnitConfig
  | SetShowBoxFanRpm
  | SetShowBoxFanEfficiency
  | SetShowBoxFanMotorPower
  | SetShowStandardFanAlternatives
  | SetSoundDistance;

export interface Reset {
  readonly type: "user-settings/RESET";
}

export function reset(): Reset {
  return {
    type: "user-settings/RESET",
  };
}

export interface SetShowAdvanced {
  readonly type: "user-settings/SET_SHOW_ADVANCED";
  readonly payload: boolean;
}

export function setShowAdvanced(showAdvanced: boolean): SetShowAdvanced {
  return {
    type: "user-settings/SET_SHOW_ADVANCED",
    payload: showAdvanced,
  };
}

export interface SetShowDiagrams {
  readonly type: "user-settings/SET_SHOW_DIAGRAMS";
  readonly payload: boolean;
}

export function setShowDiagrams(showDiagrams: boolean): SetShowDiagrams {
  return {
    type: "user-settings/SET_SHOW_DIAGRAMS",
    payload: showDiagrams,
  };
}

export interface SetSoundFilter {
  readonly type: "user-settings/SET_SOUND_FILTER";
  readonly payload: SoundFilter;
}

export function setSoundFilter(soundFilter: SoundFilter): SetSoundFilter {
  return {
    type: "user-settings/SET_SOUND_FILTER",
    payload: soundFilter,
  };
}

export interface SetOctaveBandsType {
  readonly type: "user-settings/SET_OCTAVE_BANDS_TYPE";
  readonly payload: OctaveBandsType;
}

export function setOctaveBandsType(octaveBandsType: OctaveBandsType): SetOctaveBandsType {
  return {
    type: "user-settings/SET_OCTAVE_BANDS_TYPE",
    payload: octaveBandsType,
  };
}

export interface SetSoundDistance {
  readonly type: "user-settings/SET_SOUND_DISTANCE";
  readonly payload: string;
}

export function setSoundDistance(distanceM: string): SetSoundDistance {
  return {
    type: "user-settings/SET_SOUND_DISTANCE",
    payload: distanceM,
  };
}

export interface SetSoundAbsorptionArea {
  readonly type: "user-settings/SET_SOUND_ABSORPTION_AREA";
  readonly payload: string;
}

export function setSoundAbsorptionArea(area: string): SetSoundAbsorptionArea {
  return {
    type: "user-settings/SET_SOUND_ABSORPTION_AREA",
    payload: area,
  };
}

export interface SetGroupClosed {
  readonly type: "user-settings/SET_GROUP_CLOSED";
  readonly payload: {
    readonly group: string;
    readonly closed: boolean;
  };
}

export function setGroupClosed(group: string, closed: boolean): SetGroupClosed {
  return {
    type: "user-settings/SET_GROUP_CLOSED",
    payload: {
      group,
      closed,
    },
  };
}

export interface SetFieldUnit {
  readonly type: "user-settings/SET_FIELD_UNIT";
  readonly payload: {
    readonly fieldName: string;
    readonly unit: Unit.Unit<AnyQuantity>;
  };
}

export function setFieldUnit(fieldName: string, unit: Unit.Unit<AnyQuantity>): SetFieldUnit {
  return {
    type: "user-settings/SET_FIELD_UNIT",
    payload: {
      fieldName,
      unit,
    },
  };
}

export interface ClearFieldUnit {
  readonly type: "user-settings/CLEAR_FIELD_UNIT";
  readonly payload: string;
}

export function clearFieldUnit(fieldName: string): ClearFieldUnit {
  return {
    type: "user-settings/CLEAR_FIELD_UNIT",
    payload: fieldName,
  };
}

export interface SetFieldUnitConfig {
  readonly type: "user-settings/SET_FIELD_UNIT_CONFIG";
  readonly payload: {
    readonly config: GroupValues;
  };
}

export function setFieldUnitConfig(config: GroupValues): SetFieldUnitConfig {
  return {
    type: "user-settings/SET_FIELD_UNIT_CONFIG",
    payload: {
      config,
    },
  };
}

export interface SetShowBoxFanRpm {
  readonly type: "user-settings/SET_SHOW_BOXFAN_RPM";
  readonly payload: boolean;
}

export function setShowBoxFanRpm(showBoxFanRpm: boolean): SetShowBoxFanRpm {
  return {
    type: "user-settings/SET_SHOW_BOXFAN_RPM",
    payload: showBoxFanRpm,
  };
}

export interface SetShowBoxFanEfficiency {
  readonly type: "user-settings/SET_SHOW_BOXFAN_EFFICIENCY";
  readonly payload: boolean;
}

export function setShowBoxFanEfficiency(showBoxFanEfficiency: boolean): SetShowBoxFanEfficiency {
  return {
    type: "user-settings/SET_SHOW_BOXFAN_EFFICIENCY",
    payload: showBoxFanEfficiency,
  };
}

export interface SetShowBoxFanMotorPower {
  readonly type: "user-settings/SET_SHOW_BOXFAN_MOTOR_POWER";
  readonly payload: boolean;
}

export function setShowBoxFanMotorPower(showBoxFanMotorPower: boolean): SetShowBoxFanMotorPower {
  return {
    type: "user-settings/SET_SHOW_BOXFAN_MOTOR_POWER",
    payload: showBoxFanMotorPower,
  };
}

export interface SetShowStandardFanAlternatives {
  readonly type: "user-settings/SET_SHOW_STANDARD_FAN_ALTERNATIVES";
  readonly payload: boolean;
}

export function setShowStandardFanAlternatives(showStandardFanAlternatives: boolean): SetShowStandardFanAlternatives {
  return {
    type: "user-settings/SET_SHOW_STANDARD_FAN_ALTERNATIVES",
    payload: showStandardFanAlternatives,
  };
}
