import * as AC from "shared-lib/abstract-chart";
import type { AnyQuantity } from "shared-lib/uom";
import * as AI from "abstract-image";
import type { Unit } from "uom";
import { Amount, UnitFormat } from "uom";
import type { Quantity } from "uom-units";
import { UnitsFormat } from "uom-units";
import type * as Style from "shared-lib/style";
import * as Interpolation from "shared-lib/interpolation";
import * as R from "ramda";
import * as Texts from "shared-lib/language-texts";
import * as InterpolationAmca from "../fan-calculation-amca/interpolation";
import * as FanAir from "../shared/fan-air";
import type { Curve, SpeedControl, FanAirResult, ValidSystemCurvePoint } from "../result-items-types";

const numPoints = 100;

export interface FanChartsProps {
  readonly fan: FanAirResult;
  readonly flowUnit: Unit.Unit<Quantity.VolumeFlow>;
  readonly pressureUnit: Unit.Unit<Quantity.Pressure>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly style: Style.DiagramStyle;
  readonly hideTexts?: boolean;
}

export interface FanCharts {
  readonly pressure: AC.Chart;
}

export function generateSupplyExtractCharts({
  supplyFan,
  extractFan,
  flowUnit,
  pressureUnit,
  translate,
  showLineLabels,
  style,
  hideTexts,
}: Omit<FanChartsProps, "fan"> & {
  readonly supplyFan: FanAirResult;
  readonly extractFan: FanAirResult;
}): {
  readonly supply: FanCharts;
  readonly extract: FanCharts;
} {
  const [supplyFanMerged, extractFanMerged] = FanAir.mergeMinMaxPressureAndAirFlow([supplyFan, extractFan]);
  const supply = generateCharts({
    fan: supplyFanMerged,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels,
    style,
    hideTexts,
  });
  const extract = generateCharts({
    fan: extractFanMerged,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels,
    style,
    hideTexts,
  });
  return { supply, extract };
}

export function generateCharts({
  fan,
  flowUnit,
  pressureUnit,
  translate,
  showLineLabels,
  style,
  hideTexts,
}: FanChartsProps): FanCharts {
  const wpIsValid = fan.airFlow !== undefined && fan.desiredPointIsOutsideValidArea !== true;
  const props = {
    speedControl: fan.speedControl,
    showSystemLine: false,
    showTexts: !hideTexts,
    desiredX: undefined,
    desiredY: undefined,
    minX: fan.minAirFlow,
    maxX: fan.maxAirFlow,
    minY: fan.minPressure,
    maxY: fan.maxPressure,
    wpIsValid: wpIsValid,
    minValidPoint: fan.systemCurveMinValidPoint,
    maxValidPoint: fan.systemCurveMaxValidPoint,
    workX: fan.airFlow,
    unitX: flowUnit,
    translate: translate,
    showLineLabels: showLineLabels,
    style: style,
  };
  const pressure = generateChart({
    ...props,
    showSystemLine: true,
    feiCurves: fan.feiCurves,
    desiredX: fan.desiredAirFlow,
    desiredY: fan.desiredExternalPressure,
    curves: fan.adjustedPressureCurves,
    originalCurves: fan.pressureCurves,
    workY: fan.externalPressure,
    unitY: pressureUnit,
    style: style,
    splineGetPoint: fan.diagramDrawMethod === "AMCA" ? InterpolationAmca.splineGetPoint : Interpolation.splineGetPoint,
    splineGetPoints:
      fan.diagramDrawMethod === "AMCA" ? InterpolationAmca.splineGetPoints : Interpolation.splineGetPoints,
  });
  return {
    pressure,
  };
}

type SplineGetPoint = (x: number, spline: Interpolation.Spline) => number | undefined;
type SplineGetPoints = (
  numPoints: number,
  spline: Interpolation.Spline
) => ReadonlyArray<{ readonly x: number; readonly y: number }>;

interface FanChartProps {
  readonly speedControl: SpeedControl;
  readonly showTexts: boolean;
  readonly showSystemLine: boolean;
  readonly minX?: Amount.Amount<AnyQuantity>;
  readonly maxX?: Amount.Amount<AnyQuantity>;
  readonly minY?: Amount.Amount<AnyQuantity>;
  readonly maxY?: Amount.Amount<AnyQuantity>;
  readonly desiredX?: Amount.Amount<AnyQuantity>;
  readonly desiredY?: Amount.Amount<AnyQuantity>;
  readonly wpIsValid: boolean;
  readonly minValidPoint?: ValidSystemCurvePoint;
  readonly maxValidPoint?: ValidSystemCurvePoint;
  readonly workX?: Amount.Amount<AnyQuantity>;
  readonly workY?: Amount.Amount<AnyQuantity>;
  readonly originalCurves?: ReadonlyArray<Curve>;
  readonly curves: ReadonlyArray<Curve>;
  readonly feiCurves: ReadonlyArray<Curve> | undefined;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly style: Style.DiagramStyle;
  readonly splineGetPoints: SplineGetPoints;
  readonly splineGetPoint: SplineGetPoint;
}

function generateChart(props: FanChartProps): AC.Chart {
  const {
    speedControl,
    showTexts,
    showSystemLine,
    minX,
    maxX,
    minY,
    maxY,
    desiredX,
    desiredY,
    wpIsValid,
    minValidPoint,
    maxValidPoint,
    workX,
    workY,
    curves,
    originalCurves = [],
    unitX,
    unitY,
    translate,
    showLineLabels,
    style,
    splineGetPoint,
    splineGetPoints,
    feiCurves,
  } = props;
  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLinearAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const yAxis = createLinearAxisY(curves, minY, maxY, unitY, translate, splineGetPoints);
  const components = xAxis
    ? [
        ...generateCurves(
          speedControl,
          style.originalLine,
          unitX,
          unitY,
          originalCurves,
          false,
          style.invalidColor,
          splineGetPoints
        ),
        ...generateCurves(
          speedControl,
          style.actualLine,
          unitX,
          unitY,
          curves,
          showLineLabels,
          style.invalidColor,
          splineGetPoints
        ),
        ...generateCurves(
          speedControl,
          style.feiLine,
          unitX,
          unitY,
          feiCurves ?? [],
          showLineLabels,
          style.workPointColor,
          splineGetPoints
        ),
        ...generateSystemLine(
          showSystemLine,
          xAxis,
          yAxis,
          wpIsValid,
          minValidPoint,
          maxValidPoint,
          desiredX,
          desiredY,
          unitX,
          unitY,
          style
        ),
        ...generateArea(
          speedControl,
          style.originalAreaColor,
          unitX,
          unitY,
          originalCurves,
          splineGetPoint,
          splineGetPoints
        ),
        ...generateArea(speedControl, style.actualAreaColor, unitX, unitY, curves, splineGetPoint, splineGetPoints),
        ...generateFEIArea(speedControl, style.feiArea, unitX, unitY, feiCurves ?? [], splineGetPoint, splineGetPoints),
        ...generateInvalidAreas(
          speedControl,
          style.invalidAreaColor,
          unitX,
          unitY,
          curves,
          splineGetPoint,
          splineGetPoints
        ),
        ...generatePoint(
          false,
          wpIsValid ? style.workPointColor : style.invalidColor,
          style.workingPointLabelColor,
          false,
          desiredX,
          desiredY,
          unitX,
          unitY
        ),
        ...generatePoint(
          showTexts,
          style.workPointColor,
          style.workingPointLabelColor,
          true,
          workX,
          workY,
          unitX,
          unitY
        ),
      ]
    : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: style.backgroundColor,
    gridColor: style.grid.lineColor,
    gridLabelColor: style.grid.labelColor,
    gridThickness: style.grid.lineThickness,
    subGridColor: style.subGrid.lineColor,
    subGridThickness: style.subGrid.lineThickness,
  });

  return chart;
}

function generateSystemLine(
  showSystemLine: boolean,
  xAxis: AC.Axis,
  yAxis: AC.Axis,
  valid: boolean,
  minValid: ValidSystemCurvePoint | undefined,
  maxValid: ValidSystemCurvePoint | undefined,
  workX: Amount.Amount<AnyQuantity> | undefined,
  workY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  style: Style.DiagramStyle
): ReadonlyArray<AC.ChartComponent> {
  if (!workX || !workY || !showSystemLine) {
    return [];
  }

  const xWork = Amount.valueAs(unitX, workX);
  const yWork = Amount.valueAs(unitY, workY);
  const k = yWork / (xWork * xWork);

  const diagramP0 = AI.createPoint(xAxis.min, k * xAxis.min ** 2);
  const diagramP1Xmax = AI.createPoint(xAxis.max, k * xAxis.max ** 2);
  const diagramP1Ymax = AI.createPoint(Math.sqrt(yAxis.max / k), yAxis.max);
  const diagramP1 = diagramP1Xmax.y < diagramP1Ymax.y ? diagramP1Xmax : diagramP1Ymax;

  const segments: Array<[AI.Point, AI.Point, AI.Color]> = [];
  if (valid) {
    segments.push([diagramP0, diagramP1, style.systemLine.lineColor]);
  } else if (!valid && !minValid && !maxValid) {
    segments.push([diagramP0, diagramP1, style.invalidColor]);
  } else {
    const p0 = minValid
      ? AI.createPoint(Amount.valueAs(unitX, minValid.airFlow), Amount.valueAs(unitY, minValid.pressure))
      : diagramP0;
    const p1 = maxValid
      ? AI.createPoint(Amount.valueAs(unitX, maxValid.airFlow), Amount.valueAs(unitY, maxValid.pressure))
      : diagramP1;
    const segs: Array<[AI.Point, AI.Point, AI.Color]> = [
      [diagramP0, p0, style.invalidColor],
      [p0, p1, style.systemLine.lineColor],
      [p1, diagramP1, style.invalidColor],
    ];
    segments.push(
      ...segs.filter(
        ([point0, point1]) => Math.abs(point0.x - point1.x) > 0.00001 || Math.abs(point0.y - point1.y) > 0.00001
      )
    );
  }

  const totalLen = segments.reduce((sofar, [p0, p1]) => {
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    return sofar + dist;
  }, 0);

  const components: Array<AC.ChartComponent> = [];
  for (const [p0, p1, color] of segments) {
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;
    const len = Math.sqrt(dx * dx + dy * dy);
    const segPoints = Math.min(numPoints, Math.max(2, (len / totalLen) * numPoints));
    if (!Number.isFinite(segPoints)) {
      continue;
    }

    const points: Array<AI.Point> = [p0];
    const step = (p1.x - p0.x) / segPoints;
    for (let i = 1; i < segPoints; i++) {
      const x = p0.x + i * step;
      const y = k * x ** 2;
      const p = AI.createPoint(x, y);
      if (p.y < p0.y || p.y > p1.y) {
        continue;
      }
      points.push(p);
    }
    points.push(p1);

    components.push(
      AC.createChartLine({
        points: points,
        thickness: style.systemLine.lineThickness,
        color: color,
      })
    );
  }

  return components;
}

function generatePoint(
  showLabels: boolean,
  color: AI.Color,
  labelColor: AI.Color,
  filled: boolean,
  pX: Amount.Amount<AnyQuantity> | undefined,
  pY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>
): ReadonlyArray<AC.ChartComponent> {
  if (!pX || !pY) {
    return [];
  }
  const x = Amount.valueAs(unitX, pX);
  const y = Amount.valueAs(unitY, pY);
  const axisLines = showLabels ? "linesAndText" : "none";
  if (filled) {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: color,
        labelColor: labelColor,
        position: AI.createPoint(x, y),
        size: AI.createSize(8, 8),
        axisLines: axisLines,
      }),
    ];
  } else {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: AI.transparent,
        stroke: color,
        labelColor: labelColor,
        strokeThickness: 2,
        position: AI.createPoint(x, y),
        size: AI.createSize(12, 12),
        axisLines: axisLines,
      }),
    ];
  }
}

function createLinearAxisY(
  curves: ReadonlyArray<Curve>,
  minY: Amount.Amount<AnyQuantity> | undefined,
  maxY: Amount.Amount<AnyQuantity> | undefined,
  unitY: Unit.Unit<AnyQuantity>,
  translate: Texts.TranslateFunction,
  splineGetPoints: SplineGetPoints
): AC.Axis {
  let min = 0;
  let max = 0;
  if (minY && maxY) {
    min = 0; // Amount.valueAs(unitY, minY) * 0.9;
    max = Amount.valueAs(unitY, maxY) * 1.1;
  } else {
    const ys = R.unnest<Amount.Amount<AnyQuantity>>(
      curves.map((c) => splineGetPoints(8, c.spline).map((v) => Amount.create(v.y, c.unitY)))
    ).map((y) => Amount.valueAs(unitY, y));
    min = 0; //Math.min(...ys) * 0.9;
    max = Math.max(...ys) * 1.1;
  }
  const unitLabel = translate(Texts.unitLabel(unitY), UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label);
  return AC.createLinearAxis(min, max, unitLabel);
}

function generateCurves(
  speedControl: SpeedControl,
  style: Style.DiagramLineStyle,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  labels: boolean,
  invalidColor: AI.Color,
  splineGetPoints: SplineGetPoints
): ReadonlyArray<AC.ChartComponent> {
  if (curves.length === 0) {
    return [];
  }
  if (speedControl === "None") {
    return generateCurve(unitX, unitY, curves[curves.length - 1], undefined, style, invalidColor, splineGetPoints);
  } else if (speedControl === "Transformer") {
    const showControl = labels && R.uniq(curves.map((c) => c.controlVoltage)).length > 1;
    const showSupply = labels && R.uniq(curves.map((c) => c.supplyVoltage)).length > 1;
    return R.unnest<AC.ChartComponent>(
      curves.map((curve) =>
        generateCurve(
          unitX,
          unitY,
          curve,
          showControl
            ? curve.controlVoltage.toString() + "V"
            : showSupply
            ? curve.supplyVoltage.toString() + "V"
            : undefined,
          style,
          invalidColor,
          splineGetPoints
        )
      )
    );
  } else if (speedControl === "Frequency converter") {
    const showControl = labels;
    return R.unnest<AC.ChartComponent>(
      curves.map((curve) =>
        generateCurve(
          unitX,
          unitY,
          curve,
          showControl
            ? curve.controlFrequency.toString() + "Hz" : undefined,
          style,
          invalidColor,
          splineGetPoints
        )
      )
    );
  } else {
    return [];
  }
}

function generateCurve(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  label: string | undefined,
  style: Style.DiagramLineStyle,
  invalidColor: AI.Color,
  splineGetPoints: SplineGetPoints
): ReadonlyArray<AC.ChartComponent> {
  const workPoints = generateCurvePoints(unitX, unitY, curve, splineGetPoints, curve.workMin, curve.workMax);
  const curves = [
    AC.createChartLine({
      points: workPoints,
      color: style.lineColor,
      textColor: style.labelColor,
      thickness: style.lineThickness,
      text: label,
      textAnchorPosition: "bottomLeft",
      textPosition: "start",
    }),
  ];
  if (curve.workMin > curve.spline.xMin) {
    const lowInvalidPoints = [
      ...generateCurvePoints(unitX, unitY, curve, splineGetPoints, curve.spline.xMin, curve.workMin),
      workPoints[0],
    ];
    curves.push(
      AC.createChartLine({
        points: lowInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  if (curve.workMax < curve.spline.xMax) {
    const highInvalidPoints = [
      workPoints[workPoints.length - 1],
      ...generateCurvePoints(unitX, unitY, curve, splineGetPoints, curve.workMax, curve.spline.xMax),
    ];
    curves.push(
      AC.createChartLine({
        points: highInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  return curves;
}

function generateArea(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  splineGetPoint: SplineGetPoint,
  splineGetPoints: SplineGetPoints
): ReadonlyArray<AC.ChartArea> {
  if ((speedControl !== "Stepless" && speedControl !== "Frequency converter") || curves.length === 0) {
    return [];
  }
  const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, curves[0], splineGetPoints));
  const topPoints = generateCurvePoints(unitX, unitY, curves[curves.length - 1], splineGetPoints);
  const rightPoints = R.reverse(
    curves.map((c) => createPoint(unitX, unitY, c, c.spline.xMax, splineGetPoint(c.spline.xMax, c.spline) ?? 0))
  );
  const leftPoints = curves.map((c) =>
    createPoint(unitX, unitY, c, c.spline.xMin, splineGetPoint(c.spline.xMin, c.spline) ?? 0)
  );
  const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
  return [
    AC.createChartArea({
      points: points,
      color: color,
      fill: color,
    }),
  ];
}

function generateInvalidAreas(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  splineGetPoint: SplineGetPoint,
  splineGetPoints: SplineGetPoints
): ReadonlyArray<AC.ChartArea> {
  if ((speedControl !== "Stepless" && speedControl !== "Frequency converter") || curves.length === 0) {
    return [];
  }
  const areas: Array<AC.ChartArea> = [];

  const lowCurve = curves[0];
  const highCurve = curves[curves.length - 1];
  if (lowCurve.workMin > lowCurve.spline.xMin || highCurve.workMin > highCurve.spline.xMin) {
    const bottomPoints = R.reverse(
      generateCurvePoints(unitX, unitY, lowCurve, splineGetPoints, lowCurve.spline.xMin, lowCurve.workMin)
    );
    const topPoints = generateCurvePoints(
      unitX,
      unitY,
      highCurve,
      splineGetPoints,
      highCurve.spline.xMin,
      highCurve.workMin
    );
    const rightPoints = R.reverse(
      curves.map((c) => createPoint(unitX, unitY, c, c.workMin, splineGetPoint(c.workMin, c.spline) ?? 0))
    );
    const leftPoints = curves.map((c) =>
      createPoint(unitX, unitY, c, c.spline.xMin, splineGetPoint(c.spline.xMin, c.spline) ?? 0)
    );
    const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }
  if (lowCurve.workMax < lowCurve.spline.xMax || highCurve.workMax > highCurve.spline.xMax) {
    const bottomPoints = R.reverse(
      generateCurvePoints(unitX, unitY, lowCurve, splineGetPoints, lowCurve.workMax, lowCurve.spline.xMax)
    );
    const topPoints = generateCurvePoints(
      unitX,
      unitY,
      highCurve,
      splineGetPoints,
      highCurve.workMax,
      highCurve.spline.xMax
    );
    const leftPoints = curves.map((c) =>
      createPoint(unitX, unitY, c, c.workMax, splineGetPoint(c.workMax, c.spline) ?? 0)
    );
    const rightPoints = R.reverse(
      curves.map((c) => createPoint(unitX, unitY, c, c.spline.xMax, splineGetPoint(c.spline.xMax, c.spline) ?? 0))
    );
    const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }
  return areas;
}

function generateFEIArea(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  _splineGetPoint: SplineGetPoint,
  splineGetPoints: SplineGetPoints
): ReadonlyArray<AC.ChartArea> {
  if ((speedControl !== "Stepless" && speedControl !== "Frequency converter") || curves.length === 0) {
    return [];
  }
  const areas: Array<AC.ChartArea> = [];

  for (let i = curves.length - 1; i >= 1; i--) {
    const curveAbove = curves[i];
    const curveBelow = curves[i - 1];

    if (curveAbove.workMax === curveAbove.workMin || curveBelow.workMax === curveBelow.workMin) {
      continue;
    }

    const bottomPoints = R.reverse(
      generateCurvePoints(unitX, unitY, curveBelow, splineGetPoints, curveBelow.workMin, curveBelow.workMax)
    );
    const topPoints = generateCurvePoints(
      unitX,
      unitY,
      curveAbove,
      splineGetPoints,
      curveAbove.workMin,
      curveAbove.workMax
    );

    const points = [...topPoints, ...bottomPoints];
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }

  return areas;
}

function generateCurvePoints(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  splineGetPoints: SplineGetPoints,
  xMin: number = curve.spline.xMin,
  xMax: number = curve.spline.xMax
): ReadonlyArray<AI.Point> {
  return splineGetPoints(numPoints, curve.spline)
    .filter((p) => p.x >= xMin && p.x <= xMax)
    .map((p) => createPoint(unitX, unitY, curve, p.x, p.y));
}

function createPoint(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  x: number,
  y: number
): AI.Point {
  const chartX = Amount.valueAs(unitX, Amount.create(x, curve.unitX));
  const chartY = Amount.valueAs(unitY, Amount.create(y, curve.unitY));
  return AI.createPoint(chartX, chartY);
}
