import type * as React from "react";
import type * as ReduxQuery from "shared-lib/redux-query/core";
import type { MapPropsToQuery, ReduxQueryProp } from "shared-lib/redux-query/react";
import { withReduxQuery } from "shared-lib/redux-query/react";
import type { FuncOrSelf, ComponentDecorator, MapStateToProps } from "react-redux";
import { connect } from "react-redux";
import * as QD from "shared-lib/query-diaq";
import { Spinner } from "client-lib/elements";

export function diaqConnect<
  TAtomicQuery extends ReduxQuery.AnyQuery,
  TAtomicResponse,
  TComposedResponse,
  TStateProps,
  TOwnProps,
  TResponse
>(
  mapPropsToQuery: MapPropsToQuery<TAtomicQuery, TOwnProps, TResponse, TAtomicResponse, TComposedResponse>,
  mapStateToProps: FuncOrSelf<MapStateToProps<TStateProps, TOwnProps>>
): ComponentDecorator<TStateProps, TOwnProps> {
  return (
    component: React.ComponentClass<TStateProps> | React.FunctionComponent<TStateProps>
  ): React.ComponentClass<TOwnProps> => {
    const withQuery = withReduxQuery(component, mapPropsToQuery, Spinner, QD.selectAtomicQuery, undefined, mergeProps);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return connect<TStateProps, any, TOwnProps>(mapStateToProps)(withQuery);
  };
}

function mergeProps(ownProps: object, reduxQueryProp: ReduxQueryProp): object {
  return { ...ownProps, ...reduxQueryProp.response, reduxQueryProp };
}
