import * as React from "react";
import type * as SC from "shared-lib/system-calculator";
import type { GetUnitFunction, GetDecimalsFunction } from "shared-lib/user-settings";
import * as FD from "shared-lib/system-calculator/shared/fan-diagram";
import * as AbstractChart from "shared-lib/abstract-chart";
import * as AbstractImage from "abstract-image";
import type * as Texts from "shared-lib/language-texts";
import * as Style from "shared-lib/style";
import type { FormatNumberFunction } from "shared-lib/utils";

export interface Props {
  readonly results: SC.ResultItemOutputMap;
  readonly getUnit: GetUnitFunction;
  readonly getDecimals: GetDecimalsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly translate: Texts.TranslateFunction;
}

const diagramSize = AbstractImage.createSize(250, 115);

export function FanDiagram(props: Props): React.ReactElement {
  const { results, getUnit, translate, getDecimals } = props;

  const maybeResultItem = results.Fan;
  const resultItem = maybeResultItem && maybeResultItem.type === "OutputMapperSuccess" && maybeResultItem.result;
  const fan = resultItem && resultItem.type === "Fan" && resultItem.value.air;
  if (!fan) {
    return <div />;
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);

  const { pressure } = FD.generateCharts({
    fan,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels: false,
    style: Style.diagramGanymed,
  });

  const pressureWithDecimals: AbstractChart.Chart = {
    ...pressure,
    linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
    linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
    axisLabelPlacement: "compact",
  };

  const abstractImage = AbstractChart.renderChart(pressureWithDecimals, diagramSize, {
    formatNumber: props.formatNumber.format,
  });
  const rendered = AbstractImage.createReactSvg(abstractImage);

  return rendered;
}
