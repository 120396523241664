import type { AnyQuery, AnyCacheState } from "./types";

export type Action = QueuQueries | LoadStarted | LoadCompleted | ClearCache | UpdateCache;

export interface QueuQueries {
  readonly type: "redux-query/QUEUE_QUERIES";
  readonly queries: ReadonlyArray<AnyQuery>;
}

export function queueQueries(queries: ReadonlyArray<AnyQuery>): QueuQueries {
  return {
    type: "redux-query/QUEUE_QUERIES",
    queries,
  };
}

export interface LoadStarted {
  readonly type: "redux-query/LOAD_STARTED";
}

export function loadStarted(): LoadStarted {
  return {
    type: "redux-query/LOAD_STARTED",
  };
}

export interface UpdateCache {
  readonly type: "redux-query/UPDATE_CACHE";
  readonly cache: AnyCacheState;
}

export function updateCache(cache: AnyCacheState): UpdateCache {
  return {
    type: "redux-query/UPDATE_CACHE",
    cache,
  };
}

export interface LoadCompleted {
  readonly type: "redux-query/LOAD_COMPLETED";
  readonly cache: AnyCacheState;
}

export function loadCompleted(cache: AnyCacheState): LoadCompleted {
  return {
    type: "redux-query/LOAD_COMPLETED",
    cache,
  };
}

export interface ClearCache {
  readonly type: "redux-query/CLEAR_CACHE";
  readonly paths: ReadonlyArray<string> | undefined;
}

export function clearCache(paths: ReadonlyArray<string> | undefined): ClearCache {
  return {
    type: "redux-query/CLEAR_CACHE",
    paths,
  };
}
