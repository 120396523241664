import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as Interpolation from "shared-lib/interpolation";
import {
  relativeToMassMixingRatio,
  moistAirDensity,
  standardAirPressure,
  massMixingRatioToRelativeHumidity,
} from "./functions";
import type { ElectricDuctHeater } from "../result-items-types";
import * as Attributes from "./attributes";
import * as Messages from "../messages";
import * as PressureDrop from "./pressure-drop";
import * as Utils from "./utils";
import * as Area from "./area";
import type { HeaterLimits } from "../electric-duct-heater/types";

const source = "ElectricHeater";

export interface ElectricHeaterInput {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly inletAirTemperature: Amount.Amount<Quantity.Temperature>;
  readonly inletAirHumidity: Amount.Amount<Quantity.RelativeHumidity>;

  readonly requestedOutletAirTemperature?: Amount.Amount<Quantity.Temperature>;
  readonly requestedPower?: Amount.Amount<Quantity.Power>;

  readonly attributes: Attributes.Attributes;
  readonly heaterLimits: HeaterLimits | undefined;
}

export type ElectricHeaterCalcMode = "OutletAirTemperature" | "Power" | "MaxPower";

export function calculate(
  calcMode: ElectricHeaterCalcMode,
  input: ElectricHeaterInput
): ElectricDuctHeater | undefined {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    requestedOutletAirTemperature,
    requestedPower,
    attributes,
    heaterLimits,
  } = input;

  const airFlowLps = Amount.valueAs(Units.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(Units.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(Units.PercentHumidity, inletAirHumidity);

  const maxPowerW = getMaxPowerW(attributes);

  const voltageV =
    Attributes.getInt("input-voltage-reheater-NOM", attributes) ??
    Attributes.getInt("input-voltage-heater-NOM", attributes) ??
    Attributes.getInt("input-voltage-NOM", attributes);
  if (!maxPowerW || voltageV === undefined) {
    return undefined;
  }

  const phasesO = Attributes.getInt("input-phases-reheater-NOM", attributes) ?? Attributes.getPhases(attributes) ?? 1;

  const absH = relativeToMassMixingRatio(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0);
  const airMass =
    moistAirDensity(inletAirTemperatureC, standardAirPressure, inletAirHumidityP / 100.0) * (airFlowLps / 1000.0);

  let dT = 0;
  let powerPercent = 0;
  let missingPowerW = 0;
  const messages: Array<Messages.Message> = [];

  if (calcMode === "OutletAirTemperature" && requestedOutletAirTemperature) {
    const requestedOutletAirTemperatureC = Amount.valueAs(Units.Celsius, requestedOutletAirTemperature);
    dT = maxPowerW / (airMass * 1005);
    powerPercent = 100;
    const requestedDT = requestedOutletAirTemperatureC - inletAirTemperatureC;
    if (dT > requestedDT) {
      powerPercent = (100 * requestedDT) / dT;
      dT = requestedDT;
    } else if (dT < 0) {
      dT = 0;
      powerPercent = 0;
    }
    if (dT < requestedDT) {
      missingPowerW = requestedDT * airMass * 1005 - maxPowerW;
      if (dT + 2 < requestedDT) {
        messages.push(Messages.Error_NotEnoughPower(source));
      } else {
        messages.push(Messages.Warning_NotEnoughPower(source));
      }
    }
  } else if (calcMode === "Power" && requestedPower) {
    const requestedPowerW = Amount.valueAs(Units.Watt, requestedPower);
    const powerW = Math.max(0, Math.min(requestedPowerW, maxPowerW));
    dT = powerW / (airMass * 1005);
    powerPercent = (100 * powerW) / maxPowerW;
    if (requestedPowerW > maxPowerW) {
      missingPowerW = requestedPowerW - maxPowerW;
      messages.push(Messages.Warning_NotEnoughPower(source));
    }
  } else if (calcMode === "MaxPower") {
    dT = maxPowerW / (airMass * 1005);
    powerPercent = 100;
  }

  const outletAirTemperatureC = inletAirTemperatureC + dT;
  const outletAirHumidityP =
    massMixingRatioToRelativeHumidity(outletAirTemperatureC, standardAirPressure, absH) * 100.0;

  const currentI = calculateCurrent(maxPowerW, voltageV, phasesO);

  let minAirFlowLps = undefined;
  let maxAirFlowLps = undefined;
  let pressureDropPa = undefined;
  let pressureDropCurve = undefined;
  if (heaterLimits) {
    const areaM2 = Area.getArea(attributes);
    if (areaM2 === undefined) {
      return undefined;
    }
    const airVelocityMps = (0.001 * airFlowLps) / areaM2;
    const airVelocity = Amount.create(airVelocityMps, Units.MeterPerSecond);

    const tempOutletMax = Amount.valueAs(Units.Celsius, heaterLimits.temp_outlet_max);
    if (outletAirTemperatureC > tempOutletMax) {
      messages.push(Messages.Error_OutletTemperatureTooHigh(source, heaterLimits.temp_outlet_max));
    }
    const minVelocityMps = Amount.valueAs(Units.MeterPerSecond, heaterLimits.air_velocity_min);
    minAirFlowLps = 1000 * areaM2 * minVelocityMps;
    if (airVelocityMps < minVelocityMps - 0.00001) {
      messages.push(Messages.Error_FlowTooLow(source, Amount.create(minAirFlowLps, Units.LiterPerSecond)));
    }
    const maxVelocityMps = Amount.valueAs(Units.MeterPerSecond, heaterLimits.air_velocity_max);
    maxAirFlowLps = 1000 * areaM2 * maxVelocityMps;
    if (airVelocityMps > maxVelocityMps + 0.00001) {
      messages.push(Messages.Error_FlowTooHigh(source, Amount.create(maxAirFlowLps, Units.LiterPerSecond)));
    }
    pressureDropPa = PressureDrop.calculateVelocityPressureDrop(airVelocity, heaterLimits);
    pressureDropCurve = PressureDrop.createPowerPressureCurve(minAirFlowLps, maxAirFlowLps, [
      Interpolation.vec2Create(airFlowLps, pressureDropPa),
    ]);
  }

  return {
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,

    maxPower: Amount.create(maxPowerW, Units.Watt, 2),
    minAirFlow: Utils.maybeAmount(minAirFlowLps, Units.LiterPerSecond, 0),
    maxAirFlow: Utils.maybeAmount(maxAirFlowLps, Units.LiterPerSecond, 0),
    maxOutletTemperature: heaterLimits?.temp_outlet_max,
    voltage: Amount.create(voltageV, Units.Volt, 0),
    phases: Amount.create(phasesO, Units.One, 0),

    current: Amount.create(currentI, Units.Ampere, 2),
    powerPercent: Amount.create(powerPercent, Units.Percent, 0),
    missingPower: Amount.create(missingPowerW, Units.Watt, 2),
    outletAirTemperature: Amount.create(outletAirTemperatureC, Units.Celsius, 0),
    outletAirHumidity: Amount.create(outletAirHumidityP, Units.PercentHumidity, 0),
    pressureDrop: Utils.maybeAmount(pressureDropPa, Units.Pascal, 0),

    pressureDropCurve: pressureDropCurve,

    messages: messages,
  };
}

function calculateCurrent(maxPowerW: number, voltageV: number, phasesO: number): number {
  if (phasesO === 1 || phasesO === 2) {
    return maxPowerW / voltageV;
  } else if (phasesO === 3) {
    return maxPowerW / (voltageV * Math.sqrt(3));
  }
  throw new Error("Unknown number of phases " + phasesO.toString());
}

function getMaxPowerW(attributes: Attributes.Attributes): number {
  return (
    Attributes.getFloat("input-power-reheater-NOM-BASE-ALL", attributes) ??
    Attributes.getFloat("input-power-BASE-ALL", attributes) ??
    Attributes.getFloatOrDefault("input-power-heater-step1-NOM", attributes, 0)
  );
  // Attributes.getFloatOrDefault("input-power-heater-NOM-BASE-ALL", attributes, 0); Does not exist anymore according to Jonas Enbom
}
