import * as React from "react";
import type { FalIconName, FarIconName, FasIconName } from "client-lib/font-awesome-icons";
import { FontAwesomeIcon } from "client-lib/font-awesome-icons";

type IconProps = {
  readonly title?: string;
  readonly className?: string;
} & (
  | {
      readonly prefix?: "fal";
      readonly icon?: FalIconName;
    }
  | {
      readonly prefix: "far";
      readonly icon?: FarIconName;
    }
  | {
      readonly prefix: "fas";
      readonly icon?: FasIconName;
    }
);

export function Icon({ className, icon, title, prefix = "fal" }: IconProps): React.ReactElement {
  if (!icon) {
    return <span />;
  }
  return <FontAwesomeIcon className={className} title={title} icon={[prefix, icon]} />;
}
