import type { Curve, Solution } from "../result-items-types";
import * as IP from "./interpolation";

export function getSolutionValue(curves: ReadonlyArray<Curve>, solution: Solution): number | undefined {
  if (solution.type === "Curve") {
    const curve = curves.find((c) => c.id === solution.curveId);
    return curve && (IP.splineGetPoint(solution.point.x, curve.spline) ?? 0) * solution.ratio;
  } else if (solution.type === "Interpolated") {
    const lowerValue = getSolutionValue(curves, solution.lower);
    const higherValue = getSolutionValue(curves, solution.higher);
    if (lowerValue === undefined || higherValue === undefined) {
      return undefined;
    }
    return (higherValue - lowerValue) * solution.ratio + lowerValue;
  }
  return undefined;
}
