import * as React from "react";
import { withTw } from "client-lib/elements";
import { Icon } from "client-lib/elements/icon";

const Label = withTw("span", "cursor-default");

export interface PropertyLabelProps {
  readonly label: string | React.ReactNode;
  readonly labelHover?: string;
  readonly title?: string;
  readonly isRequired?: boolean;
}

export function PropertyLabel(props: PropertyLabelProps): JSX.Element {
  const { label, labelHover } = props;
  return (
    <Label className="w-1/2" title={labelHover}>
      {label}
      {labelHover && <Icon className="ml-8" icon={"info-circle"} />}
    </Label>
  );
}
