import type * as QP from "shared-lib/query-product";
import type { PropertyValueSet } from "@promaster-sdk/property";
import { PropertyFilter } from "@promaster-sdk/property";

export interface Attributes {
  readonly byCollection: CollectionAttributeValue;
  readonly byAttribute: AttributeValue;
}

export interface CollectionAttributeValue {
  readonly [collection: string]: AttributeValue;
}

export interface AttributeValue {
  readonly [attribute: string]: ReadonlyArray<string>;
}

export function createMap(variant: PropertyValueSet.PropertyValueSet, attributes: QP.AttributesTable): Attributes {
  const rows = attributes.filter((a) => PropertyFilter.isValid(variant, a.property_filter));
  const byCollection: { [collection: string]: { [attribute: string]: Array<string> } } = {};
  const byAttribute: { [attribute: string]: Array<string> } = {};

  for (const row of rows) {
    if (byCollection[row.collection] === undefined) {
      byCollection[row.collection] = {};
    }
    if (byCollection[row.collection][row.attribute] === undefined) {
      byCollection[row.collection][row.attribute] = [];
    }
    if (byAttribute[row.attribute] === undefined) {
      byAttribute[row.attribute] = [];
    }
    byCollection[row.collection][row.attribute].push(row.value);
    byAttribute[row.attribute].push(row.value);
  }
  return {
    byCollection: byCollection,
    byAttribute: byAttribute,
  };
}

function getSingleAttributeValue(attributes: Attributes, attribute: string): string | undefined {
  const value = attributes.byAttribute[attribute];
  if (value === undefined) {
    return undefined;
  }
  if (value.length === 1) {
    return value[0];
  }
  throw new Error("Tried to get Single value for multi value attribute");
}

export function getPhases(attributes: Attributes): number | undefined {
  const value = getSingleAttributeValue(attributes, "input-phases");
  return value !== undefined ? parseInt(value[0], 10) : undefined;
}

export function getInt(name: string, attributes: Attributes): number | undefined {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? parseInt(value, 10) : undefined;
}

export function getIntOrDefault(name: string, attributes: Attributes, defaultValue: number): number {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? parseInt(value, 10) : defaultValue;
}

export function getIntOrThrow(name: string, attributes: Attributes): number {
  const value = getSingleAttributeValue(attributes, name);
  const intValue = value !== undefined ? parseInt(value, 10) : undefined;
  if (intValue === undefined) {
    console.warn(attributes);
    throw new Error("Could not find required attribute " + name);
  }
  return intValue;
}

export function getMeasurementNo(name: string, attributes: Attributes): string | undefined {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? value.split(".")[0] : undefined;
}

export function getString(name: string, attributes: Attributes): string | undefined {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? value : undefined;
}

export function getStringOrDefault(
  name: string,
  attributes: Attributes,
  defaultValue: string | number
): string | number {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? value : defaultValue;
}

export function getStringOrThrow(name: string, attributes: Attributes): string {
  const value = getSingleAttributeValue(attributes, name);
  if (value === undefined) {
    console.warn(attributes);
    throw new Error("Could not find required attribute " + name);
  }
  return value;
}

export function getFloat(name: string, attributes: Attributes): number | undefined {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? parseFloat(value.replace(",", ".")) : undefined;
}

export function getFloatOrThrow(name: string, attributes: Attributes): number {
  const value = getSingleAttributeValue(attributes, name);
  if (value === undefined) {
    console.warn(attributes);
    throw new Error("Could not find required attribute " + name);
  }
  return parseFloat(value.replace(",", "."));
}

export function getFloatOrDefault(name: string, attributes: Attributes, defaultValue: number): number {
  const value = getSingleAttributeValue(attributes, name);
  return value !== undefined ? parseFloat(value.replace(",", ".")) : defaultValue;
}

export function matchesAttribute(name: string, attributes: Attributes, value: string): boolean {
  const attributeValue = attributes.byAttribute[name];
  if (attributeValue === undefined) {
    return false;
  }
  return attributeValue.some((a) => a === value);
}

export function matchInputFrequency(
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes
): boolean {
  const variant50 = matchesAttribute("input-frequency-MULTI", variantAttributes, "50");
  const variant60 = matchesAttribute("input-frequency-MULTI", variantAttributes, "60");
  if (!variant50 && !variant60) {
    return true;
  }
  const search50 = searchAttributes.some((a) => a.attribute === "input-frequency-MULTI" && a.value === "50");
  const search60 = searchAttributes.some((a) => a.attribute === "input-frequency-MULTI" && a.value === "60");
  if (!search50 && !search60) {
    return true;
  }
  if (search50 && variant50) {
    return true;
  }
  if (search60 && variant60) {
    return true;
  }
  return false;
}

export function matchAttribute(
  attribute: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes
): boolean {
  const searchCollection = searchAttributes.filter((a) => a.attribute === attribute);
  if (searchCollection.length === 0) {
    return true;
  }

  const values = variantAttributes.byAttribute[attribute];
  if (values === undefined) {
    return false;
  }

  return searchCollection.some((a) => values.find((b) => b === a.value));
}

export function matchAttributeParts(
  attribute: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes
): boolean {
  const searchCollection = searchAttributes.filter((a) => a.attribute === attribute);
  if (searchCollection.length === 0) {
    return true;
  }

  const variantValue = variantAttributes.byAttribute[attribute];
  if (variantValue === undefined) {
    return false;
  }
  return searchCollection.every((a) => variantValue.find((b) => b.includes(a.value)));
}

export function matchCollection(
  collection: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes
): boolean {
  const searchCollection = searchAttributes.filter((a) => a.collection === collection);
  if (searchCollection.length === 0) {
    return true;
  }
  const match = searchCollection.some((a) =>
    variantAttributes.byCollection[collection]?.[a.attribute]?.find((b) => b === a.value)
  );
  // if (!match) {
  //   console.log(collection);
  // }
  return match;
}
