/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import type { Property } from "client-lib/properties-selector";
import * as Texts from "shared-lib/language-texts";
import { SelectButton } from "client-lib/elements";
import type { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import * as Shared from "./shared";

export interface RadioGroupPropertySelectorProps {
  readonly productId: string;
  readonly property: Property;
  readonly selections: Shared.Selections;
  readonly selectionsChanged: Shared.SelectionsChanged;
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly translate: Texts.TranslateFunction;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly showAdvanced: boolean;
  readonly images: Shared.Images;
}

export function RadioGroupPropertySelector(props: RadioGroupPropertySelectorProps): JSX.Element {
  const {
    productId,
    property,
    variants,
    selections,
    selectionsChanged,
    translate,
    filterPrettyPrint,
    showAdvanced,
    images,
  } = props;
  const value = PropertyValueSet.getInteger(property.name, variants[0]);
  return (
    <div className="flex flex-col space-y-4">
      {property.value.map((v) => {
        const intValue = v.value ? PropertyValue.getInteger(v.value) ?? 0 : 0;
        const translated = translate(Texts.property_value(productId, property.name, intValue));
        const label = `${translated} ${showAdvanced ? `(${intValue})` : ""}`;
        const error = Shared.isFilterValid(variants, v.property_filter)
          ? undefined
          : filterPrettyPrint(v.property_filter);
        const selected = intValue === value;
        const image = v.image ? images[v.image] : undefined;
        return (
          <SelectButton
            key={intValue}
            selected={selected}
            title={error}
            disabled={!!error}
            onClick={() => selectionsChanged(Shared.setIntegers(property.name, [intValue], selections))}
            label={label}
            imageUrl={image}
          />
        );
      })}
    </div>
  );
}
