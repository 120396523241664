/* eslint-disable @typescript-eslint/no-explicit-any */
import { retryFetch } from "shared-lib/fetch";

export interface NicotraInput {
  readonly Airflow: number; // m3/h
  readonly Pressure: number; // PA
  readonly FanSeries: string; // from Database BoxfanselectionDB in the Desktop application for Boxfan selection, table Fanseries, column Fanseries
  readonly Frequency: number; // hz
  readonly Distance: number; // m
}

export interface NicotraSoundResult {
  readonly Hz63: number;
  readonly Hz125: number;
  readonly Hz250: number;
  readonly Hz500: number;
  readonly Hz1000: number;
  readonly Hz2000: number;
  readonly Hz4000: number;
  readonly Hz8000: number;
}

export interface NicotraResult {
  readonly Airflow: number; // m3/h
  readonly AirPressure: number; // PA
  readonly ArticleNumber: string;
  readonly FanCode: string;
  readonly FanRPM: number; // RPM
  readonly FanEfficiency: number; // %
  readonly FanShaftPower: number; //KW
  readonly FanPowerFactor: number;
  readonly FanSize: string;
  readonly FanDiameter: string;
  readonly FanPrice: number;
  readonly OutletVelocity: number; // m/s
  readonly FanMaxRPM: number; // RPM
  readonly FanStaticEfficiency: number;
  readonly FanMaxPower: number; //KW
  readonly FanMaxFrequency: number;
  readonly InletDBA: number;
  readonly OutletDBA: number;
  readonly FanAccoustics: string;
  readonly FanLength: number;
  readonly FanHeight: number;
  readonly FanWidth: number;
  readonly FanWeight: number;
  readonly FanPressureDrop: number;
  readonly KFactor: number;
  readonly FanFamily: string;
  readonly AbsorbedPower: number;
  readonly MotorKw: number;
  readonly MotorModel: string;
  readonly MotorCurrent: string;
  readonly TotalPressure: number;
  readonly MotorSpeed: number;

  readonly SoundInlet: NicotraSoundResult;
  readonly SoundPowerSurroundingDB: number;
  readonly SoundPowerSurroundingDBA: number;

  readonly SoundOutlet: NicotraSoundResult;
  readonly SoundPowerOutletDB: number;
  readonly SoundPowerOutletDBA: number;

  readonly SoundPressure: NicotraSoundResult;
  readonly SoundPressureDB: number;
  readonly SoundPressureDBA: number;

  readonly SoundBreakOut: NicotraSoundResult;
  readonly UnitBreakOutDB: number;
  readonly UnitBreakOutDBA: number;

  readonly DiagramBase64Png: string | null; // png image of the Diagram encoded as Base64
}

interface DeferedPromise {
  readonly resolve: (result: ReadonlyArray<NicotraResult>) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly reject: (rejectReason: any) => void;
}

// eslint-disable-next-line functional/prefer-readonly-type
type PromiseMap = Map<string, Array<DeferedPromise>>;
const promisesToSettle: PromiseMap = new Map();

// eslint-disable-next-line functional/prefer-readonly-type, functional/no-let
let resultCache: { [key: string]: ReadonlyArray<NicotraResult> } = {};
export async function calculate(input: NicotraInput): Promise<ReadonlyArray<NicotraResult>> {
  const key = JSON.stringify(input);

  if (resultCache[key] !== undefined) {
    return resultCache[key];
  }

  const promises = promisesToSettle.get(key);
  if (promises !== undefined) {
    const promise = new Promise(
      (resolve: (result: ReadonlyArray<NicotraResult>) => void, reject: (error: any) => void) => {
        promises.push({
          resolve,
          reject,
        });
      }
    );

    return promise;
  }

  const promisesToResolve: Array<DeferedPromise> = [];
  promisesToSettle.set(key, promisesToResolve);

  const result: ReadonlyArray<NicotraResult> = await retryFetch(
    "https://design-dllcal.systemair.com/api/nicotra/calculate",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }
  )
    .then((r) => r.json())
    .catch((error) => {
      settlePromises(promisesToResolve, error, undefined);
    });

  settlePromises(promisesToResolve, undefined, result);
  resultCache[key] = result;
  if (Object.keys(resultCache).length > 10) {
    // Hard clear
    resultCache = {};
  }
  promisesToSettle.delete(key);
  return result;
}

function settlePromises(
  // eslint-disable-next-line functional/prefer-readonly-type
  promises: Array<DeferedPromise>,

  error: any,
  result: ReadonlyArray<NicotraResult> | undefined
): void {
  while (promises.length > 0) {
    const promise = promises.pop();

    if (promise) {
      const { resolve, reject } = promise;
      if (error === undefined && result) {
        resolve(result);
      } else {
        reject(error);
      }
    }
  }
}
