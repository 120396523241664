import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { Fan} from "../result-items-types";
import { createFanResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<Fan>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createFanResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
