import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as Types from "../types";
import * as Messages from "../messages";
import type { BoxFanYilida, YilidaAir, YilidaProduct, OctaveBands } from "../result-items-types";
import type { Input } from "./types";
import * as YilidaDLL from "../dll/yilida";
import * as AirDensity from "../shared/air-density";

export async function calculate(input: Input): Promise<Types.CalculatorResult<BoxFanYilida>> {
  const {
    calcParams,
    fanSeries,
    fanSize,
    airFlow,
    soundPressureDistance,
    externalPressure,
    frequency,
    airDensity,
    accessoryPressureDrop,
    dllLimits,
    fanType,
    voltage,
    phases,
  } = input;
  const messages: Array<Messages.Message> = [];

  if (!airFlow || !externalPressure || !soundPressureDistance || !airDensity) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing("Yilida")]);
  }
  const airFlowm3h = Amount.valueAs(Units.CubicMeterPerHour, airFlow);
  const externalPressurePa = Amount.valueAs(Units.Pascal, externalPressure);
  const soundPressureDistanceM = Amount.valueAs(Units.Meter, soundPressureDistance);
  const density = Amount.valueAs(Units.KilogramPerCubicMeter, airDensity);

  const externalPressurePaWithAccessories =
    externalPressurePa +
    accessoryPressureDrop.reduce((sum, accessory) => {
      return (sum += Amount.valueAs(Units.Pascal, accessory.pressure_drop));
    }, 0);

  // Added 2020-07-19 so that Systemair can test Density
  const requiredPressureByDensityPa = Amount.valueAs(
    Units.Pascal,
    AirDensity.calculateExternalPressureWithDensity(
      Amount.create(externalPressurePaWithAccessories, Units.Pascal),
      airDensity
    )!
  );

  const calculationResult = await YilidaDLL.calculate({
    Airflow: airFlowm3h,
    Pressure: requiredPressureByDensityPa,
    Fantype: fanType,
    Frequency: frequency,
    Density: density,
    SoundDistance: soundPressureDistanceM,
    DllLimits: dllLimits,
  });

  const dllResult = calculationResult.find((t) => `${t.Series}/${t.SubSeries}` === fanSeries && t.Size === fanSize);

  if (dllResult === undefined) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange("Yilida")]);
  }

  const air: YilidaAir = {
    airFlow: Amount.create(dllResult.AirFlow, Units.CubicMeterPerHour),
    externalPressure: Amount.create(dllResult.StaticPressure, Units.Pascal),
    totalPressure: Amount.create(dllResult.TotalPressure, Units.Pascal),
    efficiency: Amount.create(dllResult.Efficiency, Units.Percent),
    fanSpeed: Amount.create(dllResult.Speed, Units.RevolutionsPerMinute),
    airVelocity: Amount.create(dllResult.AirVelocity, Units.MeterPerSecond),
    absorbedPower: Amount.create(dllResult.Power, Units.KiloWatt),
    airDensity: airDensity,
  };

  const product: YilidaProduct = {
    family: fanType,
    size: dllResult.Size.indexOf("/") > -1 ? dllResult.Size.replace("/", "-") : dllResult.Size,
    motor: dllResult.Motor,
    motorPower: Amount.create(dllResult.MotorPower, Units.KiloWatt),
    motorVoltage: Amount.create<Quantity.ElectricPotential>(voltage, Units.Volt),
    motorPhase: Amount.create<Quantity.Dimensionless>(phases, Units.One),
  };

  return Types.createCalculatorSuccess(
    [{ value: fanSize, descending: false }],
    {
      air,
      product,
      diagramBase64: dllResult.DiagramBase64Png ?? undefined,
      accessories: accessoryPressureDrop.map((t) => t.name),
      soundPowerOutlet: createSoundResult(dllResult.SoundOutlet, dllResult.SoundPowerOutletDBA),
      soundPressure: createSoundResult(dllResult.SoundPressureLevel, dllResult.SoundPressureDBA),
      soundPressureDistance: soundPressureDistance,
    },
    messages,
    calcParams
  );
}

function createSoundResult(octaveBands: YilidaDLL.YilidaSoundResult, totalSound: number): OctaveBands {
  return {
    type: "Octave",
    hz63: octaveBands.Hz63,
    hz125: octaveBands.Hz125,
    hz250: octaveBands.Hz250,
    hz500: octaveBands.Hz500,
    hz1000: octaveBands.Hz1000,
    hz2000: octaveBands.Hz2000,
    hz4000: octaveBands.Hz4000,
    hz8000: octaveBands.Hz8000,
    total: Amount.create<Quantity.SoundPowerLevel>(totalSound, Units.DecibelLw),
  };
}
