import * as Interpolation from "shared-lib/interpolation";
import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import * as R from "ramda";
import type { Curve } from "../result-items-types";
import type { HeaterLimits } from "../electric-duct-heater/types";

export function calculateVelocityPressureDrop(
  airVelocity: Amount.Amount<Quantity.Velocity>,
  heaterLimits: HeaterLimits
): number {
  const velocityMps = Amount.valueAs(Units.MeterPerSecond, heaterLimits.air_velocity_max);
  const pressureDropPa = Amount.valueAs(Units.Pascal, heaterLimits.pressure_drop_max);
  const k = pressureDropPa / (velocityMps * velocityMps);
  // eslint-disable-next-line no-restricted-properties
  return k * Math.pow(Amount.valueAs(Units.MeterPerSecond, airVelocity), 2);
}

export function createPowerPressureCurve(
  minFlowLps: number,
  maxFlowLps: number,
  points: ReadonlyArray<Interpolation.Vec2>,
  samples: number = 10
): Curve {
  const powerCurve = Interpolation.powerCurveCreateFromPoints(points);
  const flowStep = (maxFlowLps - minFlowLps) / samples;
  const sampleXs = [...R.range(0, samples + 1).map((i) => minFlowLps + i * flowStep), ...points.map((p) => p.x)];
  const newPoints = sampleXs.map((x) => {
    const y = Interpolation.powerCurveGetY(x, powerCurve);
    return Interpolation.vec2Create(x, y);
  });
  const spline = Interpolation.splineCreateFromPoints(newPoints);

  return {
    id: "1",
    controlVoltage: 100,
    supplyVoltage: 100,
    controlFrequency: 100,
    unitX: Units.LiterPerSecond,
    unitY: Units.Pascal,
    workMin: minFlowLps,
    workMax: maxFlowLps,
    spline: spline,
  };
}
