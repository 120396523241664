import * as R from "ramda";
import type * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { createSelector } from "reselect";
import * as ProductUtils from "shared-lib/product-utils";
import { getProductCodes, getVariantFromM3ItemNo } from "shared-lib/product-codes";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import type { MarketTablesResponse } from "./ecom-markets";
import { availableInMarket } from "./ecom-markets";
import * as PC from "../product-codes";

export interface FricoProductTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

interface FricoResult {
  readonly itemNo: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
}

interface AccessoryItem {
  readonly m3ItemNo: string;
  readonly quantity: number;
}

interface Product {
  readonly productId: string;
  readonly m3ItemNo: string;
}

interface ProductEpim {
  readonly productId: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
}

interface AddedProduct {
  readonly productId: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
  readonly quantity: number;
}

interface TablesForProducts {
  readonly [id: string]: AllProductTables;
}

interface TablesForProductsEpim {
  readonly [id: string]: AllProductTablesEpim;
}

interface AllProductTables {
  readonly ct_AccessoryRelations: QP.AccessoryRelationsTable;
}

interface AllProductTablesEpim {
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

interface AccessoryResponse {
  readonly tablesForProducts: TablesForProducts;
  readonly ct_FricoAccessories: QP.FricoAccessoriesTable;
  readonly marketTables: MarketTablesResponse;
}

interface AccessoryResponseEpim {
  readonly tablesForProducts: TablesForProductsEpim;
  readonly marketTables: MarketTablesResponse;
  readonly tablesForAccessories: TablesForProductsEpim;
}

export function onlyDefaultVariants(
  productTables: FricoProductTables,
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const result: Array<FricoResult> = [];
  const propValuesOrdered = getVariantPropertyValue(productTables);

  if (propValuesOrdered === undefined) {
    return variants;
  }

  for (const val of propValuesOrdered) {
    const filter = PropertyFilter.fromString("variantName=" + val, CustomUnitsLookup)!;

    for (const variant of variants) {
      const code = getProductCodes(productTables, variant);

      if (
        code.itemNo === "" ||
        !PropertyFilter.isValid(variant, filter) ||
        result.find((r) => r.itemNo === code.itemNo) !== undefined
      ) {
        continue;
      }

      result.push({
        itemNo: code.itemNo,
        variant: variant,
      });
    }
  }
  return result.map((r) => r.variant);
}

function getVariantPropertyValue(productTables: FricoProductTables): ReadonlyArray<number> | undefined {
  const variantNameProp = productTables.property.find((r) => r.name === "variantName");
  if (variantNameProp === undefined) {
    return undefined;
  }

  const values = variantNameProp.value
    .reduce((sofar, current) => {
      if (current.value.type === "integer" && current.value.value !== 0) {
        sofar.push(current.value.value);
      }

      return sofar;
    }, Array<number>())
    .sort((a, b) => b - a); // Sort 0,2,1

  return [0, ...values];
}

// export function getFricoVariantsEpim(
//   productTables: FricoProductTables
// ): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
//   const properties = R.uniq(
//     R.unnest<string>([
//       ...productTables.code.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
//       ...productTables.ct_VariantNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
//       ...productTables.ct_ItemNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
//     ])
//   );
//   return ProductUtils.generateVariantsForProperties(properties, PropertyValueSet.Empty, productTables.property);
// }

export function getFricoDefaultVariants(
  productTables: FricoProductTables
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const properties = R.uniq(
    R.unnest<string>([
      ...productTables.code.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
      ...productTables.ct_ItemNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
    ])
  );
  const variants = ProductUtils.generateVariantsForProperties(
    properties,
    PropertyValueSet.Empty,
    productTables.property
  );

  return onlyDefaultVariants(productTables, variants);
}

export interface GetFricoIncludedAccessoriesProps {
  readonly products: ReadonlyArray<AddedProduct>;
  readonly response: AccessoryResponse;
  readonly addedAccessories: ReadonlyArray<AccessoryItem>;
}

export interface GetFricoIncludedAccessoriesEpimProps {
  readonly products: ReadonlyArray<AddedProduct>;
  readonly response: AccessoryResponseEpim;
  readonly addedAccessories: ReadonlyArray<AccessoryItem>;
}

export interface FricoAccessory {
  readonly sortNo: number;
  readonly itemNo: string;
  readonly variantId: string;
  readonly name: string;
  readonly category: number;
  readonly control: string;
  readonly defaultQuantity: number;
  readonly typeControlItemNumbers: string;
  readonly includedByDefault: number;
  readonly productId: string;
  readonly accVariant: PropertyValueSet.PropertyValueSet;
}

export const getFricoIncludedAccessoriesEpim = createSelector(
  (p: GetFricoIncludedAccessoriesEpimProps) => p.products,
  (p: GetFricoIncludedAccessoriesEpimProps) => p.response.tablesForProducts,
  (p: GetFricoIncludedAccessoriesEpimProps) => p.response.tablesForAccessories,
  (p: GetFricoIncludedAccessoriesEpimProps) => p.response.marketTables,
  (p: GetFricoIncludedAccessoriesEpimProps) => p.addedAccessories,
  (products, tablesForProducts, tablesForAccessories, marketTables, addedAccessories) =>
    getFricoIncludedAccessoriesEpimImpl(
      products,
      {
        tablesForProducts,
        tablesForAccessories,
        marketTables,
      },
      addedAccessories
    )
);

function getFricoIncludedAccessoriesEpimImpl(
  products: ReadonlyArray<ProductEpim>,
  response: AccessoryResponseEpim | undefined,
  _addedAccessories: ReadonlyArray<AccessoryItem>
): ReadonlyArray<FricoAccessory> | undefined {
  if (response === undefined) {
    return undefined;
  }

  const { tablesForProducts, marketTables, tablesForAccessories } = response;

  if (tablesForProducts === undefined || marketTables === undefined || tablesForAccessories === undefined) {
    return undefined;
  }

  const result: Array<FricoAccessory> = [];
  for (const product of products) {
    const m3Variant = getVariantFromM3ItemNo(tablesForProducts[product.productId], product.m3ItemNo, product.variantNo);
    if (!m3Variant) {
      continue;
    }

    const accRow = tablesForProducts[product.productId].ct_Accessories.filter((acc) =>
      PropertyFilter.isValid(m3Variant, acc.property_filter)
    );

    for (const row of accRow) {
      const accessoryProductTables = tablesForAccessories[row.product];

      const accessoryProperties = accessoryProductTables.property.map((p) => p.name);
      const variants = ProductUtils.generateVariantsForProperties(
        accessoryProperties,
        row.variant,
        accessoryProductTables.property
      );

      for (const accVariant of variants) {
        const code = getProductCodes(accessoryProductTables, accVariant);
        if (code === undefined || code.variantId === undefined) {
          continue;
        }
        // Must be in market
        if (!marketTables.variantNoToPrice || marketTables.variantNoToPrice[code.variantId] === undefined) {
          continue;
        }

        if (result.some((acc) => acc.itemNo === code.itemNo)) {
          continue;
        }

        result.push({
          productId: row.product,
          sortNo: 1,
          itemNo: code.itemNo,
          variantId: code.variantId,
          name: code.code,
          accVariant: accVariant,
          category: accTypeToCategory(row.accessory_type),
          control: "",
          defaultQuantity: 0,
          typeControlItemNumbers: "",
          includedByDefault: 0,
        });
      }
    }
  }
  return result;

  // const selectedControlItemNo = getSelectedControlAccessory(response.ct_FricoAccessories, addedAccessories);

  // // Find accessory in fricoaccessory table
  // return accessoriesItemno
  //   .reduce((result, itemNo) => {
  //     const accessory = ct_FricoAccessories.find((r) => r.item_no === itemNo);
  //     if (accessory !== undefined) {
  //       if (accessory.type_control_item_numbers !== undefined && accessory.type_control_item_numbers.length > 0) {
  //         // Clean on selected control
  //         if (selectedControlItemNo === undefined) {
  //           return result;
  //         }
  //         const validForControl = accessory.type_control_item_numbers.split(",");
  //         if (validForControl.find((r) => r === selectedControlItemNo) === undefined) {
  //           return result;
  //         }
  //       }

  //       // Valid for market?
  //       if (marketTables.variantNoToPrice && marketTables.variantNoToPrice[accessory.variant_id] !== undefined) {
  //         result.push(accessory);
  //       }
  //     }
  //     return result;
  //   }, Array<FricoAccessory>())
  //   .sort((a, b) => a.sort_no - b.sort_no);
}

export function getFricoAccessoryQuantitiesEpimImpl(
  _addedProducts: ReadonlyArray<AddedProduct>,
  _response: AccessoryResponseEpim | undefined,
  includedAccessories: ReadonlyArray<FricoAccessory>,
  addedAccessories: ReadonlyArray<AccessoryItem>
): { readonly [m3ItemNo: string]: number } {
  const quantities = includedAccessories.reduce<{ [m3ItemNo: string]: number }>((sofar, includedAccessory) => {
    const userAdded = addedAccessories.find((a) => a.m3ItemNo === includedAccessory.itemNo);
    if (userAdded) {
      sofar[userAdded.m3ItemNo] = userAdded.quantity;
    }
    return sofar;
  }, {});

  // Make sure a control accessory is selected
  const selectedControlItem = includedAccessories.find((a) => a.category === 0 && (quantities[a.itemNo] || 0) > 0);
  const defaultControlItem = includedAccessories.find((r) => r.category === 0);
  if (!selectedControlItem && defaultControlItem) {
    quantities[defaultControlItem.itemNo] = 1;
  }

  // defaultControlItem.default_quantity

  // Make sure a valve accessory is selected
  const selectedValveItem = includedAccessories.find((a) => a.category === 1 && (quantities[a.itemNo] || 0) > 0);
  const defaultValveItem = includedAccessories.find((r) => r.category === 1);
  if (!selectedValveItem && defaultValveItem) {
    quantities[defaultValveItem.itemNo] = 1;
  }

  // Added automatically added accessories
  //const includedByDefault = includedAccessories.filter((a) => a.included_by_default === 1);
  const quantiesIncluded: { [itemNo: string]: number } = {};
  // for (const addedProduct of addedProducts) {
  //   const accessoryRelations =
  //     (response &&
  //       response.tablesForProducts &&
  //       response.tablesForProducts[addedProduct.productId]?.ct_AccessoryRelations) ||
  //     [];
  //   for (const relation of accessoryRelations) {
  //     if (!includedByDefault.some((a) => a.item_no === relation.accessory_item_no)) {
  //       continue;
  //     }
  //     if (relation.item_no !== addedProduct.m3ItemNo) {
  //       continue;
  //     }
  //     const currentQuantity = quantiesIncluded[relation.accessory_item_no] || 0;
  //     quantiesIncluded[relation.accessory_item_no] = currentQuantity + addedProduct.quantity;
  //   }
  // }
  for (const [itemNo, quantity] of Object.entries(quantiesIncluded)) {
    if (quantities[itemNo] === undefined && quantity > 0) {
      quantities[itemNo] = quantity;
    }
  }

  return quantities;
}

function accTypeToCategory(accessoryType: QP.AccessoryCategory): number {
  switch (accessoryType) {
    case "ElectricalAccessory":
      return 2;
    case "MechanicalAccessory":
      return 3;
    case "WaterAccessory":
      return 4;
    case "Valves":
      return 1;
    case "Controls":
      return 0;
    case "Accessory":
      return 5;
    default:
      return 99;
  }
}

export const getFricoIncludedAccessories = createSelector(
  (p: GetFricoIncludedAccessoriesProps) => p.products,
  (p: GetFricoIncludedAccessoriesProps) => p.response.tablesForProducts,
  (p: GetFricoIncludedAccessoriesProps) => p.response.ct_FricoAccessories,
  (p: GetFricoIncludedAccessoriesProps) => p.response.marketTables,
  (p: GetFricoIncludedAccessoriesProps) => p.addedAccessories,
  (products, tablesForProducts, ct_FricoAccessories, marketTables, addedAccessories) =>
    getFricoIncludedAccessoriesImpl(
      products,
      {
        tablesForProducts,
        ct_FricoAccessories,
        marketTables,
      },
      addedAccessories
    )
);

function getFricoIncludedAccessoriesImpl(
  products: ReadonlyArray<Product>,
  response: AccessoryResponse | undefined,
  addedAccessories: ReadonlyArray<AccessoryItem>
): ReadonlyArray<QP.FricoAccessory> | undefined {
  if (response === undefined) {
    return undefined;
  }
  const { ct_FricoAccessories, tablesForProducts, marketTables } = response;

  if (tablesForProducts === undefined || marketTables === undefined || ct_FricoAccessories === undefined) {
    return undefined;
  }

  // Get itemnumbers for all accessories
  const accessoriesItemno = R.uniq(
    products.reduce((result, item) => {
      if (tablesForProducts[item.productId].ct_AccessoryRelations === undefined) {
        return result;
      }
      const accForVariant = tablesForProducts[item.productId].ct_AccessoryRelations
        .filter((r) => r.item_no === item.m3ItemNo)
        .map((r) => r.accessory_item_no);

      return [...result, ...accForVariant];
    }, Array<string>())
  );

  const selectedControlItemNo = getSelectedControlAccessory(response.ct_FricoAccessories, addedAccessories);

  // Find accessory in fricoaccessory table
  return accessoriesItemno
    .reduce((result, itemNo) => {
      const accessory = ct_FricoAccessories.find((r) => r.item_no === itemNo);
      if (accessory !== undefined) {
        if (accessory.type_control_item_numbers !== undefined && accessory.type_control_item_numbers.length > 0) {
          // Clean on selected control
          if (selectedControlItemNo === undefined) {
            return result;
          }
          const validForControl = accessory.type_control_item_numbers.split(",");
          if (validForControl.find((r) => r === selectedControlItemNo) === undefined) {
            return result;
          }
        }

        // Valid for market?
        if (marketTables.variantNoToPrice?.[accessory.variant_id] !== undefined) {
          result.push(accessory);
        }
      }
      return result;
    }, Array<QP.FricoAccessory>())
    .sort((a, b) => a.sort_no - b.sort_no);
}

export const getFricoAccessoryQuantities = createSelector(
  (p: GetFricoIncludedAccessoriesProps) => p.products,
  (p: GetFricoIncludedAccessoriesProps) => p.response.tablesForProducts,
  (p: GetFricoIncludedAccessoriesProps) => p.response.ct_FricoAccessories,
  (p: GetFricoIncludedAccessoriesProps) => p.response.marketTables,
  (p: GetFricoIncludedAccessoriesProps) => p.addedAccessories,
  getFricoIncludedAccessories,
  (products, tablesForProducts, ct_FricoAccessories, marketTables, addedAccessories, includedAccessories) =>
    includedAccessories
      ? getFricoAccessoryQuantitiesImpl(
          products,
          {
            tablesForProducts,
            ct_FricoAccessories,
            marketTables,
          },
          includedAccessories,
          addedAccessories
        )
      : {}
);

function getFricoAccessoryQuantitiesImpl(
  addedProducts: ReadonlyArray<AddedProduct>,
  response: AccessoryResponse | undefined,
  includedAccessories: ReadonlyArray<QP.FricoAccessory>,
  addedAccessories: ReadonlyArray<AccessoryItem>
): { readonly [m3ItemNo: string]: number } {
  const quantities = includedAccessories.reduce<{ [m3ItemNo: string]: number }>((sofar, includedAccessory) => {
    const userAdded = addedAccessories.find((a) => a.m3ItemNo === includedAccessory.item_no);
    if (userAdded) {
      sofar[userAdded.m3ItemNo] = userAdded.quantity;
    }
    return sofar;
  }, {});

  // Make sure a control accessory is selected
  const selectedControlItem = includedAccessories.find((a) => a.category === 0 && (quantities[a.item_no] || 0) > 0);
  const defaultControlItem = includedAccessories.find((r) => r.category === 0);
  if (!selectedControlItem && defaultControlItem) {
    quantities[defaultControlItem.item_no] = defaultControlItem.default_quantity;
  }

  // Added automatically added accessories
  const includedByDefault = includedAccessories.filter((a) => a.included_by_default === 1);
  const quantiesIncluded: { [itemNo: string]: number } = {};
  for (const addedProduct of addedProducts) {
    const accessoryRelations = response?.tablesForProducts?.[addedProduct.productId]?.ct_AccessoryRelations || [];
    for (const relation of accessoryRelations) {
      if (!includedByDefault.some((a) => a.item_no === relation.accessory_item_no)) {
        continue;
      }
      if (relation.item_no !== addedProduct.m3ItemNo) {
        continue;
      }
      const currentQuantity = quantiesIncluded[relation.accessory_item_no] || 0;
      quantiesIncluded[relation.accessory_item_no] = currentQuantity + addedProduct.quantity;
    }
  }
  for (const [itemNo, quantity] of Object.entries(quantiesIncluded)) {
    if (quantities[itemNo] === undefined && quantity > 0) {
      quantities[itemNo] = quantity;
    }
  }

  return quantities;
}

function getSelectedControlAccessory(
  ct_FricoAccessories: QP.FricoAccessoriesTable,
  addedAccessories: ReadonlyArray<AccessoryItem>
): string | undefined {
  const addedSet = new Set<string>(addedAccessories.filter((a) => a.quantity > 0).map((a) => a.m3ItemNo));
  return ct_FricoAccessories.find((a) => a.category === 0 && addedSet.has(a.item_no))?.item_no;
}

// Get all default variants
// Loop rest, if itemnumber exisits in both variantName = 1 and 2 take 2, otherwise take 1

// RENAME TO VARIANTNAME
// const variantProperty = properties.find(r => r === "variantName");
// const variant1Filter = PropertyFilter.fromString("variantName=1"); // "230V/1phase"
// const variant2Filter = PropertyFilter.fromString("variantName=2"); // "400V/4Phase"

// const variant1Variants = variants.some(v => PropertyFilter.isValid(v, variant1Filter!));
// const variant2Variants = variants.some(v => PropertyFilter.isValid(v, variant2Filter!));

// const otherFilter = variant1Variants && variant2Variants ? "variantName=2" : "variantName=1";

// let results: Array<PropertyValueSet.PropertyValueSet> = [];

// const filter =
//   variantProperty !== undefined ? PropertyFilter.fromString("variantName=0|" + otherFilter) : PropertyFilter.Empty;

// for (const variant of variants) {
//   if (filter && !PropertyFilter.isValid(variant, filter)) {
//     continue;
//   }
//   results.push(variant);
// }
// return results;

export interface FricoProductTablesEpim {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_Attributes2: QP.AttributesTable;
}

// TODO SELECTOR(RESELECT)
export function getFricoDefaultVariantsEpim(
  productTables: FricoProductTablesEpim,
  ecomProductId: string | undefined,
  includeAmbient: boolean,
  marketTables: MarketTablesResponse,
  includeExpired: boolean
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  return getFricoDefaultVariantsEpimImpl(productTables, ecomProductId, includeAmbient, marketTables, includeExpired);
}

export type HeatingMethod = "electric" | "water" | "ambient";

export function getHeatingMethod(attributes: Attributes.Attributes): HeatingMethod {
  if (Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Electric")) {
    return "electric";
  } else if (
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water heat, coil for high water temperature") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water heat, coil for low water temperature") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water heat, coil for medium water temperature") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water high") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water low") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Water heat, coil for very low water temperature")
  ) {
    return "water";
  } else if (
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "No heater") ||
    Attributes.matchesAttribute("PROP-heat-type-MULTI", attributes, "Ambient, no heat")
  ) {
    return "ambient";
  } else {
    console.log("Missing attribute for heating method, defaulting to ambient");
    return "ambient";
  }
}

export function isAmbient(ct_Attributes2: QP.AttributesTable, variant: PropertyValueSet.PropertyValueSet): boolean {
  const heatTypes = ct_Attributes2.filter(
    (att) =>
      att.attribute === "PROP-heat-type-MULTI" &&
      PropertyFilter.isValid(variant, att.property_filter) &&
      att.value !== "No heater"
  );

  return heatTypes.length === 0;
}

function getFricoDefaultVariantsEpimImpl(
  productTables: FricoProductTablesEpim,
  ecomProductId: string | undefined,
  includeAmbient: boolean,
  marketTables: MarketTablesResponse,
  includeExpired?: boolean
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const properties = R.uniq(
    R.unnest<string>([
      ...productTables.code.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
      ...productTables.ct_VariantNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
      ...productTables.ct_ItemNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
    ])
  );
  const allProductVariants = ProductUtils.generateVariantsForProperties(
    properties,
    PropertyValueSet.Empty,
    productTables.property
  );

  const results: Array<PropertyValueSet.PropertyValueSet> = [];
  for (const variant of allProductVariants) {
    const code = PC.getProductCodes(productTables, variant);
    if (!includeExpired && !availableInMarket(marketTables, code)) {
      continue;
    }

    // Check that variant belongs to supplied ecom_product_id if supplied
    if (
      ecomProductId !== undefined &&
      !productTables.ct_Attributes2.some(
        (att) =>
          att.attribute === "ecom-product-id" &&
          att.collection === "ECOM-HIERARCHY-FRICO" &&
          att.value === ecomProductId &&
          PropertyFilter.isValid(variant, att.property_filter)
      )
    ) {
      continue;
    }

    // Only default variants allowed
    if (
      !productTables.ct_Attributes2.find(
        (att) =>
          att.attribute === "default-variant" &&
          PropertyFilter.isValid(variant, att.property_filter) &&
          att.value === "1"
      )
    ) {
      continue;
    }

    // Should ambient be included
    if (!includeAmbient) {
      const heatTypes = productTables.ct_Attributes2.filter(
        (att) => att.attribute === "PROP-heat-type-MULTI" && PropertyFilter.isValid(variant, att.property_filter)
      );

      const noHeat = heatTypes.some((ht) => ht.value === "No heater" || ht.value === "Ambient, no heat");
      if (heatTypes.length === 0 || noHeat) {
        continue;
      }
    }
    results.push(variant);
  }

  return results;
}
