/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as Redux from "redux";
import type { DiaqCacheState, DiaqCacheUpdate } from "shared-lib/query-diaq";
import * as Actions from "./actions";
import type { LoadAtomicQueries, AnyQueryStoreState, AnyStoreState, AnyCacheState, AnyQuery } from "./types";

const debouncedLoadAllQueuedQueries = debounce(loadAllQueuedQueries, 50);

export function createQueryMiddleware(
  loadQueries: LoadAtomicQueries<AnyCacheState, AnyQuery, ReadonlyArray<Promise<DiaqCacheUpdate>>>,
  storeKey: string | undefined
): any /* Redux.Middleware */ {
  return (store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    // Run everything before becuase we want the state to be updated
    const responseToReturn = next(action);
    const state = getStateFromStore(store, storeKey);

    switch (action.type) {
      case "redux-query/QUEUE_QUERIES":
        if (state.currentlyLoadingQueries.length === 0) {
          debouncedLoadAllQueuedQueries(state, loadQueries, store.dispatch, store, storeKey);
        }
        break;
      case "redux-query/LOAD_COMPLETED":
        if (state.queudQueries.length > 0) {
          debouncedLoadAllQueuedQueries(state, loadQueries, store.dispatch, store, storeKey);
        }
        break;
      default:
        break;
    }

    return responseToReturn;
  };
}

function getStateFromStore(store: Redux.Store<AnyStoreState>, storeKey: string | undefined): AnyQueryStoreState {
  const storeState = storeKey ? store.getState()[storeKey] : store.getState();
  return storeState;
}

async function loadAllQueuedQueries(
  state: AnyQueryStoreState,
  loadQueries: LoadAtomicQueries<AnyCacheState, AnyQuery, ReadonlyArray<Promise<DiaqCacheUpdate>>>,
  dispatch: Redux.Dispatch<AnyStoreState>,
  _store: Redux.Store<any>,
  _storeKey: string | undefined
): Promise<void> {
  const unresolvedQueries = state.queudQueries;

  if (unresolvedQueries.length > 0) {
    dispatch(Actions.loadStarted());
    const promises = await loadQueries(state.cache, unresolvedQueries);
    let indexedPromises = promises.map((promise, index) => ({
      index,
      promise: promise.then((callback) => ({ callback, index })),
    }));

    const debouncedCache = debounce((_cache: DiaqCacheState) => dispatch(Actions.updateCache(_cache)), 50);

    let cache = state.cache || {};

    while (indexedPromises.length > 0) {
      const { index, callback } = await Promise.race(indexedPromises.map((i) => i.promise));
      indexedPromises = indexedPromises.filter((p) => p.index !== index);

      cache = callback(cache);
      debouncedCache(cache);
    }
    debouncedCache.cancel();
    dispatch(Actions.loadCompleted(cache));
  }
}

// (From underscore.js)
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.

function debounce<F extends (...args: ReadonlyArray<any>) => any>(
  func: F,
  wait: number
): ((...args: Parameters<F>) => void) & { readonly cancel: () => void } {
  let timeout: number | undefined = undefined;

  const debounced = function (...args: Parameters<F>): void {
    const later = function (): void {
      timeout = undefined;
      func(...args);
    };

    if (timeout) {
      window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(later, wait);
  };

  debounced.cancel = function (): void {
    if (timeout) {
      window.clearTimeout(timeout);
      timeout = undefined;
    }
  };

  return debounced as ((...args: Parameters<F>) => void) & { cancel: () => void };
}
