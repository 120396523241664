import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import type { RootState } from "../../../types";
import type { VisualizerOwnProps } from "../types";
import type { StateProps, Response } from "./container-component";
import { BoxFanDiagramsVisualizerContainerComponent } from "./container-component";

function mapPropsToQuery(_: VisualizerOwnProps, __: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
  });
}

const mapStateToProps: Selector<RootState, VisualizerOwnProps, StateProps> = createSelector(
  UserSettings.stateSelector,
  UserSettings.showBoxFanRpmSelector,
  UserSettings.showBoxFanEfficiencySelector,
  UserSettings.showBoxFanMotorPowerSelector,
  (userSettings, showRpm, showEfficiency, showMotorPower) => ({
    userSettings: userSettings,
    showRpm: showRpm,
    showEfficiency: showEfficiency,
    showMotorPower: showMotorPower,
  })
);

export const BoxFanDiagramsVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(BoxFanDiagramsVisualizerContainerComponent);
