/* eslint-disable no-case-declarations */
import * as R from "ramda";
import type { ComponentResult } from "shared-lib/search";
import type { RehydrateAction } from "redux-persist";
import type * as PU from "shared-lib/product-utils";
import type * as Actions from "./actions";
import type { Item } from "./types";

export interface Accessory {
  readonly productId: string;
  readonly m3ItemNo: string;
  readonly quantity: number;
}

export interface ResultIdToM3ItemNo {
  readonly [resultId: string]: string;
}

export interface State {
  readonly productId: string | undefined;
  readonly ecomProductId: string | undefined;
  readonly selectedM3ItemNo: string | undefined;
  readonly persistedSearchSelections: PU.Selections | undefined;
  readonly persistedAddedItems: ReadonlyArray<Item>;
  readonly searchSelections: PU.Selections | undefined;
  readonly addedItems: ReadonlyArray<Item>;
  readonly addedAccessories: ReadonlyArray<Accessory>;
  readonly addedSearchResultIds: ResultIdToM3ItemNo;
  readonly browserLvl1: string | undefined;
  readonly browserLvl2: string | undefined;
  readonly searchNotFound: boolean;
  readonly savedCalculations: ReadonlyArray<ComponentResult>;
  readonly calculationExists: boolean;
  readonly projectName: string;
  readonly projectComments: string;
  readonly displayPrint: boolean;
  readonly highLightItemNo: string | undefined;
}

const initialState: State = {
  productId: undefined,
  selectedM3ItemNo: undefined,
  persistedSearchSelections: undefined,
  persistedAddedItems: [],
  searchSelections: undefined,
  addedItems: [],
  ecomProductId: undefined,
  browserLvl1: undefined,
  browserLvl2: undefined,
  searchNotFound: false,
  savedCalculations: [],
  addedAccessories: [],
  addedSearchResultIds: {},
  calculationExists: false,
  projectName: "",
  projectComments: "",
  displayPrint: false,
  highLightItemNo: undefined,
};

export function reducer(state: State = initialState, action: Actions.Action | RehydrateAction): State {
  switch (action.type) {
    case "persist/REHYDRATE": {
      if (action.key === "fricoSpecificationTextEpim") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const persistedSearchSelections = (action.payload as any)?.persistedSearchSelections;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const persistedAddedItems = (action.payload as any)?.persistedAddedItems;
        return {
          ...state,
          persistedSearchSelections: undefined,
          persistedAddedItems: [],
          searchSelections: persistedAddedItems?.length ? persistedSearchSelections : undefined,
          addedItems: persistedAddedItems || [],
        };
      } else {
        return state;
      }
    }
    case "frico-specification-text-epim/SET_SELECTED_PATH":
      return {
        ...state,
        browserLvl1: action.payload.lvl1,
        browserLvl2: action.payload.lvl2,
        productId: action.payload.productId,
        ecomProductId: action.payload.ecomProductId,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SET_SELECTED_VARIANT":
      return {
        ...state,
        selectedM3ItemNo: action.payload,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SEARCH_NOT_FOUND":
      return { ...state, searchNotFound: true, calculationExists: false };
    case "frico-specification-text-epim/ADD_VARIANT":
      const calcParams = state.addedItems.length > 0 ? state.addedItems[0].calcParams : undefined;
      return {
        ...state,
        addedItems: [
          ...state.addedItems.filter((i) => i.m3ItemNo !== action.payload.m3ItemNo),
          { ...action.payload, calcParams: calcParams },
        ],
        calculationExists: false,
        highLightItemNo: action.payload.m3ItemNo,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/REMOVE_VARIANT":
      const newItemsList = state.addedItems.filter((i) => i.m3ItemNo !== action.payload.m3ItemNo);
      const selectedStillExists = newItemsList.find((i) => i.m3ItemNo === state.selectedM3ItemNo);
      const addedAccessories = state.addedAccessories.filter(
        (a) => action.payload.accessoriesToRemove.find((r) => r === a.m3ItemNo) === undefined
      );
      const calculations = state.savedCalculations.filter((c) => c.m3 !== action.payload.m3ItemNo);
      return {
        ...state,
        addedItems: newItemsList,
        selectedM3ItemNo: selectedStillExists !== undefined ? selectedStillExists.m3ItemNo : undefined,
        calculationExists: false,
        searchNotFound: false,
        addedAccessories: addedAccessories,
        savedCalculations: calculations,
      };
    case "frico-specification-text-epim/UPDATE_VARIANT": {
      const updatedItems = state.addedItems.map((item) =>
        item.m3ItemNo === action.payload.m3ItemNo ? action.payload : item
      );
      return {
        ...state,
        addedItems: updatedItems,
        calculationExists: false,
        searchNotFound: false,
      };
    }
    case "frico-specification-text-epim/UPDATE_ALL_CALCPARAMS": {
      const updatedItems = state.addedItems.map((item) => ({ ...item, calcParams: action.payload }));
      return {
        ...state,
        addedItems: updatedItems,
        calculationExists: false,
        searchNotFound: false,
      };
    }
    case "frico-specification-text-epim/CALCULATION_EXISTS":
      return { ...state, calculationExists: true, searchNotFound: false };
    case "frico-specification-text-epim/CLEAR_SAVED_CALCULATIONS":
      return {
        ...state,
        savedCalculations: [],
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SAVE_CALCULATION":
      return {
        ...state,
        savedCalculations: [...state.savedCalculations, action.payload],
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/REMOVE_CALCULATION":
      return {
        ...state,
        savedCalculations: state.savedCalculations.filter((c) => c.id !== action.payload),
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/DISPLAY_PRINT":
      return {
        ...state,
        displayPrint: action.payload,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/RESET":
      return initialState;
    case "frico-specification-text-epim/UPDATE_PROJECT_NAME":
      return {
        ...state,
        calculationExists: false,
        searchNotFound: false,
        projectName: action.payload,
      };
    case "frico-specification-text-epim/UPDATE_PROJECT_COMMENTS":
      return {
        ...state,
        calculationExists: false,
        searchNotFound: false,
        projectComments: action.payload,
      };
    case "frico-specification-text-epim/ADD_PERSISTED_VARIANT": {
      // Clear previously added items
      const searchM3ItemNos = Object.values(state.addedSearchResultIds);
      const clearedPersistedAddedItems = state.persistedAddedItems.filter((i) => searchM3ItemNos.includes(i.m3ItemNo));

      const newAddedSearchResultIds = {
        ...state.addedSearchResultIds,
        [action.payload.searchResultId]: action.payload.item.m3ItemNo,
      };

      if (clearedPersistedAddedItems.some((i) => i.m3ItemNo === action.payload.item.m3ItemNo)) {
        return {
          ...state,
          searchSelections: action.payload.searchSelections,
          addedSearchResultIds: newAddedSearchResultIds,
          calculationExists: false,
          searchNotFound: false,
        };
      } else {
        return {
          ...state,
          persistedSearchSelections: action.payload.searchSelections,
          persistedAddedItems: [
            ...clearedPersistedAddedItems,
            {
              ...action.payload.item,
              sortNo:
                clearedPersistedAddedItems.length === 0
                  ? 0
                  : Math.max(...clearedPersistedAddedItems.map((r) => r.sortNo)) + 1,
              addedFromSearch: true,
            },
          ],
          addedSearchResultIds: newAddedSearchResultIds,
          calculationExists: false,
          searchNotFound: false,
        };
      }
    }
    case "frico-specification-text-epim/REMOVE_PERSISTED_VARIANT": {
      const m3ItemNo = state.addedSearchResultIds[action.payload];
      if (!m3ItemNo) {
        return state;
      }
      const item = state.persistedAddedItems.find((i) => i.m3ItemNo === m3ItemNo);
      if (!item || !item.addedFromSearch) {
        return state;
      }
      const newAddedItems = state.persistedAddedItems.filter((i) => i.m3ItemNo !== m3ItemNo);
      const newAddedSearchResultIds = R.omit([action.payload], state.addedSearchResultIds);
      return {
        ...state,
        persistedAddedItems: newAddedItems,
        addedSearchResultIds: newAddedSearchResultIds,
      };
    }
    case "frico-specification-text-epim/UPDATE_ACCESSORY_QUANTITY":
      const clean = state.addedAccessories.filter((r) => r.m3ItemNo !== action.payload.itemNumber);
      return {
        ...state,
        addedAccessories: [
          ...clean,
          {
            productId: action.payload.productId,
            m3ItemNo: action.payload.itemNumber,
            quantity: action.payload.quantity,
          },
        ],
      };
    case "frico-specification-text-epim/CLEAR_ADDED_SEARCH_RESULT_IDS":
      if (action.payload.setPersistedDataToCurrent) {
        return {
          ...state,
          addedSearchResultIds: {},
          persistedSearchSelections: undefined,
          persistedAddedItems: [],
          searchSelections: state.persistedSearchSelections,
          addedItems: state.persistedAddedItems,
        };
      } else {
        return {
          ...state,
          addedSearchResultIds: {},
        };
      }
    default:
      return state;
  }
}
