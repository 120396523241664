import { PropertyFilter } from "@promaster-sdk/property";
import type { PropertyValueSet } from "@promaster-sdk/property";
import type { AllProductTables, MetaTables, SearchMetaTables } from "../types";

export function preFilter(
  _searchVariant: PropertyValueSet.PropertyValueSet,
  _productId: string,
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  _metaTables: MetaTables,
  _searchMetaTable: SearchMetaTables,
  productTables: AllProductTables
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  const filteredVariants = [];
  for (const variant of variants) {
    if (productTables.ct_AmcaStatements.some((r) => PropertyFilter.isValid(variant, r.property_filter))) {
      filteredVariants.push(variant);
    }
  }
  return filteredVariants;
}
