import { Amount } from "uom";
import { Units } from "uom-units";
import { customUnits } from "shared-lib/uom";
import * as Types from "../types";
import * as Messages from "../messages";
import type { Curve, Fan, FanNominalData } from "../result-items-types";
// Uncomment below to use AMCA calculation
// import * as FanAir from "../fan-calculation-amca/fan-air";
// import * as FanMaxEfficiency from "../fan-calculation-amca/fan-max-efficiency";
// import * as FanSound from "../fan-calculation-amca/fan-sound";
import * as FanAir from "../shared/fan-air";
import * as FanMaxEfficiency from "../shared/fan-max-efficiency";
import * as FanSound from "../shared/fan-sound";
import * as Attributes from "../shared/attributes";
import * as Utils from "../shared/utils";
import type { Input } from "./types";

const source = "SystemairFanCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<Fan>> {
  const {
    airFlow,
    externalPressure,
    speedControl,
    accessories,
    airData,
    airLimitsData,
    soundData,
    attributes,
    calcParams,
    airDensity,
  } = input;

  if (!speedControl) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const nomVoltage = Attributes.getInt("input-voltage-NOM", attributes);
  const nomPower = Attributes.getFloat("input-power-BASE-ALL", attributes);
  const nomCurrent = Attributes.getFloat("input-current", attributes);
  const nomSpeed = Attributes.getFloat("impeller-speed-NOM", attributes);
  const maxAirFlow = Attributes.getFloat("air-flow-RATE-MAX-BASE-ALL", attributes);
  const nomSoundPressure = Attributes.getFloat("sound-press-3m-20m2-sabin", attributes);
  const insulationClass = Attributes.getString("insulation-class-motor", attributes);
  const enclosureClass = Attributes.getString("enclosure-class-motor", attributes);
  const weight = Attributes.getFloat("M3-WEIGHT-NET-METRIC", attributes);

  const nominalData: FanNominalData = {
    voltage: Utils.maybeAmount(nomVoltage, customUnits.Volt, 0),
    power: Utils.maybeAmount(nomPower, customUnits.Watt, 1),
    current: Utils.maybeAmount(nomCurrent, customUnits.Ampere, 3),
    speed: Utils.maybeAmount(nomSpeed, customUnits.RevolutionsPerMinute, 0),
    maxAirFlow: Utils.maybeAmount(maxAirFlow, customUnits.CubicMeterPerHour, 0),
    soundPressure: Utils.maybeAmount(nomSoundPressure, customUnits.Decibel, 1),
    insulationClass: insulationClass,
    enclosureClass: enclosureClass,
    weight: Utils.maybeAmount(weight, customUnits.Kilogram, 2),
  };

  const messages: Array<Messages.Message> = [];

  const maxEfficiency =
    !airFlow && !externalPressure ? FanMaxEfficiency.findMaxEfficiencyWorkingPoint(airData) : undefined;
  const desiredAirFlow = maxEfficiency?.airFlow ?? airFlow;
  const desiredPressure = maxEfficiency?.pressure ?? externalPressure;

  const accessoryPressureCurves = accessories.map((a) => a.pressureCurve);
  const pressureCurves = accessoryPressureCurves.filter((a) => a) as Array<Curve>;

  const fan = {
    ...FanAir.calculate(
      speedControl,
      airData,
      airLimitsData,
      desiredAirFlow,
      desiredPressure,
      pressureCurves,
      airDensity,
      undefined,
      false,
      maxEfficiency !== undefined
    ),
    minAirFlow: Amount.create(0, Units.LiterPerSecond, 1), //makes Charts fan-diagram x-axis start from origo, 0
  };
  //console.log(fan)

  if (!airFlow) {
    messages.push(Messages.Error_MissingInput(source, "airFlow"));
  }
  if (!externalPressure) {
    messages.push(Messages.Error_MissingInput(source, "externalPressure"));
  }

  if (!fan.workingPoint) {
    messages.push(Messages.Error_OutsideValidRange(source));
    return Types.createCalculatorSuccess(
      [
        {
          value:
            fan.distanceWorkingPointToDesiredPoint !== undefined ? fan.distanceWorkingPointToDesiredPoint : Infinity,
          descending: false,
        },
        { value: 1 - ((fan.efficiency && Amount.valueAs(customUnits.One, fan.efficiency)) || 1), descending: false },
      ],
      {
        air: fan,
        fanEfficiencyIndex: undefined,
        inletSound: undefined,
        inletWithSilencerSound: undefined,
        outletSound: undefined,
        outletWithSilencerSound: undefined,
        surroundingSound: undefined,
        nominal: nominalData,
        accessories: accessories.map((a) => ({ id: a.id, pressureDrop: a.pressureDrop })),
      },
      messages,
      calcParams
    );
  }

  if (fan.airFlow !== undefined && fan.desiredAirFlow !== undefined && fan.desiredPointIsOutsideValidArea) {
    messages.push(Messages.Warning_PointAdjustedToClosestValid(source));
  }

  const inletSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Inlet",
    undefined
  );
  const inletSilencer = accessories.find((a) => a.type === "Silencer" && a.silencerType === "InletSilencer");
  const inletWithSilencerSound =
    inletSilencer && FanSound.applyAttenuation(inletSound.octaveBands, inletSilencer.soundAttenuation);
  const outletSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Outlet",
    undefined
  );
  const outletSilencer = accessories.find((a) => a.type === "Silencer" && a.silencerType === "OutletSilencer");
  const outletWithSilencerSound =
    outletSilencer && FanSound.applyAttenuation(outletSound.octaveBands, outletSilencer.soundAttenuation);
  const surroundingSound = FanSound.calcSound(
    speedControl,
    desiredAirFlow,
    desiredPressure,
    airDensity,
    fan,
    soundData,
    "Surrounding",
    undefined
  );

  if (accessories.length > 0 && fan.airFlow && fan.desiredAirFlow && !Amount.equals(fan.airFlow, fan.desiredAirFlow)) {
    messages.push(Messages.Warning_AccessoriesUsingRequiredAirFlow(source));
  }

  return Types.createCalculatorSuccess(
    [
      {
        value: fan.distanceWorkingPointToDesiredPoint !== undefined ? fan.distanceWorkingPointToDesiredPoint : Infinity,
        descending: false,
      },
      { value: 1 - ((fan.efficiency && Amount.valueAs(customUnits.One, fan.efficiency)) || 1), descending: false },
    ],
    {
      air: fan,
      fanEfficiencyIndex: undefined,
      inletSound: inletSound.octaveBands3rd,
      inletWithSilencerSound: inletWithSilencerSound,
      outletSound: outletSound.octaveBands3rd,
      outletWithSilencerSound: outletWithSilencerSound,
      surroundingSound: surroundingSound.octaveBands3rd,
      nominal: nominalData,
      accessories: accessories.map((a) => ({ id: a.id, pressureDrop: a.pressureDrop })),
    },
    messages,
    calcParams
  );
}
