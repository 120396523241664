export interface Map<T> {
  readonly [key: string]: T;
}

export function makeMap<T, X>(
  items: ReadonlyArray<T>,
  keySelector: (i: T) => string,
  valueSelector: (i: T) => X
  // eslint-disable-next-line functional/prefer-readonly-type
): Map<X> {
  const map: { [key: string]: X } = {};
  for (const item of items) {
    map[keySelector(item)] = valueSelector(item);
  }
  return map;
}
