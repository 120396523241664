/* eslint-disable functional/no-this-expression */
/* eslint-disable class-methods-use-this */
import * as React from "react";
import * as R from "ramda";
import type { PropertyValueSet } from "@promaster-sdk/property";
import { PropertyFilter } from "@promaster-sdk/property";
import type { DispatchProp } from "client-lib/redux-integration";
import type * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import * as PU from "shared-lib/product-utils";
import * as PC from "shared-lib/product-codes";
import { roundTo } from "shared-lib/utils";
import { PositiveNumberfield, LinkButton, withTw } from "client-lib/elements";
import type { TranslateFunction } from "shared-lib/language-texts";
import * as ImageService from "client-lib/image-service";
import type { ProductDescriptionAndTechnicalParameters } from "shared-lib/ecom-api";
import type { State } from "./reducer";
import * as Actions from "./actions";

export interface Item {
  readonly sortNo: number;
  readonly itemName: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
  readonly quantity: number;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
}

export interface AccessoryItem {
  readonly m3ItemNo: string;
  readonly quantity: number;
}

interface ProductDescriptionAndTechParam {
  readonly [variantNo: string]: ProductDescriptionAndTechnicalParameters;
}

export type Props = OwnProps & StateProps & Response & DispatchProp<Actions.Action>;

export interface Response {
  readonly itemNoToProductId: QP.ItemNoToProductId;
  readonly tablesForProducts: TablesForProducts;
  readonly productDescriptions: ProductDescriptionAndTechParam;
  readonly marketTables: PU.MarketTablesResponse;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly accessoryTables: TablesForProducts;
  readonly ct_SearchProducts: QP.SearchProductsTable;
}

export interface StateProps {
  readonly state: State;
}

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly ecomUrl: string;
  readonly specificationDataUrl: string;
  readonly translate: TranslateFunction;
  readonly addedAccessories: ReadonlyArray<AccessoryItem>;
  readonly addedProducts: ReadonlyArray<Item>;
  readonly updateQuantity: (
    productId: string,
    itemNumber: string,
    quantity: number,
    category: number | undefined
  ) => void;
}
interface TablesForProducts {
  readonly [id: string]: AllProductTables;
}

interface AllProductTables {
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_Attributes2: QP.AttributesTable;
}

// TODO: Fix frico styling when frico is using DMS styling
const AccCategory = withTw("div", "");
const AccCategoryDesc = withTw("div", "text-neutral-600 font-normal");
const AccessoryImage = withTw("img", "");
const PlaceholderImage = withTw("div", "");
const AccessoryImageContainer = withTw("div", "");

export function FricoAccessoriesTableContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, addedProducts, addedAccessories, updateQuantity } = props;

  const accessories = PU.getFricoIncludedAccessoriesEpim({
    products: addedProducts,
    response: {
      tablesForProducts: props.tablesForProducts,
      tablesForAccessories: props.accessoryTables,
      marketTables: props.marketTables,
    },
    addedAccessories: props.addedAccessories,
  });

  const quantities = PU.getFricoAccessoryQuantitiesEpimImpl(
    addedProducts,
    {
      tablesForProducts: props.tablesForProducts,
      tablesForAccessories: props.accessoryTables,
      marketTables: props.marketTables,
    },
    accessories || [],
    addedAccessories
  );

  React.useEffect(() => {
    if (addedAccessories.length === 0) {
      const controlDefault = accessories?.find((controls) => controls.category === 0);
      const valveDefault = accessories?.find((valves) => valves.category === 1);

      if (controlDefault) {
        updateQuantity(
          controlDefault.productId,
          controlDefault.itemNo,
          controlDefault.defaultQuantity || 1,
          controlDefault.category
        );
      }

      if (valveDefault) {
        updateQuantity(
          valveDefault.productId,
          valveDefault.itemNo,
          valveDefault.defaultQuantity || 1,
          valveDefault.category
        );
      }
    }
  }, [addedAccessories, accessories]);

  if (addedProducts.length === 0 || accessories === undefined || accessories.length === 0) {
    return <span />;
  }

  const categories = R.uniq(accessories.map((r) => r.category)).sort((a, b) => a - b);
  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th key="name">{translate(Texts.accessory_name())}</th>
              <th key="m3ItemNo">{translate(Texts.accessory_no())}</th>
              <th key="qty">{translate(Texts.qty())}</th>
            </tr>
          </thead>
          {categories.map((c) => renderCategory(accessories, c, translate, quantities, props))}
        </table>
      </div>
    </div>
  );
}

function renderCategory(
  accessories: ReadonlyArray<PU.FricoAccessory>,
  category: number,
  translate: TranslateFunction,
  quantities: { readonly [itemNo: string]: number },
  props: Props
): ReadonlyArray<React.ReactElement> {
  const catItems = accessories.filter((r) => r.category === category).sort((a, b) => (a.itemNo > b.itemNo ? 1 : -1));
  const catDesc = translate(Texts.specification_category_description(category), "");
  return [
    <thead key={`thead_${category}`}>
      <tr>
        <th key="name">
          <AccCategory>{translate(Texts.specification_category(category))}</AccCategory>
          {catDesc ? <AccCategoryDesc>{catDesc}</AccCategoryDesc> : undefined}
        </th>
        <th />
        <th />
      </tr>
    </thead>,
    <tbody key={`tbody_${category}`}>
      {catItems.map((row, i) => {
        const isOpen = props.state.openDescriptions.some((r) => r === row.itemNo);

        return [
          <tr key={`accrow_${i}`}>{renderAccessoryRow(row, props, category, quantities[row.itemNo])}</tr>,
          isOpen ? (
            <tr key={`spectext_${i}`}>
              <td colSpan={3} className="border-none">
                <div className="flex">
                  <AccessoryImageContainer>
                    <AccessoryImage src={ImageService.itemNumberToServiceUrl(row.itemNo, undefined, 400, 400)} />
                    <PlaceholderImage>
                      <i
                        className={"fa fa-image fa-2xl"}
                        aria-hidden="true"
                        style={{ fontSize: "34px", color: "lightgray" }}
                      />
                    </PlaceholderImage>
                  </AccessoryImageContainer>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.productDescriptions[row.variantId].description!.product_specification_text,
                    }}
                  />
                </div>
              </td>
            </tr>
          ) : null,
        ];
      })}
    </tbody>,
  ];
}

function renderAccessoryRow(
  row: PU.FricoAccessory,
  props: Props,
  category: number,
  quantity: number | undefined
): React.ReactElement | null {
  const { dispatch, state, productDescriptions, translate, updateQuantity } = props;

  const isOpen = state.openDescriptions.some((r) => r === row.itemNo);

  return (
    <>
      <td>
        {translate(Texts.item_name(row.productId, row.accVariant))}
        {getAccessoryRangeText(row, props)}
        {productDescriptions[row.variantId]?.description?.product_specification_text ? (
          <div>
            <LinkButton
              onClick={() =>
                isOpen ? dispatch(Actions.closeDescription(row.itemNo)) : dispatch(Actions.openDescription(row.itemNo))
              }
            >
              {isOpen ? translate(Texts.hide_description()) : translate(Texts.show_description())}
            </LinkButton>
          </div>
        ) : (
          ""
        )}
      </td>

      <td>{row.itemNo}</td>

      <td>
        <PositiveNumberfield
          value={quantity ?? (row.defaultQuantity || 0)}
          onChange={(v) => updateQuantity(row.productId, row.itemNo, v, category === 0 ? category : undefined)}
        />
      </td>
    </>
  );
}

function getAccessoryRangeText(row: PU.FricoAccessory, props: Props): string {
  const accessoryTable = props.accessoryTables[row.productId];
  const m3Variant = PC.getVariantFromM3ItemNo(accessoryTable, row.itemNo, row.variantId);

  const attributes =
    m3Variant &&
    accessoryTable.ct_Attributes2.filter((attribute) => {
      return PropertyFilter.isValid(m3Variant, attribute.property_filter);
    });

  const flowMin = attributes?.find((a) => a.attribute === "water-flow-range-MIN-BASE-ALL");
  const flowMax = attributes?.find((a) => a.attribute === "water-flow-range-MAX-BASE-ALL");
  const pressureMin = attributes?.find((a) => a.attribute === "water-pressure-range-MIN-BASE-ALL");
  const pressureMax = attributes?.find((a) => a.attribute === "water-pressure-range-MAX-BASE-ALL");

  if (!flowMin || !flowMax || !pressureMin || !pressureMax) {
    return "";
  }

  const flowMinVal = roundTo(parseFloat(flowMin.value) * 0.277778, 3); //converting from m3/h to l/s 0.277778
  const flowMaxVal = roundTo(parseFloat(flowMax.value) * 0.277778, 3); //converting from m3/h to l/s 0.277778
  const pressureMinVal = roundTo(parseFloat(pressureMin.value) / 1000, 0); // Converting from Pa to kPa
  const pressureMaxVal = roundTo(parseFloat(pressureMax.value) / 1000, 0); // Converting from Pa to kPa

  return ` (${flowMinVal}-${flowMaxVal} l/s, ${pressureMinVal}-${pressureMaxVal} kPa)`;
}
