import type * as QP from "shared-lib/query-product";
import * as Attributes from "../shared/attributes";

export function match(
  _itemNumber: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  return (
    Attributes.matchAttribute("PROP-heat-type-MULTI", searchAttributes, variantAttributes) &&
    // Maps to property Mounting method. Horizontal/Vertical
    Attributes.matchAttribute("td-PROP-installation-type-MULTI", searchAttributes, variantAttributes) &&
    // Maps property BuildingType = Cold storage
    Attributes.matchAttribute("td-PROP-cold-storage", searchAttributes, variantAttributes) &&
    // Maps to property Mounting
    Attributes.matchAttribute("inst-place-air-curtain-standard", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("inst-place-air-curtain-recessed", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("inst-place-air-curtainblow-from-below", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("inst-place-air-curtain-revolving-door", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-frico-selection-control", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-frico-selection-version", searchAttributes, variantAttributes)
  );
}
