import { createSelector } from "reselect";
import type { RootState } from "../../types";
import type { ComparedProduct } from "./types";
//import { getComparedProductFromCookie } from "./cookie";

export const stateSelector = createSelector(
  (s: RootState) => s.compare,
  (s) => s
);

export function selectComparedProduct(s: RootState): ComparedProduct | undefined {
  const state = stateSelector(s);
  //  if (state.comparedProduct) {
  return state.comparedProduct;
  //  } else {
  //    return getComparedProductFromCookie();
  //  }
}
