import type { Selector } from "reselect";
import { createSelector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import * as PU from "shared-lib/product-utils";
import * as QP from "shared-lib/query-product";
import * as UserSettings from "client-lib/user-settings";
import * as Compare from "client-lib/compare";
import type { Response, OwnProps, StateProps, ProductTables } from "./types";
import type { RootState } from "../../types";
import { AccessoriesTable } from "./container-component";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.product,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (s: RootState) => s.external,
  Compare.selectComparedProduct,
  (state, showAdvanced, userSettings, externalState, comparedProduct) => ({
    state,
    showAdvanced,
    userSettings,
    externalState,
    comparedProduct,
  })
);

function mapPropsToQuery(props: OwnProps & StateProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productId: QP.productIdByM3ItemNo(props.m3ItemNo, props.productId),
    marketTables: PU.createMarketTablesQuery(props.ecomUrl, props.market),
    accessoryTables: accessoryTablesQuery(response),
    productTables: response && productTablesQuery(props, response),
  });
}

function productTablesQuery(ownProps: OwnProps, response: Response): QD.DiaqMapQuery<ProductTables> {
  return QD.createMapQuery<ProductTables>({
    property: QP.tableByProductId(response.productId, "property"),
    code: QP.tableByProductId(response.productId, "code"),
    ct_ItemNo: QP.tableByProductId(response.productId, "ct_ItemNo"),
    ct_Accessories: QP.tableByProductId(response.productId, "ct_Accessories"),
    ct_VariantNo: QP.tableByProductId(response.productId, "ct_VariantNo"),
    ct_Attributes2: QP.tableFromMtOrByProductId(response.productId, ownProps.variant, "ct_Attributes2"),
  });
}

function accessoryTablesQuery(
  response: Response | undefined
): QD.DiaqMapQuery<Response["accessoryTables"]> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_DiaqTemplates",
      "ct_CalcParamDefault",
      "ct_Accessories",
      "ct_Attributes2",
      "ct_VariantNo",
    ]);
  }
  return QD.createMapQuery(map);
}

export const AccessoriesTableContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(AccessoriesTable);
