import type * as QP from "shared-lib/query-product";

const amcaVersion = "1.0.0";

export const acceptableAmcaSearchProducts = ["GANYMED_SEARCH_FAN", "GANYMED_SEARCH_CENTRIFUGAL_FAN"];

const acceptableMarkets = ["330"];

export interface Response {
  readonly products: QP.Products;
  readonly searchProductTables: {
    readonly [productId: string]: {
      readonly ct_SearchProducts: QP.SearchProductsTable;
    };
  };
}

export function getAmcaVersion(market: string, productKey: string, tables: Response): string | undefined {
  if (!acceptableMarkets.includes(market)) {
    return undefined;
  }

  const productIds = acceptableAmcaSearchProducts.map((p) => tables.products[p].id);

  if (
    acceptableAmcaSearchProducts.includes(productKey) ||
    productIds.some((p) =>
      tables.searchProductTables[p].ct_SearchProducts.some((s) => tables.products[productKey].id === s.product)
    )
  ) {
    return amcaVersion;
  }
  return undefined;
}
