import * as R from "ramda";
import type * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import { Amount, Serialize } from "uom";
import * as ProductUtils from "shared-lib/product-utils";
import { CustomUnitsLookup } from "shared-lib/uom";
// import * as Old from "shared-lib/product-codes";

export interface ProductCodes {
  readonly code: string;
  readonly itemNo: string;
  readonly variantId: string | undefined;
  readonly variant: string | undefined;
}

const amountRegex = new RegExp("{([a-z0-9_]+):([A-Za-z]+):([0-9]+)}", "g");

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export function getProductCodes(
  productTables: ProductTables,
  propertyValueSet: PropertyValueSet.PropertyValueSet
): ProductCodes {
  const codeParts = productTables.code;
  const matchingCodeParts = codeParts.filter((c) => PropertyFilter.isValid(propertyValueSet, c.property_filter));
  const codes = R.groupBy((c) => c.type, matchingCodeParts);
  const finalCodes = R.keys(codes).map((t) => {
    const finalCode: string = PropertyValueSet.getPropertyNames(propertyValueSet).reduce((c, p) => {
      const value: PropertyValue.PropertyValue | undefined = PropertyValueSet.get(p, propertyValueSet);
      if (value === undefined) {
        return c;
      }
      switch (value.type) {
        case "integer":
          // eslint-disable-next-line no-useless-escape
          return c.replace(new RegExp(`\{${p}\}`, "g"), value.value.toString());
        case "text":
          // eslint-disable-next-line no-useless-escape
          return c.replace(new RegExp(`\{${p}\}`, "g"), value.value);
        case "amount":
          return c.replace(amountRegex, (match: string, p1: string, p2: string, p3: string): string => {
            const amount = PropertyValue.getAmount(value);
            const property = p1;
            const unit = Serialize.stringToUnit(p2, CustomUnitsLookup);
            const decimals = parseInt(p3, 10);
            // eslint-disable-next-line no-restricted-globals
            if (amount && property === p && unit && !isNaN(decimals) && unit.quantity === amount.unit.quantity) {
              return Amount.valueAs(unit, amount).toFixed(decimals);
            } else {
              return match;
            }
          });
        default:
          return c;
      }
    }, codes[t].map((code) => code.code).join(""));

    return {
      type: t,
      code: finalCode,
    };
  });
  const mainCode = finalCodes.find((c) => c.type === "main");
  const code = mainCode ? mainCode.code : "";

  const m3ItemNo = getItemNo(productTables.ct_ItemNo, propertyValueSet);
  const variant = getVariant(productTables.property, propertyValueSet);
  const variantId = getVariantId(productTables.ct_VariantNo, propertyValueSet);

  return {
    code: code,
    itemNo: m3ItemNo ?? "",
    variant: variant,
    variantId: variantId,
  };
}

function getItemNo(
  itemNoTable: QP.ItemNoTable,
  propertyValueSet: PropertyValueSet.PropertyValueSet
): string | undefined {
  const tableItemNo = itemNoTable.find((r) => PropertyFilter.isValid(propertyValueSet, r.property_filter));
  if (tableItemNo) {
    return tableItemNo.item_no;
  }

  const itemNo = PropertyValueSet.getInteger("model", propertyValueSet);
  return itemNoTable.length > 0 && itemNo !== undefined ? itemNo.toString() : undefined;
}

function getVariantId(
  variantNoTable: QP.VariantNoTable,
  propertyValueSet: PropertyValueSet.PropertyValueSet
): string | undefined {
  const tableVariantNo = variantNoTable?.find((r) => PropertyFilter.isValid(propertyValueSet, r.property_filter));
  if (tableVariantNo) {
    return tableVariantNo.variant_no;
  }

  const variantNo = PropertyValueSet.getInteger("variant", propertyValueSet);
  return variantNoTable && variantNoTable.length > 0 && variantNo !== undefined ? variantNo.toString() : undefined;
  // const variantNo = variantNoTable.find(r => PropertyFilter.isValid(propertyValueSet, r.property_filter));
  // return variantNo ? variantNo.variant_no : undefined;
}

function getVariant(
  property: QP.PropertyTable,
  propertyValueSet: PropertyValueSet.PropertyValueSet
): string | undefined {
  const variantValue = PropertyValueSet.getValue("variant", propertyValueSet);
  const variantProperty = property.find((p) => p.name === "variant");
  if (!variantValue || !variantProperty) {
    return undefined;
  }
  const variantPropertyValue = variantProperty.value.find((v) => PropertyValue.equals(v.value, variantValue));
  return variantPropertyValue ? variantPropertyValue.description : undefined;
}

export function getMainCodeProperties(codeTable: QP.CodeTable): ReadonlyArray<string> {
  return R.uniq(
    R.unnest<string>(
      codeTable
        .filter((c) => c.type === "main" || c.type === "complete")
        .map((c) => PropertyFilter.getReferencedProperties(c.property_filter))
    )
  );
}

export function getVariantFromM3ItemNo(
  productTables: ProductTables,
  m3ItemNo: string,
  variantId: string | undefined
): PropertyValueSet.PropertyValueSet | undefined {
  const properties = R.uniq(
    R.unnest<string>([
      ...productTables.code.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
      ...productTables.ct_ItemNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
      ...productTables.ct_VariantNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter)),
    ])
  );
  const variants = ProductUtils.generateVariantsForProperties(
    properties,
    PropertyValueSet.Empty,
    productTables.property
  );

  for (const variant of variants) {
    const codes = getProductCodes(productTables, variant);
    if (codes.itemNo === m3ItemNo && (!variantId || codes.variantId === variantId)) {
      return variant;
    }
  }

  // for (const variant of variants) {
  //   const codes = Old.getProductCodes(productTables, variant);
  //   const main = codes.find(c => c.type === "main");
  //   if (main && main.m3s.find(m3 => m3 === m3ItemNo)) {
  //     return variant;
  //   }
  // }

  console.warn("Could not find M3 item no " + m3ItemNo + " and variant " + variantId);
  return undefined;
}

export function getItemNoPropertyNames(itemNo: QP.ItemNoTable, variantNo: QP.VariantNoTable): ReadonlyArray<string> {
  return R.uniq<string>([
    ...R.unnest<string>(itemNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter))),
    ...R.unnest<string>(variantNo.map((r) => PropertyFilter.getReferencedProperties(r.property_filter))),
  ]);
}
