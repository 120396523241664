import type { State } from "./types";
import type { Action } from "./actions";

const initialState: State = { comparedProduct: undefined };

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "compare/ADD_PRODUCT":
      return {
        ...state,
        comparedProduct: {
          ...action.payload,
        },
      };

    case "compare/REMOVE_PRODUCT":
      return {
        ...state,
        comparedProduct: undefined,
      };

    default:
      return state;
  }
}
