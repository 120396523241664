import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import type { RootState } from "types";
import { VersionInfoContainerComponent } from "./container-component";
import type { OwnProps } from "./types";

const mapStateToProps = (_: RootState, __: OwnProps): unknown => ({});

function mapPropsToQuery(_: OwnProps, __: unknown): QD.DiaqMapQuery<unknown> {
  return QD.createMapQuery({});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VersionInfoContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(VersionInfoContainerComponent as any);
