import type { AnyQuantity } from "shared-lib/uom";
import type * as UserSettingsShared from "shared-lib/user-settings";
import { Amount } from "uom";
import type { FormatNumberFunction } from "shared-lib/utils";

export interface RenderAmountProps {
  readonly formatNumber: FormatNumberFunction;
  readonly getUnit: UserSettingsShared.GetUnitFunction;
  readonly getDecimals: UserSettingsShared.GetDecimalsFunction;
}

export function renderAmount(
  amount: Amount.Amount<AnyQuantity> | undefined,
  fieldName: string,
  props: RenderAmountProps
): string {
  if (!amount) {
    return "-";
  }

  const { getUnit, getDecimals, formatNumber } = props;
  const unit = getUnit(fieldName, amount.unit.quantity);
  const decimals = getDecimals(fieldName, unit);
  const result = Amount.valueAs(unit, amount);
  return result !== undefined && !Number.isNaN(result) ? formatNumber.format(result.toFixed(decimals)) : "-";
}
