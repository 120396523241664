/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ReduxQuery from "shared-lib/redux-query/core";
import * as QP from "shared-lib/query-product";
import * as QU from "shared-lib/query-url";
import * as QI from "shared-lib/query-identity";
import * as QECOM from "shared-lib/query-ecom";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import type { DiaqCacheState, DiaqMapQuery, DiaqAtomicQuery, DiaqAtomicResponse } from "./query-types";

// Re-export this function so the caller don't need to import redux-query
export { allowUndefined } from "shared-lib/redux-query/react";

const initialDiaqCacheState: DiaqCacheState = {
  product: undefined,
  url: undefined,
  ecom: undefined,
};

/**
 * Sends the selectQuery call to the correct source.
 */
export function selectAtomicQuery(
  cache: DiaqCacheState = initialDiaqCacheState,
  query: DiaqAtomicQuery
): ReduxQuery.AnyResponse | undefined {
  switch (query.source) {
    case "product":
      return QP.selectQuery(cache.product, query);
    case "url":
      return QU.selectQuery(cache.url, query);
    case "identity":
      return QI.selectQuery(undefined, query);
    case "ecom":
      return QECOM.selectQuery(cache.ecom, query);
    default:
      return exhaustiveCheck(query, true);
  }
}

export function createLoadQueries(
  pimAddress: string,
  markerName: string,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): ReduxQuery.LoadAtomicQueries<DiaqCacheState, DiaqAtomicQuery, ReadonlyArray<Promise<DiaqCacheUpdate>>> {
  return (cache: DiaqCacheState | undefined, queries: ReadonlyArray<DiaqAtomicQuery>) =>
    loadAtomicQueries(pimAddress, markerName, useMeasureToolForMeasurements, mtUrl, cache, queries);
}

export type DiaqCacheUpdate = (cache: DiaqCacheState) => DiaqCacheState;

async function loadAtomicQueries(
  pimAddress: string,
  markerName: string,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined,
  cache: DiaqCacheState = initialDiaqCacheState,
  queries: ReadonlyArray<DiaqAtomicQuery>
): Promise<ReadonlyArray<Promise<DiaqCacheUpdate>>> {
  const bySource = {
    product: queries.filter((q) => q.source === "product") as ReadonlyArray<QP.ProductQuery>,
    url: queries.filter((q) => q.source === "url") as ReadonlyArray<QU.UrlQuery>,
    ecom: queries.filter((q) => q.source === "ecom") as ReadonlyArray<QECOM.EcomQuery>,
  };

  const product = await QP.loadQueries(
    pimAddress,
    markerName,
    cache.product,
    bySource.product,
    useMeasureToolForMeasurements,
    mtUrl
  );
  const url = QU.loadQueries(cache.url, bySource.url);
  const ecom = QECOM.loadQueries(cache.ecom, bySource.ecom);

  const wrappedProduct: ReadonlyArray<Promise<DiaqCacheUpdate>> = product.map((p) =>
    p.then((f) => (newCache: DiaqCacheState) => {
      const updatedProductCache = f(newCache.product || QP.initialCache);
      return {
        ...newCache,
        product: updatedProductCache,
      };
    })
  );
  const wrappedUrl: Promise<DiaqCacheUpdate> = url.then((f) => (newCache: DiaqCacheState) => ({
    ...newCache,
    url: f(newCache.url || QU.initialCache),
  }));

  const wrappedEcom: Promise<DiaqCacheUpdate> = ecom.then((f) => (newCache: DiaqCacheState) => ({
    ...newCache,
    ecom: f(newCache.ecom || QECOM.initialCache),
  }));

  return [...wrappedProduct, wrappedUrl, wrappedEcom];
}

export function createMapQuery<TComposedResponse>(
  map: ReduxQuery.MapOfQueriesWithSameKeysAsResponse<DiaqAtomicQuery, DiaqAtomicResponse, TComposedResponse>
): DiaqMapQuery<TComposedResponse> {
  return ReduxQuery.createMapQuery<DiaqAtomicQuery, DiaqAtomicResponse, TComposedResponse>(map);
}
