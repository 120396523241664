import type { Action } from "./actions";
import type { State } from "./types";

const initialState: State = { adding: false, buttonId: "", status: undefined };

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "external/ADD_ITEMS":
      return {
        ...state,
        buttonId: action.payload.buttonId,
        adding: true,
        status: undefined,
      };

    case "external/ITEMS_ADDED":
      return {
        ...state,
        adding: false,
        status: action.payload,
      };

    case "external/DISMISS_ERROR":
      return {
        ...state,
        buttonId: "",
        status: undefined,
      };

    default:
      return state;
  }
}
