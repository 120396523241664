/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as Redux from "redux";
import type { Action } from "./actions";
import type { AnyQueryStoreState, AnyCacheState } from "./types";

const initialState: AnyQueryStoreState = {
  queudQueries: [],
  currentlyLoadingQueries: [],
  cache: undefined,
};

export function createQueryReducer(): Redux.Reducer<AnyQueryStoreState> {
  return function queryReducer(state: AnyQueryStoreState = initialState, action: Action): AnyQueryStoreState {
    switch (action.type) {
      case "redux-query/QUEUE_QUERIES":
        return {
          ...state,
          queudQueries: [...state.queudQueries, ...action.queries],
        };
      case "redux-query/LOAD_STARTED":
        return {
          ...state,
          queudQueries: [],
          currentlyLoadingQueries: state.queudQueries, // TODO: append new queries instead of overriting currentlyLoadingQueries
        };
      case "redux-query/UPDATE_CACHE": {
        return {
          ...state,
          cache: action.cache,
        };
      }
      case "redux-query/LOAD_COMPLETED":
        return {
          ...state,
          cache: action.cache,
          currentlyLoadingQueries: [],
        };
      case "redux-query/CLEAR_CACHE":
        if (action.paths && action.paths.length > 0) {
          const clearedCache = clearCache(state.cache, action.paths);
          return {
            ...state,
            cache: clearedCache,
          };
        }
        return {
          ...state,
          cache: initialState.cache,
        };
      default:
        return state;
    }
  };
}

// eslint-disable-next-line functional/prefer-readonly-type
function clearCache(partialCache: { [key: string]: any }, paths: ReadonlyArray<string>): AnyCacheState {
  const key = paths[0];
  if (!key) {
    return undefined;
  }

  return {
    ...partialCache,
    [key]: partialCache[key] ? clearCache(partialCache[key], paths.slice(1)) : undefined,
  };
}
