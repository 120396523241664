import type * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as Attributes from "../shared/attributes";
import type * as ResultTypes from "../result-items-types";
import { getUserSpeedControl } from "./input-mapper";
import { isBathroomFanSearch } from "./bathroom-fan";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  if (Attributes.getIntOrDefault("export-promaster-fantech-fan-selector", variantAttributes, 0) !== 1) {
    return false;
  }

  if (isBathroomFanSearch(searchVariant)) {
    return true;
  }

  return (
    Attributes.matchCollection("CL-fantech-fan-sele-app-COL", searchAttributes, variantAttributes) &&
    Attributes.matchInputFrequency(searchAttributes, variantAttributes) &&
    // variantAttributes.byCollection["ErP-ready-COL"] === undefined &&
    matchMaxAirTemperature(variantAttributes, searchVariant, calcParams) &&
    Attributes.matchAttribute("enclosure-class-motor", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("insulation-class-motor", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("input-voltage-NOM", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("input-phases", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-product-type-fan", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-motor-type", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("td-PROP-fan-sound-insulated", searchAttributes, variantAttributes)
  );
}

export function matchMaxAirTemperature(
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  const maxTemp = PropertyValueSet.getAmount<Quantity.Temperature>("maxtemp", searchVariant);
  if (!maxTemp) {
    return true;
  }
  const maxTempC = Amount.valueAs(Units.Celsius, maxTemp);
  const speedControl = getSearchSpeedControl(getUserSpeedControl(calcParams), variantAttributes);
  const airTempTransNormalCondition = Attributes.getFloat(
    "air-temp-trans-normal-condition-MAX-BASE-ALL",
    variantAttributes
  );
  const airTempTransSpeedControlled = Attributes.getFloat(
    "air-temp-trans-speed-controlled-MAX-BASE-ALL",
    variantAttributes
  );
  if (speedControl === "Transformer" || speedControl === "Stepless") {
    const foundMatch = airTempTransSpeedControlled !== undefined && maxTempC <= airTempTransSpeedControlled;
    if (!foundMatch) {
      console.log({ speedControl, maxTempC, airTempTransSpeedControlled });
    }
    return foundMatch;
  } else {
    const foundMatch = airTempTransNormalCondition !== undefined && maxTempC <= airTempTransNormalCondition;
    if (!foundMatch) {
      console.log({ speedControl, maxTempC, airTempTransNormalCondition });
    }
    return foundMatch;
  }
}

export function getSearchSpeedControl(
  userSpeedControl: ResultTypes.SpeedControl | undefined,
  attributes: Attributes.Attributes
): ResultTypes.SpeedControl | undefined {
  if (userSpeedControl) {
    return Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, userSpeedControl)
      ? userSpeedControl
      : undefined;
    // return Attributes.getInt(userSpeedControl, attributes) === 1 ? userSpeedControl : undefined;
  } else if (Attributes.matchesAttribute("CL-motor-type", attributes, "EC")) {
    return "Stepless";
  } else if (Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Transformer")) {
    return "Transformer";
  } else if (Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "None")) {
    return "None";
  } else if (Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Stepless")) {
    return "Stepless";
  }
  return undefined;
}
