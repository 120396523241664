import type * as QP from "shared-lib/query-product";
import type * as SC from "shared-lib/system-calculator";
import type { MetaTables, TableResultValue } from "./types";
import { getResultItemValue } from "./table-value";

export interface MultiColumnTableResultRow {
  readonly item: QP.ResultVisualizerParamsTable;
  readonly allValues: ReadonlyArray<ReadonlyArray<TableResultValue>>;
}

export function getMultiColumnRows(
  market: string,
  metaTables: MetaTables,
  visualizerParams: string,
  resultItemMaps: ReadonlyArray<SC.ResultItemMap>
): ReadonlyArray<MultiColumnTableResultRow> {
  const { ct_ResultVisualizerParamsTable, ct_MarketHiddenFields } = metaTables;
  const params = visualizerParams.split(";");
  const rows = ct_ResultVisualizerParamsTable.filter((r) => r.table_name === params[1]);
  const hiddenFieldsInMarket = ct_MarketHiddenFields.filter((r) => r.market === market).map((r) => r.field_name);
  const results = rows
    .filter((r) => hiddenFieldsInMarket.find((h) => h === r.label) === undefined)
    .map((item) => {
      const paths = item.result_value_path.split(",");
      return {
        item: item,
        allValues: resultItemMaps.map((resultItemMap) => paths.map((c) => getResultItemValue(c, resultItemMap))),
      };
    })
    .filter((r) => r.allValues.some((values) => values.some((v) => v !== undefined)));
  return results;
}
