export type Action = ClearSelection | SetSearchTerm;

export interface ClearSelection {
  readonly type: "frico-product-browser-epim/CLEAR_SELECTION";
}

export function clearSelection(): ClearSelection {
  return {
    type: "frico-product-browser-epim/CLEAR_SELECTION",
  };
}

export interface SetSearchTerm {
  readonly type: "frico-product-browser-epim/SET_SEARCH_TERM";
  readonly payload: string;
}

export function setSearchTerm(value: string): SetSearchTerm {
  return {
    type: "frico-product-browser-epim/SET_SEARCH_TERM",
    payload: value,
  };
}
