import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import * as Texts from "shared-lib/language-texts";
import * as ProductUtils from "shared-lib/product-utils";
import type { StateProps, Response, OwnProps } from "./container-component";
import { FricoProductBrowserEpimContainerComponent } from "./container-component";
import type { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.fricoProductBrowserEpim,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (state, showAdvanced, userSettings) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_EcomCategories: QP.tableByProductId(QP.fricoProductId, "ct_EcomCategories"),
    ct_SearchProducts: QP.tableByProductId(QP.fricoProductId, "ct_SearchProducts"),
    ct_HiddenEcomCategories: QP.tableByProductId(QP.fricoProductId, "ct_HiddenEcomCategories"),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    translateTables:
      response?.ct_SearchProducts &&
      Texts.getTablesQuery([QP.fricoProductId, ...response.ct_SearchProducts.map((p) => p.product)]),
    tablesForProducts: tablesForProductsQuery(getMainProducts(response)),
    marketTables: ProductUtils.createMarketTablesQuery(ownProps.ecomUrl, ownProps.market),
  });
}

function tablesForProductsQuery(
  products: ReadonlyArray<string> | undefined
): QD.DiaqMapQuery<Response["tablesForProducts"]> | undefined {
  if (products === undefined) {
    return undefined;
  }

  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    map[product] = QP.tablesByProductId(product, [
      "ct_ItemNo",
      "code",
      "property",
      "ct_VariantNo",
      "ct_Attributes2",
      "ct_ItemNumberStatus",
    ]);
  }
  return QD.createMapQuery(map);
}

function getMainProducts(response: Response | undefined): ReadonlyArray<string> | undefined {
  // if (!response || !response.ct_BrowserProducts || !response.marketTables) {

  if (!response || !response.ct_SearchProducts) {
    return undefined;
  }

  //return ProductUtils.filterProductsByMarket(response.marketTables, response.ct_BrowserProducts.map(p => p.product));
  return response.ct_SearchProducts.map((p) => p.product); // Need to include all and filter in component
}

export const FricoProductBrowserEpimContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(FricoProductBrowserEpimContainerComponent);
