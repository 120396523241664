import * as R from "ramda";
import * as React from "react";
import type * as SC from "shared-lib/system-calculator";
import { Combobox, Heading4 } from "client-lib/elements";
import { customUnits } from "shared-lib/uom";
import { isAllDefined, isDefined } from "shared-lib/utils";
import * as Texts from "shared-lib/language-texts";
import * as FanSound from "shared-lib/system-calculator/shared/fan-sound";
import * as UserSettings from "shared-lib/user-settings";
import * as UserSettingsClient from "client-lib/user-settings";
import type { DispatchProp } from "client-lib/redux-integration";
import { Units } from "uom-units";
import { Amount } from "uom";
import type { VisualizerOwnProps } from "../types";
import { OctaveTable } from "../shared";

export type Props = VisualizerOwnProps & StateProps & DispatchProp<UserSettingsClient.Action>;

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function OctaveBandsTableFanVisualizerContainerComponent({
  visualizerParams,
  products,
  translate,
  userSettings,
  dispatch,
}: Props): React.ReactElement<Props> {
  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const allResultItemMaps = products.map((p) => p.resultItemMap);
  const allResults = allResultItemMaps.map(
    (resultItemMap) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (R.values(resultItemMap)[0].value as any) as {
        [key: string]: SC.OctaveBands;
      }
  );

  const singleProduct = products.length === 1;

  const filter = UserSettings.getSoundFilter(userSettings);
  const octaveBandsType = singleProduct ? UserSettings.getOctaveBandsType(userSettings) : "Octave";
  const items = visualizerParams.split(",");
  const itemsInResult = allResults[0] || {};
  const rows = items.filter((i) => itemsInResult[i]);
  if (rows.length === 0) {
    return <span />;
  }

  const regularOctaveBands = rows.map(
    (i): OctaveTable.OctaveBandProps => ({
      key: i,
      label: Texts.createText(i),
      unitName: filterToString(filter),
      octaveBands: allResults
        .map((results) => results[i])
        .map((ob) => ob && FanSound.applyFilter(filter, ob))
        .map((ob) => (octaveBandsType === "Octave" ? FanSound.calcOctaveBandsFrom3rds(ob) : ob))
        .filter(isDefined),
    })
  );

  const atThreeMetersRows = getAtThreeMetersRows(
    allResults.map((results) => results.surroundingSound),
    filter,
    octaveBandsType,
    items
  );

  const metaTexts = getBoxFanMetaTexts(allResultItemMaps, translate);

  return (
    <div>
      {<Heading4>{translate(Texts.sound_power_level())}</Heading4>}
      <div className="flex flex-col space-y-16">
        <div className="flex items-center">
          <span className="mr-52 w-144">{translate(Texts.acoustic_filter())}</span>
          <Combobox
            noBorder={false}
            value={filter}
            options={[
              { value: "None", label: translate(Texts.no_filter()) },
              { value: "A", label: translate(Texts.a_filter()) },
              { value: "C", label: translate(Texts.c_filter()) },
            ]}
            onChange={(newValue) => dispatch(UserSettingsClient.setSoundFilter(newValue as UserSettings.SoundFilter))}
          />
        </div>
        <div className="flex items-center">
          {singleProduct && (
            <div className="flex">
              <span className="mr-52 w-144">{translate(Texts.octave_bands())}</span>
              <Combobox
                noBorder={false}
                value={octaveBandsType}
                options={[
                  { value: "Octave", label: translate(Texts.octave_bands_option()) },
                  { value: "Octave3rd", label: translate(Texts.third_octave_bands_option()) },
                ]}
                onChange={(newValue) =>
                  dispatch(UserSettingsClient.setOctaveBandsType(newValue as UserSettings.OctaveBandsType))
                }
              />
            </div>
          )}
        </div>
        <OctaveTable.OctaveTable
          translate={translate}
          octaveBandsType={octaveBandsType}
          octaveBands={[...regularOctaveBands, ...atThreeMetersRows]}
          metaTexts={metaTexts}
        />
      </div>
    </div>
  );
}

function getBoxFanMetaTexts(
  resultItemMaps: ReadonlyArray<SC.ResultItemMap>,
  translate: Texts.TranslateFunction
): ReadonlyArray<string> {
  const allResults = resultItemMaps.map(
    (resultItemMap) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (R.values(resultItemMap)[0].value as any) as {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      }
  );

  if (allResults.every((r) => !r.soundPressureDistance && !r.soundPressureLevelLpa)) {
    return [];
  }

  return R.unnest(
    allResults.map((results) => {
      const distance = results.soundPressureDistance;
      const soundPressureLevel = results.soundPressureLevelLpa;
      if (distance && soundPressureLevel) {
        return [
          `${translate(Texts.soundPressureLevelLpa())}: ${Amount.valueAs(
            customUnits.Decibel,
            soundPressureLevel
          )}dB(A)`,
          `${translate(Texts.distance())}: ${Amount.valueAs(customUnits.Meter, distance)}m`,
          `${translate(Texts.directivity())}: ${translate(Texts.spherical_q1())}`,
        ];
      } else {
        return [];
      }
    })
  );
}

function getAtThreeMetersRows(
  surroundingSoundOctaveBands: ReadonlyArray<SC.OctaveBands | undefined>,
  filter: UserSettings.SoundFilter,
  octaveBandsType: UserSettings.OctaveBandsType,
  items: ReadonlyArray<string>
): ReadonlyArray<OctaveTable.OctaveBandProps> {
  if (!surroundingSoundOctaveBands) {
    return [];
  }
  const octaveBands = surroundingSoundOctaveBands.map(
    (bands) => bands && FanSound.calcOctaveBandsFrom3rds(FanSound.applyFilter(filter, bands))
  );
  const totals = octaveBands.map((bands) => FanSound.calcTotFromOctaveBands(bands));

  if (totals.every((t) => t === undefined)) {
    return [];
  }

  const rows: Array<OctaveTable.OctaveBandProps> = [];

  if (items.some((i) => i === "soundAt3mSabine")) {
    rows.push({
      key: "soundAt3mSabine",
      label: Texts.createText("soundAt3mSabine"),
      unitName: filterToString(filter),
      octaveBands: totals.map((total) =>
        makeAtThreeMetersOctaveBands(octaveBandsType, total !== undefined ? total - 7 : undefined)
      ),
    });
  }

  if (items.some((i) => i === "soundAt3mFree")) {
    rows.push({
      key: "soundAt3mFree",
      label: Texts.createText("soundAt3mFree"),
      unitName: filterToString(filter),
      octaveBands: totals.map((total) =>
        makeAtThreeMetersOctaveBands(octaveBandsType, total !== undefined ? total - 21 : undefined)
      ),
    });
  }

  return rows;
}

function makeAtThreeMetersOctaveBands(
  octaveBandsType: UserSettings.OctaveBandsType,
  total: number | undefined
): SC.OctaveBands | SC.OctaveBands3rd {
  const totalAmount = total !== undefined ? Amount.create(total, Units.DecibelLw, 1) : undefined;
  if (octaveBandsType === "Octave3rd") {
    return {
      type: "Octave",
      hz63: undefined,
      hz125: undefined,
      hz250: undefined,
      hz500: undefined,
      hz1000: undefined,
      hz2000: undefined,
      hz4000: undefined,
      hz8000: undefined,
      total: totalAmount,
    };
  } else {
    return {
      type: "Octave3rd",
      hz50: undefined,
      hz63: undefined,
      hz80: undefined,
      hz100: undefined,
      hz125: undefined,
      hz160: undefined,
      hz200: undefined,
      hz250: undefined,
      hz315: undefined,
      hz400: undefined,
      hz500: undefined,
      hz630: undefined,
      hz800: undefined,
      hz1000: undefined,
      hz1250: undefined,
      hz1600: undefined,
      hz2000: undefined,
      hz2500: undefined,
      hz3150: undefined,
      hz4000: undefined,
      hz5000: undefined,
      hz6300: undefined,
      hz8000: undefined,
      hz10000: undefined,
      total: totalAmount,
    };
  }
}

function filterToString(filter: UserSettings.SoundFilter): string {
  if (filter === "A") {
    return "dB(A)";
  } else if (filter === "C") {
    return "dB(C)";
  } else {
    return "dB";
  }
}
