import * as React from "react";
import { withTw } from "client-lib/elements";

const PopPoverImage = withTw("img", "absolute top-0 bottom-0 left-0 right-0 m-auto");
const RegularImage = withTw("img", "p-4");

type Props = OwnProps & Response;

export interface OwnProps {
  readonly popOver: boolean;
  readonly imageUrl: string;
}

export interface Response {
  readonly imageSrc: string | undefined;
}
export function ImageViewContainerComponent({ popOver, imageSrc }: Props): React.ReactElement<Props> {
  if (imageSrc === undefined) {
    return <span />;
  }
  return popOver ? <PopPoverImage src={imageSrc} /> : <RegularImage src={imageSrc} />;
}
