/* eslint-disable functional/no-this-expression */
import * as React from "react";
import { DropdownButton } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";

export interface DropdownProps {
  readonly specificationDataUrl: string | undefined;
  readonly epimVariantId: string | undefined;
  readonly config: string;
  readonly translate: Texts.TranslateFunction;
  readonly className?: string;
}

export interface DropdownItem {
  readonly key: string;
  readonly icon?: string;
  readonly label: string;
  readonly onClick: () => void;
}

export function PdfPrintoutDropdown({
  specificationDataUrl,
  epimVariantId,
  config,
  translate,
  className,
}: DropdownProps): React.ReactElement<DropdownProps> {
  if (!epimVariantId || !specificationDataUrl) {
    return <div></div>;
  }

  const items = [
    {
      type: "item" as const,
      value: "full",
      label: translate(Texts.full()),
      url: `${specificationDataUrl}/product/pdf/externalId/${epimVariantId}?fullConfig=${config}`,
    },
    {
      type: "item" as const,
      value: "compact",
      label: translate(Texts.compact()),
      url: `${specificationDataUrl}/product/pdf-compact/externalId/${epimVariantId}?fullConfig=${config}`,
    },
  ];

  return <DropdownButton className={className} leftIcon={"file-pdf"} rightIcon={"chevron-down"} items={items} />;
}
