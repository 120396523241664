import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import * as Messages from "../messages";
import * as Attributes from "./attributes";

export function validateMaxAirFlowAndPressureAttr(
  source: string,
  attributes: Attributes.Attributes,
  airFlow: number | Amount.Amount<Quantity.VolumeFlow> | undefined, // lps
  pressure: number | Amount.Amount<Quantity.Pressure> | undefined
): ReadonlyArray<Messages.Message> {
  const maxAirFlow = Attributes.getFloat("air-flow-pressure-drop-MAX-BASE-ALL", attributes);
  const maxPressure = Attributes.getFloat("air-press-pressure-drop-MAX-BASE-ALL", attributes);
  return validateMaxAirFlowAndPressure(source, maxAirFlow, maxPressure, airFlow, pressure);
}

export function validateMaxAirFlowAndPressure(
  source: string,
  maxAirFlow: number | Amount.Amount<Quantity.VolumeFlow> | undefined, // lps
  maxPressure: number | Amount.Amount<Quantity.Pressure> | undefined,
  airFlow: number | Amount.Amount<Quantity.VolumeFlow> | undefined, // lps
  pressure: number | Amount.Amount<Quantity.Pressure> | undefined
): ReadonlyArray<Messages.Message> {
  const maxAirFlowLps = getAirFlowLps(maxAirFlow);
  const maxPressurePa = getPressurePa(maxPressure);
  const airFlowLps = getAirFlowLps(airFlow);
  const pressurePa = getPressurePa(pressure);
  if (maxAirFlowLps !== undefined && airFlowLps !== undefined && airFlowLps >= maxAirFlowLps) {
    return [Messages.Warning_AccessoryAirFlowTooHigh(source)];
  }
  if (maxPressurePa !== undefined && pressurePa !== undefined && pressurePa >= maxPressurePa) {
    return [Messages.Warning_AccessoryPressureTooHigh(source)];
  }
  return [];
}

function getAirFlowLps(airFlow: number | Amount.Amount<Quantity.VolumeFlow> | undefined): number | undefined {
  if (typeof airFlow === "undefined") {
    return undefined;
  } else if (typeof airFlow === "number") {
    return airFlow;
  } else {
    return Amount.valueAs(Units.LiterPerSecond, airFlow);
  }
}

function getPressurePa(pressure: number | Amount.Amount<Quantity.Pressure> | undefined): number | undefined {
  if (typeof pressure === "undefined") {
    return undefined;
  } else if (typeof pressure === "number") {
    return pressure;
  } else {
    return Amount.valueAs(Units.Pascal, pressure);
  }
}
