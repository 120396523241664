import * as R from "ramda";
import { appInfo } from "shared-lib/version";
import { globalSearchReset } from "global-search-reset";
import type { RehydrateAction } from "redux-persist";
import type * as Actions from "./actions";
import type { State } from "./types";

const calcResultsTimeout = 1000 * 3600 * 12;

export const initialSearchState: State = {
  type: "Idle",
  time: 0,
  noSearchResult: false,
  searchProductId: "",
  searchVariants: [],
  results: [],
  clientVersion: appInfo.clientVersion,
  source: undefined,
  searchId: "",
  language: undefined,
  market: undefined,
};

type SearchState = { readonly search?: State } | undefined;

export function shouldSearchBeReset(action: RehydrateAction): boolean {
  const search = (action.payload as SearchState)?.search;

  if (!search || !globalSearchReset.language || !globalSearchReset.market) {
    return false;
  }

  if (
    search.type === "Calculating" ||
    (search.type === "Idle" && Date.now() - search.time > calcResultsTimeout) ||
    search.clientVersion !== appInfo.clientVersion ||
    search.source === undefined ||
    !R.equals(search.source, globalSearchReset.source) ||
    search.language !== globalSearchReset.language ||
    search.market !== globalSearchReset.market
  ) {
    return true;
  }

  return false;
}

export function reducer(state: State = initialSearchState, action: Actions.Action): State {
  switch (action.type) {
    case "search/REHYDRATE": {
      return action.payload;
    }
    case "search/START_CALCULATION":
      return {
        type: "Calculating",
        progress: 0,
        abort: false,
      };
    case "search/SET_PROGRESS":
      return {
        type: "Calculating",
        progress: action.payload,
        abort: false,
      };
    case "search/ABORT_CALCULATION":
      return {
        type: "Calculating",
        progress: 100,
        abort: true,
      };
    case "search/RESET":
    case "search-products/RESET":
      return initialSearchState;
    case "search/CALCULATION_FINISHED":
      return {
        type: "Idle",
        time: Date.now(),
        searchId: action.payload.searchId,
        noSearchResult: action.payload.results.length === 0,
        searchProductId: action.payload.searchProductId,
        searchVariants: action.payload.searchVariants,
        results: action.payload.results,
        clientVersion: appInfo.clientVersion,
        source: action.payload.source,
        language: action.payload.language,
        market: action.payload.market,
      };
    default:
      return state;
  }
}
