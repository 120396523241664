import type { Amount } from "uom";
import * as Texts from "shared-lib/language-texts";
import type { Quantity } from "uom-units";
import type { GetDecimalsFunction, GetUnitFunction } from "shared-lib/user-settings";

export interface Message {
  readonly code: MessageCode;
  readonly params: Texts.MessageParams;
  readonly source: string;
}

export enum MessageLevel {
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
  PRODUCT_DATA_ERROR = 4,
  EXCEPTION = 5,
}

export enum MessageCode {
  OK = 0,

  // INFO
  Info_Min = 1,

  Info_Max = 199,

  // WARNINGS
  Warning_Min = 200,

  Warning_IsovelInOccupancyZone = 201,
  Warning_DefrostRequired = 202,
  Warning_Condensation = 203,
  Warning_LowLoadOutsideValidRange = 204,
  Warning_HighLoadOutsideValidRange = 205,
  Warning_NotEnoughPower = 206,
  Warning_ChildrenHasWarnings = 250,
  Warning_CoilPressure = 255,
  Warning_PointAdjustedToClosestValid = 256,
  Warning_AccessoryPressureTooHigh = 257,
  Warning_AccessoryAirFlowTooHigh = 258,
  Warning_AccessoriesUsingRequiredAirFlow = 259,
  Warning_AirVelocityTooHigh = 260,

  Warning_Max = 499,

  // ERRORS
  Error_Min = 500,

  Error_OutsideValidRange = 501,
  Error_FlowTooLow = 502,
  Error_FlowTooHigh = 503,
  Error_PressureTooLow = 504,
  Error_PressureTooHigh = 505,
  Error_InletTemperatureTooLow = 506,
  Error_OutletTemperatureTooHigh = 507,
  Error_NotEnoughPower = 508,
  Error_CoolingWithAHeater = 509,
  Error_HeatingWithACooler = 510,
  Error_SupplyExhaustAirFlowDifferenceTooHigh = 511,
  Error_NotMatchingScoreCriteria = 512,
  Error_CalculationInputMissing = 513,

  Error_AirVelocityTooHigh = 514,
  Error_AirPressureDropTooHigh = 515,
  Error_WaterPressureDropTooHigh = 516,
  Error_SupplyExhaustPressureDifferenceTooHigh = 517,
  Error_MissingInput = 518,
  Error_StandardFanWithoutMotorAttribute = 520,

  Error_ChildrenHasErrors = 550,

  Error_Max = 999,

  // PRODUCT DATA ERRORS
  ProductDataError_Min = 1000,

  ProductDataError_NoDataAvailable = 1001,

  ProductDataError_Max = 1999,

  // EXCEPTION, any exceptions in any calculation step should incur this error and nothing else, there should be no "unknown" error codes
  // since every error/warning in this enumeration should be fixable by the end-user himself except Exception.
  Exception = 99999999,
}

export function isStoppingErrorMessage(code: MessageCode): boolean {
  return code > MessageCode.Error_Min;
}

export function isErrorMessage(code: MessageCode): boolean {
  return code > MessageCode.Error_Min && code < MessageCode.Error_Max;
}

export function isWarningMessage(code: MessageCode): boolean {
  return code > MessageCode.Warning_Min && code < MessageCode.Warning_Max;
}

export function isInfoMessage(code: MessageCode): boolean {
  return code < MessageCode.Info_Max;
}

export function isProductDataErrorMessage(code: MessageCode): boolean {
  return code > MessageCode.ProductDataError_Min && code < MessageCode.ProductDataError_Max;
}

export function isExceptionMessage(code: MessageCode): boolean {
  return code === MessageCode.Exception;
}

// Creator functions for messages

export const Warning_IsovelInOccupancyZone = (source: string): Message =>
  createMessageType(MessageCode.Warning_IsovelInOccupancyZone, source);

export const Warning_DefrostRequired = (source: string): Message =>
  createMessageType(MessageCode.Warning_DefrostRequired, source);

export const Warning_CoilPressure = (source: string): Message =>
  createMessageType(MessageCode.Warning_CoilPressure, source);

export const Warning_Condensation = (source: string): Message =>
  createMessageType(MessageCode.Warning_Condensation, source);

export const Warning_LowLoadOutsideValidRange = (source: string): Message =>
  createMessageType(MessageCode.Warning_LowLoadOutsideValidRange, source);

export const Warning_HighLoadOutsideValidRange = (source: string): Message =>
  createMessageType(MessageCode.Warning_HighLoadOutsideValidRange, source);

export const Warning_NotEnoughPower = (source: string): Message =>
  createMessageType(MessageCode.Warning_NotEnoughPower, source);

export const Warning_PointAdjustedToClosestValid = (source: string): Message =>
  createMessageType(MessageCode.Warning_PointAdjustedToClosestValid, source);

export const Warning_AccessoryPressureTooHigh = (source: string): Message =>
  createMessageType(MessageCode.Warning_AccessoryPressureTooHigh, source);

export const Warning_AccessoryAirFlowTooHigh = (source: string): Message =>
  createMessageType(MessageCode.Warning_AccessoryAirFlowTooHigh, source);

export const Warning_AccessoriesUsingRequiredAirFlow = (source: string): Message =>
  createMessageType(MessageCode.Warning_AccessoriesUsingRequiredAirFlow, source);

export const Warning_ChildrenHasWarnings = (source: string): Message =>
  createMessageType(MessageCode.Warning_ChildrenHasWarnings, source);

export const Warning_AirVelocityTooHigh = (source: string, maxVelocity: Amount.Amount<Quantity.Velocity>): Message =>
  createMessageType(MessageCode.Warning_AirVelocityTooHigh, source, {
    maxVelocity: Texts.createAmountParam("airVelocity", maxVelocity),
  });

export const Error_OutsideValidRange = (source: string): Message =>
  createMessageType(MessageCode.Error_OutsideValidRange, source);

export const Error_FlowTooLow = (source: string, minFlow: Amount.Amount<Quantity.VolumeFlow>): Message =>
  createMessageType(MessageCode.Error_FlowTooLow, source, {
    minFlow: Texts.createAmountParam("airFlow", minFlow),
  });

export const Error_FlowTooHigh = (source: string, maxFlow: Amount.Amount<Quantity.VolumeFlow>): Message =>
  createMessageType(MessageCode.Error_FlowTooHigh, source, {
    maxFlow: Texts.createAmountParam("airFlow", maxFlow),
  });

export const Error_PressureTooLow = (source: string, minPressure: Amount.Amount<Quantity.Pressure>): Message =>
  createMessageType(MessageCode.Error_PressureTooLow, source, {
    minPressure: Texts.createAmountParam("airPressure", minPressure),
  });

export const Error_PressureTooHigh = (source: string, maxPressure: Amount.Amount<Quantity.Pressure>): Message =>
  createMessageType(MessageCode.Error_PressureTooHigh, source, {
    maxPressure: Texts.createAmountParam("airPressure", maxPressure),
  });

export const Error_AirVelocityTooHigh = (source: string, maxVelocity: Amount.Amount<Quantity.Velocity>): Message =>
  createMessageType(MessageCode.Error_AirVelocityTooHigh, source, {
    maxVelocity: Texts.createAmountParam("airVelocity", maxVelocity),
  });

export const Error_AirPressureDropTooHigh = (
  source: string,
  maxPressureDrop: Amount.Amount<Quantity.Pressure>
): Message =>
  createMessageType(MessageCode.Error_AirPressureDropTooHigh, source, {
    maxPressureDrop: Texts.createAmountParam("airPressure", maxPressureDrop),
  });

export const Error_WaterPressureDropTooHigh = (
  source: string,
  maxPressureDrop: Amount.Amount<Quantity.Pressure>
): Message =>
  createMessageType(MessageCode.Error_WaterPressureDropTooHigh, source, {
    maxPressureDrop: Texts.createAmountParam("waterPressureDrop", maxPressureDrop),
  });

export const Error_ChildrenHasErrors = (source: string): Message =>
  createMessageType(MessageCode.Error_ChildrenHasErrors, source);

export const Error_InletTemperatureTooLow = (
  source: string,
  minInletTemperature: Amount.Amount<Quantity.Temperature>
): Message =>
  createMessageType(MessageCode.Error_InletTemperatureTooLow, source, {
    minInletTemperature: Texts.createAmountParam("airTemperature", minInletTemperature),
  });

export const Error_OutletTemperatureTooHigh = (
  source: string,
  maxOutletTemperature: Amount.Amount<Quantity.Temperature>
): Message =>
  createMessageType(MessageCode.Error_OutletTemperatureTooHigh, source, {
    maxOutletTemperature: Texts.createAmountParam("airTemperature", maxOutletTemperature),
  });

export const Error_CalculationInputMissing = (source: string): Message =>
  createMessageType(MessageCode.Error_CalculationInputMissing, source);

export const Error_StandardFanWithoutMotorAttribute = (source: string): Message =>
  createMessageType(MessageCode.Error_StandardFanWithoutMotorAttribute, source);

export const Error_NotEnoughPower = (source: string): Message =>
  createMessageType(MessageCode.Error_NotEnoughPower, source);

export const Error_CoolingWithAHeater = (source: string): Message =>
  createMessageType(MessageCode.Error_CoolingWithAHeater, source);

export const Error_HeatingWithACooler = (source: string): Message =>
  createMessageType(MessageCode.Error_HeatingWithACooler, source);

export const Error_SupplyExhaustAirFlowDifferenceTooHigh = (source: string): Message =>
  createMessageType(MessageCode.Error_SupplyExhaustAirFlowDifferenceTooHigh, source);

export const Error_NotMatchingScoreCriteria = (source: string): Message =>
  createMessageType(MessageCode.Error_NotMatchingScoreCriteria, source);

export const ProductDataError_NoDataAvailable = (source: string): Message =>
  createMessageType(MessageCode.ProductDataError_NoDataAvailable, source);

export const Error_SupplyExhaustPressureDifferenceTooHigh = (source: string): Message =>
  createMessageType(MessageCode.Error_SupplyExhaustPressureDifferenceTooHigh, source);

export const Error_MissingInput = (source: string, fieldName: string): Message =>
  createMessageType(MessageCode.Error_MissingInput, source, { fieldName: Texts.createFieldParam(fieldName) });

export const Exception = (source: string, msg: string): Message =>
  createMessageType(MessageCode.Exception, source, { msg: Texts.createTextParam(msg) });

function createMessageType(code: MessageCode, source: string, params: Texts.MessageParams = {}): Message {
  return {
    code: code,
    source: source,
    params: params,
  };
}

type AlertType = "info" | "warning" | "error" | "data";

export function renderMessage(
  componentName: string | undefined,
  message: Message,
  translate: Texts.TranslateFunction,
  getUnit: GetUnitFunction,
  getDecimals: GetDecimalsFunction
): { readonly type: AlertType; readonly text: string } {
  let type: AlertType = "info";
  if (isInfoMessage(message.code)) {
    type = "info";
  }
  if (isWarningMessage(message.code)) {
    type = "warning";
  }
  if (isErrorMessage(message.code)) {
    type = "error";
  }
  if (isProductDataErrorMessage(message.code)) {
    type = "data";
  }
  if (isExceptionMessage(message.code)) {
    type = "error";
  }

  const description = translate(
    Texts.message_code(message.code, message.source, message.params),
    "",
    getUnit,
    getDecimals
  );

  const text = componentName ? `${componentName}: ${description}` : description;

  return { type, text };
}

export function isAccessoryViewMessage(message: Message): boolean {
  return message.code === MessageCode.Warning_AccessoriesUsingRequiredAirFlow;
}
