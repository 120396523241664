import * as R from "ramda";
import { UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as PU from "shared-lib/product-utils";
import * as Texts from "shared-lib/language-texts";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as CUtils from "client-lib/utils";
import { formatNumberFunction } from "shared-lib/utils";

export interface RenderedParam {
  readonly key: string;
  readonly label: string;
  readonly value: string;
}

export interface RenderedSelectionGroup {
  readonly key: string;
  readonly label: string;
  readonly properties: ReadonlyArray<RenderedParam>;
}

export interface RenderedSelectionCriteria {
  readonly groups: ReadonlyArray<RenderedSelectionGroup>;
}

export function renderFricoSelectionCriteria({
  property,
  ct_MarketUnits,
  ct_PropertyFieldNames,
  ct_LanguageMapping,
  market,
  language,
  userSettings,
  translate,
  searchSelections,
}: {
  readonly property: QP.PropertyTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly ct_PropertyFieldNames: QP.PropertyFieldNamesTable;
  readonly market: string;
  readonly language: string;
  readonly userSettings: UserSettingsShared.State;
  readonly translate: Texts.TranslateFunction;
  readonly searchSelections: PU.Selections;
}): RenderedSelectionCriteria {
  const hideProperties = ["market"];
  const searchVariant = (searchSelections && PU.getVariants(searchSelections)[0]) || PropertyValueSet.Empty;
  const searchProperties = property.filter(
    (p) => !hideProperties.some((hp) => hp === p.name) && PropertyFilter.isValid(searchVariant, p.visibility_filter)
  );
  const propertyGroups = R.groupBy((p) => p.group || "main", searchProperties);
  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);
  const groups = R.keys(propertyGroups).map((group) => {
    const groupLabel = translate(Texts.property_group(group));
    const properties = propertyGroups[group].map((p) => {
      const value = (searchSelections[p.name] as PU.PropertySelection | undefined) || {
        type: "Text",
        value: "-",
      };
      const fieldName = ct_PropertyFieldNames.find((n) => n.property === p.name)?.field_name || p.name;
      const unit = fieldName && value.type === "Amount" ? getUnit(fieldName, value.value.unit.quantity) : undefined;
      const unitLabel = unit && translate(Texts.unitLabel(unit), UnitFormat.getUnitFormat(unit, UnitsFormat)?.label);
      const renderedValue =
        value.type === "Amount"
          ? CUtils.renderAmount(value.value, fieldName, { getUnit, getDecimals, formatNumber })
          : value.type === "Discrete"
          ? translate(Texts.property_value(QP.fricoProductId, p.name, value.values[0]))
          : value.value;
      const label = `${translate(Texts.property_name(QP.fricoProductId, p.name))}${unitLabel ? ` [${unitLabel}]` : ""}`;
      return {
        key: p.name,
        label: label,
        value: renderedValue,
      };
    });
    return {
      key: group,
      label: groupLabel,
      properties,
    };
  });
  return {
    groups: groups,
  };
}
