import { retryFetch } from "shared-lib/fetch";

export interface EurocoilInput {
  readonly FlgDim: boolean;
  readonly Geometry: string;
  readonly Tube: string;
  readonly A: number;
  readonly L: number;
  readonly NR: number;
  readonly FinSpa: number;
  readonly NC: number;
  readonly TS: number;
  readonly Fins: string;
  readonly FinThickness: number;
  readonly TSA1: number;
  readonly UMREL1: number;
  readonly FlagQaVa: number;
  readonly QaVa: number;
  readonly TW1: number;
  readonly FlagTuwQwVw: number;
  readonly TuwQwVw: number;
  readonly Glic: number;
  readonly Altitude: number;
}

export interface EurocoilResult {
  readonly Qa: number;
  readonly Va: number;
  readonly DPA: number;
  readonly TUA: number;
  readonly UUA: number;
  readonly QTOT: number;
  readonly QSEN: number;
  readonly Qw: number;
  readonly Vw: number;
  readonly DPW: number;
  readonly TUW: number;
  readonly Lamin: string;
  readonly Geom1: string;
  readonly Tpr1: number;
  readonly A1: number;
}

export interface EurocoilHeatInput {
  readonly Flg_DIM: boolean; // Flg_DIM: Flag for Eurocoil internal use
  readonly Geometry: string; // Geometry: Geometry
  readonly Tube: string; // Tube: Tube diameter
  readonly A: number; // A: Fin height (mm)
  readonly L: number; // L: Fin lenght (mm)
  readonly NR: number; // NR: Rows (Nr.)
  readonly FinSpa: number; // FinSpa: Fin spacing (mm)
  readonly NC: number; // NC: Circuits (Nr.)
  readonly TS: number; // TS: Unused tubes (Nr.)
  readonly Fins: string; // Fins: Fin type
  readonly FinThickness: number; // FinThickness: Only for Eurocoil internal use
  readonly TSA1: number; // TSA1: Inlet air temp. (°C)
  readonly FlagQaVa: number; // FlagQaVa: Flag air flow or air velocity. 0 QaVa is air flow, 1 QaVa is air velocity
  readonly QaVa: number; // QaVa: air flow (m3/h) or air velocity (m/s)
  readonly TW1: number; // TW1: Inlet water temp. (°C)
  readonly FlagTuwQwVw: number; // FlagTuwQwVw: Flag outlet water temp. or water flow or water velocity. 0 TuwQwVw is outlet water temp, 1 TuwQwVw is water flow, 2 TuwQwVw is water velocity
  readonly TuwQwVw: number; // TuwQwVw: Outlet water temp. (°C) or  water flow (l/h) or  water velocity (m/s)
  readonly Glic: number; // Glic: Glycol (%)
  readonly Altitude: number; // Altitude: Altitude from see level
  readonly OutAirTemp: number | undefined; // outlet air temp (°C)
}

export interface EurocoilHeatOutput {
  readonly returnValue: number; // CalcHEAT_Eurocoil_For_Customer return value, -1 = Ok
  readonly Qa: number; // Qa: Air flow (m3/h)
  readonly Va: number; // Va: Air veocity (m/s)
  readonly DPA: number; // DPA: Air pressure drop (Pa)
  readonly TUA: number; // TUA: Outlet air temp. (°C)
  readonly QTOT: number; // QTOT: Total capacity (kW)
  readonly Qw: number; // Qw: Water flow (l/h)
  readonly Vw: number; // Vw: Water veocity (m/s)
  readonly DPW: number; // DPW: Water pressure drop (KPa)
  readonly TUW: number; // TUW: Outlet water temp. (°C)
  readonly Lamin: number; // Lamin$: If “L” then laminar
  readonly Geom1: string; // Geom1: Geometry extended (for Eurocoil internal use)
  readonly Tpr1: number; // Tpr1: Tube per row (Nr.
  readonly A1: number; // Height: (for Eurocoil internal use
}

export async function calculateCool(input: EurocoilInput): Promise<EurocoilResult | undefined> {
  //console.log("Sending", JSON.stringify(input);
  const response = await retryFetch("https://design-dllcal.systemair.com/api/eurocoil/calculate", {
    //const response = await retryFetch("http://localhost:5000/calculate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  });
  if (!response.ok) {
    return undefined;
  }
  const result = await response.json();
  return result;
}

export async function calculateHeat(input: EurocoilHeatInput): Promise<EurocoilHeatOutput | undefined> {
  //console.log("Sending", JSON.stringify(input);
  const response = await retryFetch("https://design-dllcal.systemair.com/api/eurocoil/CalcHEAT_Eurocoil_For_Customer", {
    //const response = await retryFetch("http://localhost:5000/CalcHEAT_Eurocoil_For_Customer", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  });
  if (!response.ok) {
    return undefined;
  }
  const result = await response.json();
  return result;
}

export async function calculateHeatByOutAirTemp(input: EurocoilHeatInput): Promise<EurocoilHeatOutput | undefined> {
  //console.log("Sending", JSON.stringify(input);
  const response = await retryFetch(
    "https://design-dllcal.systemair.com/api/eurocoil/CalcHEAT_Eurocoil_For_Customer_By_OutAirTemp",
    {
      //const response = await retryFetch("http://localhost:5000/CalcHEAT_Eurocoil_For_Customer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }
  );
  if (!response.ok) {
    return undefined;
  }
  const result = await response.json();
  return result;
}
