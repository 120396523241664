import * as React from "react";
import { withTw } from "./with-tw";

interface ButtonProps {
  readonly selected: boolean;
  readonly isValid: boolean;
  readonly disabled: boolean;
}

const Button = withTw(
  "div",
  "text-sm flex flex-col space-y-4 h-full select-none items-center text-center select-btn w-full",
  (p: ButtonProps) => (p.disabled ? "select-btn-disabled" : p.selected ? "select-btn-selected" : ""),
  (p: ButtonProps) => (!p.disabled ? "cursor-pointer" : ""),
  (p: ButtonProps) =>
    !p.isValid && p.selected ? "bg-[--dms-danger-400] hover:shadow-dms-danger-hover text-btn-danger-active-text" : "",
  (p: ButtonProps) => (!p.isValid ? "hover:shadow-dms-danger-hover border-danger-600 text" : ""),
  (p: ButtonProps) => (!p.isValid && !p.selected ? "hover:text-danger-600" : "")
);
const Label = withTw("span", "text-center leading-text", ({ enableWrap }: { readonly enableWrap?: boolean }) =>
  enableWrap ? "" : "whitespace-nowrap"
);

interface Props {
  readonly selected: boolean;
  readonly label: string | undefined;
  readonly onClick: (selected: boolean) => void;
  readonly isValid?: boolean;
  readonly imageUrl?: string;
  readonly title?: string;
  readonly disabled?: boolean;
  readonly enableWrap?: boolean;
}

export function SelectButtonLarge({
  selected,
  isValid = true,
  disabled = false,
  label,
  onClick,
  imageUrl,
  title,
  enableWrap,
}: Props): React.ReactElement<Props> {
  return (
    <div className="hover:z-50 w-full flex flex-col items-center">
      <Button
        disabled={disabled}
        isValid={isValid}
        selected={selected}
        title={title}
        onClick={() => !disabled && onClick(!selected)}
      >
        <div className="min-w-36 min-h-36">{imageUrl && <img className="h-36 w-36" src={imageUrl} />}</div>
        <Label enableWrap={enableWrap}>{label}</Label>
      </Button>
    </div>
  );
}
