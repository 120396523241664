import { itemNumberToServiceUrl as sharedItemNumberToServiceUrl } from "shared-lib/image-service";
import { clientConfig } from "../../config";

export function itemNumberToServiceUrl(
  itemNumber: string,
  scale?: number,
  maxWidth?: number,
  maxHeight?: number,
  format?: "jpeg" | "png",
  background?: string
): string {
  return sharedItemNumberToServiceUrl(
    clientConfig.imageServiceUrl,
    itemNumber,
    scale,
    maxWidth,
    maxHeight,
    format,
    background
  );
}
