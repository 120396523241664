import type * as QP from "shared-lib/query-product";
import * as Attributes from "../shared/attributes";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  return (
    Attributes.getIntOrDefault("default-variant", variantAttributes, 1) === 1 &&
    Attributes.matchAttribute("PROP-ahu-inst-place-MULTI", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("PROP-ahu-mounting-supply-side-MULTI", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-heat-exchanger-type", searchAttributes, variantAttributes)
  );
}
