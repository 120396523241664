/* eslint-disable no-return-await */
import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Types from "../types";
import type { Input } from "./types";
import { calculate } from "./calculator";
import type * as ResultTypes from "../result-items-types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";
import * as BathroomFan from "./bathroom-fan";
import { getUserSpeedControl } from "./input-mapper";

const source = "FantechFanSelector";

export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.Fan>> {
  const {
    speedControl,
    attributes,
    airFlow,
    limitType,
    maxSfp,
    minEfficiency,
    tolerancePlus,
    toleranceMinus,
    bathroomFanSearchInput,
  } = input;

  const hasStepless = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Stepless");
  const hasTransformer = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Transformer");
  const hasNoControl =
    hasTransformer || Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "None");
  const isEC = Attributes.matchesAttribute("CL-motor-type", attributes, "EC");

  let selectSpeedControl: ResultTypes.SpeedControl = "None";
  if (speedControl === undefined && isEC) {
    selectSpeedControl = "Stepless";
  } else if (speedControl === "None" && !hasNoControl) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  } else if (speedControl === "Transformer" && !hasTransformer) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  } else if (speedControl === "Stepless") {
    if (!hasStepless) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
    selectSpeedControl = "Stepless";
  }

  // Special bathroom fan mode
  if (bathroomFanSearchInput) {
    const bathroomFanWp = BathroomFan.findBathroomFanWorkingPoint(bathroomFanSearchInput, input.airData);
    if (!bathroomFanWp) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
    const updateCalcParams = BathroomFan.updateCalcParams(
      input.calcParams,
      bathroomFanWp.airFlow,
      bathroomFanWp.pressure
    );
    const bathroomFanResult = await calculate({
      ...input,
      speedControl: getUserSpeedControl(updateCalcParams) ?? "None",
      calcParams: updateCalcParams,
      airFlow: bathroomFanWp.airFlow,
      externalPressure: bathroomFanWp.pressure,
    });
    // Hide fans that can't handle the calculated air flow
    if (bathroomFanResult.messages.some((m) => m.code === Messages.MessageCode.Warning_PointAdjustedToClosestValid)) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
    return bathroomFanResult;
  }

  if (!airFlow) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const selectInput = { ...input, speedControl: selectSpeedControl };
  const result = await calculate(selectInput);

  if (result.type === "CalculatorError" || !result.output.air.workingPoint || !result.output.air.airFlow) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }

  if (limitType === 0 && maxSfp && result.output.air.sfp) {
    const maxSfpValue = Amount.valueAs(customUnits.KiloWattPerCubicMeterPerSecond, maxSfp);
    const resultSfpValue = Amount.valueAs(customUnits.KiloWattPerCubicMeterPerSecond, result.output.air.sfp);
    if (resultSfpValue > maxSfpValue) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  } else if (limitType === 1 && minEfficiency && result.output.air.efficiency) {
    const minEfficiencyValue = Amount.valueAs(customUnits.Percent, minEfficiency);
    const resultEfficiencyValue = Amount.valueAs(customUnits.Percent, result.output.air.efficiency);
    if (resultEfficiencyValue < minEfficiencyValue) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  }

  const inputAirFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const workingFlowLps = Amount.valueAs(customUnits.LiterPerSecond, result.output.air.airFlow);
  const diff = (100.0 * (workingFlowLps - inputAirFlowLps)) / inputAirFlowLps;
  if (toleranceMinus && Amount.valueAs(customUnits.Percent, toleranceMinus) >= diff) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }
  if (tolerancePlus && diff > Amount.valueAs(customUnits.Percent, tolerancePlus)) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
  }

  let resultSpeedControl: ResultTypes.SpeedControl = selectSpeedControl;
  if (resultSpeedControl === "None" && speedControl !== "None") {
    resultSpeedControl = "Transformer";
  }

  const calcParams = PropertyValueSet.setInteger(
    "speedControl",
    speedControlToInt(resultSpeedControl),
    input.calcParams
  );

  const resultInput = { ...input, speedControl: resultSpeedControl, calcParams };
  const result2 = await calculate(resultInput);
  return result2;
}

function speedControlToInt(speedControl: ResultTypes.SpeedControl): number {
  if (speedControl === "None") {
    return 1;
  } else if (speedControl === "Transformer") {
    return 2;
  } else {
    return 3;
  }
}
