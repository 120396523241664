import { retryFetch } from "shared-lib/fetch";

export interface RecairInput {
  readonly Model: string; // "RS160" or "RS300"
  readonly Height: number;
  readonly FreshAirFlow: number;
  readonly FreshAirTemperature: number;
  readonly FreshAirRelativeHumidity: number;
  readonly ExtractAirFlow: number;
  readonly ExtractAirTemperature: number;
  readonly ExtractAirRelativeHumidity: number;
}

export interface RecairOutput {
  readonly SupplyAirTemperature: number;
  readonly ExhaustAirTemperature: number;
  readonly DryEfficiency: number;
  readonly WetEfficiency: number;
  readonly SupplyAirPressureDrop: number;
  readonly ExhaustAirPressureDrop: number;
  readonly SupplyRelativeHumidity: number;
  readonly ExhaustRelativeHumidity: number;
  readonly TransferredPowerSensible: number;
  readonly Condensate: number;
}

export async function calculate(input: RecairInput): Promise<RecairOutput> {
  const result: RecairOutput = await retryFetch("https://design-dllcal.systemair.com/api/recair/calculate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  }).then((r) => r.json());

  return result;
}
