import * as React from "react";

export interface TextboxPropertySelectorProps {
  readonly value: string | undefined;
  readonly onChange: (newValue: string) => void;
  readonly disabled?: boolean;
}

export function TextboxPropertySelector(props: TextboxPropertySelectorProps): JSX.Element {
  const { value, onChange, disabled = false } = props;
  return (
    <input type="text" className="w-1/3" value={value} readOnly={disabled} onChange={(e) => onChange(e.target.value)} />
  );
}
