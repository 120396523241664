import * as React from "react";
import { Heading4 } from "./heading";

interface Props {
  readonly heading?: string;
  readonly children?: ReadonlyArray<React.ReactNode> | React.ReactNode;
}

export function ResultRowContainer(props: Props): React.ReactElement<Props> {
  const children = (props.children
    ? Array.isArray(props.children)
      ? props.children
      : [props.children]
    : []) as ReadonlyArray<React.ReactElement>;
  return (
    <div>
      {props.heading ? <Heading4>{props.heading}</Heading4> : null}
      <div className="flex flex-row w-full">
        {children.length > 1 && <div className="w-1/12" />}
        <div className="flex flex-row justify-around w-4/5">
          {(children || []).map((c, i) => (
            <div key={i} className="flex-shrink flex-grow basis-1/2 min-w-340">
              {c}
            </div>
          ))}
        </div>
        {children.length > 1 && <div className="w-1/12" />}
      </div>
    </div>
  );
}
