import type { AnyQuery } from "./types";

export type ComposedQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse> =
  | MapQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>
  | ArrayQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>;
export type ComposedResponse<TAtomicResponse> = MapResponse<TAtomicResponse> | ArrayResponse<TAtomicResponse>;

// Query for a map of other queries (used for composition)
export type MapOfQueriesWithSameKeysAsResponse<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse> = {
  readonly // Response may be undefined if the information to create the query is not yet available

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [P in keyof TComposedResponse]: TAtomicQuery | ComposedQuery<TAtomicQuery, TAtomicResponse, any> | undefined;
};
export interface MapQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse> {
  readonly type: "@@ComposedQuery/Map";
  readonly source: "@@ComposedQuery";
  readonly map: MapOfQueriesWithSameKeysAsResponse<TAtomicQuery, TAtomicResponse, TComposedResponse>;
}
export interface MapResponse<TAtomicResponse> {
  readonly [key: string]: TAtomicResponse | ComposedResponse<TAtomicResponse>;
}
export function createMapQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse>(
  map: MapOfQueriesWithSameKeysAsResponse<TAtomicQuery, TAtomicResponse, TComposedResponse>
): MapQuery<TAtomicQuery, TAtomicResponse, TComposedResponse> {
  return { type: "@@ComposedQuery/Map", source: "@@ComposedQuery", map };
}

// Query for an array of queries
export interface ArrayQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse> {
  readonly type: "@@ComposedQuery/Array";
  readonly source: "@@ComposedQuery";
  readonly array: ReadonlyArray<TAtomicQuery | ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>>;
}
export interface ArrayResponse<TAtomicResponse>
  extends ReadonlyArray<TAtomicResponse | ComposedResponse<TAtomicResponse>> {}
export function createArrayQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse>(
  array: ReadonlyArray<TAtomicQuery | ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>>
): ArrayQuery<TAtomicQuery, TAtomicResponse, TComposedResponse> {
  return { type: "@@ComposedQuery/Array", source: "@@ComposedQuery", array };
}
