export { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
export { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
export { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
export { faFilePdf } from "@fortawesome/pro-light-svg-icons/faFilePdf";
export { faArrowUp19 } from "@fortawesome/pro-light-svg-icons/faArrowUp19";
export { faArrowDown91 } from "@fortawesome/pro-light-svg-icons/faArrowDown91";
export { faSortUp } from "@fortawesome/pro-light-svg-icons/faSortUp";
export { faSortDown } from "@fortawesome/pro-light-svg-icons/faSortDown";
export { faSort } from "@fortawesome/pro-light-svg-icons/faSort";
export { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
export { faThumbsUp } from "@fortawesome/pro-light-svg-icons/faThumbsUp";
export { faArrowUp } from "@fortawesome/pro-light-svg-icons/faArrowUp";
export { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
export { faArrowDown } from "@fortawesome/pro-light-svg-icons/faArrowDown";
export { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown";
export { faAngleUp } from "@fortawesome/pro-light-svg-icons/faAngleUp";
export { faSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
export { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
export { faTable } from "@fortawesome/pro-light-svg-icons/faTable";
export { faCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
export { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
export { faFaceFrown } from "@fortawesome/pro-light-svg-icons/faFaceFrown";
export { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
export { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";

export type FalIconName =
  | "plus"
  | "chevron-down"
  | "trash"
  | "file-pdf"
  | "arrow-up-1-9"
  | "arrow-down-9-1"
  | "sort-up"
  | "sort-down"
  | "sort"
  | "xmark"
  | "thumbs-up"
  | "arrow-up"
  | "circle"
  | "arrow-down"
  | "angle-down"
  | "angle-up"
  | "spinner-third"
  | "info-circle"
  | "table"
  | "circle-info"
  | "triangle-exclamation"
  | "face-frown"
  | "circle-check"
  | "magnifying-glass";
