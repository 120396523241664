import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type { Input, Response } from "./types";
import type { ComponentInput, InputMapperSuccess, InputMapperError, InputParam, System, ResultQuery } from "../types";
import { createInputMapperError, createInputMapperSuccess } from "../types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";

const msgSource = "BoxFanInputMapper";

export function getCalcParams(params: string): ReadonlyArray<InputParam> {
  if (params === "search") {
    return [];
  }
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "staticPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty("staticPressure=0.1:Pascal~999999:Pascal", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "soundPressureDistance",
      values: [
        {
          value: 10,
          name: "1.0 m",
        },
        {
          value: 15,
          name: "1.5 m",
        },
        {
          value: 30,
          name: "3.0 m",
        },
        {
          value: 50,
          name: "5.0 m",
        },
      ],
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "freq",
      values: [
        {
          value: 50,
          name: "50",
        },
        {
          value: 60,
          name: "60",
        },
      ],
    },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_AccessoryPressureDrop: QP.tableByProductId(productId, "ct_AccessoryPressureDrop"),
    ct_FanCondition: QP.tableByProductId(productId, "ct_FanCondition"),
  });
}

export function getResultsQuery(system: System, componentId: string): ReadonlyArray<ResultQuery> {
  return system.components
    .filter((c) => c.id !== componentId && c.resultItems.length > 0)
    .map((c) => {
      const resultItems = c.resultItems.map((i) => i.name);
      return {
        componentId: c.id,
        resultItems: resultItems,
      };
    });
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const inputErrors: Array<Messages.Message> = [];

  const frequency = PropertyValueSet.getInteger("freq", calcParams) ?? PropertyValueSet.getInteger("freq", properties);

  // const fanSeries =
  //   Attributes.getString("FAN-SERIES", attributes) || Attributes.getString("box-fan-series", attributes); // EPIM ATTRIBUTE LAST ONE
  // const fanSize = Attributes.getString("FAN-SIZE", attributes) || Attributes.getString("box-fan-size", attributes); // EPIM ATTRIBUTE LAST ONE
  // const maxShaftPowerKw =
  //   Attributes.getFloat("FAN-MAX-SHAFT-POWER", attributes) ||
  //   Attributes.getFloat("output-power-shaft-MAX-BASE-ALL", attributes)! / 1000; // EPIM ATTRIBUTE LAST ONE
  // const motorPowerKw =
  //   Attributes.getFloat("MOTOR-POWER", attributes) ||
  //   Attributes.getFloat("input-power-motor-BASE-ALL", attributes)! / 1000; // EPIM ATTRIBUTE LAST ONE;
  // const motorCurrentA =
  //   Attributes.getFloat("MOTOR-CURRENT", attributes) || Attributes.getFloat("input-current-motor", attributes); // EPIM ATTRIBUTE LAST ONE;

  // const fanType = Attributes.getString("FAN-TYPE", attributes) || Attributes.getString("box-fan-type", attributes); // EPIM ATTRIBUTE LAST ONE;;

  const fanSeries = Attributes.getString("box-fan-series", attributes); // EPIM ATTRIBUTE
  const fanSize = Attributes.getString("box-fan-size", attributes); // EPIM ATTRIBUTE
  const maxShaftPowerKw = Attributes.getFloat("output-power-shaft-MAX-BASE-ALL", attributes)! / 1000; // EPIM ATTRIBUTE
  const motorPowerKw = Attributes.getFloat("input-power-motor-BASE-ALL", attributes)! / 1000; // EPIM ATTRIBUTE
  const motorCurrentA = Attributes.getFloat("input-current-motor", attributes); // EPIM ATTRIBUTE
  const fanType = Attributes.getString("box-fan-type", attributes); // EPIM ATTRIBUTE

  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const externalPressure = PropertyValueSet.getAmount<Quantity.Pressure>("staticPressure", calcParams);
  const airDensity = PropertyValueSet.getAmount<Quantity.Density>("airDensity", calcParams);
  const soundPressureDistanceInDecimeter = PropertyValueSet.getInteger("soundPressureDistance", calcParams);
  const soundPressureDistance =
    soundPressureDistanceInDecimeter !== undefined
      ? Amount.create(soundPressureDistanceInDecimeter, Units.Decimeter)
      : undefined;

  if (!airFlow) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "airFlow"));
  }
  if (!fanSeries) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanSeries"));
  }
  if (!fanSize) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanSize"));
  }
  if (!maxShaftPowerKw) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "maxShaftPowerKw"));
  }

  if (!motorPowerKw) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "motorPowerKw"));
  }
  if (!fanType) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanType"));
  }
  if (!externalPressure) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "staticPressure"));
  }
  if (!motorCurrentA) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "motorCurrent"));
  }
  if (!soundPressureDistance) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "soundPressureDistance"));
  }
  if (inputErrors.length > 0) {
    return createInputMapperError(inputErrors);
  }

  return createInputMapperSuccess({
    airFlow: airFlow,
    externalPressure: externalPressure,
    airDensity: airDensity,
    fanSeries: fanSeries!,
    soundPressureDistance: soundPressureDistance,
    queryResultMap: queryResultMap,
    calcParams: calcParams,
    frequency: frequency!,
    fanSize: fanSize!,
    maxShaftPowerKw: maxShaftPowerKw,
    motorPowerKw: motorPowerKw,
    accessoryPressureDrop: getAccessoriesList(queryResultMap.ct_AccessoryPressureDrop, properties),
    fanCondition: queryResultMap.ct_FanCondition.find((fc) => PropertyFilter.isValid(properties, fc.property_filter)),
    fanType: fanType!,
    motorCurrent: motorCurrentA!,
  });
}

function getAccessoriesList(
  accessoryPressureDrop: QP.AccessoryPressureDropTable,
  properties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<QP.AccessoryPressureDrop> {
  return accessoryPressureDrop.filter((a) => PropertyFilter.isValid(properties, a.property_filter));
}
