import type { UrlQuery, UrlResponse } from "./query-types";
import { exhaustiveCheck } from "../exhaustive-check/index";
import * as CacheSelectors from "./cache/cache-selectors";
import * as CacheLoader from "./cache/cache-loader";
import type { UrlCacheState, CacheLoadRequest } from "./cache/types";

export const initialCache: UrlCacheState = {
  blobByUrl: {},
  jsonByUrl: {},
};

export function selectQuery(cache: UrlCacheState | undefined = initialCache, query: UrlQuery): UrlResponse | undefined {
  switch (query.type) {
    case "Url/Blob":
      return CacheSelectors.selectUrlBlob(cache, query);
    case "Url/Json":
      return CacheSelectors.selectUrlJson(cache, query);
    default:
      return exhaustiveCheck(query, true);
  }
}

export async function loadQueries(
  cache: UrlCacheState = initialCache,
  queries: ReadonlyArray<UrlQuery>
): Promise<CacheLoader.CacheUpdate> {
  // Fold queries together to minimize number of requests when loading cache
  const fullCacheLoadRequest = mapQueriesToCacheLoadRequest(queries);

  // Remove things that are already present in cache
  const optimizedCacheLoadRequest = CacheLoader.optimizeLoadRequest(cache, fullCacheLoadRequest);

  // Now load the rest
  return CacheLoader.executeLoadRequest(optimizedCacheLoadRequest);
}

function mapQueriesToCacheLoadRequest(queries: ReadonlyArray<UrlQuery>): CacheLoadRequest {
  // Collect data to make cache load from all queries
  const blobUrls: Array<string> = [];
  const jsonUrls: Array<string> = [];
  for (const query of queries) {
    switch (query.type) {
      case "Url/Blob":
        blobUrls.push(query.url);
        break;
      case "Url/Json":
        jsonUrls.push(query.url);
        break;
      default:
        exhaustiveCheck(query);
        // If we are sent an unknown query type we do nothing
        break;
    }
  }

  return {
    blobUrls: blobUrls,
    jsonUrls: jsonUrls,
  };
}
