
import type { Curve, WorkingPoint } from "../result-items-types";
import { getSolutionValue } from "./solution";


export function calculateShaftPowerFromWorkingPoint(
    workingPoint: WorkingPoint,
    shaftPowerCurves: ReadonlyArray<Curve>,
    rpmCurves: ReadonlyArray<Curve>,
    fanSpeedRpm: number | undefined
  ): number | undefined {
    if (fanSpeedRpm === undefined) {
      return undefined;
    }
  
    if (workingPoint.solution.type === "Curve") {
      // If we are on the curve we can return the interpolated curve value
      return getSolutionValue(shaftPowerCurves, workingPoint.solution);
    }
  
    // If we are between two curves, we need to use the Fan Law to calculate shaft power (H)
    // = Ratio of specific heats
    const γ = 1.4;
    const barometricPressure = 101325;
    // // Air density at point A
  
    const lower = workingPoint.solution.lower;
    // Point a = the point on the lower curve
    const Q_A = lower.point.x / 1000; // convert to m3/s
    // Lower Point Shaft power
    const H_A = getSolutionValue(shaftPowerCurves, lower);
    if (H_A === undefined) {
      return undefined;
    }
    // Pressure for lower point
    const Pt_A = lower.point.y;
    //fan rotation speed at lower point
    const N_A = getSolutionValue(rpmCurves, lower);
    if (N_A === undefined) {
      return undefined;
    }
  
    // // fan rotation speed at workingPoint
    const N_c = fanSpeedRpm;
  
    const x = Pt_A / barometricPressure;
  
    const z = ((γ - 1) / γ) * (H_A / (Q_A * barometricPressure));
  
    const kp = (Math.log(1 + x) / x) * (z / Math.log(1 + z));
  
    const nt = (Q_A * Pt_A * kp) / H_A;
    //
    // eslint-disable-next-line no-restricted-properties
    const f1 = z * Math.pow(N_c / N_A, 2);
    const f2 = (nt * γ) / (γ - 1);
  
    // eslint-disable-next-line no-restricted-properties
    const KpC = 1 / (1 + (f2 - 1) * (f1 / 2) + ((f2 - 1) * (f2 - 1) * (f2 - 2) * Math.pow(f1, 2)) / 6);
    // eslint-disable-next-line no-restricted-properties
  
    // eslint-disable-next-line no-restricted-properties
    const shaftPower = H_A * Math.pow(N_c / N_A, 3) * (kp / KpC);
  
    return shaftPower;
  }