import type { CalculatorSuccess, OutputMapperResult } from "../types";
import { createOutputMapperSuccess } from "../types";
import type { SystemairHeatRecoveryUnit } from "../result-items-types";
import { createSystemairHeatRecoveryUnitResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<SystemairHeatRecoveryUnit>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createSystemairHeatRecoveryUnitResultItem(result.output),
    result.messages
  );
}
