import * as R from "ramda";
import * as CacheSelectors from "./cache/cache-selectors";
import * as CacheLoader from "./cache/cache-loader";
import { exhaustiveCheck } from "../exhaustive-check/index";
import type { ProductQuery, ProductResponse } from "./query-types";
import type { TableName } from "./table-types";
import type { CacheLoadRequest, ProductCacheState, Measurement } from "./cache/types";

export const initialCache: ProductCacheState = {
  productsByKey: undefined,
  products: undefined,
  tables: {},
  blobs: {},
  itemNoToProductId: undefined,
  variantNoToProductId: undefined,
  productIdToRetired: undefined,
  markerInfo: { type: "Loading" },
  markerInfoTime: 0,
  clearCache: true,
};

export const searchProducts: { readonly [productKey: string]: string } = {
  GANYMED_SEARCH_HEAT_RECOVERY_UNIT: "64F4BB90-5DA0-11E7-9A6A-4941B404A253",
  GANYMED_SEARCH_ELECTRIC_HEAT_WATER_COIL: "2B126100-5DA0-11E7-DE92-199EB319B78A",
  GANYMED_SEARCH_FAN: "8DE682C0-84B0-11E7-9CEF-6DD5C1DBC161",
  GANYMED_SEARCH_BOXFAN: "21574700-3260-11E9-C35E-2BDDE95BCFDC",
  GANYMED_SEARCH_FRICO: "AF3E4180-CEA0-11E9-F858-2BAE52FFA150",
  GANYMED_SEARCH_FANTECH_FAN: "A6113390-ABC0-11E7-D264-CD90F30F46EB",
  GANYMED_SEARCH_FANTECH_HRU: "17C809C0-ABC0-11E7-9A24-77C5C447D5CF",
  GANYMED_SEARCH_CENTRIFUGAL_FAN: "1E0F5660-CEA0-11E9-ADAD-D7E33F9762E9",
  GANYMED_SEARCH_FANTECH_HRU_FRESH_AIR: "D9B892C0-91F0-11EA-AD66-1B9769D3529B",
  GANYMED_SEARCH_FANTECH_HRU_COMMERCIAL: "F673EB80-91F0-11EA-9F17-A79DAAE01A00",
};

// Sync function for selecting directly from cache
export function selectQuery(
  cache: ProductCacheState | undefined = initialCache,
  query: ProductQuery
): ProductResponse | undefined {
  switch (query.type) {
    case "MarkerInfo":
      return cache.markerInfo.type === "Loading" ? undefined : cache.markerInfo;
    case "DataSource":
      return CacheSelectors.selectDataSource(cache);
    case "ProductsById":
      return CacheSelectors.selectProductsById(cache, query);
    case "ProductsByKey":
      return CacheSelectors.selectProductsByKey(cache, query);
    case "ProductById":
      return CacheSelectors.selectProductById(cache, query);
    case "ProductByKey":
      return CacheSelectors.selectProductByKey(cache, query);
    case "TableByProductId":
      return CacheSelectors.selectTableByProductId(cache, query);
    case "TablesByProductId":
      return CacheSelectors.selectTablesByProductId(cache, query);
    case "TablesByProductIds":
      return CacheSelectors.selectTablesByProductIds(cache, query);
    case "ProductIdByM3ItemNo":
      return CacheSelectors.selectProductIdByM3ItemNo(cache, query);
    case "ItemNoToProductId":
      return CacheSelectors.selectItemNoToProductId(cache);
    case "VariantNoToProductId":
      return CacheSelectors.selectVariantNoToProductId(cache);
    case "ProductIdToRetired":
      return CacheSelectors.selectProductIdToRetired(cache);
    case "Blob":
      return CacheSelectors.selectBlob(cache, query);
    case "MeasurementTable":
      return CacheSelectors.selectMeasurement(cache, query);
    default:
      return exhaustiveCheck(query, true);
  }
}

// Load the data necessary to resolve the query into cache
export async function loadQueries(
  baseAddress: string,
  markerName: string,
  cache: ProductCacheState = initialCache,
  queries: ReadonlyArray<ProductQuery>,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): Promise<ReadonlyArray<Promise<CacheLoader.CacheUpdate>>> {
  const loadRequests: Array<Promise<CacheLoader.CacheUpdate>> = [];

  // Make sure we have a current releaseId
  const newMarker = await CacheLoader.setCurrentReleaseOrTransactionFromMarkerIfNotSet(baseAddress, cache, markerName);
  if (newMarker) {
    loadRequests.push(new Promise((resolve) => resolve(() => newMarker)));
  }

  // Always load product index becuase we need it to optimize the other queries
  const loadProducts = await CacheLoader.loadProductsIfNotLoaded(baseAddress, newMarker ?? cache);
  if (loadProducts) {
    loadRequests.push(new Promise((resolve) => resolve(() => loadProducts)));
  }

  // Fold queries together to minimize number of requests when loading cache
  const fullCacheLoadRequest = mapQueriesToCacheLoadRequest(queries);

  // Convert measurement table request to regular product table request if measure tool isn't in use
  const withoutMtCacheLoadRequest = CacheLoader.convertMtRequests(fullCacheLoadRequest, useMeasureToolForMeasurements);

  // Remove things that are already present in cache
  const optimizedCacheLoadRequest = CacheLoader.optimizeLoadRequest(loadProducts ?? cache, withoutMtCacheLoadRequest);

  // Now load the rest
  loadRequests.push(
    ...CacheLoader.executeLoadRequest(
      baseAddress,
      loadProducts ?? cache,
      optimizedCacheLoadRequest,
      useMeasureToolForMeasurements,
      mtUrl
    )
  );

  return loadRequests;
}

function mapQueriesToCacheLoadRequest(queries: ReadonlyArray<ProductQuery>): CacheLoadRequest {
  const tableNamesByProductId: { [productId: string]: Array<TableName> } = {};
  const blobs: Array<string> = [];
  const measurementByKey: { [key: string]: Measurement } = {};

  // Collect data to make cache load from all queries
  for (const query of queries) {
    switch (query.type) {
      case "MarkerInfo":
      case "ProductsById":
      case "ProductsByKey":
      case "ProductById":
      case "ProductByKey":
        // Products are always loaded at start so no need to do it again
        break;
      case "DataSource":
        break;
      case "TableByProductId":
        if (tableNamesByProductId[query.productId] === undefined) {
          tableNamesByProductId[query.productId] = [query.table];
        } else {
          tableNamesByProductId[query.productId].push(query.table);
        }
        break;
      case "TablesByProductId":
        if (tableNamesByProductId[query.productId] === undefined) {
          tableNamesByProductId[query.productId] = [...query.tables];
        } else {
          tableNamesByProductId[query.productId].push(...query.tables);
        }
        break;
      case "TablesByProductIds":
        for (const productId of query.productIds) {
          if (tableNamesByProductId[productId] === undefined) {
            tableNamesByProductId[productId] = [...query.tables];
          } else {
            tableNamesByProductId[productId].push(...query.tables);
          }
        }
        break;
      case "ProductIdByM3ItemNo":
        break;
      case "ItemNoToProductId":
        break;
      case "VariantNoToProductId":
        break;
      case "ProductIdToRetired":
        break;
      case "Blob":
        blobs.push(query.url);
        break;
      case "MeasurementTable":
        {
          const key = `${query.variantId}|${query.productId}`;
          const currentTables = measurementByKey[key] ? measurementByKey[key].tables : [];
          measurementByKey[key] = {
            variantId: query.variantId,
            productId: query.productId,
            tables: [...currentTables, query.table],
          };
        }
        break;
      default:
        exhaustiveCheck(query);
        // If we are sent an unknown query type we do nothing
        break;
    }
  }

  const measurements = R.values(measurementByKey).map((m) => ({ ...m, tables: R.uniq(m.tables) }));

  return {
    tableNamesByProductId,
    measurements,
    blobs,
  };
}
