import type * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import type { Curve } from "shared-lib/system-calculator";
import { splineGetPoint } from "shared-lib/interpolation";
import * as CI from "./calculation-inputs";

export interface MaxEfficiencyPoint {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressure: Amount.Amount<Quantity.Pressure>;
}

export function findMaxEfficiencyWorkingPoint(airData: ReadonlyArray<QP.AirData>): MaxEfficiencyPoint | undefined {
  const pressureCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    airData.filter((p) => p.param === "Ps_v"),
    []
  );

  const powerCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "P_v"),
    []
  );

  if (pressureCurves.length === 0 || powerCurves.length === 0) {
    return undefined;
  }

  const maxPressureCurve = pressureCurves[pressureCurves.length - 1];
  const maxPowerCurve = powerCurves[powerCurves.length - 1];

  const precision = 20;

  let eMax = 0;
  let xeMax = 0;
  const step = maxPressureCurve.workMax - maxPressureCurve.workMin;

  for (let i = 0; i <= 20; i++) {
    const x = maxPressureCurve.workMin + (i * step) / precision;
    const e = calculateEfficiency(x, maxPressureCurve, maxPowerCurve);
    if (!e) {
      continue;
    }
    if (e > eMax) {
      eMax = e;
      xeMax = x;
    }
  }
  if (!xeMax) {
    return undefined;
  }

  return {
    airFlow: Amount.create(xeMax, Units.LiterPerSecond),
    pressure: Amount.create(
      Math.round((calculatePressureForAirflow(xeMax, maxPressureCurve)! * 100) / 100),
      Units.Pascal
    ),
  };
}

function calculateEfficiency(x: number, pressureCurve: Curve, powerCurve: Curve): number | undefined {
  const pressure = calculatePressureForAirflow(x, pressureCurve);
  const power = calculatePowerForAirflow(x, powerCurve);
  return pressure && power && (x * pressure) / power;
}

function calculatePressureForAirflow(x: number, pressureCurve: Curve): number | undefined {
  return splineGetPoint(x, pressureCurve.spline);
}
function calculatePowerForAirflow(x: number, powerCurve: Curve): number | undefined {
  return splineGetPoint(x, powerCurve.spline);
}
