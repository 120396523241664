// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IdentityQuery = Noop | IdentityValueQuery<any>;
export type UrlResponse = Noop | IdentityResponse;

// Identity, just returns the value
export interface IdentityValueQuery<TValue> {
  readonly type: "Identity/Identity";
  readonly source: "identity";
  readonly value: TValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IdentityResponse = any;
export function createIdentityQuery<TValue>(value: TValue): IdentityValueQuery<TValue> {
  if (value === undefined) {
    throw new Error("value of identity query cannot be undefined");
  }
  return { type: "Identity/Identity", source: "identity", value };
}

// Noop
export interface Noop {
  readonly source: "identity";
  readonly type: "Identity/Noop";
}
export type NoopResponse = unknown;
export function noop(): Noop {
  return {
    source: "identity",
    type: "Identity/Noop",
  };
}
