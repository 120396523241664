/* eslint-disable max-lines */
export interface NominalMotorEfficiencyRow {
  readonly motorPower: number;
  readonly pole2?: number;
  readonly pole4?: number;
  readonly pole6?: number;
  readonly pole8?: number;
}

export const ODP_HP_Motor: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 0.77,
    pole4: 0.855,
    pole6: 0.825,
    pole8: 0.755,
  },
  {
    motorPower: 1.5,
    pole2: 0.84,
    pole4: 0.865,
    pole6: 0.865,
    pole8: 0.77,
  },
  {
    motorPower: 2,
    pole2: 0.855,
    pole4: 0.865,
    pole6: 0.875,
    pole8: 0.865,
  },
  {
    motorPower: 3,
    pole2: 0.855,
    pole4: 0.895,
    pole6: 0.885,
    pole8: 0.875,
  },
  {
    motorPower: 5,
    pole2: 0.865,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.885,
  },
  {
    motorPower: 7.5,
    pole2: 0.885,
    pole4: 0.91,
    pole6: 0.902,
    pole8: 0.895,
  },
  {
    motorPower: 10,
    pole2: 0.895,
    pole4: 0.917,
    pole6: 0.917,
    pole8: 0.902,
  },
  {
    motorPower: 15,
    pole2: 0.902,
    pole4: 0.93,
    pole6: 0.917,
    pole8: 0.902,
  },
  {
    motorPower: 20,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.924,
    pole8: 0.91,
  },
  {
    motorPower: 25,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.91,
  },
  {
    motorPower: 30,
    pole2: 0.917,
    pole4: 0.941,
    pole6: 0.936,
    pole8: 0.917,
  },
  {
    motorPower: 40,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 50,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 60,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.93,
  },
  {
    motorPower: 75,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.941,
  },
  {
    motorPower: 100,
    pole2: 0.936,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 125,
    pole2: 0.941,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 150,
    pole2: 0.941,
    pole4: 0.958,
    pole6: 0.954,
    pole8: 0.941,
  },
  {
    motorPower: 200,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.954,
    pole8: 0.941,
  },
  {
    motorPower: 250,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.958,
    pole8: 0.95,
  },
  {
    motorPower: 300,
    pole2: 0.954,
    pole4: 0.958,
    pole6: 0.958,
  },
  {
    motorPower: 350,
    pole2: 0.954,
    pole4: 0.958,
    pole6: 0.958,
  },
  {
    motorPower: 400,
    pole2: 0.958,
    pole4: 0.958,
  },
  {
    motorPower: 450,
    pole2: 0.962,
    pole4: 0.962,
  },
  {
    motorPower: 500,
    pole2: 0.962,
    pole4: 0.962,
  },
];

export const TEFC_HP_Motor: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 0.77,
    pole4: 0.855,
    pole6: 0.825,
    pole8: 0.755,
  },
  {
    motorPower: 1.5,
    pole2: 0.84,
    pole4: 0.865,
    pole6: 0.875,
    pole8: 0.785,
  },
  {
    motorPower: 2,
    pole2: 0.855,
    pole4: 0.865,
    pole6: 0.885,
    pole8: 0.84,
  },
  {
    motorPower: 3,
    pole2: 0.865,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.855,
  },
  {
    motorPower: 5,
    pole2: 0.885,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.865,
  },
  {
    motorPower: 7.5,
    pole2: 0.895,
    pole4: 0.917,
    pole6: 0.91,
    pole8: 0.865,
  },
  {
    motorPower: 10,
    pole2: 0.902,
    pole4: 0.917,
    pole6: 0.91,
    pole8: 0.895,
  },
  {
    motorPower: 15,
    pole2: 0.91,
    pole4: 0.924,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 20,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.917,
    pole8: 0.902,
  },
  {
    motorPower: 25,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.902,
  },
  {
    motorPower: 30,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.917,
  },
  {
    motorPower: 40,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 50,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 60,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.924,
  },
  {
    motorPower: 75,
    pole2: 0.936,
    pole4: 0.954,
    pole6: 0.945,
    pole8: 0.936,
  },
  {
    motorPower: 100,
    pole2: 0.941,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.936,
  },
  {
    motorPower: 125,
    pole2: 0.95,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 150,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.958,
    pole8: 0.941,
  },
  {
    motorPower: 200,
    pole2: 0.954,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.945,
  },
  {
    motorPower: 250,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.95,
  },
  {
    motorPower: 300,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
  },
  {
    motorPower: 350,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
  },
  {
    motorPower: 400,
    pole2: 0.958,
    pole4: 0.962,
  },
  {
    motorPower: 450,
    pole2: 0.958,
    pole4: 0.962,
  },
  {
    motorPower: 500,
    pole2: 0.958,
    pole4: 0.962,
  },
];

export const ODP_KW_MOTOR: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 0.75,
    pole2: 0.77,
    pole4: 0.855,
    pole6: 0.825,
    pole8: 0.755,
  },
  {
    motorPower: 1.1,
    pole2: 0.84,
    pole4: 0.865,
    pole6: 0.865,
    pole8: 0.785,
  },
  {
    motorPower: 1.5,
    pole2: 0.855,
    pole4: 0.865,
    pole6: 0.875,
    pole8: 0.84,
  },
  {
    motorPower: 2.2,
    pole2: 0.855,
    pole4: 0.895,
    pole6: 0.885,
    pole8: 0.855,
  },
  {
    motorPower: 3.7,
    pole2: 0.865,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.865,
  },
  {
    motorPower: 5.5,
    pole2: 0.885,
    pole4: 0.91,
    pole6: 0.902,
    pole8: 0.865,
  },
  {
    motorPower: 7.5,
    pole2: 0.895,
    pole4: 0.917,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 11,
    pole2: 0.902,
    pole4: 0.93,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 15,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.924,
    pole8: 0.902,
  },
  {
    motorPower: 18.5,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.902,
  },
  {
    motorPower: 22,
    pole2: 0.917,
    pole4: 0.941,
    pole6: 0.936,
    pole8: 0.917,
  },
  {
    motorPower: 30,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 37,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 45,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.924,
  },
  {
    motorPower: 55,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.936,
  },
  {
    motorPower: 75,
    pole2: 0.936,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 90,
    pole2: 0.941,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 110,
    pole2: 0.941,
    pole4: 0.958,
    pole6: 0.954,
    pole8: 0.945,
  },
  {
    motorPower: 150,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.954,
    pole8: 0.945,
  },
  {
    motorPower: 186,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.958,
    pole8: 0.95,
  },
  {
    motorPower: 224,
    pole2: 0.954,
    pole4: 0.958,
    pole6: 0.958,
  },
  {
    motorPower: 261,
    pole2: 0.954,
    pole4: 0.958,
    pole6: 0.958,
  },
  {
    motorPower: 298,
    pole2: 0.958,
    pole4: 0.958,
  },
  {
    motorPower: 336,
    pole2: 0.962,
    pole4: 0.962,
  },
  {
    motorPower: 373,
    pole2: 0.962,
    pole4: 0.962,
  },
];

export const TEFC_KW_Motor: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 0.75,
    pole2: 0.77,
    pole4: 0.855,
    pole6: 0.825,
    pole8: 0.755,
  },
  {
    motorPower: 1.1,
    pole2: 0.84,
    pole4: 0.865,
    pole6: 0.875,
    pole8: 0.785,
  },
  {
    motorPower: 1.5,
    pole2: 0.855,
    pole4: 0.865,
    pole6: 0.885,
    pole8: 0.84,
  },
  {
    motorPower: 2.2,
    pole2: 0.865,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.855,
  },
  {
    motorPower: 3.7,
    pole2: 0.885,
    pole4: 0.895,
    pole6: 0.895,
    pole8: 0.865,
  },
  {
    motorPower: 5.5,
    pole2: 0.895,
    pole4: 0.917,
    pole6: 0.91,
    pole8: 0.865,
  },
  {
    motorPower: 7.5,
    pole2: 0.902,
    pole4: 0.917,
    pole6: 0.91,
    pole8: 0.895,
  },
  {
    motorPower: 11,
    pole2: 0.91,
    pole4: 0.924,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 15,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.917,
    pole8: 0.902,
  },
  {
    motorPower: 18.5,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.902,
  },
  {
    motorPower: 22,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.917,
  },
  {
    motorPower: 30,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 37,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 45,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.945,
    pole8: 0.924,
  },
  {
    motorPower: 55,
    pole2: 0.936,
    pole4: 0.954,
    pole6: 0.945,
    pole8: 0.936,
  },
  {
    motorPower: 75,
    pole2: 0.941,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.936,
  },
  {
    motorPower: 90,
    pole2: 0.95,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.941,
  },
  {
    motorPower: 110,
    pole2: 0.95,
    pole4: 0.958,
    pole6: 0.958,
    pole8: 0.941,
  },
  {
    motorPower: 150,
    pole2: 0.954,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.945,
  },
  {
    motorPower: 186,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.95,
  },
  {
    motorPower: 224,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
  },
  {
    motorPower: 261,
    pole2: 0.958,
    pole4: 0.962,
    pole6: 0.958,
  },
  {
    motorPower: 298,
    pole2: 0.958,
    pole4: 0.962,
  },
  {
    motorPower: 336,
    pole2: 0.958,
    pole4: 0.962,
  },
  {
    motorPower: 373,
    pole2: 0.958,
    pole4: 0.962,
  },
];

export const IE1_50Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 72.1,
    pole4: 72.1,
    pole6: 70.0,
  },
  {
    motorPower: 1.1,
    pole2: 75.0,
    pole4: 75.0,
    pole6: 72.9,
    pole8: 66.5,
  },
  {
    motorPower: 1.5,
    pole2: 77.2,
    pole4: 77.2,
    pole6: 75.2,
    pole8: 70.2,
  },
  {
    motorPower: 2.2,
    pole2: 79.7,
    pole4: 79.7,
    pole6: 77.7,
    pole8: 74.2,
  },
  {
    motorPower: 3,
    pole2: 81.5,
    pole4: 81.5,
    pole6: 79.7,
    pole8: 77.0,
  },
  {
    motorPower: 4,
    pole2: 83.1,
    pole4: 83.1,
    pole6: 81.4,
    pole8: 79.2,
  },
  {
    motorPower: 5.5,
    pole2: 84.7,
    pole4: 84.7,
    pole6: 83.1,
    pole8: 81.4,
  },
  {
    motorPower: 7.5,
    pole2: 86.0,
    pole4: 86.0,
    pole6: 84.7,
    pole8: 83.1,
  },
  {
    motorPower: 11,
    pole2: 87.6,
    pole4: 87.6,
    pole6: 86.4,
    pole8: 85.0,
  },
  {
    motorPower: 15,
    pole2: 88.7,
    pole4: 88.7,
    pole6: 87.7,
    pole8: 86.2,
  },
  {
    motorPower: 18.5,
    pole2: 89.3,
    pole4: 89.3,
    pole6: 88.6,
    pole8: 86.9,
  },
  {
    motorPower: 22,
    pole2: 89.9,
    pole4: 89.9,
    pole6: 89.2,
    pole8: 87.4,
  },
  {
    motorPower: 30,
    pole2: 90.7,
    pole4: 90.7,
    pole6: 90.2,
    pole8: 88.3,
  },
  {
    motorPower: 37,
    pole2: 91.2,
    pole4: 91.2,
    pole6: 90.8,
    pole8: 88.8,
  },
  {
    motorPower: 45,
    pole2: 91.7,
    pole4: 91.7,
    pole6: 91.4,
    pole8: 89.2,
  },
  {
    motorPower: 55,
    pole2: 92.1,
    pole4: 92.1,
    pole6: 91.9,
    pole8: 89.7,
  },
  {
    motorPower: 75,
    pole2: 92.7,
    pole4: 92.7,
    pole6: 92.6,
    pole8: 90.3,
  },
  {
    motorPower: 90,
    pole2: 93.0,
    pole4: 93.0,
    pole6: 92.9,
    pole8: 90.7,
  },
  {
    motorPower: 110,
    pole2: 93.3,
    pole4: 93.3,
    pole6: 93.3,
    pole8: 91.1,
  },
  {
    motorPower: 132,
    pole2: 93.5,
    pole4: 93.5,
    pole6: 93.5,
    pole8: 91.5,
  },
  {
    motorPower: 160,
    pole2: 93.8,
    pole4: 93.8,
    pole6: 93.8,
    pole8: 91.9,
  },
  {
    motorPower: 200,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 220,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 250,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 315,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 355,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 400,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 450,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 500,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
  {
    motorPower: 1000,
    pole2: 94.0,
    pole4: 94.0,
    pole6: 94.0,
    pole8: 92.5,
  },
];

export const IE2_50Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 77.4,
    pole4: 79.6,
    pole6: 75.9,
  },
  {
    motorPower: 1.1,
    pole2: 79.6,
    pole4: 81.4,
    pole6: 78.1,
    pole8: 70.8,
  },
  {
    motorPower: 1.5,
    pole2: 81.3,
    pole4: 82.8,
    pole6: 79.8,
    pole8: 74.1,
  },
  {
    motorPower: 2.2,
    pole2: 83.2,
    pole4: 84.3,
    pole6: 81.8,
    pole8: 77.6,
  },
  {
    motorPower: 3,
    pole2: 84.6,
    pole4: 85.5,
    pole6: 83.3,
    pole8: 80.0,
  },
  {
    motorPower: 4,
    pole2: 85.8,
    pole4: 86.6,
    pole6: 84.6,
    pole8: 81.9,
  },
  {
    motorPower: 5.5,
    pole2: 87.0,
    pole4: 87.7,
    pole6: 86.0,
    pole8: 83.8,
  },
  {
    motorPower: 7.5,
    pole2: 88.1,
    pole4: 88.7,
    pole6: 87.2,
    pole8: 85.3,
  },
  {
    motorPower: 11,
    pole2: 89.4,
    pole4: 89.8,
    pole6: 88.7,
    pole8: 86.9,
  },
  {
    motorPower: 15,
    pole2: 90.3,
    pole4: 90.6,
    pole6: 89.7,
    pole8: 88.0,
  },
  {
    motorPower: 18.5,
    pole2: 90.9,
    pole4: 91.2,
    pole6: 90.4,
    pole8: 88.6,
  },
  {
    motorPower: 22,
    pole2: 91.3,
    pole4: 91.6,
    pole6: 90.9,
    pole8: 89.1,
  },
  {
    motorPower: 30,
    pole2: 92.0,
    pole4: 92.3,
    pole6: 91.7,
    pole8: 89.8,
  },
  {
    motorPower: 37,
    pole2: 92.5,
    pole4: 92.7,
    pole6: 92.2,
    pole8: 90.3,
  },
  {
    motorPower: 45,
    pole2: 92.9,
    pole4: 93.1,
    pole6: 92.7,
    pole8: 90.7,
  },
  {
    motorPower: 55,
    pole2: 93.2,
    pole4: 93.5,
    pole6: 93.1,
    pole8: 91.0,
  },
  {
    motorPower: 75,
    pole2: 93.8,
    pole4: 94.0,
    pole6: 93.7,
    pole8: 91.6,
  },
  {
    motorPower: 90,
    pole2: 94.1,
    pole4: 94.2,
    pole6: 94.0,
    pole8: 91.9,
  },
  {
    motorPower: 110,
    pole2: 94.3,
    pole4: 94.5,
    pole6: 94.3,
    pole8: 92.3,
  },
  {
    motorPower: 132,
    pole2: 94.6,
    pole4: 94.7,
    pole6: 94.6,
    pole8: 92.6,
  },
  {
    motorPower: 160,
    pole2: 94.8,
    pole4: 94.9,
    pole6: 94.8,
    pole8: 93.0,
  },
  {
    motorPower: 200,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 220,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 250,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 315,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 355,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 400,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 450,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 500,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
  {
    motorPower: 1000,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
    pole8: 93.5,
  },
];

export const IE3_50Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 80.7,
    pole4: 82.5,
    pole6: 78.9,
  },
  {
    motorPower: 1.1,
    pole2: 82.7,
    pole4: 84.1,
    pole6: 81.0,
    pole8: 77.7,
  },
  {
    motorPower: 1.5,
    pole2: 84.2,
    pole4: 85.3,
    pole6: 82.5,
    pole8: 79.7,
  },
  {
    motorPower: 2.2,
    pole2: 85.9,
    pole4: 86.7,
    pole6: 84.3,
    pole8: 81.9,
  },
  {
    motorPower: 3,
    pole2: 87.1,
    pole4: 87.7,
    pole6: 85.6,
    pole8: 83.5,
  },
  {
    motorPower: 4,
    pole2: 88.1,
    pole4: 88.6,
    pole6: 86.8,
    pole8: 84.8,
  },
  {
    motorPower: 5.5,
    pole2: 89.2,
    pole4: 89.6,
    pole6: 88.0,
    pole8: 86.2,
  },
  {
    motorPower: 7.5,
    pole2: 90.1,
    pole4: 90.4,
    pole6: 89.1,
    pole8: 87.3,
  },
  {
    motorPower: 11,
    pole2: 91.2,
    pole4: 91.4,
    pole6: 90.3,
    pole8: 88.6,
  },
  {
    motorPower: 15,
    pole2: 91.9,
    pole4: 92.1,
    pole6: 91.2,
    pole8: 89.6,
  },
  {
    motorPower: 18.5,
    pole2: 92.4,
    pole4: 92.6,
    pole6: 91.7,
    pole8: 90.1,
  },
  {
    motorPower: 22,
    pole2: 92.7,
    pole4: 93.0,
    pole6: 92.2,
    pole8: 90.6,
  },
  {
    motorPower: 30,
    pole2: 93.3,
    pole4: 93.6,
    pole6: 92.9,
    pole8: 91.3,
  },
  {
    motorPower: 37,
    pole2: 93.7,
    pole4: 93.9,
    pole6: 93.3,
    pole8: 91.8,
  },
  {
    motorPower: 45,
    pole2: 94.0,
    pole4: 94.2,
    pole6: 93.7,
    pole8: 92.2,
  },
  {
    motorPower: 55,
    pole2: 94.3,
    pole4: 94.6,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 75,
    pole2: 94.7,
    pole4: 95.0,
    pole6: 94.6,
    pole8: 93.1,
  },
  {
    motorPower: 90,
    pole2: 95.0,
    pole4: 95.2,
    pole6: 94.9,
    pole8: 93.4,
  },
  {
    motorPower: 110,
    pole2: 95.2,
    pole4: 95.4,
    pole6: 95.1,
    pole8: 93.7,
  },
  {
    motorPower: 132,
    pole2: 95.4,
    pole4: 95.6,
    pole6: 95.4,
    pole8: 94.0,
  },
  {
    motorPower: 160,
    pole2: 95.6,
    pole4: 95.8,
    pole6: 95.6,
    pole8: 94.3,
  },
  {
    motorPower: 200,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 220,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 250,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 315,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 355,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 400,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 450,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 500,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
  {
    motorPower: 1000,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
    pole8: 94.6,
  },
];

export const IE4_50Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 0.12,
    pole2: 0.665,
    pole4: 0.698,
    pole6: 0.649,
    pole8: 0.623,
  },
  {
    motorPower: 0.18,
    pole2: 0.708,
    pole4: 0.747,
    pole6: 0.701,
    pole8: 0.672,
  },
  {
    motorPower: 0.2,
    pole2: 0.719,
    pole4: 0.758,
    pole6: 0.714,
    pole8: 0.684,
  },
  {
    motorPower: 0.25,
    pole2: 0.743,
    pole4: 0.779,
    pole6: 0.741,
    pole8: 0.708,
  },
  {
    motorPower: 0.37,
    pole2: 0.781,
    pole4: 0.811,
    pole6: 0.78,
    pole8: 0.743,
  },
  {
    motorPower: 0.4,
    pole2: 0.789,
    pole4: 0.817,
    pole6: 0.787,
    pole8: 0.749,
  },
  {
    motorPower: 0.55,
    pole2: 0.815,
    pole4: 0.839,
    pole6: 0.809,
    pole8: 0.77,
  },
  {
    motorPower: 0.75,
    pole2: 0.835,
    pole4: 0.857,
    pole6: 0.827,
    pole8: 0.784,
  },
  {
    motorPower: 1.1,
    pole2: 0.852,
    pole4: 0.872,
    pole6: 0.845,
    pole8: 0.808,
  },
  {
    motorPower: 1.5,
    pole2: 0.865,
    pole4: 0.882,
    pole6: 0.859,
    pole8: 0.826,
  },
  {
    motorPower: 2.2,
    pole2: 0.88,
    pole4: 0.895,
    pole6: 0.874,
    pole8: 0.845,
  },
  {
    motorPower: 3,
    pole2: 0.891,
    pole4: 0.904,
    pole6: 0.886,
    pole8: 0.859,
  },
  {
    motorPower: 4,
    pole2: 0.9,
    pole4: 0.911,
    pole6: 0.895,
    pole8: 0.871,
  },
  {
    motorPower: 5.5,
    pole2: 0.909,
    pole4: 0.919,
    pole6: 0.905,
    pole8: 0.883,
  },
  {
    motorPower: 7.5,
    pole2: 0.917,
    pole4: 0.926,
    pole6: 0.913,
    pole8: 0.893,
  },
  {
    motorPower: 11,
    pole2: 0.926,
    pole4: 0.933,
    pole6: 0.923,
    pole8: 0.904,
  },
  {
    motorPower: 15,
    pole2: 0.933,
    pole4: 0.939,
    pole6: 0.929,
    pole8: 0.912,
  },
  {
    motorPower: 18.5,
    pole2: 0.937,
    pole4: 0.942,
    pole6: 0.934,
    pole8: 0.917,
  },
  {
    motorPower: 22,
    pole2: 0.94,
    pole4: 0.945,
    pole6: 0.937,
    pole8: 0.921,
  },
  {
    motorPower: 30,
    pole2: 0.945,
    pole4: 0.949,
    pole6: 0.942,
    pole8: 0.927,
  },
  {
    motorPower: 37,
    pole2: 0.948,
    pole4: 0.952,
    pole6: 0.945,
    pole8: 0.931,
  },
  {
    motorPower: 45,
    pole2: 0.95,
    pole4: 0.954,
    pole6: 0.948,
    pole8: 0.934,
  },
  {
    motorPower: 55,
    pole2: 0.953,
    pole4: 0.957,
    pole6: 0.951,
    pole8: 0.937,
  },
  {
    motorPower: 75,
    pole2: 0.956,
    pole4: 0.96,
    pole6: 0.954,
    pole8: 0.942,
  },
  {
    motorPower: 90,
    pole2: 0.958,
    pole4: 0.961,
    pole6: 0.956,
    pole8: 0.944,
  },
  {
    motorPower: 110,
    pole2: 0.96,
    pole4: 0.963,
    pole6: 0.958,
    pole8: 0.947,
  },
  {
    motorPower: 132,
    pole2: 0.962,
    pole4: 0.964,
    pole6: 0.96,
    pole8: 0.949,
  },
  {
    motorPower: 160,
    pole2: 0.963,
    pole4: 0.966,
    pole6: 0.962,
    pole8: 0.951,
  },
  {
    motorPower: 200,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.963,
    pole8: 0.954,
  },
  {
    motorPower: 250,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.965,
    pole8: 0.954,
  },
  {
    motorPower: 315,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
  {
    motorPower: 355,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
  {
    motorPower: 400,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
  {
    motorPower: 450,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
  {
    motorPower: 500,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
  {
    motorPower: 1000,
    pole2: 0.965,
    pole4: 0.967,
    pole6: 0.966,
    pole8: 0.954,
  },
];
export const IE1_60Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1.1,
    pole2: 78.5,
    pole4: 79.0,
    pole6: 75.0,
    pole8: 73.5,
  },
  {
    motorPower: 1.5,
    pole2: 81.0,
    pole4: 81.5,
    pole6: 77.0,
    pole8: 77.0,
  },
  {
    motorPower: 2.2,
    pole2: 81.5,
    pole4: 83.0,
    pole6: 78.5,
    pole8: 78.0,
  },
  {
    motorPower: 3.7,
    pole2: 84.5,
    pole4: 85.0,
    pole6: 83.5,
    pole8: 80.0,
  },
  {
    motorPower: 5.5,
    pole2: 86.0,
    pole4: 87.0,
    pole6: 85.0,
    pole8: 84.0,
  },
  {
    motorPower: 7.5,
    pole2: 87.5,
    pole4: 87.5,
    pole6: 86.0,
    pole8: 85.0,
  },
  {
    motorPower: 11,
    pole2: 87.5,
    pole4: 88.5,
    pole6: 89.0,
    pole8: 87.5,
  },
  {
    motorPower: 15,
    pole2: 88.5,
    pole4: 89.5,
    pole6: 89.5,
    pole8: 88.5,
  },
  {
    motorPower: 18.5,
    pole2: 89.5,
    pole4: 90.5,
    pole6: 90.2,
    pole8: 88.5,
  },
  {
    motorPower: 22,
    pole2: 89.5,
    pole4: 91.0,
    pole6: 91.0,
    pole8: 90.2,
  },
  {
    motorPower: 30,
    pole2: 90.2,
    pole4: 91.7,
    pole6: 91.7,
    pole8: 90.2,
  },
  {
    motorPower: 37,
    pole2: 91.5,
    pole4: 92.4,
    pole6: 91.7,
    pole8: 91.0,
  },
  {
    motorPower: 45,
    pole2: 91.7,
    pole4: 93.0,
    pole6: 91.7,
    pole8: 91.0,
  },
  {
    motorPower: 55,
    pole2: 92.4,
    pole4: 93.0,
    pole6: 92.1,
    pole8: 91.5,
  },
  {
    motorPower: 75,
    pole2: 93.0,
    pole4: 93.2,
    pole6: 93.0,
    pole8: 92.0,
  },
  {
    motorPower: 90,
    pole2: 93.0,
    pole4: 93.2,
    pole6: 93.0,
    pole8: 92.5,
  },
  {
    motorPower: 110,
    pole2: 93.0,
    pole4: 93.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 150,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 185,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 220,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 250,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 315,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 335,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 375,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 500,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
  {
    motorPower: 1000,
    pole2: 94.1,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.5,
  },
];

export const IE2_60Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1.1,
    pole2: 82.5,
    pole4: 84.0,
    pole6: 85.5,
    pole8: 75.5,
  },
  {
    motorPower: 1.5,
    pole2: 84.0,
    pole4: 84.0,
    pole6: 86.5,
    pole8: 82.5,
  },
  {
    motorPower: 2.2,
    pole2: 85.5,
    pole4: 87.5,
    pole6: 87.5,
    pole8: 84.0,
  },
  {
    motorPower: 3.7,
    pole2: 87.5,
    pole4: 87.5,
    pole6: 87.5,
    pole8: 85.5,
  },
  {
    motorPower: 5.5,
    pole2: 88.5,
    pole4: 89.5,
    pole6: 89.5,
    pole8: 85.5,
  },
  {
    motorPower: 7.5,
    pole2: 89.5,
    pole4: 89.5,
    pole6: 89.5,
    pole8: 88.5,
  },
  {
    motorPower: 11,
    pole2: 90.2,
    pole4: 91.0,
    pole6: 90.2,
    pole8: 88.5,
  },
  {
    motorPower: 15,
    pole2: 90.2,
    pole4: 91.0,
    pole6: 90.2,
    pole8: 89.5,
  },
  {
    motorPower: 18.5,
    pole2: 91.0,
    pole4: 92.4,
    pole6: 91.7,
    pole8: 89.5,
  },
  {
    motorPower: 22,
    pole2: 91.0,
    pole4: 92.4,
    pole6: 91.7,
    pole8: 91.0,
  },
  {
    motorPower: 30,
    pole2: 91.7,
    pole4: 93.0,
    pole6: 93.0,
    pole8: 91.0,
  },
  {
    motorPower: 37,
    pole2: 92.4,
    pole4: 93.0,
    pole6: 93.0,
    pole8: 91.7,
  },
  {
    motorPower: 45,
    pole2: 93.0,
    pole4: 93.6,
    pole6: 93.6,
    pole8: 91.7,
  },
  {
    motorPower: 55,
    pole2: 93.0,
    pole4: 94.1,
    pole6: 93.6,
    pole8: 93.0,
  },
  {
    motorPower: 75,
    pole2: 93.6,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 93.0,
  },
  {
    motorPower: 90,
    pole2: 94.5,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 93.6,
  },
  {
    motorPower: 110,
    pole2: 94.5,
    pole4: 95.0,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 150,
    pole2: 95.0,
    pole4: 95.0,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 185,
    pole2: 95.4,
    pole4: 95.0,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 220,
    pole2: 95.4,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 250,
    pole2: 95.4,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 315,
    pole2: 95.4,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 335,
    pole2: 95.4,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 375,
    pole2: 95.4,
    pole4: 95.8,
    pole6: 95.0,
    pole8: 94.1,
  },
  {
    motorPower: 500,
    pole2: 95.4,
    pole4: 95.8,
    pole6: 95.0,
    pole8: 94.1,
  },
  {
    motorPower: 1000,
    pole2: 95.4,
    pole4: 95.8,
    pole6: 95.0,
    pole8: 94.1,
  },
];

export const IE3_60Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1.1,
    pole2: 84.0,
    pole4: 86.5,
    pole6: 87.5,
    pole8: 78.5,
  },
  {
    motorPower: 1.5,
    pole2: 85.5,
    pole4: 86.5,
    pole6: 88.5,
    pole8: 84.0,
  },
  {
    motorPower: 2.2,
    pole2: 86.5,
    pole4: 89.5,
    pole6: 89.5,
    pole8: 85.5,
  },
  {
    motorPower: 3.7,
    pole2: 88.5,
    pole4: 89.5,
    pole6: 89.5,
    pole8: 86.5,
  },
  {
    motorPower: 5.5,
    pole2: 89.5,
    pole4: 91.7,
    pole6: 91.0,
    pole8: 86.5,
  },
  {
    motorPower: 7.5,
    pole2: 90.2,
    pole4: 91.7,
    pole6: 91.0,
    pole8: 89.5,
  },
  {
    motorPower: 11,
    pole2: 91.0,
    pole4: 92.4,
    pole6: 91.7,
    pole8: 89.5,
  },
  {
    motorPower: 15,
    pole2: 91.0,
    pole4: 93.0,
    pole6: 91.7,
    pole8: 90.2,
  },
  {
    motorPower: 18.5,
    pole2: 91.7,
    pole4: 93.6,
    pole6: 93.0,
    pole8: 90.2,
  },
  {
    motorPower: 22,
    pole2: 91.7,
    pole4: 93.6,
    pole6: 93.0,
    pole8: 91.7,
  },
  {
    motorPower: 30,
    pole2: 92.4,
    pole4: 94.1,
    pole6: 94.1,
    pole8: 91.7,
  },
  {
    motorPower: 37,
    pole2: 93.0,
    pole4: 94.5,
    pole6: 94.1,
    pole8: 92.4,
  },
  {
    motorPower: 45,
    pole2: 93.6,
    pole4: 95.0,
    pole6: 94.5,
    pole8: 92.4,
  },
  {
    motorPower: 55,
    pole2: 93.6,
    pole4: 95.4,
    pole6: 94.5,
    pole8: 93.6,
  },
  {
    motorPower: 75,
    pole2: 94.1,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 93.6,
  },
  {
    motorPower: 90,
    pole2: 95.0,
    pole4: 95.4,
    pole6: 95.0,
    pole8: 94.1,
  },
  {
    motorPower: 110,
    pole2: 95.0,
    pole4: 95.8,
    pole6: 95.8,
    pole8: 94.1,
  },
  {
    motorPower: 150,
    pole2: 95.4,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 94.5,
  },
  {
    motorPower: 185,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 220,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 250,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 315,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 335,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 375,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 500,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
  {
    motorPower: 1000,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.8,
    pole8: 95.0,
  },
];

export const IE4_60Hz: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 0.12,
    pole2: 0.66,
    pole4: 0.7,
    pole6: 0.68,
    pole8: 0.64,
  },
  {
    motorPower: 0.18,
    pole2: 0.7,
    pole4: 0.74,
    pole6: 0.72,
    pole8: 0.68,
  },
  {
    motorPower: 0.25,
    pole2: 0.74,
    pole4: 0.77,
    pole6: 0.755,
    pole8: 0.72,
  },
  {
    motorPower: 0.37,
    pole2: 0.77,
    pole4: 0.815,
    pole6: 0.785,
    pole8: 0.755,
  },
  {
    motorPower: 0.55,
    pole2: 0.8,
    pole4: 0.84,
    pole6: 0.825,
    pole8: 0.77,
  },
  {
    motorPower: 0.75,
    pole2: 0.825,
    pole4: 0.855,
    pole6: 0.84,
    pole8: 0.785,
  },
  {
    motorPower: 1.1,
    pole2: 0.855,
    pole4: 0.875,
    pole6: 0.885,
    pole8: 0.815,
  },
  {
    motorPower: 1.5,
    pole2: 0.865,
    pole4: 0.885,
    pole6: 0.895,
    pole8: 0.855,
  },
  {
    motorPower: 2.2,
    pole2: 0.885,
    pole4: 0.91,
    pole6: 0.902,
    pole8: 0.875,
  },
  {
    motorPower: 3.7,
    pole2: 0.895,
    pole4: 0.91,
    pole6: 0.902,
    pole8: 0.885,
  },
  {
    motorPower: 5.5,
    pole2: 0.902,
    pole4: 0.924,
    pole6: 0.917,
    pole8: 0.885,
  },
  {
    motorPower: 7.5,
    pole2: 0.917,
    pole4: 0.924,
    pole6: 0.924,
    pole8: 0.91,
  },
  {
    motorPower: 11,
    pole2: 0.924,
    pole4: 0.936,
    pole6: 0.93,
    pole8: 0.91,
  },
  {
    motorPower: 15,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.93,
    pole8: 0.917,
  },
  {
    motorPower: 18.5,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 22,
    pole2: 0.93,
    pole4: 0.945,
    pole6: 0.941,
    pole8: 0.93,
  },
  {
    motorPower: 30,
    pole2: 0.936,
    pole4: 0.95,
    pole6: 0.95,
    pole8: 0.93,
  },
  {
    motorPower: 37,
    pole2: 0.941,
    pole4: 0.954,
    pole6: 0.95,
    pole8: 0.936,
  },
  {
    motorPower: 45,
    pole2: 0.945,
    pole4: 0.954,
    pole6: 0.954,
    pole8: 0.936,
  },
  {
    motorPower: 55,
    pole2: 0.945,
    pole4: 0.958,
    pole6: 0.954,
    pole8: 0.945,
  },
  {
    motorPower: 75,
    pole2: 0.95,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.945,
  },
  {
    motorPower: 90,
    pole2: 0.954,
    pole4: 0.962,
    pole6: 0.958,
    pole8: 0.95,
  },
  {
    motorPower: 110,
    pole2: 0.954,
    pole4: 0.962,
    pole6: 0.962,
    pole8: 0.95,
  },
  {
    motorPower: 150,
    pole2: 0.958,
    pole4: 0.965,
    pole6: 0.962,
    pole8: 0.954,
  },
  {
    motorPower: 185,
    pole2: 0.962,
    pole4: 0.965,
    pole6: 0.962,
    pole8: 0.954,
  },
  {
    motorPower: 220,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.954,
  },
  {
    motorPower: 250,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 315,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 355,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 400,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 450,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 500,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
  {
    motorPower: 1000,
    pole2: 0.962,
    pole4: 0.968,
    pole6: 0.965,
    pole8: 0.958,
  },
];

export const GB_Grade_1: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 84.9,
    pole4: 85.6,
    pole6: 83.1,
  },
  {
    motorPower: 1.1,
    pole2: 86.7,
    pole4: 87.4,
    pole6: 84.1,
  },
  {
    motorPower: 1.5,
    pole2: 87.5,
    pole4: 88.1,
    pole6: 86.2,
  },
  {
    motorPower: 2.2,
    pole2: 89.1,
    pole4: 89.7,
    pole6: 87.1,
  },
  {
    motorPower: 3,
    pole2: 89.7,
    pole4: 90.3,
    pole6: 88.7,
  },
  {
    motorPower: 4,
    pole2: 90.3,
    pole4: 90.9,
    pole6: 89.7,
  },
  {
    motorPower: 5.5,
    pole2: 91.5,
    pole4: 92.1,
    pole6: 89.5,
  },
  {
    motorPower: 7.5,
    pole2: 92.1,
    pole4: 92.6,
    pole6: 90.2,
  },
  {
    motorPower: 11,
    pole2: 93.0,
    pole4: 93.6,
    pole6: 91.5,
  },
  {
    motorPower: 15,
    pole2: 93.4,
    pole4: 94.0,
    pole6: 92.5,
  },
  {
    motorPower: 18.5,
    pole2: 93.8,
    pole4: 94.3,
    pole6: 93.1,
  },
  {
    motorPower: 22,
    pole2: 94.4,
    pole4: 94.7,
    pole6: 93.9,
  },
  {
    motorPower: 30,
    pole2: 94.5,
    pole4: 95.0,
    pole6: 94.3,
  },
  {
    motorPower: 37,
    pole2: 94.8,
    pole4: 95.3,
    pole6: 94.6,
  },
  {
    motorPower: 45,
    pole2: 95.1,
    pole4: 95.6,
    pole6: 94.9,
  },
  {
    motorPower: 55,
    pole2: 95.4,
    pole4: 95.8,
    pole6: 95.2,
  },
  {
    motorPower: 75,
    pole2: 95.6,
    pole4: 96.0,
    pole6: 95.4,
  },
  {
    motorPower: 90,
    pole2: 95.8,
    pole4: 96.2,
    pole6: 95.6,
  },
  {
    motorPower: 110,
    pole2: 96.0,
    pole4: 96.4,
    pole6: 95.6,
  },
  {
    motorPower: 132,
    pole2: 95.0,
    pole4: 96.5,
    pole6: 95.8,
  },
  {
    motorPower: 160,
    pole2: 96.2,
    pole4: 96.5,
    pole6: 96.0,
  },
  {
    motorPower: 200,
    pole2: 96.3,
    pole4: 96.6,
    pole6: 96.1,
  },
  {
    motorPower: 250,
    pole2: 96.4,
    pole4: 96.7,
    pole6: 96.1,
  },
  {
    motorPower: 315,
    pole2: 96.5,
    pole4: 96.8,
    pole6: 96.1,
  },
  {
    motorPower: 355,
    pole2: 96.6,
    pole4: 96.8,
    pole6: 96.1,
  },
  {
    motorPower: 375,
    pole2: 96.6,
    pole4: 96.8,
    pole6: 96.1,
  },
];

export const GB_Grade_2: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 80.7,
    pole4: 82.5,
    pole6: 78.9,
  },
  {
    motorPower: 1.1,
    pole2: 82.7,
    pole4: 84.1,
    pole6: 81.0,
  },
  {
    motorPower: 1.5,
    pole2: 84.2,
    pole4: 85.3,
    pole6: 82.5,
  },
  {
    motorPower: 2.2,
    pole2: 85.9,
    pole4: 86.7,
    pole6: 84.3,
  },
  {
    motorPower: 3,
    pole2: 87.1,
    pole4: 87.7,
    pole6: 85.6,
  },
  {
    motorPower: 4,
    pole2: 88.1,
    pole4: 88.6,
    pole6: 86.8,
  },
  {
    motorPower: 5.5,
    pole2: 89.2,
    pole4: 89.6,
    pole6: 88.0,
  },
  {
    motorPower: 7.5,
    pole2: 90.1,
    pole4: 90.4,
    pole6: 89.1,
  },
  {
    motorPower: 11,
    pole2: 91.2,
    pole4: 91.4,
    pole6: 90.3,
  },
  {
    motorPower: 15,
    pole2: 91.9,
    pole4: 92.1,
    pole6: 91.2,
  },
  {
    motorPower: 18.5,
    pole2: 92.4,
    pole4: 92.6,
    pole6: 91.7,
  },
  {
    motorPower: 22,
    pole2: 92.7,
    pole4: 93.0,
    pole6: 92.2,
  },
  {
    motorPower: 30,
    pole2: 93.3,
    pole4: 93.6,
    pole6: 92.9,
  },
  {
    motorPower: 37,
    pole2: 93.7,
    pole4: 93.9,
    pole6: 93.3,
  },
  {
    motorPower: 45,
    pole2: 94.0,
    pole4: 94.2,
    pole6: 93.7,
  },
  {
    motorPower: 55,
    pole2: 94.3,
    pole4: 94.6,
    pole6: 94.1,
  },
  {
    motorPower: 75,
    pole2: 94.7,
    pole4: 95.0,
    pole6: 94.6,
  },
  {
    motorPower: 90,
    pole2: 95.0,
    pole4: 95.2,
    pole6: 94.9,
  },
  {
    motorPower: 110,
    pole2: 95.2,
    pole4: 95.4,
    pole6: 95.1,
  },
  {
    motorPower: 132,
    pole2: 95.4,
    pole4: 95.6,
    pole6: 95.4,
  },
  {
    motorPower: 160,
    pole2: 95.6,
    pole4: 95.8,
    pole6: 95.6,
  },
  {
    motorPower: 200,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
  },
  {
    motorPower: 250,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
  },
  {
    motorPower: 315,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
  },
  {
    motorPower: 355,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
  },
  {
    motorPower: 375,
    pole2: 95.8,
    pole4: 96.0,
    pole6: 95.8,
  },
];

export const GB_Grade_3: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 77.4,
    pole4: 79.6,
    pole6: 75.9,
  },
  {
    motorPower: 1.1,
    pole2: 79.6,
    pole4: 81.4,
    pole6: 78.1,
  },
  {
    motorPower: 1.5,
    pole2: 81.3,
    pole4: 82.8,
    pole6: 79.8,
  },
  {
    motorPower: 2.2,
    pole2: 83.2,
    pole4: 84.3,
    pole6: 81.8,
  },
  {
    motorPower: 3,
    pole2: 84.6,
    pole4: 85.5,
    pole6: 83.3,
  },
  {
    motorPower: 4,
    pole2: 85.8,
    pole4: 86.6,
    pole6: 84.6,
  },
  {
    motorPower: 5.5,
    pole2: 87.0,
    pole4: 87.7,
    pole6: 86.0,
  },
  {
    motorPower: 7.5,
    pole2: 88.1,
    pole4: 88.7,
    pole6: 87.2,
  },
  {
    motorPower: 11,
    pole2: 89.4,
    pole4: 89.8,
    pole6: 88.7,
  },
  {
    motorPower: 15,
    pole2: 90.3,
    pole4: 90.6,
    pole6: 89.7,
  },
  {
    motorPower: 18.5,
    pole2: 90.9,
    pole4: 91.2,
    pole6: 90.4,
  },
  {
    motorPower: 22,
    pole2: 91.3,
    pole4: 91.6,
    pole6: 90.9,
  },
  {
    motorPower: 30,
    pole2: 92.0,
    pole4: 92.3,
    pole6: 91.7,
  },
  {
    motorPower: 37,
    pole2: 92.5,
    pole4: 92.7,
    pole6: 92.2,
  },
  {
    motorPower: 45,
    pole2: 92.9,
    pole4: 93.1,
    pole6: 92.7,
  },
  {
    motorPower: 55,
    pole2: 93.2,
    pole4: 93.5,
    pole6: 93.1,
  },
  {
    motorPower: 75,
    pole2: 93.8,
    pole4: 94.0,
    pole6: 93.7,
  },
  {
    motorPower: 90,
    pole2: 94.1,
    pole4: 94.2,
    pole6: 94.0,
  },
  {
    motorPower: 110,
    pole2: 94.6,
    pole4: 94.5,
    pole6: 94.3,
  },
  {
    motorPower: 132,
    pole2: 94.6,
    pole4: 94.7,
    pole6: 94.6,
  },
  {
    motorPower: 160,
    pole2: 94.8,
    pole4: 94.9,
    pole6: 94.8,
  },
  {
    motorPower: 200,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
  },
  {
    motorPower: 250,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
  },
  {
    motorPower: 315,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
  },
  {
    motorPower: 355,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
  },
  {
    motorPower: 375,
    pole2: 95.0,
    pole4: 95.1,
    pole6: 95.0,
  },
];

export const AO_Motor: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 0.72,
    pole4: 0.755,
    pole6: 0.7,
    pole8: 0.7,
  },
  {
    motorPower: 1.5,
    pole2: 0.8,
    pole4: 0.77,
    pole6: 0.77,
    pole8: 0.72,
  },
  {
    motorPower: 2,
    pole2: 0.815,
    pole4: 0.77,
    pole6: 0.785,
    pole8: 0.825,
  },
  {
    motorPower: 3,
    pole2: 0.815,
    pole4: 0.815,
    pole6: 0.8,
    pole8: 0.84,
  },
  {
    motorPower: 5,
    pole2: 0.825,
    pole4: 0.815,
    pole6: 0.815,
    pole8: 0.855,
  },
  {
    motorPower: 7.5,
    pole2: 0.855,
    pole4: 0.865,
    pole6: 0.885,
    pole8: 0.885,
  },
  {
    motorPower: 10,
    pole2: 0.865,
    pole4: 0.875,
    pole6: 0.902,
    pole8: 0.895,
  },
  {
    motorPower: 15,
    pole2: 0.875,
    pole4: 0.895,
    pole6: 0.902,
    pole8: 0.895,
  },
  {
    motorPower: 20,
    pole2: 0.885,
    pole4: 0.895,
    pole6: 0.91,
    pole8: 0.902,
  },
  {
    motorPower: 25,
    pole2: 0.895,
    pole4: 0.902,
    pole6: 0.917,
    pole8: 0.902,
  },
  {
    motorPower: 30,
    pole2: 0.895,
    pole4: 0.924,
    pole6: 0.924,
    pole8: 0.895,
  },
  {
    motorPower: 40,
    pole2: 0.902,
    pole4: 0.924,
    pole6: 0.93,
    pole8: 0.895,
  },
  {
    motorPower: 50,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.93,
    pole8: 0.902,
  },
  {
    motorPower: 60,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.936,
    pole8: 0.91,
  },
  {
    motorPower: 75,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.936,
    pole8: 0.924,
  },
  {
    motorPower: 100,
    pole2: 0.917,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 125,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 150,
    pole2: 0.924,
    pole4: 0.945,
    pole6: 0.945,
    pole8: 0.924,
  },
  {
    motorPower: 200,
    pole2: 0.936,
    pole4: 0.945,
    pole6: 0.945,
    pole8: 0.924,
  },
  {
    motorPower: 250,
    pole2: 0.936,
    pole4: 0.945,
    pole6: 0.95,
    pole8: 0.936,
  },
];

export const TEAO_Motor: ReadonlyArray<NominalMotorEfficiencyRow> = [
  {
    motorPower: 1,
    pole2: 0.72,
    pole4: 0.755,
    pole6: 0.72,
    pole8: 0.7,
  },
  {
    motorPower: 1.5,
    pole2: 0.8,
    pole4: 0.77,
    pole6: 0.785,
    pole8: 0.74,
  },
  {
    motorPower: 2,
    pole2: 0.815,
    pole4: 0.77,
    pole6: 0.8,
    pole8: 0.8,
  },
  {
    motorPower: 3,
    pole2: 0.825,
    pole4: 0.815,
    pole6: 0.815,
    pole8: 0.815,
  },
  {
    motorPower: 5,
    pole2: 0.855,
    pole4: 0.815,
    pole6: 0.815,
    pole8: 0.825,
  },
  {
    motorPower: 7.5,
    pole2: 0.865,
    pole4: 0.875,
    pole6: 0.895,
    pole8: 0.855,
  },
  {
    motorPower: 10,
    pole2: 0.875,
    pole4: 0.875,
    pole6: 0.895,
    pole8: 0.885,
  },
  {
    motorPower: 15,
    pole2: 0.885,
    pole4: 0.885,
    pole6: 0.902,
    pole8: 0.885,
  },
  {
    motorPower: 20,
    pole2: 0.885,
    pole4: 0.895,
    pole6: 0.902,
    pole8: 0.895,
  },
  {
    motorPower: 25,
    pole2: 0.895,
    pole4: 0.902,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 30,
    pole2: 0.895,
    pole4: 0.917,
    pole6: 0.917,
    pole8: 0.895,
  },
  {
    motorPower: 40,
    pole2: 0.902,
    pole4: 0.924,
    pole6: 0.93,
    pole8: 0.895,
  },
  {
    motorPower: 50,
    pole2: 0.91,
    pole4: 0.93,
    pole6: 0.93,
    pole8: 0.902,
  },
  {
    motorPower: 60,
    pole2: 0.917,
    pole4: 0.936,
    pole6: 0.936,
    pole8: 0.902,
  },
  {
    motorPower: 75,
    pole2: 0.917,
    pole4: 0.941,
    pole6: 0.936,
    pole8: 0.917,
  },
  {
    motorPower: 100,
    pole2: 0.924,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.917,
  },
  {
    motorPower: 125,
    pole2: 0.936,
    pole4: 0.941,
    pole6: 0.941,
    pole8: 0.924,
  },
  {
    motorPower: 150,
    pole2: 0.936,
    pole4: 0.945,
    pole6: 0.95,
    pole8: 0.924,
  },
  {
    motorPower: 200,
    pole2: 0.941,
    pole4: 0.95,
    pole6: 0.95,
    pole8: 0.93,
  },
  {
    motorPower: 250,
    pole2: 0.945,
    pole4: 0.95,
    pole6: 0.95,
    pole8: 0.936,
  },
];

export interface InductionMoterEfficiencyRow {
  readonly power: number;
  readonly pole2a: number;
  readonly pole2b: number;
  readonly pole2c: number;

  readonly pole4a: number;
  readonly pole4b: number;
  readonly pole4c: number;

  readonly pole6a: number;
  readonly pole6b: number;
  readonly pole6c: number;

  readonly pole8a: number;
  readonly pole8b: number;
  readonly pole8c: number;
}

export const VFD_Driven_HP: ReadonlyArray<InductionMoterEfficiencyRow> = [
  {
    power: 1,
    pole2a: 1.02905870898212,
    pole2b: 0.0170105815775609,
    pole2c: -0.0118466096841083,
    pole4a: 1.03744,
    pole4b: 0.03337,
    pole4c: -0.00393856992171249,
    pole6a: 1.09059129971241,
    pole6b: 0.0645692525947851,
    pole6c: -0.0244437335140015,
    pole8a: 1.09059129971241,
    pole8b: 0.0645692525947851,
    pole8c: -0.0244437335140015,
  },
  {
    power: 1.5,
    pole2a: 1.02997713317441,
    pole2b: 0.0161031784718422,
    pole2c: -0.0136540806057077,
    pole4a: 1.03812,
    pole4b: 0.0311967075366188,
    pole4c: -0.00671384267694175,
    pole6a: 1.08484384395432,
    pole6b: 0.0590277450178818,
    pole6c: -0.0243771695858694,
    pole8a: 1.08484384395432,
    pole8b: 0.0590277450178818,
    pole8c: -0.0243771695858694,
  },
  {
    power: 2,
    pole2a: 1.03089555736671,
    pole2b: 0.0151957753661235,
    pole2c: -0.0154647826375369,
    pole4a: 1.0388,
    pole4b: 0.0290204898496369,
    pole4c: -0.00950370789195087,
    pole6a: 1.07909638819624,
    pole6b: 0.0534862374409785,
    pole6c: -0.0243099053837346,
    pole8a: 1.07909638819624,
    pole8b: 0.0534862374409785,
    pole8c: -0.0243099053837346,
  },
  {
    power: 3,
    pole2a: 1.03273240575129,
    pole2b: 0.0133809691546862,
    pole2c: -0.0190959147503533,
    pole4a: 1.04016,
    pole4b: 0.0246680544756731,
    pole4c: -0.0151189894685007,
    pole6a: 1.06760147668006,
    pole6b: 0.0424032222871719,
    pole6c: -0.024173231484836,
    pole8a: 1.06760147668006,
    pole8b: 0.0424032222871719,
    pole8c: -0.024173231484836,
  },
  {
    power: 5,
    pole2a: 1.03640610252047,
    pole2b: 0.0097513567318114,
    pole2c: -0.0263973359490506,
    pole4a: 1.04288,
    pole4b: 0.0159631837277457,
    pole4c: -0.0264938894473439,
    pole6a: 1.04461165364771,
    pole6b: 0.0202371919795587,
    pole6c: -0.0238909754121521,
    pole8a: 1.04461165364771,
    pole8b: 0.0202371919795587,
    pole8c: -0.0238909754121521,
  },
  {
    power: 7.5,
    pole2a: 1.03489224169401,
    pole2b: 0.00891616760433174,
    pole2c: -0.0257465138569046,
    pole4a: 1.04077,
    pole4b: 0.0144634672809126,
    pole4c: -0.0259314736977148,
    pole6a: 1.042429389884,
    pole6b: 0.0179808136877475,
    pole6c: -0.0240167357454282,
    pole8a: 1.042429389884,
    pole8b: 0.0179808136877475,
    pole8c: -0.0240167357454282,
  },
  {
    power: 10,
    pole2a: 1.03337838086755,
    pole2b: 0.00808097847685208,
    pole2c: -0.0250946133602514,
    pole4a: 1.03866,
    pole4b: 0.0129637508340795,
    pole4c: -0.0253673926088294,
    pole6a: 1.0402471261203,
    pole6b: 0.0157244353959363,
    pole6c: -0.0241430548186066,
    pole8a: 1.0402471261203,
    pole8b: 0.0157244353959363,
    pole8c: -0.0241430548186066,
  },
  {
    power: 15,
    pole2a: 1.03035065921463,
    pole2b: 0.00641060022189276,
    pole2c: -0.0237875664142084,
    pole4a: 1.03443,
    pole4b: 0.00996431794041325,
    pole4c: -0.0242243033986378,
    pole6a: 1.03588259859289,
    pole6b: 0.0112116788123138,
    pole6c: -0.0243973841456757,
    pole8a: 1.03588259859289,
    pole8b: 0.0112116788123138,
    pole8c: -0.0243973841456757,
  },
  {
    power: 20,
    pole2a: 1.02732293756172,
    pole2b: 0.00474022196693344,
    pole2c: -0.0224761735432204,
    pole4a: 1.03021,
    pole4b: 0.00696488504674705,
    pole4c: -0.0230843352121202,
    pole6a: 1.03151807106548,
    pole6b: 0.00669892222869129,
    pole6c: -0.0246539936507006,
    pole8a: 1.03151807106548,
    pole8b: 0.00669892222869129,
    pole8c: -0.0246539936507006,
  },
  {
    power: 25,
    pole2a: 1.02653679982007,
    pole2b: 0.00475801219188196,
    pole2c: -0.0216756545993342,
    pole4a: 1.02882,
    pole4b: 0.00642118338277682,
    pole4c: -0.0222559073547484,
    pole6a: 1.02977438579548,
    pole6b: 0.00622312641904065,
    pole6c: -0.0234056033478893,
    pole8a: 1.02977438579548,
    pole8b: 0.00622312641904065,
    pole8c: -0.0234056033478893,
  },
  {
    power: 30,
    pole2a: 1.02575066207842,
    pole2b: 0.00477580241683048,
    pole2c: -0.0208751640028904,
    pole4a: 1.02742,
    pole4b: 0.0058774817188066,
    pole4c: -0.0214166423572604,
    pole6a: 1.02803070052547,
    pole6b: 0.00574733060939002,
    pole6c: -0.022156031875898,
    pole8a: 1.02803070052547,
    pole8b: 0.00574733060939002,
    pole8c: -0.022156031875898,
  },
  {
    power: 40,
    pole2a: 1.02417838659512,
    pole2b: 0.00481138286672751,
    pole2c: -0.0192742678463069,
    pole4a: 1.02464,
    pole4b: 0.00479007839086616,
    pole4c: -0.0197552922108097,
    pole6a: 1.02454332998546,
    pole6b: 0.00479573899008875,
    pole6c: -0.0196533387126192,
    pole8a: 1.02454332998546,
    pole8b: 0.00479573899008875,
    pole8c: -0.0196533387126192,
  },
  {
    power: 50,
    pole2a: 1.02260611111182,
    pole2b: 0.00484696331662455,
    pole2c: -0.017673485061426,
    pole4a: 1.02185,
    pole4b: 0.00370267506292571,
    pole4c: -0.0180803791680006,
    pole6a: 1.02105595944544,
    pole6b: 0.00384414737078748,
    pole6c: -0.0171459007055395,
    pole8a: 1.02105595944544,
    pole8b: 0.00384414737078748,
    pole8c: -0.0171459007055395,
  },
  {
    power: 60,
    pole2a: 1.02225927237835,
    pole2b: 0.00448877856289772,
    pole2c: -0.017691082463734,
    pole4a: 1.021,
    pole4b: 0.00349989560712136,
    pole4c: -0.0174390694702475,
    pole6a: 1.02057271348589,
    pole6b: 0.00398605499083111,
    pole6c: -0.0165208056552211,
    pole8a: 1.02057271348589,
    pole8b: 0.00398605499083111,
    pole8c: -0.0165208056552211,
  },
  {
    power: 75,
    pole2a: 1.02173901427814,
    pole2b: 0.00395150143230747,
    pole2c: -0.0177175021108678,
    pole4a: 1.01972,
    pole4b: 0.00319572642341484,
    pole4c: -0.0164716347382154,
    pole6a: 1.01984784454655,
    pole6b: 0.00419891642089655,
    pole6c: -0.0155834943353939,
    pole8a: 1.01984784454655,
    pole8b: 0.00419891642089655,
    pole8c: -0.0155834943353939,
  },
  {
    power: 100,
    pole2a: 1.02087191744447,
    pole2b: 0.00305603954799039,
    pole2c: -0.0177615977513126,
    pole4a: 1.01758,
    pole4b: 0.00268877778390397,
    pole4c: -0.0148512903964158,
    pole6a: 1.01863972964766,
    pole6b: 0.00455368547100562,
    pole6c: -0.0140221915268242,
    pole8a: 1.01863972964766,
    pole8b: 0.00455368547100562,
    pole8c: -0.0140221915268242,
  },
  {
    power: 125,
    pole2a: 1.01997093600036,
    pole2b: 0.00299296617606235,
    pole2c: -0.0169273069670903,
    pole4a: 1.01725,
    pole4b: 0.00285756025169809,
    pole4c: -0.0143514296733125,
    pole6a: 1.01955544838737,
    pole6b: 0.00458555434490091,
    pole6c: -0.014901562119545,
    pole8a: 1.01955544838737,
    pole8b: 0.00458555434490091,
    pole8c: -0.014901562119545,
  },
  {
    power: 150,
    pole2a: 1.01906995455625,
    pole2b: 0.00292989280413431,
    pole2c: -0.0160929112472523,
    pole4a: 1.01692,
    pole4b: 0.00302634271949222,
    pole4c: -0.0138517371765512,
    pole6a: 1.02047116712708,
    pole6b: 0.00461742321879621,
    pole6c: -0.0157808769207777,
    pole8a: 1.02047116712708,
    pole8b: 0.00461742321879621,
    pole8c: -0.0157808769207777,
  },
  {
    power: 200,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 250,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 300,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 350,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 400,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 450,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
  {
    power: 500,
    pole2a: 1.01726799166803,
    pole2b: 0.00280374606027823,
    pole2c: -0.0144238049215275,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223026046065,
    pole8b: 0.00468116096658681,
    pole8c: -0.0175393391700152,
  },
];

export interface VFDPerformanceCoefficienctRow {
  readonly power: number;
  readonly d: number;
  readonly e: number;
  readonly f: number;
}

export const VFD_Performance_Coefficienct_HP: ReadonlyArray<VFDPerformanceCoefficienctRow> = [
  {
    power: 1,
    d: 0.9803,
    e: 0.04,
    f: -0.0131,
  },
  {
    power: 1.5,
    d: 0.97995,
    e: 0.03855,
    f: -0.0118,
  },
  {
    power: 2,
    d: 0.9796,
    e: 0.0371,
    f: -0.0105,
  },
  {
    power: 3,
    d: 0.9789,
    e: 0.0342,
    f: -0.0079,
  },
  {
    power: 5,
    d: 0.9775,
    e: 0.0284,
    f: -0.0027,
  },
  {
    power: 7.5,
    d: 0.9781,
    e: 0.0253,
    f: -0.0004,
  },
  {
    power: 10,
    d: 0.9787,
    e: 0.0222,
    f: 0.0019,
  },
  {
    power: 15,
    d: 0.98185,
    e: 0.01985,
    f: 0.0007,
  },
  {
    power: 20,
    d: 0.985,
    e: 0.0175,
    f: -0.0005,
  },
  {
    power: 25,
    d: 0.9862,
    e: 0.0165,
    f: -0.0032,
  },
  {
    power: 30,
    d: 0.9874,
    e: 0.0155,
    f: -0.0059,
  },
  {
    power: 40,
    d: 0.98765,
    e: 0.01695,
    f: -0.0038,
  },
  {
    power: 50,
    d: 0.9879,
    e: 0.0184,
    f: -0.0017,
  },
  {
    power: 60,
    d: 0.9719,
    e: 0.0145,
    f: 0.0118,
  },
  {
    power: 75,
    d: 0.9919,
    e: 0.0179,
    f: -0.0013,
  },
  {
    power: 100,
    d: 0.9824,
    e: 0.0126,
    f: 0.0014,
  },
  {
    power: 125,
    d: 0.98293,
    e: 0.0119,
    f: -0.0001,
  },
  {
    power: 150,
    d: 0.98345,
    e: 0.0112,
    f: -0.0016,
  },
  {
    power: 200,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 250,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 300,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 350,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 400,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 450,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 500,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
];

export const VFD_Driven_Kw: ReadonlyArray<InductionMoterEfficiencyRow> = [
  {
    power: 1,
    pole2a: 1.02968,
    pole2b: 0.01639,
    pole2c: -0.013075689449916,
    pole4a: 1.03791,
    pole4b: 0.03189,
    pole4c: -0.00583395516964025,
    pole6a: 1.08667748265463,
    pole6b: 0.0607956790705668,
    pole6c: -0.0243984813425477,
    pole8a: 1.08668,
    pole8b: 0.0608,
    pole8c: -0.0243966817496231,
  },
  {
    power: 1.1,
    pole2a: 1.02993,
    pole2b: 0.01615,
    pole2c: -0.0135609900113172,
    pole4a: 1.03809,
    pole4b: 0.03131,
    pole4c: -0.00657416295779156,
    pole6a: 1.08513660979723,
    pole6b: 0.0593100202027643,
    pole6c: -0.0243805770755581,
    pole8a: 1.08514,
    pole8b: 0.05931,
    pole8c: -0.0243837969999339,
  },
  {
    power: 1.5,
    pole2a: 1.03092,
    pole2b: 0.01518,
    pole2c: -0.0155046395713077,
    pole4a: 1.03882,
    pole4b: 0.02897,
    pole4c: -0.00957267947559215,
    pole6a: 1.07897311836764,
    pole6b: 0.0533673847315543,
    pole6c: -0.0243084549676034,
    pole8a: 1.07897,
    pole8b: 0.05337,
    pole8c: -0.0243029514795372,
  },
  {
    power: 2.2,
    pole2a: 1.03264,
    pole2b: 0.01347,
    pole2c: -0.0189152120931058,
    pole4a: 1.04009,
    pole4b: 0.02489,
    pole4c: -0.0148308598971596,
    pole6a: 1.06818700836587,
    pole6b: 0.0429677726569368,
    pole6c: -0.0241802636381439,
    pole8a: 1.06819,
    pole8b: 0.04297,
    pole8c: -0.0241809448018639,
  },
  {
    power: 3,
    pole2a: 1.03461,
    pole2b: 0.01153,
    pole2c: -0.0228169209019999,
    pole4a: 1.04155,
    pole4b: 0.02022,
    pole4c: -0.0209072552978771,
    pole6a: 1.0558600255067,
    pole6b: 0.0310825017145169,
    pole6c: -0.0240305928487594,
    pole8a: 1.05586,
    pole8b: 0.03108,
    pole8c: -0.0240330527214183,
  },
  {
    power: 3.7,
    pole2a: 1.03633,
    pole2b: 0.00982,
    pole2c: -0.0262522033629755,
    pole4a: 1.04283,
    pole4b: 0.01614,
    pole4c: -0.0262660656996081,
    pole6a: 1.04507391550492,
    pole6b: 0.0206828896398995,
    pole6c: -0.0238967715757734,
    pole8a: 1.04507,
    pole8b: 0.02068,
    pole8c: -0.0238958341497824,
  },
  {
    power: 4,
    pole2a: 1.03619,
    pole2b: 0.00963,
    pole2c: -0.0263066667987282,
    pole4a: 1.04257,
    pole4b: 0.01575,
    pole4c: -0.0264041348757076,
    pole6a: 1.04429572270068,
    pole6b: 0.0199105313153287,
    pole6c: -0.0239091475542508,
    pole8a: 1.0443,
    pole8b: 0.01991,
    pole8c: -0.0239138747536547,
  },
  {
    power: 5.5,
    pole2a: 1.03497,
    pole2b: 0.00896,
    pole2c: -0.0257790199809704,
    pole4a: 1.04088,
    pole4b: 0.01454,
    pole4c: -0.025962505174759,
    pole6a: 1.04254055077277,
    pole6b: 0.018095749847384,
    pole6c: -0.0240103162487912,
    pole8a: 1.04254,
    pole8b: 0.0181,
    pole8c: -0.0240055004419999,
  },
  {
    power: 7.5,
    pole2a: 1.03335,
    pole2b: 0.00806,
    pole2c: -0.0250877923933099,
    pole4a: 1.03861,
    pole4b: 0.01293,
    pole4c: -0.0253521961043703,
    pole6a: 1.04020032153555,
    pole6b: 0.0156760412234577,
    pole6c: -0.024145770222713,
    pole8a: 1.0402,
    pole8b: 0.01568,
    pole8c: -0.0241414618777569,
  },
  {
    power: 11,
    pole2a: 1.0305,
    pole2b: 0.0065,
    pole2c: -0.0238450074515648,
    pole4a: 1.03465,
    pole4b: 0.01012,
    pole4c: -0.0242842434562232,
    pole6a: 1.03610492037043,
    pole6b: 0.0114415511315868,
    pole6c: -0.0243843741749061,
    pole8a: 1.0361,
    pole8b: 0.01144,
    pole8c: -0.0243810804397693,
  },
  {
    power: 15,
    pole2a: 1.02731,
    pole2b: 0.00474,
    pole2c: -0.0224635229014472,
    pole4a: 1.03018,
    pole4b: 0.00695,
    pole4c: -0.0230696658225336,
    pole6a: 1.03148067298999,
    pole6b: 0.00668871749282747,
    pole6c: -0.0246272309069935,
    pole8a: 1.03148,
    pole8b: 0.00669,
    pole8c: -0.0246252570304661,
  },
  {
    power: 18.5,
    pole2a: 1.02657,
    pole2b: 0.00476,
    pole2c: -0.021706676221187,
    pole4a: 1.02887,
    pole4b: 0.00644,
    pole4c: -0.0222864751003538,
    pole6a: 1.02984450718703,
    pole6b: 0.00624226029878532,
    pole6c: -0.023455829495016,
    pole8a: 1.02984,
    pole8b: 0.00624,
    pole8c: -0.0234536492288122,
  },
  {
    power: 22,
    pole2a: 1.02583,
    pole2b: 0.00477,
    pole2c: -0.020960020701255,
    pole4a: 1.02756,
    pole4b: 0.00593,
    pole4c: -0.0215024902329188,
    pole6a: 1.02820834138408,
    pole6b: 0.00579580310474317,
    pole6c: -0.0222833881491156,
    pole8a: 1.02821,
    pole8b: 0.0058,
    pole8c: -0.0222807715251541,
  },
  {
    power: 30,
    pole2a: 1.02414,
    pole2b: 0.00481,
    pole2c: -0.0192374677799785,
    pole4a: 1.02458,
    pole4b: 0.00477,
    pole4c: -0.0197159548951502,
    pole6a: 1.02446853383447,
    pole6b: 0.00477532951836111,
    pole6c: -0.0195996097212541,
    pole8a: 1.02447,
    pole8b: 0.00478,
    pole8c: -0.0195963295447759,
  },
  {
    power: 37,
    pole2a: 1.02267,
    pole2b: 0.00485,
    pole2c: -0.0177339901477831,
    pole4a: 1.02196,
    pole4b: 0.00375,
    pole4c: -0.0181419676214198,
    pole6a: 1.02119620222855,
    pole6b: 0.00388241513027681,
    pole6c: -0.0172468277532594,
    pole8a: 1.0212,
    pole8b: 0.00388,
    pole8c: -0.0172530581344383,
  },
  {
    power: 45,
    pole2a: 1.02225,
    pole2b: 0.00448,
    pole2c: -0.0176907454603377,
    pole4a: 1.02097,
    pole4b: 0.00349,
    pole4c: -0.0174192069676826,
    pole6a: 1.02055716669898,
    pole6b: 0.00399062038343305,
    pole6c: -0.0165006982926035,
    pole8a: 1.02056,
    pole8b: 0.00399,
    pole8c: -0.0165041484476938,
  },
  {
    power: 55,
    pole2a: 1.02178,
    pole2b: 0.004,
    pole2c: -0.0177091633466135,
    pole4a: 1.01983,
    pole4b: 0.00322,
    pole4c: -0.0165566874663583,
    pole6a: 1.01990938391137,
    pole6b: 0.00418084507518054,
    pole6c: -0.0156630540338729,
    pole8a: 1.01991,
    pole8b: 0.00418,
    pole8c: -0.0156645222967995,
  },
  {
    power: 75,
    pole2a: 1.02085,
    pole2b: 0.00305,
    pole2c: -0.0177458750810029,
    pole4a: 1.01758,
    pole4b: 0.00269,
    pole4c: -0.014850053356471,
    pole6a: 1.01865936972787,
    pole6b: 0.00455436898572723,
    pole6c: -0.0140410526076151,
    pole8a: 1.01866,
    pole8b: 0.00455,
    pole8c: -0.0140460902891841,
  },
  {
    power: 90,
    pole2a: 1.02013,
    pole2b: 0.003,
    pole2c: -0.0170787637088734,
    pole4a: 1.01731,
    pole4b: 0.00283,
    pole4c: -0.0144391372416062,
    pole6a: 1.01939587273568,
    pole6b: 0.00458000078778779,
    pole6c: -0.0147483246095585,
    pole8a: 1.0194,
    pole8b: 0.00458,
    pole8c: -0.0147524338529534,
  },
  {
    power: 110,
    pole2a: 1.01916,
    pole2b: 0.00294,
    pole2c: -0.0161724529882148,
    pole4a: 1.01695,
    pole4b: 0.00301,
    pole4c: -0.0138981665187785,
    pole6a: 1.02037787674609,
    pole6b: 0.00461417652386854,
    pole6c: -0.0156912978042654,
    pole8a: 1.02038,
    pole8b: 0.00461,
    pole8c: -0.0156976339076855,
  },
  {
    power: 132,
    pole2a: 1.0181,
    pole2b: 0.00286,
    pole2c: -0.0151965379016012,
    pole4a: 1.01656,
    pole4b: 0.00321,
    pole4c: -0.0133072836195811,
    pole6a: 1.02145808115755,
    pole6b: 0.00465176983355736,
    pole6c: -0.0167284942192207,
    pole8a: 1.02146,
    pole8b: 0.00465,
    pole8c: -0.0167321952918926,
  },
  {
    power: 150,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 160,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 185,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 200,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 220,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 250,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 300,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 330,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
  {
    power: 375,
    pole2a: 1.01727,
    pole2b: 0.0028,
    pole2c: -0.0144295971280415,
    pole4a: 1.01626,
    pole4b: 0.00336,
    pole4c: -0.0128568011481422,
    pole6a: 1.0223026046065,
    pole6b: 0.00468116096658681,
    pole6c: -0.0175393391700152,
    pole8a: 1.0223,
    pole8b: 0.00468,
    pole8c: -0.0175379225225942,
  },
];

export const VFD_Performance_Coefficienct_KW: ReadonlyArray<VFDPerformanceCoefficienctRow> = [
  {
    power: 1,
    d: 0.980061662198391,
    e: 0.039012600536193,
    f: -0.012214745308311,
  },
  {
    power: 1.1,
    d: 0.979967828418231,
    e: 0.0386238605898123,
    f: -0.0118662198391421,
  },
  {
    power: 1.5,
    d: 0.979592493297587,
    e: 0.0370689008042895,
    f: -0.0104721179624665,
  },
  {
    power: 2.2,
    d: 0.978935656836461,
    e: 0.0343477211796247,
    f: -0.00803243967828418,
  },
  {
    power: 3,
    d: 0.978184986595174,
    e: 0.0312378016085791,
    f: -0.00524423592493298,
  },
  {
    power: 3.7,
    d: 0.977528150134048,
    e: 0.0285166219839142,
    f: -0.00280455764075067,
  },
  {
    power: 4,
    d: 0.977586863270777,
    e: 0.0279512064343164,
    f: -0.00236702412868633,
  },
  {
    power: 5.5,
    d: 0.978069436997319,
    e: 0.025457908847185,
    f: -0.000517158176943699,
  },
  {
    power: 7.5,
    d: 0.978733780160858,
    e: 0.0221747989276139,
    f: 0.00188713136729223,
  },
  {
    power: 11,
    d: 0.981689544235925,
    e: 0.0199697050938338,
    f: 0.00076112600536193,
  },
  {
    power: 15,
    d: 0.985025737265416,
    e: 0.0174785522788204,
    f: -0.000557908847184987,
  },
  {
    power: 18.5,
    d: 0.986151742627346,
    e: 0.0165402144772118,
    f: -0.00309142091152815,
  },
  {
    power: 22,
    d: 0.987277747989276,
    e: 0.0156018766756032,
    f: -0.00562493297587131,
  },
  {
    power: 30,
    d: 0.987655361930295,
    e: 0.0169810991957105,
    f: -0.00375495978552279,
  },
  {
    power: 37,
    d: 0.987889946380697,
    e: 0.0183416890080429,
    f: -0.00178445040214477,
  },
  {
    power: 45,
    d: 0.972328954423592,
    e: 0.0145729222520107,
    f: 0.0115190348525469,
  },
  {
    power: 55,
    d: 0.990202055406613,
    e: 0.0176113494191242,
    f: -0.000187846291331544,
  },
  {
    power: 75,
    d: 0.982411260053619,
    e: 0.0125849865951743,
    f: 0.00136782841823056,
  },
  {
    power: 90,
    d: 0.982833512064343,
    e: 0.0120219839142091,
    f: 0.000161394101876676,
  },
  {
    power: 110,
    d: 0.983396514745308,
    e: 0.0112713136729223,
    f: -0.00144718498659517,
  },
  {
    power: 132,
    d: 0.98401581769437,
    e: 0.0104455764075067,
    f: -0.00321662198391421,
  },
  {
    power: 150,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 160,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 185,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 200,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 220,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 250,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 300,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 330,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
  {
    power: 375,
    d: 0.9845,
    e: 0.0098,
    f: -0.0046,
  },
];

export const DOL_HP: ReadonlyArray<InductionMoterEfficiencyRow> = [
  {
    power: 1,
    pole2a: 1.1346,
    pole2b: 0.08674,
    pole2c: -0.0440399727625742,
    pole4a: 1.12541,
    pole4b: 0.09132,
    pole4c: -0.0312374005791152,
    pole6a: 1.16873,
    pole6b: 0.11466,
    pole6c: -0.0485080652396246,
    pole8a: 1.16873,
    pole8b: 0.11466,
    pole8c: -0.0485080652396246,
  },
  {
    power: 1.5,
    pole2a: 1.12932,
    pole2b: 0.08114,
    pole2c: -0.0445640712581166,
    pole4a: 1.12067,
    pole4b: 0.08492,
    pole4c: -0.0329517383770233,
    pole6a: 1.15895,
    pole6b: 0.10606,
    pole6c: -0.0478183823662368,
    pole8a: 1.15895,
    pole8b: 0.10606,
    pole8c: -0.0478183823662368,
  },
  {
    power: 2,
    pole2a: 1.12405,
    pole2b: 0.07555,
    pole2c: -0.0450932081260751,
    pole4a: 1.11592,
    pole4b: 0.07851,
    pole4c: -0.0346867437483194,
    pole6a: 1.14917,
    pole6b: 0.09747,
    pole6c: -0.0471083492031674,
    pole8a: 1.14917,
    pole8b: 0.09747,
    pole8c: -0.0471083492031674,
  },
  {
    power: 3,
    pole2a: 1.1135,
    pole2b: 0.06436,
    pole2c: -0.0461685895749557,
    pole4a: 1.10643,
    pole4b: 0.06571,
    pole4c: -0.0382092689380789,
    pole6a: 1.12962,
    pole6b: 0.08027,
    pole6c: -0.0456830236885224,
    pole8a: 1.12962,
    pole8b: 0.08027,
    pole8c: -0.0456830236885224,
  },
  {
    power: 5,
    pole2a: 1.09241,
    pole2b: 0.04197,
    pole2c: -0.0484083035020202,
    pole4a: 1.08745,
    pole4b: 0.04009,
    pole4c: -0.0455345210510629,
    pole6a: 1.09051,
    pole6b: 0.04588,
    pole6c: -0.042672199487513,
    pole8a: 1.09051,
    pole8b: 0.04588,
    pole8c: -0.042672199487513,
  },
  {
    power: 7.5,
    pole2a: 1.08883,
    pole2b: 0.0399,
    pole2c: -0.0470526012116548,
    pole4a: 1.0834,
    pole4b: 0.03745,
    pole4c: -0.0442912911465612,
    pole6a: 1.08579,
    pole6b: 0.04217,
    pole6c: -0.0418549756757534,
    pole8a: 1.08579,
    pole8b: 0.04217,
    pole8c: -0.0418549756757534,
  },
  {
    power: 10,
    pole2a: 1.08526,
    pole2b: 0.03783,
    pole2c: -0.0457011263887148,
    pole4a: 1.07936,
    pole4b: 0.03481,
    pole4c: -0.0430513814130131,
    pole6a: 1.08107,
    pole6b: 0.03846,
    pole6c: -0.0410319126398706,
    pole8a: 1.08107,
    pole8b: 0.03846,
    pole8c: -0.0410319126398706,
  },
  {
    power: 15,
    pole2a: 1.07811,
    pole2b: 0.03368,
    pole2c: -0.0429823543069421,
    pole4a: 1.07127,
    pole4b: 0.02953,
    pole4c: -0.0405427719444793,
    pole6a: 1.07163,
    pole6b: 0.03104,
    pole6c: -0.0393680167597767,
    pole8a: 1.07163,
    pole8b: 0.03104,
    pole8c: -0.0393680167597767,
  },
  {
    power: 20,
    pole2a: 1.07096,
    pole2b: 0.02953,
    pole2c: -0.0402416636717724,
    pole4a: 1.06318,
    pole4b: 0.02425,
    pole4c: -0.0380082987551866,
    pole6a: 1.06218,
    pole6b: 0.02362,
    pole6c: -0.0376702291866122,
    pole8a: 1.06218,
    pole8b: 0.02362,
    pole8c: -0.0376702291866122,
  },
  {
    power: 25,
    pole2a: 1.06949,
    pole2b: 0.02923,
    pole2c: -0.039116621163394,
    pole4a: 1.06033,
    pole4b: 0.02291,
    pole4c: -0.0365819084767967,
    pole6a: 1.05966,
    pole6b: 0.02257,
    pole6c: -0.0362713555062246,
    pole8a: 1.05966,
    pole8b: 0.02257,
    pole8c: -0.0362713555062246,
  },
  {
    power: 30,
    pole2a: 1.06802,
    pole2b: 0.02892,
    pole2c: -0.0380010107685729,
    pole4a: 1.05749,
    pole4b: 0.02157,
    pole4c: -0.0351615650420432,
    pole6a: 1.05713,
    pole6b: 0.02152,
    pole6c: -0.034859816743676,
    pole8a: 1.05713,
    pole8b: 0.02152,
    pole8c: -0.034859816743676,
  },
  {
    power: 40,
    pole2a: 1.06508,
    pole2b: 0.02831,
    pole2c: -0.0357576995264073,
    pole4a: 1.0518,
    pole4b: 0.01889,
    pole4c: -0.0322998557253482,
    pole6a: 1.05208,
    pole6b: 0.01942,
    pole6c: -0.0320378254301465,
    pole8a: 1.05208,
    pole8b: 0.01942,
    pole8c: -0.0320378254301465,
  },
  {
    power: 50,
    pole2a: 1.06214,
    pole2b: 0.02769,
    pole2c: -0.0335217818602886,
    pole4a: 1.04612,
    pole4b: 0.01621,
    pole4c: -0.0294328928075889,
    pole6a: 1.04703,
    pole6b: 0.01732,
    pole6c: -0.0292041835410686,
    pole8a: 1.04703,
    pole8b: 0.01732,
    pole8c: -0.0292041835410686,
  },
  {
    power: 60,
    pole2a: 1.05946,
    pole2b: 0.02585,
    pole2c: -0.0327630745235659,
    pole4a: 1.04436,
    pole4b: 0.01556,
    pole4c: -0.0283587380361574,
    pole6a: 1.04553,
    pole6b: 0.01691,
    pole6c: -0.0281440835472166,
    pole8a: 1.04553,
    pole8b: 0.01691,
    pole8c: -0.0281440835472166,
  },
  {
    power: 75,
    pole2a: 1.05544,
    pole2b: 0.02309,
    pole2c: -0.0316198965877879,
    pole4a: 1.04172,
    pole4b: 0.01459,
    pole4c: -0.0267398653643343,
    pole6a: 1.04328,
    pole6b: 0.01631,
    pole6c: -0.0265371786167605,
    pole8a: 1.04328,
    pole8b: 0.01631,
    pole8c: -0.0265371786167605,
  },
  {
    power: 100,
    pole2a: 1.04874,
    pole2b: 0.01849,
    pole2c: -0.0297008316232856,
    pole4a: 1.03732,
    pole4b: 0.01298,
    pole4c: -0.0240281150664376,
    pole6a: 1.03954,
    pole6b: 0.0153,
    pole6c: -0.0238747168324631,
    pole8a: 1.03954,
    pole8b: 0.0153,
    pole8c: -0.0238747168324631,
  },
  {
    power: 125,
    pole2a: 1.04713,
    pole2b: 0.01813,
    pole2c: -0.0284835924685451,
    pole4a: 1.03731,
    pole4b: 0.01332,
    pole4c: -0.0236746536138632,
    pole6a: 1.03948,
    pole6b: 0.01463,
    pole6c: -0.0244916866246809,
    pole8a: 1.03948,
    pole8b: 0.01463,
    pole8c: -0.0244916866246809,
  },
  {
    power: 150,
    pole2a: 1.04553,
    pole2b: 0.01778,
    pole2c: -0.0272652243117375,
    pole4a: 1.03729,
    pole4b: 0.01365,
    pole4c: -0.0233216593498744,
    pole6a: 1.03942,
    pole6b: 0.01396,
    pole6c: -0.0251094717740346,
    pole8a: 1.03942,
    pole8b: 0.01396,
    pole8c: -0.0251094717740346,
  },
  {
    power: 200,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 250,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 300,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 350,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 400,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 450,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 500,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
];

export const DOL_KW: ReadonlyArray<InductionMoterEfficiencyRow> = [
  {
    power: 1,
    pole2a: 1.13101,
    pole2b: 0.08293,
    pole2c: -0.0443980682038545,
    pole4a: 1.12218,
    pole4b: 0.08696,
    pole4c: -0.0324022963126518,
    pole6a: 1.16207,
    pole6b: 0.10881,
    pole6c: -0.0480334773315536,
    pole8a: 1.16207,
    pole8b: 0.10881,
    pole8c: -0.0480334773315536,
  },
  {
    power: 1.1,
    pole2a: 1.12959,
    pole2b: 0.08143,
    pole2c: -0.0445336267719594,
    pole4a: 1.12091,
    pole4b: 0.08524,
    pole4c: -0.0328683056282482,
    pole6a: 1.15945,
    pole6b: 0.1065,
    pole6c: -0.0478535924084953,
    pole8a: 1.15945,
    pole8b: 0.1065,
    pole8c: -0.0478535924084953,
  },
  {
    power: 1.5,
    pole2a: 1.12394,
    pole2b: 0.07543,
    pole2c: -0.0451075383799968,
    pole4a: 1.11582,
    pole4b: 0.07837,
    pole4c: -0.0347283399946214,
    pole6a: 1.14896,
    pole6b: 0.09728,
    pole6c: -0.0470982793817438,
    pole8a: 1.14896,
    pole8b: 0.09728,
    pole8c: -0.0470982793817438,
  },
  {
    power: 2.2,
    pole2a: 1.11404,
    pole2b: 0.06493,
    pole2c: -0.0461157071356804,
    pole4a: 1.10691,
    pole4b: 0.06636,
    pole4c: -0.0380265576353203,
    pole6a: 1.13061,
    pole6b: 0.08115,
    pole6c: -0.045747583591546,
    pole8a: 1.13061,
    pole8b: 0.08115,
    pole8c: -0.045747583591546,
  },
  {
    power: 3,
    pole2a: 1.10273,
    pole2b: 0.05292,
    pole2c: -0.0473065380085855,
    pole4a: 1.09674,
    pole4b: 0.05263,
    pole4c: -0.0419045628568444,
    pole6a: 1.10964,
    pole6b: 0.06271,
    pole6c: -0.0441606835354893,
    pole8a: 1.10964,
    pole8b: 0.06271,
    pole8c: -0.0441606835354893,
  },
  {
    power: 3.7,
    pole2a: 1.09283,
    pole2b: 0.04242,
    pole2c: -0.048358627040924,
    pole4a: 1.08783,
    pole4b: 0.04061,
    pole4c: -0.0453772306627844,
    pole6a: 1.09129,
    pole6b: 0.04657,
    pole6c: -0.0427300610565944,
    pole8a: 1.09129,
    pole8b: 0.04657,
    pole8c: -0.0427300610565944,
  },
  {
    power: 4,
    pole2a: 1.09189,
    pole2b: 0.04167,
    pole2c: -0.0482110457246536,
    pole4a: 1.08686,
    pole4b: 0.03971,
    pole4c: -0.045349183907051,
    pole6a: 1.08982,
    pole6b: 0.04534,
    pole6c: -0.0425507490385904,
    pole8a: 1.08982,
    pole8b: 0.04534,
    pole8c: -0.0425507490385904,
  },
  {
    power: 5.5,
    pole2a: 1.08902,
    pole2b: 0.04,
    pole2c: -0.0471346153846155,
    pole4a: 1.08361,
    pole4b: 0.03759,
    pole4c: -0.0443527790360354,
    pole6a: 1.08603,
    pole6b: 0.04236,
    pole6c: -0.0418953144786831,
    pole8a: 1.08603,
    pole8b: 0.04236,
    pole8c: -0.0418953144786831,
  },
  {
    power: 7.5,
    pole2a: 1.08518,
    pole2b: 0.03778,
    pole2c: -0.0456744203973869,
    pole4a: 1.07927,
    pole4b: 0.03476,
    pole4c: -0.0430148053654955,
    pole6a: 1.08097,
    pole6b: 0.03838,
    pole6c: -0.0410158130934724,
    pole8a: 1.08097,
    pole8b: 0.03838,
    pole8c: -0.0410158130934724,
  },
  {
    power: 11,
    pole2a: 1.07848,
    pole2b: 0.03389,
    pole2c: -0.0431283792279644,
    pole4a: 1.07168,
    pole4b: 0.0298,
    pole4c: -0.0406680908914352,
    pole6a: 1.07211,
    pole6b: 0.03142,
    pole6c: -0.0394504663473656,
    pole8a: 1.07211,
    pole8b: 0.03142,
    pole8c: -0.0394504663473656,
  },
  {
    power: 15,
    pole2a: 1.07093,
    pole2b: 0.02952,
    pole2c: -0.0402226280208251,
    pole4a: 1.06311,
    pole4b: 0.02422,
    pole4c: -0.0379703579309134,
    pole6a: 1.06213,
    pole6b: 0.0236,
    pole6c: -0.0376416568972255,
    pole8a: 1.06213,
    pole8b: 0.0236,
    pole8c: -0.0376416568972255,
  },
  {
    power: 18.5,
    pole2a: 1.06955,
    pole2b: 0.02924,
    pole2c: -0.0391648206443591,
    pole4a: 1.06045,
    pole4b: 0.02296,
    pole4c: -0.0366485493078905,
    pole6a: 1.05976,
    pole6b: 0.02261,
    pole6c: -0.0363286101250722,
    pole8a: 1.05976,
    pole8b: 0.02261,
    pole8c: -0.0363286101250722,
  },
  {
    power: 22,
    pole2a: 1.06817,
    pole2b: 0.02895,
    pole2c: -0.038116526556198,
    pole4a: 1.05778,
    pole4b: 0.02171,
    pole4c: -0.0353035597185112,
    pole6a: 1.05739,
    pole6b: 0.02163,
    pole6c: -0.0350028875424566,
    pole8a: 1.05739,
    pole8b: 0.02163,
    pole8c: -0.0350028875424566,
  },
  {
    power: 30,
    pole2a: 1.06502,
    pole2b: 0.02829,
    pole2c: -0.0357194954730671,
    pole4a: 1.05168,
    pole4b: 0.01883,
    pole4c: -0.0322428668178205,
    pole6a: 1.05197,
    pole6b: 0.01937,
    pole6c: -0.0319805369983424,
    pole8a: 1.05197,
    pole8b: 0.01937,
    pole8c: -0.0319805369983424,
  },
  {
    power: 37,
    pole2a: 1.06226,
    pole2b: 0.02772,
    pole2c: -0.0336083758222083,
    pole4a: 1.04635,
    pole4b: 0.01631,
    pole4c: -0.0295579104800698,
    pole6a: 1.04723,
    pole6b: 0.0174,
    pole6c: -0.0293198348732062,
    pole8a: 1.04723,
    pole8b: 0.0174,
    pole8c: -0.0293198348732062,
  },
  {
    power: 45,
    pole2a: 1.05937,
    pole2b: 0.02579,
    pole2c: -0.0327357451330195,
    pole4a: 1.0443,
    pole4b: 0.01554,
    pole4c: -0.0283199086200443,
    pole6a: 1.04548,
    pole6b: 0.0169,
    pole6c: -0.028105025076212,
    pole8a: 1.04548,
    pole8b: 0.0169,
    pole8c: -0.028105025076212,
  },
  {
    power: 55,
    pole2a: 1.05578,
    pole2b: 0.02333,
    pole2c: -0.0317102010104267,
    pole4a: 1.04194,
    pole4b: 0.01468,
    pole4c: -0.0268656128040368,
    pole6a: 1.04347,
    pole6b: 0.01636,
    pole6c: -0.0266736195836121,
    pole8a: 1.04347,
    pole8b: 0.01636,
    pole8c: -0.0266736195836121,
  },
  {
    power: 75,
    pole2a: 1.04871,
    pole2b: 0.01848,
    pole2c: -0.0296814861362029,
    pole4a: 1.03732,
    pole4b: 0.01299,
    pole4c: -0.0240180061007511,
    pole6a: 1.03954,
    pole6b: 0.01528,
    pole6c: -0.0238948861397841,
    pole8a: 1.03954,
    pole8b: 0.01528,
    pole8c: -0.0238948861397841,
  },
  {
    power: 90,
    pole2a: 1.04741,
    pole2b: 0.0182,
    pole2c: -0.0286878805735611,
    pole4a: 1.03731,
    pole4b: 0.01326,
    pole4c: -0.0237352703156148,
    pole6a: 1.03949,
    pole6b: 0.01474,
    pole6c: -0.0243904842619784,
    pole8a: 1.03949,
    pole8b: 0.01474,
    pole8c: -0.0243904842619784,
  },
  {
    power: 110,
    pole2a: 1.04569,
    pole2b: 0.01782,
    pole2c: -0.0273820518362775,
    pole4a: 1.03729,
    pole4b: 0.01362,
    pole4c: -0.0233519464888223,
    pole6a: 1.03943,
    pole6b: 0.01403,
    pole6c: -0.0250485685827837,
    pole8a: 1.03943,
    pole8b: 0.01403,
    pole8c: -0.0250485685827837,
  },
  {
    power: 132,
    pole2a: 1.0438,
    pole2b: 0.0174,
    pole2c: -0.0259484961666994,
    pole4a: 1.03728,
    pole4b: 0.01401,
    pole4c: -0.0229484916322322,
    pole6a: 1.03936,
    pole6b: 0.01323,
    pole6c: -0.0257888139909004,
    pole8a: 1.03936,
    pole8b: 0.01323,
    pole8c: -0.0257888139909004,
  },
  {
    power: 150,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 160,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 185,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 200,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 220,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 250,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
  {
    power: 300,
    pole2a: 1.04231,
    pole2b: 0.01707,
    pole2c: -0.0248163843196636,
    pole4a: 1.03726,
    pole4b: 0.01432,
    pole4c: -0.0226161369193154,
    pole6a: 1.03931,
    pole6b: 0.01262,
    pole6c: -0.0263573699907171,
    pole8a: 1.03931,
    pole8b: 0.01262,
    pole8c: -0.0263573699907171,
  },
];
