import * as C from "shared-lib/calculation";
import { createTransform } from "redux-persist";

export interface State {
  readonly comparedProduct: ComparedProduct | undefined;
}

export interface ComparedProduct {
  readonly productId: string;
  readonly itemConfig: C.ItemConfig;
}

interface SerializedComparedProduct {
  readonly productId: string;
  readonly itemConfig: string;
}

export const persistTransfrom = createTransform(
  (inboundState: ComparedProduct | undefined, _key: string) => {
    return inboundState && serializeConfig(inboundState);
  },
  (outboundState: SerializedComparedProduct, _key: string) => {
    return deserializeConfig(outboundState);
  },
  { whitelist: ["comparedProduct"] }
);

function serializeConfig(comparedProduct: ComparedProduct): SerializedComparedProduct {
  return {
    ...comparedProduct,
    itemConfig: C.serializeConfig(comparedProduct.itemConfig),
  };
}

function deserializeConfig(comparedProduct: SerializedComparedProduct): ComparedProduct {
  return {
    ...comparedProduct,
    itemConfig: C.deserializeConfig(
      comparedProduct.itemConfig.startsWith("{") ? "" : comparedProduct.itemConfig,
      "use-properties"
    ),
  };
}
