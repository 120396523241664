/* eslint-disable no-restricted-properties */
import * as R from "ramda";
import * as AC from "shared-lib/abstract-chart";
import type { AnyQuantity } from "shared-lib/uom";
import type { Quantity } from "uom-units";
import { UnitsFormat } from "uom-units";
import type { Unit } from "uom";
import { Amount, UnitFormat } from "uom";
import * as AI from "abstract-image";
import * as Style from "shared-lib/style";
import * as Texts from "shared-lib/language-texts";
import { splineGetPointsPerSegment, splineCreateFromPoints } from "shared-lib/interpolation";
import type { LineTextAnchorPosition } from "shared-lib/abstract-chart";
import type { TranslateFunction } from "shared-lib/language-texts";
import type { BoxFanCurve } from "../box-fan/types";
import type { BoxFanAirResult } from "../result-items-types";

export interface BoxFanChartsProps {
  readonly fan: BoxFanAirResult;
  readonly flowUnit: Unit.Unit<Quantity.VolumeFlow>;
  readonly pressureUnit: Unit.Unit<Quantity.Pressure>;
  readonly powerUnit: Unit.Unit<Quantity.Power>;
  readonly translate: Texts.TranslateFunction;
  readonly showRpmCurves: boolean;
  readonly showEfficiencyCurves: boolean;
  readonly showMotorPowerCurves: boolean;
  readonly showLineLabels: boolean;
  readonly showActualRpmCurve: boolean;
  readonly showActualRpmCurveLabel: boolean;
}

export interface BoxFanCharts {
  readonly pressure: AC.Chart;
  readonly power: AC.Chart;
}

export function generateCharts({
  fan,
  flowUnit,
  pressureUnit,
  powerUnit,
  translate,
  showRpmCurves,
  showEfficiencyCurves,
  showMotorPowerCurves,
  showLineLabels,
  showActualRpmCurve,
  showActualRpmCurveLabel,
}: BoxFanChartsProps): BoxFanCharts {
  const xs = R.unnest<number>(
    fan.pressureOutlineCurves.map((c) =>
      c.points.map((p) => Amount.valueAs(flowUnit, Amount.create(p.x, c.unitX as Unit.Unit<Quantity.VolumeFlow>)))
    )
  );

  const minX = Math.min(...xs);
  const maxX = getSuitableMax(minX, xs, 0.15);
  const props = {
    minX: Amount.create(minX, flowUnit),
    maxX: Amount.create(maxX, flowUnit),
    workX: fan.airFlow,
    unitX: flowUnit,
    translate: translate,
  };
  const pressure = generatePressureChart({
    ...props,
    workY: fan.externalPressure,
    desiredY: fan.desiredExternalPressure,
    desiredX: fan.desiredAirFlow,
    unitY: pressureUnit,
    rpmCurves: fan.rpmCurves,
    showActualRpmCurve: showActualRpmCurve,
    showActualRpmCurveLabel: showActualRpmCurveLabel,
    showRpmCurves: showRpmCurves,
    showEfficiencyCurves: showEfficiencyCurves,
    showMotorPowerCurves: showMotorPowerCurves,
    actualRpmCurve: fan.actualRpmCurve,
    pressureOutlineCurvePoints: fan.pressureOutlineCurves,
    efficiencyCurves: fan.efficiencyCurves,
    powerCurves: fan.powerCurves,
  });
  const power = generatePowerChart({
    ...props,
    unitY: powerUnit,
    workY: fan.power,
    motorPower: fan.motorPower,
    frequency: fan.frequency,
    insideWorkingArea: fan.insideWorkingArea,
    showLineLabels: showLineLabels,
    maxPowerDemand: fan.maxPowerDemand,
    powerOutlineCurves: fan.powerOutlineCurves,
  });
  return {
    pressure,
    power,
  };
}

function getSuitableMax(min: number, values: ReadonlyArray<number>, desiredPercentPadding: number): number {
  const valuesMax = Math.max(...values);
  const logValuesMax = Math.log10(valuesMax);
  const step = Math.pow(10, Math.floor(logValuesMax));
  const logMin = Math.log10(min);
  let steps = 1; //Math.ceil(valuesMax / step) * step;
  while (getPercentPadding(steps * step, logMin, logValuesMax) < desiredPercentPadding) {
    steps++;
  }
  return steps * step;
}

function getPercentPadding(max: number, logMin: number, logValuesMax: number): number {
  const logMax = Math.log10(max);
  const percentPadding = 1 - (logValuesMax - logMin) / (logMax - logMin);
  return percentPadding;
}

interface BoxFanPressureChartProps {
  readonly minX?: Amount.Amount<AnyQuantity>;
  readonly maxX?: Amount.Amount<AnyQuantity>;
  readonly desiredX?: Amount.Amount<AnyQuantity>;
  readonly desiredY?: Amount.Amount<AnyQuantity>;
  readonly workX?: Amount.Amount<AnyQuantity>;
  readonly workY?: Amount.Amount<AnyQuantity>;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly translate: Texts.TranslateFunction;
  readonly pressureOutlineCurvePoints: ReadonlyArray<BoxFanCurve>;
  readonly rpmCurves: ReadonlyArray<BoxFanCurve>;
  readonly actualRpmCurve: BoxFanCurve | undefined;
  readonly efficiencyCurves: ReadonlyArray<BoxFanCurve>;
  readonly powerCurves: ReadonlyArray<BoxFanCurve>;
  readonly showActualRpmCurve: boolean;
  readonly showActualRpmCurveLabel: boolean;
  readonly showRpmCurves: boolean;
  readonly showEfficiencyCurves: boolean;
  readonly showMotorPowerCurves: boolean;
}

function generatePressureChart({
  minX,
  maxX,
  unitX,
  unitY,
  translate,
  pressureOutlineCurvePoints,
  rpmCurves,
  efficiencyCurves,
  powerCurves,
  showRpmCurves,
  showEfficiencyCurves,
  showMotorPowerCurves,
  actualRpmCurve,
  showActualRpmCurve,
  showActualRpmCurveLabel,
  workX,
  workY,
  desiredX,
  desiredY,
}: BoxFanPressureChartProps): AC.Chart {
  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLogarithmicAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const yAxis = createLogarithmicAxisY(pressureOutlineCurvePoints, unitY, translate, 5);
  const systemX = workX ?? desiredX;
  const systemY = workY ?? desiredY;
  const components =
    xAxis && yAxis
      ? [
          ...generateArea(Style.diagramBoxFanAreaColor, unitX, unitY, pressureOutlineCurvePoints),
          ...generateCurves(Style.diagramRpmColor, rpmCurves, "start", showRpmCurves, unitX, unitY, "bottomRight"), //RPM curves
          ...generateCurves(
            Style.diagramEfficiencyColor,
            efficiencyCurves,
            "start",
            showEfficiencyCurves,
            unitX,
            unitY,
            "topRight"
          ), // Efficiency curves
          ...generateCurves(
            Style.diagramMotorPowerColor,
            powerCurves,
            "end",
            showMotorPowerCurves,
            unitX,
            unitY,
            "topLeft"
          ), // Power curves
          ...generateCurves(
            Style.diagramActualRpmColor,
            actualRpmCurve,
            "start",
            showActualRpmCurve,
            unitX,
            unitY,
            "bottomRight",
            2,
            showActualRpmCurveLabel
          ),
          ...generateSystemLine(true, xAxis, yAxis, true, systemX, systemY, unitX, unitY), // System line
          ...generatePoint(false, Style.diagramActualRpmColor, false, desiredX, desiredY, unitX, unitY), // Desired Point
          ...generatePoint(true, Style.diagramActualRpmColor, true, workX, workY, unitX, unitY), // Working
        ]
      : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: Style.diagramBoxFanBackgroundColor,
    gridColor: Style.diagramGanymed.grid.lineColor,
  });

  return chart;
}

interface BoxFanPowerChartProps {
  readonly minX?: Amount.Amount<AnyQuantity>;
  readonly maxX?: Amount.Amount<AnyQuantity>;
  readonly workX?: Amount.Amount<AnyQuantity>;
  readonly workY?: Amount.Amount<AnyQuantity>;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly translate: Texts.TranslateFunction;
  readonly powerOutlineCurves: ReadonlyArray<BoxFanCurve>;
  readonly maxPowerDemand?: Amount.Amount<Quantity.Power>;
  readonly motorPower?: Amount.Amount<Quantity.Power>;
  readonly frequency: number | undefined;
  readonly insideWorkingArea: boolean;
  readonly showLineLabels: boolean;
}

function generatePowerChart(props: BoxFanPowerChartProps): AC.Chart {
  const {
    minX,
    maxX,
    unitX,
    unitY,
    translate,
    powerOutlineCurves,
    maxPowerDemand,
    workX,
    workY,
    frequency,
    motorPower,
    insideWorkingArea,
    showLineLabels,
  } = props;
  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLogarithmicAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const yAxis = createLogarithmicAxisY(powerOutlineCurves, unitY, translate);

  const components = xAxis
    ? [
        ...generateArea(Style.diagramBoxFanAreaColor, unitX, unitY, powerOutlineCurves),
        ...generatePowerBox(
          insideWorkingArea,
          maxPowerDemand,
          unitY,
          yAxis,
          xAxis,
          motorPower,
          frequency,
          showLineLabels,
          translate
        ),
        ...generatePoint(insideWorkingArea, Style.diagramActualRpmColor, true, workX, workY, unitX, unitY), // Working
      ]
    : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: Style.diagramBoxFanBackgroundColor,
    gridColor: Style.diagramGanymed.grid.lineColor,
  });

  return chart;
}
function generatePowerBox(
  insideWorkingArea: boolean,
  maxPowerDemand: Amount.Amount<Quantity.Power> | undefined,
  unitY: Unit.Unit<AnyQuantity>,
  yAxis: AC.LogarithmicAxis,
  xAxis: AC.LogarithmicAxis,
  power: Amount.Amount<Quantity.Power> | undefined,
  frequency: number | undefined,
  showLineLabels: boolean,
  translate: TranslateFunction
): ReadonlyArray<AC.ChartComponent> {
  if (!insideWorkingArea || !maxPowerDemand || !power) {
    return [];
  }
  const maxY = Amount.valueAs(unitY, maxPowerDemand);
  const diagramString = `${Amount.valueAs(unitY, power)} ${translate(
    Texts.unitLabel(unitY),
    UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label
  )} 400V ${frequency}hz`;
  return [
    AC.createChartArea({
      points: [
        AI.createPoint(xAxis.min, yAxis.min),
        AI.createPoint(xAxis.min, maxY),
        AI.createPoint(xAxis.max, maxY),
        AI.createPoint(xAxis.max, yAxis.min),
      ],
      color: AI.transparent,
      fill: Style.diagramBoxFanPowerArea,
    }),
    AC.createChartLine({
      points: [AI.createPoint(xAxis.min, maxY), AI.createPoint(xAxis.min + 1, maxY)],
      text: showLineLabels ? diagramString : undefined,
      textPosition: "start",
      textAnchorPosition: "bottomLeft",
      textBackground: true,
      color: Style.diagramActualRpmColor,
    }),
  ];
}

function createLogarithmicAxisY(
  pressureOutlineCurvePoints: ReadonlyArray<BoxFanCurve>,
  unitY: Unit.Unit<AnyQuantity>,
  translate: Texts.TranslateFunction,
  minY?: number
): AC.LogarithmicAxis {
  const yPoints = R.unnest<number>(pressureOutlineCurvePoints.map((c) => c.points.map((p) => p.y)));
  const lowestY = Math.min(...yPoints);
  const highestY = getSuitableMax(lowestY, yPoints, 0.1);
  const unitLabel = translate(Texts.unitLabel(unitY), UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label);
  const valuesUnitY = pressureOutlineCurvePoints[0].unitY;
  return AC.createLogarithmicAxis(
    Amount.valueAs(unitY, Amount.create(Math.max(minY ?? 1, lowestY), valuesUnitY)),
    Amount.valueAs(unitY, Amount.create(highestY, valuesUnitY)),
    unitLabel
  );
}

function generateArea(
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<BoxFanCurve>
): ReadonlyArray<AC.ChartArea> {
  const points = curves.reduce((ps, c) => [...ps, ...generateCurvePoints(unitX, unitY, c)], []);
  return [
    AC.createChartArea({
      points: [...points],
      color: color,
      fill: color,
    }),
  ];
}

function generateCurvePoints(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: BoxFanCurve
): ReadonlyArray<AI.Point> {
  return curve.points.map((p) => createPoint(unitX, unitY, curve, p.x, p.y));
}

function createPoint(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: BoxFanCurve,
  x: number,
  y: number
): AI.Point {
  const chartX = Amount.valueAs(unitX, Amount.create(x, curve.unitX));
  const chartY = Amount.valueAs(unitY, Amount.create(y, curve.unitY));
  return AI.createPoint(chartX, chartY);
}

function generateCurves(
  color: AI.Color,
  curveOrCurves: ReadonlyArray<BoxFanCurve> | BoxFanCurve | undefined,
  textPos: "start" | "middle" | "end" | undefined,
  visible: boolean,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  textAnchorPosition: LineTextAnchorPosition,
  thickness: number = 1,
  displayLabel: boolean = true
): ReadonlyArray<AC.ChartLine> {
  if (!visible || !curveOrCurves) {
    return [];
  }

  const curves = curveOrCurves instanceof Array ? curveOrCurves : [curveOrCurves];

  return curves
    .slice()
    .reverse()
    .map((c) => {
      // reverse in order to get a "better" visual overlapping functionality
      const detailedPoints = splineGetPointsPerSegment(5, splineCreateFromPoints(c.points)).map((p) =>
        createPoint(unitX, unitY, c, p.x, p.y)
      );

      return displayLabel
        ? AC.createChartLine({
            points: detailedPoints,
            thickness: thickness,
            color: color,
            text: c.name,
            textPosition: textPos,
            textAnchorPosition: textAnchorPosition,
            textBackground: true,
          })
        : AC.createChartLine({
            points: detailedPoints,
            thickness: thickness,
            color: color,
          });
    });
}

function generatePoint(
  showLabels: boolean,
  color: AI.Color,
  filled: boolean,
  pX: Amount.Amount<AnyQuantity> | undefined,
  pY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>
): ReadonlyArray<AC.ChartComponent> {
  if (!pX || !pY) {
    return [];
  }
  const x = Amount.valueAs(unitX, pX);
  const y = Amount.valueAs(unitY, pY);
  const axisLines = showLabels ? "lines" : "none";
  if (filled) {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: color,
        position: AI.createPoint(x, y),
        size: AI.createSize(8, 8),
        axisLines: axisLines,
      }),
    ];
  } else {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: AI.transparent,
        stroke: color,
        strokeThickness: 2,
        position: AI.createPoint(x, y),
        size: AI.createSize(12, 12),
        axisLines: axisLines,
      }),
    ];
  }
}

function generateSystemLine(
  showSystemLine: boolean,

  _xAxis: AC.Axis,
  yAxis: AC.Axis,
  valid: boolean,
  workX: Amount.Amount<AnyQuantity> | undefined,
  workY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>
): ReadonlyArray<AC.ChartComponent> {
  if (!workX || !workY || !showSystemLine) {
    return [];
  }
  const xWork = Amount.valueAs(unitX, workX);
  const yWork = Amount.valueAs(unitY, workY);
  const k = yWork / (xWork * xWork);
  const maxPoint = AI.createPoint(Math.sqrt(yAxis.max / k), yAxis.max);
  const minPoint = AI.createPoint(Math.sqrt(yAxis.min / k), yAxis.min);

  return [
    AC.createChartLine({
      points: [minPoint, maxPoint],
      thickness: 1,
      color: valid ? Style.diagramActualRpmColor : Style.diagramGanymed.invalidColor,
    }),
  ];
}
