/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as Redux from "redux";
import { setFieldUnitsCookie } from "client-lib/user-settings/cookie";
import type * as Actions from "./actions";
import { stateSelector } from "./selectors";

type AnyStoreState = any;

export function createMiddleware(): any {
  return (store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    const responseToReturn = next(action);
    if (action.type === "user-settings/SET_FIELD_UNIT" || action.type === "user-settings/CLEAR_FIELD_UNIT") {
      const userSettings = stateSelector(store.getState());
      const fieldUnits = userSettings.fieldUnits;
      if (fieldUnits !== undefined) {
        setFieldUnitsCookie(fieldUnits);
      }
    }
    return responseToReturn;
  };
}
