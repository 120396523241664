import type { ComponentResult } from "shared-lib/search";
import type * as Actions from "./actions";
import type { SelectedVariant } from "./types";

export interface State {
  readonly productId: string | undefined;
  readonly selectedVariant: SelectedVariant | undefined;
  readonly ecomProductId: string | undefined;
  readonly calculationExists: boolean;
  readonly browserLvl1: string | undefined;
  readonly browserLvl2: string | undefined;
  readonly browserSearchNotFound: boolean;
  readonly savedCalculations: ReadonlyArray<ComponentResult>;
  readonly searchItemNumber: string | undefined;
}

const initialState = {
  productId: undefined,
  ecomProductId: undefined,
  selectedVariant: undefined,
  savedCalculations: [],
  browserLvl1: undefined,
  browserLvl2: undefined,
  calculationExists: false,
  browserSearchNotFound: false,
  searchItemNumber: undefined,
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "frico-heating-calculation-epim/SET_SELECTED_PATH":
      return {
        ...state,
        browserLvl1: action.payload.lvl1,
        browserLvl2: action.payload.lvl2,
        productId: action.payload.productId,
        selectedVariant: action.payload.selectedVariant,
        ecomProductId: action.payload.ecomProductId,
        calculationExists: false,
        browserSearchNotFound: false,
      };
    case "frico-heating-calculation-epim/SEARCH_NOT_FOUND":
      return { ...state, browserSearchNotFound: true, calculationExists: false };
    case "frico-heating-calculation-epim/SET_SELECTED_VARIANT":
      return {
        ...state,
        selectedVariant: action.payload,
        calculationExists: false,
        browserSearchNotFound: false,
      };
    case "frico-heating-calculation-epim/CALCULATION_EXISTS":
      return { ...state, calculationExists: true, browserSearchNotFound: false };
    case "frico-heating-calculation-epim/CLEAR_SAVED_CALCULATIONS":
      return {
        ...state,
        savedCalculations: [],
        calculationExists: false,
        browserSearchNotFound: false,
      };
    case "frico-heating-calculation-epim/SAVE_CALCULATION":
      return {
        ...state,
        savedCalculations: [...state.savedCalculations, action.payload],
        calculationExists: false,
        browserSearchNotFound: false,
      };
    case "frico-heating-calculation-epim/REMOVE_CALCULATION":
      return {
        ...state,
        savedCalculations: state.savedCalculations.filter((c) => c.id !== action.payload),
        calculationExists: false,
        browserSearchNotFound: false,
      };
    default:
      return state;
  }
}
