import * as React from "react";
import type * as SC from "shared-lib/system-calculator";
import type { GetUnitFunction, GetDecimalsFunction } from "shared-lib/user-settings";
import * as FD from "shared-lib/system-calculator/shared/fan-diagram";
import * as AbstractChart from "shared-lib/abstract-chart";
import * as AbstractImage from "abstract-image";
import { withTw } from "client-lib/elements";
import type * as Texts from "shared-lib/language-texts";
import * as Style from "shared-lib/style";
import type { FormatNumberFunction } from "shared-lib/utils";

export interface Props {
  readonly results: SC.ResultItemOutputMap;
  readonly getUnit: GetUnitFunction;
  readonly translate: Texts.TranslateFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly getDecimals: GetDecimalsFunction;
}

const diagramSize = AbstractImage.createSize(200, 150);

const NoWrap = withTw("div", "flex");

export function HeatRecoveryUnitDiagrams(props: Props): React.ReactElement<Props> {
  const { results, getUnit, getDecimals, translate } = props;

  const maybeResultItem = results.HeatRecoveryUnit;
  const resultItem = maybeResultItem && maybeResultItem.type === "OutputMapperSuccess" && maybeResultItem.result;
  const hru =
    resultItem &&
    (resultItem.type === "HeatRecoveryUnit" || resultItem.type === "SystemairHeatRecoveryUnit") &&
    resultItem.value;
  if (!hru) {
    return <div />;
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);

  const { supply, extract } = FD.generateSupplyExtractCharts({
    supplyFan: hru.supplyFan,
    extractFan: hru.extractFan,
    flowUnit: flowUnit,
    pressureUnit: pressureUnit,
    translate: translate,
    showLineLabels: false,
    style: Style.diagramGanymed,
  });

  const supplyWithDecimals = {
    ...supply,
    pressure: {
      ...supply.pressure,
      linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
      linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
    },
  };

  const extractWithDecimals = {
    ...extract,
    pressure: {
      ...extract.pressure,
      linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
      linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
    },
  };

  return (
    <NoWrap>
      {renderDiagram(supplyWithDecimals.pressure, props.formatNumber)}
      {renderDiagram(extractWithDecimals.pressure, props.formatNumber)}
    </NoWrap>
  );
}

function renderDiagram(chart: AbstractChart.Chart, formatNumber: FormatNumberFunction): React.ReactElement {
  const abstractImage = AbstractChart.renderChart(chart, diagramSize, { formatNumber: formatNumber.format });
  const rendered = AbstractImage.createReactSvg(abstractImage);
  return rendered;
}
