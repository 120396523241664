/* eslint-disable no-restricted-globals */
import type * as QP from "shared-lib/query-product";
import * as R from "ramda";
import { Amount } from "uom";
import type { Quantity} from "uom-units";
import { Units } from "uom-units";
import * as Interpolation from "shared-lib/interpolation";
import type { SoundFilter } from "shared-lib/user-settings";
import type {
  SoundResult,
  OctaveBands,
  OctaveBands3rd,
  Curve,
  Solution,
  FanAirResult,
  WorkingPoint,
  SpeedControl,
} from "../result-items-types";
import * as FanAir from "./fan-air";
import * as AirDensity from "../shared/air-density";
import * as CI from "./calculation-inputs";

export function calcSound(
  speedControl: SpeedControl,
  desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  airDensity: Amount.Amount<Quantity.Density> | undefined,
  fanResult: FanAirResult,
  soundDataTable: ReadonlyArray<QP.SoundData>,
  soundQuantity: QP.SoundQuantity,
  fixedCurveId: string | undefined,
  _impellerDiameterMM?: number, // Only used for AMCA fan sound, added here to have the same function signature
  _numberOfFanBlades?: number // Only used for AMCA fan sound, added here to have the same function signature
): SoundResult {
  const soundData = soundDataTable.filter((r) => r.sound_quantity === soundQuantity);
  if (!fanResult.workingPoint || soundData.length === 0) {
    return {};
  }

  const desiredAirFlowLps = desiredAirFlow && Amount.valueAs(Units.LiterPerSecond, desiredAirFlow);

  const requiredPressureByDensity = AirDensity.calculateExternalPressureWithDensity(
    desiredExternalPressure,
    airDensity
  );
  const requiredPressureByDensityPa =
    requiredPressureByDensity && Amount.valueAs(Units.Pascal, requiredPressureByDensity);

  const airWorkingPoint = fanResult.workingPoint;
  const airPressureCurves = fanResult.pressureCurves;

  const soundAirData: ReadonlyArray<QP.AirData> = soundData.map((r) => ({
    meas: r.measurement,
    part: "Main" as QP.AirDataPart,
    param: "Ps_v" as QP.AirDataParam,
    step: r.step_name,
    volt_ctrl: r.voltage_control,
    volt_supp: r.voltage_control,
    freq_ctrl: r.frequency_control,
    flow: r.Q_v,
    value: r.Ps_v,
  }));

  const soundPressureCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    soundAirData.filter((p) => p.param === "Ps_v"),
    []
  );

  const soundWorkingPoint =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? FanAir.findWorkingPoint(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          soundPressureCurves,
          fixedCurveId
        )
      : undefined;
  const workingPoint =
    airPressureCurves.length !== soundPressureCurves.length ||
    // airPressureCurves.some((c, i) => c.id !== soundPressureCurves[i].id)
    airPressureCurves.some((c) => soundPressureCurves.find((s) => s.id === c.id) === undefined)
      ? soundWorkingPoint
      : airWorkingPoint;

  const octaveBands3rd = calcOctaveBands3rd(
    airWorkingPoint,
    workingPoint,
    soundData,
    airPressureCurves,
    fanResult.rpmCurves
  );
  const octaveBands3rdA = aWeightOctaveBands3rd(octaveBands3rd);
  const octaveBands = calcOctaveBandsFrom3rds(octaveBands3rd);
  const octaveBandsA = calcOctaveBandsFrom3rds(octaveBands3rdA);
  return {
    octaveBands: octaveBands,
    octaveBandsA: octaveBandsA,
    octaveBands3rd: octaveBands3rd,
    octaveBands3rdA: octaveBands3rdA,
  };
}

export function applyFilter(
  filter: SoundFilter,
  rawOctaveBands: OctaveBands | OctaveBands3rd
): OctaveBands | OctaveBands3rd {
  if (filter === "A") {
    return rawOctaveBands.type === "Octave3rd"
      ? aWeightOctaveBands3rd(rawOctaveBands)!
      : aWeightOctaveBands(rawOctaveBands)!;
  } else if (filter === "C") {
    return rawOctaveBands.type === "Octave3rd"
      ? cWeightOctaveBands3rd(rawOctaveBands)!
      : cWeightOctaveBands(rawOctaveBands)!;
  } else {
    return rawOctaveBands;
  }
}

export function getFilterUnit(soundFilter: SoundFilter): string {
  switch (soundFilter) {
    case "A":
      return "dB(A)";
    case "C":
      return "dB(C)";
    case "None":
      return "dB";
    default:
      return "dB";
  }
}

export function sumResults(a: SoundResult, b: SoundResult): SoundResult {
  if (!a.octaveBands3rd || !b.octaveBands3rd) {
    return {};
  }
  const octaveBands3rd = createOctaveBands3rd(
    sumOctaveBands([a.octaveBands3rd.hz50, b.octaveBands3rd.hz50]),
    sumOctaveBands([a.octaveBands3rd.hz63, b.octaveBands3rd.hz63]),
    sumOctaveBands([a.octaveBands3rd.hz80, b.octaveBands3rd.hz80]),
    sumOctaveBands([a.octaveBands3rd.hz100, b.octaveBands3rd.hz100]),
    sumOctaveBands([a.octaveBands3rd.hz125, b.octaveBands3rd.hz125]),
    sumOctaveBands([a.octaveBands3rd.hz160, b.octaveBands3rd.hz160]),
    sumOctaveBands([a.octaveBands3rd.hz200, b.octaveBands3rd.hz200]),
    sumOctaveBands([a.octaveBands3rd.hz250, b.octaveBands3rd.hz250]),
    sumOctaveBands([a.octaveBands3rd.hz315, b.octaveBands3rd.hz315]),
    sumOctaveBands([a.octaveBands3rd.hz400, b.octaveBands3rd.hz400]),
    sumOctaveBands([a.octaveBands3rd.hz500, b.octaveBands3rd.hz500]),
    sumOctaveBands([a.octaveBands3rd.hz630, b.octaveBands3rd.hz630]),
    sumOctaveBands([a.octaveBands3rd.hz800, b.octaveBands3rd.hz800]),
    sumOctaveBands([a.octaveBands3rd.hz1000, b.octaveBands3rd.hz1000]),
    sumOctaveBands([a.octaveBands3rd.hz1250, b.octaveBands3rd.hz1250]),
    sumOctaveBands([a.octaveBands3rd.hz1600, b.octaveBands3rd.hz1600]),
    sumOctaveBands([a.octaveBands3rd.hz2000, b.octaveBands3rd.hz2000]),
    sumOctaveBands([a.octaveBands3rd.hz2500, b.octaveBands3rd.hz2500]),
    sumOctaveBands([a.octaveBands3rd.hz3150, b.octaveBands3rd.hz3150]),
    sumOctaveBands([a.octaveBands3rd.hz4000, b.octaveBands3rd.hz4000]),
    sumOctaveBands([a.octaveBands3rd.hz5000, b.octaveBands3rd.hz5000]),
    sumOctaveBands([a.octaveBands3rd.hz6300, b.octaveBands3rd.hz6300]),
    sumOctaveBands([a.octaveBands3rd.hz8000, b.octaveBands3rd.hz8000]),
    sumOctaveBands([a.octaveBands3rd.hz10000, b.octaveBands3rd.hz10000])
  );
  const octaveBands3rdA = aWeightOctaveBands3rd(octaveBands3rd);
  const octaveBands = calcOctaveBandsFrom3rds(octaveBands3rd);
  const octaveBandsA = calcOctaveBandsFrom3rds(octaveBands3rdA);
  return {
    octaveBands: octaveBands,
    octaveBandsA: octaveBandsA,
    octaveBands3rd: octaveBands3rd,
    octaveBands3rdA: octaveBands3rdA,
  };
}

function calcOctaveBands3rd(
  airWorkingPoint: WorkingPoint,
  workingPoint: WorkingPoint | undefined,
  soundData: ReadonlyArray<QP.SoundData>,
  airPressureCurves: ReadonlyArray<Curve>,
  rpmCurves: ReadonlyArray<Curve>
): OctaveBands3rd | undefined {
  if (!workingPoint) {
    return undefined;
  }
  const rpmWork = FanAir.calcValueFromWorkingPoint(airWorkingPoint, rpmCurves);
  if (!rpmWork) {
    return undefined;
  }
  const soundPoints = soundData
    .map((p) => fixSoundPoint(p, airPressureCurves, rpmCurves))
    .sort((a, b) => a.Q_v - b.Q_v);
  const curves = R.groupBy((r) => r.step_name, soundPoints);
  const pWork = getSoundForSolution(curves, rpmWork, workingPoint.solution);
  if (!pWork) {
    return undefined;
  }

  const octaveBands3rd = createOctaveBands3rd(
    pWork.snd_50,
    pWork.snd_63,
    pWork.snd_80,
    pWork.snd_100,
    pWork.snd_125,
    pWork.snd_160,
    pWork.snd_200,
    pWork.snd_250,
    pWork.snd_315,
    pWork.snd_400,
    pWork.snd_500,
    pWork.snd_630,
    pWork.snd_800,
    pWork.snd_1000,
    pWork.snd_1250,
    pWork.snd_1600,
    pWork.snd_2000,
    pWork.snd_2500,
    pWork.snd_3150,
    pWork.snd_4000,
    pWork.snd_5000,
    pWork.snd_6300,
    pWork.snd_8000,
    pWork.snd_10000
  );

  return octaveBands3rd;
}

interface SoundCurves {
  readonly [id: string]: ReadonlyArray<QP.SoundData>;
}

function getSoundForSolution(curves: SoundCurves, rpmWork: number, solution: Solution): QP.SoundData | undefined {
  if (solution.type === "Curve") {
    const curve = curves[solution.curveId];
    if (!curve) {
      return undefined;
    }
    const point = soundCurveFindPoint(solution.point, rpmWork, curve);
    return point;
  } else if (solution.type === "Interpolated") {
    const lower = getSoundForSolution(curves, rpmWork, solution.lower);
    const higher = getSoundForSolution(curves, rpmWork, solution.higher);
    if (!lower || !higher) {
      console.warn({ lower, higher });
      return undefined;
    }
    const interpolated = interpolatePoint(lower.r_v, lower, higher.r_v, higher, rpmWork, solution.point);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(interpolated.Ps_v)) {
      console.warn({ interpolated, lower, higher, rpmWork, curves });
    }
    return interpolated;
  }
  return undefined;
}

function fixSoundPoint(
  point: QP.SoundData,
  pressureCurves: ReadonlyArray<Curve>,
  rpmCurves: ReadonlyArray<Curve>
): QP.SoundData {
  const id = point.step_name;
  const pressureCurve = pressureCurves.find((c) => c.id === id);
  const pressure =
    pressureCurve && point.Ps_v <= 0 ? Interpolation.splineGetPoint(point.Q_v, pressureCurve.spline) : point.Ps_v;
  const rpmCurve = rpmCurves.find((c) => c.id === id);
  const rpm = rpmCurve && point.r_v <= 0 ? Interpolation.splineGetPoint(point.Q_v, rpmCurve.spline) : point.r_v;
  return {
    ...point,
    Ps_v: pressure || point.Ps_v,
    r_v: rpm || point.r_v,
  };
}

function soundCurveFindPoint(
  point: Interpolation.Vec2,
  rpmWork: number,
  curve: ReadonlyArray<QP.SoundData>
): QP.SoundData {
  const psLeft = curve.filter((p) => p.Q_v < point.x);
  const psRight = curve.filter((p) => p.Q_v >= point.x);
  if (psLeft.length === 0) {
    const pRight = amcaRpmCorrection(psRight[0], rpmWork);
    return pRight;
  } else if (psRight.length === 0) {
    const pLeft = amcaRpmCorrection(psLeft[psLeft.length - 1], rpmWork);
    return pLeft;
  } else {
    const pLeft = amcaRpmCorrection(psLeft[psLeft.length - 1], rpmWork);
    const pRight = amcaRpmCorrection(psRight[0], rpmWork);
    const pWork = interpolatePoint(pLeft.Q_v, pLeft, pRight.Q_v, pRight, point.x, point);
    return pWork;
  }
}

const bandFrequencies = [
  50,
  63,
  80,
  100,
  125,
  160,
  200,
  250,
  315,
  400,
  500,
  630,
  800,
  1000,
  1250,
  1600,
  2000,
  2500,
  3150,
  4000,
  5000,
  6300,
  8000,
  10000,
];

const bandWidths = [
  50,
  63,
  80,
  100,
  125,
  160,
  200,
  250,
  315,
  400,
  500,
  630,
  800,
  1000,
  1250,
  1600,
  2000,
  2500,
  3150,
  4000,
  5000,
  6300,
  8000,
  10000,
];

function amcaRpmCorrection(p: QP.SoundData, rpmWork: number): QP.SoundData {
  const rpmPoint = p.r_v;
  const levels = [
    p.snd_50,
    p.snd_63,
    p.snd_80,
    p.snd_100,
    p.snd_125,
    p.snd_160,
    p.snd_200,
    p.snd_250,
    p.snd_315,
    p.snd_400,
    p.snd_500,
    p.snd_630,
    p.snd_800,
    p.snd_1000,
    p.snd_1250,
    p.snd_1600,
    p.snd_2000,
    p.snd_2500,
    p.snd_3150,
    p.snd_4000,
    p.snd_5000,
    p.snd_6300,
    p.snd_8000,
    p.snd_10000,
  ];
  const lwgc = [];
  const lwg = [];
  const x = [];
  const xc = [];

  for (let i = 0; i < levels.length; i++) {
    lwg.push(levels[i] - 40 * Math.log10(rpmPoint / 1000) - 10 * Math.log10(bandWidths[i]));
    x.push(10 * Math.log10(bandFrequencies[i] / rpmPoint));
    xc.push(10 * Math.log10(bandFrequencies[i] / rpmWork));
  }
  for (let i = 0; i < levels.length; i++) {
    lwgc.push(interpolateArrays(x, xc[i], lwg) + 40 * Math.log10(rpmWork / 1000) + 10 * Math.log10(bandWidths[i]));
  }

  return {
    ...p,
    snd_50: lwgc[0],
    snd_63: lwgc[1],
    snd_80: lwgc[2],
    snd_100: lwgc[3],
    snd_125: lwgc[4],
    snd_160: lwgc[5],
    snd_200: lwgc[6],
    snd_250: lwgc[7],
    snd_315: lwgc[8],
    snd_400: lwgc[9],
    snd_500: lwgc[10],
    snd_630: lwgc[11],
    snd_800: lwgc[12],
    snd_1000: lwgc[13],
    snd_1250: lwgc[14],
    snd_1600: lwgc[15],
    snd_2000: lwgc[16],
    snd_2500: lwgc[17],
    snd_3150: lwgc[18],
    snd_4000: lwgc[19],
    snd_5000: lwgc[20],
    snd_6300: lwgc[21],
    snd_8000: lwgc[22],
    snd_10000: lwgc[23],
  };
}

function interpolateArrays(v1: ReadonlyArray<number>, x: number, v2: ReadonlyArray<number>): number {
  if (isNaN(x)) {
    return NaN;
  }

  if (isNaN(v1[0])) {
    return NaN;
  }

  if (x <= v1[0]) {
    return v2[0];
  }
  if (x >= v1[v1.length - 1]) {
    return v2[v1.length - 1];
  }
  let i = 0;
  for (i = 0; i < v1.length; i++) {
    if (v1[i] >= x) {
      break;
    }
  }
  const rd = v1[i] - v1[i - 1];
  const vd = v2[i] - v2[i - 1];
  return v2[i - 1] + (vd / rd) * (x - v1[i - 1]);
}

// function kForPoint(point: QP.SoundData): number {
//   return point.Ps_v / (point.Q_v * point.Q_v);
// }

function interpolatePoint(
  aX: number,
  aP: QP.SoundData,
  bX: number,
  bP: QP.SoundData,
  x: number,
  point: Interpolation.Vec2
): QP.SoundData {
  return {
    measurement: aP.measurement,
    step_name: aP.step_name,
    voltage_control: aP.voltage_control,
    frequency_control: aP.frequency_control,
    sound_quantity: aP.sound_quantity,
    Q_v: point.x,
    Ps_v: point.y,
    r_v: interpolateValue(aX, aP.r_v, bX, bP.r_v, x),
    snd_50: interpolateValue(aX, aP.snd_50, bX, bP.snd_50, x),
    snd_63: interpolateValue(aX, aP.snd_63, bX, bP.snd_63, x),
    snd_80: interpolateValue(aX, aP.snd_80, bX, bP.snd_80, x),
    snd_100: interpolateValue(aX, aP.snd_100, bX, bP.snd_100, x),
    snd_125: interpolateValue(aX, aP.snd_125, bX, bP.snd_125, x),
    snd_160: interpolateValue(aX, aP.snd_160, bX, bP.snd_160, x),
    snd_200: interpolateValue(aX, aP.snd_200, bX, bP.snd_200, x),
    snd_250: interpolateValue(aX, aP.snd_250, bX, bP.snd_250, x),
    snd_315: interpolateValue(aX, aP.snd_315, bX, bP.snd_315, x),
    snd_400: interpolateValue(aX, aP.snd_400, bX, bP.snd_400, x),
    snd_500: interpolateValue(aX, aP.snd_500, bX, bP.snd_500, x),
    snd_630: interpolateValue(aX, aP.snd_630, bX, bP.snd_630, x),
    snd_800: interpolateValue(aX, aP.snd_800, bX, bP.snd_800, x),
    snd_1000: interpolateValue(aX, aP.snd_1000, bX, bP.snd_1000, x),
    snd_1250: interpolateValue(aX, aP.snd_1250, bX, bP.snd_1250, x),
    snd_1600: interpolateValue(aX, aP.snd_1600, bX, bP.snd_1600, x),
    snd_2000: interpolateValue(aX, aP.snd_2000, bX, bP.snd_2000, x),
    snd_2500: interpolateValue(aX, aP.snd_2500, bX, bP.snd_2500, x),
    snd_3150: interpolateValue(aX, aP.snd_3150, bX, bP.snd_3150, x),
    snd_4000: interpolateValue(aX, aP.snd_4000, bX, bP.snd_4000, x),
    snd_5000: interpolateValue(aX, aP.snd_5000, bX, bP.snd_5000, x),
    snd_6300: interpolateValue(aX, aP.snd_6300, bX, bP.snd_6300, x),
    snd_8000: interpolateValue(aX, aP.snd_8000, bX, bP.snd_8000, x),
    snd_10000: interpolateValue(aX, aP.snd_10000, bX, bP.snd_10000, x),
  };
}

function interpolateValue(xa: number, ya: number, xb: number, yb: number, xc: number): number {
  const dx = xb - xa;
  if (dx === 0) {
    return ya;
  }
  const dy = yb - ya;
  const k = dy / dx;
  const m = ya - k * xa;
  const v = k * xc + m;
  return v;
}

export function calcTotFromOctaveBands(octaveBands: OctaveBands | undefined): number | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  return sumOctaveBands([
    octaveBands.hz63,
    octaveBands.hz125,
    octaveBands.hz250,
    octaveBands.hz500,
    octaveBands.hz1000,
    octaveBands.hz2000,
    octaveBands.hz4000,
    octaveBands.hz8000,
  ]);
}

export function calcTotFromOctaveBands3rd(octaveBands3rd: OctaveBands3rd | undefined): number | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }
  return sumOctaveBands([
    octaveBands3rd.hz50,
    octaveBands3rd.hz63,
    octaveBands3rd.hz80,
    octaveBands3rd.hz100,
    octaveBands3rd.hz125,
    octaveBands3rd.hz160,
    octaveBands3rd.hz200,
    octaveBands3rd.hz250,
    octaveBands3rd.hz315,
    octaveBands3rd.hz400,
    octaveBands3rd.hz500,
    octaveBands3rd.hz630,
    octaveBands3rd.hz800,
    octaveBands3rd.hz1000,
    octaveBands3rd.hz1250,
    octaveBands3rd.hz1600,
    octaveBands3rd.hz2000,
    octaveBands3rd.hz2500,
    octaveBands3rd.hz3150,
    octaveBands3rd.hz4000,
    octaveBands3rd.hz5000,
    octaveBands3rd.hz6300,
    octaveBands3rd.hz8000,
    octaveBands3rd.hz10000,
  ]);
}

export function aWeightOctaveBands(octaveBands: OctaveBands | OctaveBands3rd | undefined): OctaveBands | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  return createOctaveBands(
    safeAdd(octaveBands.hz63, -26.2),
    safeAdd(octaveBands.hz125, -16.1),
    safeAdd(octaveBands.hz250, -8.6),
    safeAdd(octaveBands.hz500, -3.2),
    safeAdd(octaveBands.hz1000, 0),
    safeAdd(octaveBands.hz2000, 1.2),
    safeAdd(octaveBands.hz4000, 1),
    safeAdd(octaveBands.hz8000, -1.1)
  );
}

export function cWeightOctaveBands(octaveBands: OctaveBands | undefined): OctaveBands | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  return createOctaveBands(
    safeAdd(octaveBands.hz63, -0.8),
    safeAdd(octaveBands.hz125, -0.2),
    safeAdd(octaveBands.hz250, 0),
    safeAdd(octaveBands.hz500, 0),
    safeAdd(octaveBands.hz1000, 0),
    safeAdd(octaveBands.hz2000, -0.2),
    safeAdd(octaveBands.hz4000, -0.8),
    safeAdd(octaveBands.hz8000, -3)
  );
}

export function aWeightOctaveBands3rd(octaveBands3rd: OctaveBands3rd | undefined): OctaveBands3rd | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }
  return createOctaveBands3rd(
    safeAdd(octaveBands3rd.hz50, -30.2),
    safeAdd(octaveBands3rd.hz63, -26.2),
    safeAdd(octaveBands3rd.hz80, -22.5),
    safeAdd(octaveBands3rd.hz100, -19.1),
    safeAdd(octaveBands3rd.hz125, -16.1),
    safeAdd(octaveBands3rd.hz160, -13.4),
    safeAdd(octaveBands3rd.hz200, -10.9),
    safeAdd(octaveBands3rd.hz250, -8.6),
    safeAdd(octaveBands3rd.hz315, -6.6),
    safeAdd(octaveBands3rd.hz400, -4.8),
    safeAdd(octaveBands3rd.hz500, -3.2),
    safeAdd(octaveBands3rd.hz630, -1.9),
    safeAdd(octaveBands3rd.hz800, -0.8),
    safeAdd(octaveBands3rd.hz1000, 0),
    safeAdd(octaveBands3rd.hz1250, 0.6),
    safeAdd(octaveBands3rd.hz1600, 1),
    safeAdd(octaveBands3rd.hz2000, 1.2),
    safeAdd(octaveBands3rd.hz2500, 1.3),
    safeAdd(octaveBands3rd.hz3150, 1.2),
    safeAdd(octaveBands3rd.hz4000, 1),
    safeAdd(octaveBands3rd.hz5000, 0.5),
    safeAdd(octaveBands3rd.hz6300, -0.1),
    safeAdd(octaveBands3rd.hz8000, -1.1),
    safeAdd(octaveBands3rd.hz10000, -2.5)
  );
}

export function cWeightOctaveBands3rd(octaveBands3rd: OctaveBands3rd | undefined): OctaveBands3rd | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }
  return createOctaveBands3rd(
    safeAdd(octaveBands3rd.hz50, -1.3),
    safeAdd(octaveBands3rd.hz63, -0.8),
    safeAdd(octaveBands3rd.hz80, -0.5),
    safeAdd(octaveBands3rd.hz100, -0.3),
    safeAdd(octaveBands3rd.hz125, -0.2),
    safeAdd(octaveBands3rd.hz160, 0.1),
    safeAdd(octaveBands3rd.hz200, 0),
    safeAdd(octaveBands3rd.hz250, 0),
    safeAdd(octaveBands3rd.hz315, 0),
    safeAdd(octaveBands3rd.hz400, 0),
    safeAdd(octaveBands3rd.hz500, 0),
    safeAdd(octaveBands3rd.hz630, 0),
    safeAdd(octaveBands3rd.hz800, 0),
    safeAdd(octaveBands3rd.hz1000, 0),
    safeAdd(octaveBands3rd.hz1250, 0),
    safeAdd(octaveBands3rd.hz1600, -0.1),
    safeAdd(octaveBands3rd.hz2000, -0.2),
    safeAdd(octaveBands3rd.hz2500, -0.3),
    safeAdd(octaveBands3rd.hz3150, -0.5),
    safeAdd(octaveBands3rd.hz4000, -0.8),
    safeAdd(octaveBands3rd.hz5000, -1.3),
    safeAdd(octaveBands3rd.hz6300, -2),
    safeAdd(octaveBands3rd.hz8000, -3),
    safeAdd(octaveBands3rd.hz10000, -4.4)
  );
}

export function calcOctaveBandsFrom3rds(
  octaveBands: OctaveBands3rd | OctaveBands | undefined
): OctaveBands | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  if (octaveBands.type === "Octave") {
    return octaveBands;
  }
  return createOctaveBands(
    sumOctaveBands([octaveBands.hz50, octaveBands.hz63, octaveBands.hz80]),
    sumOctaveBands([octaveBands.hz100, octaveBands.hz125, octaveBands.hz160]),
    sumOctaveBands([octaveBands.hz200, octaveBands.hz250, octaveBands.hz315]),
    sumOctaveBands([octaveBands.hz400, octaveBands.hz500, octaveBands.hz630]),
    sumOctaveBands([octaveBands.hz800, octaveBands.hz1000, octaveBands.hz1250]),
    sumOctaveBands([octaveBands.hz1600, octaveBands.hz2000, octaveBands.hz2500]),
    sumOctaveBands([octaveBands.hz3150, octaveBands.hz4000, octaveBands.hz5000]),
    sumOctaveBands([octaveBands.hz6300, octaveBands.hz8000, octaveBands.hz10000])
  );
}

function safeAdd(a: number | undefined, b: number | undefined): number | undefined {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  return a + b;
}

function safeSub(a: number | undefined, b: number | undefined): number | undefined {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  return a - b;
}

function sumOctaveBands(octaveBands: ReadonlyArray<number | undefined>): number | undefined {
  let tot = 0.0;
  for (const band of octaveBands) {
    if (band === undefined) {
      return undefined;
    }
    // eslint-disable-next-line no-restricted-properties
    tot += Math.pow(10, 0.1 * band);
  }
  return 10 * Math.log10(tot);
}

export function applyAttenuation(
  octaveBands: OctaveBands | undefined,
  attenuation: OctaveBands | undefined
): OctaveBands | undefined {
  if (!octaveBands || !attenuation) {
    return undefined;
  }
  return createOctaveBands(
    safeSub(octaveBands.hz63, attenuation.hz63),
    safeSub(octaveBands.hz125, attenuation.hz125),
    safeSub(octaveBands.hz250, attenuation.hz250),
    safeSub(octaveBands.hz500, attenuation.hz500),
    safeSub(octaveBands.hz1000, attenuation.hz1000),
    safeSub(octaveBands.hz2000, attenuation.hz2000),
    safeSub(octaveBands.hz4000, attenuation.hz4000),
    safeSub(octaveBands.hz8000, attenuation.hz8000)
  );
}

export function applyOneAttenuation(
  octaveBands: OctaveBands | undefined,
  attenuation: number
): OctaveBands | undefined {
  if (!octaveBands || !attenuation) {
    return undefined;
  }
  return createOctaveBands(
    safeSub(octaveBands.hz63, attenuation),
    safeSub(octaveBands.hz125, attenuation),
    safeSub(octaveBands.hz250, attenuation),
    safeSub(octaveBands.hz500, attenuation),
    safeSub(octaveBands.hz1000, attenuation),
    safeSub(octaveBands.hz2000, attenuation),
    safeSub(octaveBands.hz4000, attenuation),
    safeSub(octaveBands.hz8000, attenuation)
  );
}

export function createOctaveBands(
  hz63: number | undefined,
  hz125: number | undefined,
  hz250: number | undefined,
  hz500: number | undefined,
  hz1000: number | undefined,
  hz2000: number | undefined,
  hz4000: number | undefined,
  hz8000: number | undefined
): OctaveBands {
  const total = sumOctaveBands([hz63, hz125, hz250, hz500, hz1000, hz2000, hz4000, hz8000]);
  return {
    type: "Octave",
    hz63,
    hz125,
    hz250,
    hz500,
    hz1000,
    hz2000,
    hz4000,
    hz8000,
    total: total !== undefined ? Amount.create(total, Units.DecibelLw, 1) : undefined,
  };
}

export function createOctaveBands3rd(
  hz50: number | undefined,
  hz63: number | undefined,
  hz80: number | undefined,
  hz100: number | undefined,
  hz125: number | undefined,
  hz160: number | undefined,
  hz200: number | undefined,
  hz250: number | undefined,
  hz315: number | undefined,
  hz400: number | undefined,
  hz500: number | undefined,
  hz630: number | undefined,
  hz800: number | undefined,
  hz1000: number | undefined,
  hz1250: number | undefined,
  hz1600: number | undefined,
  hz2000: number | undefined,
  hz2500: number | undefined,
  hz3150: number | undefined,
  hz4000: number | undefined,
  hz5000: number | undefined,
  hz6300: number | undefined,
  hz8000: number | undefined,
  hz10000: number | undefined
): OctaveBands3rd {
  const total = sumOctaveBands([
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000,
  ]);
  return {
    type: "Octave3rd",
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000,
    total: total !== undefined ? Amount.create(total, Units.DecibelLw, 1) : undefined,
  };
}
