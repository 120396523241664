import * as React from "react";
import { Icon } from "./icon";

interface ClassnameProps {
  readonly className?: string;
}

export function Spinner({ className }: ClassnameProps): React.ReactElement {
  return (
    <div className={`flex flex-row items-center justify-center ${className} p-24`}>
      <Icon icon="spinner-third" className="fa-spin w-36 h-36" />
    </div>
  );
}
