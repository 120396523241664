// Lib imports
import * as Redux from "redux";
import * as ReduxQuery from "shared-lib/redux-query/core";
// App imports
import type * as UserSettingsShared from "shared-lib/user-settings";
import * as UserSettingsClient from "client-lib/user-settings";
import * as Search from "client-lib/search";
import * as External from "client-lib/external";
import * as Compare from "client-lib/compare";
import { persistReducer /* createTransform , PersistConfig, Transform */ } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as localforage from "localforage";
import * as Product from "./containers/product/reducer";
//import * as ProductMultiple from "./containers/product-multiple/reducer";
import * as SearchProducts from "./containers/search-products/reducer";
import * as SearchResultsTabe from "./containers/search-result-table/reducer";
import * as FricoSpecificationTextEpim from "./containers/frico-specification-text-epim/reducer";
import * as FricoProductBrowserEpim from "./containers/frico-product-browser-epim/reducer";
import * as FricoHeatingCalculationEpim from "./containers/frico-heating-calculation-epim/reducer";
import * as FricoProductTable from "./containers/frico-product-table/reducer";
import * as FricoAccessoriesTableEpim from "./containers/frico-accessories-table-epim/reducer";
import type { RootState, Ui } from "./types";

const productPersistConfig = {
  key: "ui",
  storage: localforage,
  whitelist: ["searchProducts", "searchResults"],
};

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["search", "settings"],
};

const comparePersistConfig = {
  key: "compare",
  storage: localforage,
  transforms: [Compare.persistTransfrom],
};

const fricoPersistConfig = {
  key: "fricoSpecificationTextEpim",
  storage: storage,
  whitelist: ["persistedSearchSelections", "persistedAddedItems"],
};

const uiReducer = Redux.combineReducers<Ui>({
  product: Product.reducer,
  //productMultiple: ProductMultiple.reducer,
  fricoProductBrowserEpim: FricoProductBrowserEpim.reducer,
  fricoAccessoriesTableEpim: FricoAccessoriesTableEpim.reducer,
  fricoProductTable: FricoProductTable.reducer,
  fricoHeatingCalculationEpim: FricoHeatingCalculationEpim.reducer,
  searchProducts: SearchProducts.reducer,
  searchResults: SearchResultsTabe.reducer,
  fricoSpecificationTextEpim: persistReducer(fricoPersistConfig, FricoSpecificationTextEpim.reducer),
});

const settingsReducer = Redux.combineReducers<UserSettingsShared.State>({
  user: UserSettingsClient.reducer,
});

const rootReducer = Redux.combineReducers<RootState>({
  ui: persistReducer(productPersistConfig, uiReducer),
  settings: settingsReducer,
  search: Search.reducer,
  query: ReduxQuery.createQueryReducer(/*diaqSelectQueries*/),
  external: External.reducer,
  compare: persistReducer(comparePersistConfig, Compare.reducer),
});
export const persistedRootReducer = persistReducer(persistConfig, rootReducer);
