import type * as QP from "shared-lib/query-product";
import { createSelector } from "reselect";

type ValueOf<T> = T[keyof T];

export type FormatNumberFunction = { readonly format: (number: string) => string; readonly getFormat: NumberFormat };

export type NumberFormat = {
  readonly thousandSeparator: SeparatorSymbol;
  readonly decimalSeparator: SeparatorSymbol;
};

export function getLocalizedNumberFormat(isoCode: string, ct_LanguageMapping: QP.LanguageMappingTable): NumberFormat {
  const format = getNumberFormat(
    ct_LanguageMapping.find((row) => row.iso_code === isoCode || row.language === isoCode)
  );
  return format;
}

export function formatNumberFunction(
  isoCode: string,
  ct_LanguageMapping: QP.LanguageMappingTable
): FormatNumberFunction {
  return formatNumberFunctionMemo({ isoCode, ct_LanguageMapping });
}

interface FormatNumberFunctionMemoProps {
  readonly isoCode: string;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}
const formatNumberFunctionMemo = createSelector(
  (p: FormatNumberFunctionMemoProps) => p.isoCode,
  (p: FormatNumberFunctionMemoProps) => p.ct_LanguageMapping,
  (isoCode, ct_LanguageMapping) => {
    const format = getLocalizedNumberFormat(isoCode, ct_LanguageMapping);
    return { format: (number: string) => stringToLocalized(number, format), getFormat: format };
  }
);

export function stringToLocalized(value: string, numberFormat: NumberFormat): string {
  const isMinus = value.startsWith("-");
  const val = value.replace("-", "").replace(",", ".").replace(".", numberFormat.decimalSeparator);
  const parts = val.split(numberFormat.decimalSeparator);
  const number = parts[0];

  let formatedNumber = number.substring(number.length - 3, number.length);
  let index = number.length - 6;
  while (index > -3) {
    formatedNumber = `${number.substring(index, index + 3)}${numberFormat.thousandSeparator}${formatedNumber}`;
    index -= 3;
  }

  if (parts[1]) {
    return `${isMinus ? "-" : ""}${formatedNumber}${numberFormat.decimalSeparator}${parts[1]}`;
  } else {
    return `${isMinus ? "-" : ""}${formatedNumber}`;
  }
}

export function localizedToString(value: string, numberFormat: NumberFormat): string {
  // can't use replaceAll due to browser compat
  const re = numberFormat.thousandSeparator === "" ? "" : new RegExp(`\\${numberFormat.thousandSeparator}`, "g");
  return value.replace(re, "").replace(numberFormat.decimalSeparator, ".");
}

const separators = {
  dot: ".",
  comma: ",",
  space: " ",
  nothing: "",
} as const;

const defaultFormat = {
  thousandSeparator: "comma",
  decimalSeparator: "dot",
};

export type SeparatorType = keyof typeof separators;
export type SeparatorSymbol = ValueOf<typeof separators>;

function getNumberFormat(row: QP.LanguageMapping | undefined): NumberFormat {
  const decimalSeparatorType = (row?.decimal_separator || defaultFormat.decimalSeparator) as SeparatorType;
  const thousandSeparatorType = (row?.thousand_separator || defaultFormat.thousandSeparator) as SeparatorType;

  const thousandSeparator = separators[thousandSeparatorType] as SeparatorSymbol;
  const decimalSeparator = separators[decimalSeparatorType] as SeparatorSymbol;

  return { thousandSeparator, decimalSeparator };
}
