import type { ComponentResult } from "shared-lib/search";
import type { SelectedVariant } from "./types";

export type Action =
  | SetSelectedPath
  | SetSelectedVariant
  | SaveCalculation
  | ClearSavedCalculations
  | RemoveCalculation
  | CalculationExists
  | SearchNotFound;

export interface SetSelectedPath {
  readonly type: "frico-heating-calculation-epim/SET_SELECTED_PATH";
  readonly payload: {
    readonly lvl1: string | undefined;
    readonly lvl2: string | undefined;
    readonly productId: string | undefined;
    readonly ecomProductId: string | undefined;
    readonly selectedVariant: SelectedVariant | undefined;
  };
}

export function setSelectedPath(
  lvl1: string | undefined,
  lvl2: string | undefined,
  productId: string | undefined,
  selectedVariant: SelectedVariant | undefined,
  ecomProductId: string | undefined
): SetSelectedPath {
  return {
    type: "frico-heating-calculation-epim/SET_SELECTED_PATH",
    payload: {
      lvl1: lvl1,
      lvl2: lvl2,
      productId: productId,
      ecomProductId: ecomProductId,
      selectedVariant: selectedVariant,
    },
  };
}

export interface SetSelectedVariant {
  readonly type: "frico-heating-calculation-epim/SET_SELECTED_VARIANT";
  readonly payload: SelectedVariant | undefined;
}

export function setSelectedVariant(selected: SelectedVariant | undefined): SetSelectedVariant {
  return {
    type: "frico-heating-calculation-epim/SET_SELECTED_VARIANT",
    payload: selected,
  };
}

export interface ClearSavedCalculations {
  readonly type: "frico-heating-calculation-epim/CLEAR_SAVED_CALCULATIONS";
}

export interface SearchNotFound {
  readonly type: "frico-heating-calculation-epim/SEARCH_NOT_FOUND";
}
export function searchNotFound(): SearchNotFound {
  return {
    type: "frico-heating-calculation-epim/SEARCH_NOT_FOUND",
  };
}

export function clearSavedCalculations(): ClearSavedCalculations {
  return {
    type: "frico-heating-calculation-epim/CLEAR_SAVED_CALCULATIONS",
  };
}

export interface SaveCalculation {
  readonly type: "frico-heating-calculation-epim/SAVE_CALCULATION";
  readonly payload: ComponentResult;
}

export function saveCalculation(result: ComponentResult): SaveCalculation {
  return {
    type: "frico-heating-calculation-epim/SAVE_CALCULATION",
    payload: result,
  };
}

export interface RemoveCalculation {
  readonly type: "frico-heating-calculation-epim/REMOVE_CALCULATION";
  readonly payload: string;
}

export function removeCalculation(id: string): RemoveCalculation {
  return {
    type: "frico-heating-calculation-epim/REMOVE_CALCULATION",
    payload: id,
  };
}

export interface CalculationExists {
  readonly type: "frico-heating-calculation-epim/CALCULATION_EXISTS";
}

export function calculationExists(): CalculationExists {
  return {
    type: "frico-heating-calculation-epim/CALCULATION_EXISTS",
  };
}
