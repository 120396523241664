import type * as QP from "shared-lib/query-product";
import * as Attributes from "../shared/attributes";

const otherEquipmentAttrs = [
  "hru-other-equipment-non-sealed-combustion-heat",
  "hru-other-equipment-simplified-installation",
  "PROP-AHU-other-equipment-non-sealed-combustion-heat", // TODO THEASE TWO "PROP" are new and the above ones are deleted in EPIM
  "PROP-AHU-other-equipment-simplified-installation",
];

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  return (
    Attributes.matchAttribute("PROP-ahu-inst-place-MULTI", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("PROP-AHU-NA-recovery-type-MULTI", searchAttributes, variantAttributes) &&
    matchOtherEquipment(searchAttributes, variantAttributes) &&
    Attributes.matchAttributeParts("certificate", searchAttributes, variantAttributes)
  );
}

export function matchOtherEquipment(
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  const searchOther = searchAttributes.filter((a) => otherEquipmentAttrs.includes(a.attribute));
  if (searchOther.length === 0) {
    return true;
  }
  return searchOther.some((sa) => variantAttributes.byAttribute[sa.attribute]?.find((a) => a === sa.value));
}
