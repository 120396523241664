import * as React from "react";
import * as ProductUtils from "shared-lib/product-utils";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { isUiFilterProperty } from "shared-lib/search";
import { Button, Spinner } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import type * as QP from "shared-lib/query-product";
import * as SC from "shared-lib/system-calculator";
import * as SearchClient from "client-lib/search";
import type { DispatchProp } from "client-lib/redux-integration";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import type { OwnProps, StateProps, Response } from "./types";

export type Props = OwnProps & StateProps & Response & DispatchProp<SearchClient.Action>;

export function ProgressSearchButtonContainerComponent(props: Props): React.ReactElement<Props> {
  const {
    source,
    ecomUrl,
    market,
    language,
    searchProductId,
    calcParams,
    accessories,
    searchState,
    property,
    translate,
    ct_DiaqTemplates,
    dispatch,
    resetCallBack,
    ct_SearchAttributes,
    ct_ResultItems,
    externalApiType,
  } = props;

  if (
    property === undefined ||
    ct_DiaqTemplates === undefined ||
    ct_SearchAttributes === undefined ||
    ct_ResultItems === undefined
  ) {
    return <Spinner />;
  }

  const uiFilterProperties = property.filter(isUiFilterProperty).map((p) => p.name);
  const variants = props.variants.map((v) => PropertyValueSet.removeProperties(uiFilterProperties, v));
  const variant = PropertyValueSet.setInteger("isSearch", 1, variants[0]);

  const template = ct_DiaqTemplates.find(
    (t) => t.type === "ResultItems" && ProductUtils.isFilterValid(variants, t.property_filter)
  );
  const resultItems = ct_ResultItems.filter((i) => template && i.template === template.template);
  const attributes = ct_SearchAttributes
    .filter((a) => ProductUtils.isFilterValid(variants, a.property_filter))
    .map((r) => ({
      collection: r.collection,
      attribute: r.attribute,
      value: r.value,
    }));
  const attributesMap = Attributes.createMap(variant, ct_SearchAttributes);
  const calcParamDefinitions = SC.getCalcParams(
    resultItems,
    attributesMap,
    variant,
    PropertyValueSet.merge(variant, calcParams)
  );
  const inputsValid = getInputsValid(property, calcParamDefinitions, variants, calcParams);
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row space-x-8">
        <Button
          disabled={!inputsValid || searchState.type === "Calculating"}
          clicked={() =>
            dispatch(
              SearchClient.startCalculation(source, {
                ecomUrl: ecomUrl,
                searchProductId: searchProductId,
                market: market,
                language: language,
                attributes: attributes,
                properties: variants,
                calcParams: PropertyValueSet.merge(variant, calcParams),
                accessories: accessories,
                externalApiType: externalApiType || "none",
                overallSortParams: {
                  sortPath: props.sortPath,
                  sortType: props.sortType,
                  descending: props.descending,
                },
                groupSortParams: props.groupSortParams,
              })
            )
          }
          iconLeft={searchState.type === "Calculating" ? "spinner-third" : "magnifying-glass"}
          spinnerIcon={searchState.type === "Calculating"}
          label={searchState.type === "Calculating" ? translate(Texts.calculating()) : translate(Texts.search())}
        />
        <Button buttonType="secondary-bg" clicked={() => resetCallBack()} label={translate(Texts.reset())} />
      </div>
    </div>
  );
}

function getInputsValid(
  property: QP.PropertyTable,
  calcParamDefinitions: ReadonlyArray<SC.InputParam>,
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  const withoutUiFilterProps = property.filter((p) => !isUiFilterProperty(p));
  return variants.some((properties) => {
    if (!ProductUtils.variantIsValid(withoutUiFilterProps, properties)) {
      return false;
    }
    const merged = PropertyValueSet.merge(properties, calcParams);
    for (const cp of calcParamDefinitions.filter(
      (d) => !d.visibilityFilter || PropertyFilter.isValid(merged, d.visibilityFilter)
    )) {
      const param = calcParams[cp.name];
      if (param !== undefined) {
        if (
          PropertyFilter.isValid(calcParams, cp.visibilityFilter || PropertyFilter.Empty) &&
          !PropertyFilter.isValid(calcParams, cp.validationFilter || PropertyFilter.Empty)
        ) {
          return false;
        }
      } else if (!PropertyFilter.isValid(properties, cp.validationFilter || PropertyFilter.Empty)) {
        return false;
      }
    }
    return true;
  });
}
