import * as R from "ramda";
import * as React from "react";
import type * as SC from "shared-lib/system-calculator";
import { OctaveBandsTable, OctaveBandsTableTd, OctaveBandsTableTh } from "client-lib/elements";
import { isDefined } from "shared-lib/utils";
import * as Texts from "shared-lib/language-texts";
import type * as UserSettings from "shared-lib/user-settings";
import { Amount } from "uom";
import { Units } from "uom-units";

export interface OctaveBandProps {
  readonly key: string;
  readonly label: Texts.PLangText;
  readonly unitName: string;
  readonly octaveBands: ReadonlyArray<SC.OctaveBands | SC.OctaveBands3rd>;
}

export function OctaveTable({
  translate,
  octaveBandsType,
  octaveBands,
  metaTexts,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly octaveBandsType: UserSettings.OctaveBandsType;
  readonly octaveBands: ReadonlyArray<OctaveBandProps>;
  readonly metaTexts: ReadonlyArray<string>;
}): JSX.Element | null {
  if (octaveBands.length === 0) {
    return null;
  }
  const numProducts = octaveBands[0].octaveBands.length;
  const singleProduct = numProducts === 1;
  return (
    <div className={`overflow-auto ${octaveBandsType === "Octave3rd" ? "sm-max:text-xs" : ""}`}>
      <OctaveBandsTable equalWidths={true}>
        {singleProduct ? (
          <colgroup>
            <col key={"single_label"} />
            <col key={"single_selector"} width={octaveBandsType === "Octave3rd" ? "6%" : "10%"} />
            {R.range(0, octaveBandsType === "Octave3rd" ? 24 : 8).map((idx) => (
              <col key={`single_band_${idx}`} width={octaveBandsType === "Octave3rd" ? "3%" : "8%"} />
            ))}
            <col key={`single_band_total`} width={octaveBandsType === "Octave3rd" ? "4%" : "10%"} />
          </colgroup>
        ) : (
          <colgroup>
            <col key={"label"} width={"10%"} />
            <col />
            {R.range(0, numProducts).map((pIdx) => (
              <React.Fragment key={pIdx}>
                {R.range(0, 8).map((idx) => (
                  <col key={`band_${idx}`} />
                ))}
                <col key={`single_band_total`} width={"10%"} />
                <col key={`band_empty`} />
              </React.Fragment>
            ))}
            <col key={"selector"} width={"6%"} />
          </colgroup>
        )}
        <thead>
          <tr>
            <OctaveBandsTableTh colSpan={singleProduct ? 1 : 2}></OctaveBandsTableTh>
            {singleProduct ? <OctaveBandsTableTh /> : null}
            {octaveBands[0].octaveBands.map((_, pIdx) => (
              <HeaderFreqBands
                key={pIdx}
                translate={translate}
                singleProduct={singleProduct}
                octaveBandsType={octaveBandsType}
              />
            ))}
            {!singleProduct ? <OctaveBandsTableTh /> : null}
          </tr>
        </thead>
        <tbody>
          {octaveBands.map(({ label, octaveBands: obs, unitName, key }) => (
            <OctaveBandsTableRow
              key={key}
              label={translate(label)}
              unitName={unitName}
              octaveBandsType={octaveBandsType}
              octaveBands={obs}
            />
          ))}
          <MetaRow octaveBandsType={octaveBandsType} metaTexts={metaTexts} numProducts={numProducts} />
        </tbody>
      </OctaveBandsTable>
    </div>
  );
}

function HeaderFreqBands({
  translate,
  singleProduct,
  octaveBandsType,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly singleProduct: boolean;
  readonly octaveBandsType: UserSettings.OctaveBandsType;
}): JSX.Element {
  const headings =
    octaveBandsType === "Octave3rd"
      ? [
          "50",
          "63",
          "80",
          "100",
          "125",
          "160",
          "200",
          "250",
          "315",
          "400",
          "500",
          "630",
          "800",
          "1k",
          "1.25k",
          "1.6k",
          "2k",
          "2.5k",
          "3.15k",
          "4k",
          "5k",
          "6.3k",
          "8k",
          "10k",
          translate(Texts.total()),
        ]
      : ["63", "125", "250", "500", "1k", "2k", "4k", "8k", translate(Texts.total())];

  const className = octaveBandsType === "Octave3rd" ? "text-right vertical-lr" : "text-center";
  const renderedHeadings = headings.map((text) => (
    <OctaveBandsTableTh key={text} className={className}>
      {text}
    </OctaveBandsTableTh>
  ));
  if (singleProduct) {
    return <>{renderedHeadings}</>;
  } else {
    return (
      <>
        {renderedHeadings} <OctaveBandsTableTh key="empty" />
      </>
    );
  }
}

function OctaveBandsTableRow({
  label,
  unitName,
  octaveBands,
  octaveBandsType,
}: {
  readonly label: string;
  readonly unitName: string;
  readonly octaveBandsType: UserSettings.OctaveBandsType;
  readonly octaveBands: ReadonlyArray<SC.OctaveBands | SC.OctaveBands3rd | undefined>;
}): JSX.Element | null {
  const obs = octaveBands.filter(isDefined);
  if (obs.length === 0) {
    return null;
  }
  return renderOctaveBandsTableRowValues(
    label,
    unitName,
    octaveBandsType,
    obs.map((ob) => {
      const cols = [];
      if (octaveBandsType === "Octave3rd" && ob.type === "Octave3rd") {
        cols.push(
          ...[
            ob.hz50,
            ob.hz63,
            ob.hz80,
            ob.hz100,
            ob.hz125,
            ob.hz160,
            ob.hz200,
            ob.hz250,
            ob.hz315,
            ob.hz400,
            ob.hz500,
            ob.hz630,
            ob.hz800,
            ob.hz1000,
            ob.hz1250,
            ob.hz1600,
            ob.hz2000,
            ob.hz2500,
            ob.hz3150,
            ob.hz4000,
            ob.hz5000,
            ob.hz6300,
            ob.hz8000,
            ob.hz10000,
          ]
        );
      } else if (octaveBandsType === "Octave" && ob.type === "Octave") {
        cols.push(...[ob.hz63, ob.hz125, ob.hz250, ob.hz500, ob.hz1000, ob.hz2000, ob.hz4000, ob.hz8000]);
      } else {
        cols.push(...R.repeat(undefined, octaveBandsType === "Octave3rd" ? 24 : 8));
      }
      cols.push(ob.total && Amount.valueAs(Units.DecibelLw, ob.total));
      return cols;
    })
  );
}

function renderOctaveBandsTableRowValues(
  label: string,
  unitName: string,
  octaveBandsType: UserSettings.OctaveBandsType,
  octaveBandValues: ReadonlyArray<ReadonlyArray<number | undefined>>
): JSX.Element {
  const singleProduct = octaveBandValues.length === 1;
  const filterTd = (
    <OctaveBandsTableTd key="filter_column" textAlign="center">
      {unitName}
    </OctaveBandsTableTd>
  );
  return (
    <tr key={label}>
      <OctaveBandsTableTd colSpan={singleProduct ? 1 : 2}>{label}</OctaveBandsTableTd>
      {singleProduct ? filterTd : null}
      {...R.unnest(
        octaveBandValues.map((values, valuesIndex) => [
          ...values.map((cellValue, valueIndex) => (
            <SoundCell key={`${valuesIndex}_${valueIndex}`} sound={cellValue} octaveBandsType={octaveBandsType} />
          )),
          !singleProduct ? <OctaveBandsTableTd key={valuesIndex} /> : null,
        ])
      )}
      {!singleProduct ? filterTd : null}
    </tr>
  );
}

function SoundCell({
  sound,
  octaveBandsType,
}: {
  readonly sound: number | undefined;
  readonly octaveBandsType: UserSettings.OctaveBandsType;
}): JSX.Element {
  return (
    <OctaveBandsTableTd
      className={`text-center ${octaveBandsType === "Octave3rd" ? "sm-max:sound-cell-extra-small" : ""}`}
    >
      {sound === undefined ? "-" : sound < 10 ? "<10" : sound.toFixed(0)}
    </OctaveBandsTableTd>
  );
}

function MetaRow({
  metaTexts,
  octaveBandsType,
  numProducts,
}: {
  readonly metaTexts: ReadonlyArray<string>;
  readonly octaveBandsType: UserSettings.OctaveBandsType;
  readonly numProducts: number;
}): JSX.Element | null {
  if (metaTexts.length === 0) {
    return null;
  }
  return (
    <>
      <tr key={"big_screen"} className="sm-max:hidden">
        <td colSpan={2}></td>
        <td colSpan={numProducts === 1 ? (octaveBandsType === "Octave3rd" ? 25 : 9) : 1 + 10 * numProducts}>
          <div className="flex flex-row justify-between">
            {metaTexts.map((text) => (
              <div key={text}>{text}</div>
            ))}
            <div key={`_padding`} />
          </div>
        </td>
      </tr>
      <tr className="hidden sm-max:table-row">
        <td colSpan={numProducts === 1 ? (octaveBandsType === "Octave3rd" ? 27 : 11) : 1 + 10 * numProducts}>
          <div className="flex flex-col space-y-4">
            {metaTexts.map((text) => (
              <div key={text}>{text}</div>
            ))}
          </div>
        </td>
      </tr>
    </>
  );
}
