/* eslint-disable functional/no-this-expression */
import * as R from "ramda";
import * as React from "react";
import * as ProductUtils from "shared-lib/product-utils";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import type { ComboboxOption } from "client-lib/elements";
import {
  withTw,
  Checkbox,
  Combobox,
  Alert,
  FricoIcon,
  Group,
  HToolbar,
  SelectButton,
  DropdownButton,
} from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as UserSettingsClient from "client-lib/user-settings";
import * as Search from "client-lib/search";
import { createSelector } from "reselect";
import * as QP from "shared-lib/query-product";
import type * as SC from "shared-lib/system-calculator";
import * as SearchShared from "shared-lib/search";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { CustomUnitsLookup } from "shared-lib/uom";
import { clientConfig } from "config";
import { AmcaVersion } from "containers/amca-version";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import { getFieldUnitsFromCookie } from "client-lib/user-settings/cookie";
import { setFieldUnitConfig } from "client-lib/user-settings";
import { getAttributesFromQueryParams } from "client-lib/ecom-query-parameters";
import { CalculatorContainer } from "../calculator";
import { SearchResultTableContainer } from "../search-result-table";
import type { SortProps } from "../search-result-table/types";
import { ProgressSearchButtonContainer } from "../search-progress-button";
import type { Props } from "./types";
import { CalcParamsSelectorContainer } from "../calc-params-selector";
import { SearchPropertiesSelectorContainer } from "../search-properties-selector";
import * as Actions from "./actions";
import { FricoFooter } from "./components/frico-footer";
import type * as SpecificationText from "../frico-specification-text-epim";

const Label = withTw("span", "whitespace-nowrap");
const Wrapper = withTw("span", "flex flex-row space-x-16 items-center");

export function SearchProductsContainerComponent(props: Props): React.ReactElement<Props> {
  const {
    market,
    language,
    translateTables,
    ct_DiaqTemplates,
    ct_CalcParamDefault,
    ct_ResultItems,
    ct_SearchViews,
    ct_SearchColumns,
    ct_SearchSortings,
    ct_EcomCategoryToProperty,
    searchState,
    property,
    state,
    ct_AttributeTemplateMapping,
    dispatch,
    isFricoWebsite,
  } = props;
  const categoryQueryParams = getAttributesFromQueryParams(window.location.search);
  const categoryId = categoryQueryParams.find((cat) => cat.attribute === "ecom-category-id-MULTI");

  const defaultSelectionEcom =
    categoryId && state.useEcomQueryParam
      ? ct_EcomCategoryToProperty.find((c) => c.ecom_category_id === categoryId.value)
      : undefined;

  const translate = Texts.translateFunctionSelector(translateTables, language);
  const selections = ProductUtils.getFullSelections(
    property,
    ct_CalcParamDefault,
    market,
    state.selections || {},
    defaultSelectionEcom?.variant
  );
  const variants = ProductUtils.getVariants(selections);
  const variant = PropertyValueSet.setInteger("isSearch", 1, variants[0]);
  const attributes = Attributes.createMap(PropertyValueSet.Empty, []);
  const calcParams = ProductUtils.getFullCalcParams(
    ct_DiaqTemplates,
    ct_ResultItems,
    ct_CalcParamDefault,
    state.calcParams,
    variant,
    market,
    attributes,
    ct_AttributeTemplateMapping
  );

  const searchVariant =
    searchState.type === "Idle" && searchState.searchVariants.length > 0 ? searchState.searchVariants[0] : undefined;
  const resultTabletemplate = searchVariant
    ? ct_DiaqTemplates?.find(
        (t) => t.type === "ResultItems" && PropertyFilter.isValid(searchVariant, t.property_filter)
      )
    : undefined;
  const resultTablemetaTemplate =
    resultTabletemplate && ct_ResultItems.find((r) => r.template === resultTabletemplate.template);
  const searchSortings = resultTablemetaTemplate
    ? ct_SearchSortings?.filter((r) => r.result_type === resultTablemetaTemplate.type) || []
    : [];

  const searchColumnsFiltered =
    resultTablemetaTemplate && searchVariant
      ? ct_SearchColumns?.filter(
          (r) =>
            r.result_type === resultTablemetaTemplate.type &&
            (!r.property_filter || (r.property_filter && PropertyFilter.isValid(searchVariant, r.property_filter)))
        ) || []
      : [];
  const searchColumns = React.useMemo(() => searchColumnsFiltered, [JSON.stringify(searchColumnsFiltered)]);

  const searchViewsFiltered = resultTablemetaTemplate
    ? ct_SearchViews?.filter((r) => r.result_type === resultTablemetaTemplate.type) || []
    : [];
  const searchViews = React.useMemo(() => searchViewsFiltered, [JSON.stringify(searchViewsFiltered)]);

  const heatingTypeExtraText =
    (props.isFricoWebsite &&
      PropertyValueSet.get("heatingMethod", variant)?.value === 0 &&
      PropertyValueSet.get("heatingType", variant)?.value === 1 &&
      translate(Texts.heating_type_extra_text())) ||
    "";

  React.useEffect(() => {
    const fieldUnits = getFieldUnitsFromCookie();
    if (fieldUnits) {
      dispatch(setFieldUnitConfig(fieldUnits));
    }
  }, []);

  React.useEffect(() => {
    if (props.searchProductId !== state.searchProductId) {
      dispatch(Actions.reset());
    }
  }, [props.searchProductId, state.searchProductId]);

  const sortProps = React.useMemo(
    (): SortProps => ({
      groupBy: state.groupBy,
      groupsOpen: state.groupsOpen,
      sortType: state.sortType,
      sortPath: state.sortPath,
      columnGroup: state.columnGroup,
      descending: state.descending,
      numResults: state.numResults,
      groupSortParams: state.groupSortParams,
      setGroupOpen: (group, open) => dispatch(Actions.setGroupOpen(group, open)),
      setSorting: (sortType, sortPath, descending) => dispatch(Actions.setSorting(sortType, sortPath, descending)),
      setGroupSorting: (groupSortParams) => dispatch(Actions.setGroupSorting(groupSortParams)),
      setNumResults: (numResults) => dispatch(Actions.setNumResults(numResults)),
    }),
    [
      state.groupBy,
      state.groupsOpen,
      state.sortType,
      state.sortPath,
      state.columnGroup,
      state.descending,
      state.numResults,
      state.groupSortParams,
    ]
  );

  const crmSavedResults = React.useMemo(() => props.state.crmSavedResults, [props.state.crmSavedResults.length]);

  React.useEffect(() => {
    if (!props.isFricoWebsite) {
      return () => undefined;
    }
    const oldTitle = document.title;
    document.title = translate(Texts.product_selection_guide_title());
    return () => {
      document.title = oldTitle;
    };
  }, []);

  const uiFilterProperties = props.property.filter(SearchShared.isUiFilterProperty).map((p) => p.name);

  return (
    <div>
      {props.isFricoWebsite && (
        <div className="hidden print:block mb-24">
          <img src="/images/frico_logo.svg" className="h-64" />
        </div>
      )}
      <AmcaVersion market={market} productKey={props.searchProductKey} />
      <div className={clientConfig.addOuterPadding ? "px-400 print:px-0" : ""}>
        <SearchProductSelector {...{ ...props, translate }} />
        <PropertiesSelector
          translate={translate}
          selections={selections}
          hideProperties={uiFilterProperties}
          searchProductId={props.searchProductId}
          market={props.market}
          language={props.language}
          dispatch={props.dispatch}
          isFricoWebsite={props.isFricoWebsite}
        />
        {heatingTypeExtraText && <div style={{ padding: "5px 20px", fontSize: "10pt" }}>{heatingTypeExtraText}</div>}
        {(props.searchProductKey === "GANYMED_SEARCH_FANTECH_HRU" ||
          props.searchProductKey === "GANYMED_SEARCH_FANTECH_HRU_FRESH_AIR") && (
          <AirflowCalculator
            translate={translate}
            calcParams={calcParams}
            searchProductId={props.searchProductId}
            market={props.market}
            language={props.language}
            dispatch={props.dispatch}
          />
        )}
        <CalcParamsSelector
          translate={translate}
          variant={variant}
          calcParams={calcParams}
          attributes={attributes}
          searchProductId={props.searchProductId}
          market={props.market}
          language={props.language}
          dispatch={props.dispatch}
        />
        <AccessoriesSelector
          variants={variants}
          translate={translate}
          ct_DiaqTemplates={props.ct_DiaqTemplates}
          ct_SearchAccessories={props.ct_SearchAccessories}
          image={props.image}
          images={props.images}
          state={props.state}
          dispatch={props.dispatch}
          market={props.market}
          ct_ClosedGroups={props.ct_ClosedGroups}
          ct_MarketSettings={props.ct_MarketSettings}
          ct_ResultItems={props.ct_ResultItems}
          searchProductId={props.searchProductId}
        />
      </div>
      <ResultToolbar
        resetCallBack={resetCallbackSelector(props)}
        accessories={getAccessories(props)}
        searchSortings={searchSortings}
        searchViews={searchViews}
        searchColumns={searchColumns}
        variants={variants}
        calcParams={calcParams}
        translate={translate}
        dispatch={props.dispatch}
        ecomUrl={props.ecomUrl}
        externalConfig={props.externalConfig}
        market={props.market}
        language={props.language}
        searchProductId={props.searchProductId}
        ct_SearchAccessories={props.ct_SearchAccessories}
        searchState={props.searchState}
        showPrice={props.showPrice}
        showDiagrams={props.showDiagrams}
        groupBy={props.state.groupBy}
        sortType={props.state.sortType}
        sortPath={props.state.sortPath}
        columnGroup={props.state.columnGroup}
        descending={props.state.descending}
        isFricoWebsite={props.isFricoWebsite}
        property={props.property}
        selections={selections}
        uiFilterNoResults={props.uiFilterNoResults}
      />
      {/*
        NOTE: If adding any props make sure they are memoized so the result table isn't rerendered each time the input changes (check the React profiler)
      */}
      <div>
        <ResultTable
          resetCallBack={resetCallbackSelector(props)}
          accessories={getAccessories(props)}
          searchViews={searchViews}
          searchColumns={searchColumns}
          translate={translate}
          ecomUrl={props.ecomUrl}
          externalConfig={props.externalConfig}
          market={props.market}
          language={props.language}
          navigateToItemNo={props.navigateToItemNo}
          shopUrl={props.shopUrl}
          searchProductId={props.searchProductId}
          ct_SearchAccessories={props.ct_SearchAccessories}
          isFricoWebsite={props.isFricoWebsite}
          specificationDataUrl={props.specificationDataUrl}
          sortProps={sortProps}
          crmSavedResults={crmSavedResults}
          selections={selections}
          fricoAddedSearchResultIds={props.fricoAddedSearchResultIds}
        />
        {isFricoWebsite && (
          <FricoFooter
            translate={translate}
            fricoSpecificationTextUrl={props.fricoSpecificationTextUrl}
            navigateToSpecificationText={props.navigateToSpecificationText}
            dispatch={dispatch}
            calculationState={props.searchState}
            itemsAreAdded={Object.keys(props.fricoAddedSearchResultIds).length > 0}
          />
        )}
      </div>
    </div>
  );
}

function SearchProductSelector({
  translate,
  ct_SearchMetaProducts,
  navigateToSearchProduct,
  searchProductKey,
}: { readonly translate: Texts.TranslateFunction } & Pick<
  Props,
  "ct_SearchMetaProducts" | "navigateToSearchProduct" | "searchProductKey"
>): JSX.Element {
  if (!navigateToSearchProduct) {
    return <span />;
  }

  return (
    <HToolbar>
      <DropdownButton
        label={translate(Texts.select_product_category())}
        items={ct_SearchMetaProducts.map((p) => ({
          type: "item",
          value: p.product,
          isActive: p.product === searchProductKey,
          label: translate(Texts.product_name(p.product)),
          onClick: () => navigateToSearchProduct(p.product),
        }))}
      />
    </HToolbar>
  );
}

const resetCallbackSelector = createSelector(
  (props: Props) => props.dispatch,
  (dispatch) => () => {
    dispatch(Search.reset());
    dispatch(Actions.reset());
    dispatch(UserSettingsClient.reset());
  }
);

function PropertiesSelector({
  translate,
  selections,
  hideProperties,
  searchProductId,
  market,
  language,
  dispatch,
  isFricoWebsite,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly selections: ProductUtils.Selections;
  readonly hideProperties: ReadonlyArray<string>;
} & Pick<Props, "searchProductId" | "market" | "language" | "dispatch" | "isFricoWebsite">): React.ReactElement {
  return (
    <SearchPropertiesSelectorContainer
      translate={translate}
      market={market}
      language={language}
      productId={searchProductId}
      hideFirstGroupTitle={true}
      comboBoxImageCSSFilter={
        !isFricoWebsite && searchProductId === QP.fricoProductId ? "hue-rotate(190deg)" : undefined
      }
      hideProperties={[...hideProperties, "market"]}
      selections={selections}
      selectionsChanged={(s) => dispatch(Actions.setSelections(s, searchProductId))}
    />
  );
}

function CalcParamsSelector({
  translate,
  variant,
  calcParams,
  attributes,
  searchProductId,
  market,
  language,
  dispatch,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly attributes: Attributes.Attributes;
} & Pick<Props, "searchProductId" | "market" | "language" | "dispatch">): React.ReactElement {
  return (
    <CalcParamsSelectorContainer
      hideErrors={false}
      translate={translate}
      market={market}
      language={language}
      productId={searchProductId}
      variant={variant}
      calcParams={calcParams}
      calcParamsChanged={(p) => dispatch(Actions.setCalcParams(p, searchProductId))}
      attributes={attributes}
      hideInvalidValues={false}
    />
  );
}

const ToolbarContainer = withTw(
  "div",
  "flex flex-col space-y-16 bg-neutral-50",
  clientConfig.addOuterPadding ? "px-400" : "px-16"
);

function ResultToolbar({
  resetCallBack,
  accessories,
  searchSortings,
  searchViews,
  searchColumns,
  variants,
  calcParams,
  translate,
  dispatch,
  ecomUrl,
  externalConfig,
  market,
  language,
  searchProductId,
  ct_SearchAccessories,
  searchState,
  showPrice,
  showDiagrams,
  groupBy,
  sortType,
  sortPath,
  columnGroup,
  descending,
  selections,
  isFricoWebsite,
  property,
  uiFilterNoResults,
}: {
  readonly resetCallBack: () => void;
  readonly accessories: ReadonlyArray<QP.AccessoryType>;
  readonly searchSortings: ReadonlyArray<QP.SearchSorting>;
  readonly searchViews: ReadonlyArray<QP.SearchView>;
  readonly searchColumns: ReadonlyArray<QP.SearchColumn>;
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly translate: Texts.TranslateFunction;
  readonly showPrice: boolean;
  readonly showDiagrams: boolean;
  readonly groupBy: SearchShared.GroupBy;
  readonly sortType: SC.ResultItemType | undefined;
  readonly sortPath: string;
  readonly columnGroup: string;
  readonly descending: boolean;
  readonly selections: ProductUtils.Selections;
  readonly isFricoWebsite: boolean;
  readonly property: QP.PropertyTable;
  readonly uiFilterNoResults: boolean;
} & Pick<
  Props,
  | "dispatch"
  | "ecomUrl"
  | "externalConfig"
  | "market"
  | "language"
  | "searchProductId"
  | "ct_SearchAccessories"
  | "searchState"
>): React.ReactElement | null {
  if (ct_SearchAccessories === undefined) {
    return null;
  }
  const uiFilterProperties = property.filter(SearchShared.isUiFilterProperty).map((p) => p.name);
  const notUiFilterProperties = property
    .filter((p) => !uiFilterProperties.some((p2) => p.name === p2))
    .map((p) => p.name);
  return (
    <>
      <ToolbarContainer className={`mt-24 ${uiFilterProperties.length > 0 ? "py-24" : "pt-24"}`}>
        <div className="flex flex-row justify-between items-center">
          <div className="print:hidden pr-16">
            <ProgressSearchButtonContainer
              ecomUrl={ecomUrl}
              market={market}
              language={language}
              translate={translate}
              searchProductId={searchProductId}
              variants={variants}
              calcParams={calcParams}
              resetCallBack={resetCallBack}
              accessories={accessories}
              externalApiType={externalConfig?.type}
            />
          </div>
          <div>
            {searchState.type === "Idle" && searchState.noSearchResult ? (
              <div key="result">
                <Alert horizontalAlign={true} type="warning">
                  {translate(Texts.no_result_found())}
                </Alert>
              </div>
            ) : searchState.type === "Idle" && !searchState.noSearchResult ? (
              <div key="result">{translate(Texts.found_products(searchState.results.length))}</div>
            ) : undefined}
          </div>
        </div>
      </ToolbarContainer>
      {uiFilterProperties.length > 0 && (
        <div className={clientConfig.addOuterPadding ? "px-400" : ""}>
          <PropertiesSelector
            translate={translate}
            selections={selections}
            hideProperties={notUiFilterProperties}
            searchProductId={searchProductId}
            market={market}
            language={language}
            dispatch={dispatch}
            isFricoWebsite={isFricoWebsite}
          />
          {uiFilterNoResults && (
            <div className="flex flex-row justify-end mt-16">
              <Alert type="warning">{translate(Texts.no_result_found())}</Alert>
            </div>
          )}
        </div>
      )}
      <ToolbarContainer className={`pb-24 ${uiFilterProperties.length > 0 ? "mt-24 pt-16" : "pt-16"}`}>
        <div className="flex flex-row sm-max:flex-col space-x-16 sm-max:space-x-0">
          <Wrapper>
            <SortByCombobox
              searchSortings={searchSortings}
              translate={translate}
              dispatch={dispatch}
              showPrice={showPrice}
              sortType={sortType}
              sortPath={sortPath}
              descending={descending}
            />
            <ColumnGroupCombobox
              searchColumns={searchColumns}
              translate={translate}
              columnGroup={columnGroup}
              dispatch={dispatch}
            />
          </Wrapper>
          <Wrapper>
            <GroupByCheckbox translate={translate} groupBy={groupBy} dispatch={dispatch} />
            <ShowDiagramsCheckbox
              searchViews={searchViews}
              translate={translate}
              dispatch={dispatch}
              showDiagrams={showDiagrams}
            />
          </Wrapper>
        </div>
        {searchProductId === QP.fricoProductId ? (
          <div className="flex flex-row space-x-24">
            <span>{translate(Texts.air_curtain_capacity())}</span>
            <div className="flex flex-row space-x-16">
              <FricoIcon icon="high" label={translate(Texts.high())} />
              <FricoIcon icon="ok" label={translate(Texts.ok())} />
              <FricoIcon icon="low" label={translate(Texts.low())} />
              <FricoIcon className="pl-32" icon="recommended" label={translate(Texts.recommended_coil())} />
            </div>
          </div>
        ) : null}
      </ToolbarContainer>
    </>
  );
}

function ResultTable({
  crmSavedResults,
  resetCallBack,
  accessories,
  searchViews,
  searchColumns,
  translate,
  ecomUrl,
  externalConfig,
  market,
  language,
  navigateToItemNo,
  shopUrl,
  searchProductId,
  ct_SearchAccessories,
  isFricoWebsite,
  specificationDataUrl,
  sortProps,
  selections,
  fricoAddedSearchResultIds,
}: {
  readonly crmSavedResults: ReadonlyArray<string>;
  readonly resetCallBack: () => void;
  readonly accessories: ReadonlyArray<QP.AccessoryType>;
  readonly searchViews: ReadonlyArray<QP.SearchView>;
  readonly searchColumns: ReadonlyArray<QP.SearchColumn>;
  readonly translate: Texts.TranslateFunction;
  readonly sortProps: SortProps;
  readonly selections: ProductUtils.Selections;
  readonly fricoAddedSearchResultIds: SpecificationText.ResultIdToM3ItemNo;
} & Pick<
  Props,
  | "ecomUrl"
  | "externalConfig"
  | "market"
  | "language"
  | "navigateToItemNo"
  | "shopUrl"
  | "searchProductId"
  | "ct_SearchAccessories"
  | "isFricoWebsite"
  | "specificationDataUrl"
>): React.ReactElement | null {
  if (ct_SearchAccessories === undefined) {
    return null;
  }
  return (
    <SearchResultTableContainer
      ecomUrl={ecomUrl}
      externalConfig={externalConfig}
      specificationDataUrl={specificationDataUrl}
      market={market}
      language={language}
      translate={translate}
      searchProductId={searchProductId}
      navigateToItemNo={navigateToItemNo}
      shopUrl={shopUrl}
      isFricoWebsite={isFricoWebsite}
      resetCallBack={resetCallBack}
      accessories={accessories}
      searchViews={searchViews}
      searchColumns={searchColumns}
      sortProps={sortProps}
      crmSavedResults={crmSavedResults}
      selections={selections}
      fricoAddedSearchResultIds={fricoAddedSearchResultIds}
    />
  );
}

const getAccessories = createSelector(
  (p: Props) => p.ct_SearchAccessories,
  (p: Props) => p.state.accessories,
  (searchAccessories, accessories) =>
    searchAccessories.map((sa) => sa.accessory_type).filter((sa) => accessories.includes(sa))
);

function AirflowCalculator({
  translate,
  calcParams,
  searchProductId,
  market,
  language,
  dispatch,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
} & Pick<Props, "searchProductId" | "market" | "language" | "dispatch">): JSX.Element {
  const params: Array<SC.InputParam> = [
    {
      type: "Amount",
      group: "ventilationCalculator",
      name: "numBedrooms",
      quantity: "Dimensionless",
      defaultValue: Amount.create(1, Units.One),
      fieldName: "numBedrooms",
      validationFilter: PropertyFilter.fromStringOrEmpty("numBedrooms>0:One", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "ventilationCalculator",
      name: "floorArea",
      quantity: "Area",
      defaultValue: Amount.create(50, Units.SquareMeter),
      fieldName: "roomArea",
      validationFilter: PropertyFilter.fromStringOrEmpty("floorArea>0:SquareMeter", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "ventilationCalculator",
      name: "previewVentilationCalculator",
      quantity: "VolumeFlow",
      defaultValue: Amount.create(0, Units.CubicMeterPerHour),
      fieldName: "airFlow",
      validationFilter: PropertyFilter.Empty,
    } as SC.InputParam,
  ];

  return (
    <CalculatorContainer
      market={market}
      language={language}
      productId={searchProductId}
      translate={translate}
      params={params}
      paramValues={calcParams}
      paramsChanged={(p) => dispatch(Actions.setCalcParams(p, searchProductId))}
      calculate={calculate}
      hideErrors={false}
      previewParam={"previewVentilationCalculator"}
      resultParams={["supplyAirFlow", "extractAirFlow"]}
      roundUpResult={true}
    />
  );
}

function calculate(pvs: PropertyValueSet.PropertyValueSet): Amount.Amount<Quantity.VolumeFlow> {
  const numRoomsAmount = PropertyValueSet.getAmount("numBedrooms", pvs);
  const floorAreaAmount = PropertyValueSet.getAmount("floorArea", pvs);

  const numRooms = Amount.valueAs(Units.One, numRoomsAmount as Amount.Amount<Quantity.Dimensionless>);
  const floorArea = Amount.valueAs(Units.SquareFeet, floorAreaAmount as Amount.Amount<Quantity.Area>);

  // ASHRAE 62.2 to calculate minimum ventilation rate
  const neededAirflow = floorArea / 100 + (numRooms + 1) * 7.5;
  return Amount.create(neededAirflow, Units.CubicFeetPerMinute);
}

function AccessoriesSelector({
  variants,
  translate,
  ct_DiaqTemplates,
  ct_SearchAccessories,
  image,
  images,
  state,
  dispatch,
  market,
  ct_ClosedGroups,
  ct_MarketSettings,
  ct_ResultItems,
  searchProductId,
}: {
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly translate: Texts.TranslateFunction;
} & Pick<
  Props,
  | "ct_DiaqTemplates"
  | "ct_SearchAccessories"
  | "image"
  | "images"
  | "state"
  | "dispatch"
  | "market"
  | "ct_ClosedGroups"
  | "ct_MarketSettings"
  | "ct_ResultItems"
  | "searchProductId"
>): React.ReactElement | null {
  if (
    !ct_SearchAccessories ||
    !ct_MarketSettings ||
    !ct_DiaqTemplates ||
    !ct_ResultItems ||
    ct_ClosedGroups === undefined ||
    image === undefined ||
    images === undefined
  ) {
    return null;
  }
  const template = ct_DiaqTemplates.find(
    (t) => t.type === "ResultItems" && PropertyFilter.isValid(variants[0], t.property_filter)
  );
  const resultItems = ct_ResultItems.filter((i) => template && i.template === template.template);
  const setting = ct_MarketSettings?.find((s) => s.market === market && s.setting === "EnableAccessories");

  if (!setting || !setting.value.split(",").find((v) => !!resultItems.find((r) => r.type === v))) {
    return null;
  }

  if (ct_SearchAccessories.length === 0) {
    return null;
  }
  return (
    <Group header={translate(Texts.accessories())} onToggleClosed={() => undefined}>
      <HToolbar>
        {ct_SearchAccessories.map((a) => {
          const selected = !!state.accessories.find((sa) => sa === a.accessory_type);
          const icon = image.find((i) => i.name === a.accessory_type);
          const iconUrl = icon ? images[icon.image] : undefined;
          return (
            <SelectButton
              key={a.accessory_type}
              label={translate(Texts.createText(a.accessory_type))}
              disabled={variants.every((v) => !PropertyFilter.isValid(v, a.property_filter))}
              selected={selected}
              onClick={(c) => {
                const newAccessories = c
                  ? R.append(a.accessory_type, state.accessories)
                  : state.accessories.filter((sa) => sa !== a.accessory_type);
                dispatch(Actions.setAccessories(newAccessories, searchProductId));
              }}
              imageUrl={iconUrl}
            />
          );
        })}
      </HToolbar>
    </Group>
  );
}

function ShowDiagramsCheckbox({
  searchViews,
  translate,
  dispatch,
  showDiagrams,
}: {
  readonly searchViews: ReadonlyArray<QP.SearchView>;
  readonly translate: Texts.TranslateFunction;
} & Pick<Props, "dispatch" | "showDiagrams">): React.ReactElement {
  if (searchViews.length === 0) {
    return <span />;
  }

  return (
    <Wrapper>
      <Checkbox
        checked={showDiagrams}
        checkedChanged={() => dispatch(UserSettingsClient.setShowDiagrams(!showDiagrams))}
        label={translate(Texts.show_diagrams())}
      />
    </Wrapper>
  );
}

function GroupByCheckbox({
  translate,
  groupBy,
  dispatch,
}: { readonly translate: Texts.TranslateFunction; readonly groupBy: SearchShared.GroupBy } & Pick<
  Props,
  "dispatch"
>): React.ReactElement {
  return (
    <Wrapper>
      <Checkbox
        label={translate(Texts.groupByProduct())}
        checked={groupBy === "Product"}
        checkedChanged={(checked) =>
          checked ? dispatch(Actions.setGroupBy("Product")) : dispatch(Actions.setGroupBy("None"))
        }
      />
    </Wrapper>
  );
}

function SortByCombobox({
  searchSortings,
  translate,
  dispatch,
  showPrice,
  sortType,
  sortPath,
  descending,
}: {
  readonly searchSortings: ReadonlyArray<QP.SearchSorting>;
  readonly translate: Texts.TranslateFunction;
  readonly sortType: SC.ResultItemType | undefined;
  readonly sortPath: string;
  readonly descending: boolean;
} & Pick<Props, "dispatch" | "showPrice">): React.ReactElement {
  const withPrice: Array<ComboboxOption> = showPrice
    ? [
        {
          value: "/price/Ascending",
          label: translate(Texts.price()) + " (" + translate(Texts.ascending()) + ")",
        },
        {
          value: "/price/Descending",
          label: translate(Texts.price()) + " (" + translate(Texts.descending()) + ")",
        },
      ]
    : [];

  const sortOptions: Array<ComboboxOption> = [
    {
      value: "/sort_no/Ascending",
      label: translate(Texts.bestFit()),
    },
    ...withPrice,
    ...searchSortings.map((s) => ({
      value: s.result_type + "/" + s.result_path + "/" + s.direction,
      label:
        translate(Texts.createText(R.last<string, Array<string>>(s.result_path.split(".")) || "")) +
        " (" +
        translate(Texts.createText(s.direction)) +
        ")",
    })),
  ];
  const selectedSorting = (sortType || "") + "/" + sortPath + "/" + (descending ? "Descending" : "Ascending");
  if (!sortOptions.find((o) => o.value === selectedSorting)) {
    sortOptions.push({
      value: selectedSorting,
      label: translate(Texts.other()),
    });
  }

  return (
    <Wrapper>
      <Label>{translate(Texts.sortBy())}</Label>
      <Combobox
        value={selectedSorting}
        onChange={(s) => {
          const parts = s.split("/");
          dispatch(Actions.setSorting(parts[0], parts[1], parts[2] === "Descending"));
        }}
        options={sortOptions}
      />
    </Wrapper>
  );
}

function ColumnGroupCombobox({
  searchColumns,
  translate,
  columnGroup,
  dispatch,
}: {
  readonly searchColumns: ReadonlyArray<QP.SearchColumn>;
  readonly translate: Texts.TranslateFunction;
  readonly columnGroup: string;
} & Pick<Props, "dispatch">): React.ReactElement {
  const groupOptions: Array<ComboboxOption> = R.uniq(searchColumns.map((c) => c.group)).map((g) => ({
    value: g,
    label: translate(Texts.createText(g)),
  }));
  if (groupOptions.length <= 1) {
    return <span />;
  }
  const selectedColumnGroup = groupOptions.find((o) => o.value === columnGroup) ? columnGroup : groupOptions[0].value;
  return (
    <Wrapper>
      <Label>{translate(Texts.columns())}</Label>
      <Combobox
        value={selectedColumnGroup}
        onChange={(s) => dispatch(Actions.setColumnGroup(s))}
        options={groupOptions}
      />
    </Wrapper>
  );
}
