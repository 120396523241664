import type * as UserSettingsShared from "shared-lib/user-settings";
import type { FormatNumberFunction } from "shared-lib/utils/number-format";
import { roundTo } from "shared-lib/utils";
import * as PC from "shared-lib/product-codes";
import * as Texts from "shared-lib/language-texts";
import * as C from "shared-lib/calculation";
import type * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { calcOctaveBandsFrom3rds } from "shared-lib/system-calculator/shared/fan-sound";
import { itemNumberToServiceUrl } from "shared-lib/image-service";
import type { AnyQuantity } from "shared-lib/uom";
import { Amount, UnitFormat } from "uom";
import { Units, UnitsFormat } from "uom-units";
import type { Response, OwnProps, SpecifierPayload, SpecifierSound } from "./types";

type Props = {
  readonly ownProps: OwnProps;
  readonly getUnit: UserSettingsShared.GetUnitFunction;
  readonly getExtraText: UserSettingsShared.GetExtraTextFunction;
  readonly getDecimals: UserSettingsShared.GetDecimalsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly hideErrors: boolean;
  readonly response: Response;
  readonly translate: Texts.TranslateFunction;
  readonly fullConfig: C.ItemConfig;
  readonly attributes: Attributes.Attributes;
  readonly productId: string;
};

export async function createSpecifierPayload(props: Props): Promise<SpecifierPayload> {
  const { translate, response, fullConfig, productId, ownProps } = props;

  const { variant, m3ItemNo, imageServiceUrl, externalConfig } = ownProps;
  const { metaTables, accessoryTables } = response;

  const results = (
    await C.calculateSystem(
      { productId: productId, config: fullConfig, variantId: variant },
      response.calculationResponse
    )
  )?.result;
  if (!results) {
    throw new Error("Not loaded yet");
  }

  const accessories = fullConfig.accessories.map((acc) => {
    const code = PC.getProductCodes(accessoryTables[acc.productId], acc.properties);

    return {
      ProductCode: translate(Texts.item_name(acc.id, acc.properties)),
      PartNumber: code.itemNo,
      ProductDescription: translate(Texts.product_description(acc.id)),
      Qty: 1,
      // DiscountPercentage: 10,
      // SaleAmount: 1036,
    };
  });

  const productName = translate(Texts.item_name(productId, fullConfig.properties));
  const productDescription = translate(Texts.product_description(productId));

  const payload: SpecifierPayload = {
    payload: {
      Projects__Tbl: [
        {
          ProjectNo: externalConfig?.type === "specifier" ? externalConfig.projectId : "",
          Products__Tbl: {
            ApplicationInterfaceNo: 2,
            ProductDetailsNo: null,
            ProductCode: m3ItemNo,
            ProductDescription: productName,
            RangeDescription: productDescription,
            ProductImageJpgURL: itemNumberToServiceUrl(imageServiceUrl, m3ItemNo, undefined, 225, 225, "jpeg"),
            ProductType: "External",
            Qty: 1,

            Properties__Tbl: [
              {
                PropertyName: "configuratorId", // Needed for the rest of properties to show
                PropertyType: "string",
                PropertyValue: "Ganymed",
              },
            ],

            Ancillaries__Tbl: accessories,
          },
        },
      ],
    },
  };

  for (const row of metaTables.ct_SpecifierResultMappingTable) {
    const calculator = row.result_value_path.split(".")[0];
    if (!results[productId][calculator]) {
      continue;
    }
    const resultValue = getFieldPath(results[productId], row.result_value_path);
    switch (row.type) {
      case "value": {
        const { value } = amountToValue(row.field_name, resultValue, props);
        setFieldPath(payload, row.specifier_path, value);
        break;
      }
      case "unit": {
        const { unit } = amountToValue(row.field_name, resultValue, props);

        setFieldPath(payload, row.specifier_path, unit);
        break;
      }
      case "property_value": {
        const { value, unit } = amountToValue(row.field_name, resultValue, props);

        const property = {
          PropertyName: translate(Texts.createText(row.label)),
          PropertyType: "string",
          PropertyValue: `${value} ${unit}`.trim(),
        };
        const propertyIndex = payload.payload.Projects__Tbl[0].Products__Tbl.Properties__Tbl.length;
        setFieldPath(payload, row.specifier_path + `[${propertyIndex}]`, property);
        break;
      }
      case "sound_table": {
        const bands = calcOctaveBandsFrom3rds(resultValue);

        if (!bands) {
          continue;
        }

        if (
          !bands.hz63 ||
          !bands.hz125 ||
          !bands.hz250 ||
          !bands.hz500 ||
          !bands.hz1000 ||
          !bands.hz2000 ||
          !bands.hz4000 ||
          !bands.hz8000 ||
          !bands.total
        ) {
          continue;
        }

        const sound: SpecifierSound = {
          SoundDescription: translate(Texts.createText(row.label)),
          dBA63: roundTo(bands.hz63, 0),
          dBA125: roundTo(bands.hz125, 0),
          dBA250: roundTo(bands.hz250, 0),
          dBA500: roundTo(bands.hz500, 0),
          dBA1k: roundTo(bands.hz1000, 0),
          dBA2k: roundTo(bands.hz2000, 0),
          dBA4k: roundTo(bands.hz4000, 0),
          dBA8k: roundTo(bands.hz8000, 0),
          dBATotal: roundTo(Amount.valueAs(Units.DecibelLw, bands.total), 0),
        };

        const soundIndex = payload.payload.Projects__Tbl[0].Products__Tbl.Sounds__Tbl?.length ?? 0;
        setFieldPath(payload, row.specifier_path + `[${soundIndex}]`, sound);

        break;
      }
      default:
        throw new Error("Unsupported type");
    }
  }

  return payload;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFieldPath(obj: any, path: string): any {
  const keys = path.split(".");
  let current = obj;

  for (const key of keys) {
    if (current === null || !(key in current)) {
      return undefined; // Return undefined if the path doesn't exist
    }
    current = current[key];
  }

  return current;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setFieldPath(obj: any, path: string, value: any): void {
  const keys = path.split(/[.[\]]/).filter(Boolean); // Split by "." and "[" or "]"
  let current = obj;

  for (const [i, key] of keys.slice(0, -1).entries()) {
    // If the key is an array index
    if (!Number.isNaN(Number(key))) {
      const index = Number(key);
      if (!current[index]) {
        current[index] = []; // Create an empty object if the index doesn't exist
      }
      current = current[index];
    } else {
      // Regular object field
      if (!(key in current)) {
        const nextKey = keys[i + 1];
        if (!Number.isNaN(Number(nextKey))) {
          current[key] = []; // Create nested array if not present
        } else {
          current[key] = {}; // Create nested object if not present
        }
      }
      current = current[key];
    }
  }

  // Set the final value
  const lastKey = keys[keys.length - 1];

  if (!Number.isNaN(Number(lastKey))) {
    const index = Number(lastKey);
    if (!Array.isArray(current)) {
      current = [value];
    } else {
      current[index] = value;
    }
  } else {
    current[lastKey] = value;
  }
}

function amountToValue(
  label: string,
  value: Amount.Amount<AnyQuantity> | undefined,
  props: Props
): { readonly value: string; readonly unit: string } {
  const { getUnit, getDecimals, translate } = props;
  if (!value) {
    return { value: "-", unit: "" };
  }
  const amount = value as Amount.Amount<AnyQuantity>;
  if (!amount.unit) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return { value: value.toString(), unit: "" };
  }
  const unit = getUnit(label, amount.unit.quantity);
  const decimals = getDecimals(label, unit);
  const unitLabel = translate(Texts.unitLabel(unit), UnitFormat.getUnitFormat(unit, UnitsFormat)?.label);
  const valueStr = Amount.valueAs(unit, amount).toFixed(decimals);
  return { value: valueStr, unit: unitLabel };
}
