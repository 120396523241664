/* eslint-disable no-restricted-globals */
export function clamp(value: number, min: number, max: number): number {
  if (isFinite(min) && value < min) {
    return min;
  }
  if (isFinite(max) && value > max) {
    return max;
  }
  return value;
}
