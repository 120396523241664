import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import type { AnyQuantity } from "shared-lib/uom";
import { CustomUnitsLookup } from "shared-lib/uom";
import type { Unit } from "uom";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type { ComponentInput, InputMapperSuccess, InputMapperError, InputParam, System, ResultQuery } from "../types";
import { createInputMapperError, createInputMapperSuccess } from "../types";
import type { Input, Response, BoxFanCurve } from "./types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";
import * as AirDensity from "../shared/air-density";

const msgSource = "BoxFanInputMapper";

export function getCalcParams(params: string, attributes: Attributes.Attributes): ReadonlyArray<InputParam> {
  if (params === "search") {
    return [];
  }
  const isConfigurableFan = Attributes.getIntOrDefault("CONFIGURABLE-FAN", attributes, 0) === 1;
  const configurableFanVisibilityFilter = isConfigurableFan
    ? PropertyFilter.fromStringOrEmpty("1=1", CustomUnitsLookup)
    : PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup);

  const extendedAccessories = Attributes.getInt("EXTENDED-ACCESSORIES", attributes) === 1;
  const extendedAccessoriesFilter = !extendedAccessories
    ? PropertyFilter.fromStringOrEmpty("1=0", CustomUnitsLookup)
    : configurableFanVisibilityFilter;
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "externalPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "externalPressure=0.1:Pascal~999999:Pascal",
        CustomUnitsLookup
      ),
      quantity: "Pressure",
      fieldName: "airPressure",
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "soundPressureDistance",
      values: [
        {
          value: 10,
          name: "1.0 m",
        },
        {
          value: 15,
          name: "1.5 m",
        },
        {
          value: 30,
          name: "3.0 m",
        },
        {
          value: 50,
          name: "5.0 m",
        },
      ],
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "freq",
      visibilityFilter: configurableFanVisibilityFilter,
      values: [
        {
          value: 50,
          name: "50",
        },
        {
          value: 60,
          name: "60",
        },
      ],
    },
    //------------- Accessories
    {
      type: "Discrete",
      group: "accessories",
      name: "inlet",
      values: [
        {
          value: 0,
          name: "InletSpigot",
        },
        {
          value: 1,
          name: "InletRainProtection",
        },
        {
          value: 2,
          name: "InletDumper",
        },
        {
          value: 3,
          name: "BlindInletPanel",
        },
      ],
      visibilityFilter: extendedAccessoriesFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      selectorType: "Checkbox",
      name: "outlet",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: configurableFanVisibilityFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      selectorType: "Checkbox",
      name: "roof",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: configurableFanVisibilityFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      selectorType: "Checkbox",
      name: "powerSwitch",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: configurableFanVisibilityFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      selectorType: "Checkbox",
      name: "doubleGalvanised",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: extendedAccessoriesFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      name: "pressureSwitch",
      selectorType: "Checkbox",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: extendedAccessoriesFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      name: "inletFilter",
      selectorType: "Checkbox",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: extendedAccessoriesFilter,
    },
    {
      type: "Discrete",
      group: "accessories",
      selectorType: "Checkbox",
      name: "frequencyConverter",
      values: [
        {
          value: 0,
          name: "Off",
        },
        {
          value: 1,
          name: "On",
        },
      ],
      visibilityFilter: extendedAccessoriesFilter,
    },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_Curve: QP.tableByProductId(QP.boxFanProductId, "ct_Curve"),
    ct_CurvePoint: QP.tableByProductId(QP.boxFanProductId, "ct_CurvePoint"),
    ct_Motor: QP.tableByProductId(QP.boxFanProductId, "ct_Motor"),

    ct_Fan: QP.tableByProductId(productId, "ct_Fan"),
    ct_StandardFan: QP.tableByProductId(productId, "ct_StandardFan"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_AccessoryPressureDrop: QP.tableByProductId(productId, "ct_AccessoryPressureDrop"),
  });
}

export function getResultsQuery(system: System, componentId: string): ReadonlyArray<ResultQuery> {
  return system.components
    .filter((c) => c.id !== componentId && c.resultItems.length > 0)
    .map((c) => {
      const resultItems = c.resultItems.map((i) => i.name);
      return {
        componentId: c.id,
        resultItems: resultItems,
      };
    });
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const { ct_Fan, ct_Motor, ct_Curve, ct_CurvePoint, ct_ItemNo, ct_StandardFan } = queryResultMap;

  const configurableFan = Attributes.getIntOrDefault("CONFIGURABLE-FAN", attributes, 0) === 1;
  const fanToUse = configurableFan
    ? getConfigurableFan(ct_ItemNo, ct_Fan, properties)
    : getStandardFan(ct_StandardFan, properties);

  if (!fanToUse) {
    return createInputMapperError([
      Messages.Error_MissingInput(msgSource, `Could not find a fan for ${PropertyValueSet.toString(properties)}`),
    ]);
  }

  const fan = ct_Fan.find((f) => f.id === fanToUse.id);
  const motor = ct_Motor.find((m) => m.id === fanToUse.motorId);

  if (!fan || (!motor && !configurableFan)) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "fan or motor could not be found")]);
  }

  const airDensity = AirDensity.getAirDensityKgPerCubicMeter(calcParams);
  const soundPressureDistanceInDecimeter = PropertyValueSet.getInteger("soundPressureDistance", calcParams);

  if (!airDensity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airDensity")]);
  }
  if (!soundPressureDistanceInDecimeter) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "soundPressureDistanceInDecimeter")]);
  }

  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const externalPressure = PropertyValueSet.getAmount<Quantity.Pressure>("externalPressure", calcParams);
  const soundPressureDistance = Amount.create(soundPressureDistanceInDecimeter, Units.Decimeter);
  const itemNumber = configurableFan ? fan.item_number : PropertyValueSet.getInteger("model", properties);

  const curves: QP.BoxFanCurveTable = ct_Curve.filter((c) => c.fan_id === fan.id);
  const pressureOutlineCurves = createCurves("1", curves, ct_CurvePoint, Units.LiterPerSecond, Units.Pascal, true);
  const powerOutlineCurves = createCurves("16", curves, ct_CurvePoint, Units.LiterPerSecond, Units.Watt, true);
  const rpmCurves = createCurves("10", curves, ct_CurvePoint, Units.LiterPerSecond, Units.Pascal);
  const powerCurves = createCurves("12", curves, ct_CurvePoint, Units.LiterPerSecond, Units.Watt);
  const efficiencyCurves = createCurves("11", curves, ct_CurvePoint, Units.LiterPerSecond, Units.Pascal);
  const airFlowExtremes = [...pressureOutlineCurves, ...powerOutlineCurves].reduce<Array<number>>((sofar, curr) => {
    sofar.push(...curr.points.map((p) => p.x));
    return sofar;
  }, []);

  const motorPowerCurve = motor?.power
    ? powerCurves.find((c) =>
        Amount.equals(
          Amount.create(parseFloat(c.name.replace(/,/g, ".")), Units.KiloWatt),
          Amount.create(motor.power, Units.KiloWatt)
        )
      )
    : undefined;

  return createInputMapperSuccess({
    airFlow: airFlow,
    externalPressure: externalPressure,
    airDensity: airDensity,
    soundPressureDistance: soundPressureDistance,
    queryResultMap: queryResultMap,
    fan: fan,
    motor: motor,
    configurableFan: configurableFan,
    calcParams: calcParams,
    fanSpeed: fanToUse.fanSpeed,
    frequency: fanToUse.frequency,
    itemNumber: (itemNumber && `${itemNumber}`) || undefined,
    pressureOutlineCurves: pressureOutlineCurves,
    powerOutlineCurves: powerOutlineCurves,
    rpmCurves: rpmCurves,
    powerCurves: powerCurves,
    efficiencyCurves: efficiencyCurves,
    minAirFlow: Amount.create(Math.min(...airFlowExtremes), Units.LiterPerSecond),
    maxAirFlow: Amount.create(Math.max(...airFlowExtremes), Units.LiterPerSecond),
    motorPowerCurve: motorPowerCurve,
  });
}

function createCurves(
  type: string,
  curvesTable: QP.BoxFanCurveTable,
  pointsTable: QP.BoxFanCurvePointTable,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  reverseSecondCurve: boolean = false
): ReadonlyArray<BoxFanCurve> {
  const typeCurves = curvesTable
    .filter((t) => t.type === type)
    .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));

  return typeCurves.map((c, i) => {
    const curvePoints = pointsTable.filter((p) => p.curve_id === c.id);
    if (reverseSecondCurve && i === 1) {
      curvePoints.reverse();
    }
    return {
      name: c.name,
      unitX: unitX,
      unitY: unitY,
      points: curvePoints.map((p) => {
        return {
          x: parseFloat(p.x),
          y: parseFloat(p.y),
        };
      }),
    };
  });
}

interface FanData {
  readonly id: string;
  readonly motorId: string | undefined;
  readonly fanSpeed: number;
  readonly frequency: number | undefined;
}

function getConfigurableFan(
  itemnoTable: QP.ItemNoTable,
  fanTable: QP.BoxFanFanTable,
  properties: PropertyValueSet.PropertyValueSet
): FanData | undefined {
  const itemNoRow = itemnoTable.find((r) => PropertyFilter.isValid(properties, r.property_filter));
  if (itemNoRow) {
    const fanRow = fanTable.find((f) => f.item_number === itemNoRow.item_no);
    if (fanRow) {
      return {
        id: fanRow.id,
        motorId: undefined,
        fanSpeed: 0,
        frequency: PropertyValueSet.getInteger("freq", properties),
      };
    }
  }
  return undefined;
}

function getStandardFan(
  standardFanTable: QP.BoxFanStandardFanTable,
  properties: PropertyValueSet.PropertyValueSet
): FanData | undefined {
  const standardFan = standardFanTable.find((r) => PropertyFilter.isValid(properties, r.property_filter));
  if (!standardFan) {
    return undefined;
  }

  return {
    id: standardFan.fan_id,
    motorId: standardFan.motor_id,
    fanSpeed: Amount.valueAs(Units.RevolutionsPerMinute, standardFan.rpm),
    frequency: standardFan.is60Hz === "1" ? 60 : 50,
  };
}
