/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import * as ProductCodes from "shared-lib/product-codes";
import * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import * as SC from "shared-lib/system-calculator";
import * as C from "shared-lib/calculation";
import * as Uuid from "uuid";
import * as QD from "shared-lib/query-diaq";
import * as PU from "shared-lib/product-utils";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import type { Selector } from "reselect";
import { createSelector } from "reselect";
import * as Texts from "shared-lib/language-texts";
import * as Accessories from "shared-lib/accessories";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import type {
  OwnProps,
  Response,
  MetaTables,
  SearchMetaTables,
  Result,
  ComponentResult,
  AllProductTables,
} from "./types";
import * as FilterFunctions from "./filter-functions";
import { createUiFilterData } from "./result-sorting";
import * as Brand from "../brand";

export function searchQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productsById: QP.productsById(),
    marketTables: PU.createMarketTablesQuery(ownProps.ecomUrl, ownProps.market),
    metaTables: QD.createMapQuery<MetaTables>({
      ct_ResultItems: QP.tableByProductId(QP.metaProductId, "ct_ResultItems"),
      ct_AttributeTemplateMapping: QP.tableByProductId(QP.metaProductId, "ct_AttributeTemplateMapping"),
      ct_ScoreGuide: QP.tableByProductId(QP.fricoProductId, "ct_ScoreGuide"),
      ct_ScoreTypeEpim: QP.tableByProductId(QP.fricoProductId, "ct_ScoreType"),
    }),
    searchMetaTables: QD.createMapQuery<SearchMetaTables>({
      ct_SearchProducts: QP.tableByProductId(ownProps.searchProductId, "ct_SearchProducts"),
      ct_FilterFunctions: QP.tableByProductId(ownProps.searchProductId, "ct_FilterFunctions"),
      ct_DiaqTemplates: QP.tableByProductId(ownProps.searchProductId, "ct_DiaqTemplates"),
      ct_BathroomFanSelection: QP.tableByProductId(ownProps.searchProductId, "ct_BathroomFanSelection"),
    }),
    tablesForProducts: tablesForProductsQuery(getMainProducts(response)),
    tablesForAccessories: tablesForProductsQuery(getAccessoryProducts(response)),
    systemQueryResult: buildSystemQuery(response),
    translateTables: getTranslateTables(response),
    productIdToRetired: QP.productIdToRetired(),
  });
}

function getTranslateTables(response: Response | undefined): QP.TablesByProductIdsQuery | undefined {
  const mainProducts = getMainProducts(response);
  const accessoryProducts = getAccessoryProducts(response);
  if (!mainProducts || !accessoryProducts) {
    return undefined;
  }
  return Texts.getTablesQuery([...mainProducts, ...accessoryProducts]);
}

function tablesForProductsQuery(products: ReadonlyArray<string> | undefined): QP.TablesByProductIdsQuery | undefined {
  if (products === undefined) {
    return undefined;
  }
  return QP.tablesByProductIds(products, [
    "property",
    "code",
    "ct_ItemNo",
    "ct_VariantNo",
    "ct_Attributes2",
    "ct_Length",
    "ct_VerticalLimit",
    "ct_CurtainFilterDimension",
    "ct_DiaqTemplates",
    "ct_Accessories",
    "ct_CalcParamDefault",
    "ct_ItemNumberStatus",
    "ct_SearchExcludeProperties",
    "ct_AmcaStatements",
  ]);
}

export const getMainProducts: Selector<Response | undefined, never, ReadonlyArray<string> | undefined> = createSelector(
  (response: Response | undefined) => response?.searchMetaTables.ct_SearchProducts,
  (response: Response | undefined) => response?.marketTables,
  (searchProducts, marketTables) => {
    if (!searchProducts || !marketTables || !marketTables.variantNoToPrice) {
      return undefined;
    }
    return PU.filterProductsByMarket(
      marketTables,
      searchProducts.map((p) => p.product)
    );
  }
);

export const getAccessoryProducts: Selector<
  Response | undefined,
  never,
  ReadonlyArray<string> | undefined
> = createSelector(
  getMainProducts,
  (response: Response | undefined) => response?.searchMetaTables.ct_SearchProducts,
  (response: Response | undefined) => response?.marketTables,
  (response: Response | undefined) => response?.tablesForProducts,
  (mainProducts, searchProducts, marketTables, tablesForProducts) => {
    if (!searchProducts || !mainProducts || !marketTables || !tablesForProducts || !marketTables.variantNoToPrice) {
      return undefined;
    }
    const accessoryProducts: Array<string> = [];
    for (const product of mainProducts) {
      const productTables = tablesForProducts[product];
      if (!productTables) {
        return undefined;
      }
      accessoryProducts.push(...productTables.ct_Accessories.map((a) => a.product));
    }
    const inMarket = PU.filterProductsByMarket(marketTables, accessoryProducts);
    return inMarket;
  }
);

export const getAllProducts: Selector<Response | undefined, never, ReadonlyArray<string> | undefined> = createSelector(
  getMainProducts,
  getAccessoryProducts,
  (mainProducts, accessories) => {
    if (mainProducts === undefined || accessories === undefined) {
      return undefined;
    }
    return [...mainProducts, ...accessories];
  }
);

export const buildSystemQuery: Selector<
  Response | undefined,
  never,
  QD.DiaqMapQuery<unknown> | undefined
> = createSelector(
  getAllProducts,
  getMainProducts,
  getAccessoryProducts,
  (response: Response | undefined) => response?.tablesForProducts,
  (response: Response | undefined) => response?.tablesForAccessories,
  (response: Response | undefined) => response?.metaTables?.ct_ResultItems,
  (response: Response | undefined) => response?.metaTables?.ct_AttributeTemplateMapping,
  (
    allProducts,
    mainProducts,
    accessoryProducts,
    tablesForProducts,
    tablesForAccessories,
    resultItems,
    attributeTemplateMapping
  ) => {
    if (
      allProducts === undefined ||
      mainProducts === undefined ||
      accessoryProducts === undefined ||
      tablesForProducts === undefined ||
      tablesForAccessories === undefined ||
      mainProducts.find((p) => tablesForProducts[p] === undefined) ||
      accessoryProducts.find((p) => tablesForAccessories[p] === undefined) ||
      resultItems === undefined ||
      attributeTemplateMapping === undefined
    ) {
      return undefined;
    }

    const productAndCalcs: Array<SC.ProductIdAndCalculators> = [];
    for (const productId of allProducts) {
      const productTables = tablesForProducts[productId] || tablesForAccessories[productId];
      if (!productTables) {
        return undefined;
      }
      const templates = DiaqTemplates.getAllTemplatesFromAttributes(
        productTables.ct_DiaqTemplates,
        attributeTemplateMapping,
        productTables.ct_Attributes2
      ).filter((t) => t.type === "ResultItems");
      if (templates.length === 0) {
        continue;
      }

      const templateResultItems = resultItems.filter((r) => templates.find((t) => t.template === r.template));
      const calculators = R.uniq(templateResultItems.map((i) => i.calculator));

      productAndCalcs.push({
        variantId: undefined,
        productId: productId,
        calculators: calculators,
      });
    }

    const systemQuery = SC.getQueryForProductIdAndCalculators(productAndCalcs);
    return systemQuery;
  }
);

export async function calculateProductVariants(ownProps: OwnProps, response: Response): Promise<ReadonlyArray<Result>> {
  console.time("Search");
  const { market, language, attributes, calcParams, ecomAttributes } = ownProps;
  const {
    tablesForProducts,
    tablesForAccessories,
    metaTables,
    marketTables,
    systemQueryResult,
    translateTables,
    searchMetaTables,
  } = response;
  const translate = Texts.translateFunctionSelector(translateTables, language);
  const promises: Array<Promise<void>> = [];
  const allResults: Array<Result> = [];

  const searchProperties = ownProps.properties[0];

  const filterFunctions = searchMetaTables.ct_FilterFunctions.filter((f) =>
    PropertyFilter.isValid(searchProperties, f.property_filter)
  );
  const preFilters = filterFunctions.map((f) => FilterFunctions.preFilterFunctions[f.pre_filter]);
  const postFilters = filterFunctions.map((f) => FilterFunctions.postFilterFunctions[f.post_filter]);

  const productIds = getMatchingProductIds(ownProps, response);
  for (const productId of productIds) {
    const productTables = tablesForProducts[productId];
    // Does not work because the properties are come from the search product but the ct_attributes are the real product, moved to use the variant instead
    // const itemsTemplate = getFirstMatchingResultItems(
    //   properties,
    //   productTables.ct_DiaqTemplates,
    //   metaTables.ct_AttributeTemplateMapping,
    //   productTables.ct_Attributes2
    // );
    // if (!itemsTemplate) {
    //   continue;
    // }

    const allVariants = getProductVariants(market, productId, searchProperties, productTables);
    if (allVariants.length === 0) {
      console.log("No variants", productId);
    }

    const results: Array<Result> = [];

    const variants = preFilters.reduce(
      (sofar, f) => (f ? f(searchProperties, productId, sofar, metaTables, searchMetaTables, productTables) : sofar),
      allVariants
    );

    for (const variant of variants) {
      const productCodes = ProductCodes.getProductCodes(productTables, variant);
      const variantCode = productCodes.itemNo ? productCodes.code : "?";
      // console.log("variantCode", variantCode);
      // if (variantCode === "?") {
      //   console.log("Variant without orderingCode", variant);
      // }

      // we have to get the template on variant level because the attributes are variant depending
      const itemsTemplate = getFirstMatchingResultItems(
        [variant],
        productTables.ct_DiaqTemplates,
        metaTables.ct_AttributeTemplateMapping,
        productTables.ct_Attributes2
      );
      if (!itemsTemplate) {
        continue;
      }

      const resultItems = metaTables.ct_ResultItems.filter((r) => r.template === itemsTemplate);

      const m3ItemNo = productCodes.itemNo;

      const status = productTables.ct_ItemNumberStatus.find((r) => r.item_no === m3ItemNo);
      if (!status || status.status !== 20) {
        // console.log(`${m3ItemNo}: Status ${status ? status.status : undefined}`);
        continue;
      }

      const variantNo = productCodes.variantId ? productCodes.variantId : undefined;
      const validVariantNo =
        variantNo && (!marketTables.variantNoToPrice || marketTables.variantNoToPrice[variantNo] !== undefined);
      if (!validVariantNo) {
        if (
          !(
            QP.offlineBoxFanProductsToSkipMarketCheck.find((p) => p === productId) &&
            (market === "240" || market === "645")
          ) //240=india, 645=spain, because they are not setup from epim and Promaster so there is missmatch in Itemnumbers
        ) {
          continue;
        }
      }

      const variantAttributes = Attributes.createMap(variant, productTables.ct_Attributes2);
      const calculators = resultItems.map((r) => r.calculator);
      const isMatch = matchAttributes(
        m3ItemNo,
        searchProperties,
        calcParams,
        calculators,
        attributes,
        variantAttributes
      );
      if (!isMatch) {
        // console.log(`${m3ItemNo}: Attributes`);
        continue;
      }

      if (ecomAttributes && !matchEcomAttributes(ecomAttributes, variantAttributes)) {
        continue;
      }

      const calculationConfig = buildCalculationConfig(variant, calcParams, productTables, ownProps, response);
      if (!calculationConfig) {
        // Could not build config with all requested accessories, skip variant
        // console.log(`${m3ItemNo}: buildConfig`);
        continue;
      }
      const promise = C.calculateSystem(
        {
          productId,
          variantId: undefined,
          config: calculationConfig,
          selected: true,
        },
        {
          metaTables: response.metaTables,
          productTables: response.tablesForProducts[productId],
          accessoryTables: response.tablesForAccessories,
          systemQueryResult,
        }
      ).then(({ result: systemResult, system, updatedCalcParams }) => {
        if (!systemResult || !system) {
          // console.log(`${m3ItemNo}: CalculateSystem`);
          return;
        }
        const messages = SC.getAllMessages(systemResult);
        if (messages.some((m) => SC.isStoppingErrorMessage(m.code))) {
          // console.log(`${m3ItemNo}: Errors`, messages);
          return;
        }

        const componentResults = systemResult[productId];
        const itemName = translate(Texts.item_name(productId, variant), productCodes.code);
        const variantName = translate(Texts.item_variant(productId, variant), variantCode);
        const componentResult: ComponentResult = {
          id: productId,
          productId: productId,
          properties: variant,
          // showProperties: showProperties,
          calcParams: updatedCalcParams[productId] || calcParams,
          productCode: variantName,
          productCodeEN: productCodes.code,
          itemName: itemName,
          m3: m3ItemNo,
          variant: variantNo,
          price:
            (marketTables.variantNoToPrice &&
              variantNo &&
              marketTables.variantNoToPrice[variantNo] &&
              marketTables.variantNoToPrice[variantNo] > 1 &&
              marketTables.variantNoToPrice[variantNo]) ||
            undefined,
          results: trimExcessCurvesFromFanResult(componentResults),
          additionalData: undefined, // Added by Postfilter
          uiFilterData: createUiFilterData(resultItems, variantAttributes),
        };

        const accessoryResults: Array<ComponentResult> = [];
        for (const accId of R.keys(systemResult)) {
          if (accId === productId) {
            continue;
          }
          const accComponent = system.components.find((c) => c.id === accId);
          if (!accComponent) {
            throw new Error("Could not find accessory component");
          }
          const accTables = tablesForAccessories[accComponent.productId];
          const accCodes = ProductCodes.getProductCodes(accTables, accComponent.propertyValues);
          const accItemName = Accessories.getAccessoryName(translate, accTables, accComponent.propertyValues);
          const accResults = systemResult[accId];
          accessoryResults.push({
            id: accId,
            productId: accComponent.productId,
            properties: accComponent.propertyValues,
            calcParams: updatedCalcParams[accId] || accComponent.calcParams,
            productCode: accCodes.code || "-",
            productCodeEN: accCodes.code || "-",
            itemName: accItemName,
            m3: accCodes.itemNo || "-",
            variant: accCodes.variantId,
            price: undefined,
            additionalData: undefined,
            results: accResults,
            uiFilterData: undefined,
          });
        }

        //        console.log(ownProps.searchProductId);

        const productNameFromAttribute = translate(
          Texts.product_name(productId, Brand.searchProductToBrand[ownProps.searchProductId], variant)
        ); //Attributes.getString("td-product-name", variantAttributes);
        const productName = productNameFromAttribute || translate(Texts.family_name(productId, variant));
        const productDescription = productNameFromAttribute ? "" : translate(Texts.product_description(productId), " ");

        results.push({
          id: Uuid(),
          productName: productName,
          productDescription: productDescription,
          result: componentResult,
          accessoryResults: accessoryResults,
        });
      });

      // console.log("product:", response.productsById[productId].key);
      promises.push(promise);
    }

    await Promise.all(promises); // Results for one product

    const finalResults = postFilters.reduce(
      (sofar, f) => (f ? f(searchProperties, sofar, calcParams, metaTables, searchMetaTables, productTables) : sofar),
      results
    );

    allResults.push(...finalResults);
  }
  console.timeEnd("Search");
  return allResults;
}

function getFirstMatchingResultItems(
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  diaqTemplates: QP.DiaqTemplatesTable,
  attributeTemplateMapping: QP.AttributeTemplateMappingTable,
  attributesTables: QP.AttributesTable
): string | undefined {
  for (const variant of variants) {
    const attributes = Attributes.createMap(variant, attributesTables);
    const template = DiaqTemplates.getTemplatesFromAttributes(
      variant,
      diaqTemplates,
      attributeTemplateMapping,
      attributes
    ).find((t) => t.type === "ResultItems");
    if (template) {
      return template.template;
    }
  }
  return undefined;
}

function matchEcomAttributes(
  ecomAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  for (const exAttribute of ecomAttributes!) {
    if (!Attributes.matchAttribute(exAttribute.attribute, ecomAttributes, variantAttributes)) {
      return false;
    }
  }
  return true;
}

function trimExcessCurvesFromFanResult(componentResults: SC.ResultItemOutputMap): SC.ResultItemOutputMap {
  const newComponentResults: any = {};
  for (const key of Object.keys(componentResults)) {
    const componentResult = componentResults[key];
    if (componentResult.type === "OutputMapperSuccess" && componentResult.result.type === "Fan") {
      const fanResultItem = componentResult.result;
      const newFanResultItem: SC.FanResultItem = {
        ...fanResultItem,
        value: {
          ...fanResultItem.value,
          air: {
            ...fanResultItem.value.air,
            powerCurves: [],
            currentCurves: [],
            rpmCurves: [],
          },
        },
      };
      newComponentResults[key] = { ...componentResult, result: newFanResultItem };
    } else {
      newComponentResults[key] = componentResult;
    }
  }
  return newComponentResults;
}

export function matchAttributes(
  itemNumber: string,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet,
  calculators: ReadonlyArray<string>,
  searchAttributes: ReadonlyArray<SC.SearchAttribute>,
  variantAttributes: Attributes.Attributes
): boolean {
  for (const calculator of calculators) {
    const matcher = SC.lookupMatcher(calculator);
    if (!matcher(itemNumber, searchAttributes, variantAttributes, searchVariant, calcParams)) {
      return false;
    }
  }
  return true;
}

function buildCalculationConfig(
  variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet,
  productTables: AllProductTables,
  ownProps: OwnProps,
  response: Response
): C.ItemConfig | undefined {
  const { market } = ownProps;
  const { tablesForAccessories, metaTables } = response;

  const accessories: Array<C.Accessory> = [];
  for (const accType of ownProps.accessories) {
    const accProduct = productTables.ct_Accessories.find(
      (a) => a.type === accType && PropertyFilter.isValid(variant, a.property_filter)
    );
    if (!accProduct) {
      return undefined;
    }

    const accTables = tablesForAccessories[accProduct.product];

    const accessoryProperties = accTables.property.map((p) => p.name);
    const variants = PU.generateVariantsForProperties(
      accessoryProperties,
      PropertyValueSet.setText("type", accType, accProduct.variant),
      accTables.property.map((p) => {
        const propertyValue = PropertyValueSet.getInteger(p.name, accProduct.variant);
        if (propertyValue !== undefined) {
          return { ...p, value: p.value.filter((v) => v.value.value === propertyValue) };
        } else {
          return p;
        }
      })
    );
    if (variants.length === 0) {
      return undefined;
    }
    const accVariant = PU.getFullVariant(accTables.property, accTables.ct_CalcParamDefault, market, variants[0]);

    const accAttributes = Attributes.createMap(accVariant, accTables.ct_Attributes2);
    let accCalcParams = PU.getFullCalcParams(
      accTables.ct_DiaqTemplates,
      metaTables.ct_ResultItems,
      accTables.ct_CalcParamDefault,
      PropertyValueSet.Empty,
      accVariant,
      market,
      accAttributes,
      metaTables.ct_AttributeTemplateMapping
    );
    accCalcParams = PropertyValueSet.setInteger("is_accessory", 1, accCalcParams);
    accCalcParams = PropertyValueSet.setInteger("is_accessory_search", 1, accCalcParams);

    accessories.push({
      id: accType,
      parentId: undefined,
      productId: accProduct.product,
      properties: accVariant,
      calcParams: accCalcParams,
    });
  }

  return {
    properties: variant,
    calcParams: calcParams,
    secondCalcParams: undefined,
    accessories: accessories,
  };
}

export function getProductVariants(
  market: string,
  _productId: string,
  properties: PropertyValueSet.PropertyValueSet,
  productTables: AllProductTables
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // _systemQueryResult: any
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  // const propertiesInTables = getPropertiesFromFiltersInTables(resultItems, productId, productTables, systemQueryResult);

  // if (propertiesInTables === undefined) {
  //   return [PropertyValueSet.Empty];
  // }

  // const generateProperties = propertiesInTables.filter(
  //   (p) => !productTables.ct_SearchExcludeProperties.some((se) => se.property === p)
  // );

  // Discrete properties!
  const filterProperties = PropertyValueSet.keepProperties(
    PropertyValueSet.getPropertyNames(properties).filter((p) => {
      const intValue = PropertyValueSet.getInteger(p, properties);
      return intValue !== undefined && intValue !== -1;
    }),
    properties
  );
  const variants = PU.generateVariantsForProperties(
    productTables.property.map((p) => p.name),
    filterProperties,
    productTables.property
  );
  const fullVariants = variants.map((v) =>
    PU.getFullVariant(productTables.property, productTables.ct_CalcParamDefault, market, v)
  );

  return fullVariants;
}

// function getPropertiesFromFiltersInTables(
//   resultItems: QP.ResultItemsTable,
//   productId: string,
//   productTables: AllProductTables,
//   systemQueryResult: any
// ): ReadonlyArray<string> | undefined {
//   const calculators = R.uniq(resultItems.map((i) => i.calculator));
//   if (calculators.length === 0) {
//     return undefined;
//   }
//   const filters = R.unnest<PropertyFilter.PropertyFilter>(
//     calculators.map((c) => {
//       const systemQueryKey = productId + ";" + c;
//       const queryResult = systemQueryResult[systemQueryKey];

//       return R.unnest(
//         R.values(queryResult)
//           .filter(Array.isArray)
//           .map((t: any) => t.filter((r: any) => !!r.property_filter).map((r: any) => r.property_filter))
//       );
//     })
//   );
//   filters.push(...productTables.ct_Attributes2.map((r) => r.property_filter));
//   const propertyNames = R.uniq(R.unnest<string>(filters.map((f) => PropertyFilter.getReferencedProperties(f))));
//   return propertyNames;
// }

function getMatchingProductIds(props: OwnProps, response: Response): ReadonlyArray<string> {
  const { properties } = props;
  const { marketTables, searchMetaTables } = response;

  const products = [];
  for (const variant of properties) {
    const filteredVariant = R.pickBy((v) => {
      const integer = PropertyValue.getInteger(v);
      return integer !== undefined && integer >= 0;
    }, variant) as PropertyValueSet.PropertyValueSet;

    const matchingProductIds = searchMetaTables.ct_SearchProducts
      .filter((p) => PropertyFilter.isValidMatchMissing(filteredVariant, p.property_filter))
      .map((p) => p.product);

    const notRetiredProducts = matchingProductIds.filter((p) => !response.productIdToRetired[p]);

    // Only use products in current market
    products.push(...PU.filterProductsByMarket(marketTables, notRetiredProducts));
  }
  return R.uniq(products);
}
