/* eslint-disable no-restricted-properties */
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import type * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import * as Types from "../types";
import type { BoxFanAirResult, BoxFan, OctaveBands, ErpFanData, EcoDesignFanData } from "../result-items-types";
import type { Input, BoxFanCurve } from "./types";
import * as BoxFanBlackBoxCalculator from "../dll/boxfanblackbox";
import * as Interpolation from "../../interpolation/index";
import type { Spline } from "../../interpolation/index";
import { splineGetPoint, findSystemCurveIntersection } from "../../interpolation/index";
import * as Fansound from "../shared/fan-sound";
import * as Messages from "../messages";

const msgSource = "BoxFanCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<BoxFan>> {
  const {
    airFlow,
    externalPressure,
    airDensity,
    calcParams,
    soundPressureDistance,
    fan,
    fanSpeed,
    motor,
    queryResultMap,
    frequency,
    configurableFan,
    itemNumber,
    powerCurves,
    pressureOutlineCurves,
    powerOutlineCurves,
    rpmCurves,
    efficiencyCurves,
    minAirFlow,
    maxAirFlow,
    motorPowerCurve,
  } = input;

  const { ct_AccessoryPressureDrop } = queryResultMap;

  const workingPoint = calculateWorkingPoint(
    rpmCurves,
    fanSpeed,
    configurableFan,
    airFlow,
    externalPressure,
    motorPowerCurve,
    calcParams,
    ct_AccessoryPressureDrop
  );
  const { curve1SpeedSpline, curve2SpeedSpline, ratio } = workingPoint;

  const [blackBoxResult] =
    workingPoint.airFlow && workingPoint.pressure
      ? await BoxFanBlackBoxCalculator.calculate({
          Airflow: workingPoint.airFlow * 3.6, // Convert from l/s to m3/h
          Pressure: workingPoint.pressure,
          AirDensity: Amount.valueAs(Units.KilogramPerCubicMeter, airDensity),
          LPDistance: Amount.valueAs(Units.Meter, soundPressureDistance),
          Series: fan.family.split(" ")[1],
          FanSize: fan.size,
        })
      : [undefined];

  const fanMotor =
    configurableFan && blackBoxResult ? findMotor(fan, blackBoxResult.Power / 1000, queryResultMap.ct_Motor) : motor;

  const actualRpmCurve =
    curve1SpeedSpline && curve2SpeedSpline && ratio
      ? generateActualRpmCurve(curve1SpeedSpline, curve2SpeedSpline, ratio, fanSpeed, motorPowerCurve)
      : undefined;

  const bestStandardFan =
    airFlow && externalPressure && configurableFan
      ? getBestStandardFan(
          airFlow,
          externalPressure,
          input,
          fan,
          rpmCurves,
          PropertyValueSet.getInteger("freq", calcParams),
          powerCurves
        )
      : undefined;

  const insideWorkingArea =
    (blackBoxResult && fanMotor && blackBoxResult.Power < fanMotor.max_power_demand * 1000) || false;
  const fanFrequency = frequency ?? (bestStandardFan && bestStandardFan.is60Hz === "1" ? 60 : 50) ?? undefined;
  const fanAirResult: BoxFanAirResult = {
    minAirFlow: minAirFlow,
    maxAirFlow: maxAirFlow,
    maxPowerDemand: (fanMotor && Amount.create(fanMotor.max_power_demand, Units.KiloWatt)) || undefined,
    fanId: fan.id,
    motorId: fanMotor?.id,
    frequency: fanFrequency,
    insideWorkingArea: insideWorkingArea,
    pressureOutlineCurves: pressureOutlineCurves,
    rpmCurves: rpmCurves,
    efficiencyCurves: efficiencyCurves,
    powerCurves: powerCurves,
    powerOutlineCurves: powerOutlineCurves,
    actualRpmCurve: actualRpmCurve,
    bestStandardFan: bestStandardFan?.item_number,
    fanIsConfigurable: configurableFan,
    desiredAirFlow: airFlow,
    desiredExternalPressure: externalPressure,
    airDensity: airDensity,
    airFlow: (workingPoint.airFlow && Amount.create(workingPoint.airFlow, Units.LiterPerSecond)) || undefined,
    externalPressure: (workingPoint.pressure && Amount.create(workingPoint.pressure, Units.Pascal)) || undefined,
    totalPressure: blackBoxResult && Amount.create(blackBoxResult.TotalPressure, Units.Pascal),
    power: blackBoxResult && Amount.create(blackBoxResult.Power, Units.Watt),
    efficiency: blackBoxResult && Amount.create(blackBoxResult.Efficiency, Units.Percent),
    fanSpeed: Amount.create(blackBoxResult?.Speed ?? 0, Units.RevolutionsPerMinute),
    airVelocity: blackBoxResult && Amount.create(blackBoxResult.AirVelocity, Units.MeterPerSecond),

    family: fan.madam_family,
    size: fan.size,
    motorPower: (fanMotor && Amount.create(fanMotor.power, Units.KiloWatt)) || undefined,
    motorVoltage: Amount.create(400, Units.Volt), // STANDARD
    accessories: getAccessoriesList(input),
  };

  const sortValues =
    fanAirResult.efficiency && fanAirResult.power
      ? [
          { value: Amount.valueAs(Units.Percent, fanAirResult.efficiency), descending: true },
          { value: Amount.valueAs(Units.Watt, fanAirResult.power), descending: false },
          { value: calculateWorkDistance(fanAirResult), descending: true },
        ]
      : [];

  const messages = !insideWorkingArea ? [Messages.Error_OutsideValidRange(msgSource)] : [];

  return Types.createCalculatorSuccess(
    sortValues,
    {
      air: fanAirResult,
      outletSound: (blackBoxResult && getSound(blackBoxResult.SoundOutlet)) || undefined,
      surroundingSound: (blackBoxResult && getSound(blackBoxResult.SoundSurrounding)) || undefined,
      soundPressureLevelLpa:
        (blackBoxResult && Amount.create(blackBoxResult.SoundPressureLevel, Units.Decibel)) || undefined,
      soundPressureDistance: input.soundPressureDistance,
      erpFanData: erpFanData(blackBoxResult),
      ecoDesignData: ecoDesignData(fanAirResult, fanMotor, blackBoxResult, itemNumber),
    },
    messages,
    calcParams
  );
}

function calculateWorkDistance(fanAirResult: BoxFanAirResult): number {
  const desiredX = fanAirResult.desiredAirFlow && Amount.valueAs(Units.CubicMeterPerHour, fanAirResult.desiredAirFlow);
  const desiredY =
    fanAirResult.desiredExternalPressure && Amount.valueAs(Units.Pascal, fanAirResult.desiredExternalPressure);
  const workX = fanAirResult.airFlow && Amount.valueAs(Units.CubicMeterPerHour, fanAirResult.airFlow);
  const workY = fanAirResult.externalPressure && Amount.valueAs(Units.Pascal, fanAirResult.externalPressure);

  if (!desiredX || !desiredY || !workX || !workY) {
    return Number.MAX_VALUE;
  }
  return Math.sqrt(Math.pow(desiredX - workX, 2) + Math.pow(desiredY - workY, 2));
}

function findMotor(fan: QP.Fan, power: number, motors: QP.BoxFanMotorTable): QP.Motor | undefined {
  return motors
    .filter((m) => m.power >= fan.min_motor_power && m.power <= fan.max_motor_power)
    .sort((a, b) => a.power - b.power)
    .find((m) => m.max_power_demand > power);
}

function ecoDesignData(
  airResult: BoxFanAirResult,
  motor: QP.Motor | undefined,
  dllOutput: BoxFanBlackBoxCalculator.BoxFanBlackBoxResult | undefined,
  itemNumber: string | number | undefined
): EcoDesignFanData | undefined {
  const calculatedData = ecoDesignCalculation(airResult.airFlow, airResult.externalPressure, motor, dllOutput);

  return {
    manufacturer: "Systemair",
    productName: (itemNumber && `${itemNumber}`) || "",
    erpClassification: calculatedData?.erpClassification,
    unitCategory: "NRVU",
    drive: "vsd_needed",
    unitType: "UVU",
    heatRecoveryType: "none",
    temperatureRatio: "not_applicable",
    workingAirFlow: airResult.airFlow,
    effectiveElectricPowerInputInclVariableDrive:
      calculatedData && Amount.create(calculatedData.effectiveElectricPowerInputInclVariableDrive, Units.Watt),
    sfp: calculatedData && Amount.create(calculatedData.sfp, Units.WattPerCubicMeterPerSecond),
    nominalExternalPressure: airResult.externalPressure,
    efficiencyByStaticPressure:
      calculatedData && Amount.create(calculatedData.efficiencyByStaticPressure, Units.Percent),
    externalLeakage: Amount.create(5, Units.Percent),
    soundPower: dllOutput && soundPowerAweighted(dllOutput.SoundSurrounding),
  };
}

interface EcoDesignResult {
  readonly erpClassification: string | undefined;
  readonly effectiveElectricPowerInputInclVariableDrive: number;
  readonly sfp: number;
  readonly efficiencyByStaticPressure: number;
}
function ecoDesignCalculation(
  airflow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  pressure: Amount.Amount<Quantity.Pressure> | undefined,
  motor: QP.Motor | undefined,
  dllOutput: BoxFanBlackBoxCalculator.BoxFanBlackBoxResult | undefined
): EcoDesignResult | undefined {
  if (!motor || !airflow || !pressure || !dllOutput) {
    return undefined;
  }
  const p = dllOutput.Power / (motor.efficiency / 1000);
  const z = p * 1.06;
  const airFlow = Amount.valueAs(Units.CubicMeterPerSecond, airflow);
  const pressurePa = Amount.valueAs(Units.Pascal, pressure);
  const fanEfficiency = (airFlow * pressurePa) / z;

  const minimumEfficiencyErp2016 = 0.062 * Math.log(p / 1000) + 0.35;
  const minimumEfficiencyErp2018 = 0.062 * Math.log(p / 1000) + 0.42;
  let erpClassification = undefined;

  if (fanEfficiency >= minimumEfficiencyErp2016) {
    erpClassification = "ErP 2016";
  }
  if (fanEfficiency >= minimumEfficiencyErp2018) {
    erpClassification += ", ErP 2018";
  }

  return {
    erpClassification: erpClassification,
    effectiveElectricPowerInputInclVariableDrive: z,
    sfp: p / airFlow,
    efficiencyByStaticPressure: ((airFlow * pressurePa) / dllOutput.Power) * 100,
  };
}

function erpFanData(dllOutput: BoxFanBlackBoxCalculator.BoxFanBlackBoxResult | undefined): ErpFanData | undefined {
  if (!dllOutput) {
    return undefined;
  }

  return {
    efficiencyGradeNReal: Amount.create(dllOutput.EfficiencyGradeNReal, Units.One),
    efficiencyGradeNNorm: Amount.create(dllOutput.EfficiencyGradeNNormal, Units.One),
    objectiveN: Amount.create(dllOutput.NObjective, Units.Percent),
    realN: Amount.create(dllOutput.NReal, Units.Percent),
    measurementCategory: dllOutput.MeasurementCategory,
    efficiencyCategory: dllOutput.EfficiencyCategory,
    specificRation: Amount.create(dllOutput.SpecificRatio, Units.One),
  };
}

function getSound(dllSound: BoxFanBlackBoxCalculator.BoxFanSoundResult): OctaveBands {
  const sound: OctaveBands = {
    type: "Octave",
    hz63: dllSound.Hz63,
    hz125: dllSound.Hz125,
    hz250: dllSound.Hz250,
    hz500: dllSound.Hz500,
    hz1000: dllSound.Hz1000,
    hz2000: dllSound.Hz2000,
    hz4000: dllSound.Hz4000,
    hz8000: dllSound.Hz8000,
    total: undefined,
  };
  return { ...sound, total: sumOctaveBands(sound) };
}

function sumOctaveBands(octaveBands: OctaveBands | undefined): Amount.Amount<Quantity.SoundPowerLevel> | undefined {
  if (!octaveBands) {
    return undefined;
  }
  const { hz63, hz125, hz250, hz500, hz1000, hz2000, hz4000, hz8000 } = octaveBands;
  if (!hz63 || !hz125 || !hz250 || !hz500 || !hz1000 || !hz2000 || !hz4000 || !hz8000) {
    return undefined;
  }

  return Amount.create(
    10 *
      Math.log10(
        Math.pow(10, 0.1 * hz63) +
          Math.pow(10, 0.1 * hz125) +
          Math.pow(10, 0.1 * hz250) +
          Math.pow(10, 0.1 * hz500) +
          Math.pow(10, 0.1 * hz1000) +
          Math.pow(10, 0.1 * hz2000) +
          Math.pow(10, 0.1 * hz4000) +
          Math.pow(10, 0.1 * hz8000)
      ),
    Units.DecibelLw
  );
}

function soundPowerAweighted(
  dllSound: BoxFanBlackBoxCalculator.BoxFanSoundResult
): Amount.Amount<Quantity.SoundPowerLevel> | undefined {
  const octaveBands = getSound(dllSound);
  const soundPowerAWeighted = Fansound.aWeightOctaveBands(octaveBands);
  return sumOctaveBands(soundPowerAWeighted);
}

interface StandardFanDistance {
  readonly distance: number;
  readonly standardFan: QP.StandardFan;
}
function getBestStandardFan(
  desiredAirflow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredPressure: Amount.Amount<Quantity.Pressure> | undefined,
  input: Input,
  fan: QP.Fan,
  rpmCurves: ReadonlyArray<BoxFanCurve>,
  frequency: number | undefined,
  powerCurves: ReadonlyArray<BoxFanCurve>
): QP.StandardFan | undefined {
  if (!desiredAirflow || !desiredPressure) {
    return undefined;
  }
  const { ct_StandardFan, ct_AccessoryPressureDrop, ct_Motor } = input.queryResultMap;
  const standardFans = ct_StandardFan.filter((sf) => sf.fan_id === fan.id);
  const x1 = Amount.valueAs(Units.LiterPerSecond, desiredAirflow);
  const y1 = Amount.valueAs(Units.Pascal, desiredPressure);

  const standardFansByDistanceToWorkingPoint = standardFans
    .reduce((results, sf) => {
      if ((sf.is60Hz === "1" && frequency !== 60) || (sf.is60Hz === "0" && frequency !== 50)) {
        return results;
      }

      const motor = ct_Motor.find((m) => m.id === sf.motor_id);

      const powerCurve =
        motor &&
        powerCurves.find((c) =>
          Amount.equals(
            Amount.create(parseFloat(c.name.replace(/,/g, ".")), Units.KiloWatt),
            Amount.create(motor.power, Units.KiloWatt)
          )
        );

      const workingPoint = calculateWorkingPoint(
        rpmCurves,
        Amount.valueAs(Units.RevolutionsPerMinute, sf.rpm),
        false,
        desiredAirflow,
        desiredPressure,
        powerCurve,
        input.calcParams,
        ct_AccessoryPressureDrop
      );

      if (workingPoint.airFlow && workingPoint.pressure) {
        const { airFlow, pressure } = workingPoint;
        // Distance to working point

        const powerSpline = powerCurve ? Interpolation.splineCreateFromPoints(powerCurve.points) : undefined;

        if (powerSpline) {
          const k = pressure / Math.pow(airFlow, 2);
          const sysremCurveIntersectionWithPower = findSystemCurveIntersection(k, powerSpline);
          if (sysremCurveIntersectionWithPower && pressure > sysremCurveIntersectionWithPower.y) {
            return results;
          }
        }

        const distance = Math.sqrt(Math.pow(airFlow - x1, 2) + Math.pow(pressure - y1, 2));

        results.push({ standardFan: sf, distance: distance });
      }
      return results;
    }, [] as Array<StandardFanDistance>)
    .sort((a, b) => a.distance - b.distance);

  return standardFansByDistanceToWorkingPoint[0]?.standardFan;
}

interface WorkingPoint {
  readonly airFlow: number | undefined;
  readonly pressure: number | undefined;
  readonly curve1SpeedSpline: Interpolation.Spline | undefined;
  readonly curve2SpeedSpline: Interpolation.Spline | undefined;
  readonly ratio: number | undefined;
}

function calculateWorkingPoint(
  curves: ReadonlyArray<BoxFanCurve>,
  fanSpeed: number,
  configurableFan: boolean,
  desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  powerCurve: BoxFanCurve | undefined,
  calcParams: PropertyValueSet.PropertyValueSet,
  accessoryPressureDropTable: QP.AccessoryPressureDropTable
): WorkingPoint {
  const [curve1, curve2] = getClosestSpeedCurves(curves, fanSpeed);

  if (!curve1 || !curve2) {
    return {
      airFlow: undefined,
      pressure: undefined,
      curve1SpeedSpline: undefined,
      curve2SpeedSpline: undefined,
      ratio: undefined,
    };
  }

  const curve1Speed = parseFloat(curve1.name);
  const curve2Speed = parseFloat(curve2.name);

  const ratio = (curve2Speed - curve1Speed) / (fanSpeed - curve1Speed);

  const curve1SpeedSpline = Interpolation.splineCreateFromPoints(curve1.points);
  const curve2SpeedSpline = Interpolation.splineCreateFromPoints(curve2.points);
  if (!desiredAirFlow || !desiredExternalPressure) {
    return {
      airFlow: undefined,
      pressure: undefined,
      curve1SpeedSpline: curve1SpeedSpline,
      curve2SpeedSpline: curve2SpeedSpline,
      ratio: ratio,
    };
  }

  const airflowls = Amount.valueAs(Units.LiterPerSecond, desiredAirFlow);
  const externalPressureP = Amount.valueAs(Units.Pascal, desiredExternalPressure);
  const k = externalPressureP / Math.pow(airflowls, 2);
  const curve1Point = Interpolation.findSystemCurveIntersection(k, curve1SpeedSpline);
  const curve2Point = Interpolation.findSystemCurveIntersection(k, curve2SpeedSpline);
  if (!curve1Point || !curve2Point) {
    return {
      airFlow: undefined,
      pressure: undefined,
      curve1SpeedSpline: curve1SpeedSpline,
      curve2SpeedSpline: curve2SpeedSpline,
      ratio: ratio,
    };
  }

  const actualAirFlow = configurableFan ? airflowls : curve1Point.x + (curve2Point.x - curve1Point.x) / ratio;

  const actualPressure = configurableFan ? externalPressureP : k * Math.pow(actualAirFlow, 2);

  if (!configurableFan && powerCurve) {
    const powerSpline = Interpolation.splineCreateFromPoints(powerCurve.points);
    const interSect = findSystemCurveIntersection(k, powerSpline);

    if (interSect && actualPressure > interSect.y) {
      return {
        airFlow: undefined,
        pressure: undefined,
        curve1SpeedSpline: curve1SpeedSpline,
        curve2SpeedSpline: curve2SpeedSpline,
        ratio: ratio,
      };
    }
  }

  return {
    airFlow: actualAirFlow,
    pressure: adjustPressureDropForAccessory(actualPressure, calcParams, accessoryPressureDropTable, configurableFan),
    curve1SpeedSpline: curve1SpeedSpline,
    curve2SpeedSpline: curve2SpeedSpline,
    ratio: ratio,
  };
}

function adjustPressureDropForAccessory(
  pressurePa: number,
  calcParams: PropertyValueSet.PropertyValueSet,
  accessoryPressureDropTable: QP.AccessoryPressureDropTable,
  configurableFan: boolean
): number {
  if (!configurableFan) {
    return pressurePa;
  }
  const filteredRows = accessoryPressureDropTable.filter((r) => PropertyFilter.isValid(calcParams, r.property_filter));

  return filteredRows.reduce((sum, current) => {
    const additionalPressureDrop = Amount.valueAs(Units.Pascal, current.pressure_drop);
    return sum + additionalPressureDrop;
  }, pressurePa);
}

// TODO Add table on ganymed_searc_boxfan where propertyfilter maps to item name

function getAccessoriesList(input: Input): ReadonlyArray<string> | undefined {
  const accessories = input.queryResultMap.ct_AccessoryPressureDrop
    .filter((a) => PropertyFilter.isValid(input.calcParams, a.property_filter))
    .map((a) => a.name);
  if (accessories.length === 0) {
    return undefined;
  }
  return accessories;
}

function getClosestSpeedCurves(
  curves: ReadonlyArray<BoxFanCurve>,
  fanSpeed: number
): readonly [BoxFanCurve, BoxFanCurve] {
  const speedCurves = [...curves].sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10)); // Speed curves
  const higherCurveIndex = speedCurves.findIndex((s) => parseInt(s.name, 10) >= fanSpeed);
  if (higherCurveIndex === 0) {
    // No lower curve exists
    // Use lowest to curves to interpolate "between"
    return [speedCurves[0], speedCurves[1]];
  } else if (higherCurveIndex === -1) {
    // No higher curve exists
    // Use highest curves to interpolate "between"
    return [speedCurves[speedCurves.length - 2], speedCurves[speedCurves.length - 1]];
  } else {
    return [speedCurves[higherCurveIndex - 1], speedCurves[higherCurveIndex]];
  }
}

function generateActualRpmCurve(
  curve1SpeedSpline: Spline,
  curve2SpeedSpline: Spline,
  ratio: number,
  fanSpeed: number,
  powerCurve: BoxFanCurve | undefined
): BoxFanCurve {
  const points = [];

  const lowX = curve1SpeedSpline.xMin;
  const highX = curve1SpeedSpline.xMax;
  const pointDistX = (highX - lowX) / 50;

  const powerSpline = powerCurve ? Interpolation.splineCreateFromPoints(powerCurve.points) : undefined;

  let x = lowX;
  while (x <= highX) {
    const y = splineGetPoint(x, curve1SpeedSpline);
    if (y) {
      const k = y / Math.pow(x, 2);
      let highPoint = findSystemCurveIntersection(k, curve2SpeedSpline);

      if (!highPoint && x === lowX) {
        highPoint = { x: curve2SpeedSpline.xMin, y: splineGetPoint(curve2SpeedSpline.xMin, curve2SpeedSpline)! };
      }

      if (highPoint) {
        const actualPoint = {
          x: x + (highPoint.x - x) / ratio,
          y: y + (highPoint.y - y) / ratio,
        };

        if (powerSpline) {
          const sysremCurveIntersectionWithPower = findSystemCurveIntersection(k, powerSpline);
          if (sysremCurveIntersectionWithPower && actualPoint.y > sysremCurveIntersectionWithPower.y) {
            break;
          }
        }

        points.push(actualPoint);
      }
    }
    x += pointDistX;
  }
  return {
    name: fanSpeed.toString(),
    points: points,
    unitX: Units.LiterPerSecond,
    unitY: Units.Pascal,
  };
}
