import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { WaterCoil} from "../result-items-types";
import { createWaterCoilResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<WaterCoil>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createWaterCoilResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
