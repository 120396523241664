/* eslint-disable no-restricted-globals */
/* eslint-disable functional/no-this-expression */
/* eslint-disable functional/no-class */
import * as React from "react";
import * as ReactDOM from "react-dom";

interface Props {
  readonly value: number;
  readonly onChange: (value: number) => void;
  readonly label?: string;
  readonly disabled?: boolean;
}

interface State {
  readonly value: string;
}

export class PositiveNumberfield extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value.toString(),
    };
  }

  componentDidMount(): void {
    const DOMNode = ReactDOM.findDOMNode(this);
    if (DOMNode) {
      DOMNode.addEventListener("mouseup", (e) => {
        e.stopPropagation();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.setState({
      value: nextProps.value.toString(),
    });
  }

  onChangeHandler(e: React.FormEvent<HTMLInputElement>): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    const numberValue = parseFloat(target.value);
    if (!isNaN(numberValue) && numberValue > -1) {
      this.setState({
        value: target.value,
      });
      this.props.onChange(numberValue);
    }
  }

  render(): JSX.Element {
    const { value } = this.state;

    return (
      <label>
        {this.props.label && <span>{this.props.label}</span>}
        <input
          type="number"
          className="form-input max-w-80 min-w-52 p-4 pl-8 h-36 w-full"
          disabled={this.props.disabled}
          value={value}
          onChange={(e) => this.onChangeHandler(e)}
        />
      </label>
    );
  }
}
