/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
import type * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import type { Quantity } from "uom-units";
import { Units } from "uom-units";
import * as R from "ramda";
import type {
  SoundResult,
  OctaveBands,
  OctaveBands3rd,
  FanAirResult,
  SpeedControl,
  WorkingPoint,
  Curve,
} from "../result-items-types";
import * as AirDensity from "../shared/air-density";
import * as IP from "./interpolation";
import { getSolutionValue } from "./solution";
import { createDataPointCurves } from "./curve";
import { calculateKFactor } from "./k-factor";

export function calcSound(
  speedControl: SpeedControl,
  desiredAirFlow: Amount.Amount<Quantity.VolumeFlow> | undefined,
  desiredExternalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  airDensity: Amount.Amount<Quantity.Density> | undefined,
  fanResult: FanAirResult,
  soundDataTable: ReadonlyArray<QP.SoundData>,
  soundQuantity: QP.SoundQuantity,
  fixedCurveId: string | undefined,
  impellerDiameterMM?: number,
  numberOfFanBlades?: number
): SoundResult {
  const soundDataRaw = soundDataTable.filter((r) => r.sound_quantity === soundQuantity);
  if (!fanResult.workingPoint || soundDataRaw.length === 0) {
    return {};
  }
  // any qv or dp in the measurement that is = 0, we set to 0,000000000000001 to prevent division by 0
  const soundData = soundDataRaw
    .reduce<Array<QP.SoundData>>((sofar, point) => {
      const Q_v = point.Q_v === 0 ? 0.000000000000000000001 : point.Q_v;
      const Ps_v = point.Ps_v === 0 ? 0.000000000000000000001 : point.Ps_v;
      sofar.push({ ...point, Q_v, Ps_v });
      return sofar;
    }, [])
    .sort((a, b) => a.Q_v - b.Q_v);

  const desiredAirFlowLps = desiredAirFlow && Amount.valueAs(Units.LiterPerSecond, desiredAirFlow);

  const requiredPressureByDensity = AirDensity.calculateExternalPressureWithDensity(
    desiredExternalPressure,
    airDensity
  );
  const requiredPressureByDensityPa =
    requiredPressureByDensity && Amount.valueAs(Units.Pascal, requiredPressureByDensity);

  const workingPoint =
    desiredAirFlowLps && requiredPressureByDensityPa
      ? findSoundWorkingPoint(
          speedControl,
          desiredAirFlowLps,
          requiredPressureByDensityPa,
          soundData,
          fanResult,
          fixedCurveId
        )
      : undefined;

  let octaveBands3rd: OctaveBands3rd | undefined = undefined;

  if (!workingPoint) {
    return {
      octaveBands: undefined,
      octaveBandsA: undefined,
      octaveBands3rd: undefined,
      octaveBands3rdA: undefined,
    };
  }

  if (workingPoint.solution.type === "Curve") {
    // the working point sits on one curve, use curve to calculate the sound
    const curve = soundData.filter((sd) => sd.step_name === workingPoint.curveId);

    const rpm = getSolutionValue(fanResult.rpmCurves, fanResult.workingPoint.solution);

    octaveBands3rd = calculateSoundForCurve(
      curve,
      workingPoint.point,
      true,
      fanResult.fanSpeed ? Amount.valueAs(Units.RevolutionsPerMinute, fanResult.fanSpeed) : undefined,
      impellerDiameterMM,
      numberOfFanBlades,
      rpm
    );
  } else {
    // WorkingPoint is between two curves
    // Calculate the sound for the closest curves
    const lower = workingPoint.solution.lower;
    const higher = workingPoint.solution.higher;

    if (lower.type === "Interpolated" || higher.type === "Interpolated") {
      return {};
    }

    const curveLower = soundData.filter((sd) => sd.step_name === lower.curveId);
    const curveHigher = soundData.filter((sd) => sd.step_name === higher.curveId);
    const lowerInterSection = workingPoint.solution.lower.point;
    const higherIntersection = workingPoint.solution.higher.point;

    const lowerRpm = getSolutionValue(fanResult.rpmCurves, lower);
    const higherRpm = getSolutionValue(fanResult.rpmCurves, higher);

    const lowerSound = calculateSoundForCurve(
      curveLower,
      lowerInterSection,
      false,
      fanResult.fanSpeed ? Amount.valueAs(Units.RevolutionsPerMinute, fanResult.fanSpeed) : undefined,
      impellerDiameterMM,
      numberOfFanBlades,
      lowerRpm
    );

    const higherSound = calculateSoundForCurve(
      curveHigher,
      higherIntersection,
      false,
      fanResult.fanSpeed ? Amount.valueAs(Units.RevolutionsPerMinute, fanResult.fanSpeed) : undefined,
      impellerDiameterMM,
      numberOfFanBlades,
      higherRpm
    );
    octaveBands3rd = calculateSoundBetweenCurves(
      lowerSound,
      higherSound,
      fanResult,
      fanResult.fanSpeed ? Amount.valueAs(Units.RevolutionsPerMinute, fanResult.fanSpeed) : undefined,
      impellerDiameterMM
    );
  }

  const octaveBands3rdA = aWeightOctaveBands3rd(octaveBands3rd);
  const octaveBands = calcOctaveBandsFrom3rds(octaveBands3rd);
  const octaveBandsA = calcOctaveBandsFrom3rds(octaveBands3rdA);
  return {
    octaveBands: octaveBands,
    octaveBandsA: octaveBandsA,
    octaveBands3rd: octaveBands3rd,
    octaveBands3rdA: octaveBands3rdA,
  };
}

function findWorkingPointNoSpeedControl(k: number, curves: ReadonlyArray<Curve>): WorkingPoint | undefined {
  const maxCurve = curves[curves.length - 1];

  const point = IP.findSystemCurveIntersection(k, maxCurve.spline); // TODO SHOULD USE const qv = Math.sqrt(ps * k) / k;
  if (!point || point.x < maxCurve.workMin || point.x > maxCurve.workMax) {
    return undefined;
  }
  return {
    point: point,
    controlVoltage: maxCurve.controlVoltage,
    supplyVoltage: maxCurve.supplyVoltage,
    controlFrequency: maxCurve.controlFrequency,
    curveId: maxCurve.id,
    curveIndex: curves.length - 1,
    solution: {
      type: "Curve",
      curveId: maxCurve.id,
      point: point,
      ratio: 1,
    },
  };
}

function findWorkingPointSteplessSpeedControl(
  k: number,
  x: number,
  y: number,
  curves: ReadonlyArray<Curve>,
  soundData: ReadonlyArray<QP.SoundData>,
  fanAirResult: FanAirResult | undefined,
  airAndSoundSameStep: boolean
): WorkingPoint | undefined {
  const workingFanSpeed =
    fanAirResult?.fanSpeed !== undefined
      ? Amount.valueAs(Units.RevolutionsPerMinute, fanAirResult.fanSpeed)
      : undefined;
  if (curves.length === 1 && workingFanSpeed !== undefined) {
    // Only one curve for the sound exists, calculate intersection using K-factor
    const curve = soundData[0];
    const ps = y * Math.pow(curve.r_v / workingFanSpeed, 2);

    const qv = Math.sqrt(ps * k) / k;

    return {
      point: { x: qv, y: ps },
      controlVoltage: curve.voltage_control,
      supplyVoltage: curve.voltage_control,
      controlFrequency: curve.frequency_control,
      curveId: curve.step_name,
      curveIndex: 0,
      solution: { type: "Curve", curveId: curve.step_name, point: { x: qv, y: ps }, ratio: 1 },
    };
  }

  /// More than one curve

  // If air and sound have the same amount of steps(curves), use the fan selected curves...to create a working point
  if (airAndSoundSameStep && fanAirResult?.workingPoint) {
    // Use the same curves as the fan air calculation did:
    // TODO, risk of not having the same stepnames!, need to sort or similar

    // We can get curve here (if the user is above or below the biggest or smallest curve)
    if (fanAirResult.workingPoint.solution.type === "Curve") {
      return fanAirResult.workingPoint;
    }

    const lower = fanAirResult.workingPoint.solution.lower;
    const higher = fanAirResult.workingPoint.solution.higher;

    if (lower.type === "Interpolated" || higher.type === "Interpolated") {
      return undefined;
    }

    const curveLower = soundData.filter((sd) => sd.step_name === lower.curveId);
    const curveHigher = soundData.filter((sd) => sd.step_name === higher.curveId);

    const pressureLower = fanAirResult.pressureCurves.find((a) => a.id === lower.curveId);
    const pressureHigher = fanAirResult.pressureCurves.find((a) => a.id === higher.curveId);

    if (!pressureLower || !pressureHigher) {
      return undefined;
    }

    // Todo need to have som margin....and not expect point to be excatly on curve
    const soundPointsExistOnLowerCurve = true; // curveLower.every((p) => IP.splineGetPoint(p.Q_v,pressureLower.spline) !== undefined);
    const soundPointsExistOnHigherCurve = true; // curveHigher.every((p) => IP.splineGetPoint(p.Q_v,pressureHigher.spline) !== undefined);
    // Ensure all the sound points are located on the airlines
    // We have cases where the sound and air have the same amount of steps, but they are not on the same curves

    if (curveLower && curveHigher && soundPointsExistOnHigherCurve && soundPointsExistOnLowerCurve) {
      return fanAirResult.workingPoint;
    }
  }

  // the sound and air measurements are not (not the same amount of steps (curves)) matching, we have to use the soundcurves

  if (workingFanSpeed === undefined) {
    return undefined;
  }

  // 1 Group by stepname
  const groupedCurves = R.groupBy((s) => s.step_name, soundData);
  // 2 calculate average for N

  // 3 Calculate q and ps for each curve given Operating K-Factor (intersections)

  const interSections: Array<{ step: string; qv: number; ps: number }> = [];
  for (const step of Object.keys(groupedCurves)) {
    const points = groupedCurves[step];

    const avgRpm = points.reduce((sofar, curr) => sofar + curr.r_v, 0) / points.length;
    const ps = y * Math.pow(avgRpm / workingFanSpeed, 2);
    const qv = Math.sqrt(ps * k) / k;
    interSections.push({ step, qv, ps });
  }

  const sortedIntersections = interSections.sort((a, b) => a.qv - b.qv);

  const curveAbove = sortedIntersections.find((c) => c.qv >= x);
  const curveBelow = sortedIntersections.reverse().find((c) => c.qv <= x);

  console.log(curveAbove?.step, curveBelow?.step);

  // 4 find 2 closest curves

  // 5 calculate ratio
  // Multiple sound curves exists, use K-Factor to find closest curves and then create fake working points

  return undefined;
}

function findWorkingPointSteppedSpeedControl(
  k: number,
  _x: number,
  y: number,
  curves: ReadonlyArray<Curve>,
  fixedCurveId: string | undefined,
  soundData: ReadonlyArray<QP.SoundData>,
  fanAirResult: FanAirResult | undefined,
  airAndSoundSameStep: boolean
): WorkingPoint | undefined {
  // Need to find the curve to use
  // 1. Only one curve
  // 2. Fixed curve id
  // 3. Multiple curves -> find the one above or highest, closest to our point
  const fixedCurveIx = curves.findIndex((c) => c.id === fixedCurveId);

  // Fixed Curve
  if (fixedCurveIx >= 0) {
    const fixedCurve = curves[fixedCurveIx];

    const fixedPoint = IP.findSystemCurveIntersection(k, fixedCurve.spline); // Todo same problem as below
    if (fixedPoint === undefined) {
      return undefined;
    }

    return {
      point: fixedPoint,
      controlVoltage: fixedCurve.controlVoltage,
      supplyVoltage: fixedCurve.supplyVoltage,
      controlFrequency: fixedCurve.controlFrequency,
      curveId: fixedCurve.id,
      curveIndex: fixedCurveIx,
      solution: {
        type: "Curve",
        curveId: fixedCurve.id,
        point: fixedPoint,
        ratio: 1,
      },
    };
  }

  // If we only have one curve, use it:
  const workingFanSpeed =
    fanAirResult?.fanSpeed !== undefined
      ? Amount.valueAs(Units.RevolutionsPerMinute, fanAirResult.fanSpeed)
      : undefined;
  if (curves.length === 1 && workingFanSpeed !== undefined) {
    // Only one curve for the sound exists, calculate intersection using K-factor
    const curve = soundData[0];
    const ps = y * Math.pow(curve.r_v / workingFanSpeed, 2);

    const qv = Math.sqrt(ps * k) / k;

    return {
      point: { x: qv, y: ps },
      controlVoltage: curve.voltage_control,
      supplyVoltage: curve.voltage_control,
      controlFrequency: curve.frequency_control,
      curveId: curve.step_name,
      curveIndex: 0,
      solution: { type: "Curve", curveId: curve.step_name, point: { x: qv, y: ps }, ratio: 1 },
    };
  }

  ////////////////////////////

  // IF SOUND AND AIR HAVE THE SAME STEPS
  if (airAndSoundSameStep && fanAirResult?.workingPoint) {
    // Use the same curves as the fan air calculation did:
    return fanAirResult.workingPoint;
  }

  // TODO Problem sound and air do not match, must use sound curves to get working point

  // Find closest curve above input airflow and pressure or the closest one if none above exists
  // We might have issues where the air measurement is ok with airflow from 0 to 7000 l/s
  // but the sound measurement only contains two points in 500 l/s and 6000 l/s, this will cause the intersection calculation to fail.

  // const curvesWithPoint = curves.map((c, i) => {

  //   const curve = soundData[0];
  //   const ps = y * Math.pow(curve.r_v / workingFanSpeed, 2);

  //   const qv = Math.sqrt(ps * k) / k;

  //   return {
  //   curve: c,
  //   index: i,
  //   point: IP.findSystemCurveIntersection(k, c.spline),
  //   }
  // });
  // const { point, curve, index } =
  //   curvesWithPoint
  //     .filter((r) => !!r.point)
  //     .sort((a, b) => a.point!.x - b.point!.x)
  //     .find((r) => !!r.point && r.point.x > x) || curvesWithPoint[curvesWithPoint.length - 1];
  // if (!point || point.x < curve.workMin || point.x > curve.workMax) {
  //   return undefined;
  // }
  // return {
  //   point: point,
  //   controlVoltage: curve.controlVoltage,
  //   supplyVoltage: curve.supplyVoltage,
  //   curveId: curve.id,
  //   curveIndex: index,
  //   solution: {
  //     type: "Curve",
  //     curveId: curve.id,
  //     point: point,
  //     ratio: 1,
  //   },
  // };

  return undefined;
}

function findSoundWorkingPoint(
  speedControl: SpeedControl,
  x: number,
  y: number,
  soundData: ReadonlyArray<QP.SoundData>,
  fanAirResult: FanAirResult | undefined,
  fixedCurveId?: string
): WorkingPoint | undefined {
  if (soundData.length === 0) {
    return undefined;
  }

  const soundAirData: ReadonlyArray<QP.AirData> = soundData.map((r) => ({
    meas: r.measurement,
    part: "Main" as QP.AirDataPart,
    param: "Ps_v" as QP.AirDataParam,
    step: r.step_name,
    volt_ctrl: r.voltage_control,
    volt_supp: r.voltage_control,
    freq_ctrl: r.frequency_control,
    flow: r.Q_v,
    value: r.Ps_v,
  }));

  const soundPressureCurves = createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    soundAirData.filter((p) => p.param === "Ps_v"),
    []
  );

  // Build system curve
  const k = calculateKFactor(x, y);

  switch (speedControl) {
    case "None":
      return findWorkingPointNoSpeedControl(k, soundPressureCurves);
    case "Stepless":
      return findWorkingPointSteplessSpeedControl(
        k,
        x,
        y,
        soundPressureCurves,
        soundData,
        fanAirResult,
        fanAirResult?.pressureCurves.length === soundPressureCurves.length
      );
    case "Transformer":
      return findWorkingPointSteppedSpeedControl(
        k,
        x,
        y,
        soundPressureCurves,
        fixedCurveId,
        soundData,
        fanAirResult,
        fanAirResult?.pressureCurves.length === soundPressureCurves.length
      );
    default:
      return undefined; // Unknown Speedcontrol
  }
}

function calculateSoundBetweenTwoCurves(
  lwLower: number | undefined,
  lwHigher: number | undefined,
  rpmRatio: number,
  fanSpeedRpm?: number,
  impellerDiameterMM?: number
): number | undefined {
  if (lwLower === undefined || lwHigher === undefined) {
    return undefined;
  }

  const lw = lwHigher + rpmRatio * (lwLower - lwHigher);
  if (fanSpeedRpm === undefined || impellerDiameterMM === undefined) {
    return lw;
  }
  return (
    lw + 50 * Math.log10(fanSpeedRpm / AMCA_STANDARD_RPM) + 70 * Math.log10(impellerDiameterMM / AMCA_STANDARD_DIAMETER)
  );
}

function calculateSoundBetweenCurves(
  octaveBands3rdLower: OctaveBands3rd | undefined,
  octaveBands3rdHigher: OctaveBands3rd | undefined,
  fanairResult: FanAirResult,
  fanSpeedRpm?: number,
  impellerDiameterMM?: number
): OctaveBands3rd | undefined {
  if (fanairResult.fanSpeed === undefined || octaveBands3rdLower === undefined || octaveBands3rdHigher === undefined) {
    return undefined;
  }

  const a = fanairResult.workingPoint?.solution;

  if (a === undefined || a.type !== "Interpolated") {
    return undefined;
  }

  const workingRpm = Amount.valueAs(Units.RevolutionsPerMinute, fanairResult.fanSpeed);
  const lowerRpm = getSolutionValue(fanairResult.rpmCurves, a.lower);
  const higherRpm = getSolutionValue(fanairResult.rpmCurves, a.higher);

  if (lowerRpm === undefined || higherRpm === undefined) {
    return undefined;
  }

  const rpmRatio = (workingRpm - higherRpm) / (lowerRpm - higherRpm);

  const hz50 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz50,
    octaveBands3rdHigher.hz50,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz63 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz63,
    octaveBands3rdHigher.hz63,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz80 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz80,
    octaveBands3rdHigher.hz80,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz100 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz100,
    octaveBands3rdHigher.hz100,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz125 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz125,
    octaveBands3rdHigher.hz125,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz160 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz160,
    octaveBands3rdHigher.hz160,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz200 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz200,
    octaveBands3rdHigher.hz200,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz250 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz250,
    octaveBands3rdHigher.hz250,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz315 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz315,
    octaveBands3rdHigher.hz315,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz400 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz400,
    octaveBands3rdHigher.hz400,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz500 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz500,
    octaveBands3rdHigher.hz500,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz630 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz630,
    octaveBands3rdHigher.hz630,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz800 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz800,
    octaveBands3rdHigher.hz800,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz1000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz1000,
    octaveBands3rdHigher.hz1000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz1250 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz1250,
    octaveBands3rdHigher.hz1250,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz1600 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz1600,
    octaveBands3rdHigher.hz1600,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz2000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz2000,
    octaveBands3rdHigher.hz2000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz2500 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz2500,
    octaveBands3rdHigher.hz2500,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz3150 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz3150,
    octaveBands3rdHigher.hz3150,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz4000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz4000,
    octaveBands3rdHigher.hz4000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz5000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz5000,
    octaveBands3rdHigher.hz5000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  const hz6300 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz6300,
    octaveBands3rdHigher.hz6300,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz8000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz8000,
    octaveBands3rdHigher.hz8000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );
  const hz10000 = calculateSoundBetweenTwoCurves(
    octaveBands3rdLower.hz10000,
    octaveBands3rdHigher.hz10000,
    rpmRatio,
    fanSpeedRpm,
    impellerDiameterMM
  );

  return createOctaveBands3rd(
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000
  );
}

interface BandAndValue {
  readonly freqBand: number;
  readonly value: number;
  readonly xp?: number;
}
function soundPointToArray(point: QP.SoundData): ReadonlyArray<BandAndValue> {
  const arr: Array<BandAndValue> = [];

  arr.push({ freqBand: 50, value: point.snd_50 });
  arr.push({ freqBand: 63, value: point.snd_63 });
  arr.push({ freqBand: 80, value: point.snd_80 });
  arr.push({ freqBand: 100, value: point.snd_100 });
  arr.push({ freqBand: 125, value: point.snd_125 });
  arr.push({ freqBand: 160, value: point.snd_160 });
  arr.push({ freqBand: 200, value: point.snd_200 });
  arr.push({ freqBand: 250, value: point.snd_250 });
  arr.push({ freqBand: 315, value: point.snd_315 });
  arr.push({ freqBand: 400, value: point.snd_400 });
  arr.push({ freqBand: 500, value: point.snd_500 });
  arr.push({ freqBand: 630, value: point.snd_630 });
  arr.push({ freqBand: 800, value: point.snd_800 });
  arr.push({ freqBand: 1000, value: point.snd_1000 });
  arr.push({ freqBand: 1250, value: point.snd_1250 });
  arr.push({ freqBand: 1600, value: point.snd_1600 });
  arr.push({ freqBand: 2000, value: point.snd_2000 });
  arr.push({ freqBand: 2500, value: point.snd_2500 });
  arr.push({ freqBand: 3150, value: point.snd_3150 });
  arr.push({ freqBand: 4000, value: point.snd_4000 });
  arr.push({ freqBand: 5000, value: point.snd_5000 });
  arr.push({ freqBand: 6300, value: point.snd_6300 });
  arr.push({ freqBand: 8000, value: point.snd_8000 });
  arr.push({ freqBand: 10000, value: point.snd_10000 });

  return arr;
}

function arrayToSoundPoint(point: ReadonlyArray<number>, originalCurve: QP.SoundData): QP.SoundData {
  return {
    ...originalCurve,
    snd_50: point[0],
    snd_63: point[1],
    snd_80: point[2],
    snd_100: point[3],
    snd_125: point[4],
    snd_160: point[5],
    snd_200: point[6],
    snd_250: point[7],
    snd_315: point[8],
    snd_400: point[9],
    snd_500: point[10],
    snd_630: point[11],
    snd_800: point[12],
    snd_1000: point[13],
    snd_1250: point[14],
    snd_1600: point[15],
    snd_2000: point[16],
    snd_2500: point[17],
    snd_3150: point[18],
    snd_4000: point[19],
    snd_5000: point[20],
    snd_6300: point[21],
    snd_8000: point[22],
    snd_10000: point[23],
  };
}

function generalizedFrequency(freqency: number, fanSpeedRpm: number): number {
  if (freqency === 0) {
    return 0;
  }
  return 10 * Math.log10(freqency / fanSpeedRpm) + 20;
}

// Todo, refactor ans send point instead of qa, qb,lwa,lwb
function calculateSoundOnCurve(
  lwa: number,
  qa: number,
  qb: number | undefined,
  qab: number,
  lwb: number | undefined,
  singleSoundCurve: boolean,
  refPointLw: number | undefined,
  fanSpeedRpm?: number,
  impellerDiameterMM?: number,
  measuredLw?: number
): number {
  if ((qb === undefined || lwb === undefined) && impellerDiameterMM === undefined) {
    return 0;
  }
  const ratio = qb !== undefined ? (qab - qa) / (qb - qa) : qab / qa;

  const lw = lwb !== undefined ? lwa + ratio * (lwb - lwa) : lwa;

  if (!singleSoundCurve) {
    // More than one curve used
    if (fanSpeedRpm && impellerDiameterMM && measuredLw && refPointLw) {
      // BPF
      const amcaLw =
        lw +
        50 * Math.log10(fanSpeedRpm / AMCA_STANDARD_RPM) +
        70 * Math.log10(impellerDiameterMM / AMCA_STANDARD_DIAMETER);

      if (amcaLw < refPointLw) {
        return measuredLw;
      }
      return lw;
    }
    return refPointLw !== undefined && lw < refPointLw ? refPointLw : lw;
  }

  if (refPointLw === undefined && fanSpeedRpm !== undefined && impellerDiameterMM !== undefined) {
    return (
      lw +
      50 * Math.log10(fanSpeedRpm / AMCA_STANDARD_RPM) +
      70 * Math.log10(impellerDiameterMM / AMCA_STANDARD_DIAMETER)
    );
  }

  if (fanSpeedRpm && impellerDiameterMM && measuredLw && refPointLw) {
    const lwAmca =
      lw +
      50 * Math.log10(fanSpeedRpm / AMCA_STANDARD_RPM) +
      70 * Math.log10(impellerDiameterMM / AMCA_STANDARD_DIAMETER);

    if (lwAmca < refPointLw) {
      //measured
      return (
        measuredLw +
        50 * Math.log10(fanSpeedRpm / AMCA_STANDARD_RPM) +
        70 * Math.log10(impellerDiameterMM / AMCA_STANDARD_DIAMETER)
      );
    } else {
      return lwAmca;
    }
  }

  return refPointLw !== undefined && lw < refPointLw ? refPointLw : lw;
}

// Determines what frequency band for a curve, is impacted by the working fan RPM
function getFrequencyImpactedByBladePassFrequency(numberOfFanBlades: number, workingRpm: number): number | undefined {
  const workingBPF = calculateBladePassFrequency(numberOfFanBlades, workingRpm);

  // TODO if workingBPF is excatly 45 or 71 no match will be found
  if (workingBPF > 45 && workingBPF < 56) {
    return 50;
  } else if (workingBPF > 56 && workingBPF < 71) {
    return 63;
  } else if (workingBPF > 71 && workingBPF < 90) {
    return 80;
  } else if (workingBPF > 90 && workingBPF < 112) {
    return 100;
  } else if (workingBPF > 112 && workingBPF < 140) {
    return 125;
  } else if (workingBPF > 140 && workingBPF < 180) {
    return 160;
  } else if (workingBPF > 180 && workingBPF < 224) {
    return 200;
  } else if (workingBPF > 224 && workingBPF < 280) {
    return 250;
  } else if (workingBPF > 280 && workingBPF < 355) {
    return 315;
  } else if (workingBPF > 355 && workingBPF < 450) {
    return 400;
  } else if (workingBPF > 450 && workingBPF < 560) {
    return 500;
  } else if (workingBPF > 560 && workingBPF < 710) {
    return 630;
  } else if (workingBPF > 710 && workingBPF < 900) {
    return 800;
  } else if (workingBPF > 900 && workingBPF < 1120) {
    return 1000;
  } else if (workingBPF > 1120 && workingBPF < 1400) {
    return 1250;
  } else if (workingBPF > 1400 && workingBPF < 1800) {
    return 1600;
  } else if (workingBPF > 1800 && workingBPF < 2240) {
    return 2000;
  } else if (workingBPF > 2240 && workingBPF < 2800) {
    return 2500;
  } else if (workingBPF > 2800 && workingBPF < 3550) {
    return 3150;
  } else if (workingBPF > 3550 && workingBPF < 4500) {
    return 4000;
  } else if (workingBPF > 4500 && workingBPF < 5600) {
    return 5000;
  } else if (workingBPF > 5600 && workingBPF < 7100) {
    return 6300;
  } else if (workingBPF > 7100 && workingBPF < 9000) {
    return 8000;
  } else if (workingBPF > 9000 && workingBPF < 11200) {
    return 10000;
  }

  return undefined;
}

function regressionSlope(point: ReadonlyArray<BandAndValue>, pointFanSpeedRpm: number): BandAndValue {
  // Calculate average LWG for the last 5 points but exclude the last point
  const avgLwg =
    point
      .slice(-6)
      .slice(0, 5)
      .reduce((sofar, curr) => sofar + curr.value, 0) / 5; //ok
  const lastSixPoints = point.slice(-6);
  const lastPoint = lastSixPoints[lastSixPoints.length - 1];

  const xAvg =
    lastSixPoints.reduce((sofar, curr) => sofar + generalizedFrequency(curr.freqBand, pointFanSpeedRpm), 0) /
    lastSixPoints.length;
  const lwgAvg = lastSixPoints.reduce((sofar, curr) => sofar + curr.value, 0) / lastSixPoints.length;
  let tot = 0;
  let mtot = 0;
  for (const p of lastSixPoints) {
    const q = generalizedFrequency(p.freqBand, pointFanSpeedRpm);
    tot += (q - xAvg) * (p.value - lwgAvg);
    mtot += (q - xAvg) * (q - xAvg);
  }

  // m -> Intercept
  // k -> Slope
  // y = kx + m
  const k = tot / mtot;

  const m =
    lastSixPoints.reduce(
      (sofar, curr) => sofar + curr.value - k * generalizedFrequency(curr.freqBand, pointFanSpeedRpm),
      0
    ) / lastSixPoints.length;

  const freq = lastPoint.value < avgLwg ? m / Math.abs(k) + m / k / m : lastPoint.xp! + lastPoint.xp! / 2;
  const sound = lastPoint.value < avgLwg ? 0 : Math.abs(k) * freq + m;

  return {
    xp: freq,
    value: sound,
    freqBand: -1,
  };
}

function amcaAdjust(
  p0: QP.SoundData,
  p1: QP.SoundData | undefined, // can be undefined if the curve only has one soundpoint
  singleSoundCurve: boolean,
  fanSpeedRpm?: number,
  impellerDiameterMM?: number,
  numberOfFanBlades?: number,
  curveFanSpeed?: number
): readonly [QP.SoundData, QP.SoundData | undefined] {
  if (impellerDiameterMM === undefined || numberOfFanBlades === undefined || fanSpeedRpm === undefined) {
    return [p0, p1]; // No adjustments Possible
  }

  return [
    amcaAdjustCurve(p0, impellerDiameterMM, numberOfFanBlades, fanSpeedRpm, singleSoundCurve, curveFanSpeed),
    p1 !== undefined
      ? amcaAdjustCurve(p1, impellerDiameterMM, numberOfFanBlades, fanSpeedRpm, singleSoundCurve, curveFanSpeed)
      : undefined,
  ];
}

function findPointToUse(sortedArr: ReadonlyArray<BandAndValue>, searchFreq: number, _curveRpmSpeed: number): number {
  // find where the freqency falls between.
  for (let i = 0; i < sortedArr.length; i++) {
    if (i + 1 === sortedArr.length) {
      return i - 1;
    }
    if (searchFreq >= sortedArr[i].xp! && searchFreq <= sortedArr[i + 1].xp!) {
      return i;
    }
  }
  return 0;
}

const freqBandx = [
  50,
  63,
  80,
  100,
  125,
  160,
  200,
  250,
  315,
  400,
  500,
  630,
  800,
  1000,
  1250,
  1600,
  2000,
  2500,
  3150,
  4000,
  5000,
  6300,
  8000,
  10000,
];

function amcaAdjustCurve(
  curve: QP.SoundData,
  impellerDiameterMM: number,
  numberOfFanBlades: number,
  workingFanSpeedRpm: number,
  singleSoundCurve?: boolean,
  curveSpeed?: number
): QP.SoundData {
  const arr = soundPointToArray(curve);

  const lwgArr = arr.map((v) => ({
    xp: generalizedFrequency(v.freqBand, curve.r_v),
    ...calculateGeneralizedSoundPowerLevel(v, curve.r_v, impellerDiameterMM),
  }));
  const lwgArrSorted = [...lwgArr].sort((a, b) => a.xp - b.xp);

  const bpf = calculateBladePassFrequency(numberOfFanBlades, curve.r_v);
  const impactedFrequency = getFrequencyImpactedByBladePassFrequency(numberOfFanBlades, curve.r_v);

  const bpfval = lwgArrSorted.find((c) => c.freqBand === impactedFrequency);
  // if outside frequencies
  const bpfReal =
    bpfval !== undefined
      ? bpfval.value
      : bpf > 11200
      ? lwgArrSorted[lwgArrSorted.length - 1].value
      : lwgArrSorted[0].value;

  const extrapSlope = regressionSlope(lwgArrSorted, curve.r_v);
  lwgArrSorted.push({ xp: generalizedFrequency(bpf, curve.r_v), freqBand: -1, value: bpfReal });
  lwgArrSorted.sort((a, b) => a.xp - b.xp);

  lwgArrSorted.unshift({ xp: 0, freqBand: 0, value: lwgArrSorted[0].value });
  lwgArrSorted.push({ xp: extrapSlope.xp!, ...extrapSlope });

  // console.table(lwgArrSorted)
  // [7]
  // console.log("[7]")
  // console.table(lwgArrSorted);

  // Extrapolate
  // console.log();

  const impactingFrequency = getFrequencyImpactedByBladePassFrequency(numberOfFanBlades, workingFanSpeedRpm);
  const adjusted: Array<number> = [];
  for (const freqBand of freqBandx) {
    const currentFreq = freqBand;
    const genFreq =
      singleSoundCurve || curveSpeed === undefined
        ? generalizedFrequency(currentFreq, workingFanSpeedRpm)
        : generalizedFrequency(currentFreq, curveSpeed);

    // Need to find correct value to use given the current frequency
    const index = findPointToUse(lwgArrSorted, genFreq, curve.r_v);

    const pointA = index === 0 ? 0 : index;
    const pointB = index + 1;

    const XP00 = lwgArrSorted[pointA].xp;
    const XP01 = lwgArrSorted[pointB].xp;
    const lwgP0 = lwgArrSorted[pointA].value;
    const lwgP1 = lwgArrSorted[pointB].value;

    let LwgIP0 = ((lwgP1 - lwgP0) / (XP01 - XP00)) * (genFreq - XP00) + lwgP0;
    // Check if this freqency is impacted by BFG and take the largest value
    if (currentFreq === impactingFrequency) {
      LwgIP0 = Math.max(...[LwgIP0, bpfReal]);
    }

    adjusted.push(LwgIP0);
  }

  return arrayToSoundPoint(adjusted, curve);
}

function calculateSoundForCurve(
  soundData: ReadonlyArray<QP.SoundData>,
  interSection: IP.Vec2,
  singleSoundCurve: boolean,
  fanSpeedRpm?: number,
  impellerDiameterMM?: number,
  numberOfFanBlades?: number,
  intersectionRpm?: number
): OctaveBands3rd | undefined {
  const result = closestPointsOnCurve(interSection, soundData); // Todo, rename pointstatus for clarity
  if (result === undefined) {
    return undefined;
  }
  const [p0s, p1s, pointStatus] = result;
  if (p0s === undefined || (p1s === undefined && pointStatus !== "onepoint")) {
    console.log("UNDEFINED", p0s, p1s);
    console.log(soundData.length);
  }

  // P1 can be undefined if the curve only has one point
  const [p0, p1] = amcaAdjust(
    p0s,
    p1s,
    singleSoundCurve,
    fanSpeedRpm,
    impellerDiameterMM,
    numberOfFanBlades,
    intersectionRpm
  );

  const qa = p0.Q_v;
  const qb = p1?.Q_v;
  const qab = interSection.x;

  const referencePoint = pointStatus === "between" ? undefined : pointStatus === "left" ? p1s : p0s;

  const hz50 = calculateSoundOnCurve(
    p0.snd_50,
    qa,
    qb,
    qab,
    p1?.snd_50,
    singleSoundCurve,
    referencePoint?.snd_50,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 50, value: referencePoint.snd_50 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz63 = calculateSoundOnCurve(
    p0.snd_63,
    qa,
    qb,
    qab,
    p1?.snd_63,
    singleSoundCurve,
    referencePoint?.snd_63,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 63, value: referencePoint.snd_63 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz80 = calculateSoundOnCurve(
    p0.snd_80,
    qa,
    qb,
    qab,
    p1?.snd_80,
    singleSoundCurve,
    referencePoint?.snd_80,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 80, value: referencePoint.snd_80 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz100 = calculateSoundOnCurve(
    p0.snd_100,
    qa,
    qb,
    qab,
    p1?.snd_100,
    singleSoundCurve,
    referencePoint?.snd_100,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 100, value: referencePoint.snd_100 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz125 = calculateSoundOnCurve(
    p0.snd_125,
    qa,
    qb,
    qab,
    p1?.snd_125,
    singleSoundCurve,
    referencePoint?.snd_125,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 125, value: referencePoint.snd_125 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz160 = calculateSoundOnCurve(
    p0.snd_160,
    qa,
    qb,
    qab,
    p1?.snd_160,
    singleSoundCurve,
    referencePoint?.snd_160,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 160, value: referencePoint.snd_160 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz200 = calculateSoundOnCurve(
    p0.snd_200,
    qa,
    qb,
    qab,
    p1?.snd_200,
    singleSoundCurve,
    referencePoint?.snd_200,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 200, value: referencePoint.snd_200 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz250 = calculateSoundOnCurve(
    p0.snd_250,
    qa,
    qb,
    qab,
    p1?.snd_250,
    singleSoundCurve,
    referencePoint?.snd_250,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 250, value: referencePoint.snd_250 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz315 = calculateSoundOnCurve(
    p0.snd_315,
    qa,
    qb,
    qab,
    p1?.snd_315,
    singleSoundCurve,
    referencePoint?.snd_315,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 315, value: referencePoint.snd_315 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz400 = calculateSoundOnCurve(
    p0.snd_400,
    qa,
    qb,
    qab,
    p1?.snd_400,
    singleSoundCurve,
    referencePoint?.snd_400,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 400, value: referencePoint.snd_400 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz500 = calculateSoundOnCurve(
    p0.snd_500,
    qa,
    qb,
    qab,
    p1?.snd_500,
    singleSoundCurve,
    referencePoint?.snd_500,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 500, value: referencePoint.snd_500 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz630 = calculateSoundOnCurve(
    p0.snd_630,
    qa,
    qb,
    qab,
    p1?.snd_630,
    singleSoundCurve,
    referencePoint?.snd_630,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 630, value: referencePoint.snd_630 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz800 = calculateSoundOnCurve(
    p0.snd_800,
    qa,
    qb,
    qab,
    p1?.snd_800,
    singleSoundCurve,
    referencePoint?.snd_800,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 800, value: referencePoint.snd_800 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz1000 = calculateSoundOnCurve(
    p0.snd_1000,
    qa,
    qb,
    qab,
    p1?.snd_1000,
    singleSoundCurve,
    referencePoint?.snd_1000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 1000, value: referencePoint.snd_1000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz1250 = calculateSoundOnCurve(
    p0.snd_1250,
    qa,
    qb,
    qab,
    p1?.snd_1250,
    singleSoundCurve,
    referencePoint?.snd_1250,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 1250, value: referencePoint.snd_1250 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz1600 = calculateSoundOnCurve(
    p0.snd_1600,
    qa,
    qb,
    qab,
    p1?.snd_1600,
    singleSoundCurve,
    referencePoint?.snd_1600,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 1600, value: referencePoint.snd_1600 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz2000 = calculateSoundOnCurve(
    p0.snd_2000,
    qa,
    qb,
    qab,
    p1?.snd_2000,
    singleSoundCurve,
    referencePoint?.snd_2000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 2000, value: referencePoint.snd_2000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz2500 = calculateSoundOnCurve(
    p0.snd_2500,
    qa,
    qb,
    qab,
    p1?.snd_2500,
    singleSoundCurve,
    referencePoint?.snd_2500,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 2500, value: referencePoint.snd_2500 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz3150 = calculateSoundOnCurve(
    p0.snd_3150,
    qa,
    qb,
    qab,
    p1?.snd_3150,
    singleSoundCurve,
    referencePoint?.snd_3150,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 3150, value: referencePoint.snd_3150 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz4000 = calculateSoundOnCurve(
    p0.snd_4000,
    qa,
    qb,
    qab,
    p1?.snd_4000,
    singleSoundCurve,
    referencePoint?.snd_4000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 4000, value: referencePoint.snd_4000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz5000 = calculateSoundOnCurve(
    p0.snd_5000,
    qa,
    qb,
    qab,
    p1?.snd_5000,
    singleSoundCurve,
    referencePoint?.snd_5000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 5000, value: referencePoint.snd_5000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  const hz6300 = calculateSoundOnCurve(
    p0.snd_6300,
    qa,
    qb,
    qab,
    p1?.snd_6300,
    singleSoundCurve,
    referencePoint?.snd_6300,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 6300, value: referencePoint.snd_6300 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz8000 = calculateSoundOnCurve(
    p0.snd_8000,
    qa,
    qb,
    qab,
    p1?.snd_8000,
    singleSoundCurve,
    referencePoint?.snd_8000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 8000, value: referencePoint.snd_8000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );
  const hz10000 = calculateSoundOnCurve(
    p0.snd_10000,
    qa,
    qb,
    qab,
    p1?.snd_10000,
    singleSoundCurve,
    referencePoint?.snd_10000,
    fanSpeedRpm,
    impellerDiameterMM,
    referencePoint && impellerDiameterMM && numberOfFanBlades
      ? calculateGeneralizedSoundPowerLevel(
          { freqBand: 10000, value: referencePoint.snd_10000 },
          referencePoint?.r_v,
          impellerDiameterMM
        ).value
      : undefined
  );

  return createOctaveBands3rd(
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000
  );
}

function closestPointsOnCurve(
  interSectPoint: IP.Vec2,
  curve: ReadonlyArray<QP.SoundData>
): readonly [QP.SoundData, QP.SoundData | undefined, "between" | "right" | "left" | "onepoint"] | undefined {
  if (curve.length === 0) {
    return undefined; // no point
  }
  if (curve.length === 1) {
    return [curve[0], undefined, "onepoint"];
  }

  const psLeft = curve.filter((p) => p.Q_v < interSectPoint.x);
  const psRight = curve.filter((p) => p.Q_v >= interSectPoint.x);

  if (psLeft.length === 0) {
    // Take the two closest points on the right
    const p1 = psRight[0];
    const p2 = psRight[1];
    return [p1, p2, "right"];
  }
  if (psRight.length === 0) {
    // Take the two closest points on the left
    const p1 = psLeft[psLeft.length - 2];
    const p2 = psLeft[psLeft.length - 1];
    return [p1, p2, "left"];
  } else {
    // between points
    const pLeft = psLeft[psLeft.length - 1];
    const pRight = psRight[0];
    return [pLeft, pRight, "between"];
  }
}

export function aWeightOctaveBands(octaveBands: OctaveBands | OctaveBands3rd | undefined): OctaveBands | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  return createOctaveBands(
    safeAdd(octaveBands.hz63, -26.2),
    safeAdd(octaveBands.hz125, -16.1),
    safeAdd(octaveBands.hz250, -8.6),
    safeAdd(octaveBands.hz500, -3.2),
    safeAdd(octaveBands.hz1000, 0),
    safeAdd(octaveBands.hz2000, 1.2),
    safeAdd(octaveBands.hz4000, 1),
    safeAdd(octaveBands.hz8000, -1.1)
  );
}

export function aWeightOctaveBands3rd(octaveBands3rd: OctaveBands3rd | undefined): OctaveBands3rd | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }

  return createOctaveBands3rd(
    safeAdd(octaveBands3rd.hz50, -30.27),
    safeAdd(octaveBands3rd.hz63, -26.22),
    safeAdd(octaveBands3rd.hz80, -22.4),
    safeAdd(octaveBands3rd.hz100, -19.14),
    safeAdd(octaveBands3rd.hz125, -16.19),
    safeAdd(octaveBands3rd.hz160, -13.24),
    safeAdd(octaveBands3rd.hz200, -10.89),
    safeAdd(octaveBands3rd.hz250, -8.67),
    safeAdd(octaveBands3rd.hz315, -6.64),
    safeAdd(octaveBands3rd.hz400, -4.77),
    safeAdd(octaveBands3rd.hz500, -3.25),
    safeAdd(octaveBands3rd.hz630, -1.91),
    safeAdd(octaveBands3rd.hz800, -0.79),
    safeAdd(octaveBands3rd.hz1000, 0),
    safeAdd(octaveBands3rd.hz1250, 0.58),
    safeAdd(octaveBands3rd.hz1600, 0.99),
    safeAdd(octaveBands3rd.hz2000, 1.2),
    safeAdd(octaveBands3rd.hz2500, 1.27),
    safeAdd(octaveBands3rd.hz3150, 1.2),
    safeAdd(octaveBands3rd.hz4000, 0.96),
    safeAdd(octaveBands3rd.hz5000, 0.55),
    safeAdd(octaveBands3rd.hz6300, -0.12),
    safeAdd(octaveBands3rd.hz8000, -1.15),
    safeAdd(octaveBands3rd.hz10000, -2.49)
  );
}

export function calcTotFromOctaveBands(octaveBands: OctaveBands | undefined): number | undefined {
  if (octaveBands === undefined) {
    return undefined;
  }
  return sumOctaveBands([
    octaveBands.hz63,
    octaveBands.hz125,
    octaveBands.hz250,
    octaveBands.hz500,
    octaveBands.hz1000,
    octaveBands.hz2000,
    octaveBands.hz4000,
    octaveBands.hz8000,
  ]);
}

export function cWeightOctaveBands3rd(octaveBands3rd: OctaveBands3rd | undefined): OctaveBands3rd | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }
  return createOctaveBands3rd(
    safeAdd(octaveBands3rd.hz50, -1.3),
    safeAdd(octaveBands3rd.hz63, -0.8),
    safeAdd(octaveBands3rd.hz80, -0.5),
    safeAdd(octaveBands3rd.hz100, -0.3),
    safeAdd(octaveBands3rd.hz125, -0.2),
    safeAdd(octaveBands3rd.hz160, 0.1),
    safeAdd(octaveBands3rd.hz200, 0),
    safeAdd(octaveBands3rd.hz250, 0),
    safeAdd(octaveBands3rd.hz315, 0),
    safeAdd(octaveBands3rd.hz400, 0),
    safeAdd(octaveBands3rd.hz500, 0),
    safeAdd(octaveBands3rd.hz630, 0),
    safeAdd(octaveBands3rd.hz800, 0),
    safeAdd(octaveBands3rd.hz1000, 0),
    safeAdd(octaveBands3rd.hz1250, 0),
    safeAdd(octaveBands3rd.hz1600, -0.1),
    safeAdd(octaveBands3rd.hz2000, -0.2),
    safeAdd(octaveBands3rd.hz2500, -0.3),
    safeAdd(octaveBands3rd.hz3150, -0.5),
    safeAdd(octaveBands3rd.hz4000, -0.8),
    safeAdd(octaveBands3rd.hz5000, -1.3),
    safeAdd(octaveBands3rd.hz6300, -2),
    safeAdd(octaveBands3rd.hz8000, -3),
    safeAdd(octaveBands3rd.hz10000, -4.4)
  );
}

export function calcOctaveBandsFrom3rds(octaveBands3rd: OctaveBands3rd | undefined): OctaveBands | undefined {
  if (octaveBands3rd === undefined) {
    return undefined;
  }
  return createOctaveBands(
    sumOctaveBands([octaveBands3rd.hz50, octaveBands3rd.hz63, octaveBands3rd.hz80]),
    sumOctaveBands([octaveBands3rd.hz100, octaveBands3rd.hz125, octaveBands3rd.hz160]),
    sumOctaveBands([octaveBands3rd.hz200, octaveBands3rd.hz250, octaveBands3rd.hz315]),
    sumOctaveBands([octaveBands3rd.hz400, octaveBands3rd.hz500, octaveBands3rd.hz630]),
    sumOctaveBands([octaveBands3rd.hz800, octaveBands3rd.hz1000, octaveBands3rd.hz1250]),
    sumOctaveBands([octaveBands3rd.hz1600, octaveBands3rd.hz2000, octaveBands3rd.hz2500]),
    sumOctaveBands([octaveBands3rd.hz3150, octaveBands3rd.hz4000, octaveBands3rd.hz5000]),
    sumOctaveBands([octaveBands3rd.hz6300, octaveBands3rd.hz8000, octaveBands3rd.hz10000])
  );
}

function safeAdd(a: number | undefined, b: number | undefined): number | undefined {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  return a + b;
}

function safeSub(a: number | undefined, b: number | undefined): number | undefined {
  if (a === undefined || b === undefined) {
    return undefined;
  }
  return a - b;
}

function sumOctaveBands(octaveBands: ReadonlyArray<number | undefined>): number | undefined {
  let tot = 0.0;
  for (const band of octaveBands) {
    if (band === undefined) {
      return undefined;
    }
    // eslint-disable-next-line no-restricted-properties
    tot += Math.pow(10, 0.1 * band);
  }

  return 10 * Math.log10(tot);
}

export function applyAttenuation(
  octaveBands: OctaveBands | undefined,
  attenuation: OctaveBands | undefined
): OctaveBands | undefined {
  if (!octaveBands || !attenuation) {
    return undefined;
  }
  return createOctaveBands(
    safeSub(octaveBands.hz63, attenuation.hz63),
    safeSub(octaveBands.hz125, attenuation.hz125),
    safeSub(octaveBands.hz250, attenuation.hz250),
    safeSub(octaveBands.hz500, attenuation.hz500),
    safeSub(octaveBands.hz1000, attenuation.hz1000),
    safeSub(octaveBands.hz2000, attenuation.hz2000),
    safeSub(octaveBands.hz4000, attenuation.hz4000),
    safeSub(octaveBands.hz8000, attenuation.hz8000)
  );
}

export function applyOneAttenuation(
  octaveBands: OctaveBands | undefined,
  attenuation: number
): OctaveBands | undefined {
  if (!octaveBands || !attenuation) {
    return undefined;
  }
  return createOctaveBands(
    safeSub(octaveBands.hz63, attenuation),
    safeSub(octaveBands.hz125, attenuation),
    safeSub(octaveBands.hz250, attenuation),
    safeSub(octaveBands.hz500, attenuation),
    safeSub(octaveBands.hz1000, attenuation),
    safeSub(octaveBands.hz2000, attenuation),
    safeSub(octaveBands.hz4000, attenuation),
    safeSub(octaveBands.hz8000, attenuation)
  );
}

export function createOctaveBands(
  hz63: number | undefined,
  hz125: number | undefined,
  hz250: number | undefined,
  hz500: number | undefined,
  hz1000: number | undefined,
  hz2000: number | undefined,
  hz4000: number | undefined,
  hz8000: number | undefined
): OctaveBands {
  const total = sumOctaveBands([hz63, hz125, hz250, hz500, hz1000, hz2000, hz4000, hz8000]);
  return {
    type: "Octave",
    hz63,
    hz125,
    hz250,
    hz500,
    hz1000,
    hz2000,
    hz4000,
    hz8000,
    total: total !== undefined ? Amount.create(total, Units.DecibelLw, 1) : undefined,
  };
}

export function createOctaveBands3rd(
  hz50: number | undefined,
  hz63: number | undefined,
  hz80: number | undefined,
  hz100: number | undefined,
  hz125: number | undefined,
  hz160: number | undefined,
  hz200: number | undefined,
  hz250: number | undefined,
  hz315: number | undefined,
  hz400: number | undefined,
  hz500: number | undefined,
  hz630: number | undefined,
  hz800: number | undefined,
  hz1000: number | undefined,
  hz1250: number | undefined,
  hz1600: number | undefined,
  hz2000: number | undefined,
  hz2500: number | undefined,
  hz3150: number | undefined,
  hz4000: number | undefined,
  hz5000: number | undefined,
  hz6300: number | undefined,
  hz8000: number | undefined,
  hz10000: number | undefined
): OctaveBands3rd {
  const total = sumOctaveBands([
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000,
  ]);
  return {
    type: "Octave3rd",
    hz50,
    hz63,
    hz80,
    hz100,
    hz125,
    hz160,
    hz200,
    hz250,
    hz315,
    hz400,
    hz500,
    hz630,
    hz800,
    hz1000,
    hz1250,
    hz1600,
    hz2000,
    hz2500,
    hz3150,
    hz4000,
    hz5000,
    hz6300,
    hz8000,
    hz10000,
    total: total !== undefined ? Amount.create(total, Units.DecibelLw, 1) : undefined,
  };
}

function calculateBladePassFrequency(numberOfFanBlades: number, fanSpeedRpm: number): number {
  return (numberOfFanBlades * fanSpeedRpm) / 60;
}

const AMCA_STANDARD_RPM = 1000;
const AMCA_STANDARD_DIAMETER = 508;

function calculateGeneralizedSoundPowerLevel(
  lwa: BandAndValue,
  lwaRpm: number,
  fanImpelerDiametermm: number
): BandAndValue {
  const lwp =
    lwa.value -
    50 * Math.log10(lwaRpm / AMCA_STANDARD_RPM) -
    70 * Math.log10(fanImpelerDiametermm / AMCA_STANDARD_DIAMETER);

  return {
    freqBand: lwa.freqBand,
    value: lwp,
  };
}
