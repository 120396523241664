import { retryFetch } from "../fetch";

export interface PriceList {
  readonly [itemNo: string]: number;
}

export async function getVariantNoPriceList(baseUrl: string, market: string): Promise<PriceList> {
  if (market === "999999") {
    // ignore market, fake all items in list
    const allItemsList: { [itemNo: string]: number } = {};
    for (let i = 0; i < 3000000; i++) {
      allItemsList[i] = 1;
    }
    return allItemsList;
  }

  try {
    const priceListUrl = `${baseUrl}?divisionid=${market}`;
    const response = await retryFetch(priceListUrl, { method: "get" });
    const json = await response.json();
    return json;
  } catch (ex) {
    console.log(ex);
  }
  return {};
}
