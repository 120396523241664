import * as React from "react";
import type * as QP from "shared-lib/query-product";
import { withTw } from "client-lib/elements";

const Wrapper = withTw("div", "inline-flex justify-center items-center h-full w-full");

export function renderResultTableRowIcon(icon: QP.ResultTableRowIcon): React.ReactElement {
  if (icon === "required") {
    return (
      <Wrapper>
        <svg width="16" height="16">
          <ellipse cx="8" cy="8" rx="6" ry="6" stroke="rgb(8,67,128)" strokeWidth="2" fill="rgb(255,255,255)" />
        </svg>
      </Wrapper>
    );
  } else if (icon === "working") {
    return (
      <Wrapper>
        <svg width="16" height="16">
          <ellipse cx="8" cy="8" rx="4" ry="4" stroke="rgb(0,0,0)" strokeWidth="0" fill="rgb(8,67,128)" />
        </svg>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <span />
      </Wrapper>
    );
  }
}
