import type * as QP from "shared-lib/query-product";
import type { Unit } from "uom";
import * as R from "ramda";
import type { AnyQuantity } from "shared-lib/uom";
import type { Curve } from "../result-items-types";
import * as IP from "./interpolation";

export function createDataPointCurves(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  points: ReadonlyArray<QP.AirData>,
  limits: ReadonlyArray<QP.AirLimitsData>
): ReadonlyArray<Curve> {
  const groupedPoints = R.groupBy((r) => r.step, points);
  const curves = R.keys(groupedPoints).map((l) => {
    const linePoints = groupedPoints[l];
    const first = linePoints[0];
    const lineLimits = limits.find(
      (li) =>
        li.step_name === first.step && li.voltage_control === first.volt_ctrl && li.voltage_supply === first.volt_supp
    );
    const workMin =
      lineLimits && lineLimits.min_flow !== 0 ? lineLimits.min_flow : Math.min(...linePoints.map((p) => p.flow));
    const workMax =
      lineLimits && lineLimits.max_flow !== 0 ? lineLimits.max_flow : Math.max(...linePoints.map((p) => p.flow));
    return {
      id: l,
      controlVoltage: first.volt_ctrl,
      supplyVoltage: first.volt_supp,
      controlFrequency: first.freq_ctrl,
      unitX: unitX,
      unitY: unitY,
      workMin: workMin,
      workMax: workMax,
      spline: IP.splineCreateFromPoints(
        linePoints.map((p) => ({
          x: p.flow,
          y: p.value,
        }))
      ),
    };
  });
  curves.sort((a, b) => {
    // Take two points from each curve and sort on pressuredrop (middle of the curve 0,5 and between middle and end 0.7)
    const aVal =
      (IP.splineGetPoint((a.spline.xMin + a.spline.xMax) * 0.5, a.spline) ?? 0) +
      (IP.splineGetPoint((a.spline.xMin + a.spline.xMax) * 0.7, a.spline) ?? 0);
    const bVal =
      (IP.splineGetPoint((b.spline.xMin + b.spline.xMax) * 0.5, b.spline) ?? 0) +
      (IP.splineGetPoint((b.spline.xMin + b.spline.xMax) * 0.7, b.spline) ?? 0);
    if (aVal === undefined || bVal === undefined) {
      //console.warn("Sorting issues!");
      return 0;
    }
    return aVal - bVal;
  });
  return curves;
}
