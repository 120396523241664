import { retryFetch } from "shared-lib/fetch";
import { memoize } from "shared-lib/utils/memoize";

export interface DbmInput {
  readonly target: "AirOutTemperature" | "FluidFlow_dm3s" | "FluidFlow2_dm3h" | "FluidTempOut" | null;
  readonly inputsData: {
    readonly CoilType: number;
    readonly AirInTemperature: number;
    readonly AirInHumidity: number;
    readonly AirInAbsHumidity: number;
    readonly AirInWetBulb: number;
    readonly AirInFlowStandard: number;
    readonly AirInFlowNormal: number;
    readonly AirInFlowActual: number;
    readonly HeaderMaterial: number;
    readonly AirWeight_kgh: number;
    readonly AirWeight_kgs: number;
    readonly NoRows: number;
    readonly NoTubes: number;
    readonly FinPitch: number;
    readonly NoCircuits: number;
    readonly CoilWidth: number;
    readonly CoilHeight: number;
    readonly Capacity_kCalh: number;
    readonly CapacitykW_kW: number;
    readonly AirOutTemperature: number;
    readonly AirInVelocityStandard: number;
    readonly AirInVelocityNormal: number;
    readonly AirInVelocityActual: number;
    readonly FluidFlow_dm3s: number;
    readonly FluidFlow2_dm3h: number;
    readonly FluidTempIn: number;
    readonly FluidTempOut: number;
    readonly FluidWeight: number;
    readonly FluidWeightkgH: number;
    readonly MaxAllowedFluidSidePressureDrop: number;
    readonly WorkingPressure_bar: number;
    readonly WorkingPressure_atm: number;
    readonly WorkingPressure_kpa: number;
    readonly WorkingPressure_kgm2: number;
    readonly WorkingPressure_mmHg: number;
    readonly WorkingPressureMMH20: number;
    readonly TubeSideFoulingFactor: number;
    readonly GlycolType: number;
    readonly GlycolPercentageByVolume: number;
    readonly GlycolPercentageByWieght: number;
    readonly SafetyFactorOnSurface: number;
    readonly SafetyFactorOnCapacity: number;
    readonly FluidType: number;
    readonly FluidDensity: number;
    readonly FluidVicosity: number;
    readonly FluidSpecificHeat: number;
    readonly FluidConductivity: number;
    readonly FrameCode: number;
    readonly PriceMultiplier: number;
    readonly FoulingFactorFinsSide: number;
    readonly CondensingPressure: number;
    readonly CondensingTemperature: number;
    readonly EvaporatingPressure: number;
    readonly EvaporatingTemperature: number;
    readonly SubCooling: number;
    readonly SuperHeating: number;
    readonly TypeOfCalculation: number;
    readonly FinsMaterial: number;
    readonly FinsThickness: number;
    readonly TubeThickness: number;
    readonly Flanges: number;
    readonly TubeMaterial: number;
    readonly CustomerField1: number;
    readonly CustomerField2: number;
    readonly CustomerField3: number;
    readonly ConnectionSide: number;
    readonly OveralldimensionWidth: number;
    readonly ARIVersion: number;
    readonly TypeOfFins: number;
    readonly AutomaticCoilSelection: number;
    readonly NumberOfGasCircuits: number;
    readonly OverallDimensionHeight: number;
    readonly SteamCoilExecutionType: number;
    readonly ElectroTinnedAfterManufacturing: number;
    readonly CalculationMode: number;
    readonly InletManifoldDiameter: number;
    readonly OutletManifoldDiameter: number;
    readonly BasinType: number;
    readonly DropEliminator: number;
    readonly PackingType: number;
    readonly MinheightofBottomFrameMetalSheet: number;
    readonly MinheightofTopFrameMetalSheet: number;
    readonly TypeOfFlow: number;
  };
}

export interface DbmResult {
  readonly supplierCode: string;
  readonly inletConnectionSize: string;
  readonly outletConnectionSize: string;
  readonly capacityKw: number;
  readonly capacityKcal: number;
  readonly airoutlettemperature: number;
  readonly airoutletrelativehumidity: number;
  readonly airoutletabsolutehumidity: number;
  readonly fluidoutlettemperature: number;
  readonly fluidvolumeDmH: number;
  readonly fluidvolumeDmS: number;
  readonly fluidweightKgH: number;
  readonly fluidweightKgS: number;
  readonly airsidepressuredrop: number;
  readonly fluidsidepressuredrop: number;
  readonly capacityReserve: number;
  readonly coilheight: number;
  readonly coildepth: number;
  readonly ddimension: number;
  readonly gasvelocity: number;
  readonly fluidvelocity: number;
  readonly fluidensity: number;
  readonly fluidviscosity: number;
  readonly fluidspecificheat: number;
  readonly fluidconductivity: number;
  readonly sensibleHeat: number;
  readonly condensedwater: number;
  readonly errorcode: number;
  readonly numberofrows: number;
  readonly numberofcircuits: number;
  readonly coilprice: number;
  readonly coilweight: number;
  readonly subcooling: number;
  readonly superheating: number;
  readonly vapourfraction: number;
  readonly vapourvelocityinsideinletmanifold: number;
  readonly vapourvelocityinsidetubes: number;
  readonly liquidvelocityinsidetubes: number;
  readonly liquidvelocityinsideoutletmanifold: number;
  readonly numberofdistributors: number;
  readonly distributorsdenomination: number;
  readonly capillarsoutsidediameter: number;
  readonly capillarsinsidediameter: number;
  readonly capillarslength: number;
  readonly distributorheaderdiameter: number;
  readonly condensingtemperature: number;
  readonly condensingpressure: number;
  readonly evaporatingtemperature: number;
  readonly evaporatingpressure: number;
  readonly totalexchangesurface: number;
  readonly freonpressuredrop: number;
  readonly inletairrelativehumidity: number;
  readonly internalvolume: number;
  readonly finspitch: number;
  readonly customercode: number;
  readonly coilfinnedlength: number;
  readonly tubesnumber: number;
  readonly tubethickness: number;
  readonly coiloveralllength: number;
  readonly coiloverallheight: number;
  readonly dropeliminatorpressuredrop: number;
  readonly draintrayprice: number;
  readonly dropeliminatorprice: number;
  readonly notused: number;
  readonly numberofcoils: number;
  readonly distancebetweenmanifoldsXforwatercoils: number;
  readonly distancebetweenmanifoldsYforwatercoils: number;
  readonly numberofgascircuit: number;
  readonly coilneedsdrawingconfirmation: number;
  readonly framethickness: number;
  readonly warningcode: number;
  readonly finsthickness: number;
  readonly connectionside: number;
  readonly airsidepressuredropdrymode: number;
  readonly framelengthonbendsside: number;
}

export const calculate = memoize(_calculate);
export async function _calculate(input: DbmInput): Promise<DbmResult | undefined> {
  //console.log("Sending", JSON.stringify({ inputsData: input, optionsData: [0] }));
  const response = await retryFetch("https://design-dllcal.systemair.com/api/dbm-coils/StartJobModulate", {
    //const response = await retryFetch("http://127.0.0.1:5000/StartJobModulate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...input, optionsData: [0] }),
  });
  if (!response.ok) {
    return undefined;
  }
  const result = await response.json();
  return result;
}
