import * as React from "react";
import { Icon } from "./icon";

interface FricoIconProps {
  readonly className?: string;
  readonly icon: "recommended" | "high" | "ok" | "low";
  readonly title?: string;
  readonly label?: string;
}

export function FricoIcon({ className, icon, title, label }: FricoIconProps): React.ReactElement {
  const classNameColor =
    icon === "high" ? "text-warm-600  " : icon === "ok" ? "text-fresh-600" : icon === "low" ? "text-cool-600" : "";
  const iconName =
    icon === "recommended"
      ? "thumbs-up"
      : icon === "high"
      ? "arrow-up"
      : icon === "ok"
      ? "circle"
      : icon === "low"
      ? "arrow-down"
      : undefined;
  return (
    <span className={`${classNameColor} ${className ?? ""}`}>
      <Icon prefix="fas" className="pl-4" icon={iconName} title={title} />
      {label ? <span className="pl-4">{label}</span> : undefined}
    </span>
  );
}
