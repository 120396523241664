import { retryFetch } from "shared-lib/fetch";
import type { ExternalConfig, ExternalResult } from "shared-lib/external-item";
import { exhaustiveCheck } from "ts-exhaustive-check";
import type { ExternalData, ExternalStatus } from "./types";

export async function postToExternal(externalConfig: ExternalConfig, items: ExternalResult): Promise<ExternalStatus> {
  try {
    const postData = createExternalData(externalConfig, items);

    const result = await retryFetch(postData.url, {
      method: "POST",
      body: postData.body,
      headers: {
        "Content-Type": "application/json",
        ...postData.headers,
      },
    });
    if (result.ok) {
      return {
        type: "success",
      };
    } else {
      const json = await result.json();
      const error = typeof json === "object" ? json.error : undefined;
      return {
        type: "error",
        code: typeof error?.code === "number" ? error?.code : undefined,
        message: typeof error?.message === "string" ? error?.message : undefined,
      };
    }
  } catch {
    return {
      type: "error",
      code: undefined,
      message: undefined,
    };
  }
}

function createExternalData(config: ExternalConfig, items: ExternalResult): ExternalData {
  console.log(items);
  switch (config.type) {
    case "crm": {
      return {
        headers: {},
        url: config.crmUrl,
        body: JSON.stringify({ quoteId: config.crmQuoteId, selectionToolData: items.externalItems }),
      };
    }
    case "specifier": {
      return {
        headers: { "x-access-token": config.token },
        url: config.url,
        body: replaceNonAscii(JSON.stringify(items.specifierPayload)),
      };
    }
    default: {
      exhaustiveCheck(config);
      throw new Error("Not implemented");
    }
  }
}

function replaceNonAscii(text: string): string {
  let cleanedText = text;
  cleanedText = cleanedText.replaceAll("–", "-");
  cleanedText = cleanedText.replaceAll("•", "-");
  return cleanedText;
}
