import * as AI from "abstract-image";

export const blue30 = "#B4E5F4"; // text-cool-100
export const blue80 = "#00A7D8"; // text-cool
export const blue100 = "#084380"; // text-cool-600

export const gray10 = "#F6F7F8"; // text-gray-50
export const gray30 = "#EDEFF1"; // text-gray-100
export const gray80 = "#AFB8C1"; // text-gray-400
export const gray100 = "#768695"; // text-gray-600

export const red30 = "#F7D5CE"; // text-warm-100
export const red50 = "#E46F59"; // text-warm

export const feiHex = "#03FC45";

export const purple30 = "#d3b2e4";
export const purple100 = "#80085c";

export interface DiagramStyle {
  readonly backgroundColor: AI.Color;
  readonly grid: DiagramLineStyle;
  readonly subGrid: DiagramLineStyle;

  readonly originalAreaColor: AI.Color;
  readonly actualAreaColor: AI.Color;
  readonly invalidAreaColor: AI.Color;

  readonly feiLine: DiagramLineStyle;
  readonly feiArea: AI.Color;

  readonly originalLine: DiagramLineStyle;
  readonly actualLine: DiagramLineStyle;
  readonly systemLine: DiagramLineStyle;
  readonly sfpLine: DiagramLineStyle;
  readonly powerLine: DiagramLineStyle;

  readonly desiredPointColor: AI.Color;
  readonly invalidColor: AI.Color;
  readonly workPointColor: AI.Color;
  readonly workingPointLabelColor: AI.Color;

  readonly actualAreaColorMulti: ReadonlyArray<AI.Color>;
  readonly actualLineMulti: ReadonlyArray<DiagramLineStyle>;
}

export interface DiagramLineStyle {
  readonly lineColor: AI.Color;
  readonly lineThickness: number;
  readonly labelFontSize: number;
  readonly labelColor: AI.Color;
}

export type ClassColors = { readonly [className: string]: AI.Color };

export const diagramGanymed: DiagramStyle = {
  backgroundColor: hexToColor(gray10),

  feiArea: hexToColor(feiHex),

  grid: {
    lineColor: hexToColor(gray80),
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  subGrid: {
    lineColor: AI.lightGray,
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalAreaColor: hexToColor(gray30),
  actualAreaColor: hexToColor(blue30),
  invalidAreaColor: hexToColor(red30),
  feiLine: {
    lineColor: hexToColor(feiHex),
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalLine: {
    lineColor: hexToColor(gray100),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: AI.black,
  },
  actualLine: {
    lineColor: hexToColor(blue100),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  systemLine: {
    lineColor: hexToColor(blue100),
    lineThickness: 2,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  sfpLine: {
    lineColor: hexToColor(blue100),
    lineThickness: 1,
    labelFontSize: 8,
    labelColor: AI.black,
  },

  powerLine: {
    lineColor: hexToColor(red50),
    lineThickness: 1,
    labelFontSize: 8,
    labelColor: AI.black,
  },

  desiredPointColor: hexToColor(blue80),
  invalidColor: hexToColor(red50),
  workPointColor: hexToColor(blue100),
  workingPointLabelColor: AI.black,

  actualAreaColorMulti: colorGradient("#0000ff", "#ff0000"),
  actualLineMulti: colorGradient("#0000ff", "#ff0000").map((c) => ({
    lineColor: c,
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: c,
  })),
};

export const diagramEpim: DiagramStyle = {
  backgroundColor: AI.fromArgb(255, 220, 240, 240),

  feiArea: hexToColor(feiHex),

  grid: {
    lineColor: AI.fromArgb(255, 25, 25, 25),
    lineThickness: 0.75,
    labelColor: AI.fromArgb(255, 0, 0, 0),
    labelFontSize: 10,
  },

  subGrid: {
    lineColor: AI.lightGray,
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalAreaColor: hexToColor(gray30),
  actualAreaColor: hexToColor(blue30),
  invalidAreaColor: hexToColor(red30),
  feiLine: {
    lineColor: hexToColor(feiHex),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalLine: {
    lineColor: AI.fromArgb(255, 0, 0, 0),
    lineThickness: 1.5,
    labelColor: AI.fromArgb(255, 0, 0, 0),
    labelFontSize: 10,
  },
  actualLine: {
    lineColor: AI.fromArgb(255, 0, 0, 0),
    lineThickness: 1.5,
    labelColor: AI.fromArgb(255, 0, 0, 0),
    labelFontSize: 10,
  },

  systemLine: {
    lineColor: AI.fromArgb(255, 0, 150, 60),
    lineThickness: 1.5,
    labelColor: AI.fromArgb(255, 0, 150, 60),
    labelFontSize: 10,
  },

  sfpLine: {
    lineColor: AI.fromArgb(255, 0, 150, 60),
    lineThickness: 1,
    labelColor: AI.fromArgb(255, 0, 150, 60),
    labelFontSize: 8,
  },
  powerLine: {
    lineColor: hexToColor(red50),
    lineThickness: 1,
    labelFontSize: 8,
    labelColor: hexToColor(red30),
  },

  desiredPointColor: AI.fromArgb(255, 0, 0, 0),
  invalidColor: AI.fromArgb(255, 0, 0, 0),
  workPointColor: AI.fromArgb(255, 0, 0, 0),
  workingPointLabelColor: AI.fromArgb(255, 0, 0, 0),

  actualAreaColorMulti: colorGradient("#000000", "#000000"),
  actualLineMulti: colorGradient("#000000", "#000000").map((c) => ({
    lineColor: c,
    lineThickness: 1.5,
    labelFontSize: 10,
    labelColor: c,
  })),
};

export const diagramCentrifugalFan: DiagramStyle = {
  feiArea: hexToColor(feiHex),
  backgroundColor: hexToColor("#FFFFFF"),

  grid: {
    lineColor: hexToColor(gray80),
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  subGrid: {
    lineColor: AI.lightGray,
    lineThickness: 1,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalAreaColor: hexToColor(gray30),
  actualAreaColor: hexToColor("#EFEFEF"),
  invalidAreaColor: hexToColor(red30),
  feiLine: {
    lineColor: hexToColor(feiHex),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: AI.black,
  },

  originalLine: {
    lineColor: hexToColor("#6EB91E"),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: hexToColor(gray100),
  },
  actualLine: {
    lineColor: hexToColor("#6EB91E"),
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: hexToColor("#0079F0"),
  },

  systemLine: {
    lineColor: hexToColor(blue100),
    lineThickness: 2,
    labelFontSize: 12,
    labelColor: hexToColor(blue100),
  },

  sfpLine: {
    lineColor: hexToColor(blue100),
    lineThickness: 1,
    labelFontSize: 8,
    labelColor: hexToColor(blue100),
  },
  powerLine: {
    lineColor: hexToColor(red50),
    lineThickness: 1,
    labelFontSize: 8,
    labelColor: hexToColor(red30),
  },

  desiredPointColor: hexToColor(blue80),
  invalidColor: hexToColor(red50),
  workPointColor: hexToColor(blue100),
  workingPointLabelColor: AI.fromArgb(255, 0, 0, 0),

  actualAreaColorMulti: colorGradient(blue30, purple30),
  actualLineMulti: colorGradient(blue100, purple100).map((c) => ({
    lineColor: c,
    lineThickness: 0.6,
    labelFontSize: 12,
    labelColor: c,
  })),
};

export const diagramRpmColor = hexToColor("#6EB91E");
export const diagramActualRpmColor = hexToColor("#0079F0");
export const diagramEfficiencyColor = hexToColor("#FFAA00");
export const diagramMotorPowerColor = hexToColor("#A60177");
export const diagramBoxFanAreaColor = hexToColor("#EFEFEF");
export const diagramBoxFanBackgroundColor = hexToColor("#FFFFFF");
export const diagramClassAreaColors: ClassColors = {
  class2: hexToColor("#7ED6FE"),
  class3: hexToColor("#7E9FFF"),
};
// #B4E5F4
export const diagramBoxFanPowerArea = AI.fromString("#600079F0");
export const diagramBoxFanPowerAreaOutside = AI.fromString("#60FFAAAA");
export const diagramBoxFanPowerAreaOutsideLine = AI.fromString("#FFFF2020");

function hexToColor(hex: string): AI.Color {
  return AI.fromString("#FF" + hex.substring(1)) ?? AI.white;
}

function colorGradient(startColorHex: string, endColorHex: string): ReadonlyArray<AI.Color> {
  const numberOfColors = 3;
  const start = AI.fromString("#FF" + startColorHex.substring(1)) ?? AI.white;
  const end = AI.fromString("#FF" + endColorHex.substring(1)) ?? AI.white;
  const colors: Array<AI.Color> = [];
  for (let i = 0; i < numberOfColors; i++) {
    const t = numberOfColors > 1 ? i / (numberOfColors - 1) : 0;
    const r = start.r * t + end.r * (1.0 - t);
    const g = start.g * t + end.g * (1.0 - t);
    const b = start.b * t + end.b * (1.0 - t);
    const a = start.a * t + end.a * (1.0 - t);
    colors.push({ r, g, b, a });
  }
  return colors;
}
