import * as React from "react";
import { SelectButton } from "./select-button";

export interface ListBoxItem {
  readonly key: string;
  readonly label: string;
}

export interface ListBoxProps {
  readonly items: ReadonlyArray<ListBoxItem>;
  readonly selectedItem: string | undefined;
  readonly onChange: (item: string) => void;
}

export function CustomListBox(props: ListBoxProps): React.ReactElement {
  const { items, selectedItem, onChange } = props;
  return <div className="flex flex-col min-w-700">{items.map((i) => renderItem(i, onChange, selectedItem))}</div>;
}

function renderItem(
  item: ListBoxItem,
  onChange: (item: string) => void,
  selectedItem: string | undefined
): React.ReactElement {
  return (
    <SelectButton
      key={`${item.key}-${item.label}`}
      selected={!!selectedItem && selectedItem === item.key}
      onClick={() => onChange(item.key)}
      label={item.label}
      enableWrap={true}
    />
  );
}
