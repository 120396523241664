import { retryFetch } from "shared-lib/fetch";

export interface LautnerInput {
  readonly ConstructionType: string;
  readonly DriveType: string;
  readonly RotorSize: number;
  readonly RotorType: string;
  readonly RotationSpeed: number;

  readonly AirDensity: number;
  readonly AtmosphericPressure: number;
  readonly FreshAirFlow: number;
  readonly FreshAirTemperature: number;
  readonly FreshAirRelativeHumidity: number;
  readonly ExtractAirFlow: number;
  readonly ExtractAirTemperature: number;
  readonly ExtractAirRelativeHumidity: number;
}

export interface LautnerOutput {
  readonly SupplyAirTemperature: number;
  readonly ExhaustAirTemperature: number;
  readonly DryEfficiency: number;
  readonly WetEfficiency: number;
  readonly HumidityEfficiency: number;
  readonly SupplyAirPressureDrop: number;
  readonly ExhaustAirPressureDrop: number;
  readonly SupplyRelativeHumidity: number;
  readonly ExhaustRelativeHumidity: number;
  readonly SupplyAirVelocity: number;
  readonly ExhaustAirVelocity: number;
  readonly TransferredPowerSensible: number;
  readonly TransferredPowerTotal: number;
  readonly TransferredPowerLatent: number;
  readonly Condensate: number;
}

export async function calculate(input: LautnerInput): Promise<LautnerOutput> {
  const result: LautnerOutput = await retryFetch("https://design-dllcal.systemair.com/api/lautner/calculate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  }).then((r) => r.json());

  return result;
}
