import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import { ResultRowContainer, Heading3 } from "client-lib/elements";
import type * as UserSettingsShared from "shared-lib/user-settings";
import type * as QP from "shared-lib/query-product";
import type * as SC from "shared-lib/system-calculator";
import { isAllDefined } from "shared-lib/utils";
import type { DispatchProp } from "client-lib/redux-integration";
import type * as UserSettingsClient from "client-lib/user-settings";
import type { VisualizerOwnProps } from "../types";

export type Props = VisualizerOwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  readonly showRpm: boolean;
  readonly showEfficiency: boolean;
  readonly showMotorPower: boolean;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
}

interface Base64Diagram {
  readonly diagramBase64: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBase64Diagram(arg: any): arg is Base64Diagram {
  return arg.diagramBase64 !== undefined;
}

export function Base64DiagramVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  if (products.length === 1) {
    return (
      <div className="w-1/2">
        <Heading3>{translate(Texts.performanceCurve())}</Heading3>
        <Base64DiagramVisualizer translate={props.translate} resultItemMap={products[0].resultItemMap} />
      </div>
    );
  } else {
    return (
      <ResultRowContainer heading={translate(Texts.performanceCurve())}>
        {products.map((p, i) => (
          <Base64DiagramVisualizer key={i} translate={props.translate} resultItemMap={p.resultItemMap} />
        ))}
      </ResultRowContainer>
    );
  }
}

export function Base64DiagramVisualizer({
  translate,
  resultItemMap,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly resultItemMap: SC.ResultItemMap;
}): React.ReactElement<Props> {
  const maybeResultItem = resultItemMap[Object.keys(resultItemMap)[0]];
  const resultValue = maybeResultItem?.value;
  if (!resultValue || !isBase64Diagram(resultValue)) {
    return <div />;
  }

  return (
    <div className="p-8">
      <div>
        <img
          src={`data:image/png;base64,${resultValue.diagramBase64}`}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
      <div className="text-center text-xs">{translate(Texts.diagram_operating_point_warning())}</div>
    </div>
  );
}
