/* eslint-disable functional/no-this-expression */
import * as React from "react";
import type { FalIconName } from "client-lib/font-awesome-icons";
import { withTw } from "./with-tw";
import { StyledButton } from "./button";
import { Icon } from "./icon";
import { TextAreaInput } from "./textarea-input";

const Container = withTw("div", "relative");
const Background = withTw("div", "fixed top-0 left-0 w-full h-full");
const Options = withTw(
  "div",
  "z-50 w-full min-w-fit absolute top-36 flex flex-col text-sm dropdown-item-container pt-8 pb-8"
);
const ItemLink = withTw("a", "font-normal whitespace-nowrap cursor-pointer text-sm pl-16 pr-16 pt-8 pb-8");
const ItemText = withTw(
  "div",
  ({ isActive }: { readonly isActive?: boolean }) => (isActive ? "bg-btn-active-bg text-btn-active-text" : ""),
  "font-normal whitespace-nowrap cursor-pointer text-sm pl-16 pr-16 pt-8 pb-8 hover:bg-brand-50 hover:text-cta-hover"
);

export type DropdownButtonProps = {
  readonly className?: string;
  readonly leftIcon?: FalIconName;
  readonly rightIcon?: FalIconName;
  readonly label?: string;
  readonly items: ReadonlyArray<DropdownButtonItem | DropdownButtonCategory>;
  readonly enableSearch?: boolean;
};

export type DropdownButtonCategory = {
  readonly type: "category";
  readonly value: string;
  readonly label: string | React.ReactNode;
  readonly icon?: string;
  readonly items: ReadonlyArray<DropdownButtonItem>;
  readonly collapseAble?: boolean;
};

export type DropdownButtonItem = {
  readonly type: "item";
  readonly value: string;
  readonly label: string | React.ReactNode;
  readonly isActive?: boolean;
  readonly icon?: string;
  readonly url?: string;
  readonly onClick?: () => void;
};

// eslint-disable-next-line functional/no-class
export function DropdownButton(props: DropdownButtonProps): JSX.Element {
  const { className, leftIcon, rightIcon, label, items, enableSearch } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [openCategories, setOpenCategories] = React.useState(new Set());

  const toggleCategory = (category: string): void => {
    setOpenCategories((previousState) =>
      previousState.has(category)
        ? new Set([...previousState].filter((c) => c !== category))
        : new Set([...previousState, category])
    );
  };

  const renderItems = (i: DropdownButtonItem): JSX.Element => {
    return i.url ? (
      <ItemLink
        key={i.value}
        target="_blank"
        href={i.url}
        onClick={() => {
          setIsOpen(false);
          if (i.onClick) {
            i.onClick();
          }
        }}
      >
        {i.label}
      </ItemLink>
    ) : (
      <ItemText
        key={i.value}
        isActive={i.isActive}
        className=""
        onClick={() => {
          setIsOpen(false);
          if (i.onClick) {
            i.onClick();
          }
        }}
      >
        {i.label}
      </ItemText>
    );
  };

  const itemsList = isOpen ? (
    <Options className="w-full">
      {enableSearch && (
        <div className={"px-16 pt-8"}>
          <TextAreaInput
            autoFocus={true}
            className={"w-full resize-none h-32 mb-8 !pl-32"}
            placeholder={"search"}
            rows={1}
            value={filter}
            onChange={setFilter}
          />
          <Icon icon={"magnifying-glass"} className={"absolute top-24 left-24 text-neutral-500"} />
        </div>
      )}

      {items
        .filter((i) =>
          enableSearch && i.type === "item"
            ? getComponentText(i.label).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            : true
        )
        .map((option) => {
          if (option.type === "item") {
            return renderItems(option);
          }

          const optionItems = enableSearch
            ? option.items.filter((item) =>
                getComponentText(item.label).toLocaleLowerCase().includes(filter.toLocaleLowerCase())
              )
            : option.items;

          if (optionItems.length === 0) {
            return [];
          }

          return (
            <div key={option.value}>
              <ItemText
                className={"p-6"}
                key={option.value}
                onClick={() => {
                  if (option.collapseAble) {
                    toggleCategory(option.value);
                  }
                }}
              >
                <div className="flex flex-row">
                  {option.collapseAble && (
                    <Icon
                      className="text-md text-gray-400 mt-[0.15em] mr-8"
                      icon={openCategories.has(option.value) ? "angle-down" : "angle-up"}
                    />
                  )}
                  {option.label}
                </div>
              </ItemText>

              {(!option.collapseAble || openCategories.has(option.value)) && (
                <div className={"px-9"}> {optionItems.map(renderItems)}</div>
              )}
            </div>
          );
        })}
    </Options>
  ) : undefined;

  return (
    <Container className={className}>
      {isOpen ? <Background onClick={() => setIsOpen(false)} /> : undefined}

      <StyledButton
        small={true}
        buttonType={"secondary"}
        onClick={(e) => {
          const newIsOpen = !isOpen;
          setIsOpen(newIsOpen);
          if (!newIsOpen) {
            e.currentTarget.blur();
          }
        }}
      >
        {leftIcon && <Icon icon={leftIcon} />}
        {label && <span className={"whitespace-nowrap text-sm"}>{label}</span>}
        <Icon icon={rightIcon || "chevron-down"} />
      </StyledButton>
      {isOpen ? itemsList : undefined}
    </Container>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComponentText(obj: any): string {
  let buf = "";
  if (obj) {
    const type = typeof obj;
    if (type === "string" || type === "number") {
      buf += obj;
    } else if (type === "object") {
      let children = null;
      if (Array.isArray(obj)) {
        children = obj;
      } else {
        const props = obj.props;
        if (props) {
          children = props.children;
        }
      }
      if (children) {
        if (Array.isArray(children)) {
          children.forEach(function (o) {
            buf += getComponentText(o);
          });
        } else {
          buf += getComponentText(children);
        }
      }
    }
  }
  return buf;
}
