import type { CalculatorSuccess, OutputMapperResult} from "../types";
import { createOutputMapperSuccess } from "../types";
import type { BoxFanYilida} from "../result-items-types";
import { createBoxFanYilidaResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<BoxFanYilida>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createBoxFanYilidaResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
