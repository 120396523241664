import * as React from "react";
import { withTw } from "client-lib/elements";
import { getAmcaVersion } from "shared-lib/version";
import type { Props } from "./types";

export const VersionDiv = withTw("div", "w-full h-0 text-xs text-right text-white print:hidden");

export function AmcaVersionComponent(props: Props): React.ReactElement<Props> {
  const { market, productKey, tables } = props;
  const amcaVersion = getAmcaVersion(market, productKey, tables);

  if (!amcaVersion) {
    return <></>;
  }

  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <div>Version: {amcaVersion}</div>
    </div>
  );
}
