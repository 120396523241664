import * as R from "ramda";
import { PropertyValue } from "@promaster-sdk/property";
import * as QP from "../query-product";

export interface TranslationTables {
  readonly [productId: string]: {
    readonly ct_LanguageMapping: QP.LanguageMappingTable;
    readonly text: QP.TextTable;
    readonly property: QP.PropertyTable;
  };
}

export const getTablesQuery = (productIds: ReadonlyArray<string>): QP.TablesByProductIdsQuery => {
  return QP.tablesByProductIds([QP.metaProductId, ...productIds], ["ct_LanguageMapping", "text", "property"]);
};

export interface ProductsWithPropertyTexts {
  readonly [productId: string]: {
    readonly propertyTexts: ProductPropertyTexts;
  };
}

export interface Translation {
  readonly [locale: string]: string;
}

export interface ProductPropertyTexts {
  readonly [property: string]: ProductPropertyText;
}

export interface ProductPropertyText {
  readonly standard: Translation;
  readonly long: Translation;
  readonly values: {
    readonly [propertyValue: number]: ProductPropertyValueTexts;
  };
}

export interface ProductPropertyValueTexts {
  readonly description: string;
  readonly translation: Translation;
}

export function propertyTextCombiner(tables: TranslationTables): ProductsWithPropertyTexts | undefined {
  if (tables === undefined) {
    return undefined;
  }

  const productsWithPropertyTexts: {
    [productId: string]: {
      readonly propertyTexts: ProductPropertyTexts;
    };
  } = {};
  for (const productId of Object.keys(tables)) {
    const converted = convertPropertyTexts(tables[productId].property);
    if (converted) {
      productsWithPropertyTexts[productId] = { propertyTexts: converted };
    }
  }
  return productsWithPropertyTexts;
}

function convertPropertyTexts(properties: QP.PropertyTable | undefined): ProductPropertyTexts | undefined {
  if (properties === undefined) {
    return undefined;
  }
  return R.fromPairs(
    properties.map((property) => {
      const standardTexts = R.fromPairs(
        property.translation
          .filter((t) => t.type !== "long")
          .map((t) => [t.language, t.translation] as R.KeyValuePair<string, string>)
      );
      const longTexts = R.fromPairs(
        property.translation
          .filter((t) => t.type === "long")
          .map((t) => [t.language, t.translation] as R.KeyValuePair<string, string>)
      );
      const valuesTexts = R.fromPairs(
        (property.value || []).map((v) => {
          const value = PropertyValue.getInteger(v.value);
          const description = v.description;
          const valueTexts = R.fromPairs(
            v.translation.map((t) => [t.language, t.translation] as R.KeyValuePair<string, string>)
          );
          return [
            value,
            {
              description: description,
              translation: valueTexts,
            },
          ] as R.KeyValuePair<number, ProductPropertyValueTexts>;
        })
      );

      const propTexts = {
        standard: standardTexts,
        long: longTexts,
        values: valuesTexts,
      };
      return [property.name, propTexts] as R.KeyValuePair<string, ProductPropertyText>;
    })
  );
}
