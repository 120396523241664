import { retryFetch } from "shared-lib/fetch";

export interface ProductDescriptionAndTechnicalParameters {
  readonly description: SpecificationText | undefined;
  readonly technicalParameters: TechnicalParametersTable | undefined;
}

export interface SpecificationText {
  readonly product_specification_text: string;
}

export interface TechnicalParametersTable {
  readonly tabName: string;
  readonly table: ReadonlyArray<TableRow>;
}

export interface TableRow {
  readonly tr: ReadonlyArray<TableCell>;
}

export interface TableCell {
  readonly th?: string;
  readonly td?: string;
}

export async function getProductDescriptionAndTechnicalParameters(
  baseUrl: string,
  market: string,
  language: string,
  itemnumber: string,
  variantId: string
): Promise<ProductDescriptionAndTechnicalParameters> {
  const productIdentifier = `itemNumber=${itemnumber}&market=${market}&languageCode=${language}&variantId=${variantId}`;

  const productSpecificationUrl = `${baseUrl}/product/${variantId}/description/short`;
  const technicalParametersUrl = `${baseUrl}/selectiontooltechnicaldataprovider?${productIdentifier}`;

  const specificationPromise = getSpecification(productSpecificationUrl);
  const technicalParametersPromise = getTechnicalParameters(technicalParametersUrl);

  try {
    const [specificationResult, technicalParametersResult] = await Promise.all([
      specificationPromise,
      technicalParametersPromise,
    ]);
    return {
      description: specificationResult,
      technicalParameters: technicalParametersResult,
    };
  } catch (ex) {
    return {
      description: undefined,
      technicalParameters: undefined,
    };
  }
}

async function getSpecification(url: string): Promise<SpecificationText | undefined> {
  try {
    const results = await retryFetch(url, { method: "get" });
    const json = await results.json();
    if (json.html === "") {
      return undefined;
    }
    return { product_specification_text: json.html };
  } catch (ex) {
    return undefined;
  }
}

async function getTechnicalParameters(url: string): Promise<TechnicalParametersTable | undefined> {
  try {
    const results = await retryFetch(url, { method: "get" });
    const json = await results.json();
    if (json.success === false) {
      return undefined;
    }
    return json;
  } catch (ex) {
    return undefined;
  }
}
