import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as log from "loglevel";
import * as Types from "../types";
import * as Messages from "../messages";
import type { DxCoil } from "../result-items-types";
import * as Dbm from "../dll/dbm";
import * as PressureDrop from "../shared/pressure-drop";
import type { Input } from "./types";
import * as Area from "../shared/area";
import * as Attributes from "../shared/attributes";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "DbmDxCoilCalculator";

const probeFaceVelocities = [1, 3];

export async function calculate(input: Input): Promise<Types.CalculatorResult<DxCoil>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    calculationMethod,
    outletAirTemperature,
    condensingTemperature,
    evaporatingTemperature,
    codes,
    waterCoilLimits,
    attributes,
    dllDbmCoilsFluidTypes,
    calcParams: newCalcParams,
  } = input;

  if (!condensingTemperature || !evaporatingTemperature) {
    return Types.createCalculatorError([Messages.Exception(source, "Missing DX temps")]);
  }

  const airFlowCmph = Amount.valueAs(customUnits.CubicMeterPerHour, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const outletAirTemperatureC = outletAirTemperature ? Amount.valueAs(customUnits.Celsius, outletAirTemperature) : null;

  const condensingTemperatureC = Amount.valueAs(customUnits.Celsius, condensingTemperature);
  const evaporatingTemperatureC = Amount.valueAs(customUnits.Celsius, evaporatingTemperature);

  const searchTarget =
    calculationMethod === 0
      ? {
          outletAirTemperatureC: 0,
          evaporatingTemperatureC: evaporatingTemperatureC ?? 0,
        }
      : {
          outletAirTemperatureC: outletAirTemperatureC ?? 0,
          evaporatingTemperatureC: 0,
        };

  const fluidType = Attributes.getIntOrThrow("DLL-input-dbm-fluid-type", attributes);
  const refrigerantType = dllDbmCoilsFluidTypes.find((r) => r.value === fluidType)?.fluid_type;

  try {
    const dbmInput: Dbm.DbmInput = {
      target: null,
      inputsData: {
        CoilType: Attributes.getIntOrThrow("DLL-input-dbm-coil-type", attributes),
        AirInTemperature: inletAirTemperatureC,
        AirInHumidity: inletAirHumidityP,
        AirInAbsHumidity: 0,
        AirInWetBulb: 0,
        AirInFlowStandard: 0,
        AirInFlowNormal: 0,
        AirInFlowActual: airFlowCmph,
        HeaderMaterial: Attributes.getIntOrThrow("DLL-input-dbm-header-material", attributes),
        AirWeight_kgh: 0,
        AirWeight_kgs: 0,
        NoRows: Attributes.getIntOrThrow("DLL-input-dbm-rows", attributes),
        NoTubes: Attributes.getIntOrThrow("DLL-input-dbm-tubes", attributes),
        FinPitch: Attributes.getFloatOrThrow("DLL-input-dbm-fin-pitch", attributes),
        NoCircuits: Attributes.getIntOrThrow("DLL-input-dbm-circuits", attributes),
        CoilWidth: Attributes.getFloatOrThrow("DLL-input-dbm-width", attributes),
        CoilHeight: Attributes.getFloatOrThrow("DLL-input-dbm-height", attributes),
        Capacity_kCalh: 0,
        CapacitykW_kW: 0,
        AirOutTemperature: searchTarget.outletAirTemperatureC,
        AirInVelocityStandard: 0,
        AirInVelocityNormal: 0,
        AirInVelocityActual: 0,
        FluidFlow_dm3s: 0,
        FluidFlow2_dm3h: 0,
        FluidTempIn: 0,
        FluidTempOut: 0,
        FluidWeight: 0,
        FluidWeightkgH: 0,
        MaxAllowedFluidSidePressureDrop: 0,
        WorkingPressure_bar: 1.013,
        WorkingPressure_atm: 0,
        WorkingPressure_kpa: 0,
        WorkingPressure_kgm2: 0,
        WorkingPressure_mmHg: 0,
        WorkingPressureMMH20: 0,
        TubeSideFoulingFactor: 0,
        GlycolType: 0,
        GlycolPercentageByVolume: 0,
        GlycolPercentageByWieght: 0,
        SafetyFactorOnSurface: 0,
        SafetyFactorOnCapacity: 0,
        FluidType: fluidType,
        FluidDensity: 0,
        FluidVicosity: 0,
        FluidSpecificHeat: 0,
        FluidConductivity: 0,
        FrameCode: Attributes.getIntOrThrow("DLL-input-dbm-frame-code", attributes),
        PriceMultiplier: 1,
        FoulingFactorFinsSide: 0,
        CondensingPressure: 0, //
        CondensingTemperature: condensingTemperatureC,
        EvaporatingPressure: 0, //
        EvaporatingTemperature: searchTarget.evaporatingTemperatureC,
        SubCooling: 0, // 0 = default 2 K
        SuperHeating: 0, // 0 = default 5 K
        TypeOfCalculation: 2, // 2 = Direct expansion, 3 = Condenser
        FinsMaterial: Attributes.getIntOrThrow("DLL-input-dbm-fins-material", attributes),
        FinsThickness: Attributes.getFloatOrThrow("DLL-input-dbm-fins-thickness", attributes),
        TubeThickness: Attributes.getFloatOrThrow("DLL-input-dbm-tube-thickness", attributes),
        Flanges: 0,
        TubeMaterial: Attributes.getIntOrThrow("DLL-input-dbm-tube-material", attributes),
        CustomerField1: 0,
        CustomerField2: 0,
        CustomerField3: 0,
        ConnectionSide: 0,
        OveralldimensionWidth: 0,
        ARIVersion: 0,
        TypeOfFins: 0,
        AutomaticCoilSelection: 0,
        NumberOfGasCircuits: 0, //
        OverallDimensionHeight: 0,
        SteamCoilExecutionType: 0,
        ElectroTinnedAfterManufacturing: 0,
        CalculationMode: 0,
        InletManifoldDiameter: Attributes.getIntOrThrow("DLL-input-dbm-inlet-manifold-diameter", attributes),
        OutletManifoldDiameter: Attributes.getIntOrThrow("DLL-input-dbm-outled-manifold-diameter", attributes),
        BasinType: 0,
        DropEliminator: 0,
        PackingType: 0,
        MinheightofBottomFrameMetalSheet: 0,
        MinheightofTopFrameMetalSheet: 0,
        TypeOfFlow: 0,
      },
    };
    const result = await Dbm.calculate(dbmInput);
    if (!result) {
      return Types.createCalculatorError([Messages.Exception(source, "DBM request failed")]);
    } else if (result.errorcode > 0) {
      log.warn(`DBM errorcode: ${result.errorcode}`);
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }

    // Returns a negative capacity for some low air flows / low evap temps
    if (result.capacityKw < 0) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }

    const messages: Array<Messages.Message> = [];

    if (result.gasvelocity > Amount.valueAs(customUnits.MeterPerSecond, waterCoilLimits.max_air_velocity)) {
      messages.push(Messages.Error_AirVelocityTooHigh(source, waterCoilLimits.max_air_velocity));
    }

    if (result.airsidepressuredropdrymode > Amount.valueAs(customUnits.Pascal, waterCoilLimits.max_air_pressure_drop)) {
      messages.push(Messages.Error_AirPressureDropTooHigh(source, waterCoilLimits.max_air_pressure_drop));
    }

    const areaM2 = Area.getArea(attributes);
    if (areaM2 === undefined) {
      return Types.createCalculatorError([Messages.Exception(source, "Could not get face area data")]);
    }
    const altInputs = probeFaceVelocities.map((vMPS) => ({
      ...dbmInput,
      inputsData: { ...dbmInput.inputsData, AirInFlowActual: areaM2 * vMPS * 3600 },
    }));
    const altCalls = altInputs.map((altInput) => Dbm.calculate(altInput));
    const altResults = await Promise.all(altCalls);
    const pressureDropCurve = PressureDrop.createPowerPressureCurve(
      0,
      5000,
      altResults
        .map((r, i) =>
          Interpolation.vec2Create(altInputs[i].inputsData.AirInFlowActual / 3.6, r?.airsidepressuredropdrymode ?? NaN)
        )
        .filter((p) => Number.isFinite(p.y))
    );

    messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, result.airsidepressuredropdrymode));

    return Types.createCalculatorSuccess(
      [{ value: codes.code, descending: false }],
      {
        airFlow: airFlow,
        airPressureDrop: Amount.create(result.airsidepressuredropdrymode, customUnits.Pascal, 1),
        outletAirTemperature: Amount.create(result.airoutlettemperature, customUnits.Celsius, 1),
        outletAirHumidity: Amount.create(result.airoutletrelativehumidity, customUnits.PercentHumidity, 1),
        evaporatingTemperature: Amount.create(result.evaporatingtemperature, customUnits.Celsius, 1),
        condensingTemperature: Amount.create(result.condensingtemperature, customUnits.Celsius, 1),
        airVelocity: Amount.create(result.gasvelocity, customUnits.MeterPerSecond, 2),
        power: Amount.create(result.capacityKw, customUnits.KiloWatt, 2),
        inletAirTemperature: inletAirTemperature,
        inletAirHumidity: inletAirHumidity,
        connectionSizeIn: result.inletConnectionSize || undefined,
        connectionSizeOut: result.outletConnectionSize || undefined,
        coilCode: result.supplierCode,
        pressureDropCurve: pressureDropCurve,
        refrigerantType: refrigerantType,
        refrigerantMassFlow: undefined,
        refrigerantPressureDrop: undefined,
      },
      messages,
      newCalcParams
    );
  } catch (e) {
    return Types.createCalculatorError([Messages.Exception(source, e.toString())]);
  }
}
